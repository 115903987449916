import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import { Link } from 'react-router-dom';

import './contactcards.scss';

export default function ContactCards(props) {
  return (
    <div className="card-flex">
      <Card sx={{ maxWidth: 300 }}>
        <CardMedia
          component="img"
          image="https://thebeigeindex-images.s3.ap-southeast-2.amazonaws.com/headshots/siang.jpg"
          alt="Siang Lu, author of The Whitewash and co-creator of The Beige Index."
        />
        <CardContent>
          <p>
            <strong>Siang Lu</strong> is the award-winning author of a novel –{' '}
            <a
              href="https://www.amazon.com.au/Whitewash-Siang-Lu/dp/070226573X/ref=sr_1_55?crid=2U05OEN21RL6F&amp;keywords=the+whitewash&amp;qid=1650017750&amp;sprefix=the+whitewash%252Caps%252C267&amp;sr=8-55&_encoding=UTF8&tag=sianglu-22&linkCode=ur2&linkId=bdecd62eed4b3d6cd9e513430f9ab762&camp=247&creative=1211"
              target="_blank"
              rel="sponsored noreferrer"
            >
              <em>The Whitewash</em>
            </a>{' '}
            – which, if you found this site amusing and thought-provoking, you
            will 100% also enjoy reading. Siang is behind a great majority of
            the jokes on this site, and he wrote them on much less sleep than
            you'd imagine (unless you didn't like the site, in which case you
            should imagine him oversleeping every day).
          </p>
        </CardContent>
        <CardActions className="siang-card">
          <Button
            fullWidth
            variant="outlined"
            color="warning"
            href="https://www.amazon.com.au/Whitewash-Siang-Lu/dp/070226573X/ref=sr_1_55?crid=2U05OEN21RL6F&amp;keywords=the+whitewash&amp;qid=1650017750&amp;sprefix=the+whitewash%252Caps%252C267&amp;sr=8-55&_encoding=UTF8&tag=sianglu-22&linkCode=ur2&linkId=bdecd62eed4b3d6cd9e513430f9ab762&camp=247&creative=1211"
            target="_blank"
            rel="sponsored"
          >
            Buy The Whitewash
          </Button>
        </CardActions>
      </Card>
      <Card sx={{ maxWidth: 300 }}>
        <CardMedia
          component="img"
          image="https://thebeigeindex-images.s3.ap-southeast-2.amazonaws.com/headshots/jonathan.jpg"
          alt="Jonathan O'Brien, the person behind O'Creative and co-creator of The Beige Index."
        />
        <CardContent>
          <p>
            <strong>Jonathan O'Brien</strong> runs the data, design, and
            communication firm{' '}
            <a href="https://jonobri.com/" target="_blank" rel="noreferrer">
              O'Creative
            </a>
            . If this site's design, aesthetics and functionality has inspired
            you to pursue a stunning data visualisation project of your own, you
            should reach out to{' '}
            <a href="mailto:hi@ocreative.company">hi@ocreative.company</a>.
            Jonathan is also an{' '}
            <a href="https://jonobri.com/" target="_blank" rel="noreferrer">
              award-winning writer
            </a>{' '}
            - Siang just beat him to the punch on the technicality of{' '}
            <em>having a book published</em>.
          </p>
        </CardContent>
        <CardActions>
          <Button
            fullWidth
            variant="outlined"
            color="warning"
            href="https://ocreative.company"
            target="_blank"
            rel="noreferrer"
          >
            Visit O'Creative
          </Button>
        </CardActions>
      </Card>
      {props.alan ? (
        <>
          <Card sx={{ maxWidth: 300 }}>
            <CardMedia
              component="img"
              image="https://thebeigeindex-images.s3.ap-southeast-2.amazonaws.com/headshots/alan.jpg"
              alt="Alan (The Beige Index Intern) had nothing to do with the creation of The Beige Index."
            />
            <CardContent>
              <p>
                <strong>Alan</strong> is a former venture capitalist responsible
                for launching the <em>Click Bae</em> brand, whose legacy is
                covered in{' '}
                <Link to={'/film/the-whitewash'}>
                  <em>The Whitewash</em>
                </Link>
                . He has since made a lateral career move to become an intern
                for The Beige Index. We give him the stuff we don't want, like
                'blame', 'research', and 'write the FAQ but give <em>us</em> the
                credit'.
              </p>
              <p>Some interesting things we have learned about Alan are:</p>
              <ul>
                <li>He hates films</li>
                <li>He just wants to “see [his] daughter again”</li>
              </ul>
            </CardContent>
            <CardActions>
              <Button fullWidth variant="outlined" color="warning">
                Just like Alan, this button has no purpose
              </Button>
            </CardActions>
          </Card>
          <Card sx={{ maxWidth: 300 }}>
            <CardMedia
              component="img"
              image="https://thebeigeindex-images.s3.ap-southeast-2.amazonaws.com/headshots/bagel.jpg"
              alt="Alan (The Beige Index Intern) had nothing to do with the creation of The Beige Index."
            />
            <CardContent>
              <p>
                <strong>The Bagel</strong> is a being we accidentally summoned
                while mucking around with graphql configuration. While we cannot
                pretend to understand him, the bug reports indicate that he is
                drawn to the energy of films in which every member of the
                credited cast is white.
              </p>
            </CardContent>
            <CardActions className="bagel-card">
              <Button
                fullWidth
                variant="outlined"
                color="warning"
                href="/film/tt0035446"
              >
                <center>Here is one of the bagel's favourite films</center>
              </Button>
            </CardActions>
          </Card>
        </>
      ) : null}
    </div>
  );
}
