import React from 'react';
import { Paper } from '@mui/material';
import { Link } from 'react-router-dom';
import './FAQ.scss';
import ContactCards from '../components/contactcards';

export default function FAQ(props) {
  return (
    <>
      <Paper elevation={3} className="about-paper-container">
        <div
          className="faq-container"
          itemScope={true}
          itemType={'http://schema.org/Article'}
          id="page"
        >
          <div className="header">
            <center>
              <h1 id="header" itemProp={'title'}>
                Frequently Asked Questions
              </h1>
            </center>
          </div>
          <div>
            <h5>
              Q. C'mon. You haven't <em>really </em>watched all of the top 250
              films, have you? That'd be over... 538 hours!
            </h5>
            <p className="answer">
              A. Yes, we have, and yes, it is. We're tired.
            </p>
            <br></br>

            <h5>Q. Who are you guys?</h5>
            <p className="answer">
              A. Siang and Jonathan are the co-creators of The Beige Index.
            </p>
            <br></br>
            <ContactCards alan={true} />

            <h5>Q. I love the site! How can I support you?</h5>
            <p className="answer">
              A. Thanks! Consider purchasing your favourite film through our
              Affiliate links, buy us a coffee, or subscribe to our{' '}
              <a href="patreonurl" target="_blank" rel="noreferrer">
                Patreon
              </a>
              ! The Beige Index was a labour of love. We did it for free and
              we'd do it again, dammit.
            </p>
            <p>
              But there are cooler projects for us on the horizon: think The
              Beige Index Oscars Edition, The Beige Index US Senate edition, The
              Beige Index Fortune 500, a gorgeous coffee table book, the biopic
              of Alan (The Beige Index Intern), and more.
            </p>
            <p>
              We also have boring things to pay for, like hosting, film rental
              costs, API subscriptions, cocaine, reheated pizza slices for Alan,
              etc.—for which any donations will help tremendously.
            </p>

            <h5>
              Q. God. Siang, I need to know about your novel,{' '}
              <em>The Whitewash</em>. ASAP. I canceled all my meetings just to
              read it. Please, tell me more about it, the suspense is tearing me
              apart. I need copies, now, preferably multiple copies for some odd
              reason I can't quite remember.
            </h5>
            <p>
              A. Stop twisting our arm! You can read more about the book{' '}
              <a
                href="https://www.amazon.com.au/Whitewash-Siang-Lu/dp/070226573X/ref=sr_1_55?crid=2U05OEN21RL6F&amp;keywords=the+whitewash&amp;qid=1650017750&amp;sprefix=the+whitewash%252Caps%252C267&amp;sr=8-55&_encoding=UTF8&tag=sianglu-22&linkCode=ur2&linkId=bdecd62eed4b3d6cd9e513430f9ab762&camp=247&creative=1211"
                target="_blank"
                rel="noreferrer"
              >
                here
              </a>
              , but let it be known that we share the link only with great,
              really tremendous reluctance.
            </p>
            <p>
              If you have a rights query related to <em>The Whitewash</em>,
              please reach out to{' '}
              <a href="mailto:hayley.tomlinson@uqp.com.au">
                hayley.tomlinson@uqp.com.au
              </a>
              .
            </p>

            <h5>
              Q. Can I or my firm commission The Beige Index for analytics and
              data visualisations using a bespoke film dataset attached to our:
            </h5>
            <ul>
              <li>Streaming service catalogue</li>

              <li>Individual film production</li>

              <li>Academic research</li>

              <li>
                Event programming, such as university film courses and film
                festivals
              </li>
            </ul>
            <p className="answer">
              A. Yes. Just get in touch with us at info@thebeigeindex.com.
            </p>
            <br></br>

            <h5>Q. Why can't I find [Insert film title] on your site?</h5>
            <p className="answer">
              A. The current IMDb Top 250 user-voted films featured on The Beige
              Index was generated in June, 2022. [Insert film title] (great
              pick, by the way!) was likely not featured on the list at that
              time.
            </p>

            <h5>
              Q. Why can’t you use the American spelling for words on your site,
              like ‘color’, ‘labor', and ‘aggrandizement’?
            </h5>
            <p className="answer">A. Because we’re Australian.</p>

            <h5>
              Q. Well it’s making me very uncomfortable. Could you{' '}
              <em>change</em> the spelling to be Americanised?
            </h5>
            <p className="answer">A. No.</p>

            <h5>
              Q. How do you count the ethnicities of Wookies, Ewoks, Orcs, etc.?
            </h5>
            <p className="answer">
              A. We go by the ethnicity of the actor playing the role, not the
              character. You can learn more by reading about our methodology{' '}
              <Link
                onclick={() => {
                  document.getElementById('shelf').scrollIntoView({
                    behavior: 'smooth',
                    block: 'start',
                    inline: 'center',
                  });
                }}
                to={'/film/about'}
              >
                here
              </Link>
              .
            </p>

            <h5>Q. What's your favourite film out of the Top 250?</h5>
            <p className="answer">A.</p>
            <br></br>
            <br></br>
            <ul>
              <li>
                Siang:{' '}
                <Link
                  to="/film/tt6751668"
                  onClick={() => this.props.selectFilm('tt6751668')}
                >
                  <em>Parasite</em> (2019)
                </Link>{' '}
              </li>

              <li>
                Jon:{' '}
                <Link
                  to="/film/tt0469494"
                  onClick={() => this.props.selectFilm('tt0469494')}
                >
                  <em>There Will Be Blood</em> (2007)
                </Link>{' '}
              </li>

              <li>
                Alan:{' '}
                <Link
                  to="/film/tt0015324"
                  onClick={() => this.props.selectFilm('tt0015324')}
                >
                  <em>Sherlock Jr.</em> (1924)
                </Link>{' '}
                , because 'it's the least long one'.
              </li>
            </ul>
          </div>
        </div>
      </Paper>
    </>
  );
}
