import React from 'react';
import { Link } from 'react-router-dom';
import MediaQuery, { useMediaQuery } from 'react-responsive';
import logo from '../assets/TBI_Logo_Dist.svg';
import desktoplogo from '../assets/index2.svg';
import './navbar.scss';
import { Helmet } from 'react-helmet-async';

export default function NavBar(props) {
  let isMobileUser = useMediaQuery({ query: '(max-width:1000px)' });

  const getLogo = () => {
    if (isMobileUser) {
      return logo;
    } else {
      return desktoplogo;
    }
  };

  return (
    <React.Fragment>
      <Helmet>
        <script src="https://code.iconify.design/2/2.2.1/iconify.min.js"></script>
      </Helmet>
      <nav className="navbar-wrapper">
        <div className="navbar-container">
          <MediaQuery minWidth={1000}>
            <div className="navbar-title">
              <Link
                to={`/film`}
                replace={true}
                onClick={() => {
                  let selectedSpine = document.querySelector(
                    '.film-spine-flexbox-wrapper-active'
                  );
                  if (selectedSpine) {
                    selectedSpine.className = 'film-spine-flexbox-wrapper';
                  }
                  // if (isMobileUser) {
                  document.getElementById('shelf').scrollIntoView({
                    behavior: 'smooth',
                    block: 'start',
                    inline: 'center',
                  });
                  // }
                }}
              >
                <h2
                  className="navbar-text"
                  style={{ color: '#2b2b2b', lineHeight: '1em' }}
                >
                  THE BEIGE INDEX
                </h2>
              </Link>
            </div>
          </MediaQuery>
          <div className="navbar-logo">
            <div className="navbar-brand">
              <Link to={`/film`} replace={true}>
                <img
                  src={getLogo()}
                  alt="The Beige Index"
                  width="100px"
                  onClick={() => {
                    let selectedSpine = document.querySelector(
                      '.film-spine-flexbox-wrapper-active'
                    );
                    if (selectedSpine) {
                      selectedSpine.className = 'film-spine-flexbox-wrapper';
                    }
                    // if (isMobileUser) {
                    document.getElementById('shelf').scrollIntoView({
                      behavior: 'smooth',
                      block: 'start',
                      inline: 'center',
                    });
                    // }
                  }}
                ></img>
              </Link>
            </div>
          </div>
          <div className="navbar-menu">
            <p className="navtext">
              <Link
                to={'/film/faq'}
                alt="Frequently asked questions"
                onClick={() => {
                  let selectedSpine = document.querySelector(
                    '.film-spine-flexbox-wrapper-active'
                  );
                  if (selectedSpine) {
                    selectedSpine.className = 'film-spine-flexbox-wrapper';
                  }
                  // if (isMobileUser) {
                  document.getElementById('main-content').scrollIntoView({
                    behavior: 'smooth',
                    block: 'start',
                    inline: 'center',
                  });
                  // }
                }}
              >
                FAQ
              </Link>
              <br></br>
              <Link
                to={'/film/about'}
                alt="About The Beige Index"
                onClick={() => {
                  let selectedSpine = document.querySelector(
                    '.film-spine-flexbox-wrapper-active'
                  );
                  if (selectedSpine) {
                    selectedSpine.className = 'film-spine-flexbox-wrapper';
                  }
                  // if (isMobileUser) {
                  document.getElementById('main-content').scrollIntoView({
                    behavior: 'smooth',
                    block: 'start',
                    inline: 'center',
                  });
                  // }
                }}
              >
                More Info
              </Link>
              <br></br>
              <Link
                to={'/film/supporters'}
                alt="Supporters of The Beige Index"
                onClick={() => {
                  let selectedSpine = document.querySelector(
                    '.film-spine-flexbox-wrapper-active'
                  );
                  if (selectedSpine) {
                    selectedSpine.className = 'film-spine-flexbox-wrapper';
                  }
                  // if (isMobileUser) {
                  document.getElementById('main-content').scrollIntoView({
                    behavior: 'smooth',
                    block: 'start',
                    inline: 'center',
                  });
                  // }
                }}
              >
                Credited Cast
              </Link>
              <br></br>
              <Link
                to={'/film/contact'}
                alt="Contact The Beige Index"
                onClick={() => {
                  let selectedSpine = document.querySelector(
                    '.film-spine-flexbox-wrapper-active'
                  );
                  if (selectedSpine) {
                    selectedSpine.className = 'film-spine-flexbox-wrapper';
                  }
                  // if (isMobileUser) {
                  document.getElementById('main-content').scrollIntoView({
                    behavior: 'smooth',
                    block: 'start',
                    inline: 'center',
                  });
                  // }
                }}
              >
                Contact
              </Link>
              <br></br>
            </p>
          </div>
        </div>
      </nav>
    </React.Fragment>
  );
}
