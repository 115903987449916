import React, { useCallback, useState, useEffect } from 'react';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import { Suspense } from 'react';

// import styles
import './App.scss';

// import graphics
import './logo.svg';

// import components
import TopMenu from './components/topmenu';
import ShelfVert from './components/shelf_vert';
import BeigeChartJS from './components/chartview_chartjs';
import TableView from './components/tableview';

// import helper functions

// import data
import { chartJSData } from './data/chartdata';
import { viewData } from './data/viewdata';
import { sortData } from './data/sortdata';
import { filmSort } from './data/filmsort';
import { CSSTransition, TransitionGroup } from 'react-transition-group';

// import pages w/ code splitting
import TheWhitewash from './pages/thewhitewash';
import Contact from './pages/contact';
import FAQ from './pages/FAQ';
import About from './pages/about';
import FilmInfoRouter from './pages/filminfo_router';
import Supporters from './pages/supporters';

export default function App() {
  const films = filmSort;
  const sortOptions = sortData;
  const viewOptions = viewData;

  const [films_shelf, setFilmsShelf] = useState(films);
  const [chartData, setChartData] = useState(chartJSData);

  const [selectedFilm, setSelectedFilm] = useState(0);
  const [selectedFilmIndex, setSelectedFilmIndex] = useState(null);
  const [selectedFilmID, setSelectedFilmID] = useState(null);
  const [selectedView, setSelectedView] = useState('shelf');
  const [selectedField, setSelectedField] = useState('release_year');
  const [selectedField1, setSelectedField1] = useState('beige_score');

  let navigate = useNavigate();
  let isMobileUser = useMediaQuery({ query: '(max-width:1000px)' });

  const handleX = (xField) => {
    if (selectedFilm.imdb_id && selectedView === 'shelf') {
      let previousFilm = document.getElementById(selectedFilm.imdb_id);
      previousFilm.className = 'film-spine-flexbox-wrapper';
    }
    const sortArray = [...films];
    //lexicographical sorting
    if (xField === 'title') {
      sortArray.sort((a, b) => a[xField].localeCompare(b[xField]));
    } else {
      //numeric sorting
      sortArray.sort((a, b) => a[xField] - b[xField]);
    }
    // const newFilmsShelf = sortArray.filter((film) => {
    //   return film[xField] !== null;
    // });
    const newFilmsShelf = sortArray;

    setFilmsShelf(newFilmsShelf);
    setSelectedField(xField);
    setChartJSData(xField, selectedField1, newFilmsShelf);
  };

  const handleY = (yField) => {
    const sortArray = [...films];
    //lexicographical sorting
    if (yField === 'title') {
      sortArray.sort((a, b) => a[yField].localeCompare(b[yField]));
    } else {
      //numeric sorting
      sortArray.sort((a, b) => a[yField] - b[yField]);
    }

    const newFilmsShelf = sortArray;

    setSelectedField1(yField);
    setChartJSData(selectedField, yField, newFilmsShelf);
  };

  const setChartJSData = (xField, yField, newFilmsShelf) => {
    const newChartData = [];

    const fields = [xField, yField];
    const axes = ['x', 'y'];

    films.forEach((film) => {
      const coords = {};

      fields.forEach((field, index) => {
        if (field === 'title') {
          coords[axes[index]] = newFilmsShelf.findIndex(
            (index) => index.imdb_id === film.imdb_id
          );
        } else {
          coords[axes[index]] = film[field];
        }
      });

      const point = {};
      point.label = film['title'];
      point.backgroundColor = film['beige_color'];
      point.borderColor = 'rgba(245, 245, 245, 0.25)';
      point.imdb_id = film['imdb_id'];
      point.data = [coords];
      if (coords.x && coords.y && point.label) {
        newChartData.push(point);
      }
    });

    const newChartDataObj = { datasets: newChartData };
    setChartData(newChartDataObj);
  };

  const handleShelfSelect = (film) => {
    let newFilm = document.getElementById(film.imdb_id);
    if (selectedFilm.imdb_id) {
      let previousFilm = document.getElementById(selectedFilm.imdb_id);
      previousFilm.className = 'film-spine-flexbox-wrapper';
    }
    if (film.imdb_id === selectedFilm.imdb_id) {
      newFilm.className = 'film-spine-flexbox-wrapper';
      setSelectedFilm(0);
      setSelectedFilmIndex(null);
      navigate(`/film`, { replace: true });
    } else {
      newFilm.className = 'film-spine-flexbox-wrapper-active';
      if (isMobileUser) {
        newFilm.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
          inline: 'center',
        });
      }
    }
  };

  const handleSelectID = (filmID) => {
    const selectedFilmNew = films_shelf.find(
      ({ imdb_id }) => imdb_id === filmID
    );

    if (!selectedFilmNew) {
      navigate(`/error`, { replace: true });
      return;
    }

    if (selectedFilmNew.imdb_id === selectedFilm.imdb_id) {
      setSelectedFilm(0);
      setSelectedFilmIndex(null);
      navigate(`/film`, { replace: true });
    } else if (selectedFilmNew.imdb_id) {
      setSelectedFilm(selectedFilmNew);
      setSelectedFilmIndex(films_shelf.indexOf(selectedFilmNew));
      navigate(`/film/${selectedFilmNew.imdb_id}`, { replace: true });
      setSelectedFilmID(selectedFilmNew.imdb_id);
    } else {
      setSelectedFilm(0);
      setSelectedFilmIndex(null);
      navigate(`/film`, { replace: true });
    }
    if (selectedView === 'shelf') {
      handleShelfSelect(selectedFilmNew);
    }
  };

  const handleLeft = () => {
    console.log('handleLeft selectedFilmIndex = ' + selectedFilmIndex);
    let selectNewFilmIndex = selectedFilmIndex;
    if (selectNewFilmIndex == null) {
      selectNewFilmIndex = films_shelf.length - 1;
    } else if (selectedFilmIndex > 0) {
      selectNewFilmIndex--;
    } else {
      selectNewFilmIndex = films_shelf.length - 1;
    }
    let selectedFilm = films_shelf[selectNewFilmIndex];
    handleSelectID(selectedFilm.imdb_id);
  };

  const handleRandom = () => {
    const randomFilm =
      films_shelf[Math.floor(Math.random() * films_shelf.length)];
    handleSelectID(randomFilm.imdb_id);
  };

  const handleRight = () => {
    let selectNewFilmIndex = selectedFilmIndex;
    if (selectNewFilmIndex == null) {
      selectNewFilmIndex = 0;
    } else if (selectedFilmIndex < films_shelf.length - 1) {
      selectNewFilmIndex++;
    } else {
      selectNewFilmIndex = 0;
    }
    let selectedFilm = films_shelf[selectNewFilmIndex];
    handleSelectID(selectedFilm.imdb_id);
  };

  // Keyboard controls
  const handleKeyPress = useCallback(
    (event) => {
      switch (event.key) {
        case 'ArrowLeft':
          console.log('left!');
          return handleLeft();
        case 'ArrowRight':
          return handleRight();
        case '/':
          return handleRandom();
        default:
          return;
      }
    },
    [selectedFilmIndex]
  );

  useEffect(() => {
    document.addEventListener('keydown', handleKeyPress);

    return () => {
      document.removeEventListener('keydown', handleKeyPress);
    };
  }, [handleKeyPress]);

  const handleView = (viewValue) => {
    setSelectedView(viewValue);
  };

  const renderTopMenu = () => {
    return (
      <React.Fragment>
        <TopMenu
          sortOptions={sortOptions}
          viewOptions={viewOptions}
          selectedView={selectedView}
          selectedField={selectedField}
          selectedField1={selectedField1}
          setChartX={handleX}
          setChartY={handleY}
          selectView={handleView}
          selectRandom={handleRandom}
          selectLeft={handleLeft}
          selectRight={handleRight}
        />
      </React.Fragment>
    );
  };

  const renderView = (view) => {
    switch (view) {
      case 'shelf':
        return renderShelf();
      case 'chart':
        return renderChart();
      case 'table':
        return renderTable();
      default:
        return renderShelf();
    }
  };

  const renderShelf = () => {
    return (
      <React.Fragment>
        <ShelfVert
          films={films_shelf}
          selectedField={selectedField}
          selectFilm={handleSelectID}
          selectedFilm={selectedFilmID}
        />
      </React.Fragment>
    );
  };

  const renderChart = () => {
    return (
      <React.Fragment>
        <BeigeChartJS
          chartData={chartData}
          xLabel={selectedField}
          yLabel={selectedField1}
          selectFilm={handleSelectID}
        />
      </React.Fragment>
    );
  };

  const renderTable = () => {
    return (
      <React.Fragment>
        <TableView rows={films} selectFilm={handleSelectID} />
      </React.Fragment>
    );
  };

  const location = useLocation();

  return (
    <React.Fragment>
      <div className="ui-wrapper">
        <div className="ui-grid" onKeyDown={handleKeyPress}>
          {renderTopMenu()}
          {renderView(selectedView)}
        </div>
      </div>
      <div id="main-content">
        <TransitionGroup component={null}>
          <CSSTransition key={location.key} classNames="fade" timeout={300}>
            <Routes location={location}>
              <Route
                path=":id"
                element={
                  // <Suspense fallback={<div>Loading...</div>}>
                  <FilmInfoRouter
                    selectRandom={handleRandom}
                    selectLeft={handleLeft}
                    selectRight={handleRight}
                    selectFilm={handleSelectID}
                    selectedFilmID={selectedFilmID}
                  />
                  // </Suspense>
                }
              />
              <Route
                path="the-whitewash"
                element={
                  // <Suspense fallback={<div>Loading...</div>}>
                  <TheWhitewash
                    selectRandom={handleRandom}
                    selectLeft={handleLeft}
                    selectRight={handleRight}
                    selectFilm={handleSelectID}
                    selectedFilmID={0}
                  />
                  /* </Suspense> */
                }
              />
              <Route
                path="about"
                element={
                  // <Suspense fallback={<div>Loading...</div>}>
                  <About
                    title={'About The Beige Index'}
                    selectRandom={handleRandom}
                    selectLeft={handleLeft}
                    selectRight={handleRight}
                    selectFilm={handleSelectID}
                    selectedFilmID={0}
                  />
                  /* </Suspense> */
                }
              />
              <Route
                path="faq"
                element={
                  // <Suspense fallback={<div>Loading...</div>}>
                  <FAQ
                    title={'Frequently Asked Questions'}
                    selectRandom={handleRandom}
                    selectLeft={handleLeft}
                    selectRight={handleRight}
                    selectFilm={handleSelectID}
                    selectedFilmID={0}
                  />
                  // </Suspense>
                }
              />
              <Route
                path="contact"
                element={
                  // <Suspense fallback={<div>Loading...</div>}>
                  <Contact
                    title={'Contact The Beige Index Team'}
                    selectRandom={handleRandom}
                    selectLeft={handleLeft}
                    selectRight={handleRight}
                    selectFilm={handleSelectID}
                    selectedFilmID={0}
                  />
                  // </Suspense>
                }
              />
              <Route
                path="supporters"
                element={
                  // <Suspense fallback={<div>Loading...</div>}>
                  <Supporters
                    title={'Support The Beige Index Team'}
                    selectRandom={handleRandom}
                    selectLeft={handleLeft}
                    selectRight={handleRight}
                    selectFilm={handleSelectID}
                    selectedFilmID={0}
                  />
                  // </Suspense>
                }
              />
            </Routes>
          </CSSTransition>
        </TransitionGroup>
      </div>
    </React.Fragment>
  );
}
