import React from 'react';
import { Paper } from '@mui/material';
import { Helmet } from 'react-helmet-async';

import './about.scss';

export default function About(props) {
  return (
    <React.Fragment>
      <Helmet>
        <title>The Beige Index – About</title>
      </Helmet>
      <Paper elevation={3} className="about-paper-container">
        <div
          className="about-container"
          itemScope={true}
          itemType={'http://schema.org/Article'}
          id="page"
        >
          <div className="header">
            <center>
              <h1 id="header" itemProp={'title'}>
                About The Beige Index
              </h1>
            </center>
          </div>
          <div itemProp={'articleBody'}>
            <h2>Methodology</h2>
            <p>
              This site began as a 'what if' joke between two <del>lovers</del>{' '}
              <del>ex-lovers</del> friends, but quickly turned into a rather
              more rigorous endeavour. We accepted the challenge of making the
              site a reality, and did what we knew we had to do: hire an intern
              with a crazy amount of skeletons in the closet (see: leverage) to
              do the dirty work.
            </p>
            <p>First, we had to define the dataset.</p>
            <h2>Dataset Overview</h2>
            <table>
              <thead>
                <tr>
                  <th colSpan="2">Quick Facts</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Films</td>
                  <td>250</td>
                </tr>
                <tr>
                  <td>Actors</td>
                  <td>9577</td>
                </tr>
                <tr>
                  <td>Watchtime</td>
                  <td>22 days, 11hrs</td>
                </tr>
              </tbody>
            </table>
            <table>
              <thead>
                <tr>
                  <th colSpan="2">Beige Score Distributions</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Creamy</td>
                  <td>60.8%</td>
                </tr>
                <tr>
                  <td>Beige Fever</td>
                  <td>30%</td>
                </tr>
                <tr>
                  <td>Middlebeige</td>
                  <td>3.2%</td>
                </tr>
                <tr>
                  <td>Down to Brown</td>
                  <td>2.8%</td>
                </tr>
                <tr>
                  <td>Welcome to Beigewatch</td>
                  <td>3.2%</td>
                </tr>
              </tbody>
            </table>
            <h3>The IMDb Top 250</h3>
            <p>
              We decided to hitch our wagon to IMDb's Top 250 User Rated films.
              This list, according to the movie-watching, IMDb-voting public,
              represents the best cinematic touchstones from the 1920s to the
              2020s. Even if you hate watching movies, like Alan (The Beige
              Index Intern), chances are you're familiar with a great many of
              these titles. By and large, they're the films future generations
              are destined to remember fifty years from now, in the coming
              utopia (dystopia if you're racist) when every film will have a
              minimum beige-o-meter score of BEIGE FEVER.
            </p>
            <h3>Credited Cast Only</h3>
            <p>
              To keep things feasible and replicable, only credited actors were
              included in our dataset. Extras, even if they had lines, were not
              included. When in doubt, we trusted the IMDb credited cast list.
            </p>
            <h3>Taxonomy of Ethnic Groups</h3>
            <p>Oh no! What a terrible header.</p>
            <p>
              Anyway. We had no choice. No choice! The people wanted, nay,{' '}
              <em>needed</em> this. So yeah, Alan designed a taxonomy of ethnic
              groups.
            </p>
            <table>
              <thead>
                <tr>
                  <th>Grouping</th>
                  <th>Inclusions</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>White</td>
                  <td>
                    Anglo-Saxon, Southern, Central and Eastern European, Celtic,
                    the Caucasus, Gallic and Semitic descent
                  </td>
                </tr>
                <tr>
                  <td>North Asian</td>
                  <td>Mongolian descent</td>
                </tr>
                <tr>
                  <td>East Asian</td>
                  <td>Chinese, Japanese and Korean descent</td>
                </tr>
                <tr>
                  <td>Southeast Asian</td>
                  <td>
                    Indochinese, Malaysian, Thailand, Vietnamese, Indonesian,
                    Filipino and Singaporean descent
                  </td>
                </tr>
                <tr>
                  <td>Latin American</td>
                  <td>
                    Mexican, Guatemalan, Honduran, El Salvadorian, Nicaraguan,
                    Costa Rican, Panamanian, Colombian, Venezuelan, Ecuadorian,
                    Peruvian, Bolivian, Brazilian, Paraguayan, Chilean,
                    Argentinean, Uruguayan, Cuban, Haitian, Dominican and Puerto
                    Rican descent
                  </td>
                </tr>
                <tr>
                  <td>Native American</td>
                  <td>
                    First Nations groups from the Americas such as the
                    Algonquin, Iroquois, Huron, Wampanoag, Mohican, Mohegan,
                    Ojibwa, Ho-chunk, Sauk, Fox and Illinois
                  </td>
                </tr>
                <tr>
                  <td>Pacific Islander</td>
                  <td>
                    Native Hawaiian, Samoan, Gumanian or Chamorro, Fijian,
                    Tongan, Marshallese, Melanesian, Micronesian and Polynesian
                    descent
                  </td>
                </tr>
                <tr>
                  <td>Middle Eastern</td>
                  <td>
                    Arab, Kurd, Persian, Turk, Azerbaijan, Aramean, Assyrian,
                    Armenian, Bahrani, Baloch, Copts, Druze, Gilaks, Laz, Lurs,
                    Mandaean, Maronite, Mazanderani, Mhallami, Nawar, Rûm,
                    Samaritan, Shabak, Talysh, Tat and Zaza descent
                  </td>
                </tr>
                <tr>
                  <td>South Asian</td>
                  <td>
                    Bangladeshi, Bhutanese, Indian, Maldivean, Nepalese,
                    Pakistani and Sri Lankan descent
                  </td>
                </tr>
                <tr>
                  <td>Indigenous Australian</td>
                  <td>
                    First Nations groups including, but not limited to, the
                    Anangu, Goorie, Koorie, Murri, Nunga, Noongar, Palawan, Tiwi
                  </td>
                </tr>
                <tr>
                  <td>Black</td>
                  <td>
                    Nigerian, Ethiopian, Egyptian, Congolese, South African,
                    Tanzanian, Kenyan, Ugandan, Algerian, Sudanese, Moroccan,
                    Angolese, Ghanian, Mozambican, Madagascan, Cameroonian, Côte
                    d'Ivoirian, Nigerian, Burkinanabe, Malian, Malawian,
                    Zambian, Senegalese, Chadian, Somalian, Zimbabwean, Guinean,
                    Rwandan, Beninese, Tunisian, Aburundi, South Sudanese,
                    Togolese, Sierra Leonian, Libyan, Congolese, Liberian,
                    Central African, Mauritanian, Eritrean, Namibian, Gambian,
                    Botswanan, Gabonese, Basotho, Bissau-Guinean, Equatoguinean,
                    Mauritian, Emaswati, Djiboutian, Réunionese, Comorian,
                    Western Saharan, Cabo Verdean, Maorais, Santomean,
                    Seychellois and Saint Helenian descent
                  </td>
                </tr>
              </tbody>
            </table>
            <p>
              Oh sure, it looks easy in table form. But consider the brief
              existential crisis we were forced to have about Italian, Greek,
              Spanish, Jewish, Egyptian and other ethnicities, until we decided
              to focus on things we <em>could</em> control, like our own mental
              health.
            </p>
            <h3>Actor Research</h3>
            <p>
              We performed tiered research to determine and record each actor's
              ethnicity.
            </p>
            <table>
              <thead>
                <tr>
                  <th>Tier</th>
                  <th>Inclusions</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>1</td>
                  <td>
                    <ul>
                      <li>IMDb biographical information</li>
                      <li>Other publicly available biographical information</li>
                      <li>First person interview responses</li>
                      <li>
                        Inclusion in a particular group (eg. Hispanic
                        Organization of Latin Actors)
                      </li>
                      <li>Obituaries</li>
                    </ul>
                  </td>
                </tr>
                <tr>
                  <td>2</td>
                  <td>
                    <ul>
                      <li>Subjective skin tone assessment from the film</li>
                      <li>Subjective skin tone assessment from other media</li>
                      <li>Name/Surname of actor</li>
                      <li>Third person interview responses</li>
                    </ul>
                  </td>
                </tr>
              </tbody>
            </table>
            <p>
              While many actors had a Tier 1 item available, a large number did
              not. In the absence of Tier 1 information, two or more Tier 2
              items were used to verify ethnicity.
            </p>
            <p>
              Two other metadata tags were introduced to account for actors for
              which no conclusive verification could be determined:
            </p>
            <ul>
              <li>
                <strong>Unverifiable:</strong> Most often assigned to actors for
                which no or insufficient publicly available biographical
                information existed, and insufficient information existed to
                visually validate identity within the film. These actors were
                not included in the database.
              </li>
              <li>
                <strong>Indeterminately beige:</strong> Most often assigned to
                actors who were able to be visually identified within the film
                but for which no or insufficient publicly available biographical
                information existed and, in combination with any other tier 2
                criteria, whom our assessor, Alan, was nonetheless satisfied
                were non-White.
              </li>
            </ul>
            <p>
              If biographical information was available indicating an actor was
              of two or more ethnicities, each ethnicity was included in the
              metadata tagging with equal weight.
            </p>
            <h3>Numerical Beigeness</h3>
            <p>
              Remember earlier, when we said that Italians give us a headache?
              You know, on account of the whole are-they-white thing. Well thank
              God they made it up to us.
            </p>
            <p>
              Enter{' '}
              <a
                href="https://www.treccani.it/enciclopedia/renato-biasutti_(Dizionario-Biografico)/"
                target="_blank"
                rel="noreferrer"
              >
                Renato Biasutti
              </a>
              , the esteemed Italian geographer who used{' '}
              <a
                href="https://en.wikipedia.org/wiki/Von_Luschan%27s_chromatic_scale"
                target="_blank"
                rel="noreferrer"
              >
                the von Luschan Scale
              </a>
              —at the time of his work, a set of 36 tinted glass dowels—to
              classify the skintones of <em>all humans</em>. Arrogant stuff,
              really. His dataset was <em>the world</em>. All <em>we </em>
              covered was 10,000 actors.
            </p>
            <div className="image-wrapper">
              <img
                src="https://thebeigeindex-images.s3.ap-southeast-2.amazonaws.com/assets/biasutti.jpg"
                alt="Biasutti's wonderful map"
                width="100%"
              />
            </div>
            <p>
              Interestingly enough, while this system of classification sounds
              relatively crude, and{' '}
              <a
                href="https://www.annualreviews.org/doi/abs/10.1146/annurev.anthro.33.070203.143955"
                target="_blank"
                rel="noreferrer"
              >
                isn't perfect
              </a>
              , the von Luschan Scale is{' '}
              <a
                href="https://www.researchgate.net/publication/9055512_Barsh_GSWhat_controls_variation_in_human_skin_color_PLoS_Biol_1E27"
                target="_blank"
                rel="noreferrer"
              >
                still used by dermatologists today
              </a>{' '}
              as a proxy for spectrophotometry. And a 2014{' '}
              <a
                href="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC4103266/"
                target="_blank"
                rel="noreferrer"
              >
                study in the Indian Journal of Dermatology
              </a>{' '}
              showed that this proxy is effective in a clinical setting. We're
              not completely full of crap, is what we're saying.
            </p>
            <p>
              Here is our table of numerical beigeness taxonomy to numerical
              score, derived from the work of Biasutti and von Luschan:
            </p>
            <table>
              <thead>
                <tr>
                  <th>Grouping</th>
                  <th>Score</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>White</td>
                  <td>12</td>
                </tr>
                <tr>
                  <td>North Asian</td>
                  <td>14</td>
                </tr>
                <tr>
                  <td>East Asian</td>
                  <td>15</td>
                </tr>
                <tr>
                  <td>Indeterminately beige</td>
                  <td>18</td>
                </tr>
                <tr>
                  <td>Latin American</td>
                  <td>20</td>
                </tr>
                <tr>
                  <td>Native American</td>
                  <td>20</td>
                </tr>
                <tr>
                  <td>Southeast Asian</td>
                  <td>20</td>
                </tr>
                <tr>
                  <td>Middle Eastern</td>
                  <td>22</td>
                </tr>
                <tr>
                  <td>Pacific Islander</td>
                  <td>22</td>
                </tr>
                <tr>
                  <td>South Asian</td>
                  <td>24</td>
                </tr>
                <tr>
                  <td>Indigenous Australian</td>
                  <td>28</td>
                </tr>
                <tr>
                  <td>Black</td>
                  <td>30</td>
                </tr>
              </tbody>
            </table>
            <p>
              You'll see that the numbers are selected from within their ranges
              as laid out by Biasutti, though not always consistently. You'll
              note that White sits at the end of its range, while Black sits in
              the middle. Why is this? Because—
            </p>
            <h3>The Beige Score is Just an Average</h3>
            <p>
              Yep. There are no complicated algorithms here. The most
              complicated equation in the whole project is used by the backend
              to calculate the angle on the Beige-o-Meter™.
            </p>
            <p>
              Using an average, though, meant we needed to adjust the final
              numbers in order to ensure that any given value didn't affect the
              final score too much. For instance: set the White score to the
              Biasutti/von Luschan midpoint of 6, and almost every film becomes
              Creamy. Set the Black score to 36, and the IMDb Top 250 looks a
              lot more beige than it actually is. The final numbers were chosen
              to visually reflect the dataset as accurately as possible.
            </p>
            <p>
              We are of course aware that more complex models are possible for
              this sort of data. An ideal version of The Beige Index would
              calculate a beige score per frame, and then average that out
              across a whole film. We managed to get Alan to do this for a
              single film, <em>Once Upon a Time in America</em>, and he came as
              close to <del>escaping </del>quitting as he ever has. Someday,
              there may be the potential to use{' '}
              <a
                href="https://techcrunch.com/2022/05/11/google-new-10-shade-skin-tone-scale/"
                target="_blank"
                rel="noreferrer"
              >
                Google's Monk Skin Tone scale
              </a>{' '}
              combined with machine-driven frame analysis to calculate an
              extremely accurate Beige Score for every film in history (or at
              least all the ones available on Google Play). But that is out of
              our scope, for now.
            </p>
            <p>
              Weighting cast members by billing sounds attractive as a middle
              ground between our approach and the above hypothetical, until you
              realise that billing is as much tied to marketing more as it is to
              screentime—a recent example of this being Zendaya's appearance in{' '}
              <em>Dune </em>for seven of a total 155 minutes, despite her
              position on the cast list. As such, we have made do with an
              equally-weighted credited-cast average. And you will make do too,
              because—
            </p>
            <h3>We Watched Every Film</h3>
            <p>
              Did we mention this? We mentioned this, right? We watched the
              films. Yes, all of them. Our wallets are still hurting from the
              process, but arguably we're better, more well-rounded people as a
              result. Arguably, we said!
            </p>
            <p>
              As we continue to update the dataset, we will continue to watch
              and research the new additions, and mourn old titles from the Top
              250 that no longer make the cut.
            </p>
            <p>
              If you have any questions or complaints about the above
              methodology, do feel free to reach out directly to Alan at{' '}
              <a href="mailto:resetpassword@thebeigeindex.com">
                resetpassword@thebeigeindex.com
              </a>
              .
            </p>
          </div>
        </div>
      </Paper>
    </React.Fragment>
  );
}
