export const whitewashData = {
  imdb_id: 'the-whitewash',
  title: 'The Whitewash',
  beige_score: 14.25,
  beige_angle: 38.25,
  beige_angle_string: '38.25 111.63 111.37',
  release_year: 2022,
  beige_color: '#f5e3cb',
  imdb_user_rating: null,
  imdb_user_ranking: null,
  budget_amount: null,
  gross_amount: null,
  metascore: null,
  runtime: null,
  cast_count: 16,
  video:
    'https://thebeigeindex-images.s3.ap-southeast-2.amazonaws.com/assets/whitewash.mp4',
  image:
    'https://thebeigeindex-images.s3.ap-southeast-2.amazonaws.com/assets/the-whitewash.jpg',
  amazon:
    'https://www.amazon.com.au/Whitewash-Siang-Lu/dp/070226573X/ref=sr_1_55?crid=2U05OEN21RL6F&amp;keywords=the+whitewash&amp;qid=1650017750&amp;sprefix=the+whitewash%252Caps%252C267&amp;sr=8-55&_encoding=UTF8&tag=sianglu-22&linkCode=ur2&linkId=bdecd62eed4b3d6cd9e513430f9ab762&camp=247&creative=1211',
  justwatch: null,
  cast: [
    [2, 'JK Jr'],
    [2, 'Angela Mu'],
    [2, 'LeBron Chew'],
    [2, 'Hetty Lin'],
    [2, 'Wong Kim Ark'],
    [2, 'Yolo Zhang'],
    [2, 'Johnny Chao'],
    [2, 'Buddy Chao'],
    [2, 'Ringo Chao'],
    [2, 'Baby Bao'],
    [2, 'Miranda Tsai'],
    [2, 'Farmstrong Tian'],
    [1, "Damon 'Damo' Smith"],
    [1, 'Eric Dutton'],
    [1, 'Henry Lavida'],
    [1, 'Chase Donovan'],
  ],
  directors: 'Siang Lu',
  budget_string: null,
  gross_string: null,
};
