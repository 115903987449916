import React, { useState } from 'react';

import Ad from './ad';
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import Instagram from '@mui/icons-material/Instagram';
import IconButton from '@mui/material/IconButton';

import { Link } from 'react-router-dom';

import Box from '@mui/material/Box';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import { Button } from '@mui/material';

import './footer.scss';

import oc_logo from '../assets/oc_tbi.png';

// Configure API for email collection
import { Amplify, API, graphqlOperation, Auth } from 'aws-amplify';
import awsExports from '../aws-exports';

import { createEmail } from '../graphql/mutations';

Amplify.configure(awsExports);
Auth.configure(awsExports);

export default function Footer(props) {
  const [formState, setFormState] = useState();
  const [emailIsInvalid, setEmailIsInvalid] = useState(false);
  const [emailHelperText, setEmailHelperText] = useState(
    'Join our mailing list. Enter your email'
  );
  const [formIsDisabled, setFormIsDisabled] = useState(false);

  const validateEmail = (email) => {
    return email.match(
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
  };

  const generateKey = () => {
    return (
      Math.floor(Math.random() * 1000000).toString(36) + Date.now().toString()
    );
  };

  async function addContact() {
    if (validateEmail(formState)) {
      const data = {
        input: {
          id: generateKey(),
          email: formState,
        },
      };
      console.log(data);
      await API.graphql(graphqlOperation(createEmail, data));
      // console.log(apiData);
      setEmailHelperText('Thank you for joining the TBI Mailing List!');
      setFormIsDisabled(true);
    } else {
      setEmailHelperText('Email not valid. Try again.');
      setEmailIsInvalid(true);
    }
  }

  const emailForm = (event) => {
    return (
      <Box
        component="form"
        sx={{
          '& .MuiTextField-root': { m: 1, width: '25ch' },
        }}
        noValidate
        autoComplete="off"
        onSubmit={(e) => {
          e.preventDefault();
          addContact();
        }}
      >
        <TextField
          id="outlined-required"
          label={emailHelperText}
          required
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <MailOutlineIcon sx={{ color: '#f5f5f5' }} />
              </InputAdornment>
            ),
          }}
          variant="standard"
          placeholder="info@thebeigeindex.com"
          onChange={(e) => setFormState(e.target.value)}
          onSubmit={addContact}
          error={emailIsInvalid}
          disabled={formIsDisabled}
        />

        <Button
          variant="outlined"
          color="warning"
          onClick={addContact}
          sx={{ marginBottom: '5px' }}
        >
          Sign up
        </Button>
      </Box>
    );
  };

  return (
    <div className="main-footer">
      <Ad display={true} />
      <center>
        {emailForm()}
        <p>
          #thebeigeindex&nbsp;
          <span className="footer-icons">
            <IconButton
              href="https://www.instagram.com/thebeigeindex/"
              target="_blank"
              rel="noreferrer"
            >
              <Instagram />
            </IconButton>
            <IconButton
              href="https://www.facebook.com/The-Beige-Index-105735455560993"
              target="_blank"
              rel="noreferrer"
            >
              <FacebookIcon />
            </IconButton>
            <IconButton
              href="https://twitter.com/BeigeIndex"
              target="_blank"
              rel="noreferrer"
            >
              <TwitterIcon />
            </IconButton>
            <IconButton
              href="mailto:info@thebeigeindex.com"
              target="_blank"
              rel="noreferrer"
            >
              <MailOutlineIcon />
            </IconButton>
            <IconButton
              href="https://www.patreon.com/thebeigeindex"
              target="_blank"
              rel="noreferrer"
            >
              <span className="iconify" data-icon="mdi:patreon" />
            </IconButton>
          </span>
        </p>
        <div className={'footer-base'}>
          <a
            href="https://www.ocreative.company"
            target="_blank"
            rel="noreferrer"
          >
            <img
              className="footer-ologo"
              src={oc_logo}
              style={{ marginTop: '0px', marginBottom: '20px' }}
              width="100px"
              alt="The Beige Index is a project by O'Creative."
              href="https://www.ocreative.company"
              target="_blank"
            />
          </a>
          <p>
            The Beige Index is a 2022 project by{' '}
            <a
              href="https://www.ocreative.company"
              target="_blank"
              rel="noreferrer"
            >
              O'Creative
            </a>
            .<br></br>
            This is version {props.version}.<br></br>
            <br></br>
          </p>
        </div>
      </center>
    </div>
  );
}
