export const filmInfo = [
  {
    "imdb_id": "tt0012349",
    "plot": "The Tramp cares for an abandoned child, but events put their relationship in jeopardy.",
    "certification": "Passed",
    "other_wins_count": 2,
    "other_noms_count": 0,
    "amazon": "https://www.amazon.com/Kid-Criterion-Collection-Blu-ray/dp/B0184DLJ36/ref=sr_1_3?crid=16F5KBCB7Y076&amp;keywords=the+kid+blu+ray&amp;qid=1655023078&amp;sprefix=the+kid+blu+ray%252Caps%252C294&amp;sr=8-3&_encoding=UTF8&tag=sianglu-20&linkCode=ur2&linkId=3f1dfe82dc51366d34d2400691311cd4&camp=1789&creative=9325",
    "justwatch": "https://justwatch.com/us/movie/charlie-chaplin-the-kid?utm_source=share&utm_medium=web",
    "beige_index": 1,
    "beige_angle": 0.0,
    "cast": [
      [
        1,
        "Henry Bergman"
      ],
      [
        1,
        "Charles Chaplin"
      ],
      [
        1,
        "Jackie Coogan"
      ],
      [
        1,
        "Carl Miller"
      ],
      [
        1,
        "Edna Purviance"
      ]
    ],
    "directors": "Charles Chaplin",
    "budget_string": "0.2m USD",
    "gross_string": "<1m USD",
    "metascore_string": "N/A"
  },
  {
    "imdb_id": "tt0015324",
    "plot": "A film projectionist longs to be a detective, and puts his meagre skills to work when he is framed by a rival for stealing his girlfriend's father's pocketwatch.",
    "certification": "Passed",
    "other_wins_count": 1,
    "other_noms_count": 0,
    "amazon": "https://www.amazon.com/Sherlock-Jr-Buster-Keaton/dp/B085RFSBMS/ref=sr_1_4?crid=352QVPERWJMG5&amp;keywords=sherlock+jr+blu+ray&amp;qid=1656405426&amp;sprefix=sherlock+jr+blu+ra%252Caps%252C287&amp;sr=8-4&_encoding=UTF8&tag=sianglu-20&linkCode=ur2&linkId=3df850148dd47651d194bd54ed56a352&camp=1789&creative=9325",
    "justwatch": "https://justwatch.com/us/movie/sherlock-jr?utm_source=share&utm_medium=web",
    "beige_index": 1,
    "beige_angle": 0.0,
    "cast": [
      [
        1,
        "Erwin Connelly"
      ],
      [
        1,
        "Ward Crane"
      ],
      [
        1,
        "Buster Keaton"
      ],
      [
        1,
        "Joe Keaton"
      ],
      [
        1,
        "Kathryn McGuire"
      ]
    ],
    "directors": "Buster Keaton",
    "budget_string": "N/A",
    "gross_string": "N/A",
    "metascore_string": "N/A"
  },
  {
    "imdb_id": "tt0015864",
    "plot": "A prospector goes to the Klondike during the 1890s gold rush in hopes of making his fortune, and is smitten with a girl he sees in a dance hall.",
    "certification": "Passed",
    "other_wins_count": 3,
    "other_noms_count": 1,
    "amazon": "https://www.amazon.com/Gold-Rush-Criterion-Collection-Blu-ray/dp/B007N5YJMU/ref=sr_1_1?crid=1YVNCC8SCM4A3&amp;keywords=the+gold+rush+blu+ray&amp;qid=1655182232&amp;sprefix=the+gold+rush+blu+ray%252Caps%252C282&amp;sr=8-1&_encoding=UTF8&tag=sianglu-20&linkCode=ur2&linkId=966c9727cebbeaa13c9b29b7fcb14d0a&camp=1789&creative=9325",
    "justwatch": "https://justwatch.com/us/movie/the-gold-rush?utm_source=share&utm_medium=web",
    "beige_index": 1,
    "beige_angle": 0.0,
    "cast": [
      [
        1,
        "Henry Bergman"
      ],
      [
        1,
        "Charles Chaplin"
      ],
      [
        1,
        "Georgia Hale"
      ],
      [
        1,
        "Tom Murray"
      ],
      [
        1,
        "Tiny Sandford"
      ],
      [
        1,
        "Mack Swain"
      ],
      [
        1,
        "Malcolm Waite"
      ]
    ],
    "directors": "Charles Chaplin",
    "budget_string": "0.9m USD",
    "gross_string": "<1m USD",
    "metascore_string": "N/A"
  },
  {
    "imdb_id": "tt0017925",
    "plot": "After being rejected by the Confederate military, not realizing it was due to his crucial civilian role, an engineer must single-handedly recapture his beloved locomotive after it is seized by Union spies and return it through enemy lines.",
    "certification": "Passed",
    "other_wins_count": 2,
    "other_noms_count": 1,
    "amazon": "https://www.amazon.com/General-Three-Ages-Blu-ray/dp/B01N1QYG7C/ref=sr_1_2?crid=MX6VD3GQSWTD&amp;keywords=the+general+blu+ray&amp;qid=1656405365&amp;sprefix=the+general+blu+%252Caps%252C339&amp;sr=8-2&_encoding=UTF8&tag=sianglu-20&linkCode=ur2&linkId=13cc7cdbb5a2aaf9835fc6471bbc05cd&camp=1789&creative=9325",
    "justwatch": "https://justwatch.com/au/movie/the-general?utm_source=share&utm_medium=web",
    "beige_index": 1,
    "beige_angle": 0.0,
    "cast": [
      [
        1,
        "Frank Barnes"
      ],
      [
        1,
        "Glen Cavender"
      ],
      [
        1,
        "Mike Donlin"
      ],
      [
        1,
        "Jim Farley"
      ],
      [
        1,
        "Frank Hagney"
      ],
      [
        1,
        "Charles Henry Smith"
      ],
      [
        1,
        "Buster Keaton"
      ],
      [
        1,
        "Joe Keaton"
      ],
      [
        1,
        "Marion Mack"
      ],
      [
        1,
        "Tom Nawn"
      ],
      [
        1,
        "Frederick Vroom"
      ]
    ],
    "directors": "Clyde Bruckman & Buster Keaton",
    "budget_string": "N/A",
    "gross_string": "N/A",
    "metascore_string": "N/A"
  },
  {
    "imdb_id": "tt0017136",
    "plot": "In a futuristic city sharply divided between the working class and the city planners, the son of the city's mastermind falls in love with a working-class prophet who predicts the coming of a savior to mediate their differences.",
    "certification": "Not Rated",
    "other_wins_count": 6,
    "other_noms_count": 7,
    "amazon": "https://www.amazon.com/Complete-Metropolis-Blu-ray-Brigitte-Helm/dp/B0040QYROK/ref=sr_1_1?crid=24837AR4QIWLJ&amp;keywords=metropolis+blu+ray&amp;qid=1655008050&amp;sprefix=metropolis+blu+ray%252Caps%252C285&amp;sr=8-1&_encoding=UTF8&tag=sianglu-20&linkCode=ur2&linkId=8930194258095eac8129d9b0d2b77b5e&camp=1789&creative=9325",
    "justwatch": "https://justwatch.com/us/movie/metropolis?utm_source=share&utm_medium=web",
    "beige_index": 1,
    "beige_angle": 0.0,
    "cast": [
      [
        1,
        "Alfred Abel"
      ],
      [
        1,
        "Erwin Biswanger"
      ],
      [
        1,
        "Gustav Fr\u00f6hlich"
      ],
      [
        1,
        "Heinrich George"
      ],
      [
        1,
        "Brigitte Helm"
      ],
      [
        1,
        "Georg John"
      ],
      [
        1,
        "Rudolf Klein-Rogge"
      ],
      [
        1,
        "Theodor Loos"
      ],
      [
        1,
        "Fritz Rasp"
      ]
    ],
    "directors": "Fritz Lang",
    "budget_string": "1.4m USD",
    "gross_string": "1.3m USD",
    "metascore_string": "98%"
  },
  {
    "imdb_id": "tt0019254",
    "plot": "In 1431, Jeanne d'Arc is placed on trial on charges of heresy. The ecclesiastical jurists attempt to force Jeanne to recant her claims of holy visions.",
    "certification": "Passed",
    "other_wins_count": 5,
    "other_noms_count": 2,
    "amazon": "https://www.amazon.com/Passion-Masters-Cinema-Format-Blu-ray/dp/B072131G21/ref=sr_1_2?crid=1CAEUZTOM22T9&amp;keywords=passion+of+joan+of+arc+blu+ray&amp;qid=1655184187&amp;sprefix=passion+of+joan+of+arc+blu+ray%252Caps%252C283&amp;sr=8-2&_encoding=UTF8&tag=sianglu-20&linkCode=ur2&linkId=a0bd70b5d24ccee464de8e1ca1b8d12e&camp=1789&creative=9325",
    "justwatch": "https://justwatch.com/us/movie/the-passion-of-joan-of-arc?utm_source=share&utm_medium=web",
    "beige_index": 1,
    "beige_angle": 0.0,
    "cast": [
      [
        1,
        "Jacques Arnna"
      ],
      [
        1,
        "Antonin Artaud"
      ],
      [
        1,
        "Andr\u00e9 Berley"
      ],
      [
        1,
        "Jean d'Yd"
      ],
      [
        1,
        "Maria Falconetti"
      ],
      [
        1,
        "L\u00e9on Larive"
      ],
      [
        1,
        "Armand Lurville"
      ],
      [
        1,
        "Alexandre Mihalesco"
      ],
      [
        1,
        "Louis Ravet"
      ],
      [
        1,
        "Maurice Schutz"
      ],
      [
        1,
        "Eugene Silvain"
      ],
      [
        1,
        "Michel Simon"
      ]
    ],
    "directors": "Carl Theodor Dreyer",
    "budget_string": "N/A",
    "gross_string": "<1m USD",
    "metascore_string": "N/A"
  },
  {
    "imdb_id": "tt0021749",
    "plot": "With the aid of a wealthy erratic tippler, a dewy-eyed tramp who has fallen in love with a sightless flower girl accumulates money to be able to help her medically.",
    "certification": "G",
    "other_wins_count": 3,
    "other_noms_count": 1,
    "amazon": "https://www.amazon.com/Criterion-Collection-City-Lights-Blu-ray/dp/B019WMTUWU/ref=sr_1_2?crid=17VZIU6Y5TO0J&amp;keywords=city+lights&amp;qid=1654832900&amp;s=movies-tv&amp;sprefix=city%252Cmovies-tv-intl-ship%252C498&amp;sr=1-2&_encoding=UTF8&tag=sianglu-20&linkCode=ur2&linkId=f90ff72664d24a184c8660818cb1c4c7&camp=1789&creative=9325",
    "justwatch": "https://justwatch.com/us/movie/charlie-chaplin-city-lights?utm_source=share&utm_medium=web",
    "beige_index": 1,
    "beige_angle": 20.5722,
    "cast": [
      [
        3,
        "Al Ernest Garcia"
      ],
      [
        1,
        "Henry Bergman"
      ],
      [
        1,
        "Charles Chaplin"
      ],
      [
        1,
        "Virginia Cherrill"
      ],
      [
        1,
        "Florence Lee"
      ],
      [
        1,
        "Hank Mann"
      ],
      [
        1,
        "Harry Myers"
      ]
    ],
    "directors": "Charles Chaplin",
    "budget_string": "1.5m USD",
    "gross_string": "0.1m USD",
    "metascore_string": "99%"
  },
  {
    "imdb_id": "tt0022100",
    "plot": "When the police in a German city are unable to catch a child-murderer, other criminals join in the manhunt.",
    "certification": "Passed",
    "other_wins_count": 2,
    "other_noms_count": 0,
    "amazon": "https://www.amazon.com/Criterion-Collection-Blu-ray-Peter-Lorre/dp/B00393SFU2/ref=sr_1_7?crid=3POM2BD3MQDKA&amp;keywords=m+fritz+lang&amp;qid=1654845015&amp;s=movies-tv&amp;sprefix=M+fritz+lang%252Cmovies-tv%252C477&amp;sr=1-7&_encoding=UTF8&tag=sianglu-20&linkCode=ur2&linkId=44e6650579c095fba3d49e8ccff63ede&camp=1789&creative=9325",
    "justwatch": "https://justwatch.com/us/movie/m-1931?utm_source=share&utm_medium=web",
    "beige_index": 1,
    "beige_angle": 0.0,
    "cast": [
      [
        1,
        "Gerhard Bienert"
      ],
      [
        1,
        "Rudolf Bl\u00fcmner"
      ],
      [
        1,
        "Friedrich Gna\u00df"
      ],
      [
        1,
        "Gustaf Gr\u00fcndgens"
      ],
      [
        1,
        "Georg John"
      ],
      [
        1,
        "Paul Kemp"
      ],
      [
        1,
        "Inge Landgut"
      ],
      [
        1,
        "Theo Lingen"
      ],
      [
        1,
        "Theodor Loos"
      ],
      [
        1,
        "Peter Lorre"
      ],
      [
        1,
        "Fritz Odemar"
      ],
      [
        1,
        "Karl Platen"
      ],
      [
        1,
        "Lucie Rhoden"
      ],
      [
        1,
        "Ernst Stahl-Nachbaur"
      ],
      [
        1,
        "Franz Stein"
      ],
      [
        1,
        "Rosa Valetti"
      ],
      [
        1,
        "Hertha von Walther"
      ],
      [
        1,
        "Otto Waldis"
      ],
      [
        1,
        "Otto Wernicke"
      ],
      [
        1,
        "Ellen Widmann"
      ]
    ],
    "directors": "Fritz Lang",
    "budget_string": "N/A",
    "gross_string": "<1m USD",
    "metascore_string": "N/A"
  },
  {
    "imdb_id": "tt0025316",
    "plot": "A renegade reporter trailing a young runaway heiress for a big story joins her on a bus heading from Florida to New York, and they end up stuck with each other when the bus leaves them behind at one of the stops.",
    "certification": "Passed",
    "other_wins_count": 6,
    "other_noms_count": 2,
    "amazon": "https://www.amazon.com/Happened-One-Night-Blu-ray/dp/B00MRKX9PO/ref=sr_1_1?crid=10LHPCH4VKB2H&amp;keywords=it+happened+one+night+blu+ray&amp;qid=1655194343&amp;s=movies-tv&amp;sprefix=it+happened+one+night+blu+ray%252Cmovies-tv%252C310&amp;sr=1-1&_encoding=UTF8&tag=sianglu-20&linkCode=ur2&linkId=f960a31b85beb17b69898d51b8888222&camp=1789&creative=9325",
    "justwatch": "https://justwatch.com/us/movie/it-happened-one-night?utm_source=share&utm_medium=web",
    "beige_index": 1,
    "beige_angle": 0.0,
    "cast": [
      [
        1,
        "Irving Bacon"
      ],
      [
        1,
        "Ward Bond"
      ],
      [
        1,
        "Charles C. Wilson"
      ],
      [
        1,
        "Claudette Colbert"
      ],
      [
        1,
        "Walter Connolly"
      ],
      [
        1,
        "Blanche Friderici"
      ],
      [
        1,
        "Clark Gable"
      ],
      [
        1,
        "Alan Hale"
      ],
      [
        1,
        "Arthur Hoyt"
      ],
      [
        1,
        "Roscoe Karns"
      ],
      [
        1,
        "Jameson Thomas"
      ]
    ],
    "directors": "Frank Capra",
    "budget_string": "0.3m USD",
    "gross_string": "N/A",
    "metascore_string": "87%"
  },
  {
    "imdb_id": "tt0027977",
    "plot": "The Tramp struggles to live in modern industrial society with the help of a young homeless woman.",
    "certification": "G",
    "other_wins_count": 4,
    "other_noms_count": 1,
    "amazon": "https://www.amazon.com/CRITERION-DISTRIBUTION-CHARLIE-CHAPLIN-MODERN-BRCC1957/dp/B00G5YVSXI/ref=tmm_blu_swatch_0?_encoding=UTF8&amp;qid=1654832199&amp;sr=1-8&_encoding=UTF8&tag=sianglu-20&linkCode=ur2&linkId=f16a33e96e430abe6b0d0ed6d18bc2e2&camp=1789&creative=9325",
    "justwatch": "https://justwatch.com/us/movie/modern-times?utm_source=share&utm_medium=web",
    "beige_index": 1,
    "beige_angle": 7.2,
    "cast": [
      [
        3,
        "Al Ernest Garcia"
      ],
      [
        1,
        "Richard Alexander"
      ],
      [
        1,
        "Henry Bergman"
      ],
      [
        1,
        "Stanley Blystone"
      ],
      [
        1,
        "Charles Chaplin"
      ],
      [
        1,
        "Chester Conklin"
      ],
      [
        1,
        "Paulette Goddard"
      ],
      [
        1,
        "Frank Hagney"
      ],
      [
        1,
        "Edward LeSaint"
      ],
      [
        1,
        "Wilfred Lucas"
      ],
      [
        1,
        "Murdock MacQuarrie"
      ],
      [
        1,
        "Fred Malatesta"
      ],
      [
        1,
        "Hank Mann"
      ],
      [
        1,
        "Mira McKinney"
      ],
      [
        1,
        "Ted Oliver"
      ],
      [
        1,
        "Cecil Reynolds"
      ],
      [
        1,
        "Tiny Sandford"
      ],
      [
        1,
        "Sammy Stein"
      ],
      [
        1,
        "Juana Sutton"
      ],
      [
        1,
        "Harry Wilson"
      ]
    ],
    "directors": "Charles Chaplin",
    "budget_string": "1.5m USD",
    "gross_string": "0.5m USD",
    "metascore_string": "96%"
  },
  {
    "imdb_id": "tt0031381",
    "plot": "The manipulative daughter of a Georgia plantation owner conducts a turbulent romance with a roguish profiteer during the American Civil War and Reconstruction periods.",
    "certification": "Passed",
    "other_wins_count": 13,
    "other_noms_count": 12,
    "amazon": "https://www.amazon.com/Gone-Wind-75th-Anniversary-Blu-ray/dp/B00LTQPB6W/ref=sr_1_10_sspa?keywords=gone+with+the+wind+blu+ray&amp;qid=1655180640&amp;s=movies-tv&amp;sr=1-10-spons&amp;psc=1&amp;smid=A7QP4FXP92N6N&amp;spLa=ZW5jcnlwdGVkUXVhbGlmaWVyPUEyMFdKQ0w1UDdEQzNJJmVuY3J5cHRlZElkPUEwMDA0NDgxMzUySktTMTFCT04wOCZlbmNyeXB0ZWRBZElkPUEwNTcyNzAyM1VWUlhVVFpUUk45RCZ3aWRnZXROYW1lPXNwX210ZiZhY3Rpb249Y2xpY2tSZWRpcmVjdCZkb05vdExvZ0NsaWNrPXRydWU=&_encoding=UTF8&tag=sianglu-20&linkCode=ur2&linkId=6d241ff2887fb70bc240addc2cd00649&camp=1789&creative=9325",
    "justwatch": "https://justwatch.com/us/movie/gone-with-the-wind?utm_source=share&utm_medium=web",
    "beige_index": 1,
    "beige_angle": 34.4682,
    "cast": [
      [
        5,
        "Eddie 'Rochester' Anderson"
      ],
      [
        5,
        "Everett Brown"
      ],
      [
        5,
        "Hattie McDaniel"
      ],
      [
        5,
        "Butterfly McQueen"
      ],
      [
        5,
        "Oscar Polk"
      ],
      [
        1,
        "Mary Anderson"
      ],
      [
        1,
        "John Arledge"
      ],
      [
        1,
        "Irving Bacon"
      ],
      [
        1,
        "William Bakewell"
      ],
      [
        1,
        "Ward Bond"
      ],
      [
        1,
        "Rand Brooks"
      ],
      [
        1,
        "Yakima Canutt"
      ],
      [
        1,
        "Fred Crane"
      ],
      [
        1,
        "Jane Darwell"
      ],
      [
        1,
        "Harry Davenport"
      ],
      [
        1,
        "Olivia de Havilland"
      ],
      [
        1,
        "Cliff Edwards"
      ],
      [
        1,
        "Robert Elliott"
      ],
      [
        1,
        "Frank Faylen"
      ],
      [
        1,
        "Clark Gable"
      ],
      [
        1,
        "Howard Hickman"
      ],
      [
        1,
        "Laura Hope Crews"
      ],
      [
        1,
        "Leslie Howard"
      ],
      [
        1,
        "Olin Howland"
      ],
      [
        1,
        "Paul Hurst"
      ],
      [
        1,
        "Louis Jean Heydt"
      ],
      [
        1,
        "Isabel Jewell"
      ],
      [
        1,
        "Victor Jory"
      ],
      [
        1,
        "Lillian Kemble-Cooper"
      ],
      [
        1,
        "J.M. Kerrigan"
      ],
      [
        1,
        "Evelyn Keyes"
      ],
      [
        1,
        "Cammie King Conlon"
      ],
      [
        1,
        "Mickey Kuhn"
      ],
      [
        1,
        "Vivien Leigh"
      ],
      [
        1,
        "Eric Linden"
      ],
      [
        1,
        "Marcella Martin"
      ],
      [
        1,
        "Charles Middleton"
      ],
      [
        1,
        "Thomas Mitchell"
      ],
      [
        1,
        "Jackie Moran"
      ],
      [
        1,
        "Adrian Morris"
      ],
      [
        1,
        "Ona Munson"
      ],
      [
        1,
        "Carroll Nye"
      ],
      [
        1,
        "Barbara O'Neil"
      ],
      [
        1,
        "George Reeves"
      ],
      [
        1,
        "Alicia Rhett"
      ],
      [
        1,
        "Leona Roberts"
      ],
      [
        1,
        "Ann Rutherford"
      ]
    ],
    "directors": "Victor Fleming, George Cukor, & Sam Wood",
    "budget_string": "4m USD",
    "gross_string": "402.4m USD",
    "metascore_string": "97%"
  },
  {
    "imdb_id": "tt0032138",
    "plot": "Young Dorothy Gale and her dog are swept away by a tornado from their Kansas farm to the magical Land of Oz, and embark on a quest with three new friends to see the Wizard, who can return her to her home and fulfill the others' wishes.",
    "certification": "PG",
    "other_wins_count": 11,
    "other_noms_count": 16,
    "amazon": "https://www.amazon.com/Wizard-Oz-Blu-ray-Judy-Garland/dp/B00CNW9ZEU/ref=sr_1_2?crid=QEZIJSP7C8CT&amp;keywords=wizard+of+oz+blu+ray&amp;qid=1655185027&amp;s=movies-tv&amp;sprefix=wizard+of+oz+blu+ray%252Cmovies-tv%252C281&amp;sr=1-2&_encoding=UTF8&tag=sianglu-20&linkCode=ur2&linkId=af443264df48a5958d8e03ef25346ece&camp=1789&creative=9325",
    "justwatch": "https://justwatch.com/us/movie/the-wizard-of-oz?utm_source=share&utm_medium=web",
    "beige_index": 1,
    "beige_angle": 0.0,
    "cast": [
      [
        1,
        "Clara Blandick"
      ],
      [
        1,
        "Ray Bolger"
      ],
      [
        1,
        "Billie Burke"
      ],
      [
        1,
        "Judy Garland"
      ],
      [
        1,
        "Charley Grapewin"
      ],
      [
        1,
        "Jack Haley"
      ],
      [
        1,
        "Margaret Hamilton"
      ],
      [
        1,
        "Bert Lahr"
      ],
      [
        1,
        "Frank Morgan"
      ],
      [
        1,
        "The Singer Midgets"
      ],
      [
        1,
        "Pat Walshe"
      ],
      [
        1,
        "Harry Wilson"
      ]
    ],
    "directors": "Victor Fleming, George Cukor, & Mervyn LeRoy",
    "budget_string": "2.8m USD",
    "gross_string": "25.6m USD",
    "metascore_string": "92%"
  },
  {
    "imdb_id": "tt0031679",
    "plot": "A naive youth leader is appointed to fill a vacancy in the U.S. Senate. His idealistic plans promptly collide with corruption at home and subterfuge from his hero in Washington, but he tries to forge ahead despite attacks on his character.",
    "certification": "Passed",
    "other_wins_count": 5,
    "other_noms_count": 16,
    "amazon": "https://www.amazon.com/Mr-Smith-Goes-Washington-Blu-ray/dp/B07BF9YJ5C/ref=sr_1_1?crid=138K64HBXTCXE&amp;keywords=mr+smith+goes+to+washington+blu+ray&amp;qid=1655183201&amp;s=movies-tv&amp;sprefix=mr+smith+goes+to+washington+blu+ray%252Cmovies-tv%252C281&amp;sr=1-1&_encoding=UTF8&tag=sianglu-20&linkCode=ur2&linkId=4c5a325e1c956d46f663a5d4748b87b0&camp=1789&creative=9325",
    "justwatch": "https://justwatch.com/us/movie/frank-capras-mr-smith-goes-to-washington?utm_source=share&utm_medium=web",
    "beige_index": 1,
    "beige_angle": 0.0,
    "cast": [
      [
        1,
        "Astrid Allwyn"
      ],
      [
        1,
        "Edward Arnold"
      ],
      [
        1,
        "Jean Arthur"
      ],
      [
        1,
        "Beulah Bondi"
      ],
      [
        1,
        "Harry Carey"
      ],
      [
        1,
        "Dorothy Comingore"
      ],
      [
        1,
        "Chester Conklin"
      ],
      [
        1,
        "William Demarest"
      ],
      [
        1,
        "Ruth Donnelly"
      ],
      [
        1,
        "Dick Elliott"
      ],
      [
        1,
        "Porter Hall"
      ],
      [
        1,
        "Louis Jean Heydt"
      ],
      [
        1,
        "H.V. Kaltenborn"
      ],
      [
        1,
        "Guy Kibbee"
      ],
      [
        1,
        "Charles Lane"
      ],
      [
        1,
        "Wilfred Lucas"
      ],
      [
        1,
        "Hank Mann"
      ],
      [
        1,
        "Grant Mitchell"
      ],
      [
        1,
        "Thomas Mitchell"
      ],
      [
        1,
        "Eugene Pallette"
      ],
      [
        1,
        "Claude Rains"
      ],
      [
        1,
        "Larry Simms"
      ],
      [
        1,
        "Russell Simpson"
      ],
      [
        1,
        "James Stewart"
      ],
      [
        1,
        "Frederick Vroom"
      ],
      [
        1,
        "H.B. Warner"
      ],
      [
        1,
        "Pierre Watkin"
      ],
      [
        1,
        "Billy Watson"
      ],
      [
        1,
        "Delmar Watson"
      ],
      [
        1,
        "Harry Watson"
      ],
      [
        1,
        "Garry Watson"
      ]
    ],
    "directors": "Frank Capra",
    "budget_string": "1.9m USD",
    "gross_string": "0.1m USD",
    "metascore_string": "73%"
  },
  {
    "imdb_id": "tt0032976",
    "plot": "A self-conscious woman juggles adjusting to her new role as an aristocrat's wife and avoiding being intimidated by his first wife's spectral presence.",
    "certification": "Approved",
    "other_wins_count": 5,
    "other_noms_count": 10,
    "amazon": "https://www.amazon.com/Rebeca-Non-USA-format-Hitchcock/dp/B08RDR7BJN/ref=tmm_blu_swatch_0?_encoding=UTF8&amp;qid=1655185824&amp;sr=1-2&_encoding=UTF8&tag=sianglu-20&linkCode=ur2&linkId=7fcfc1f2c69ba96743ec8808a50dffda&camp=1789&creative=9325",
    "justwatch": "https://justwatch.com/us/movie/rebecca?utm_source=share&utm_medium=web",
    "beige_index": 1,
    "beige_angle": 0.0,
    "cast": [
      [
        1,
        "Judith Anderson"
      ],
      [
        1,
        "C. Aubrey Smith"
      ],
      [
        1,
        "Florence Bates"
      ],
      [
        1,
        "Nigel Bruce"
      ],
      [
        1,
        "Leonard Carey"
      ],
      [
        1,
        "Gladys Cooper"
      ],
      [
        1,
        "Melville Cooper"
      ],
      [
        1,
        "Reginald Denny"
      ],
      [
        1,
        "Edward Fielding"
      ],
      [
        1,
        "Joan Fontaine"
      ],
      [
        1,
        "Leo G. Carroll"
      ],
      [
        1,
        "Lumsden Hare"
      ],
      [
        1,
        "Forrester Harvey"
      ],
      [
        1,
        "Laurence Olivier"
      ],
      [
        1,
        "George Sanders"
      ],
      [
        1,
        "Philip Winter"
      ]
    ],
    "directors": "Alfred Hitchcock",
    "budget_string": "1.3m USD",
    "gross_string": "0.1m USD",
    "metascore_string": "86%"
  },
  {
    "imdb_id": "tt0032551",
    "plot": "An Oklahoma family driven off their farm by the poverty and hopelessness of the Dust Bowl joins the westward migration to California, suffering the misfortunes of the homeless in the Great Depression.",
    "certification": "Passed",
    "other_wins_count": 8,
    "other_noms_count": 5,
    "amazon": "https://www.amazon.com/Grapes-Wrath-Blu-ray-Henry-Fonda/dp/B007PM218K/ref=sr_1_2?crid=XPZGZ4OSGZRO&amp;keywords=grapes+of+wrath+blu+ray&amp;qid=1655185886&amp;s=movies-tv&amp;sprefix=grapes+of+wrath+blu+ra%252Cmovies-tv%252C277&amp;sr=1-2&_encoding=UTF8&tag=sianglu-20&linkCode=ur2&linkId=d191944db5f40dcbe18d7e71f1175fdd&camp=1789&creative=9325",
    "justwatch": "https://justwatch.com/us/movie/the-grapes-of-wrath?utm_source=share&utm_medium=web",
    "beige_index": 1,
    "beige_angle": 0.0,
    "cast": [
      [
        1,
        "John Arledge"
      ],
      [
        1,
        "Irving Bacon"
      ],
      [
        1,
        "Ward Bond"
      ],
      [
        1,
        "Dorris Bowdon"
      ],
      [
        1,
        "John Carradine"
      ],
      [
        1,
        "Cliff Clark"
      ],
      [
        1,
        "Charles D. Brown"
      ],
      [
        1,
        "Frank Darien"
      ],
      [
        1,
        "Jane Darwell"
      ],
      [
        1,
        "Frank Faylen"
      ],
      [
        1,
        "Henry Fonda"
      ],
      [
        1,
        "Charley Grapewin"
      ],
      [
        1,
        "Paul Guilfoyle"
      ],
      [
        1,
        "Darryl Hickman"
      ],
      [
        1,
        "David Hillary Hughes"
      ],
      [
        1,
        "Robert Homans"
      ],
      [
        1,
        "Roger Imhof"
      ],
      [
        1,
        "Robert J. Anderson"
      ],
      [
        1,
        "Selmer Jackson"
      ],
      [
        1,
        "Hollis Jewell"
      ],
      [
        1,
        "Kitty McHugh"
      ],
      [
        1,
        "Charles Middleton"
      ],
      [
        1,
        "Shirley Mills"
      ],
      [
        1,
        "Grant Mitchell"
      ],
      [
        1,
        "Adrian Morris"
      ],
      [
        1,
        "Ted Oliver"
      ],
      [
        1,
        "William Pawley"
      ],
      [
        1,
        "John Qualen"
      ],
      [
        1,
        "Eddie Quillan"
      ],
      [
        1,
        "Joe Sawyer"
      ],
      [
        1,
        "Russell Simpson"
      ],
      [
        1,
        "Frank Sully"
      ],
      [
        1,
        "Charles Tannen"
      ],
      [
        1,
        "Zeffie Tilbury"
      ],
      [
        1,
        "Harry Tyler"
      ],
      [
        1,
        "Eddy Waller"
      ],
      [
        1,
        "O.Z. Whitehead"
      ]
    ],
    "directors": "John Ford",
    "budget_string": "0.8m USD",
    "gross_string": "N/A",
    "metascore_string": "96%"
  },
  {
    "imdb_id": "tt0032553",
    "plot": "Dictator Adenoid Hynkel tries to expand his empire while a poor Jewish barber tries to avoid persecution from Hynkel's regime.",
    "certification": "G",
    "other_wins_count": 6,
    "other_noms_count": 1,
    "amazon": "https://www.amazon.com/Great-Dictator-Criterion-Collection/dp/B004NWPY7A/ref=sr_1_2?crid=3EX5VWUWXSAL6&amp;keywords=the+great+dictator&amp;qid=1654842082&amp;s=movies-tv&amp;sprefix=the+great+dicta%252Cmovies-tv%252C752&amp;sr=1-2&_encoding=UTF8&tag=sianglu-20&linkCode=ur2&linkId=8d9de7e90c727472f7ca5131adecedd2&camp=1789&creative=9325",
    "justwatch": "https://justwatch.com/us/movie/the-great-dictator?utm_source=share&utm_medium=web",
    "beige_index": 1,
    "beige_angle": 0.0,
    "cast": [
      [
        1,
        "Richard Alexander"
      ],
      [
        1,
        "Rudolph Anders"
      ],
      [
        1,
        "Charles Chaplin"
      ],
      [
        1,
        "Chester Conklin"
      ],
      [
        1,
        "Henry Daniell"
      ],
      [
        1,
        "Carter DeHaven"
      ],
      [
        1,
        "Wheeler Dryden"
      ],
      [
        1,
        "Emma Dunn"
      ],
      [
        1,
        "Eddie Dunn"
      ],
      [
        1,
        "Reginald Gardiner"
      ],
      [
        1,
        "Billy Gilbert"
      ],
      [
        1,
        "Paulette Goddard"
      ],
      [
        1,
        "Bernard Gorcey"
      ],
      [
        1,
        "Eddie Gribbon"
      ],
      [
        1,
        "Grace Hayle"
      ],
      [
        1,
        "George Lynn"
      ],
      [
        1,
        "Hank Mann"
      ],
      [
        1,
        "Torben Meyer"
      ],
      [
        1,
        "Esther Michelson"
      ],
      [
        1,
        "Maurice Moscovitch"
      ],
      [
        1,
        "Jack Oakie"
      ],
      [
        1,
        "Nita Pike"
      ],
      [
        1,
        "Tiny Sandford"
      ],
      [
        1,
        "Paul Weigel"
      ],
      [
        1,
        "Harry Wilson"
      ],
      [
        1,
        "Florence Wright"
      ]
    ],
    "directors": "Charles Chaplin",
    "budget_string": "2m USD",
    "gross_string": "1m USD",
    "metascore_string": "N/A"
  },
  {
    "imdb_id": "tt0033467",
    "plot": "Following the death of publishing tycoon Charles Foster Kane, reporters scramble to uncover the meaning of his final utterance: 'Rosebud.'",
    "certification": "PG",
    "other_wins_count": 10,
    "other_noms_count": 13,
    "amazon": "https://www.amazon.com/Citizen-Kane-75th-Anniversary-Blu-ray/dp/B01LZ6R8U4/ref=sr_1_3?crid=12EXUFUKSGJEO&amp;keywords=citizen+kane&amp;qid=1654844785&amp;s=movies-tv&amp;sprefix=citizen+kane%252Cmovies-tv%252C491&amp;sr=1-3&_encoding=UTF8&tag=sianglu-20&linkCode=ur2&linkId=72c727b0dd8b6e874b2890fa57592f88&camp=1789&creative=9325",
    "justwatch": "https://justwatch.com/us/movie/citizen-kane?utm_source=share&utm_medium=web",
    "beige_index": 1,
    "beige_angle": 0.0,
    "cast": [
      [
        1,
        "William Alland"
      ],
      [
        1,
        "Georgia Backus"
      ],
      [
        1,
        "Fortunio Bonanova"
      ],
      [
        1,
        "Sonny Bupp"
      ],
      [
        1,
        "Ray Collins"
      ],
      [
        1,
        "Dorothy Comingore"
      ],
      [
        1,
        "Joseph Cotten"
      ],
      [
        1,
        "George Coulouris"
      ],
      [
        1,
        "Agnes Moorehead"
      ],
      [
        1,
        "Erskine Sanford"
      ],
      [
        1,
        "Gus Schilling"
      ],
      [
        1,
        "Harry Shannon"
      ],
      [
        1,
        "Everett Sloane"
      ],
      [
        1,
        "Paul Stewart"
      ],
      [
        1,
        "Buddy Swan"
      ],
      [
        1,
        "Philip Van Zandt"
      ],
      [
        1,
        "Ruth Warrick"
      ],
      [
        1,
        "Orson Welles"
      ]
    ],
    "directors": "Orson Welles",
    "budget_string": "0.8m USD",
    "gross_string": "1.6m USD",
    "metascore_string": "100%"
  },
  {
    "imdb_id": "tt0034583",
    "plot": "A cynical expatriate American cafe owner struggles to decide whether or not to help his former lover and her fugitive husband escape the Nazis in French Morocco.",
    "certification": "PG",
    "other_wins_count": 7,
    "other_noms_count": 9,
    "amazon": "https://www.amazon.com/Casablanca/dp/B001G429TE/ref=sr_1_2?crid=1UF08GNV1ZXVB&amp;keywords=casablanca&amp;qid=1654831992&amp;s=movies-tv&amp;sprefix=casablanca%252Cmovies-tv-intl-ship%252C783&amp;sr=1-2&_encoding=UTF8&tag=sianglu-20&linkCode=ur2&linkId=75816c26acac96a50e3b89f9af4e6d1a&camp=1789&creative=9325",
    "justwatch": "https://justwatch.com/us/movie/casablanca?utm_source=share&utm_medium=web",
    "beige_index": 1,
    "beige_angle": 21.9996,
    "cast": [
      [
        5,
        "Dooley Wilson"
      ],
      [
        2,
        "Joy Page"
      ],
      [
        1,
        "Ingrid Bergman"
      ],
      [
        1,
        "Humphrey Bogart"
      ],
      [
        1,
        "Curt Bois"
      ],
      [
        1,
        "Eugene Borden"
      ],
      [
        1,
        "William Edmunds"
      ],
      [
        1,
        "Sydney Greenstreet"
      ],
      [
        1,
        "Paul Henreid"
      ],
      [
        1,
        "Leonid Kinskey"
      ],
      [
        1,
        "Madeleine Lebeau"
      ],
      [
        1,
        "Peter Lorre"
      ],
      [
        1,
        "Torben Meyer"
      ],
      [
        1,
        "John Qualen"
      ],
      [
        1,
        "Claude Rains"
      ],
      [
        1,
        "S.Z. Sakall"
      ],
      [
        1,
        "Norma Varden"
      ],
      [
        1,
        "Conrad Veidt"
      ]
    ],
    "directors": "Michael Curtiz",
    "budget_string": "0.9m USD",
    "gross_string": "4.5m USD",
    "metascore_string": "100%"
  },
  {
    "imdb_id": "tt0035446",
    "plot": "During the Nazi occupation of Poland, an acting troupe becomes embroiled in a Polish soldier's efforts to track down a German spy.",
    "certification": "Passed",
    "other_wins_count": 2,
    "other_noms_count": 1,
    "amazon": "https://www.amazon.com/Be-Not/dp/B06XVTJD9K/ref=tmm_dvd_swatch_0?_encoding=UTF8&amp;qid=1655185690&amp;sr=1-6&_encoding=UTF8&tag=sianglu-20&linkCode=ur2&linkId=1290c7aa081e33e67f92e7c892a7ee7f&camp=1789&creative=9325",
    "justwatch": "https://justwatch.com/us/movie/to-be-or-not-to-be?utm_source=share&utm_medium=web",
    "beige_index": 1,
    "beige_angle": 0.0,
    "cast": [
      [
        1,
        "Rudolph Anders"
      ],
      [
        1,
        "Lionel Atwill"
      ],
      [
        1,
        "Jack Benny"
      ],
      [
        1,
        "Felix Bressart"
      ],
      [
        1,
        "Tom Dugan"
      ],
      [
        1,
        "Maude Eburne"
      ],
      [
        1,
        "Charles Halton"
      ],
      [
        1,
        "Halliwell Hobbes"
      ],
      [
        1,
        "Carole Lombard"
      ],
      [
        1,
        "George Lynn"
      ],
      [
        1,
        "Miles Mander"
      ],
      [
        1,
        "Stanley Ridges"
      ],
      [
        1,
        "Sig Ruman"
      ],
      [
        1,
        "Robert Stack"
      ],
      [
        1,
        "Henry Victor"
      ]
    ],
    "directors": "Ernst Lubitsch",
    "budget_string": "N/A",
    "gross_string": "4.6m USD",
    "metascore_string": "86%"
  },
  {
    "imdb_id": "tt0036775",
    "plot": "A Los Angeles insurance representative lets an alluring housewife seduce him into a scheme of insurance fraud and murder that arouses the suspicion of his colleague, an insurance investigator.",
    "certification": "Passed",
    "other_wins_count": 2,
    "other_noms_count": 2,
    "amazon": "https://www.amazon.com/Double-Indemnity-Blu-ray-Fred-MacMurray/dp/B07G1XX2QN/ref=tmm_blu_swatch_0?_encoding=UTF8&amp;qid=1654931033&amp;sr=1-3&_encoding=UTF8&tag=sianglu-20&linkCode=ur2&linkId=ae4876c426b69b525364c4435e83e9e6&camp=1789&creative=9325",
    "justwatch": "https://justwatch.com/us/movie/double-indemnity?utm_source=share&utm_medium=web",
    "beige_index": 1,
    "beige_angle": 0.0,
    "cast": [
      [
        1,
        "Byron Barr"
      ],
      [
        1,
        "Fortunio Bonanova"
      ],
      [
        1,
        "Edward G. Robinson"
      ],
      [
        1,
        "Richard Gaines"
      ],
      [
        1,
        "Porter Hall"
      ],
      [
        1,
        "Jean Heather"
      ],
      [
        1,
        "Fred MacMurray"
      ],
      [
        1,
        "John Philliber"
      ],
      [
        1,
        "Tom Powers"
      ],
      [
        1,
        "Barbara Stanwyck"
      ]
    ],
    "directors": "Billy Wilder",
    "budget_string": "0.9m USD",
    "gross_string": "<1m USD",
    "metascore_string": "95%"
  },
  {
    "imdb_id": "tt0036868",
    "plot": "Three World War II veterans, two of them traumatized or disabled, return home to the American midwest to discover that they and their families have been irreparably changed.",
    "certification": "Approved",
    "other_wins_count": 14,
    "other_noms_count": 4,
    "amazon": "https://www.amazon.com/Best-Years-Our-Lives-Blu-ray/dp/B08NDXFDFC/ref=sr_1_1?crid=1VCNBC376V5FF&amp;keywords=the+best+years+of+our+lives+blu+ray&amp;qid=1655185579&amp;s=movies-tv&amp;sprefix=the+best+years+of+our+lives+blu+ray%252Cmovies-tv%252C301&amp;sr=1-1&_encoding=UTF8&tag=sianglu-20&linkCode=ur2&linkId=e9fd32f647fef9ea0a45916add7574c3&camp=1789&creative=9325",
    "justwatch": "https://justwatch.com/us/movie/the-best-years-of-our-lives?utm_source=share&utm_medium=web",
    "beige_index": 1,
    "beige_angle": 0.0,
    "cast": [
      [
        1,
        "Marlene Aames"
      ],
      [
        1,
        "Dorothy Adams"
      ],
      [
        1,
        "Dana Andrews"
      ],
      [
        1,
        "Walter Baldwin"
      ],
      [
        1,
        "Don Beddoe"
      ],
      [
        1,
        "Roman Bohnen"
      ],
      [
        1,
        "Hoagy Carmichael"
      ],
      [
        1,
        "Howland Chamberlain"
      ],
      [
        1,
        "Steve Cochran"
      ],
      [
        1,
        "Ray Collins"
      ],
      [
        1,
        "Victor Cutler"
      ],
      [
        1,
        "Tom Dugan"
      ],
      [
        1,
        "Gladys George"
      ],
      [
        1,
        "Minna Gombell"
      ],
      [
        1,
        "Michael Hall"
      ],
      [
        1,
        "Charles Halton"
      ],
      [
        1,
        "Myrna Loy"
      ],
      [
        1,
        "Fredric March"
      ],
      [
        1,
        "Virginia Mayo"
      ],
      [
        1,
        "Georgie Nokes"
      ],
      [
        1,
        "Cathy O'Donnell"
      ],
      [
        1,
        "Harold Russell"
      ],
      [
        1,
        "Erskine Sanford"
      ],
      [
        1,
        "Ray Teal"
      ],
      [
        1,
        "Dean White"
      ],
      [
        1,
        "Teresa Wright"
      ]
    ],
    "directors": "William Wyler",
    "budget_string": "2.1m USD",
    "gross_string": "23.7m USD",
    "metascore_string": "93%"
  },
  {
    "imdb_id": "tt0038650",
    "plot": "An angel is sent from Heaven to help a desperately frustrated businessman by showing him what life would have been like if he had never existed.",
    "certification": "PG",
    "other_wins_count": 6,
    "other_noms_count": 1,
    "amazon": "https://www.amazon.com/Wonderful-Life-Blu-ray-James-Stewart/dp/B07W6CF3Z2/ref=sr_1_4?crid=2BU7TTQJRC46L&amp;keywords=it%2527s+a+wonderful+life&amp;qid=1654827909&amp;sprefix=it%2527s+a+wonderful+life%252Caps%252C620&amp;sr=8-4&_encoding=UTF8&tag=sianglu-20&linkCode=ur2&linkId=5b29bd64eba41a43264d3f311f166d7e&camp=1789&creative=9325",
    "justwatch": "https://justwatch.com/us/movie/frank-capras-its-a-wonderful-life?utm_source=share&utm_medium=web",
    "beige_index": 1,
    "beige_angle": 12.0006,
    "cast": [
      [
        5,
        "Lillian Randolph"
      ],
      [
        3,
        "Argentina Brunetti"
      ],
      [
        1,
        "Frank Albertson"
      ],
      [
        1,
        "Jeanine Ann Roose"
      ],
      [
        1,
        "Lionel Barrymore"
      ],
      [
        1,
        "Ward Bond"
      ],
      [
        1,
        "Beulah Bondi"
      ],
      [
        1,
        "Charles C. Wilson"
      ],
      [
        1,
        "Carol Coombs"
      ],
      [
        1,
        "Ellen Corby"
      ],
      [
        1,
        "William Edmunds"
      ],
      [
        1,
        "Sarah Edwards"
      ],
      [
        1,
        "Dick Elliott"
      ],
      [
        1,
        "Frank Faylen"
      ],
      [
        1,
        "Jeanne Gail"
      ],
      [
        1,
        "Gloria Grahame"
      ],
      [
        1,
        "Karolyn Grimes"
      ],
      [
        1,
        "Frank Hagney"
      ],
      [
        1,
        "Charles Halton"
      ],
      [
        1,
        "Jimmy Hawkins"
      ],
      [
        1,
        "Robert J. Anderson"
      ],
      [
        1,
        "Todd Karns"
      ],
      [
        1,
        "Edward Keane"
      ],
      [
        1,
        "Charles Lane"
      ],
      [
        1,
        "Sheldon Leonard"
      ],
      [
        1,
        "Thomas Mitchell"
      ],
      [
        1,
        "Danny Mummert"
      ],
      [
        1,
        "Georgie Nokes"
      ],
      [
        1,
        "Virginia Patton"
      ],
      [
        1,
        "Ronnie Ralph"
      ],
      [
        1,
        "Donna Reed"
      ],
      [
        1,
        "Samuel S. Hinds"
      ],
      [
        1,
        "Larry Simms"
      ],
      [
        1,
        "James Stewart"
      ],
      [
        1,
        "Henry Travers"
      ],
      [
        1,
        "Mary Treen"
      ],
      [
        1,
        "Ray Walker"
      ],
      [
        1,
        "H.B. Warner"
      ],
      [
        1,
        "Charles Williams"
      ]
    ],
    "directors": "Frank Capra",
    "budget_string": "3.2m USD",
    "gross_string": "6.2m USD",
    "metascore_string": "89%"
  },
  {
    "imdb_id": "tt0040897",
    "plot": "Two down-on-their-luck Americans searching for work in 1920s Mexico convince an old prospector to help them mine for gold in the Sierra Madre Mountains.",
    "certification": "Passed",
    "other_wins_count": 13,
    "other_noms_count": 8,
    "amazon": "https://www.amazon.com/Treasure-Sierra-Madre-Blu-ray/dp/B001P829VY/ref=sr_1_1?crid=3K49FXIPP08PC&amp;keywords=treasure+of+sierra+madre+blu+ray&amp;qid=1655175176&amp;sprefix=treasure+of+sierra+madre+blu+ray%252Caps%252C285&amp;sr=8-1&_encoding=UTF8&tag=sianglu-20&linkCode=ur2&linkId=36327a58d61c6829498ef213cc669b40&camp=1789&creative=9325",
    "justwatch": "https://justwatch.com/us/movie/the-treasure-of-the-sierra-madre?utm_source=share&utm_medium=web",
    "beige_index": 2,
    "beige_angle": 50.7276,
    "cast": [
      [
        3,
        "Alfonso Bedoya"
      ],
      [
        3,
        "Manuel Dond\u00e9"
      ],
      [
        3,
        "Margarito Luna"
      ],
      [
        3,
        "Arturo Soto Rangel"
      ],
      [
        3,
        "Jos\u00e9 Torvay"
      ],
      [
        1,
        "Bruce Bennett"
      ],
      [
        1,
        "Humphrey Bogart"
      ],
      [
        1,
        "Tim Holt"
      ],
      [
        1,
        "Walter Huston"
      ],
      [
        1,
        "John Huston"
      ],
      [
        1,
        "Barton MacLane"
      ]
    ],
    "directors": "John Huston",
    "budget_string": "3m USD",
    "gross_string": "5m USD",
    "metascore_string": "98%"
  },
  {
    "imdb_id": "tt0040522",
    "plot": "In post-war Italy, a working-class man's bicycle is stolen, endangering his efforts to find work. He and his son set out to find it.",
    "certification": "Not Rated",
    "other_wins_count": 20,
    "other_noms_count": 2,
    "amazon": "https://www.amazon.com/Bicycle-Thieves-Criterion-Collection-Blu-ray/dp/B019I74IGW/ref=sr_1_2?crid=3UUQA8FNNZ0OE&amp;keywords=bicycle+thieves&amp;qid=1655008433&amp;sprefix=bicycle+thieves%252Caps%252C288&amp;sr=8-2&_encoding=UTF8&tag=sianglu-20&linkCode=ur2&linkId=d98e8a9e2c22977f7bd148b4fa536db8&camp=1789&creative=9325",
    "justwatch": "https://justwatch.com/us/movie/bicycle-thieves?utm_source=share&utm_medium=web",
    "beige_index": 1,
    "beige_angle": 0.0,
    "cast": [
      [
        1,
        "Elena Altieri"
      ],
      [
        1,
        "Vittorio Antonucci"
      ],
      [
        1,
        "Lianella Carell"
      ],
      [
        1,
        "Giulio Chiari"
      ],
      [
        1,
        "Emma Druetti"
      ],
      [
        1,
        "Fausto Guerzoni"
      ],
      [
        1,
        "Carlo Jachino"
      ],
      [
        1,
        "Lamberto Maggiorani"
      ],
      [
        1,
        "Mario Meniconi"
      ],
      [
        1,
        "Michele Sakara"
      ],
      [
        1,
        "Gino Saltamerenda"
      ],
      [
        1,
        "Enzo Staiola"
      ]
    ],
    "directors": "Vittorio De Sica",
    "budget_string": "0.1m USD",
    "gross_string": "0.4m USD",
    "metascore_string": "N/A"
  },
  {
    "imdb_id": "tt0041959",
    "plot": "Pulp novelist Holly Martins travels to shadowy, postwar Vienna, only to find himself investigating the mysterious death of an old friend, Harry Lime.",
    "certification": "Approved",
    "other_wins_count": 4,
    "other_noms_count": 4,
    "amazon": "https://www.amazon.com/Third-Man-Blu-ray-Joseph-Cotten/dp/B003ULW74S/ref=sr_1_1?crid=15T7B2GVCG7E3&amp;keywords=the+third+man+blu+ray&amp;qid=1655182470&amp;sprefix=the+third+man+blu+ray%252Caps%252C291&amp;sr=8-1&_encoding=UTF8&tag=sianglu-20&linkCode=ur2&linkId=52cddfb1adfa361fb3a629c999d9b160&camp=1789&creative=9325",
    "justwatch": "https://justwatch.com/us/movie/the-third-man?utm_source=share&utm_medium=web",
    "beige_index": 1,
    "beige_angle": 0.0,
    "cast": [
      [
        1,
        "Hedwig Bleibtreu"
      ],
      [
        1,
        "Siegfried Breuer"
      ],
      [
        1,
        "Joseph Cotten"
      ],
      [
        1,
        "Ernst Deutsch"
      ],
      [
        1,
        "Paul H\u00f6rbiger"
      ],
      [
        1,
        "Trevor Howard"
      ],
      [
        1,
        "Wilfrid Hyde-White"
      ],
      [
        1,
        "Bernard Lee"
      ],
      [
        1,
        "Erich Ponto"
      ],
      [
        1,
        "Gordon Tanner"
      ],
      [
        1,
        "Alida Valli"
      ],
      [
        1,
        "Orson Welles"
      ]
    ],
    "directors": "Carol Reed",
    "budget_string": "N/A",
    "gross_string": "1.2m USD",
    "metascore_string": "97%"
  },
  {
    "imdb_id": "tt0042876",
    "plot": "The rape of a bride and the murder of her samurai husband are recalled from the perspectives of a bandit, the bride, the samurai's ghost and a woodcutter.",
    "certification": "Not Rated",
    "other_wins_count": 9,
    "other_noms_count": 4,
    "amazon": "https://www.amazon.com/Rashomon-Criterion-Collection-Blu-ray-Toshiro/dp/B008Y5OWO8/ref=sr_1_1?crid=23HPPS9Z3DQCC&amp;keywords=rashomon+blu+ray&amp;qid=1655175339&amp;sprefix=rashomon+blu+ray%252Caps%252C303&amp;sr=8-1&_encoding=UTF8&tag=sianglu-20&linkCode=ur2&linkId=f2471d9c880850d1e32ed24f9121b335&camp=1789&creative=9325",
    "justwatch": "https://justwatch.com/us/movie/rashomon?utm_source=share&utm_medium=web",
    "beige_index": 2,
    "beige_angle": 45.0,
    "cast": [
      [
        2,
        "Minoru Chiaki"
      ],
      [
        2,
        "Noriko Honma"
      ],
      [
        2,
        "Daisuke Kat\u00f4"
      ],
      [
        2,
        "Machiko Ky\u00f4"
      ],
      [
        2,
        "Toshir\u00f4 Mifune"
      ],
      [
        2,
        "Masayuki Mori"
      ],
      [
        2,
        "Takashi Shimura"
      ],
      [
        2,
        "Kichijir\u00f4 Ueda"
      ]
    ],
    "directors": "Akira Kurosawa",
    "budget_string": "0.2m USD",
    "gross_string": "<1m USD",
    "metascore_string": "98%"
  },
  {
    "imdb_id": "tt0042192",
    "plot": "A seemingly timid but secretly ruthless ing\u00e9nue insinuates herself into the lives of an aging Broadway star and her circle of theater friends.",
    "certification": "Passed",
    "other_wins_count": 20,
    "other_noms_count": 20,
    "amazon": "https://www.amazon.com/All-About-Blu-ray-Bette-Davis/dp/B00569I56Q/ref=tmm_mfc_swatch_0?_encoding=UTF8&amp;qid=1655169558&amp;sr=8-5&_encoding=UTF8&tag=sianglu-20&linkCode=ur2&linkId=2806d25898b5833d01c70bc18c6d5a7f&camp=1789&creative=9325",
    "justwatch": "https://justwatch.com/us/movie/all-about-eve?utm_source=share&utm_medium=web",
    "beige_index": 1,
    "beige_angle": 0.0,
    "cast": [
      [
        1,
        "Barbara Bates"
      ],
      [
        1,
        "Anne Baxter"
      ],
      [
        1,
        "Eugene Borden"
      ],
      [
        1,
        "Bette Davis"
      ],
      [
        1,
        "Franklyn Farnum"
      ],
      [
        1,
        "Eddie Fisher"
      ],
      [
        1,
        "Steven Geray"
      ],
      [
        1,
        "Walter Hampden"
      ],
      [
        1,
        "Leland Harris"
      ],
      [
        1,
        "Craig Hill"
      ],
      [
        1,
        "Celeste Holm"
      ],
      [
        1,
        "Hugh Marlowe"
      ],
      [
        1,
        "Gary Merrill"
      ],
      [
        1,
        "Marilyn Monroe"
      ],
      [
        1,
        "Helen Mowery"
      ],
      [
        1,
        "William Pullen"
      ],
      [
        1,
        "Gregory Ratoff"
      ],
      [
        1,
        "Thelma Ritter"
      ],
      [
        1,
        "George Sanders"
      ],
      [
        1,
        "Claude Stroud"
      ],
      [
        1,
        "Randy Stuart"
      ],
      [
        1,
        "Barbara White"
      ]
    ],
    "directors": "Joseph L. Mankiewicz",
    "budget_string": "1.4m USD",
    "gross_string": "0.2m USD",
    "metascore_string": "98%"
  },
  {
    "imdb_id": "tt0043014",
    "plot": "A screenwriter develops a dangerous relationship with a faded film star determined to make a triumphant return.",
    "certification": "Passed",
    "other_wins_count": 15,
    "other_noms_count": 20,
    "amazon": "https://www.amazon.com/Sunset-Boulevard/dp/B00AC7PJDY/ref=tmm_blu_title_0?_encoding=UTF8&amp;qid=1654841794&amp;sr=1-2&_encoding=UTF8&tag=sianglu-20&linkCode=ur2&linkId=ba80cd1fa9ac3bb4e47208cc2ba5e9c1&camp=1789&creative=9325",
    "justwatch": "https://justwatch.com/us/movie/sunset-blvd?utm_source=share&utm_medium=web",
    "beige_index": 1,
    "beige_angle": 0.0,
    "cast": [
      [
        1,
        "Cecil B. DeMille"
      ],
      [
        1,
        "Fred Clark"
      ],
      [
        1,
        "Charles Dayton"
      ],
      [
        1,
        "Ray Evans"
      ],
      [
        1,
        "Franklyn Farnum"
      ],
      [
        1,
        "Lloyd Gough"
      ],
      [
        1,
        "William Holden"
      ],
      [
        1,
        "Hedda Hopper"
      ],
      [
        1,
        "Larry J. Blake"
      ],
      [
        1,
        "Buster Keaton"
      ],
      [
        1,
        "Jay Livingston"
      ],
      [
        1,
        "Nancy Olson"
      ],
      [
        1,
        "Anna Q. Nilsson"
      ],
      [
        1,
        "Gloria Swanson"
      ],
      [
        1,
        "Erich von Stroheim"
      ],
      [
        1,
        "Jack Warden"
      ],
      [
        1,
        "H.B. Warner"
      ],
      [
        1,
        "Jack Webb"
      ]
    ],
    "directors": "Billy Wilder",
    "budget_string": "1.8m USD",
    "gross_string": "0.3m USD",
    "metascore_string": "N/A"
  },
  {
    "imdb_id": "tt0045152",
    "plot": "A silent film star falls for a chorus girl just as he and his delusionally jealous screen partner are trying to make the difficult transition to talking pictures in 1920s Hollywood.",
    "certification": "G",
    "other_wins_count": 7,
    "other_noms_count": 7,
    "amazon": "https://www.amazon.com/Singin-Rain-Blu-ray-Donald-Oconnor/dp/B0087YYHZU/ref=sr_1_2?crid=2ZLR2GXC9JCP7&amp;keywords=singin%2527+in+the+rain&amp;qid=1654844158&amp;s=movies-tv&amp;sprefix=singin%2527+in+the+rain%252Cmovies-tv%252C684&amp;sr=1-2&_encoding=UTF8&tag=sianglu-20&linkCode=ur2&linkId=2a18003372158315ad67ec53efe59ecd&camp=1789&creative=9325",
    "justwatch": "https://justwatch.com/us/movie/toy-story-3-3d?utm_source=share&utm_medium=web",
    "beige_index": 1,
    "beige_angle": 16.0002,
    "cast": [
      [
        3,
        "Rita Moreno"
      ],
      [
        1,
        "Cyd Charisse"
      ],
      [
        1,
        "Douglas Fowley"
      ],
      [
        1,
        "Jeanne Gail"
      ],
      [
        1,
        "Jean Hagen"
      ],
      [
        1,
        "Gene Kelly"
      ],
      [
        1,
        "Millard Mitchell"
      ],
      [
        1,
        "Donald O'Connor"
      ],
      [
        1,
        "Debbie Reynolds"
      ]
    ],
    "directors": "Stanley Donen & Gene Kelly",
    "budget_string": "2.5m USD",
    "gross_string": "1.9m USD",
    "metascore_string": "99%"
  },
  {
    "imdb_id": "tt0044741",
    "plot": "A bureaucrat tries to find meaning in his life after he discovers he has terminal cancer.",
    "certification": "Not Rated",
    "other_wins_count": 5,
    "other_noms_count": 1,
    "amazon": "https://www.amazon.com/Criterion-Collection-Blu-ray-Takashi-Shimura/dp/B0141RBHTU/ref=sr_1_2?crid=3UHC5TJW13IWV&amp;keywords=ikiru&amp;qid=1655007463&amp;sprefix=ikiru%252Caps%252C287&amp;sr=8-2&_encoding=UTF8&tag=sianglu-20&linkCode=ur2&linkId=44f943ffe0d9770728a9ee0c128c3a48&camp=1789&creative=9325",
    "justwatch": "https://justwatch.com/us/movie/to-live?utm_source=share&utm_medium=web",
    "beige_index": 2,
    "beige_angle": 45.0,
    "cast": [
      [
        2,
        "Kusuo Abe"
      ],
      [
        2,
        "Hirayoshi Aono"
      ],
      [
        2,
        "Mie As\u00f4"
      ],
      [
        2,
        "Minoru Chiaki"
      ],
      [
        2,
        "Ichir\u00f4 Chiba"
      ],
      [
        2,
        "Kamatari Fujiwara"
      ],
      [
        2,
        "Taiz\u00f4 Fukami"
      ],
      [
        2,
        "Hiroshi Hayashi"
      ],
      [
        2,
        "Bokuzen Hidari"
      ],
      [
        2,
        "Shin'ichi Himori"
      ],
      [
        2,
        "Noriko Honma"
      ],
      [
        2,
        "Toshiyuki Ichimura"
      ],
      [
        2,
        "Y\u00fbnosuke It\u00f4"
      ],
      [
        2,
        "Nobuo Kaneko"
      ],
      [
        2,
        "Shigeo Kat\u00f4"
      ],
      [
        2,
        "Daisuke Kat\u00f4"
      ],
      [
        2,
        "Keiichir\u00f4 Katsumoto"
      ],
      [
        2,
        "Katao Kawasaki"
      ],
      [
        2,
        "Isao Kimura"
      ],
      [
        2,
        "Makoto Kobori"
      ],
      [
        2,
        "Y\u00f4y\u00f4 Kojima"
      ],
      [
        2,
        "Harue Kuramoto"
      ],
      [
        2,
        "Yoshie Minami"
      ],
      [
        2,
        "Seiji Miyaguchi"
      ],
      [
        2,
        "Eiko Miyoshi"
      ],
      [
        2,
        "Fuyuki Murakami"
      ],
      [
        2,
        "Fujio Nagahama"
      ],
      [
        2,
        "Tomo'o Nagai"
      ],
      [
        2,
        "Nobuo Nakamura"
      ],
      [
        2,
        "Junpei Natsuki"
      ],
      [
        2,
        "Miki Odagiri"
      ],
      [
        2,
        "Toranosuke Ogawa"
      ],
      [
        2,
        "Sachio Sakai"
      ],
      [
        2,
        "Rasa Saya"
      ],
      [
        2,
        "Ky\u00f4ko Seki"
      ],
      [
        2,
        "Akira Sera"
      ],
      [
        2,
        "Masao Shimizu"
      ],
      [
        2,
        "Takashi Shimura"
      ],
      [
        2,
        "Kin Sugai"
      ],
      [
        2,
        "Yatsuko Tan'ami"
      ],
      [
        2,
        "Haruo Tanaka"
      ],
      [
        2,
        "Akira Tani"
      ],
      [
        2,
        "Haruko Toyama"
      ],
      [
        2,
        "Kumeko Urabe"
      ],
      [
        2,
        "Atsushi Watanabe"
      ],
      [
        2,
        "Minosuke Yamada"
      ]
    ],
    "directors": "Akira Kurosawa",
    "budget_string": "N/A",
    "gross_string": "0.1m USD",
    "metascore_string": "91%"
  },
  {
    "imdb_id": "tt0046268",
    "plot": "In a decrepit South American village, four men are hired to transport an urgent nitroglycerine shipment without the equipment that would make it safe.",
    "certification": "Not Rated",
    "other_wins_count": 5,
    "other_noms_count": 0,
    "amazon": "https://www.amazon.com/Wages-Fear-Criterion-Collection-Blu-ray/dp/B001PYD0KM/ref=sr_1_1?crid=1P75RHFW7C1WJ&amp;keywords=the+wages+of+fear+blu+ray&amp;qid=1655183256&amp;sprefix=the+wages+of+fear+blu+ray%252Caps%252C288&amp;sr=8-1&_encoding=UTF8&tag=sianglu-20&linkCode=ur2&linkId=933d2ab183b515ac3f7e5d64ff19ec81&camp=1789&creative=9325",
    "justwatch": "https://justwatch.com/us/movie/the-wages-of-fear?utm_source=share&utm_medium=web",
    "beige_index": 2,
    "beige_angle": 45.6921,
    "cast": [
      [
        5,
        "Darling L\u00e9gitimus"
      ],
      [
        4,
        "Dar\u00edo Moreno"
      ],
      [
        3,
        "Luis De Lima"
      ],
      [
        2,
        "V\u00e9ra Clouzot"
      ],
      [
        1,
        "Antonio Centa"
      ],
      [
        1,
        "Jo Dest"
      ],
      [
        1,
        "Gr\u00e9goire Gromoff"
      ],
      [
        1,
        "Folco Lulli"
      ],
      [
        1,
        "Yves Montand"
      ],
      [
        1,
        "Josep Palau i Fabre"
      ],
      [
        1,
        "William Tubbs"
      ],
      [
        1,
        "Peter van Eyck"
      ],
      [
        1,
        "Charles Vanel"
      ]
    ],
    "directors": "Henri-Georges Clouzot",
    "budget_string": "N/A",
    "gross_string": "<1m USD",
    "metascore_string": "85%"
  },
  {
    "imdb_id": "tt0046438",
    "plot": "An old couple visit their children and grandchildren in the city, but receive little attention.",
    "certification": "Not Rated",
    "other_wins_count": 3,
    "other_noms_count": 0,
    "amazon": "https://www.amazon.com/Criterion-Collection-Tokyo-Story-Blu-ray/dp/B019WMTWJ6/ref=sr_1_1?crid=3LOC1Z1762B0W&amp;keywords=tokyo+story+blu+ray&amp;qid=1655184432&amp;s=movies-tv&amp;sprefix=tokyo+story+blu+ray%252Cmovies-tv%252C275&amp;sr=1-1&_encoding=UTF8&tag=sianglu-20&linkCode=ur2&linkId=93f6bbe62cf1e0c21aa41a1dc5f0f5cd&camp=1789&creative=9325",
    "justwatch": "https://justwatch.com/us/movie/tokyo-story?utm_source=share&utm_medium=web",
    "beige_index": 2,
    "beige_angle": 45.0,
    "cast": [
      [
        2,
        "T\u00f4ru Abe"
      ],
      [
        2,
        "Junko Anan"
      ],
      [
        2,
        "Haruko Chichibu"
      ],
      [
        2,
        "Setsuko Hara"
      ],
      [
        2,
        "Chieko Higashiyama"
      ],
      [
        2,
        "Kazuhiro Itokawa"
      ],
      [
        2,
        "Ky\u00f4ko Kagawa"
      ],
      [
        2,
        "Takashi Miki"
      ],
      [
        2,
        "Sachiko Mitani"
      ],
      [
        2,
        "Kuniko Miyake"
      ],
      [
        2,
        "Ry\u00f4ko Mizuki"
      ],
      [
        2,
        "Mitsuhiro M\u00f4ri"
      ],
      [
        2,
        "Keijir\u00f4 Morozumi"
      ],
      [
        2,
        "Zen Murase"
      ],
      [
        2,
        "Binnosuke Nagao"
      ],
      [
        2,
        "Teruko Nagaoka"
      ],
      [
        2,
        "Nobuo Nakamura"
      ],
      [
        2,
        "Tsutomu Niijima"
      ],
      [
        2,
        "Shir\u00f4 \u00d4saka"
      ],
      [
        2,
        "Chish\u00fb Ry\u00fb"
      ],
      [
        2,
        "Mutsuko Sakura"
      ],
      [
        2,
        "Haruko Sugimura"
      ],
      [
        2,
        "Sh\u00f4z\u00f4 Suzuki"
      ],
      [
        2,
        "Toyo Takahashi"
      ],
      [
        2,
        "Yoshiko Tashiro"
      ],
      [
        2,
        "Hisao Toake"
      ],
      [
        2,
        "Yoshiko Togawa"
      ],
      [
        2,
        "Eijir\u00f4 T\u00f4no"
      ],
      [
        2,
        "Fumio Tooyama"
      ],
      [
        2,
        "S\u00f4 Yamamura"
      ]
    ],
    "directors": "Yasujir\u00f4 Ozu",
    "budget_string": "N/A",
    "gross_string": "<1m USD",
    "metascore_string": "N/A"
  },
  {
    "imdb_id": "tt0047478",
    "plot": "A poor village under attack by bandits recruits seven unemployed samurai to help them defend themselves.",
    "certification": "Not Rated",
    "other_wins_count": 5,
    "other_noms_count": 6,
    "amazon": "https://www.amazon.com/Seven-Samurai-Criterion-Collection-Blu-ray/dp/B003KGBISY/ref=sr_1_2?crid=3L7MR33QR1SHY&amp;keywords=seven+samurai&amp;qid=1654827830&amp;sprefix=seven+samurai%252Caps%252C885&amp;sr=8-2&_encoding=UTF8&tag=sianglu-20&linkCode=ur2&linkId=0c5e6e2c99f4228f98dde8be8589a236&camp=1789&creative=9325",
    "justwatch": "https://justwatch.com/us/movie/seven-samurai?utm_source=share&utm_medium=web",
    "beige_index": 2,
    "beige_angle": 45.0,
    "cast": [
      [
        2,
        "Hiroshi Akitsu"
      ],
      [
        2,
        "Ry\u00fbtar\u00f4 Amami"
      ],
      [
        2,
        "Gor\u00f4 Amano"
      ],
      [
        2,
        "Shizuko Azuma"
      ],
      [
        2,
        "Minoru Chiaki"
      ],
      [
        2,
        "Ichir\u00f4 Chiba"
      ],
      [
        2,
        "Kamatari Fujiwara"
      ],
      [
        2,
        "Hiroshi Hayashi"
      ],
      [
        2,
        "Bokuzen Hidari"
      ],
      [
        2,
        "Sh\u00f4ichi Hirose"
      ],
      [
        2,
        "Noriko Honma"
      ],
      [
        2,
        "Tsurue Ichimanji"
      ],
      [
        2,
        "Toku Ihara"
      ],
      [
        2,
        "Kaneo Ikeda"
      ],
      [
        2,
        "Kazuo Imai"
      ],
      [
        2,
        "Yoshio Inaba"
      ],
      [
        2,
        "Minoru It\u00f4"
      ],
      [
        2,
        "K\u00f4ji Iwamoto"
      ],
      [
        2,
        "Michiko Kadono"
      ],
      [
        2,
        "S\u00f4jin Kamiyama"
      ],
      [
        2,
        "Ky\u00f4ichi Kamiyama"
      ],
      [
        2,
        "Kiyoshi Kamoda"
      ],
      [
        2,
        "Tsuneo Katagiri"
      ],
      [
        2,
        "Shigeo Kat\u00f4"
      ],
      [
        2,
        "Daisuke Kat\u00f4"
      ],
      [
        2,
        "Takeshi Kat\u00f4"
      ],
      [
        2,
        "Masayoshi Kawabe"
      ],
      [
        2,
        "Michiko Kawabe"
      ],
      [
        2,
        "Ippei Kawagoe"
      ],
      [
        2,
        "Yoshikazu Kawamata"
      ],
      [
        2,
        "Isao Kimura"
      ],
      [
        2,
        "Yayoko Kitano"
      ],
      [
        2,
        "Akira Kitch\u00f4ji"
      ],
      [
        2,
        "Kokuten K\u00f4d\u00f4"
      ],
      [
        2,
        "Yoshio Kosugi"
      ],
      [
        2,
        "Takuz\u00f4 Kumagai"
      ],
      [
        2,
        "Akio Kusama"
      ],
      [
        2,
        "S\u00f4kichi Maki"
      ],
      [
        2,
        "Tsuruko Mano"
      ],
      [
        2,
        "Masahide Matsushita"
      ],
      [
        2,
        "Toshir\u00f4 Mifune"
      ],
      [
        2,
        "Jun Mikami"
      ],
      [
        2,
        "Sanpei Mine"
      ],
      [
        2,
        "Chindanji Miyagawa"
      ],
      [
        2,
        "Seiji Miyaguchi"
      ],
      [
        2,
        "Keiko Mori"
      ],
      [
        2,
        "Taiji Naka"
      ],
      [
        2,
        "Tatsuya Nakadai"
      ],
      [
        2,
        "Haruo Nakajima"
      ],
      [
        2,
        "Eisuke Nakanishi"
      ],
      [
        2,
        "Toshiko Nakano"
      ],
      [
        2,
        "Takashi Narita"
      ],
      [
        2,
        "Junpei Natsuki"
      ],
      [
        2,
        "Toranosuke Ogawa"
      ],
      [
        2,
        "Tokio \u00d4kawa"
      ],
      [
        2,
        "Masanobu \u00d4kubo"
      ],
      [
        2,
        "Senkichi \u00d4mura"
      ],
      [
        2,
        "Yasumasa \u00d4nishi"
      ],
      [
        2,
        "Matsue Ono"
      ],
      [
        2,
        "Masako \u00d4shiro"
      ],
      [
        2,
        "Shin \u00d4tomo"
      ],
      [
        2,
        "Hideo \u00d4tsuka"
      ],
      [
        2,
        "Ky\u00f4ko Ozawa"
      ],
      [
        2,
        "Etsuo Saij\u00f4"
      ],
      [
        2,
        "Sachio Sakai"
      ],
      [
        2,
        "Keiji Sakakida"
      ],
      [
        2,
        "Haruya Sakamoto"
      ],
      [
        2,
        "Gor\u00f4 Sakurai"
      ],
      [
        2,
        "Takeshi Seki"
      ],
      [
        2,
        "Noriko Sengoku"
      ],
      [
        2,
        "Hideo Shibuya"
      ],
      [
        2,
        "Yukiko Shimazaki"
      ],
      [
        2,
        "Gen Shimizu"
      ],
      [
        2,
        "Megeru Shimoda"
      ],
      [
        2,
        "Takashi Shimura"
      ],
      [
        2,
        "Hiroshi Sugi"
      ],
      [
        2,
        "Shigemi Sunagawa"
      ],
      [
        2,
        "Misao Suyama"
      ],
      [
        2,
        "Jir\u00f4 Suzukawa"
      ],
      [
        2,
        "Haruo Suzuki"
      ],
      [
        2,
        "Masaaki Tachibana"
      ],
      [
        2,
        "Shinpei Takagi"
      ],
      [
        2,
        "Toriko Takahara"
      ],
      [
        2,
        "Toshio Takahara"
      ],
      [
        2,
        "Akira Tani"
      ],
      [
        2,
        "Jun Tatara"
      ],
      [
        2,
        "Y\u00fbko Togawa"
      ],
      [
        2,
        "Eijir\u00f4 T\u00f4no"
      ],
      [
        2,
        "Haruko Toyama"
      ],
      [
        2,
        "Kamayuki Tsubono"
      ],
      [
        2,
        "Yoshio Tsuchiya"
      ],
      [
        2,
        "Keiko Tsushima"
      ],
      [
        2,
        "Yasuhisa Tsutsumi"
      ],
      [
        2,
        "Kichijir\u00f4 Ueda"
      ],
      [
        2,
        "K\u00f4ji Uno"
      ],
      [
        2,
        "Atsushi Watanabe"
      ],
      [
        2,
        "Akira Yamada"
      ],
      [
        2,
        "Isao Yamagata"
      ]
    ],
    "directors": "Akira Kurosawa",
    "budget_string": "0.3m USD",
    "gross_string": "0.3m USD",
    "metascore_string": "98%"
  },
  {
    "imdb_id": "tt0047296",
    "plot": "An ex-prize fighter turned New Jersey longshoreman struggles to stand up to his corrupt union bosses, including his older brother, as he starts to connect with the grieving sister of one of the syndicate's victims.",
    "certification": "Approved",
    "other_wins_count": 22,
    "other_noms_count": 9,
    "amazon": "https://www.amazon.com/Waterfront-Blu-ray-anglais/dp/B001PBD0HS/ref=tmm_blu_title_0?_encoding=UTF8&amp;qid=1656405176&amp;sr=8-1-spons&_encoding=UTF8&tag=sianglu-20&linkCode=ur2&linkId=a8ba3b739e9823280eacbb3c43956f0e&camp=1789&creative=9325\">Waterfront</a>",
    "justwatch": "https://justwatch.com/au/movie/on-the-waterfront?utm_source=share&utm_medium=web",
    "beige_index": 1,
    "beige_angle": 0.0,
    "cast": [
      [
        1,
        "Martin Balsam"
      ],
      [
        1,
        "Don Blackman"
      ],
      [
        1,
        "Rudy Bond"
      ],
      [
        1,
        "Marlon Brando"
      ],
      [
        1,
        "Leif Erickson"
      ],
      [
        1,
        "John F. Hamilton"
      ],
      [
        1,
        "Tony Galento"
      ],
      [
        1,
        "John Heldabrand"
      ],
      [
        1,
        "Pat Henning"
      ],
      [
        1,
        "Lee J. Cobb"
      ],
      [
        1,
        "Arthur Keegan"
      ],
      [
        1,
        "Karl Malden"
      ],
      [
        1,
        "Eva Marie Saint"
      ],
      [
        1,
        "Tami Mauriello"
      ],
      [
        1,
        "Nehemiah Persoff"
      ],
      [
        1,
        "Johnny Seven"
      ],
      [
        1,
        "Abe Simon"
      ],
      [
        1,
        "Rod Steiger"
      ],
      [
        1,
        "Michael V. Gazzo"
      ],
      [
        1,
        "James Westerfield"
      ]
    ],
    "directors": "Elia Kazan",
    "budget_string": "N/A",
    "gross_string": "N/A",
    "metascore_string": "91%"
  },
  {
    "imdb_id": "tt0047396",
    "plot": "A wheelchair-bound photographer spies on his neighbors from his Greenwich Village courtyard apartment window, and becomes convinced one of them has committed murder, despite the skepticism of his fashion-model girlfriend.",
    "certification": "PG",
    "other_wins_count": 6,
    "other_noms_count": 9,
    "amazon": "https://www.amazon.com/Rear-Window-Blu-ray-James-Stewart/dp/B00J2R3W1G/ref=sr_1_3?crid=1U4YINQ1NRRGI&amp;keywords=rear+window&amp;qid=1654832774&amp;s=movies-tv&amp;sprefix=rear+window%252Cmovies-tv-intl-ship%252C576&amp;sr=1-3&_encoding=UTF8&tag=sianglu-20&linkCode=ur2&linkId=f1f8ba036c1f15dbc3cf82882508da4d&camp=1789&creative=9325",
    "justwatch": "https://justwatch.com/us/movie/alfred-hitchcocks-rear-window?utm_source=share&utm_medium=web",
    "beige_index": 1,
    "beige_angle": 12.0006,
    "cast": [
      [
        4,
        "Ross Bagdasarian"
      ],
      [
        1,
        "Sara Berner"
      ],
      [
        1,
        "Raymond Burr"
      ],
      [
        1,
        "Frank Cady"
      ],
      [
        1,
        "Sue Casey"
      ],
      [
        1,
        "Wendell Corey"
      ],
      [
        1,
        "Georgine Darcy"
      ],
      [
        1,
        "Havis Davenport"
      ],
      [
        1,
        "Judith Evelyn"
      ],
      [
        1,
        "Jesslyn Fax"
      ],
      [
        1,
        "Rand Harper"
      ],
      [
        1,
        "Grace Kelly"
      ],
      [
        1,
        "Thelma Ritter"
      ],
      [
        1,
        "James Stewart"
      ],
      [
        1,
        "Irene Winston"
      ]
    ],
    "directors": "Alfred Hitchcock",
    "budget_string": "1m USD",
    "gross_string": "37m USD",
    "metascore_string": "100%"
  },
  {
    "imdb_id": "tt0046912",
    "plot": "A former tennis star arranges the murder of his adulterous wife.",
    "certification": "PG",
    "other_wins_count": 5,
    "other_noms_count": 2,
    "amazon": "https://www.amazon.com/Dial-M-Murder-3D-Blu-Ray/dp/B07X4TQHDG/ref=tmm_blu_title_0?_encoding=UTF8&amp;amp&amp;qid=&amp;amp&amp;sr=&_encoding=UTF8&tag=sianglu-20&linkCode=ur2&linkId=c11b4d69dddc81eb2f4da38f5b4187ed&camp=1789&creative=9325",
    "justwatch": "https://justwatch.com/us/movie/dial-m-for-murder?utm_source=share&utm_medium=web",
    "beige_index": 1,
    "beige_angle": 0.0,
    "cast": [
      [
        1,
        "George Alderson"
      ],
      [
        1,
        "Patrick Allen"
      ],
      [
        1,
        "Leo Britt"
      ],
      [
        1,
        "Robert Cummings"
      ],
      [
        1,
        "Anthony Dawson"
      ],
      [
        1,
        "Robin Hughes"
      ],
      [
        1,
        "Grace Kelly"
      ],
      [
        1,
        "George Leigh"
      ],
      [
        1,
        "Ray Milland"
      ],
      [
        1,
        "John Williams"
      ]
    ],
    "directors": "Alfred Hitchcock",
    "budget_string": "1.4m USD",
    "gross_string": "<1m USD",
    "metascore_string": "75%"
  },
  {
    "imdb_id": "tt0048473",
    "plot": "Impoverished priest Harihar Ray, dreaming of a better life for himself and his family, leaves his rural Bengal village in search of work.",
    "certification": "Not Rated",
    "other_wins_count": 11,
    "other_noms_count": 1,
    "amazon": "https://www.amazon.com/Pather-Panchali-Subir-Bannerjee/dp/B0000C9JFR/ref=sr_1_6?crid=LN9DZT8Q7SQM&amp;keywords=pather+panchali&amp;qid=1655184281&amp;sprefix=pather+panchali+%252Caps%252C272&amp;sr=8-6&_encoding=UTF8&tag=sianglu-20&linkCode=ur2&linkId=b2869c3bc813b75c33a23ee47cccdefb&camp=1789&creative=9325",
    "justwatch": "https://justwatch.com/us/movie/pather-panchali?utm_source=share&utm_medium=web",
    "beige_index": 5,
    "beige_angle": 149.1428571429,
    "cast": [
      [
        5,
        "Subir Banerjee"
      ],
      [
        5,
        "Runki Banerjee"
      ],
      [
        5,
        "Haren Banerjee"
      ],
      [
        5,
        "Kanu Bannerjee"
      ],
      [
        5,
        "Karuna Bannerjee"
      ],
      [
        5,
        "Tulsi Chakraborty"
      ],
      [
        5,
        "Rampada Das"
      ],
      [
        5,
        "Uma Das Gupta"
      ],
      [
        5,
        "Chunibala Devi"
      ],
      [
        5,
        "Reba Devi"
      ],
      [
        5,
        "Aparna Devi"
      ],
      [
        5,
        "Nibhanani Devi"
      ],
      [
        5,
        "Rama Gangopadhaya"
      ],
      [
        5,
        "Roma Ganguli"
      ],
      [
        5,
        "Binoy Mukherjee"
      ],
      [
        5,
        "Haridhan Nag"
      ],
      [
        5,
        "Harimohan Nag"
      ],
      [
        5,
        "Kshirod Roy"
      ],
      [
        5,
        "Suren Roy"
      ]
    ],
    "directors": "Satyajit Ray",
    "budget_string": "N/A",
    "gross_string": "0.1m USD",
    "metascore_string": "N/A"
  },
  {
    "imdb_id": "tt0050212",
    "plot": "British POWs are forced to build a railway bridge across the river Kwai for their Japanese captors in occupied Burma, not knowing that the allied forces are planning a daring commando raid through the jungle to destroy it.",
    "certification": "PG",
    "other_wins_count": 23,
    "other_noms_count": 8,
    "amazon": "https://www.amazon.com/Bridge-River-Kwai-Blu-ray/dp/B004SUDPWI/ref=sr_1_1?crid=10WIOFVH2HHKQ&amp;keywords=bridge+on+the+river+kwai+blu+ray&amp;qid=1655181234&amp;s=movies-tv&amp;sprefix=bridge+on+the+river+kwai+blu+ray%252Cmovies-tv%252C278&amp;sr=1-1&_encoding=UTF8&tag=sianglu-20&linkCode=ur2&linkId=eb370695e865a653284f3a07c0af1aab&camp=1789&creative=9325",
    "justwatch": "https://justwatch.com/us/movie/the-bridge-on-the-river-kwai?utm_source=share&utm_medium=web",
    "beige_index": 2,
    "beige_angle": 40.2633,
    "cast": [
      [
        3,
        "Kannikar Dowklee"
      ],
      [
        3,
        "Javanart Punynchoti"
      ],
      [
        3,
        "Vilaiwan Seeboonreaung"
      ],
      [
        3,
        "Ngamta Suphaphongs"
      ],
      [
        3,
        "M.R.B. Chakrabandhu"
      ],
      [
        2,
        "Sessue Hayakawa"
      ],
      [
        2,
        "Keiichir\u00f4 Katsumoto"
      ],
      [
        2,
        "Heihachir\u00f4 \u00d4kawa"
      ],
      [
        1,
        "John Boxer"
      ],
      [
        1,
        "James Donald"
      ],
      [
        1,
        "Harold Goodwin"
      ],
      [
        1,
        "Alec Guinness"
      ],
      [
        1,
        "Jack Hawkins"
      ],
      [
        1,
        "Percy Herbert"
      ],
      [
        1,
        "William Holden"
      ],
      [
        1,
        "Geoffrey Horne"
      ],
      [
        1,
        "Andr\u00e9 Morell"
      ],
      [
        1,
        "Ann Sears"
      ],
      [
        1,
        "Peter Williams"
      ]
    ],
    "directors": "David Lean",
    "budget_string": "3m USD",
    "gross_string": "27.2m USD",
    "metascore_string": "87%"
  },
  {
    "imdb_id": "tt0050986",
    "plot": "After living a life marked by coldness, an aging professor is forced to confront the emptiness of his existence.",
    "certification": "Not Rated",
    "other_wins_count": 18,
    "other_noms_count": 3,
    "amazon": "https://www.amazon.com/Wild-Strawberries-Criterion-Collection-Blu-ray/dp/B00BX49B0C/ref=sr_1_1?crid=3MZJX6MG7J173&amp;keywords=wild+strawberries+blu+ray&amp;qid=1655182762&amp;sprefix=wild+strawberries+blu+ray%252Caps%252C285&amp;sr=8-1&_encoding=UTF8&tag=sianglu-20&linkCode=ur2&linkId=63e4e9dca9b25f31b16934c15587ef57&camp=1789&creative=9325",
    "justwatch": "https://justwatch.com/us/movie/wild-strawberries?utm_source=share&utm_medium=web",
    "beige_index": 1,
    "beige_angle": 0.0,
    "cast": [
      [
        1,
        "Bibi Andersson"
      ],
      [
        1,
        "Lena Bergman"
      ],
      [
        1,
        "Bj\u00f6rn Bjelfvenstam"
      ],
      [
        1,
        "Gunnar Bj\u00f6rnstrand"
      ],
      [
        1,
        "Gunnel Brostr\u00f6m"
      ],
      [
        1,
        "Monica Ehrling"
      ],
      [
        1,
        "\u00c5ke Fridell"
      ],
      [
        1,
        "Gertrud Fridh"
      ],
      [
        1,
        "Maud Hansson"
      ],
      [
        1,
        "Jullan Kindahl"
      ],
      [
        1,
        "Gunnel Lindblom"
      ],
      [
        1,
        "Yngve Nordwall"
      ],
      [
        1,
        "Eva Nor\u00e9e"
      ],
      [
        1,
        "Gunnar Olsson"
      ],
      [
        1,
        "Gio Petr\u00e9"
      ],
      [
        1,
        "Sif Ruud"
      ],
      [
        1,
        "Gunnar Sj\u00f6berg"
      ],
      [
        1,
        "Per Sj\u00f6strand"
      ],
      [
        1,
        "Victor Sj\u00f6str\u00f6m"
      ],
      [
        1,
        "Folke Sundquist"
      ],
      [
        1,
        "Ingrid Thulin"
      ],
      [
        1,
        "Max von Sydow"
      ],
      [
        1,
        "Naima Wifstrand"
      ],
      [
        1,
        "Ann-Marie Wiman"
      ]
    ],
    "directors": "Ingmar Bergman",
    "budget_string": "N/A",
    "gross_string": "<1m USD",
    "metascore_string": "88%"
  },
  {
    "imdb_id": "tt0051201",
    "plot": "A veteran British barrister must defend his client in a murder trial that has surprise after surprise.",
    "certification": "Approved",
    "other_wins_count": 4,
    "other_noms_count": 10,
    "amazon": "https://www.amazon.com/Witness-Prosecution-Blu-ray-Tyrone-Power/dp/B00K6D1S7A/ref=tmm_mfc_swatch_0?_encoding=UTF8&amp;qid=1654842149&amp;sr=1-4&_encoding=UTF8&tag=sianglu-20&linkCode=ur2&linkId=efd86c1d47039ab63546ec260a63d9bd&camp=1789&creative=9325",
    "justwatch": "https://justwatch.com/us/movie/witness-for-the-prosecution?utm_source=share&utm_medium=web",
    "beige_index": 1,
    "beige_angle": 0.0,
    "cast": [
      [
        1,
        "Francis Compton"
      ],
      [
        1,
        "Henry Daniell"
      ],
      [
        1,
        "Marlene Dietrich"
      ],
      [
        1,
        "Franklyn Farnum"
      ],
      [
        1,
        "Elsa Lanchester"
      ],
      [
        1,
        "Charles Laughton"
      ],
      [
        1,
        "Ruta Lee"
      ],
      [
        1,
        "Una O'Connor"
      ],
      [
        1,
        "Tyrone Power"
      ],
      [
        1,
        "Torin Thatcher"
      ],
      [
        1,
        "Philip Tonge"
      ],
      [
        1,
        "Norma Varden"
      ],
      [
        1,
        "John Williams"
      ],
      [
        1,
        "Ian Wolfe"
      ],
      [
        1,
        "Ben Wright"
      ]
    ],
    "directors": "Billy Wilder",
    "budget_string": "3m USD",
    "gross_string": "<1m USD",
    "metascore_string": "N/A"
  },
  {
    "imdb_id": "tt0050825",
    "plot": "After refusing to attack an enemy position, a general accuses the soldiers of cowardice and their commanding officer must defend them.",
    "certification": "Approved",
    "other_wins_count": 7,
    "other_noms_count": 3,
    "amazon": "https://www.amazon.com/Paths-Glory-Criterion-Collection-Blu-ray/dp/B003WKL6YO/ref=tmm_blu_swatch_0?_encoding=UTF8&amp;qid=1654841859&amp;sr=1-4&_encoding=UTF8&tag=sianglu-20&linkCode=ur2&linkId=7796333bd82e6c5d47667dda779cf257&camp=1789&creative=9325",
    "justwatch": "https://justwatch.com/us/movie/paths-of-glory?utm_source=share&utm_medium=web",
    "beige_index": 1,
    "beige_angle": 10.5876,
    "cast": [
      [
        4,
        "Kem Dibbs"
      ],
      [
        1,
        "Richard Anderson"
      ],
      [
        1,
        "Fred Bell"
      ],
      [
        1,
        "Harold Benedict"
      ],
      [
        1,
        "Peter Capell"
      ],
      [
        1,
        "Timothy Carey"
      ],
      [
        1,
        "Kirk Douglas"
      ],
      [
        1,
        "Bert Freed"
      ],
      [
        1,
        "Jerry Hausner"
      ],
      [
        1,
        "Christiane Kubrick"
      ],
      [
        1,
        "George Macready"
      ],
      [
        1,
        "Ralph Meeker"
      ],
      [
        1,
        "Adolphe Menjou"
      ],
      [
        1,
        "Emile Meyer"
      ],
      [
        1,
        "Wayne Morris"
      ],
      [
        1,
        "John Stein"
      ],
      [
        1,
        "Joe Turkel"
      ]
    ],
    "directors": "Stanley Kubrick",
    "budget_string": "0.9m USD",
    "gross_string": "<1m USD",
    "metascore_string": "90%"
  },
  {
    "imdb_id": "tt0050083",
    "plot": "The jury in a New York City murder trial is frustrated by a single member whose skeptical caution forces them to more carefully consider the evidence before jumping to a hasty verdict.",
    "certification": "Approved",
    "other_wins_count": 17,
    "other_noms_count": 10,
    "amazon": "https://www.amazon.com/12-Angry-Men-Henry-Fonda/dp/B001MLUHXQ/ref=sr_1_1?crid=KLRPI2AL8L7Z&amp;keywords=12+angry+men+movie&amp;qid=1654825733&amp;sprefix=12+angry+men%252Caps%252C493&amp;sr=8-1&_encoding=UTF8&tag=sianglu-20&linkCode=ur2&linkId=5d773791dc760f4953e3dffe38758555&camp=1789&creative=9325",
    "justwatch": "https://justwatch.com/us/movie/12-angry-men?utm_source=share&utm_medium=web",
    "beige_index": 1,
    "beige_angle": 0.0,
    "cast": [
      [
        1,
        "Martin Balsam"
      ],
      [
        1,
        "Ed Begley"
      ],
      [
        1,
        "Edward Binns"
      ],
      [
        1,
        "Rudy Bond"
      ],
      [
        1,
        "John Fiedler"
      ],
      [
        1,
        "Henry Fonda"
      ],
      [
        1,
        "Lee J. Cobb"
      ],
      [
        1,
        "Jack Klugman"
      ],
      [
        1,
        "E.G. Marshall"
      ],
      [
        1,
        "Joseph Sweeney"
      ],
      [
        1,
        "George Voskovec"
      ],
      [
        1,
        "Jack Warden"
      ],
      [
        1,
        "Robert Webber"
      ]
    ],
    "directors": "Sidney Lumet",
    "budget_string": "0.3m USD",
    "gross_string": "<1m USD",
    "metascore_string": "96%"
  },
  {
    "imdb_id": "tt0050976",
    "plot": "A knight returning to Sweden after the Crusades seeks answers about life, death, and the existence of God as he plays chess against the Grim Reaper during the Black Plague.",
    "certification": "Not Rated",
    "other_wins_count": 10,
    "other_noms_count": 2,
    "amazon": "https://www.amazon.com/The-Seventh-Seal-UHD-Blu-ray/dp/B09BT5TVVM/ref=tmm_blu_title_0?_encoding=UTF8&amp;amp&amp;qid=&amp;amp&amp;sr=&_encoding=UTF8&tag=sianglu-20&linkCode=ur2&linkId=debbbee9f91a87ee2af4f9add9020105&camp=1789&creative=9325",
    "justwatch": "https://justwatch.com/us/movie/the-seventh-seal?utm_source=share&utm_medium=web",
    "beige_index": 1,
    "beige_angle": 0.0,
    "cast": [
      [
        1,
        "Bertil Anderberg"
      ],
      [
        1,
        "Bibi Andersson"
      ],
      [
        1,
        "Lena Bergman"
      ],
      [
        1,
        "Gunnar Bj\u00f6rnstrand"
      ],
      [
        1,
        "Anders Ek"
      ],
      [
        1,
        "Bengt Ekerot"
      ],
      [
        1,
        "\u00c5ke Fridell"
      ],
      [
        1,
        "Inga Gill"
      ],
      [
        1,
        "Maud Hansson"
      ],
      [
        1,
        "Inga Landgr\u00e9"
      ],
      [
        1,
        "Gunnel Lindblom"
      ],
      [
        1,
        "Mona Malm"
      ],
      [
        1,
        "Gunnar Olsson"
      ],
      [
        1,
        "Nils Poppe"
      ],
      [
        1,
        "G\u00f6sta Pr\u00fczelius"
      ],
      [
        1,
        "Erik Strandmark"
      ],
      [
        1,
        "Max von Sydow"
      ]
    ],
    "directors": "Ingmar Bergman",
    "budget_string": "0.1m USD",
    "gross_string": "0.3m USD",
    "metascore_string": "88%"
  },
  {
    "imdb_id": "tt0052357",
    "plot": "A former San Francisco police detective juggles wrestling with his personal demons and becoming obsessed with the hauntingly beautiful woman he has been hired to trail, who may be deeply disturbed.",
    "certification": "PG",
    "other_wins_count": 8,
    "other_noms_count": 5,
    "amazon": "https://www.amazon.com/Vertigo-Blu-ray-James-Stewart/dp/B00J2R3VZI/ref=sr_1_2?crid=20QSBDUL6P2PV&amp;keywords=vertigo&amp;qid=1654845204&amp;s=movies-tv&amp;sprefix=vertigo%252Cmovies-tv%252C471&amp;sr=1-2&_encoding=UTF8&tag=sianglu-20&linkCode=ur2&linkId=8ec037e7c36ede4ed28da741f2ae944f&camp=1789&creative=9325",
    "justwatch": "https://justwatch.com/us/movie/vertigo?utm_source=share&utm_medium=web",
    "beige_index": 1,
    "beige_angle": 0.0,
    "cast": [
      [
        1,
        "Raymond Bailey"
      ],
      [
        1,
        "Barbara Bel Geddes"
      ],
      [
        1,
        "Ellen Corby"
      ],
      [
        1,
        "Tom Helmore"
      ],
      [
        1,
        "Henry Jones"
      ],
      [
        1,
        "Kim Novak"
      ],
      [
        1,
        "Lee Patrick"
      ],
      [
        1,
        "Konstantin Shayne"
      ],
      [
        1,
        "James Stewart"
      ]
    ],
    "directors": "Alfred Hitchcock",
    "budget_string": "2.5m USD",
    "gross_string": "7.8m USD",
    "metascore_string": "100%"
  },
  {
    "imdb_id": "tt0052618",
    "plot": "After a Jewish prince is betrayed and sent into slavery by a Roman friend in 1st-century Jerusalem, he regains his freedom and comes back for revenge.",
    "certification": "G",
    "other_wins_count": 18,
    "other_noms_count": 16,
    "amazon": "https://www.amazon.com/Ben-Hur-Blu-ray/dp/B007Q1UAJC/ref=tmm_blu_title_0?_encoding=UTF8&amp;qid=1655182633&amp;sr=8-1-spons&_encoding=UTF8&tag=sianglu-20&linkCode=ur2&linkId=32dfe976879f4a339b9f23240cf21606&camp=1789&creative=9325",
    "justwatch": "https://justwatch.com/us/movie/ben-hur?utm_source=share&utm_medium=web",
    "beige_index": 1,
    "beige_angle": 0.0,
    "cast": [
      [
        1,
        "Stephen Boyd"
      ],
      [
        1,
        "Finlay Currie"
      ],
      [
        1,
        "Hugh Griffith"
      ],
      [
        1,
        "Richard Hale"
      ],
      [
        1,
        "Haya Harareet"
      ],
      [
        1,
        "Jack Hawkins"
      ],
      [
        1,
        "Charlton Heston"
      ],
      [
        1,
        "Sam Jaffe"
      ],
      [
        1,
        "Tutte Lemkow"
      ],
      [
        1,
        "Terence Longdon"
      ],
      [
        1,
        "Ferdy Mayne"
      ],
      [
        1,
        "Andr\u00e9 Morell"
      ],
      [
        1,
        "Cathy O'Donnell"
      ],
      [
        1,
        "George Relph"
      ],
      [
        1,
        "Martha Scott"
      ],
      [
        1,
        "Aldo Silvani"
      ],
      [
        1,
        "Frank Thring"
      ]
    ],
    "directors": "William Wyler",
    "budget_string": "15m USD",
    "gross_string": "74.4m USD",
    "metascore_string": "90%"
  },
  {
    "imdb_id": "tt0053198",
    "plot": "A young boy, left without attention, delves into a life of petty crime.",
    "certification": "Not Rated",
    "other_wins_count": 10,
    "other_noms_count": 6,
    "amazon": "https://www.amazon.com/400-Blows-Criterion-Collection-Blu-ray/dp/B01M31SJY3/ref=sr_1_1?crid=1U7QFJJ4PXQZ6&amp;keywords=400+blows+blu+ray&amp;qid=1655193741&amp;s=movies-tv&amp;sprefix=400+blows+blu+ray%252Cmovies-tv%252C273&amp;sr=1-1&_encoding=UTF8&tag=sianglu-20&linkCode=ur2&linkId=b48fc1b494ed8584d16a13c2963c5799&camp=1789&creative=9325",
    "justwatch": "https://justwatch.com/us/movie/the-400-blows?utm_source=share&utm_medium=web",
    "beige_index": 1,
    "beige_angle": 0.0,
    "cast": [
      [
        1,
        "Bouchon"
      ],
      [
        1,
        "Bernard Abbou"
      ],
      [
        1,
        "Luc Andrieux"
      ],
      [
        1,
        "Patrick Auffay"
      ],
      [
        1,
        "Robert Beauvais"
      ],
      [
        1,
        "Jean-Fran\u00e7ois Bergouignan"
      ],
      [
        1,
        "Jean-Claude Brialy"
      ],
      [
        1,
        "Christian Brocard"
      ],
      [
        1,
        "Yvonne Claudie"
      ],
      [
        1,
        "Daniel Couturier"
      ],
      [
        1,
        "Guy Decomble"
      ],
      [
        1,
        "Georges Flamant"
      ],
      [
        1,
        "Renaud Fontanarosa"
      ],
      [
        1,
        "Michel Girard"
      ],
      [
        1,
        "Richard Kanayan"
      ],
      [
        1,
        "Marius Laurey"
      ],
      [
        1,
        "Jean-Pierre L\u00e9aud"
      ],
      [
        1,
        "Michel Lesignor"
      ],
      [
        1,
        "Claude Mansard"
      ],
      [
        1,
        "Claire Maurier"
      ],
      [
        1,
        "Serge Moati"
      ],
      [
        1,
        "Jacques Monod"
      ],
      [
        1,
        "Jeanne Moreau"
      ],
      [
        1,
        "Fran\u00e7ois Nocher"
      ],
      [
        1,
        "Albert R\u00e9my"
      ],
      [
        1,
        "Pierre Repp"
      ],
      [
        1,
        "Henri Virlojeux"
      ]
    ],
    "directors": "Fran\u00e7ois Truffaut",
    "budget_string": "N/A",
    "gross_string": "0.1m USD",
    "metascore_string": "N/A"
  },
  {
    "imdb_id": "tt0053291",
    "plot": "After two male musicians witness a mob hit, they flee the state in an all-female band disguised as women, but further complications set in.",
    "certification": "Passed",
    "other_wins_count": 14,
    "other_noms_count": 15,
    "amazon": "https://www.amazon.com/Some-Like-Blu-ray-Marilyn-Monroe/dp/B004TJ1H1E/ref=sr_1_1?crid=1ESU35SA29W7F&amp;keywords=some+like+it+hot+blu+ray&amp;qid=1655169442&amp;sprefix=some+like+it+hot+%252Caps%252C388&amp;sr=8-1&_encoding=UTF8&tag=sianglu-20&linkCode=ur2&linkId=14411fe2d290b3d40d2b7daf4118b6a8&camp=1789&creative=9325",
    "justwatch": "https://justwatch.com/us/movie/some-like-it-hot?utm_source=share&utm_medium=web",
    "beige_index": 1,
    "beige_angle": 0.0,
    "cast": [
      [
        1,
        "Dave Barry"
      ],
      [
        1,
        "Tony Curtis"
      ],
      [
        1,
        "Barbara Drew"
      ],
      [
        1,
        "Joe E. Brown"
      ],
      [
        1,
        "George E. Stone"
      ],
      [
        1,
        "Franklyn Farnum"
      ],
      [
        1,
        "Edward G. Robinson Jr."
      ],
      [
        1,
        "Billy Gray"
      ],
      [
        1,
        "Jack Lemmon"
      ],
      [
        1,
        "Hank Mann"
      ],
      [
        1,
        "Mike Mazurki"
      ],
      [
        1,
        "Marilyn Monroe"
      ],
      [
        1,
        "Pat O'Brien"
      ],
      [
        1,
        "Nehemiah Persoff"
      ],
      [
        1,
        "George Raft"
      ],
      [
        1,
        "Joan Shawlee"
      ],
      [
        1,
        "Beverly Wills"
      ],
      [
        1,
        "Harry Wilson"
      ]
    ],
    "directors": "Billy Wilder",
    "budget_string": "2.9m USD",
    "gross_string": "0.2m USD",
    "metascore_string": "98%"
  },
  {
    "imdb_id": "tt0053125",
    "plot": "A New York City advertising executive goes on the run after being mistaken for a government agent by a group of foreign spies, and falls for a woman whose loyalties he begins to doubt.",
    "certification": "Approved",
    "other_wins_count": 8,
    "other_noms_count": 7,
    "amazon": "https://www.amazon.com/North-Northwest-Blu-ray-Alfred-Hitchcock/dp/B014IAWFU0/ref=sr_1_3?crid=1WZH96EVFNYHI&amp;keywords=north+by+northwest&amp;qid=1654845067&amp;s=movies-tv&amp;sprefix=north+by+northwest%252Cmovies-tv%252C526&amp;sr=1-3&_encoding=UTF8&tag=sianglu-20&linkCode=ur2&linkId=6226d4bff01428dca1c4ba95351bfea1&camp=1789&creative=9325",
    "justwatch": "https://justwatch.com/us/movie/north-by-northwest?utm_source=share&utm_medium=web",
    "beige_index": 1,
    "beige_angle": 0.0,
    "cast": [
      [
        1,
        "Sara Berner"
      ],
      [
        1,
        "Edward Binns"
      ],
      [
        1,
        "Philip Coolidge"
      ],
      [
        1,
        "Robert Ellenstein"
      ],
      [
        1,
        "Jesslyn Fax"
      ],
      [
        1,
        "Leo G. Carroll"
      ],
      [
        1,
        "Cary Grant"
      ],
      [
        1,
        "Josephine Hutchinson"
      ],
      [
        1,
        "Martin Landau"
      ],
      [
        1,
        "Ken Lynch"
      ],
      [
        1,
        "Eva Marie Saint"
      ],
      [
        1,
        "James Mason"
      ],
      [
        1,
        "Patrick McVey"
      ],
      [
        1,
        "Philip Ober"
      ],
      [
        1,
        "Edward Platt"
      ],
      [
        1,
        "Jessie Royce Landis"
      ],
      [
        1,
        "Les Tremayne"
      ],
      [
        1,
        "Adam Williams"
      ]
    ],
    "directors": "Alfred Hitchcock",
    "budget_string": "3.1m USD",
    "gross_string": "0.1m USD",
    "metascore_string": "98%"
  },
  {
    "imdb_id": "tt0054215",
    "plot": "A Phoenix secretary embezzles $40,000 from her employer's client, goes on the run, and checks into a remote motel run by a young man under the domination of his mother.",
    "certification": "R",
    "other_wins_count": 7,
    "other_noms_count": 10,
    "amazon": "https://www.amazon.com/Psycho-Blu-ray-Anthony-Perkins/dp/B08CPLDRQH/ref=tmm_blu_swatch_0?_encoding=UTF8&amp;qid=1654829793&amp;sr=8-11&_encoding=UTF8&tag=sianglu-20&linkCode=ur2&linkId=6726a582ac697ae7307c5359a8c6b829&camp=1789&creative=9325",
    "justwatch": "https://justwatch.com/us/movie/psycho?utm_source=share&utm_medium=web",
    "beige_index": 1,
    "beige_angle": 0.0,
    "cast": [
      [
        1,
        "Frank Albertson"
      ],
      [
        1,
        "John Anderson"
      ],
      [
        1,
        "Martin Balsam"
      ],
      [
        1,
        "John Gavin"
      ],
      [
        1,
        "Patricia Hitchcock"
      ],
      [
        1,
        "Janet Leigh"
      ],
      [
        1,
        "John McIntire"
      ],
      [
        1,
        "Vera Miles"
      ],
      [
        1,
        "Mort Mills"
      ],
      [
        1,
        "Simon Oakland"
      ],
      [
        1,
        "Anthony Perkins"
      ],
      [
        1,
        "Vaughn Taylor"
      ],
      [
        1,
        "Lurene Tuttle"
      ]
    ],
    "directors": "Alfred Hitchcock",
    "budget_string": "0.8m USD",
    "gross_string": "32.1m USD",
    "metascore_string": "97%"
  },
  {
    "imdb_id": "tt0053604",
    "plot": "A Manhattan insurance clerk tries to rise in his company by letting its executives use his apartment for trysts, but complications and a romance of his own ensue.",
    "certification": "Approved",
    "other_wins_count": 19,
    "other_noms_count": 8,
    "amazon": "https://www.amazon.com/Apartment-Billy-Wilder/dp/B09Q93HV1L/ref=sr_1_4?crid=HGRNHDQTTHGD&amp;keywords=the+apartment&amp;qid=1654931168&amp;s=movies-tv&amp;sprefix=the+%252Cmovies-tv%252C1255&amp;sr=1-4&_encoding=UTF8&tag=sianglu-20&linkCode=ur2&linkId=0898b25666426cb8cb232c526bd7bb95&camp=1789&creative=9325",
    "justwatch": "https://justwatch.com/us/movie/the-apartment?utm_source=share&utm_medium=web",
    "beige_index": 1,
    "beige_angle": 0.0,
    "cast": [
      [
        1,
        "Edie Adams"
      ],
      [
        1,
        "Bill Baldwin"
      ],
      [
        1,
        "Franklyn Farnum"
      ],
      [
        1,
        "Hope Holiday"
      ],
      [
        1,
        "Joyce Jameson"
      ],
      [
        1,
        "Jack Kruschen"
      ],
      [
        1,
        "Jack Lemmon"
      ],
      [
        1,
        "David Lewis"
      ],
      [
        1,
        "Shirley MacLaine"
      ],
      [
        1,
        "Fred MacMurray"
      ],
      [
        1,
        "Johnny Seven"
      ],
      [
        1,
        "Joan Shawlee"
      ],
      [
        1,
        "Naomi Stevens"
      ],
      [
        1,
        "Ray Walston"
      ],
      [
        1,
        "Willard Waterman"
      ],
      [
        1,
        "David White"
      ]
    ],
    "directors": "Billy Wilder",
    "budget_string": "3m USD",
    "gross_string": "18.8m USD",
    "metascore_string": "94%"
  },
  {
    "imdb_id": "tt0055630",
    "plot": "A crafty ronin comes to a town divided by two criminal gangs and decides to play them against each other to free the town.",
    "certification": "Not Rated",
    "other_wins_count": 4,
    "other_noms_count": 1,
    "amazon": "https://www.amazon.com/Yojimbo-Criterion-Collection-Blu-ray-Toshir%25C3%25B4/dp/B00319HT9W/ref=sr_1_2?crid=WLHXH5NYEHN0&amp;keywords=yojimbo+blu+ray&amp;qid=1655175112&amp;sprefix=yojimbo+blu+ray%252Caps%252C291&amp;sr=8-2&_encoding=UTF8&tag=sianglu-20&linkCode=ur2&linkId=ef6ea499fb6b27d14215de1b2fa4c8b2&camp=1789&creative=9325",
    "justwatch": "https://justwatch.com/us/movie/the-bodyguard-1961?utm_source=share&utm_medium=web",
    "beige_index": 2,
    "beige_angle": 45.0,
    "cast": [
      [
        2,
        "Hideyo Amamoto"
      ],
      [
        2,
        "Ichir\u00f4 Chiba"
      ],
      [
        2,
        "Susumu Fujita"
      ],
      [
        2,
        "Kamatari Fujiwara"
      ],
      [
        2,
        "Sh\u00f4ichi Hirose"
      ],
      [
        2,
        "Noriko Honma"
      ],
      [
        2,
        "Fumiyoshi Kamagaya"
      ],
      [
        2,
        "Daisuke Kat\u00f4"
      ],
      [
        2,
        "Takeshi Kat\u00f4"
      ],
      [
        2,
        "Michiko Kawa"
      ],
      [
        2,
        "Seizabur\u00f4 Kawazu"
      ],
      [
        2,
        "Nadao Kirino"
      ],
      [
        2,
        "Fumio Kogushi"
      ],
      [
        2,
        "Naoya Kusakawa"
      ],
      [
        2,
        "Akio Kusama"
      ],
      [
        2,
        "Toshir\u00f4 Mifune"
      ],
      [
        2,
        "Hiromi Mineoka"
      ],
      [
        2,
        "Jun'ichir\u00f4 Mukai"
      ],
      [
        2,
        "Tatsuya Nakadai"
      ],
      [
        2,
        "Haruo Nakajima"
      ],
      [
        2,
        "Ichir\u00f4 Nakatani"
      ],
      [
        2,
        "Y\u00f4suke Natsuki"
      ],
      [
        2,
        "K\u00f4 Nishimura"
      ],
      [
        2,
        "Ryusuke Nishio"
      ],
      [
        2,
        "Rinsaku Ogata"
      ],
      [
        2,
        "Yasuz\u00f4 Ogawa"
      ],
      [
        2,
        "Fuminori \u00d4hashi"
      ],
      [
        2,
        "Sh\u00f4ji \u00d4ki"
      ],
      [
        2,
        "Senkichi \u00d4mura"
      ],
      [
        2,
        "Shin \u00d4tomo"
      ],
      [
        2,
        "Jun \u00d4tomo"
      ],
      [
        2,
        "Tsunagor\u00f4 Rash\u00f4mon"
      ],
      [
        2,
        "Yutaka Sada"
      ],
      [
        2,
        "Sachio Sakai"
      ],
      [
        2,
        "Haruya Sakamoto"
      ],
      [
        2,
        "Ikio Sawamura"
      ],
      [
        2,
        "Ky\u00fb Sazanka"
      ],
      [
        2,
        "Gen Shimizu"
      ],
      [
        2,
        "Takashi Shimura"
      ],
      [
        2,
        "Hiroshi Tachikawa"
      ],
      [
        2,
        "Shinpei Takagi"
      ],
      [
        2,
        "Hiroshi Takagi"
      ],
      [
        2,
        "Akira Tani"
      ],
      [
        2,
        "Yoko Terui"
      ],
      [
        2,
        "Eijir\u00f4 T\u00f4no"
      ],
      [
        2,
        "Yoshio Tsuchiya"
      ],
      [
        2,
        "Y\u00f4ko Tsukasa"
      ],
      [
        2,
        "Atsushi Watanabe"
      ],
      [
        2,
        "Isuzu Yamada"
      ],
      [
        2,
        "Hiroshi Yoseyama"
      ]
    ],
    "directors": "Akira Kurosawa",
    "budget_string": "N/A",
    "gross_string": "<1m USD",
    "metascore_string": "93%"
  },
  {
    "imdb_id": "tt0055031",
    "plot": "In 1948, an American court in occupied Germany tries four Nazis judged for war crimes.",
    "certification": "Approved",
    "other_wins_count": 14,
    "other_noms_count": 25,
    "amazon": "https://www.amazon.com/Judgment-at-Nuremberg-Special-Blu-ray/dp/B0776K7CPW/ref=sr_1_1?crid=2U5IIMW679LG5&amp;keywords=judgment+at+nuremberg+blu+ray&amp;qid=1655174232&amp;sprefix=judgment+at+nuremberg+blu+ray%252Caps%252C509&amp;sr=8-1&_encoding=UTF8&tag=sianglu-20&linkCode=ur2&linkId=ab69da102cdd3af226e75fd5be998bca&camp=1789&creative=9325",
    "justwatch": "https://justwatch.com/us/movie/judgment-at-nuremberg?utm_source=share&utm_medium=web",
    "beige_index": 1,
    "beige_angle": 0.0,
    "cast": [
      [
        1,
        "Alan Baxter"
      ],
      [
        1,
        "Joseph Bernard"
      ],
      [
        1,
        "Edward Binns"
      ],
      [
        1,
        "Martin Brandt"
      ],
      [
        1,
        "Paul Busch"
      ],
      [
        1,
        "Howard Caine"
      ],
      [
        1,
        "Virginia Christine"
      ],
      [
        1,
        "Montgomery Clift"
      ],
      [
        1,
        "Marlene Dietrich"
      ],
      [
        1,
        "Olga Fabian"
      ],
      [
        1,
        "Judy Garland"
      ],
      [
        1,
        "Bernard Kates"
      ],
      [
        1,
        "Werner Klemperer"
      ],
      [
        1,
        "Burt Lancaster"
      ],
      [
        1,
        "Kenneth MacKenna"
      ],
      [
        1,
        "Torben Meyer"
      ],
      [
        1,
        "Maximilian Schell"
      ],
      [
        1,
        "William Shatner"
      ],
      [
        1,
        "Karl Swenson"
      ],
      [
        1,
        "Ray Teal"
      ],
      [
        1,
        "Spencer Tracy"
      ],
      [
        1,
        "Otto Waldis"
      ],
      [
        1,
        "John Wengraf"
      ],
      [
        1,
        "Richard Widmark"
      ],
      [
        1,
        "Ben Wright"
      ]
    ],
    "directors": "Stanley Kramer",
    "budget_string": "3m USD",
    "gross_string": "<1m USD",
    "metascore_string": "60%"
  },
  {
    "imdb_id": "tt0056058",
    "plot": "When a ronin requesting seppuku at a feudal lord's palace is told of the brutal suicide of another ronin who previously visited, he reveals how their pasts are intertwined - and in doing so challenges the clan's integrity.",
    "certification": "Not Rated",
    "other_wins_count": 9,
    "other_noms_count": 3,
    "amazon": "https://www.amazon.com/Harakiri-Criterion-Collection-Blu-ray-Tatsuya/dp/B005D0RDCU/ref=sr_1_17?crid=3R1W00UHHK5J6&amp;keywords=hara-kiri&amp;qid=1654832384&amp;s=movies-tv&amp;sprefix=hara-kiri%252Cmovies-tv-intl-ship%252C528&amp;sr=1-17&_encoding=UTF8&tag=sianglu-20&linkCode=ur2&linkId=f8bf2217fbc74f1ff1b331d004a3ea04&camp=1789&creative=9325",
    "justwatch": "https://justwatch.com/us/movie/harakiri?utm_source=share&utm_medium=web",
    "beige_index": 2,
    "beige_angle": 45.0,
    "cast": [
      [
        2,
        "Shichisabur\u00f4 Amatsu"
      ],
      [
        2,
        "Yoshir\u00f4 Aoki"
      ],
      [
        2,
        "J\u00f4 Azumi"
      ],
      [
        2,
        "Ry\u00fbtar\u00f4 Gomi"
      ],
      [
        2,
        "Sh\u00f4tar\u00f4 Hayashi"
      ],
      [
        2,
        "Kenji Hayashi"
      ],
      [
        2,
        "K\u00f4ichi Hayashi"
      ],
      [
        2,
        "Hisashi Igawa"
      ],
      [
        2,
        "Tsuneo Ikeda"
      ],
      [
        2,
        "Yoshio Inaba"
      ],
      [
        2,
        "Akira Ishihama"
      ],
      [
        2,
        "Shima Iwashita"
      ],
      [
        2,
        "Takaaki Kadota"
      ],
      [
        2,
        "Noboru Kasuga"
      ],
      [
        2,
        "Shimez\u00f4 Kataoka"
      ],
      [
        2,
        "Akiji Kobayashi"
      ],
      [
        2,
        "Tetsur\u00f4 Komiyama"
      ],
      [
        2,
        "Tatsuo Matsumura"
      ],
      [
        2,
        "Rentar\u00f4 Mikuni"
      ],
      [
        2,
        "Masao Mishima"
      ],
      [
        2,
        "Minoru Miyagi"
      ],
      [
        2,
        "Tatsuya Nakadai"
      ],
      [
        2,
        "Shin Nakahara"
      ],
      [
        2,
        "Ichir\u00f4 Nakatani"
      ],
      [
        2,
        "Sh\u00fbichir\u00f4 Narita"
      ],
      [
        2,
        "Satoshi Nishida"
      ],
      [
        2,
        "Fumiya Ozawa"
      ],
      [
        2,
        "Kei Sat\u00f4"
      ],
      [
        2,
        "Gen Takasugi"
      ],
      [
        2,
        "K\u00f4nosuke Takemoto"
      ],
      [
        2,
        "T\u00f4ru Takeuchi"
      ],
      [
        2,
        "Kenz\u00f4 Tanaka"
      ],
      [
        2,
        "Tetsur\u00f4 Tanba"
      ],
      [
        2,
        "Nakajir\u00f4 Tomita"
      ],
      [
        2,
        "Ichir\u00f4 Y\u00fbki"
      ]
    ],
    "directors": "Masaki Kobayashi",
    "budget_string": "N/A",
    "gross_string": "<1m USD",
    "metascore_string": "85%"
  },
  {
    "imdb_id": "tt0056592",
    "plot": "Atticus Finch, a widowed lawyer in Depression-era Alabama, defends a black man against an undeserved rape charge, and his children against prejudice.",
    "certification": "Approved",
    "other_wins_count": 11,
    "other_noms_count": 16,
    "amazon": "https://www.amazon.com/Kill-Mockingbird-Blu-ray-Gregory-Peck/dp/B07G1ZHWFS/ref=sr_1_19_sspa?crid=26XT6ZG54HT1Q&amp;keywords=to+kill+a+mockingbird+blu+ray&amp;qid=1655007535&amp;sprefix=to+kill+a+mockingbird%252Caps%252C322&amp;sr=8-19-spons&amp;psc=1&amp;smid=AIRZ6WQ6RO4BW&amp;spLa=ZW5jcnlwdGVkUXVhbGlmaWVyPUEzTUY2Sjg1RFFHR0k3JmVuY3J5cHRlZElkPUEwOTE1MTE1MUk3WUpUWU9aUVNDUyZlbmNyeXB0ZWRBZElkPUEwODg5ODM2MTlNNExJV0Q0TzhEUSZ3aWRnZXROYW1lPXNwX2J0ZiZhY3Rpb249Y2xpY2tSZWRpcmVjdCZkb05vdExvZ0NsaWNrPXRydWU=&_encoding=UTF8&tag=sianglu-20&linkCode=ur2&linkId=f448edf9b2969e9b88b66936c3a9ccb7&camp=1789&creative=9325",
    "justwatch": "https://justwatch.com/us/movie/to-kill-a-mockingbird?utm_source=share&utm_medium=web",
    "beige_index": 2,
    "beige_angle": 45.0,
    "cast": [
      [
        5,
        "Estelle Evans"
      ],
      [
        5,
        "Paulene Myers"
      ],
      [
        5,
        "Brock Peters"
      ],
      [
        1,
        "Phillip Alford"
      ],
      [
        1,
        "James Anderson"
      ],
      [
        1,
        "Mary Badham"
      ],
      [
        1,
        "Crahan Denton"
      ],
      [
        1,
        "Robert Duvall"
      ],
      [
        1,
        "Paul Fix"
      ],
      [
        1,
        "Alice Ghostley"
      ],
      [
        1,
        "Richard Hale"
      ],
      [
        1,
        "John Megna"
      ],
      [
        1,
        "Rosemary Murphy"
      ],
      [
        1,
        "Frank Overton"
      ],
      [
        1,
        "Gregory Peck"
      ],
      [
        1,
        "Ruth White"
      ],
      [
        1,
        "Collin Wilcox Paxton"
      ],
      [
        1,
        "William Windom"
      ]
    ],
    "directors": "Robert Mulligan",
    "budget_string": "2m USD",
    "gross_string": "0.6m USD",
    "metascore_string": "88%"
  },
  {
    "imdb_id": "tt0056172",
    "plot": "The story of T.E. Lawrence, the English officer who successfully united and led the diverse, often warring, Arab tribes during World War I in order to fight the Turks.",
    "certification": "Approved",
    "other_wins_count": 24,
    "other_noms_count": 15,
    "amazon": "https://www.amazon.com/Lawrence-Arabia-Restored-Version-Blu-ray/dp/B008Y1YK0I/ref=sr_1_3?crid=3NB8WFMZJQT2O&amp;keywords=lawrence+of+arabia&amp;qid=1654844883&amp;s=movies-tv&amp;sprefix=lawrence+of+arabia%252Cmovies-tv%252C502&amp;sr=1-3&_encoding=UTF8&tag=sianglu-20&linkCode=ur2&linkId=8fcff42dc530180da1ead20a099f8786&camp=1789&creative=9325",
    "justwatch": "https://justwatch.com/us/movie/lawrence-of-arabia?utm_source=share&utm_medium=web",
    "beige_index": 2,
    "beige_angle": 44.1819,
    "cast": [
      [
        5,
        "I.S. Johar"
      ],
      [
        4,
        "Gamil Ratib"
      ],
      [
        4,
        "Omar Sharif"
      ],
      [
        3,
        "Jos\u00e9 Ferrer"
      ],
      [
        3,
        "Anthony Quinn"
      ],
      [
        3,
        "John Dimech"
      ],
      [
        3,
        "Zia Mohyeddin"
      ],
      [
        2,
        "Michel Ray"
      ],
      [
        1,
        "John Bennett"
      ],
      [
        1,
        "Peter Burton"
      ],
      [
        1,
        "Alec Guinness"
      ],
      [
        1,
        "Jack Gwillim"
      ],
      [
        1,
        "Jack Hawkins"
      ],
      [
        1,
        "Arthur Kennedy"
      ],
      [
        1,
        "Howard Marion-Crawford"
      ],
      [
        1,
        "Hugh Miller"
      ],
      [
        1,
        "Peter O'Toole"
      ],
      [
        1,
        "George Plimpton"
      ],
      [
        1,
        "Anthony Quayle"
      ],
      [
        1,
        "Claude Rains"
      ],
      [
        1,
        "Cyril Shaps"
      ],
      [
        1,
        "Donald Wolfit"
      ]
    ],
    "directors": "David Lean",
    "budget_string": "15m USD",
    "gross_string": "45.7m USD",
    "metascore_string": "100%"
  },
  {
    "imdb_id": "tt0057565",
    "plot": "An executive of a Yokohama shoe company becomes a victim of extortion when his chauffeur's son is kidnapped by mistake and held for ransom.",
    "certification": "Not Rated",
    "other_wins_count": 3,
    "other_noms_count": 3,
    "amazon": "https://www.amazon.com/High-Low-Criterion-Collection-Blu-ray/dp/B004WPYO3I/ref=sr_1_2?crid=3APWJHA2RWV89&amp;keywords=high+and+low&amp;qid=1654844727&amp;s=movies-tv&amp;sprefix=high+and+l%252Cmovies-tv%252C511&amp;sr=1-2&_encoding=UTF8&tag=sianglu-20&linkCode=ur2&linkId=650a5e16c2c8267f797dacd141d21be3&camp=1789&creative=9325",
    "justwatch": "https://justwatch.com/us/movie/high-and-low?utm_source=share&utm_medium=web",
    "beige_index": 2,
    "beige_angle": 45.0,
    "cast": [
      [
        2,
        "Minoru Chiaki"
      ],
      [
        2,
        "Toshio Egi"
      ],
      [
        2,
        "Susumu Fujita"
      ],
      [
        2,
        "Kamatari Fujiwara"
      ],
      [
        2,
        "Jun Hamamura"
      ],
      [
        2,
        "Kenjir\u00f4 Ishiyama"
      ],
      [
        2,
        "Minoru It\u00f4"
      ],
      [
        2,
        "Y\u00fbnosuke It\u00f4"
      ],
      [
        2,
        "Ky\u00f4ko Kagawa"
      ],
      [
        2,
        "Takeshi Kat\u00f4"
      ],
      [
        2,
        "Kazuo Kat\u00f4"
      ],
      [
        2,
        "Isao Kimura"
      ],
      [
        2,
        "Kazuo Kitamura"
      ],
      [
        2,
        "K\u00f4ji Kiyomura"
      ],
      [
        2,
        "Kenji Kodama"
      ],
      [
        2,
        "Jun Kond\u00f4"
      ],
      [
        2,
        "Akio Kusama"
      ],
      [
        2,
        "Yoshisuke Makino"
      ],
      [
        2,
        "Takeo Matsushita"
      ],
      [
        2,
        "Toshir\u00f4 Mifune"
      ],
      [
        2,
        "Tatsuya Mihashi"
      ],
      [
        2,
        "K\u00f4ji Mitsui"
      ],
      [
        2,
        "Akira Nagoya"
      ],
      [
        2,
        "Tatsuya Nakadai"
      ],
      [
        2,
        "Nobuo Nakamura"
      ],
      [
        2,
        "K\u00f4 Nishimura"
      ],
      [
        2,
        "Masao Oda"
      ],
      [
        2,
        "Senkichi \u00d4mura"
      ],
      [
        2,
        "Isao Onoda"
      ],
      [
        2,
        "Yutaka Sada"
      ],
      [
        2,
        "Ikio Sawamura"
      ],
      [
        2,
        "Ky\u00fb Sazanka"
      ],
      [
        2,
        "Masahiko Shimazu"
      ],
      [
        2,
        "Masao Shimizu"
      ],
      [
        2,
        "Gen Shimizu"
      ],
      [
        2,
        "Takashi Shimura"
      ],
      [
        2,
        "Kin Sugai"
      ],
      [
        2,
        "Satoshi Suzuki"
      ],
      [
        2,
        "Haruo Suzuki"
      ],
      [
        2,
        "Seiichi Taguchi"
      ],
      [
        2,
        "Yoshifumi Tajima"
      ],
      [
        2,
        "Jun Tazaki"
      ],
      [
        2,
        "Keiko Tomita"
      ],
      [
        2,
        "Eijir\u00f4 T\u00f4no"
      ],
      [
        2,
        "Yoshio Tsuchiya"
      ],
      [
        2,
        "Hiroshi Unayama"
      ],
      [
        2,
        "Kiyoshi Yamamoto"
      ],
      [
        2,
        "Tsutomu Yamazaki"
      ]
    ],
    "directors": "Akira Kurosawa",
    "budget_string": "N/A",
    "gross_string": "<1m USD",
    "metascore_string": "90%"
  },
  {
    "imdb_id": "tt0057115",
    "plot": "Allied prisoners of war plan for several hundred of their number to escape from a German camp during World War II.",
    "certification": "Approved",
    "other_wins_count": 3,
    "other_noms_count": 10,
    "amazon": "https://www.amazon.com/Great-Escape-Criterion-Collection-Blu-ray/dp/B084TQ3K8K/ref=sr_1_6?crid=1MA2SM708T2S3&amp;keywords=the+great+escape+blu+ray&amp;qid=1655175387&amp;sprefix=the+great+escape+blu+ray%252Caps%252C308&amp;sr=8-6&_encoding=UTF8&tag=sianglu-20&linkCode=ur2&linkId=4a6faaeab2bc456a6be1076ab9a926cf&camp=1789&creative=9325",
    "justwatch": "https://justwatch.com/us/movie/the-great-escape?utm_source=share&utm_medium=web",
    "beige_index": 1,
    "beige_angle": 0.0,
    "cast": [
      [
        1,
        "Tom Adams"
      ],
      [
        1,
        "Karl-Otto Alberty"
      ],
      [
        1,
        "Richard Attenborough"
      ],
      [
        1,
        "Ulrich Beiger"
      ],
      [
        1,
        "Charles Bronson"
      ],
      [
        1,
        "James Coburn"
      ],
      [
        1,
        "Robert Desmond"
      ],
      [
        1,
        "James Donald"
      ],
      [
        1,
        "Robert Freitag"
      ],
      [
        1,
        "James Garner"
      ],
      [
        1,
        "Robert Graf"
      ],
      [
        1,
        "Gordon Jackson"
      ],
      [
        1,
        "Til Kiwe"
      ],
      [
        1,
        "Angus Lennie"
      ],
      [
        1,
        "John Leyton"
      ],
      [
        1,
        "David McCallum"
      ],
      [
        1,
        "Steve McQueen"
      ],
      [
        1,
        "Hannes Messemer"
      ],
      [
        1,
        "George Mikell"
      ],
      [
        1,
        "Lawrence Montaigne"
      ],
      [
        1,
        "Donald Pleasence"
      ],
      [
        1,
        "Hans Reiser"
      ],
      [
        1,
        "Harry Riebauer"
      ],
      [
        1,
        "William Russell"
      ],
      [
        1,
        "Nigel Stock"
      ],
      [
        1,
        "Jud Taylor"
      ],
      [
        1,
        "Heinz Weiss"
      ]
    ],
    "directors": "John Sturges",
    "budget_string": "4m USD",
    "gross_string": "0.2m USD",
    "metascore_string": "86%"
  },
  {
    "imdb_id": "tt0057012",
    "plot": "An insane American general orders a bombing attack on the Soviet Union, triggering a path to nuclear holocaust that a war room full of politicians and generals frantically tries to stop.",
    "certification": "PG",
    "other_wins_count": 14,
    "other_noms_count": 7,
    "amazon": "https://www.amazon.com/Dr-Strangelove-UK-Region-free-Blu-ray/dp/B0036VCJ36/ref=tmm_blu_title_0?_encoding=UTF8&amp;qid=1654842606&amp;sr=1-2-spons&_encoding=UTF8&tag=sianglu-20&linkCode=ur2&linkId=6d0c30fb9e1a170c49590758d776643d&camp=1789&creative=9325",
    "justwatch": "https://justwatch.com/us/movie/dr-strangelove-or-how-i-learned-to-stop-worrying-and-love-the-bomb?utm_source=share&utm_medium=web",
    "beige_index": 1,
    "beige_angle": 16.2,
    "cast": [
      [
        5,
        "James Earl Jones"
      ],
      [
        1,
        "Glenn Beck"
      ],
      [
        1,
        "Frank Berry"
      ],
      [
        1,
        "Peter Bull"
      ],
      [
        1,
        "George C. Scott"
      ],
      [
        1,
        "Jack Creley"
      ],
      [
        1,
        "Hal Galili"
      ],
      [
        1,
        "Sterling Hayden"
      ],
      [
        1,
        "Laurence Herder"
      ],
      [
        1,
        "John McCarthy"
      ],
      [
        1,
        "Robert O'Neil"
      ],
      [
        1,
        "Slim Pickens"
      ],
      [
        1,
        "Tracy Reed"
      ],
      [
        1,
        "Shane Rimmer"
      ],
      [
        1,
        "Peter Sellers"
      ],
      [
        1,
        "Roy Stephens"
      ],
      [
        1,
        "Paul Tamarin"
      ],
      [
        1,
        "Gordon Tanner"
      ],
      [
        1,
        "Burnell Tucker"
      ],
      [
        1,
        "Keenan Wynn"
      ]
    ],
    "directors": "Stanley Kubrick",
    "budget_string": "1.8m USD",
    "gross_string": "9.5m USD",
    "metascore_string": "97%"
  },
  {
    "imdb_id": "tt0059578",
    "plot": "Two bounty hunters with the same intentions team up to track down an escaped Mexican outlaw.",
    "certification": "R",
    "other_wins_count": 0,
    "other_noms_count": 2,
    "amazon": "https://www.amazon.com/Few-Dollars-More-Special-Blu-ray/dp/B07LD4P3BG/ref=sr_1_1?crid=YXZVUNQPPJ84&amp;keywords=for+a+few+dollars+more+blu+ray&amp;qid=1655022848&amp;sprefix=for+a+few+dollars+more+blu+ra%252Caps%252C342&amp;sr=8-1&_encoding=UTF8&tag=sianglu-20&linkCode=ur2&linkId=8aab43f37d97e61732d45e2bf5b26a2a&camp=1789&creative=9325",
    "justwatch": "https://justwatch.com/us/movie/man-with-no-name-2-for-a-few-dollars-more?utm_source=share&utm_medium=web",
    "beige_index": 1,
    "beige_angle": 0.0,
    "cast": [
      [
        1,
        "Tom\u00e1s Blanco"
      ],
      [
        1,
        "Mario Brega"
      ],
      [
        1,
        "Roberto Camardiel"
      ],
      [
        1,
        "Clint Eastwood"
      ],
      [
        1,
        "Joseph Egger"
      ],
      [
        1,
        "Klaus Kinski"
      ],
      [
        1,
        "Mara Krupp"
      ],
      [
        1,
        "Dante Maggio"
      ],
      [
        1,
        "Gian Maria Volont\u00e8"
      ],
      [
        1,
        "Sergio Mendiz\u00e1bal"
      ],
      [
        1,
        "Mario Meniconi"
      ],
      [
        1,
        "Antonio Molino Rojo"
      ],
      [
        1,
        "Panos Papadopulos"
      ],
      [
        1,
        "Luigi Pistilli"
      ],
      [
        1,
        "Diana Rabito"
      ],
      [
        1,
        "Lorenzo Robledo"
      ],
      [
        1,
        "Luis Rodr\u00edguez"
      ],
      [
        1,
        "Aldo Sambrell"
      ],
      [
        1,
        "Benito Stefanelli"
      ],
      [
        1,
        "Giovanni Tarallo"
      ],
      [
        1,
        "Lee Van Cleef"
      ]
    ],
    "directors": "Sergio Leone",
    "budget_string": "0.6m USD",
    "gross_string": "15m USD",
    "metascore_string": "74%"
  },
  {
    "imdb_id": "tt0059742",
    "plot": "A young novitiate is sent by her convent in 1930s Austria to become a governess to the seven children of a widowed naval officer.",
    "certification": "G",
    "other_wins_count": 12,
    "other_noms_count": 13,
    "amazon": "https://www.amazon.com/Sound-Music-50th-Anniversary-Blu-ray/dp/B00S58LEH2/ref=sr_1_1?crid=2UV954DSCMNN&amp;keywords=the+sound+of+music+blu+ray&amp;qid=1655194286&amp;s=movies-tv&amp;sprefix=the+sound+of+music+blu+ray%252Cmovies-tv%252C323&amp;sr=1-1&_encoding=UTF8&tag=sianglu-20&linkCode=ur2&linkId=4adceb660634fab9a0056d26a17913ba&camp=1789&creative=9325",
    "justwatch": "https://justwatch.com/us/movie/the-sound-of-music?utm_source=share&utm_medium=web",
    "beige_index": 1,
    "beige_angle": 0.0,
    "cast": [
      [
        1,
        "Julie Andrews"
      ],
      [
        1,
        "Evadne Baker"
      ],
      [
        1,
        "Charmian Carr"
      ],
      [
        1,
        "Angela Cartwright"
      ],
      [
        1,
        "Duane Chase"
      ],
      [
        1,
        "Nicholas Hammond"
      ],
      [
        1,
        "Richard Haydn"
      ],
      [
        1,
        "Kym Karath"
      ],
      [
        1,
        "Anna Lee"
      ],
      [
        1,
        "Doris Lloyd"
      ],
      [
        1,
        "Heather Menzies-Urich"
      ],
      [
        1,
        "Portia Nelson"
      ],
      [
        1,
        "Marni Nixon"
      ],
      [
        1,
        "Eleanor Parker"
      ],
      [
        1,
        "Christopher Plummer"
      ],
      [
        1,
        "Gilchrist Stuart"
      ],
      [
        1,
        "Daniel Truhitte"
      ],
      [
        1,
        "Debbie Turner"
      ],
      [
        1,
        "Norma Varden"
      ],
      [
        1,
        "Peggy Wood"
      ],
      [
        1,
        "Ben Wright"
      ]
    ],
    "directors": "Robert Wise",
    "budget_string": "8.2m USD",
    "gross_string": "159.4m USD",
    "metascore_string": "63%"
  },
  {
    "imdb_id": "tt0058946",
    "plot": "In the 1950s, fear and violence escalate as the people of Algiers fight for independence from the French government.",
    "certification": "Not Rated",
    "other_wins_count": 9,
    "other_noms_count": 6,
    "amazon": "https://www.amazon.com/Battle-Algiers-Criterion-Collection-Blu-ray/dp/B005152CB4/ref=sr_1_1?crid=3N1OBYFH2USCY&amp;keywords=battle+of+algiers+blu+ray&amp;qid=1655185780&amp;s=movies-tv&amp;sprefix=battle+of+algiers+blu+ra%252Cmovies-tv%252C279&amp;sr=1-1&_encoding=UTF8&tag=sianglu-20&linkCode=ur2&linkId=a3b99b7d25c66f01d3187f106cb817da&camp=1789&creative=9325",
    "justwatch": "https://justwatch.com/us/movie/the-battle-of-algiers?utm_source=share&utm_medium=web",
    "beige_index": 4,
    "beige_angle": 114.8568,
    "cast": [
      [
        4,
        "Mohamed Ben Kassen"
      ],
      [
        4,
        "Fusia El Kader"
      ],
      [
        4,
        "Brahim Hadjadj"
      ],
      [
        4,
        "Samia Kerbash"
      ],
      [
        4,
        "Ugo Paletti"
      ],
      [
        4,
        "Yacef Saadi"
      ],
      [
        1,
        "Jean Martin"
      ]
    ],
    "directors": "Gillo Pontecorvo",
    "budget_string": "0.8m USD",
    "gross_string": "1m USD",
    "metascore_string": "96%"
  },
  {
    "imdb_id": "tt0060196",
    "plot": "A bounty hunting scam joins two men in an uneasy alliance against a third in a race to find a fortune in gold buried in a remote cemetery.",
    "certification": "R",
    "other_wins_count": 3,
    "other_noms_count": 6,
    "amazon": "https://www.amazon.com/Good-Bad-Ugly-4KUHD-Blu-ray/dp/B08TQFXFVH/ref=sr_1_2?crid=17EQCRKC3MMEZ&amp;keywords=the+good+the+bad+and+the+ugly&amp;qid=1654826769&amp;sprefix=the+good+the+bad+and+the+ugly%252Caps%252C746&amp;sr=8-2&_encoding=UTF8&tag=sianglu-20&linkCode=ur2&linkId=4c6a1edb170a99548fe65328b056bc28&camp=1789&creative=9325",
    "justwatch": "https://justwatch.com/us/movie/man-with-no-name-3-the-good-the-bad-and-the-ugly?utm_source=share&utm_medium=web",
    "beige_index": 1,
    "beige_angle": 13.7142,
    "cast": [
      [
        3,
        "Claudio Scarchilli"
      ],
      [
        3,
        "Eli Wallach"
      ],
      [
        1,
        "John Bartha"
      ],
      [
        1,
        "Mario Brega"
      ],
      [
        1,
        "Antonio Casale"
      ],
      [
        1,
        "Antonio Casas"
      ],
      [
        1,
        "Clint Eastwood"
      ],
      [
        1,
        "Aldo Giuffr\u00e8"
      ],
      [
        1,
        "Livio Lorenzon"
      ],
      [
        1,
        "Sergio Mendiz\u00e1bal"
      ],
      [
        1,
        "Antonio Molino Rojo"
      ],
      [
        1,
        "Al Mulock"
      ],
      [
        1,
        "Angelo Novi"
      ],
      [
        1,
        "Enzo Petito"
      ],
      [
        1,
        "Luigi Pistilli"
      ],
      [
        1,
        "Rada Rassimov"
      ],
      [
        1,
        "Lorenzo Robledo"
      ],
      [
        1,
        "Aldo Sambrell"
      ],
      [
        1,
        "Sandro Scarchilli"
      ],
      [
        1,
        "Benito Stefanelli"
      ],
      [
        1,
        "Lee Van Cleef"
      ]
    ],
    "directors": "Sergio Leone",
    "budget_string": "1.2m USD",
    "gross_string": "25.3m USD",
    "metascore_string": "90%"
  },
  {
    "imdb_id": "tt0060827",
    "plot": "A nurse is put in charge of a mute actress and finds that their personae are melding together.",
    "certification": "Not Rated",
    "other_wins_count": 11,
    "other_noms_count": 4,
    "amazon": "https://www.amazon.com/Persona-Criterion-Collection-Blu-ray-Ullmann/dp/B08TR9PL72/ref=tmm_blu_swatch_0?_encoding=UTF8&amp;qid=1655193987&amp;sr=1-4&_encoding=UTF8&tag=sianglu-20&linkCode=ur2&linkId=10a6bd1986921de36a58294653a867a5&camp=1789&creative=9325",
    "justwatch": "https://justwatch.com/us/movie/persona?utm_source=share&utm_medium=web",
    "beige_index": 1,
    "beige_angle": 0.0,
    "cast": [
      [
        1,
        "Bibi Andersson"
      ],
      [
        1,
        "Gunnar Bj\u00f6rnstrand"
      ],
      [
        1,
        "Margaretha Krook"
      ],
      [
        1,
        "Liv Ullmann"
      ]
    ],
    "directors": "Ingmar Bergman",
    "budget_string": "N/A",
    "gross_string": "0.1m USD",
    "metascore_string": "86%"
  },
  {
    "imdb_id": "tt0061512",
    "plot": "A laid back Southern man is sentenced to two years in a rural prison, but refuses to conform.",
    "certification": "GP",
    "other_wins_count": 3,
    "other_noms_count": 10,
    "amazon": "https://www.amazon.com/Cool-Hand-Luke-Blu-ray-Newman/dp/B0017TYPIG/ref=sr_1_1?crid=TM20IOCH0B6L&amp;keywords=cool+hand+luke+blu+ray&amp;qid=1655185968&amp;s=movies-tv&amp;sprefix=cool+hand+luke+blu+ray%252Cmovies-tv%252C275&amp;sr=1-1&_encoding=UTF8&tag=sianglu-20&linkCode=ur2&linkId=7682d374df54a5e3c9a6c8d836b0d0ab&camp=1789&creative=9325",
    "justwatch": "https://justwatch.com/us/movie/cool-hand-luke?utm_source=share&utm_medium=web",
    "beige_index": 1,
    "beige_angle": 0.0,
    "cast": [
      [
        1,
        "Lou Antonio"
      ],
      [
        1,
        "Luke Askew"
      ],
      [
        1,
        "J.D. Cannon"
      ],
      [
        1,
        "Marc Cavell"
      ],
      [
        1,
        "Richard Davalos"
      ],
      [
        1,
        "Harry Dean Stanton"
      ],
      [
        1,
        "Robert Donner"
      ],
      [
        1,
        "Robert Drivas"
      ],
      [
        1,
        "Warren Finnerty"
      ],
      [
        1,
        "Joy Harmon"
      ],
      [
        1,
        "Dennis Hopper"
      ],
      [
        1,
        "Rance Howard"
      ],
      [
        1,
        "Clifton James"
      ],
      [
        1,
        "Buck Kartalian"
      ],
      [
        1,
        "George Kennedy"
      ],
      [
        1,
        "Strother Martin"
      ],
      [
        1,
        "John McLiam"
      ],
      [
        1,
        "Paul Newman"
      ],
      [
        1,
        "Wayne Rogers"
      ],
      [
        1,
        "Charles Tyner"
      ],
      [
        1,
        "Jo Van Fleet"
      ],
      [
        1,
        "Ralph Waite"
      ],
      [
        1,
        "Morgan Woodward"
      ],
      [
        1,
        "Anthony Zerbe"
      ]
    ],
    "directors": "Stuart Rosenberg",
    "budget_string": "3.2m USD",
    "gross_string": "<1m USD",
    "metascore_string": "92%"
  },
  {
    "imdb_id": "tt0062622",
    "plot": "The Monoliths push humanity to reach for the stars; after their discovery in Africa generations ago, the mysterious objects lead mankind on an awesome journey to Jupiter, with the help of H.A.L. 9000: the world's greatest supercomputer.",
    "certification": "G",
    "other_wins_count": 15,
    "other_noms_count": 12,
    "amazon": "https://www.amazon.com/2001-Space-Odyssey-Re-Mastered-Blu-ray/dp/B07KHKWNPW/ref=tmm_blu_swatch_0?_encoding=UTF8&amp;qid=1654844567&amp;sr=1-2&_encoding=UTF8&tag=sianglu-20&linkCode=ur2&linkId=1d044868b5e01f5562c21fdf12d2a672&camp=1789&creative=9325",
    "justwatch": "https://justwatch.com/us/movie/how-the-solar-system-was-won?utm_source=share&utm_medium=web",
    "beige_index": 1,
    "beige_angle": 0.0,
    "cast": [
      [
        1,
        "John Ashley"
      ],
      [
        1,
        "Robert Beatty"
      ],
      [
        1,
        "Glenn Beck"
      ],
      [
        1,
        "Jimmy Bell"
      ],
      [
        1,
        "Ed Bishop"
      ],
      [
        1,
        "Penny Brahms"
      ],
      [
        1,
        "Edwina Carroll"
      ],
      [
        1,
        "David Charkham"
      ],
      [
        1,
        "Simon Davis"
      ],
      [
        1,
        "P\u00e9ter Delm\u00e1r"
      ],
      [
        1,
        "Heather Downham"
      ],
      [
        1,
        "Terry Duggan"
      ],
      [
        1,
        "Keir Dullea"
      ],
      [
        1,
        "Alan Gifford"
      ],
      [
        1,
        "Ann Gillis"
      ],
      [
        1,
        "Danny Grover"
      ],
      [
        1,
        "David Hines"
      ],
      [
        1,
        "Anthony Jackson"
      ],
      [
        1,
        "Gary Lockwood"
      ],
      [
        1,
        "Mike Lovell"
      ],
      [
        1,
        "Scott MacKee"
      ],
      [
        1,
        "Frank Miller"
      ],
      [
        1,
        "Douglas Rain"
      ],
      [
        1,
        "Daniel Richter"
      ],
      [
        1,
        "Leonard Rossiter"
      ],
      [
        1,
        "Sean Sullivan"
      ],
      [
        1,
        "William Sylvester"
      ],
      [
        1,
        "Burnell Tucker"
      ],
      [
        1,
        "Margaret Tyzack"
      ],
      [
        1,
        "Bill Weston"
      ],
      [
        1,
        "Bob Wilyman"
      ],
      [
        1,
        "Richard Woods"
      ]
    ],
    "directors": "Stanley Kubrick",
    "budget_string": "12m USD",
    "gross_string": "65.9m USD",
    "metascore_string": "84%"
  },
  {
    "imdb_id": "tt0064116",
    "plot": "A mysterious stranger with a harmonica joins forces with a notorious desperado to protect a beautiful widow from a ruthless assassin working for the railroad.",
    "certification": "PG-13",
    "other_wins_count": 4,
    "other_noms_count": 5,
    "amazon": "https://www.amazon.com/Once-Upon-Time-West-Blu-ray/dp/B072ZWHY7J/ref=sr_1_2?crid=Z675CXMQHC2S&amp;keywords=once+upon+a+time+in+the+west&amp;qid=1654832511&amp;s=movies-tv&amp;sprefix=once+upon+a+time+in+the+west%252Cmovies-tv-intl-ship%252C485&amp;sr=1-2&_encoding=UTF8&tag=sianglu-20&linkCode=ur2&linkId=8a0c3ba1da29c004982b247cdc48a11b&camp=1789&creative=9325",
    "justwatch": "https://justwatch.com/us/movie/once-upon-a-time-in-the-west?utm_source=share&utm_medium=web",
    "beige_index": 1,
    "beige_angle": 27.5292,
    "cast": [
      [
        5,
        "Woody Strode"
      ],
      [
        3,
        "Claudio Scarchilli"
      ],
      [
        1,
        "Charles Bronson"
      ],
      [
        1,
        "Claudia Cardinale"
      ],
      [
        1,
        "Jack Elam"
      ],
      [
        1,
        "Gabriele Ferzetti"
      ],
      [
        1,
        "Henry Fonda"
      ],
      [
        1,
        "Antonio Molino Rojo"
      ],
      [
        1,
        "Al Mulock"
      ],
      [
        1,
        "Jason Robards"
      ],
      [
        1,
        "Lorenzo Robledo"
      ],
      [
        1,
        "Aldo Sambrell"
      ],
      [
        1,
        "Lionel Stander"
      ],
      [
        1,
        "Benito Stefanelli"
      ],
      [
        1,
        "Paolo Stoppa"
      ],
      [
        1,
        "Frank Wolff"
      ],
      [
        1,
        "Keenan Wynn"
      ]
    ],
    "directors": "Sergio Leone",
    "budget_string": "5m USD",
    "gross_string": "5.4m USD",
    "metascore_string": "80%"
  },
  {
    "imdb_id": "tt0066921",
    "plot": "In the future, a sadistic gang leader is imprisoned and volunteers for a conduct-aversion experiment, but it doesn't go as planned.",
    "certification": "R",
    "other_wins_count": 12,
    "other_noms_count": 20,
    "amazon": "https://www.amazon.com/Clockwork-Orange-Blu-ray-Malcolm-McDowell/dp/B000Q678OO/ref=tmm_blu_swatch_0?_encoding=UTF8&amp;qid=1654930595&amp;sr=1-2&_encoding=UTF8&tag=sianglu-20&linkCode=ur2&linkId=b85394cdd8529fb2bf70c773774a5dd0&camp=1789&creative=9325",
    "justwatch": "https://justwatch.com/us/movie/a-clockwork-orange?utm_source=share&utm_medium=web",
    "beige_index": 1,
    "beige_angle": 0.0,
    "cast": [
      [
        1,
        "Jan Adair"
      ],
      [
        1,
        "Michael Bates"
      ],
      [
        1,
        "Steven Berkoff"
      ],
      [
        1,
        "Gaye Brown"
      ],
      [
        1,
        "Peter Burton"
      ],
      [
        1,
        "Lindsay Campbell"
      ],
      [
        1,
        "Vivienne Chandler"
      ],
      [
        1,
        "Warren Clarke"
      ],
      [
        1,
        "John Clive"
      ],
      [
        1,
        "Richard Connaught"
      ],
      [
        1,
        "Barrie Cookson"
      ],
      [
        1,
        "Adrienne Corri"
      ],
      [
        1,
        "George Coulouris"
      ],
      [
        1,
        "Prudence Drage"
      ],
      [
        1,
        "Carol Drinkwater"
      ],
      [
        1,
        "Carl Duering"
      ],
      [
        1,
        "Paul Farrell"
      ],
      [
        1,
        "Lee Fox"
      ],
      [
        1,
        "Clive Francis"
      ],
      [
        1,
        "Norman Gay"
      ],
      [
        1,
        "Michael Gover"
      ],
      [
        1,
        "Cheryl Grunwald"
      ],
      [
        1,
        "Gillian Hills"
      ],
      [
        1,
        "Adolf Hitler"
      ],
      [
        1,
        "Craig Hunter"
      ],
      [
        1,
        "John J. Carney"
      ],
      [
        1,
        "Shirley Jaffe"
      ],
      [
        1,
        "Miriam Karlin"
      ],
      [
        1,
        "Patrick Magee"
      ],
      [
        1,
        "James Marcus"
      ],
      [
        1,
        "Malcolm McDowell"
      ],
      [
        1,
        "Aubrey Morris"
      ],
      [
        1,
        "David Prowse"
      ],
      [
        1,
        "Godfrey Quigley"
      ],
      [
        1,
        "Sheila Raynor"
      ],
      [
        1,
        "Pat Roach"
      ],
      [
        1,
        "Madge Ryan"
      ],
      [
        1,
        "John Savident"
      ],
      [
        1,
        "Anthony Sharp"
      ],
      [
        1,
        "Philip Stone"
      ],
      [
        1,
        "Michael Tarn"
      ],
      [
        1,
        "Pauline Taylor"
      ],
      [
        1,
        "Margaret Tyzack"
      ],
      [
        1,
        "Virginia Wetherell"
      ],
      [
        1,
        "Neil Wilson"
      ],
      [
        1,
        "Katya Wyeth"
      ]
    ],
    "directors": "Stanley Kubrick",
    "budget_string": "2.2m USD",
    "gross_string": "27m USD",
    "metascore_string": "77%"
  },
  {
    "imdb_id": "tt0068646",
    "plot": "The aging patriarch of an organized crime dynasty in postwar New York City transfers control of his clandestine empire to his reluctant youngest son.",
    "certification": "R",
    "other_wins_count": 28,
    "other_noms_count": 30,
    "amazon": "https://www.amazon.com/Godfather-50th-Anniversary-Blu-ray/dp/B09PP7XLG3/ref=sr_1_3?crid=LK7HII6PZWG1&amp;keywords=the+godfather+blu+ray&amp;qid=1654825171&amp;sprefix=the+godfather%252Caps%252C376&amp;sr=8-3&_encoding=UTF8&tag=sianglu-20&linkCode=ur2&linkId=22158f09b185dec57bfcaf08bb390ed3&camp=1789&creative=9325",
    "justwatch": "https://justwatch.com/us/movie/the-godfather-part-i?utm_source=share&utm_medium=web",
    "beige_index": 1,
    "beige_angle": 0.0,
    "cast": [
      [
        1,
        "Rudy Bond"
      ],
      [
        1,
        "Marlon Brando"
      ],
      [
        1,
        "Richard Bright"
      ],
      [
        1,
        "James Caan"
      ],
      [
        1,
        "John Cazale"
      ],
      [
        1,
        "Franco Citti"
      ],
      [
        1,
        "Richard Conte"
      ],
      [
        1,
        "Roman Coppola"
      ],
      [
        1,
        "Gian-Carlo Coppola"
      ],
      [
        1,
        "Salvatore Corsitto"
      ],
      [
        1,
        "Robert Duvall"
      ],
      [
        1,
        "Corrado Gaipa"
      ],
      [
        1,
        "Ron Gilbert"
      ],
      [
        1,
        "Tony Giorgio"
      ],
      [
        1,
        "Julie Gregg"
      ],
      [
        1,
        "Sterling Hayden"
      ],
      [
        1,
        "Angelo Infanti"
      ],
      [
        1,
        "Diane Keaton"
      ],
      [
        1,
        "Morgana King"
      ],
      [
        1,
        "Al Lettieri"
      ],
      [
        1,
        "Jeannie Linero"
      ],
      [
        1,
        "Tony Lip"
      ],
      [
        1,
        "Tere Livrano"
      ],
      [
        1,
        "Joe Lo Grippo"
      ],
      [
        1,
        "John Marley"
      ],
      [
        1,
        "Al Martino"
      ],
      [
        1,
        "John Martino"
      ],
      [
        1,
        "Joseph Medaglia"
      ],
      [
        1,
        "Lenny Montana"
      ],
      [
        1,
        "Al Pacino"
      ],
      [
        1,
        "Victor Rendina"
      ],
      [
        1,
        "Alex Rocco"
      ],
      [
        1,
        "Tom Rosqui"
      ],
      [
        1,
        "Gianni Russo"
      ],
      [
        1,
        "Richard S. Castellano"
      ],
      [
        1,
        "Vito Scotti"
      ],
      [
        1,
        "Ardell Sheridan"
      ],
      [
        1,
        "Talia Shire"
      ],
      [
        1,
        "Frank Sivero"
      ],
      [
        1,
        "Joe Spinell"
      ],
      [
        1,
        "Simonetta Stefanelli"
      ],
      [
        1,
        "Saro Urz\u00ec"
      ],
      [
        1,
        "Nick Vallelonga"
      ],
      [
        1,
        "Abe Vigoda"
      ]
    ],
    "directors": "Francis Ford Coppola",
    "budget_string": "6m USD",
    "gross_string": "250.3m USD",
    "metascore_string": "100%"
  },
  {
    "imdb_id": "tt0070735",
    "plot": "Two grifters team up to pull off the ultimate con.",
    "certification": "PG",
    "other_wins_count": 11,
    "other_noms_count": 6,
    "amazon": "https://www.amazon.com/Sting-Blu-ray-Robert-Redford/dp/B07PL9ZRJ7/ref=sr_1_1?crid=34SDWWZ6Y5T4P&amp;keywords=the+sting+blu+ray&amp;qid=1655007676&amp;sprefix=the+sting+blu+ray%252Caps%252C287&amp;sr=8-1&_encoding=UTF8&tag=sianglu-20&linkCode=ur2&linkId=7ed64564a13a0b063676ab3125cb64c7&camp=1789&creative=9325",
    "justwatch": "https://justwatch.com/us/movie/the-sting?utm_source=share&utm_medium=web",
    "beige_index": 2,
    "beige_angle": 38.25,
    "cast": [
      [
        5,
        "Ta-Tanisha"
      ],
      [
        5,
        "Robert Earl Jones"
      ],
      [
        5,
        "Avon Long"
      ],
      [
        5,
        "Paulene Myers"
      ],
      [
        1,
        "William 'Billy' Benedict"
      ],
      [
        1,
        "Dimitra Arliss"
      ],
      [
        1,
        "Ed Bakey"
      ],
      [
        1,
        "Leonard Barr"
      ],
      [
        1,
        "Eileen Brennan"
      ],
      [
        1,
        "Jack Collins"
      ],
      [
        1,
        "Larry D. Mann"
      ],
      [
        1,
        "Charles Dierkop"
      ],
      [
        1,
        "Charles Durning"
      ],
      [
        1,
        "Dana Elcar"
      ],
      [
        1,
        "Harold Gould"
      ],
      [
        1,
        "Bob Harks"
      ],
      [
        1,
        "John Heffernan"
      ],
      [
        1,
        "Arch Johnson"
      ],
      [
        1,
        "Jack Kehoe"
      ],
      [
        1,
        "Sally Kirkland"
      ],
      [
        1,
        "Paul Newman"
      ],
      [
        1,
        "Kenneth O'Brien"
      ],
      [
        1,
        "Lee Paul"
      ],
      [
        1,
        "John Quade"
      ],
      [
        1,
        "Robert Redford"
      ],
      [
        1,
        "Ken Sansom"
      ],
      [
        1,
        "Robert Shaw"
      ],
      [
        1,
        "James Sloyan"
      ],
      [
        1,
        "Tom Spratley"
      ],
      [
        1,
        "Brad Sullivan"
      ],
      [
        1,
        "Joe Tornatore"
      ],
      [
        1,
        "Ray Walston"
      ]
    ],
    "directors": "George Roy Hill",
    "budget_string": "5.5m USD",
    "gross_string": "156m USD",
    "metascore_string": "83%"
  },
  {
    "imdb_id": "tt0070047",
    "plot": "When a 12-year-old girl is possessed by a mysterious entity, her mother seeks the help of two priests to save her.",
    "certification": "R",
    "other_wins_count": 14,
    "other_noms_count": 17,
    "amazon": "https://www.amazon.com/Exorcist-BD-Blu-ray-Ellen-McRae/dp/B005KQVE68/ref=sr_1_1?crid=DWZA3ET6GS8B&amp;keywords=the+exorcist+blu+ray&amp;qid=1655185430&amp;s=movies-tv&amp;sprefix=the+exorcist+blu+ray%252Cmovies-tv%252C271&amp;sr=1-1&_encoding=UTF8&tag=sianglu-20&linkCode=ur2&linkId=0e0a17fc11780c078dbc2e44cbb788f1&camp=1789&creative=9325",
    "justwatch": "https://justwatch.com/us/movie/the-exorcist?utm_source=share&utm_medium=web",
    "beige_index": 1,
    "beige_angle": 0.0,
    "cast": [
      [
        1,
        "Thomas Bermingham"
      ],
      [
        1,
        "Linda Blair"
      ],
      [
        1,
        "Ellen Burstyn"
      ],
      [
        1,
        "Roy Cooper"
      ],
      [
        1,
        "Ron Faber"
      ],
      [
        1,
        "Robert Gerringer"
      ],
      [
        1,
        "Barton Heyman"
      ],
      [
        1,
        "Lee J. Cobb"
      ],
      [
        1,
        "Jack MacGowran"
      ],
      [
        1,
        "John Mahon"
      ],
      [
        1,
        "Vasiliki Maliaros"
      ],
      [
        1,
        "Peter Masterson"
      ],
      [
        1,
        "Mercedes McCambridge"
      ],
      [
        1,
        "Jason Miller"
      ],
      [
        1,
        "Donna Mitchell"
      ],
      [
        1,
        "William O'Malley"
      ],
      [
        1,
        "Gina Petrushka"
      ],
      [
        1,
        "Wallace Rooney"
      ],
      [
        1,
        "Rudolf Sch\u00fcndler"
      ],
      [
        1,
        "Arthur Storch"
      ],
      [
        1,
        "Robert Symonds"
      ],
      [
        1,
        "Titos Vandis"
      ],
      [
        1,
        "Max von Sydow"
      ],
      [
        1,
        "Kitty Winn"
      ]
    ],
    "directors": "William Friedkin",
    "budget_string": "11m USD",
    "gross_string": "441.3m USD",
    "metascore_string": "81%"
  },
  {
    "imdb_id": "tt0071315",
    "plot": "A private detective hired to expose an adulterer in 1930s Los Angeles finds himself caught up in a web of deceit, corruption, and murder.",
    "certification": "R",
    "other_wins_count": 20,
    "other_noms_count": 24,
    "amazon": "https://www.amazon.com/Chinatown-Blu-ray-Jack-Nicholson/dp/B06XNQS16Z/ref=tmm_blu_swatch_0?_encoding=UTF8&amp;qid=1655180487&amp;sr=1-1&_encoding=UTF8&tag=sianglu-20&linkCode=ur2&linkId=53bcad7261d86561fa40ca9f4e9030ea&camp=1789&creative=9325",
    "justwatch": "https://justwatch.com/us/movie/chinatown?utm_source=share&utm_medium=web",
    "beige_index": 1,
    "beige_angle": 7.4628,
    "cast": [
      [
        3,
        "Claudio Mart\u00ednez"
      ],
      [
        2,
        "Jerry Fujikawa"
      ],
      [
        2,
        "James Hong"
      ],
      [
        2,
        "Beulah Quo"
      ],
      [
        1,
        "Denny Arnold"
      ],
      [
        1,
        "Richard Bakalyan"
      ],
      [
        1,
        "Jim Burk"
      ],
      [
        1,
        "Fritzi Burr"
      ],
      [
        1,
        "Lee de Broux"
      ],
      [
        1,
        "Faye Dunaway"
      ],
      [
        1,
        "Cecil Elliott"
      ],
      [
        1,
        "C.O. Erickson"
      ],
      [
        1,
        "Bruce Glover"
      ],
      [
        1,
        "Bob Golden"
      ],
      [
        1,
        "Elizabeth Harding"
      ],
      [
        1,
        "Bob Harks"
      ],
      [
        1,
        "John Hillerman"
      ],
      [
        1,
        "Nandu Hinds"
      ],
      [
        1,
        "John Holland"
      ],
      [
        1,
        "Rance Howard"
      ],
      [
        1,
        "John Huston"
      ],
      [
        1,
        "Paul Jenkins"
      ],
      [
        1,
        "Roy Jenson"
      ],
      [
        1,
        "George Justin"
      ],
      [
        1,
        "Charles Knapp"
      ],
      [
        1,
        "Diane Ladd"
      ],
      [
        1,
        "Perry Lopez"
      ],
      [
        1,
        "Joe Mantell"
      ],
      [
        1,
        "Elliott Montgomery"
      ],
      [
        1,
        "Jack Nicholson"
      ],
      [
        1,
        "James O'Rear"
      ],
      [
        1,
        "Belinda Palmer"
      ],
      [
        1,
        "Roman Polanski"
      ],
      [
        1,
        "Freddie Roberto"
      ],
      [
        1,
        "Roy Roberts"
      ],
      [
        1,
        "John Rogers"
      ],
      [
        1,
        "Jesse Vint"
      ],
      [
        1,
        "Allan Warnick"
      ],
      [
        1,
        "Noble Willingham"
      ],
      [
        1,
        "Burt Young"
      ],
      [
        1,
        "Darrell Zwerling"
      ]
    ],
    "directors": "Roman Polanski",
    "budget_string": "6m USD",
    "gross_string": "29.2m USD",
    "metascore_string": "92%"
  },
  {
    "imdb_id": "tt0071562",
    "plot": "The early life and career of Vito Corleone in 1920s New York City is portrayed, while his son, Michael, expands and tightens his grip on the family crime syndicate.",
    "certification": "R",
    "other_wins_count": 11,
    "other_noms_count": 20,
    "amazon": "https://www.amazon.com/Godfather-Part-II-Blu-ray/dp/B06XNS686F/ref=sr_1_2?crid=3TA57SVFIDFE2&amp;keywords=godfather+part+2&amp;qid=1654825564&amp;sprefix=godfather+part+2%252Caps%252C511&amp;sr=8-2&_encoding=UTF8&tag=sianglu-20&linkCode=ur2&linkId=0274d9fde618c568e1d819dc872197fa&camp=1789&creative=9325",
    "justwatch": "https://justwatch.com/us/movie/the-godfather-part-ii?utm_source=share&utm_medium=web",
    "beige_index": 1,
    "beige_angle": 7.9992,
    "cast": [
      [
        3,
        "Tito Alba"
      ],
      [
        3,
        "Ivonne Coll"
      ],
      [
        3,
        "Venancia Grangerard"
      ],
      [
        3,
        "Johnny Naranjo"
      ],
      [
        1,
        "Danny Aiello"
      ],
      [
        1,
        "John Aprea"
      ],
      [
        1,
        "Carmen Argenziano"
      ],
      [
        1,
        "Paul B. Brown"
      ],
      [
        1,
        "Oreste Baldini"
      ],
      [
        1,
        "Kathleen Beller"
      ],
      [
        1,
        "Gabriella Belloni"
      ],
      [
        1,
        "William Bowers"
      ],
      [
        1,
        "Richard Bright"
      ],
      [
        1,
        "James Caan"
      ],
      [
        1,
        "Carmine Caridi"
      ],
      [
        1,
        "Maria Carta"
      ],
      [
        1,
        "John Cazale"
      ],
      [
        1,
        "Dominic Chianese"
      ],
      [
        1,
        "Roman Coppola"
      ],
      [
        1,
        "Vincent Coppola"
      ],
      [
        1,
        "Roger Corman"
      ],
      [
        1,
        "Mario Cotone"
      ],
      [
        1,
        "Tom Dahlgren"
      ],
      [
        1,
        "Joe De Nicola"
      ],
      [
        1,
        "Robert De Niro"
      ],
      [
        1,
        "Francesca De Sapio"
      ],
      [
        1,
        "Harry Dean Stanton"
      ],
      [
        1,
        "Joseph Della Sorte"
      ],
      [
        1,
        "Nick Discenza"
      ],
      [
        1,
        "Troy Donahue"
      ],
      [
        1,
        "Peter Donat"
      ],
      [
        1,
        "Robert Duvall"
      ],
      [
        1,
        "Phil Feldman"
      ],
      [
        1,
        "Ezio Flagello"
      ],
      [
        1,
        "Carmine Foresta"
      ],
      [
        1,
        "Livio Giorgi"
      ],
      [
        1,
        "James Gounaris"
      ],
      [
        1,
        "Julie Gregg"
      ],
      [
        1,
        "Marianna Hill"
      ],
      [
        1,
        "Diane Keaton"
      ],
      [
        1,
        "Morgana King"
      ],
      [
        1,
        "Bruno Kirby"
      ],
      [
        1,
        "Peter LaCorte"
      ],
      [
        1,
        "Tere Livrano"
      ],
      [
        1,
        "Joe Lo Grippo"
      ],
      [
        1,
        "Elda Maida"
      ],
      [
        1,
        "Andrea Maugeri"
      ],
      [
        1,
        "Saveria Mazzola"
      ],
      [
        1,
        "Joseph Medaglia"
      ],
      [
        1,
        "John Megna"
      ],
      [
        1,
        "Gastone Moschin"
      ],
      [
        1,
        "James Murdock"
      ],
      [
        1,
        "Al Pacino"
      ],
      [
        1,
        "Ignazio Pappalardo"
      ],
      [
        1,
        "Salvatore Po"
      ],
      [
        1,
        "Tom Rosqui"
      ],
      [
        1,
        "Gianni Russo"
      ],
      [
        1,
        "Talia Shire"
      ],
      [
        1,
        "Giuseppe Sillato"
      ],
      [
        1,
        "Frank Sivero"
      ],
      [
        1,
        "Fay Spain"
      ],
      [
        1,
        "Joe Spinell"
      ],
      [
        1,
        "G.D. Spradlin"
      ],
      [
        1,
        "Lee Strasberg"
      ],
      [
        1,
        "Teresa Tirelli"
      ],
      [
        1,
        "Amerigo Tot"
      ],
      [
        1,
        "Leopoldo Trieste"
      ],
      [
        1,
        "Michael V. Gazzo"
      ],
      [
        1,
        "Edward Van Sickle"
      ],
      [
        1,
        "Abe Vigoda"
      ],
      [
        1,
        "Richard Watson"
      ],
      [
        1,
        "Erica Yohn"
      ]
    ],
    "directors": "Francis Ford Coppola",
    "budget_string": "13m USD",
    "gross_string": "48m USD",
    "metascore_string": "90%"
  },
  {
    "imdb_id": "tt0071853",
    "plot": "King Arthur and his Knights of the Round Table embark on a surreal, low-budget search for the Holy Grail, encountering many, very silly obstacles.",
    "certification": "PG",
    "other_wins_count": 3,
    "other_noms_count": 3,
    "amazon": "https://www.amazon.com/Monty-Python-Holy-Grail-Blu-ray/dp/B013P0X0E4/ref=sr_1_2?crid=374Y98T37UPWY&amp;keywords=monty+python+and+the+holy+grail+blu+ray&amp;qid=1655174973&amp;sprefix=monty+python+and+the+holy+grail+blu+ray%252Caps%252C288&amp;sr=8-2&_encoding=UTF8&tag=sianglu-20&linkCode=ur2&linkId=10906302572ed39c9cc00c414fadcf51&camp=1789&creative=9325",
    "justwatch": "https://justwatch.com/us/movie/monty-python-and-the-holy-grail?utm_source=share&utm_medium=web",
    "beige_index": 1,
    "beige_angle": 3.8574,
    "cast": [
      [
        3,
        "Joni Flynn"
      ],
      [
        1,
        "Mitsuko Forstater"
      ],
      [
        1,
        "Mary Allen"
      ],
      [
        1,
        "Connie Booth"
      ],
      [
        1,
        "Elspeth Cameron"
      ],
      [
        1,
        "Graham Chapman"
      ],
      [
        1,
        "John Cleese"
      ],
      [
        1,
        "Carol Cleveland"
      ],
      [
        1,
        "Sally Coombe"
      ],
      [
        1,
        "Daphne Darling"
      ],
      [
        1,
        "Rita Davies"
      ],
      [
        1,
        "Yvonne Dick"
      ],
      [
        1,
        "Bee Duffell"
      ],
      [
        1,
        "Terry Gilliam"
      ],
      [
        1,
        "Fiona Gordon"
      ],
      [
        1,
        "Gloria Graham"
      ],
      [
        1,
        "Eric Idle"
      ],
      [
        1,
        "Neil Innes"
      ],
      [
        1,
        "Sandy Johnson"
      ],
      [
        1,
        "Terry Jones"
      ],
      [
        1,
        "Sally Kinghorn"
      ],
      [
        1,
        "Judy Lamb"
      ],
      [
        1,
        "Anna Lanski"
      ],
      [
        1,
        "Vivienne MacDonald"
      ],
      [
        1,
        "Michael Palin"
      ],
      [
        1,
        "Joyce Pollner"
      ],
      [
        1,
        "Sandy Rose"
      ],
      [
        1,
        "Tracy Sneddon"
      ],
      [
        1,
        "Romilly Squire"
      ],
      [
        1,
        "Avril Stewart"
      ],
      [
        1,
        "Sylvia Taylor"
      ],
      [
        1,
        "Alison Walker"
      ],
      [
        1,
        "Loraine Ward"
      ],
      [
        1,
        "John Young"
      ],
      [
        1,
        "Mark Zycon"
      ]
    ],
    "directors": "Terry Gilliam & Terry Jones",
    "budget_string": "0.5m USD",
    "gross_string": "1.9m USD",
    "metascore_string": "91%"
  },
  {
    "imdb_id": "tt0072684",
    "plot": "An Irish rogue wins the heart of a rich widow and assumes her dead husband's aristocratic position in 18th-century England.",
    "certification": "PG",
    "other_wins_count": 15,
    "other_noms_count": 15,
    "amazon": "https://www.amazon.com/Barry-Lyndon-Blu-ray-Ryan-ONeal/dp/B073XYGB8T/ref=sr_1_1?crid=2864245ISO9EO&amp;keywords=barry+lyndon+blu+ray&amp;qid=1655183306&amp;sprefix=barry+lyndon+blu+ray%252Caps%252C283&amp;sr=8-1&_encoding=UTF8&tag=sianglu-20&linkCode=ur2&linkId=6295fc29ce1b25fcc8e67b7c4e3144f2&camp=1789&creative=9325",
    "justwatch": "https://justwatch.com/us/movie/barry-lyndon?utm_source=share&utm_medium=web",
    "beige_index": 1,
    "beige_angle": 0.0,
    "cast": [
      [
        1,
        "Marisa Berenson"
      ],
      [
        1,
        "Steven Berkoff"
      ],
      [
        1,
        "John Bindon"
      ],
      [
        1,
        "Roger Booth"
      ],
      [
        1,
        "Billy Boyle"
      ],
      [
        1,
        "Jonathan Cecil"
      ],
      [
        1,
        "Peter Cellier"
      ],
      [
        1,
        "Geoffrey Chater"
      ],
      [
        1,
        "Anthony Dawes"
      ],
      [
        1,
        "Patrick Dawson"
      ],
      [
        1,
        "Norman Gay"
      ],
      [
        1,
        "Gay Hamilton"
      ],
      [
        1,
        "Bernard Hepton"
      ],
      [
        1,
        "Anthony Herrick"
      ],
      [
        1,
        "Michael Hordern"
      ],
      [
        1,
        "Barry Jackson"
      ],
      [
        1,
        "Wolf Kahler"
      ],
      [
        1,
        "Marie Kean"
      ],
      [
        1,
        "Diana K\u00f6rner"
      ],
      [
        1,
        "Hardy Kr\u00fcger"
      ],
      [
        1,
        "Pat Laffan"
      ],
      [
        1,
        "Patrick Magee"
      ],
      [
        1,
        "Ferdy Mayne"
      ],
      [
        1,
        "Murray Melvin"
      ],
      [
        1,
        "Hans Meyer"
      ],
      [
        1,
        "Frank Middlemass"
      ],
      [
        1,
        "Andr\u00e9 Morell"
      ],
      [
        1,
        "David Morley"
      ],
      [
        1,
        "Ryan O'Neal"
      ],
      [
        1,
        "Arthur O'Sullivan"
      ],
      [
        1,
        "Godfrey Quigley"
      ],
      [
        1,
        "Liam Redmond"
      ],
      [
        1,
        "Pat Roach"
      ],
      [
        1,
        "Leonard Rossiter"
      ],
      [
        1,
        "Dominic Savage"
      ],
      [
        1,
        "Frederick Schiller"
      ],
      [
        1,
        "George Sewell"
      ],
      [
        1,
        "Anthony Sharp"
      ],
      [
        1,
        "John Sharp"
      ],
      [
        1,
        "Roy Spencer"
      ],
      [
        1,
        "Philip Stone"
      ],
      [
        1,
        "John Sullivan"
      ],
      [
        1,
        "Harry Towb"
      ],
      [
        1,
        "Leon Vitali"
      ]
    ],
    "directors": "Stanley Kubrick",
    "budget_string": "11m USD",
    "gross_string": "0.2m USD",
    "metascore_string": "89%"
  },
  {
    "imdb_id": "tt0073195",
    "plot": "When a killer shark unleashes chaos on a beach community off Long Island, it's up to a local sheriff, a marine biologist, and an old seafarer to hunt the beast down.",
    "certification": "PG",
    "other_wins_count": 12,
    "other_noms_count": 20,
    "amazon": "https://www.amazon.com/Jaws-Blu-ray-Roy-Scheider/dp/B00MA5KL9I/ref=sr_1_2?crid=36SOI7K7K0R95&amp;keywords=jaws+blu+ray&amp;qid=1655184141&amp;sprefix=jaws+blu+ray%252Caps%252C286&amp;sr=8-2&_encoding=UTF8&tag=sianglu-20&linkCode=ur2&linkId=4fbb57569a784f3ed27c473a2c0a5bc4&camp=1789&creative=9325",
    "justwatch": "https://justwatch.com/us/movie/jaws?utm_source=share&utm_medium=web",
    "beige_index": 1,
    "beige_angle": 0.0,
    "cast": [
      [
        1,
        "Susan Backlinie"
      ],
      [
        1,
        "Peter Benchley"
      ],
      [
        1,
        "Richard Dreyfuss"
      ],
      [
        1,
        "Lee Fierro"
      ],
      [
        1,
        "Jonathan Filley"
      ],
      [
        1,
        "Lorraine Gary"
      ],
      [
        1,
        "Carl Gottlieb"
      ],
      [
        1,
        "Ted Grossman"
      ],
      [
        1,
        "Murray Hamilton"
      ],
      [
        1,
        "Craig Kingsbury"
      ],
      [
        1,
        "Jeffrey Kramer"
      ],
      [
        1,
        "Jay Mello"
      ],
      [
        1,
        "Robert Nevin"
      ],
      [
        1,
        "Chris Rebello"
      ],
      [
        1,
        "Roy Scheider"
      ],
      [
        1,
        "Robert Shaw"
      ],
      [
        1,
        "Harry Shearer"
      ],
      [
        1,
        "Jeffrey Voorhees"
      ]
    ],
    "directors": "Steven Spielberg",
    "budget_string": "7m USD",
    "gross_string": "471.4m USD",
    "metascore_string": "87%"
  },
  {
    "imdb_id": "tt0073486",
    "plot": "A criminal pleads insanity and is admitted to a mental institution, where he rebels against the oppressive nurse and rallies up the scared patients.",
    "certification": "R",
    "other_wins_count": 33,
    "other_noms_count": 16,
    "amazon": "https://www.amazon.com/One-Flew-Over-Cuckoos-Nest/dp/B004FQX5B6/ref=tmm_dvd_title_0?_encoding=UTF8&amp;amp&amp;qid=&amp;amp&amp;sr=&_encoding=UTF8&tag=sianglu-20&linkCode=ur2&linkId=c0a77343044dc307dd5a9f7549ff07c1&camp=1789&creative=9325",
    "justwatch": "https://justwatch.com/us/movie/one-flew-over-the-cuckoos-nest?utm_source=share&utm_medium=web",
    "beige_index": 2,
    "beige_angle": 41.3442,
    "cast": [
      [
        5,
        "Alonzo Brown"
      ],
      [
        5,
        "Scatman Crothers"
      ],
      [
        5,
        "Mwako Cumbuka"
      ],
      [
        5,
        "Nathan George"
      ],
      [
        3,
        "Will Sampson"
      ],
      [
        2,
        "Lan Fendors"
      ],
      [
        1,
        "Michael Berryman"
      ],
      [
        1,
        "Peter Brocco"
      ],
      [
        1,
        "Aurore Cl\u00e9ment"
      ],
      [
        1,
        "Danny DeVito"
      ],
      [
        1,
        "Brad Dourif"
      ],
      [
        1,
        "William Duell"
      ],
      [
        1,
        "Josip Elic"
      ],
      [
        1,
        "Louise Fletcher"
      ],
      [
        1,
        "Bill Gratton"
      ],
      [
        1,
        "Ken Kenny"
      ],
      [
        1,
        "Mel Lambert"
      ],
      [
        1,
        "Sydney Lassick"
      ],
      [
        1,
        "Kay Lee"
      ],
      [
        1,
        "Christopher Lloyd"
      ],
      [
        1,
        "Dwight Marfield"
      ],
      [
        1,
        "Ted Markland"
      ],
      [
        1,
        "Louisa Moritz"
      ],
      [
        1,
        "Jack Nicholson"
      ],
      [
        1,
        "Dean R. Brooks"
      ],
      [
        1,
        "William Redfield"
      ],
      [
        1,
        "Philip Roth"
      ],
      [
        1,
        "Mimi Sarkisian"
      ],
      [
        1,
        "Vincent Schiavelli"
      ],
      [
        1,
        "Mews Small"
      ],
      [
        1,
        "Delos V. Smith Jr."
      ],
      [
        1,
        "Tin Welch"
      ]
    ],
    "directors": "Milos Forman",
    "budget_string": "3m USD",
    "gross_string": "109.1m USD",
    "metascore_string": "84%"
  },
  {
    "imdb_id": "tt0071411",
    "plot": "The Russian army sends an explorer on an expedition to the snowy Siberian wilderness where he makes friends with a seasoned local hunter.",
    "certification": "G",
    "other_wins_count": 7,
    "other_noms_count": 2,
    "amazon": "https://www.amazon.com/Dersu-Uzala-Maxim-Munsuk/dp/B00O2H8AAE/ref=tmm_dvd_swatch_0?_encoding=UTF8&amp;qid=1655193828&amp;sr=1-2&_encoding=UTF8&tag=sianglu-20&linkCode=ur2&linkId=12d3a0d9ba3a11d0a9de31e84ed2f52c&camp=1789&creative=9325",
    "justwatch": "https://justwatch.com/us/movie/dersu-uzala?utm_source=share&utm_medium=web",
    "beige_index": 1,
    "beige_angle": 7.4484,
    "cast": [
      [
        2,
        "Suymenkul Chokmorov"
      ],
      [
        2,
        "Tsun Di-go"
      ],
      [
        2,
        "A. Erdniev"
      ],
      [
        2,
        "V. Kiryanov"
      ],
      [
        2,
        "Zarema Mademilova"
      ],
      [
        2,
        "Maksim Munzuk"
      ],
      [
        1,
        "Mikhail Bychkov"
      ],
      [
        1,
        "Svetlana Danilchenko"
      ],
      [
        1,
        "V. Ignatov"
      ],
      [
        1,
        "Vladimir Khlestov"
      ],
      [
        1,
        "Vladimir Khrulyov"
      ],
      [
        1,
        "V. Koldin"
      ],
      [
        1,
        "Dmitriy Korshikov"
      ],
      [
        1,
        "Vladimir Kremena"
      ],
      [
        1,
        "V. Lastochkin"
      ],
      [
        1,
        "Stanislav Marin"
      ],
      [
        1,
        "Daniil Netrebin"
      ],
      [
        1,
        "G. Polunin"
      ],
      [
        1,
        "Vladimir Prikhodko"
      ],
      [
        1,
        "Aleksandr Pyatkov"
      ],
      [
        1,
        "Vladimir Sergiyakov"
      ],
      [
        1,
        "S. Sinyavskiy"
      ],
      [
        1,
        "Yuriy Solomin"
      ],
      [
        1,
        "Vladimir Sverba"
      ],
      [
        1,
        "Igor Sykhra"
      ],
      [
        1,
        "M. Tetov"
      ],
      [
        1,
        "Nikolay Volkov"
      ],
      [
        1,
        "Yanis Yakobsons"
      ],
      [
        1,
        "Serafim Zaytsev"
      ]
    ],
    "directors": "Akira Kurosawa",
    "budget_string": "4m USD",
    "gross_string": "<1m USD",
    "metascore_string": "N/A"
  },
  {
    "imdb_id": "tt0075314",
    "plot": "A mentally unstable veteran works as a nighttime taxi driver in New York City, where the perceived decadence and sleaze fuels his urge for violent action.",
    "certification": "R",
    "other_wins_count": 22,
    "other_noms_count": 16,
    "amazon": "https://www.amazon.com/Taxi-Driver-40th-Anniversary-Blu-ray/dp/B01KVJ1BKW/ref=tmm_blu_swatch_0?_encoding=UTF8&amp;qid=1655007758&amp;sr=8-2&_encoding=UTF8&tag=sianglu-20&linkCode=ur2&linkId=fbbf48a5170a2f53e2d3075f503dcb65&camp=1789&creative=9325",
    "justwatch": "https://justwatch.com/us/movie/taxi-driver?utm_source=share&utm_medium=web",
    "beige_index": 2,
    "beige_angle": 36.243,
    "cast": [
      [
        5,
        "Frank Adu"
      ],
      [
        5,
        "Nat Grant"
      ],
      [
        5,
        "Norman Matlock"
      ],
      [
        5,
        "Diahnne Abbott"
      ],
      [
        3,
        "Victor Argo"
      ],
      [
        1,
        "Gino Ardito"
      ],
      [
        1,
        "Garth Avery"
      ],
      [
        1,
        "Peter Boyle"
      ],
      [
        1,
        "Albert Brooks"
      ],
      [
        1,
        "Harlan Cary Poe"
      ],
      [
        1,
        "Harry Cohn"
      ],
      [
        1,
        "Copper Cunningham"
      ],
      [
        1,
        "Robert De Niro"
      ],
      [
        1,
        "Brenda Dickson"
      ],
      [
        1,
        "Harry Fischler"
      ],
      [
        1,
        "Jodie Foster"
      ],
      [
        1,
        "Leonard Harris"
      ],
      [
        1,
        "Richard Higgs"
      ],
      [
        1,
        "Beau Kayser"
      ],
      [
        1,
        "Harvey Keitel"
      ],
      [
        1,
        "Victor Magnotta"
      ],
      [
        1,
        "Bob Maroff"
      ],
      [
        1,
        "Bill Minkin"
      ],
      [
        1,
        "Murray Moston"
      ],
      [
        1,
        "Harry Northup"
      ],
      [
        1,
        "Gene Palma"
      ],
      [
        1,
        "Steven Prince"
      ],
      [
        1,
        "Ralph S. Singleton"
      ],
      [
        1,
        "Peter Savage"
      ],
      [
        1,
        "Martin Scorsese"
      ],
      [
        1,
        "Catherine Scorsese"
      ],
      [
        1,
        "Charles Scorsese"
      ],
      [
        1,
        "Cybill Shepherd"
      ],
      [
        1,
        "Nicholas Shields"
      ],
      [
        1,
        "Joe Spinell"
      ],
      [
        1,
        "Maria Turner"
      ],
      [
        1,
        "Robin Utt"
      ]
    ],
    "directors": "Martin Scorsese",
    "budget_string": "1.3m USD",
    "gross_string": "28.6m USD",
    "metascore_string": "94%"
  },
  {
    "imdb_id": "tt0075148",
    "plot": "A small-time Philadelphia boxer gets a supremely rare chance to fight the world heavyweight champion in a bout in which he strives to go the distance for his self-respect.",
    "certification": "PG",
    "other_wins_count": 17,
    "other_noms_count": 22,
    "amazon": "https://www.amazon.com/Rocky-Blu-ray-Sylvester-Stallone/dp/B00J4BW10S/ref=sr_1_2?crid=1VK12VX79NQUF&amp;keywords=rocky+blu+ray&amp;qid=1655184585&amp;s=movies-tv&amp;sprefix=rocky+blu+ray%252Cmovies-tv%252C299&amp;sr=1-2&_encoding=UTF8&tag=sianglu-20&linkCode=ur2&linkId=c12fe53f2c75f1f6f340f5e903de2521&camp=1789&creative=9325",
    "justwatch": "https://justwatch.com/us/movie/rocky?utm_source=share&utm_medium=web",
    "beige_index": 2,
    "beige_angle": 56.25,
    "cast": [
      [
        5,
        "Chino 'Fats' Williams"
      ],
      [
        5,
        "Tony Burton"
      ],
      [
        5,
        "Larry Carroll"
      ],
      [
        5,
        "DeForest Covan"
      ],
      [
        5,
        "Joe Frazier"
      ],
      [
        5,
        "Diana Lewis"
      ],
      [
        5,
        "Hank Rolike"
      ],
      [
        5,
        "Stan Shaw"
      ],
      [
        5,
        "Carl Weathers"
      ],
      [
        3,
        "Pedro Lovell"
      ],
      [
        1,
        "Christopher Avildsen"
      ],
      [
        1,
        "Bill Baldwin"
      ],
      [
        1,
        "Simmy Bow"
      ],
      [
        1,
        "Joseph C. Giambelluc"
      ],
      [
        1,
        "Thayer David"
      ],
      [
        1,
        "William E. Ring"
      ],
      [
        1,
        "Lou Fillipo"
      ],
      [
        1,
        "Jimmy Gambina"
      ],
      [
        1,
        "Peter Glassberg"
      ],
      [
        1,
        "Jack Hollander"
      ],
      [
        1,
        "Lloyd Kaufman"
      ],
      [
        1,
        "Robert L. Tangrea"
      ],
      [
        1,
        "Jodi Letizia"
      ],
      [
        1,
        "Jane Marla Robbins"
      ],
      [
        1,
        "George Memmoli"
      ],
      [
        1,
        "Burgess Meredith"
      ],
      [
        1,
        "George O'Hanlon"
      ],
      [
        1,
        "Shirley O'Hara"
      ],
      [
        1,
        "Kathleen Parker"
      ],
      [
        1,
        "Billy Sands"
      ],
      [
        1,
        "Don Sherman"
      ],
      [
        1,
        "Talia Shire"
      ],
      [
        1,
        "Al Silvani"
      ],
      [
        1,
        "Joe Sorbello"
      ],
      [
        1,
        "Joe Spinell"
      ],
      [
        1,
        "Frank Stallone"
      ],
      [
        1,
        "Frank Stallone"
      ],
      [
        1,
        "Sylvester Stallone"
      ],
      [
        1,
        "Frankie Van"
      ],
      [
        1,
        "Burt Young"
      ]
    ],
    "directors": "John G. Avildsen",
    "budget_string": "1m USD",
    "gross_string": "117.3m USD",
    "metascore_string": "70%"
  },
  {
    "imdb_id": "tt0074958",
    "plot": "A television network cynically exploits a deranged former anchor's ravings and revelations about the news media for its own profit, but finds that his message may be difficult to control.",
    "certification": "R",
    "other_wins_count": 16,
    "other_noms_count": 26,
    "amazon": "https://www.amazon.com/Network-Blu-ray-Peter-Finch/dp/B0033AI4CK/ref=sr_1_1?crid=3LPWE2XW6UHLF&amp;keywords=network+blu+ray&amp;qid=1655184978&amp;s=movies-tv&amp;sprefix=network+blu+ray%252Cmovies-tv%252C279&amp;sr=1-1&_encoding=UTF8&tag=sianglu-20&linkCode=ur2&linkId=1f9464a269b88c6ccae14c6f25fe4fb2&camp=1789&creative=9325",
    "justwatch": "https://justwatch.com/us/movie/network?utm_source=share&utm_medium=web",
    "beige_index": 1,
    "beige_angle": 18.9,
    "cast": [
      [
        5,
        "Arthur Burghardt"
      ],
      [
        5,
        "Marlene Warfield"
      ],
      [
        3,
        "Russ Petranto"
      ],
      [
        1,
        "Wesley Addy"
      ],
      [
        1,
        "Ned Beatty"
      ],
      [
        1,
        "Bill Burrows"
      ],
      [
        1,
        "John Carpenter"
      ],
      [
        1,
        "Jordan Charney"
      ],
      [
        1,
        "Kathy Cronkite"
      ],
      [
        1,
        "Ed Crowley"
      ],
      [
        1,
        "Jerome Dempsey"
      ],
      [
        1,
        "Faye Dunaway"
      ],
      [
        1,
        "Robert Duvall"
      ],
      [
        1,
        "Conchata Ferrell"
      ],
      [
        1,
        "Peter Finch"
      ],
      [
        1,
        "Gene Gross"
      ],
      [
        1,
        "Stanley Grover"
      ],
      [
        1,
        "Cindy Grover"
      ],
      [
        1,
        "Lance Henriksen"
      ],
      [
        1,
        "Darryl Hickman"
      ],
      [
        1,
        "William Holden"
      ],
      [
        1,
        "Mitchell Jason"
      ],
      [
        1,
        "Paul Jenkins"
      ],
      [
        1,
        "Ken Kercheval"
      ],
      [
        1,
        "Kenneth Kimmins"
      ],
      [
        1,
        "Carolyn Krigbaum"
      ],
      [
        1,
        "Zane Lasky"
      ],
      [
        1,
        "Michael Lipton"
      ],
      [
        1,
        "Michael Lombard"
      ],
      [
        1,
        "Pirie MacDonald"
      ],
      [
        1,
        "Bernard Pollock"
      ],
      [
        1,
        "Roy Poole"
      ],
      [
        1,
        "William Prince"
      ],
      [
        1,
        "Lee Richardson"
      ],
      [
        1,
        "Lane Smith"
      ],
      [
        1,
        "Ted Sorel"
      ],
      [
        1,
        "Beatrice Straight"
      ],
      [
        1,
        "Fred Stuthman"
      ],
      [
        1,
        "Sasha von Scherler"
      ],
      [
        1,
        "Lydia Wilen"
      ]
    ],
    "directors": "Sidney Lumet",
    "budget_string": "3.8m USD",
    "gross_string": "23.7m USD",
    "metascore_string": "83%"
  },
  {
    "imdb_id": "tt0076759",
    "plot": "Luke Skywalker joins forces with a Jedi Knight, a cocky pilot, a Wookiee and two droids to save the galaxy from the Empire's world-destroying battle station, while also attempting to rescue Princess Leia from the mysterious Darth Vader.",
    "certification": "PG",
    "other_wins_count": 57,
    "other_noms_count": 29,
    "amazon": "https://www.amazon.com/Star-Wars-New-Hope-Feature/dp/B083XQ6SW2/ref=sr_1_7?crid=5RF316NMMSC9&amp;keywords=star+wars+a+new+hope&amp;qid=1654828527&amp;sprefix=star+wars+a+new+h%252Caps%252C587&amp;sr=8-7&_encoding=UTF8&tag=sianglu-20&linkCode=ur2&linkId=f9e543fe78bf33ef7b1ebbf4e5d078e8&camp=1789&creative=9325",
    "justwatch": "https://justwatch.com/us/movie/star-wars-episode-iv-a-new-hope?utm_source=share&utm_medium=web",
    "beige_index": 1,
    "beige_angle": 9.2574,
    "cast": [
      [
        5,
        "James Earl Jones"
      ],
      [
        1,
        "Graham Ashley"
      ],
      [
        1,
        "Kenny Baker"
      ],
      [
        1,
        "Scott Beach"
      ],
      [
        1,
        "Phil Brown"
      ],
      [
        1,
        "Eddie Byrne"
      ],
      [
        1,
        "Gilda Cohen"
      ],
      [
        1,
        "Alfie Curtis"
      ],
      [
        1,
        "Peter Cushing"
      ],
      [
        1,
        "Anthony Daniels"
      ],
      [
        1,
        "Carrie Fisher"
      ],
      [
        1,
        "Harrison Ford"
      ],
      [
        1,
        "Shelagh Fraser"
      ],
      [
        1,
        "Rusty Goffe"
      ],
      [
        1,
        "Alec Guinness"
      ],
      [
        1,
        "Garrick Hagon"
      ],
      [
        1,
        "Mark Hamill"
      ],
      [
        1,
        "Don Henderson"
      ],
      [
        1,
        "Drewe Henley"
      ],
      [
        1,
        "William Hootkins"
      ],
      [
        1,
        "Jack Klaff"
      ],
      [
        1,
        "Denis Lawson"
      ],
      [
        1,
        "Richard LeParmentier"
      ],
      [
        1,
        "Angus MacInnes"
      ],
      [
        1,
        "Peter Mayhew"
      ],
      [
        1,
        "Alex McCrindle"
      ],
      [
        1,
        "Marcus Powell"
      ],
      [
        1,
        "David Prowse"
      ],
      [
        1,
        "Jack Purvis"
      ],
      [
        1,
        "Shane Rimmer"
      ],
      [
        1,
        "Leslie Schofield"
      ],
      [
        1,
        "Jeremy Sinden"
      ],
      [
        1,
        "Malcolm Tierney"
      ],
      [
        1,
        "Burnell Tucker"
      ],
      [
        1,
        "Bill Weston"
      ]
    ],
    "directors": "George Lucas",
    "budget_string": "11m USD",
    "gross_string": "775.4m USD",
    "metascore_string": "90%"
  },
  {
    "imdb_id": "tt0077416",
    "plot": "An in-depth examination of the ways in which the Vietnam War impacts and disrupts the lives of several friends in a small steel mill town in Pennsylvania.",
    "certification": "R",
    "other_wins_count": 19,
    "other_noms_count": 27,
    "amazon": "https://www.amazon.com/Deer-Hunter-Blu-ray-Robert-Niro/dp/B00MA5KL7U/ref=tmm_blu_title_0?_encoding=UTF8&amp;amp&amp;qid=&amp;amp&amp;sr=&_encoding=UTF8&tag=sianglu-20&linkCode=ur2&linkId=aa7abf60d6b0515c7963b505a732b902&camp=1789&creative=9325",
    "justwatch": "https://justwatch.com/us/movie/the-deer-hunter?utm_source=share&utm_medium=web",
    "beige_index": 2,
    "beige_angle": 47.4894,
    "cast": [
      [
        5,
        "Dennis Watlington"
      ],
      [
        3,
        "Chok Chai Mahasoke"
      ],
      [
        3,
        "Krieng Chaiyapuk"
      ],
      [
        3,
        "Sapox Colisium"
      ],
      [
        3,
        "Jiam Gongtongsmoot"
      ],
      [
        3,
        "Mana Hansa"
      ],
      [
        3,
        "Sombot Jumpanoi"
      ],
      [
        3,
        "Lynn Kongkham"
      ],
      [
        3,
        "Phip Manee"
      ],
      [
        3,
        "Samui Muang-Intata"
      ],
      [
        3,
        "Charan Nusvanon"
      ],
      [
        3,
        "Ot Palapoo"
      ],
      [
        3,
        "Po Pao Pee"
      ],
      [
        3,
        "Chai Peyawan"
      ],
      [
        3,
        "Ding Santos"
      ],
      [
        3,
        "Somsak Sengvilai"
      ],
      [
        3,
        "Nongnuj Timruang"
      ],
      [
        3,
        "Vitoon Winwitoon"
      ],
      [
        1,
        "Rutanya Alda"
      ],
      [
        1,
        "Mary Ann Haenel"
      ],
      [
        1,
        "Chuck Aspegren"
      ],
      [
        1,
        "Robert Beard"
      ],
      [
        1,
        "Tom Becker"
      ],
      [
        1,
        "Dale Burroughs"
      ],
      [
        1,
        "John Cazale"
      ],
      [
        1,
        "Christopher Colombi Jr."
      ],
      [
        1,
        "Paul D'Amato"
      ],
      [
        1,
        "Charlene Darrow"
      ],
      [
        1,
        "Robert De Niro"
      ],
      [
        1,
        "Frank Devore"
      ],
      [
        1,
        "Joe Dzizmba"
      ],
      [
        1,
        "George Dzundza"
      ],
      [
        1,
        "John F. Buchmelter III"
      ],
      [
        1,
        "Joe Grifasi"
      ],
      [
        1,
        "Parris Hicks"
      ],
      [
        1,
        "Mady Kaplan"
      ],
      [
        1,
        "Victoria Karnafel"
      ],
      [
        1,
        "Stephen Kopestonsky"
      ],
      [
        1,
        "Richard Kuss"
      ],
      [
        1,
        "John Savage"
      ],
      [
        1,
        "Jack Scardino"
      ],
      [
        1,
        "Pierre Segui"
      ],
      [
        1,
        "Shirley Stoler"
      ],
      [
        1,
        "Meryl Streep"
      ],
      [
        1,
        "Joe Strnad"
      ],
      [
        1,
        "Christopher Walken"
      ],
      [
        1,
        "Amy Wright"
      ]
    ],
    "directors": "Michael Cimino",
    "budget_string": "15m USD",
    "gross_string": "49.1m USD",
    "metascore_string": "86%"
  },
  {
    "imdb_id": "tt0078788",
    "plot": "A U.S. Army officer serving in Vietnam is tasked with assassinating a renegade Special Forces Colonel who sees himself as a god.",
    "certification": "R",
    "other_wins_count": 19,
    "other_noms_count": 33,
    "amazon": "https://www.amazon.com/Apocalypse-Now-Blu-ray-Marlon-Brando/dp/B00FAHYO1E/ref=tmm_mfc_swatch_0?_encoding=UTF8&amp;qid=1654833277&amp;sr=1-3-catcorr&_encoding=UTF8&tag=sianglu-20&linkCode=ur2&linkId=ee4c26bd331d3d68644c0af9ee160199&camp=1789&creative=9325",
    "justwatch": "https://justwatch.com/us/movie/apocalypse-now-redux?utm_source=share&utm_medium=web",
    "beige_index": 1,
    "beige_angle": 33.75,
    "cast": [
      [
        5,
        "Hattie Bell"
      ],
      [
        5,
        "Laurence Fishburne"
      ],
      [
        5,
        "Albert Hall"
      ],
      [
        5,
        "Damien Leake"
      ],
      [
        5,
        "Herb Rice"
      ],
      [
        1,
        "Sam Bottoms"
      ],
      [
        1,
        "Marlon Brando"
      ],
      [
        1,
        "Bo Byers"
      ],
      [
        1,
        "Colleen Camp"
      ],
      [
        1,
        "George Cantero"
      ],
      [
        1,
        "Linda Carpenter"
      ],
      [
        1,
        "Aurore Cl\u00e9ment"
      ],
      [
        1,
        "Roman Coppola"
      ],
      [
        1,
        "Gian-Carlo Coppola"
      ],
      [
        1,
        "Marc Coppola"
      ],
      [
        1,
        "Robert Duvall"
      ],
      [
        1,
        "Father Elias"
      ],
      [
        1,
        "Harrison Ford"
      ],
      [
        1,
        "Frederic Forrest"
      ],
      [
        1,
        "Larry Franco"
      ],
      [
        1,
        "Scott Glenn"
      ],
      [
        1,
        "Bill Graham"
      ],
      [
        1,
        "Dennis Hopper"
      ],
      [
        1,
        "Robert Julian"
      ],
      [
        1,
        "James Keane"
      ],
      [
        1,
        "Harvey Keitel"
      ],
      [
        1,
        "Daniel Kiewit"
      ],
      [
        1,
        "Chrystel Le Pelletier"
      ],
      [
        1,
        "R. Lee Ermey"
      ],
      [
        1,
        "Yvon LeSeaux"
      ],
      [
        1,
        "Christian Marquand"
      ],
      [
        1,
        "Tom Mason"
      ],
      [
        1,
        "Nick Nicholson"
      ],
      [
        1,
        "David Olivier"
      ],
      [
        1,
        "Michel Pitton"
      ],
      [
        1,
        "Gilbert Renkens"
      ],
      [
        1,
        "Jerry Ross"
      ],
      [
        1,
        "Kerry Rossall"
      ],
      [
        1,
        "Henri Sadardeil"
      ],
      [
        1,
        "Pierre Segui"
      ],
      [
        1,
        "Martin Sheen"
      ],
      [
        1,
        "G.D. Spradlin"
      ],
      [
        1,
        "Jack Thibeau"
      ],
      [
        1,
        "Frank Villard"
      ],
      [
        1,
        "Glenn Walken"
      ],
      [
        1,
        "Dick White"
      ],
      [
        1,
        "Cynthia Wood"
      ],
      [
        1,
        "Jerry Ziesmer"
      ]
    ],
    "directors": "Francis Ford Coppola",
    "budget_string": "31.5m USD",
    "gross_string": "92.2m USD",
    "metascore_string": "94%"
  },
  {
    "imdb_id": "tt0078748",
    "plot": "The crew of a commercial spacecraft encounter a deadly lifeform after investigating an unknown transmission.",
    "certification": "R",
    "other_wins_count": 17,
    "other_noms_count": 22,
    "amazon": "https://www.amazon.com/Alien-Blu-ray/dp/B00MBNZER8/ref=sr_1_3?crid=1I7D64B2W45CB&amp;keywords=alien+blu-ray&amp;qid=1654832843&amp;s=movies-tv&amp;sprefix=alien+blu-ra%252Cmovies-tv-intl-ship%252C373&amp;sr=1-3&_encoding=UTF8&tag=sianglu-20&linkCode=ur2&linkId=4b7fe6284cac8ff5b3b07a127c28b24c&camp=1789&creative=9325",
    "justwatch": "https://justwatch.com/us/movie/alien?utm_source=share&utm_medium=web",
    "beige_index": 2,
    "beige_angle": 54.0,
    "cast": [
      [
        5,
        "Bolaji Badejo"
      ],
      [
        5,
        "Yaphet Kotto"
      ],
      [
        1,
        "Veronica Cartwright"
      ],
      [
        1,
        "Harry Dean Stanton"
      ],
      [
        1,
        "Ian Holm"
      ],
      [
        1,
        "Helen Horton"
      ],
      [
        1,
        "John Hurt"
      ],
      [
        1,
        "Tom Skerritt"
      ],
      [
        1,
        "Sigourney Weaver"
      ]
    ],
    "directors": "Ridley Scott",
    "budget_string": "11m USD",
    "gross_string": "106.3m USD",
    "metascore_string": "89%"
  },
  {
    "imdb_id": "tt0079470",
    "plot": "Born on the original Christmas in the stable next door to Jesus Christ, Brian of Nazareth spends his life being mistaken for a messiah.",
    "certification": "R",
    "other_wins_count": 0,
    "other_noms_count": 0,
    "amazon": "https://www.amazon.com/Monty-Pythons-Life-Brian-Immaculate/dp/B00JMVRBNW/ref=sr_1_4?crid=3H59TP14XHWXT&amp;keywords=life+of+brian+blu+ray&amp;qid=1655194229&amp;s=movies-tv&amp;sprefix=life+of+brian+blu+ray%252Cmovies-tv%252C282&amp;sr=1-4&_encoding=UTF8&tag=sianglu-20&linkCode=ur2&linkId=d877994674abe14839bca7987e421cc1&camp=1789&creative=9325",
    "justwatch": "https://justwatch.com/us/movie/life-of-brian?utm_source=share&utm_medium=web",
    "beige_index": 1,
    "beige_angle": 0.0,
    "cast": [
      [
        1,
        "Terence Bayler"
      ],
      [
        1,
        "Peter Brett"
      ],
      [
        1,
        "John Case"
      ],
      [
        1,
        "Graham Chapman"
      ],
      [
        1,
        "John Cleese"
      ],
      [
        1,
        "Carol Cleveland"
      ],
      [
        1,
        "Kenneth Colley"
      ],
      [
        1,
        "Terry Gilliam"
      ],
      [
        1,
        "Eric Idle"
      ],
      [
        1,
        "Neil Innes"
      ],
      [
        1,
        "Terry Jones"
      ],
      [
        1,
        "Sue Jones-Davies"
      ],
      [
        1,
        "Chris Langham"
      ],
      [
        1,
        "Andrew MacLachlan"
      ],
      [
        1,
        "Bernard McKenna"
      ],
      [
        1,
        "Charles McKeown"
      ],
      [
        1,
        "Spike Milligan"
      ],
      [
        1,
        "Michael Palin"
      ],
      [
        1,
        "Gwen Taylor"
      ],
      [
        1,
        "John Young"
      ]
    ],
    "directors": "Terry Jones",
    "budget_string": "4m USD",
    "gross_string": "20.7m USD",
    "metascore_string": "77%"
  },
  {
    "imdb_id": "tt0080678",
    "plot": "A Victorian surgeon rescues a heavily disfigured man who is mistreated while scraping a living as a side-show freak. Behind his monstrous fa\u00e7ade, there is revealed a person of kindness, intelligence and sophistication.",
    "certification": "PG",
    "other_wins_count": 10,
    "other_noms_count": 14,
    "amazon": "https://www.amazon.com/Elephant-Region-JOHN-ANTHONY-HOPKINS/dp/B008LXFAB2/ref=tmm_dvd_title_0?_encoding=UTF8&amp;amp&amp;qid=1655179582&amp;amp&amp;sr=8-11&_encoding=UTF8&tag=sianglu-20&linkCode=ur2&linkId=c0d28bd3af04c4aef7b0b7176851d3b2&camp=1789&creative=9325",
    "justwatch": "https://justwatch.com/us/movie/the-elephant-man?utm_source=share&utm_medium=web",
    "beige_index": 1,
    "beige_angle": 0.9468,
    "cast": [
      [
        2,
        "Eiji Kusuhara"
      ],
      [
        1,
        "Stromboli"
      ],
      [
        1,
        "Michele Amas"
      ],
      [
        1,
        "Kenny Baker"
      ],
      [
        1,
        "Anne Bancroft"
      ],
      [
        1,
        "Marion Betzold"
      ],
      [
        1,
        "Dennis Burgess"
      ],
      [
        1,
        "Kathleen Byron"
      ],
      [
        1,
        "Fanny Carby"
      ],
      [
        1,
        "Gerald Case"
      ],
      [
        1,
        "Teresa Codling"
      ],
      [
        1,
        "Gilda Cohen"
      ],
      [
        1,
        "Nula Conwell"
      ],
      [
        1,
        "Deirdre Costello"
      ],
      [
        1,
        "Alfie Curtis"
      ],
      [
        1,
        "Claire Davenport"
      ],
      [
        1,
        "Peter Davidson"
      ],
      [
        1,
        "Robert Day"
      ],
      [
        1,
        "Lesley Dunlop"
      ],
      [
        1,
        "Michael Elphick"
      ],
      [
        1,
        "Roy Evans"
      ],
      [
        1,
        "Dexter Fletcher"
      ],
      [
        1,
        "Norman Gay"
      ],
      [
        1,
        "John Gielgud"
      ],
      [
        1,
        "Hannah Gordon"
      ],
      [
        1,
        "Pat Gorman"
      ],
      [
        1,
        "Chris Greener"
      ],
      [
        1,
        "Carol Harrison"
      ],
      [
        1,
        "Beryl Hicks"
      ],
      [
        1,
        "Wendy Hiller"
      ],
      [
        1,
        "Patricia Hodge"
      ],
      [
        1,
        "Anthony Hopkins"
      ],
      [
        1,
        "John Hurt"
      ],
      [
        1,
        "Freddie Jones"
      ],
      [
        1,
        "Brenda Kempner"
      ],
      [
        1,
        "Victor Kravchenko"
      ],
      [
        1,
        "Robert Lewis Bush"
      ],
      [
        1,
        "Lydia Lisle"
      ],
      [
        1,
        "Tony London"
      ],
      [
        1,
        "Hugh Manning"
      ],
      [
        1,
        "Bernadette Milnes"
      ],
      [
        1,
        "William Morgan Sheppard"
      ],
      [
        1,
        "Phoebe Nicholls"
      ],
      [
        1,
        "Orla Pederson"
      ],
      [
        1,
        "Marcus Powell"
      ],
      [
        1,
        "Pauline Quirke"
      ],
      [
        1,
        "John Rapley"
      ],
      [
        1,
        "Joan Rhodes"
      ],
      [
        1,
        "David Ryall"
      ],
      [
        1,
        "Helen Ryan"
      ],
      [
        1,
        "Lesley Scoble"
      ],
      [
        1,
        "Teri Scoble"
      ],
      [
        1,
        "Patsy Smart"
      ],
      [
        1,
        "Hugh Spight"
      ],
      [
        1,
        "John Standing"
      ],
      [
        1,
        "Frederick Treves"
      ],
      [
        1,
        "Tommy Wright"
      ]
    ],
    "directors": "David Lynch",
    "budget_string": "5m USD",
    "gross_string": "26m USD",
    "metascore_string": "78%"
  },
  {
    "imdb_id": "tt0081505",
    "plot": "A family heads to an isolated hotel for the winter where a sinister presence influences the father into violence, while his psychic son sees horrific forebodings from both past and future.",
    "certification": "R",
    "other_wins_count": 4,
    "other_noms_count": 8,
    "amazon": "https://www.amazon.com/Shining-Blu-ray-Jack-Nicholson/dp/B000UJ48WC/ref=sr_1_2?crid=2AA9HROM12QCB&amp;keywords=the+shining&amp;qid=1654842037&amp;s=movies-tv&amp;sprefix=the+shinin%252Cmovies-tv%252C950&amp;sr=1-2&_encoding=UTF8&tag=sianglu-20&linkCode=ur2&linkId=4a182e40809539a662ff502cf38318fe&camp=1789&creative=9325",
    "justwatch": "https://justwatch.com/us/movie/the-shining?utm_source=share&utm_medium=web",
    "beige_index": 1,
    "beige_angle": 29.4552,
    "cast": [
      [
        5,
        "Tony Burton"
      ],
      [
        5,
        "Scatman Crothers"
      ],
      [
        1,
        "David Baxt"
      ],
      [
        1,
        "Lia Beldam"
      ],
      [
        1,
        "Lisa Burns"
      ],
      [
        1,
        "Louise Burns"
      ],
      [
        1,
        "Alison Coleridge"
      ],
      [
        1,
        "Barry Dennen"
      ],
      [
        1,
        "Shelley Duvall"
      ],
      [
        1,
        "Norman Gay"
      ],
      [
        1,
        "Billie Gibson"
      ],
      [
        1,
        "Anne Jackson"
      ],
      [
        1,
        "Danny Lloyd"
      ],
      [
        1,
        "Barry Nelson"
      ],
      [
        1,
        "Jack Nicholson"
      ],
      [
        1,
        "Robin Pappas"
      ],
      [
        1,
        "Kate Phelps"
      ],
      [
        1,
        "Manning Redwood"
      ],
      [
        1,
        "Jana Shelden"
      ],
      [
        1,
        "Philip Stone"
      ],
      [
        1,
        "Burnell Tucker"
      ],
      [
        1,
        "Joe Turkel"
      ]
    ],
    "directors": "Stanley Kubrick",
    "budget_string": "19m USD",
    "gross_string": "47.3m USD",
    "metascore_string": "66%"
  },
  {
    "imdb_id": "tt0080684",
    "plot": "After the Rebels are brutally overpowered by the Empire on the ice planet Hoth, Luke Skywalker begins Jedi training with Yoda, while his friends are pursued across the galaxy by Darth Vader and bounty hunter Boba Fett.",
    "certification": "PG",
    "other_wins_count": 24,
    "other_noms_count": 20,
    "amazon": "https://www.amazon.com/Star-Wars-Empire-Strikes-Feature/dp/B07TLPBC22/ref=tmm_blu_swatch_0?_encoding=UTF8&amp;qid=1654827119&amp;sr=8-5&_encoding=UTF8&tag=sianglu-20&linkCode=ur2&linkId=61f9bced00182f4bf3be870a5ad4edf4&camp=1789&creative=9325",
    "justwatch": "https://justwatch.com/us/movie/star-wars-episode-v-the-empire-strikes-back?utm_source=share&utm_medium=web",
    "beige_index": 1,
    "beige_angle": 14.7276,
    "cast": [
      [
        5,
        "Billy Dee Williams"
      ],
      [
        5,
        "James Earl Jones"
      ],
      [
        1,
        "Kenny Baker"
      ],
      [
        1,
        "Bruce Boa"
      ],
      [
        1,
        "Jeremy Bulloch"
      ],
      [
        1,
        "Jane Busby"
      ],
      [
        1,
        "Norman Chancer"
      ],
      [
        1,
        "Kenneth Colley"
      ],
      [
        1,
        "Michael Culver"
      ],
      [
        1,
        "Anthony Daniels"
      ],
      [
        1,
        "John Dicks"
      ],
      [
        1,
        "Norwich Duff"
      ],
      [
        1,
        "Mike Edmonds"
      ],
      [
        1,
        "Carrie Fisher"
      ],
      [
        1,
        "Harrison Ford"
      ],
      [
        1,
        "Julian Glover"
      ],
      [
        1,
        "Alec Guinness"
      ],
      [
        1,
        "Mark Hamill"
      ],
      [
        1,
        "Jerry Harte"
      ],
      [
        1,
        "Ray Hassett"
      ],
      [
        1,
        "John Hollis"
      ],
      [
        1,
        "Milton Johns"
      ],
      [
        1,
        "Mark Jones"
      ],
      [
        1,
        "Brigitte Kahn"
      ],
      [
        1,
        "Denis Lawson"
      ],
      [
        1,
        "Ian Liston"
      ],
      [
        1,
        "Oliver Maguire"
      ],
      [
        1,
        "Christopher Malcolm"
      ],
      [
        1,
        "Peter Mayhew"
      ],
      [
        1,
        "Ian McDiarmid"
      ],
      [
        1,
        "Jack McKenzie"
      ],
      [
        1,
        "John Morton"
      ],
      [
        1,
        "Richard Oldfield"
      ],
      [
        1,
        "Frank Oz"
      ],
      [
        1,
        "David Prowse"
      ],
      [
        1,
        "Jack Purvis"
      ],
      [
        1,
        "John Ratzenberger"
      ],
      [
        1,
        "Clive Revill"
      ],
      [
        1,
        "Terry Richards"
      ],
      [
        1,
        "Robin Scobey"
      ],
      [
        1,
        "Michael Sheard"
      ],
      [
        1,
        "Burnell Tucker"
      ],
      [
        1,
        "Des Webb"
      ],
      [
        1,
        "Treat Williams"
      ]
    ],
    "directors": "Irvin Kershner",
    "budget_string": "18m USD",
    "gross_string": "538.4m USD",
    "metascore_string": "82%"
  },
  {
    "imdb_id": "tt0081398",
    "plot": "The life of boxer Jake LaMotta, whose violence and temper that led him to the top in the ring destroyed his life outside of it.",
    "certification": "R",
    "other_wins_count": 22,
    "other_noms_count": 28,
    "amazon": "https://www.amazon.com/Raging-Bull-Blu-ray-Robert-Niro/dp/B00USBLX0C/ref=tmm_blu_swatch_0?_encoding=UTF8&amp;qid=1655180120&amp;sr=1-1&_encoding=UTF8&tag=sianglu-20&linkCode=ur2&linkId=b16e46e01d4cf19cd062c316ad20e471&camp=1789&creative=9325",
    "justwatch": "https://justwatch.com/us/movie/raging-bull?utm_source=share&utm_medium=web",
    "beige_index": 1,
    "beige_angle": 14.562,
    "cast": [
      [
        5,
        "Floyd Anderson"
      ],
      [
        5,
        "Johnny Barnes"
      ],
      [
        5,
        "Eddie Mustafa Muhammad"
      ],
      [
        5,
        "Coley Wallace"
      ],
      [
        1,
        "Richard A. Berk"
      ],
      [
        1,
        "Joseph A. Morale"
      ],
      [
        1,
        "Bob Aaron"
      ],
      [
        1,
        "Frank Adonis"
      ],
      [
        1,
        "Mary Albee"
      ],
      [
        1,
        "Bernie Allen"
      ],
      [
        1,
        "Lori Anne Flax"
      ],
      [
        1,
        "John Arceri"
      ],
      [
        1,
        "Linda Artuso"
      ],
      [
        1,
        "Robert B. Loring"
      ],
      [
        1,
        "Michael Badalucco"
      ],
      [
        1,
        "Thomas Beansy Lobasso"
      ],
      [
        1,
        "Rita Bennett"
      ],
      [
        1,
        "D.J. Blair"
      ],
      [
        1,
        "Joseph Bono"
      ],
      [
        1,
        "Kevin Breslin"
      ],
      [
        1,
        "Paul Carmello"
      ],
      [
        1,
        "Nicholas Colasanto"
      ],
      [
        1,
        "Kenny Davis"
      ],
      [
        1,
        "Robert De Niro"
      ],
      [
        1,
        "Vern De Paul"
      ],
      [
        1,
        "Marty Denkin"
      ],
      [
        1,
        "Fred Dennis"
      ],
      [
        1,
        "James Dimodica"
      ],
      [
        1,
        "Shay Duffin"
      ],
      [
        1,
        "Don Dunphy"
      ],
      [
        1,
        "Bob Evan Collins"
      ],
      [
        1,
        "Peter Fain"
      ],
      [
        1,
        "Paul Forrest"
      ],
      [
        1,
        "Mario Gallo"
      ],
      [
        1,
        "Silvio Garc\u00eda Jr."
      ],
      [
        1,
        "Bill Hanrahan"
      ],
      [
        1,
        "Bob Harks"
      ],
      [
        1,
        "Chuck Hassett"
      ],
      [
        1,
        "Fritzie Higgins"
      ],
      [
        1,
        "Ted Husing"
      ],
      [
        1,
        "Laura James"
      ],
      [
        1,
        "Allen Joseph"
      ],
      [
        1,
        "Wally K. Berns"
      ],
      [
        1,
        "Lisa Katz"
      ],
      [
        1,
        "George Latka"
      ],
      [
        1,
        "Maryjane Lauria"
      ],
      [
        1,
        "Gene LeBell"
      ],
      [
        1,
        "Glenn Leigh Marshall"
      ],
      [
        1,
        "Jimmy Lennon Sr."
      ],
      [
        1,
        "Tony Lip"
      ],
      [
        1,
        "Jack Lotz"
      ],
      [
        1,
        "Victor Magnotta"
      ],
      [
        1,
        "Kevin Mahon"
      ],
      [
        1,
        "Allan Malamud"
      ],
      [
        1,
        "Joe Malanga"
      ],
      [
        1,
        "Mardik Martin"
      ],
      [
        1,
        "Richard McMurray"
      ],
      [
        1,
        "Candy Moore"
      ],
      [
        1,
        "Cathy Moriarty"
      ],
      [
        1,
        "Andrea Orlando"
      ],
      [
        1,
        "Steve Orlando"
      ],
      [
        1,
        "Daniel P. Conte"
      ],
      [
        1,
        "Harvey Parry"
      ],
      [
        1,
        "Joe Pesci"
      ],
      [
        1,
        "Peter Petrella"
      ],
      [
        1,
        "Louis Raftis"
      ],
      [
        1,
        "Ken Richards"
      ],
      [
        1,
        "Bobby Rings"
      ],
      [
        1,
        "Theresa Saldana"
      ],
      [
        1,
        "Theodore Saunders"
      ],
      [
        1,
        "Peter Savage"
      ],
      [
        1,
        "Martin Scorsese"
      ],
      [
        1,
        "Charles Scorsese"
      ],
      [
        1,
        "Sal Serafino Tomassetti"
      ],
      [
        1,
        "Frank Shain"
      ],
      [
        1,
        "Geraldine Smith"
      ],
      [
        1,
        "Lou Tiano"
      ],
      [
        1,
        "Frank Topham"
      ],
      [
        1,
        "Nick Trisko"
      ],
      [
        1,
        "Sabine Turco Jr."
      ],
      [
        1,
        "Johnny Turner"
      ],
      [
        1,
        "John Turturro"
      ],
      [
        1,
        "Robert Uricola"
      ],
      [
        1,
        "James V. Christy"
      ],
      [
        1,
        "Harold Valan"
      ],
      [
        1,
        "Billy Varga"
      ],
      [
        1,
        "Frank Vincent"
      ],
      [
        1,
        "Dick Whittington"
      ],
      [
        1,
        "Noah Young"
      ]
    ],
    "directors": "Martin Scorsese",
    "budget_string": "18m USD",
    "gross_string": "23.4m USD",
    "metascore_string": "89%"
  },
  {
    "imdb_id": "tt0082971",
    "plot": "In 1936, archaeologist and adventurer Indiana Jones is hired by the U.S. government to find the Ark of the Covenant before Adolf Hitler's Nazis can obtain its awesome powers.",
    "certification": "PG",
    "other_wins_count": 34,
    "other_noms_count": 24,
    "amazon": "https://www.amazon.com/Indiana-Jones-Raiders-Lost-Blu-ray/dp/B00G0O9XAI/ref=sr_1_2?crid=3PL47UXFJ99I9&amp;keywords=raiders+of+the+lost+ark+blu-ray&amp;qid=1654841397&amp;sprefix=raiders+of+the+lost+ark+blu-%252Caps%252C1524&amp;sr=8-2&_encoding=UTF8&tag=sianglu-20&linkCode=ur2&linkId=b6f533e5930d64cb16eeab8b3a00d58d&camp=1789&creative=9325",
    "justwatch": "https://justwatch.com/us/movie/indiana-jones-and-the-raiders-of-the-lost-ark?utm_source=share&utm_medium=web",
    "beige_index": 2,
    "beige_angle": 42.5673,
    "cast": [
      [
        5,
        "Sonny Caldinez"
      ],
      [
        5,
        "George Harris"
      ],
      [
        5,
        "Eddie Tagoe"
      ],
      [
        5,
        "Ishaq Bux"
      ],
      [
        5,
        "Kiran Shah"
      ],
      [
        4,
        "Souad Messaoudi"
      ],
      [
        4,
        "Vic Tablian"
      ],
      [
        2,
        "Anthony Chinn"
      ],
      [
        1,
        "Karen Allen"
      ],
      [
        1,
        "Patrick Durkin"
      ],
      [
        1,
        "Denholm Elliott"
      ],
      [
        1,
        "Don Fellows"
      ],
      [
        1,
        "Harrison Ford"
      ],
      [
        1,
        "Christopher Frederick"
      ],
      [
        1,
        "Paul Freeman"
      ],
      [
        1,
        "Ted Grossman"
      ],
      [
        1,
        "Steve Hanson"
      ],
      [
        1,
        "Anthony Higgins"
      ],
      [
        1,
        "William Hootkins"
      ],
      [
        1,
        "Wolf Kahler"
      ],
      [
        1,
        "Martin Kreidt"
      ],
      [
        1,
        "Ronald Lacey"
      ],
      [
        1,
        "Tutte Lemkow"
      ],
      [
        1,
        "Frank Marshall"
      ],
      [
        1,
        "Alfred Molina"
      ],
      [
        1,
        "John Rees"
      ],
      [
        1,
        "Bill Reimbold"
      ],
      [
        1,
        "John Rhys-Davies"
      ],
      [
        1,
        "Terry Richards"
      ],
      [
        1,
        "Pat Roach"
      ],
      [
        1,
        "Matthew Scurfield"
      ],
      [
        1,
        "Michael Sheard"
      ],
      [
        1,
        "Fred Sorenson"
      ],
      [
        1,
        "Tony Vogel"
      ],
      [
        1,
        "Malcolm Weaver"
      ],
      [
        1,
        "Frank Welker"
      ],
      [
        1,
        "Bill Weston"
      ]
    ],
    "directors": "Steven Spielberg",
    "budget_string": "18m USD",
    "gross_string": "389.9m USD",
    "metascore_string": "85%"
  },
  {
    "imdb_id": "tt0082096",
    "plot": "The claustrophobic world of a WWII German U-boat; boredom, filth and sheer terror.",
    "certification": "R",
    "other_wins_count": 13,
    "other_noms_count": 5,
    "amazon": "https://www.amazon.com/Das-Boot-Directors-Cut-Blu-ray/dp/B0064NTZ6W/ref=sr_1_6?crid=3SZTKA154MI6B&amp;keywords=the+boat+blu+ray&amp;qid=1654843497&amp;s=movies-tv&amp;sprefix=the+boat+blu+r%252Cmovies-tv%252C509&amp;sr=1-6&_encoding=UTF8&tag=sianglu-20&linkCode=ur2&linkId=de862508f7da94087986bc55e70917a5&camp=1789&creative=9325",
    "justwatch": "https://justwatch.com/us/movie/the-boat?utm_source=share&utm_medium=web",
    "beige_index": 1,
    "beige_angle": 0.0,
    "cast": [
      [
        1,
        "Roger Barth"
      ],
      [
        1,
        "Konrad Becker"
      ],
      [
        1,
        "Christian Bendomir"
      ],
      [
        1,
        "Hubertus Bengsch"
      ],
      [
        1,
        "Joachim Bernhard"
      ],
      [
        1,
        "Thomas Boxhammer"
      ],
      [
        1,
        "Rita Cadillac"
      ],
      [
        1,
        "Jan Fedder"
      ],
      [
        1,
        "G\u00fcnther Franke"
      ],
      [
        1,
        "Herbert Gr\u00f6nemeyer"
      ],
      [
        1,
        "Norbert Gronwald"
      ],
      [
        1,
        "Martin Hemme"
      ],
      [
        1,
        "Heinz Hoenig"
      ],
      [
        1,
        "Jean-Claude Hoffmann"
      ],
      [
        1,
        "Arno Kral"
      ],
      [
        1,
        "Albert Kraml"
      ],
      [
        1,
        "G\u00fcnter Lamprecht"
      ],
      [
        1,
        "Erwin Leder"
      ],
      [
        1,
        "Martin May"
      ],
      [
        1,
        "Helmut Neumeier"
      ],
      [
        1,
        "Uwe Ochsenknecht"
      ],
      [
        1,
        "Peter Pathenis"
      ],
      [
        1,
        "Wilhelm Pietsch"
      ],
      [
        1,
        "J\u00fcrgen Prochnow"
      ],
      [
        1,
        "Ralf Richter"
      ],
      [
        1,
        "Claude-Oliver Rudolph"
      ],
      [
        1,
        "Dirk Salomon"
      ],
      [
        1,
        "Otto Sander"
      ],
      [
        1,
        "Ferdinand Schaal"
      ],
      [
        1,
        "Lutz Schnell"
      ],
      [
        1,
        "Christian Seipolt"
      ],
      [
        1,
        "Martin Semmelrogge"
      ],
      [
        1,
        "Oliver Stritzel"
      ],
      [
        1,
        "Bernd Tauber"
      ],
      [
        1,
        "Rolf Weber"
      ],
      [
        1,
        "Klaus Wennemann"
      ],
      [
        1,
        "Lothar Zajicek"
      ]
    ],
    "directors": "Wolfgang Petersen",
    "budget_string": "13.9m USD",
    "gross_string": "11.5m USD",
    "metascore_string": "86%"
  },
  {
    "imdb_id": "tt0084787",
    "plot": "A research team in Antarctica is hunted by a shape-shifting alien that assumes the appearance of its victims.",
    "certification": "R",
    "other_wins_count": 0,
    "other_noms_count": 3,
    "amazon": "https://www.amazon.com/Thing-Collectors-Blu-ray-Kurt-Russell/dp/B01GSW5VD4/ref=sr_1_2?crid=3BP5MAQ7RS3HU&amp;keywords=the+thing+blu+ray&amp;qid=1655180912&amp;s=movies-tv&amp;sprefix=the+thing+blu+ray%252Cmovies-tv%252C275&amp;sr=1-2&_encoding=UTF8&tag=sianglu-20&linkCode=ur2&linkId=21b53015148fce406ac1519a18bfb60a&camp=1789&creative=9325",
    "justwatch": "https://justwatch.com/us/movie/thing-the?utm_source=share&utm_medium=web",
    "beige_index": 1,
    "beige_angle": 21.6,
    "cast": [
      [
        5,
        "T.K. Carter"
      ],
      [
        5,
        "Keith David"
      ],
      [
        1,
        "Wilford Brimley"
      ],
      [
        1,
        "David Clennon"
      ],
      [
        1,
        "Richard Dysart"
      ],
      [
        1,
        "Larry Franco"
      ],
      [
        1,
        "Thomas G. Waites"
      ],
      [
        1,
        "Charles Hallahan"
      ],
      [
        1,
        "Nate Irwin"
      ],
      [
        1,
        "Peter Maloney"
      ],
      [
        1,
        "Richard Masur"
      ],
      [
        1,
        "Donald Moffat"
      ],
      [
        1,
        "Joel Polis"
      ],
      [
        1,
        "Kurt Russell"
      ],
      [
        1,
        "Norbert Weisser"
      ],
      [
        1,
        "William Zeman"
      ]
    ],
    "directors": "John Carpenter",
    "budget_string": "15m USD",
    "gross_string": "19.6m USD",
    "metascore_string": "57%"
  },
  {
    "imdb_id": "tt0083987",
    "plot": "The life of the lawyer who became the famed leader of the Indian revolts against the British rule through his philosophy of nonviolent protest.",
    "certification": "PG",
    "other_wins_count": 27,
    "other_noms_count": 21,
    "amazon": "https://www.amazon.com/Gandhi-Blu-ray-Edward-Fox/dp/B001MVYUPC/ref=sr_1_3?crid=CGN5YW6YXAZN&amp;keywords=gandhi+blu+ray&amp;qid=1655194518&amp;s=movies-tv&amp;sprefix=gandhi+blu+ray%252Cmovies-tv%252C273&amp;sr=1-3&_encoding=UTF8&tag=sianglu-20&linkCode=ur2&linkId=ff5abfde96fce4d2984ec18cb67055f0&camp=1789&creative=9325",
    "justwatch": "https://justwatch.com/us/movie/gandhi?utm_source=share&utm_medium=web",
    "beige_index": 3,
    "beige_angle": 77.9184,
    "cast": [
      [
        5,
        "Winston Ntshona"
      ],
      [
        5,
        "Aadil"
      ],
      [
        5,
        "Amarjeet"
      ],
      [
        5,
        "Mohan Agashe"
      ],
      [
        5,
        "Jalal Agha"
      ],
      [
        5,
        "Vinay Apte"
      ],
      [
        5,
        "Bob Babenia"
      ],
      [
        5,
        "Charu Bala Chokshi"
      ],
      [
        5,
        "Raja Biswas"
      ],
      [
        5,
        "Shekhar Chatterjee"
      ],
      [
        5,
        "Raj Chaturvedi"
      ],
      [
        5,
        "Vijay Crishna"
      ],
      [
        5,
        "Sudhir Dalvi"
      ],
      [
        5,
        "Homi Daruvala"
      ],
      [
        5,
        "Pratap Desai"
      ],
      [
        5,
        "Anang Desai"
      ],
      [
        5,
        "Avinash Dogra"
      ],
      [
        5,
        "Sankalp Dubey"
      ],
      [
        5,
        "Neena Gupta"
      ],
      [
        5,
        "Monica Gupta"
      ],
      [
        5,
        "Alpna Gupta"
      ],
      [
        5,
        "Rahul Gupta"
      ],
      [
        5,
        "Subhash Gupta"
      ],
      [
        5,
        "Rohini Hattangadi"
      ],
      [
        5,
        "Saeed Jaffrey"
      ],
      [
        5,
        "Avpar Jhita"
      ],
      [
        5,
        "Shreedhar Joshi"
      ],
      [
        5,
        "Bani Joshi"
      ],
      [
        5,
        "Rama Kant Jha"
      ],
      [
        5,
        "Gulshan Kapoor"
      ],
      [
        5,
        "Pren Kapoor"
      ],
      [
        5,
        "Pankaj Kapur"
      ],
      [
        5,
        "Vijay Kashyap"
      ],
      [
        5,
        "Aswani Kumar"
      ],
      [
        5,
        "Roop Kumar Razdan"
      ],
      [
        5,
        "Vagish Kumar Singh"
      ],
      [
        5,
        "Shreeram Lagoo"
      ],
      [
        5,
        "Moti Makan"
      ],
      [
        5,
        "Hansu Mehta"
      ],
      [
        5,
        "Tarla Mehta"
      ],
      [
        5,
        "Sudhanshu Mishra"
      ],
      [
        5,
        "Pankaj Mohan"
      ],
      [
        5,
        "Rajeshwar Nath"
      ],
      [
        5,
        "Alok Nath"
      ],
      [
        5,
        "Dina Nath"
      ],
      [
        5,
        "Harsh Nayyar"
      ],
      [
        5,
        "Alyque Padamsee"
      ],
      [
        5,
        "Suhas Palshikar"
      ],
      [
        5,
        "Nana Palsikar"
      ],
      [
        5,
        "Prabhakar Patankar"
      ],
      [
        5,
        "Supriya Pathak"
      ],
      [
        5,
        "Manohar Pitale"
      ],
      [
        5,
        "Sunila Pradhan"
      ],
      [
        5,
        "Nigam Prakash"
      ],
      [
        5,
        "Bhatawadekar Prakash"
      ],
      [
        5,
        "Sanjeev Puri"
      ],
      [
        5,
        "Om Puri"
      ],
      [
        5,
        "Amrish Puri"
      ],
      [
        5,
        "Irpinder Puri"
      ],
      [
        5,
        "K.K. Raina"
      ],
      [
        5,
        "Tilak Raj"
      ],
      [
        5,
        "Virendra Razdan"
      ],
      [
        5,
        "Anthony Sagger"
      ],
      [
        5,
        "Jyoti Sarup"
      ],
      [
        5,
        "Roshan Seth"
      ],
      [
        5,
        "Sudarshan Sethi"
      ],
      [
        5,
        "Sunil Shende"
      ],
      [
        5,
        "Dilsher Singh"
      ],
      [
        5,
        "Gurcharan Singh"
      ],
      [
        5,
        "Karkirat Singh"
      ],
      [
        5,
        "Rovil Sinha"
      ],
      [
        5,
        "Vivek Swaroop"
      ],
      [
        5,
        "Dalip Tahil"
      ],
      [
        5,
        "Habib Tanvir"
      ],
      [
        5,
        "Chandrakant Thakkar"
      ],
      [
        5,
        "S.S. Thakur"
      ],
      [
        3,
        "Ben Kingsley"
      ],
      [
        1,
        "Tom Alter"
      ],
      [
        1,
        "Ian Bannen"
      ],
      [
        1,
        "Candice Bergen"
      ],
      [
        1,
        "John Boxer"
      ],
      [
        1,
        "Michael Bryant"
      ],
      [
        1,
        "Avis Bunnage"
      ],
      [
        1,
        "Ray Burdis"
      ],
      [
        1,
        "Peter Cartwright"
      ],
      [
        1,
        "Norman Chancer"
      ],
      [
        1,
        "Ian Charleson"
      ],
      [
        1,
        "Geoffrey Chater"
      ],
      [
        1,
        "Ernest Clark"
      ],
      [
        1,
        "John Clements"
      ],
      [
        1,
        "James Cossins"
      ],
      [
        1,
        "Jon Croft"
      ],
      [
        1,
        "Daniel Day-Lewis"
      ],
      [
        1,
        "Keith Drinkel"
      ],
      [
        1,
        "Colin Farrell"
      ],
      [
        1,
        "Gareth Forwood"
      ],
      [
        1,
        "Edward Fox"
      ],
      [
        1,
        "Rupert Frazer"
      ],
      [
        1,
        "Athol Fugard"
      ],
      [
        1,
        "David Gant"
      ],
      [
        1,
        "Wilson George"
      ],
      [
        1,
        "John Gielgud"
      ],
      [
        1,
        "Michael Godley"
      ],
      [
        1,
        "Christopher Good"
      ],
      [
        1,
        "Richard Griffiths"
      ],
      [
        1,
        "Dominic Guard"
      ],
      [
        1,
        "Terrence Hardiman"
      ],
      [
        1,
        "Peter Harlowe"
      ],
      [
        1,
        "Stewart Harwood"
      ],
      [
        1,
        "Nigel Hawthorne"
      ],
      [
        1,
        "Bernard Hepton"
      ],
      [
        1,
        "Bernard Hill"
      ],
      [
        1,
        "Michael Hordern"
      ],
      [
        1,
        "Bernard Horsfall"
      ],
      [
        1,
        "Trevor Howard"
      ],
      [
        1,
        "William Hoyland"
      ],
      [
        1,
        "Caroline Hutchison"
      ],
      [
        1,
        "Ken Hutchison"
      ],
      [
        1,
        "Geraldine James"
      ],
      [
        1,
        "Barry John"
      ],
      [
        1,
        "Stanley Lebor"
      ],
      [
        1,
        "Richard Leech"
      ],
      [
        1,
        "G\u00fcnther Maria Halmer"
      ],
      [
        1,
        "David Markham"
      ],
      [
        1,
        "Richard Mayes"
      ],
      [
        1,
        "Stanley McGeagh"
      ],
      [
        1,
        "Jack McKenzie"
      ],
      [
        1,
        "John Mills"
      ],
      [
        1,
        "Jane Myerson"
      ],
      [
        1,
        "John Naylor"
      ],
      [
        1,
        "Gerard Norman"
      ],
      [
        1,
        "Brian Oulton"
      ],
      [
        1,
        "John Patrick"
      ],
      [
        1,
        "Daniel Peacock"
      ],
      [
        1,
        "John Quentin"
      ],
      [
        1,
        "John Ratzenberger"
      ],
      [
        1,
        "Shane Rimmer"
      ],
      [
        1,
        "John Savident"
      ],
      [
        1,
        "Graham Seed"
      ],
      [
        1,
        "Martin Sheen"
      ],
      [
        1,
        "David Sibley"
      ],
      [
        1,
        "Gerald Sim"
      ],
      [
        1,
        "James Snell"
      ],
      [
        1,
        "Richard Vernon"
      ],
      [
        1,
        "John Vine"
      ],
      [
        1,
        "Marius Weyers"
      ]
    ],
    "directors": "Richard Attenborough",
    "budget_string": "22m USD",
    "gross_string": "52.8m USD",
    "metascore_string": "79%"
  },
  {
    "imdb_id": "tt0083658",
    "plot": "A blade runner must pursue and terminate four replicants who stole a ship in space, and have returned to Earth to find their creator.",
    "certification": "R",
    "other_wins_count": 12,
    "other_noms_count": 17,
    "amazon": "https://www.amazon.com/Blade-Runner-Final-Harrison-Ford/dp/B004GDB7OA/ref=sr_1_2?crid=1PR754HKLQ4YW&amp;keywords=blade+runner+blu+ray&amp;qid=1655182351&amp;sprefix=blade+runner+blu+ray%252Caps%252C288&amp;sr=8-2&_encoding=UTF8&tag=sianglu-20&linkCode=ur2&linkId=6413086ef64a39615b25bcbb10033b34&camp=1789&creative=9325",
    "justwatch": "https://justwatch.com/us/movie/blade-runner?utm_source=share&utm_medium=web",
    "beige_index": 1,
    "beige_angle": 31.5,
    "cast": [
      [
        5,
        "Tony Cox"
      ],
      [
        3,
        "Edward James Olmos"
      ],
      [
        2,
        "Kimiko Hiroshige"
      ],
      [
        2,
        "James Hong"
      ],
      [
        2,
        "Bob Okazaki"
      ],
      [
        1,
        "Joanna Cassidy"
      ],
      [
        1,
        "Carolyn DeMirjian"
      ],
      [
        1,
        "John Edward Allen"
      ],
      [
        1,
        "M. Emmet Walsh"
      ],
      [
        1,
        "Harrison Ford"
      ],
      [
        1,
        "Daryl Hannah"
      ],
      [
        1,
        "Rutger Hauer"
      ],
      [
        1,
        "Brion James"
      ],
      [
        1,
        "Charles Knapp"
      ],
      [
        1,
        "Morgan Paull"
      ],
      [
        1,
        "Hy Pyke"
      ],
      [
        1,
        "William Sanderson"
      ],
      [
        1,
        "Kevin Thompson"
      ],
      [
        1,
        "Joe Turkel"
      ],
      [
        1,
        "Sean Young"
      ]
    ],
    "directors": "Ridley Scott",
    "budget_string": "30m USD",
    "gross_string": "41.7m USD",
    "metascore_string": "84%"
  },
  {
    "imdb_id": "tt0086250",
    "plot": "In 1980 Miami, a determined Cuban immigrant takes over a drug cartel and succumbs to greed.",
    "certification": "R",
    "other_wins_count": 0,
    "other_noms_count": 8,
    "amazon": "https://www.amazon.com/Scarface-Blu-Ray-Al-Pacino/dp/B07X9MGD4S/ref=sr_1_17?crid=11CMHFSUMHP7H&amp;keywords=scarface+blu+ray&amp;qid=1655007338&amp;sprefix=scarface+blu+ray%252Caps%252C356&amp;sr=8-17&_encoding=UTF8&tag=sianglu-20&linkCode=ur2&linkId=eea9156091e1946e32435dc274ae9e53&camp=1789&creative=9325",
    "justwatch": "https://justwatch.com/us/movie/scarface-1983?utm_source=share&utm_medium=web",
    "beige_index": 2,
    "beige_angle": 43.1082,
    "cast": [
      [
        3,
        "Loren Almaguer"
      ],
      [
        3,
        "Gil Barreto"
      ],
      [
        3,
        "Steven Bauer"
      ],
      [
        3,
        "Victor Campos"
      ],
      [
        3,
        "Gary Carlos Cervantes"
      ],
      [
        3,
        "Rene Carrasco"
      ],
      [
        3,
        "Carlos Cestero"
      ],
      [
        3,
        "Miriam Colon"
      ],
      [
        3,
        "Roberto Contreras"
      ],
      [
        3,
        "Caesar Cordova"
      ],
      [
        3,
        "Gregory Cruz"
      ],
      [
        3,
        "Ray Martel"
      ],
      [
        3,
        "Richard Mendez"
      ],
      [
        3,
        "Santos Morales"
      ],
      [
        3,
        "Mike Moroff"
      ],
      [
        3,
        "Manuel Padilla Jr."
      ],
      [
        3,
        "Barbra Perez"
      ],
      [
        3,
        "Tony Perez"
      ],
      [
        3,
        "\u00c1ngel Salazar"
      ],
      [
        3,
        "Arnaldo Santana"
      ],
      [
        3,
        "Pepe Serna"
      ],
      [
        3,
        "Geno Silva"
      ],
      [
        3,
        "Ilka Tanya Pay\u00e1n"
      ],
      [
        3,
        "Bob Yanez"
      ],
      [
        3,
        "Heather Benna"
      ],
      [
        3,
        "Richard Delmonte"
      ],
      [
        3,
        "Joe Marmo"
      ],
      [
        3,
        "Victor Millan"
      ],
      [
        3,
        "Angela Nisi"
      ],
      [
        3,
        "Tony Pann"
      ],
      [
        3,
        "Robert Vandenberg"
      ],
      [
        2,
        "Mario Machado"
      ],
      [
        2,
        "Charlie Adiano"
      ],
      [
        2,
        "Marii Mak"
      ],
      [
        1,
        "Charles A. Tamburro"
      ],
      [
        1,
        "Angela Aames"
      ],
      [
        1,
        "Michael Alldredge"
      ],
      [
        1,
        "Dona Baldwin"
      ],
      [
        1,
        "Richard Belzer"
      ],
      [
        1,
        "Ted Beniades"
      ],
      [
        1,
        "Lee Benton"
      ],
      [
        1,
        "Sue Bowser"
      ],
      [
        1,
        "John Brandon"
      ],
      [
        1,
        "Cynthia Burr"
      ],
      [
        1,
        "Albert Carrier"
      ],
      [
        1,
        "John Carter"
      ],
      [
        1,
        "Lana Clarkson"
      ],
      [
        1,
        "Johnny Contardo"
      ],
      [
        1,
        "Karen Criswell"
      ],
      [
        1,
        "Emilia Crow"
      ],
      [
        1,
        "Dante D'Andre"
      ],
      [
        1,
        "Wayne Doba"
      ],
      [
        1,
        "Charles Durning"
      ],
      [
        1,
        "Mary Elizabeth Mastrantonio"
      ],
      [
        1,
        "Paul Espel"
      ],
      [
        1,
        "Michel Fran\u00e7ois"
      ],
      [
        1,
        "Ben Frommer"
      ],
      [
        1,
        "Ronald G. Joseph"
      ],
      [
        1,
        "Bob Harks"
      ],
      [
        1,
        "Dennis Holahan"
      ],
      [
        1,
        "Al Israel"
      ],
      [
        1,
        "Lisa Katz"
      ],
      [
        1,
        "Margo Kelly"
      ],
      [
        1,
        "Ava Lazar"
      ],
      [
        1,
        "Nancy Lee Andrews"
      ],
      [
        1,
        "Tina Leigh Cameron"
      ],
      [
        1,
        "Jeanette Linne"
      ],
      [
        1,
        "Robert Loggia"
      ],
      [
        1,
        "Kevyn Major Howard"
      ],
      [
        1,
        "Mark Margolis"
      ],
      [
        1,
        "John McCann"
      ],
      [
        1,
        "Margaret Michaels"
      ],
      [
        1,
        "F. Murray Abraham"
      ],
      [
        1,
        "Michael P. Moran"
      ],
      [
        1,
        "Al Pacino"
      ],
      [
        1,
        "Michelle Pfeiffer"
      ],
      [
        1,
        "Edward R. Frommer"
      ],
      [
        1,
        "Michael Rougas"
      ],
      [
        1,
        "Katt Shea"
      ],
      [
        1,
        "Paul Shenar"
      ],
      [
        1,
        "Pat Simmons"
      ],
      [
        1,
        "Garnett Smith"
      ],
      [
        1,
        "Terri Taylor"
      ],
      [
        1,
        "Shelley Taylor Morgan"
      ],
      [
        1,
        "Jim Towers"
      ],
      [
        1,
        "Nick Trisko"
      ],
      [
        1,
        "Marcia Wolf"
      ],
      [
        1,
        "Harris Yulin"
      ]
    ],
    "directors": "Brian De Palma",
    "budget_string": "25m USD",
    "gross_string": "65.9m USD",
    "metascore_string": "65%"
  },
  {
    "imdb_id": "tt0086190",
    "plot": "After a daring mission to rescue Han Solo from Jabba the Hutt, the Rebels dispatch to Endor to destroy the second Death Star. Meanwhile, Luke struggles to help Darth Vader back from the dark side without falling into the Emperor's trap.",
    "certification": "PG",
    "other_wins_count": 23,
    "other_noms_count": 16,
    "amazon": "https://www.amazon.com/Star-Wars-Return-Jedi-Feature/dp/B07TPYX1TP/ref=tmm_blu_swatch_0?_encoding=UTF8&amp;qid=1654844495&amp;sr=1-4&_encoding=UTF8&tag=sianglu-20&linkCode=ur2&linkId=6708082eee68be7d985aa347e1032521&camp=1789&creative=9325",
    "justwatch": "https://justwatch.com/us/movie/star-wars-episode-vi-return-of-the-jedi?utm_source=share&utm_medium=web",
    "beige_index": 1,
    "beige_angle": 22.3992,
    "cast": [
      [
        5,
        "Tony Cox"
      ],
      [
        5,
        "Billy Dee Williams"
      ],
      [
        5,
        "James Earl Jones"
      ],
      [
        5,
        "Femi Taylor"
      ],
      [
        5,
        "Kiran Shah"
      ],
      [
        3,
        "Luis De Jesus"
      ],
      [
        3,
        "Margarita Farrell"
      ],
      [
        3,
        "Mercedes Ngoh"
      ],
      [
        2,
        "Dalyn Chew"
      ],
      [
        2,
        "Eiji Kusuhara"
      ],
      [
        1,
        "Margo Apostolos"
      ],
      [
        1,
        "Annie Arbogast"
      ],
      [
        1,
        "Ray Armstrong"
      ],
      [
        1,
        "Kenny Baker"
      ],
      [
        1,
        "Eileen Baker"
      ],
      [
        1,
        "Adam Bareham"
      ],
      [
        1,
        "Bobby Bell"
      ],
      [
        1,
        "Patty Bell"
      ],
      [
        1,
        "Alan Bennett"
      ],
      [
        1,
        "Sarah Bennett"
      ],
      [
        1,
        "Pamela Betts"
      ],
      [
        1,
        "Danny Blackner"
      ],
      [
        1,
        "Caroline Blakiston"
      ],
      [
        1,
        "Linda Bowley"
      ],
      [
        1,
        "Jeremy Bulloch"
      ],
      [
        1,
        "Peter Burroughs"
      ],
      [
        1,
        "Ben Burtt"
      ],
      [
        1,
        "Jane Busby"
      ],
      [
        1,
        "Michael Carter"
      ],
      [
        1,
        "Vivienne Chandler"
      ],
      [
        1,
        "Maureen Charlton"
      ],
      [
        1,
        "Kenneth Colley"
      ],
      [
        1,
        "Willie Coppen"
      ],
      [
        1,
        "Sadie Corre"
      ],
      [
        1,
        "Mike Cottrell"
      ],
      [
        1,
        "Dermot Crowley"
      ],
      [
        1,
        "John Cumming"
      ],
      [
        1,
        "Jean D'Agostino"
      ],
      [
        1,
        "Anthony Daniels"
      ],
      [
        1,
        "Claire Davenport"
      ],
      [
        1,
        "Warwick Davis"
      ],
      [
        1,
        "Malcolm Dixon"
      ],
      [
        1,
        "Debbie Dixon"
      ],
      [
        1,
        "Mike Edmonds"
      ],
      [
        1,
        "Carrie Fisher"
      ],
      [
        1,
        "Phil Fondacaro"
      ],
      [
        1,
        "Sal Fondacaro"
      ],
      [
        1,
        "Harrison Ford"
      ],
      [
        1,
        "Daniel Frishman"
      ],
      [
        1,
        "Celia Fushille-Burke"
      ],
      [
        1,
        "Michael Gilden"
      ],
      [
        1,
        "Paul Grant"
      ],
      [
        1,
        "Lydia Green"
      ],
      [
        1,
        "Lars Green"
      ],
      [
        1,
        "Alec Guinness"
      ],
      [
        1,
        "Mark Hamill"
      ],
      [
        1,
        "Michael Henbury Ballan"
      ],
      [
        1,
        "Andrew Herd"
      ],
      [
        1,
        "William Hoyland"
      ],
      [
        1,
        "Billy J. Mitchell"
      ],
      [
        1,
        "Jennifer Jaffe"
      ],
      [
        1,
        "Glynn Jones"
      ],
      [
        1,
        "Trevor Jones"
      ],
      [
        1,
        "Richard Jones"
      ],
      [
        1,
        "Denis Lawson"
      ],
      [
        1,
        "Debbie Lee Carrington"
      ],
      [
        1,
        "Nancy Maclean"
      ],
      [
        1,
        "Peter Mandell"
      ],
      [
        1,
        "Tom Mannion"
      ],
      [
        1,
        "Peter Mayhew"
      ],
      [
        1,
        "Ian McDiarmid"
      ],
      [
        1,
        "Jonathan Oliver"
      ],
      [
        1,
        "Frank Oz"
      ],
      [
        1,
        "Michael Pennington"
      ],
      [
        1,
        "Ronnie Phillips"
      ],
      [
        1,
        "David Prowse"
      ],
      [
        1,
        "Jack Purvis"
      ],
      [
        1,
        "Katie Purvis"
      ],
      [
        1,
        "Nicolas Read"
      ],
      [
        1,
        "Nicholas Read"
      ],
      [
        1,
        "Carol Read"
      ],
      [
        1,
        "Chris Romano"
      ],
      [
        1,
        "Tim Rose"
      ],
      [
        1,
        "Sebastian Shaw"
      ],
      [
        1,
        "Felix Silla"
      ],
      [
        1,
        "Hugh Spight"
      ],
      [
        1,
        "Gerald Staddon"
      ],
      [
        1,
        "Kevin Thompson"
      ],
      [
        1,
        "Kendra Wall"
      ],
      [
        1,
        "Brian Wheeler"
      ]
    ],
    "directors": "Richard Marquand",
    "budget_string": "32.5m USD",
    "gross_string": "475.1m USD",
    "metascore_string": "58%"
  },
  {
    "imdb_id": "tt0086879",
    "plot": "The life, success and troubles of Wolfgang Amadeus Mozart, as told by Antonio Salieri, the contemporaneous composer who was insanely jealous of Mozart's talent and claimed to have murdered him.",
    "certification": "R",
    "other_wins_count": 35,
    "other_noms_count": 15,
    "amazon": "https://www.amazon.com/Amadeus-Directors-F-Murray-Abraham/dp/B002GTZSZU/ref=sr_1_2?crid=1PB0JNCQ098B8&amp;keywords=amadeus&amp;qid=1654843122&amp;s=movies-tv&amp;sprefix=amade%252Cmovies-tv%252C684&amp;sr=1-2&_encoding=UTF8&tag=sianglu-20&linkCode=ur2&linkId=60b85a302c9267ec70a8d03d93f7a8e3&camp=1789&creative=9325",
    "justwatch": "https://justwatch.com/us/movie/amadeus?utm_source=share&utm_medium=web",
    "beige_index": 1,
    "beige_angle": 0.0,
    "cast": [
      [
        1,
        "Kenny Baker"
      ],
      [
        1,
        "Lisbeth Bartlett"
      ],
      [
        1,
        "Elizabeth Berridge"
      ],
      [
        1,
        "Jan Blazek"
      ],
      [
        1,
        "Hana Brejchov\u00e1"
      ],
      [
        1,
        "Barbara Bryne"
      ],
      [
        1,
        "Simon Callow"
      ],
      [
        1,
        "John Carrafa"
      ],
      [
        1,
        "Martin Cavina"
      ],
      [
        1,
        "Milada Cechalova"
      ],
      [
        1,
        "Magda Celakovska"
      ],
      [
        1,
        "Miriam Chytilov\u00e1"
      ],
      [
        1,
        "Helena Cihelnikova"
      ],
      [
        1,
        "Sara Clifford"
      ],
      [
        1,
        "Richard Colton"
      ],
      [
        1,
        "Roderick Cook"
      ],
      [
        1,
        "Milan Demjanenko"
      ],
      [
        1,
        "Peter DiGesu"
      ],
      [
        1,
        "Roy Dotrice"
      ],
      [
        1,
        "Slavena Drasilova"
      ],
      [
        1,
        "Christine Ebersole"
      ],
      [
        1,
        "Karel Effa"
      ],
      [
        1,
        "Karel Fiala"
      ],
      [
        1,
        "Radka Fiedlerov\u00e1"
      ],
      [
        1,
        "Richard Frank"
      ],
      [
        1,
        "Shelley Freydont"
      ],
      [
        1,
        "Rene Gabzdyl"
      ],
      [
        1,
        "Miro Grisa"
      ],
      [
        1,
        "Karel Gult"
      ],
      [
        1,
        "Karel H\u00e1bl"
      ],
      [
        1,
        "Therese Herz"
      ],
      [
        1,
        "Patrick Hines"
      ],
      [
        1,
        "Tom Hulce"
      ],
      [
        1,
        "Atka Janouskov\u00e1"
      ],
      [
        1,
        "Marta Jarolimkova"
      ],
      [
        1,
        "Zdenek Jelen"
      ],
      [
        1,
        "Jeffrey Jones"
      ],
      [
        1,
        "Zuzana Kadlecova"
      ],
      [
        1,
        "Charles Kay"
      ],
      [
        1,
        "Mary Kellogg"
      ],
      [
        1,
        "Nicholas Kepros"
      ],
      [
        1,
        "Leos Kratochvil"
      ],
      [
        1,
        "Gabriela Krckova"
      ],
      [
        1,
        "Ladislav Krecmer"
      ],
      [
        1,
        "Vladimir Krousky"
      ],
      [
        1,
        "Jir\u00ed Krytin\u00e1r"
      ],
      [
        1,
        "Radka Kucharova"
      ],
      [
        1,
        "Raymond Kurshals"
      ],
      [
        1,
        "Jan Kuzelka"
      ],
      [
        1,
        "Philip Lenkowsky"
      ],
      [
        1,
        "Jir\u00ed L\u00edr"
      ],
      [
        1,
        "Lenka Loubalov\u00e1"
      ],
      [
        1,
        "John Malashock"
      ],
      [
        1,
        "Dagmar Maskova"
      ],
      [
        1,
        "Kenneth McMillan"
      ],
      [
        1,
        "Herman Meckler"
      ],
      [
        1,
        "Ladislav Mikes"
      ],
      [
        1,
        "Janoslav Mikul\u00edn"
      ],
      [
        1,
        "Jitka Molavcov\u00e1"
      ],
      [
        1,
        "Jonathan Moore"
      ],
      [
        1,
        "F. Murray Abraham"
      ],
      [
        1,
        "Jana Musilov\u00e1"
      ],
      [
        1,
        "Vojtech Nalezenec"
      ],
      [
        1,
        "Cynthia Nixon"
      ],
      [
        1,
        "Pavel Nov\u00fd"
      ],
      [
        1,
        "Jiri Opsatko"
      ],
      [
        1,
        "Brian Pettifer"
      ],
      [
        1,
        "Jan Pohan"
      ],
      [
        1,
        "Ivan Pokorn\u00fd"
      ],
      [
        1,
        "Tom Rawe"
      ],
      [
        1,
        "Milan Riehs"
      ],
      [
        1,
        "Vincent Schiavelli"
      ],
      [
        1,
        "Douglas Seale"
      ],
      [
        1,
        "Iva Sebkova"
      ],
      [
        1,
        "Miroslav Sekera"
      ],
      [
        1,
        "Eva Senkov\u00e1"
      ],
      [
        1,
        "Zdenek Sklenar"
      ],
      [
        1,
        "John Strauss"
      ],
      [
        1,
        "Cassie Stuart"
      ],
      [
        1,
        "Karl-Heinz Teuber"
      ],
      [
        1,
        "Renata Vackova"
      ],
      [
        1,
        "Jiri Vancura"
      ],
      [
        1,
        "Dana V\u00e1vrov\u00e1"
      ],
      [
        1,
        "Petra Vogelova"
      ],
      [
        1,
        "William Whitener"
      ],
      [
        1,
        "Gino Zeman"
      ],
      [
        1,
        "Josef Zeman"
      ],
      [
        1,
        "Rita Zohar"
      ]
    ],
    "directors": "Milos Forman",
    "budget_string": "18m USD",
    "gross_string": "52.1m USD",
    "metascore_string": "88%"
  },
  {
    "imdb_id": "tt0088247",
    "plot": "A human soldier is sent from 2029 to 1984 to stop an almost indestructible cyborg killing machine, sent from the same year, which has been programmed to execute a young woman whose unborn son is the key to humanity's future salvation.",
    "certification": "R",
    "other_wins_count": 8,
    "other_noms_count": 6,
    "amazon": "https://www.amazon.com/Terminator-Blu-ray-Packaging-Color-Vary/dp/B00UA86F1A/ref=tmm_mfc_swatch_0?_encoding=UTF8&amp;qid=1655184731&amp;sr=1-6&_encoding=UTF8&tag=sianglu-20&linkCode=ur2&linkId=6b1ddf6af7ce581631681f1cd7685f8d&camp=1789&creative=9325",
    "justwatch": "https://justwatch.com/us/movie/the-terminator?utm_source=share&utm_medium=web",
    "beige_index": 1,
    "beige_angle": 35.163,
    "cast": [
      [
        5,
        "Chino 'Fats' Williams"
      ],
      [
        5,
        "Loree Frazier"
      ],
      [
        5,
        "Paul Winfield"
      ],
      [
        3,
        "Philip Gordon"
      ],
      [
        3,
        "Tony Mirelez"
      ],
      [
        3,
        "Anthony Trujillo"
      ],
      [
        3,
        "Ed Dogans"
      ],
      [
        1,
        "Michael Biehn"
      ],
      [
        1,
        "Earl Boen"
      ],
      [
        1,
        "Franco Columbu"
      ],
      [
        1,
        "John Durban"
      ],
      [
        1,
        "John E. Bristol"
      ],
      [
        1,
        "Joe Farago"
      ],
      [
        1,
        "Hugh Farrington"
      ],
      [
        1,
        "Norman Friedman"
      ],
      [
        1,
        "Ken Fritz"
      ],
      [
        1,
        "Linda Hamilton"
      ],
      [
        1,
        "Lance Henriksen"
      ],
      [
        1,
        "Al Kahn"
      ],
      [
        1,
        "Hettie Lynne Hurtes"
      ],
      [
        1,
        "Bruce M. Kerner"
      ],
      [
        1,
        "Harriet Medin"
      ],
      [
        1,
        "David Michels"
      ],
      [
        1,
        "Dick Miller"
      ],
      [
        1,
        "Leslie Morris"
      ],
      [
        1,
        "Bess Motta"
      ],
      [
        1,
        "Marianne Muellerleile"
      ],
      [
        1,
        "Tom Oberhaus"
      ],
      [
        1,
        "Bill Paxton"
      ],
      [
        1,
        "Patrick Pinney"
      ],
      [
        1,
        "Barbara Powers"
      ],
      [
        1,
        "James Ralston"
      ],
      [
        1,
        "Brad Rearden"
      ],
      [
        1,
        "Greg Robbins"
      ],
      [
        1,
        "Rick Rossovich"
      ],
      [
        1,
        "Shawn Schepps"
      ],
      [
        1,
        "Arnold Schwarzenegger"
      ],
      [
        1,
        "Wayne Stone"
      ],
      [
        1,
        "Brian Thompson"
      ],
      [
        1,
        "Bill W. Richmond"
      ],
      [
        1,
        "Webster Williams"
      ],
      [
        1,
        "William Wisher"
      ],
      [
        1,
        "Stan Yale"
      ]
    ],
    "directors": "James Cameron",
    "budget_string": "6.4m USD",
    "gross_string": "78.4m USD",
    "metascore_string": "84%"
  },
  {
    "imdb_id": "tt0087843",
    "plot": "A former Prohibition-era Jewish gangster returns to the Lower East Side of Manhattan 35 years later, where he must once again confront the ghosts and regrets of his old life.",
    "certification": "R",
    "other_wins_count": 10,
    "other_noms_count": 12,
    "amazon": "https://www.amazon.com/Once-Upon-America-Extended-Directors/dp/B00KSQ0N44/ref=sr_1_2?crid=3MKU4BTLEIGG4&amp;keywords=once+upon+a+time+in+america&amp;qid=1654843750&amp;s=movies-tv&amp;sprefix=once+upon+a+time+in+ameri%252Cmovies-tv%252C706&amp;sr=1-2&_encoding=UTF8&tag=sianglu-20&linkCode=ur2&linkId=0672110dc7ea3a310aa47da1174caad7&camp=1789&creative=9325",
    "justwatch": "https://justwatch.com/us/movie/once-upon-a-time-in-america?utm_source=share&utm_medium=web",
    "beige_index": 1,
    "beige_angle": 0.0,
    "cast": [
      [
        1,
        "Danny Aiello"
      ],
      [
        1,
        "Bruce Bahrenburg"
      ],
      [
        1,
        "Brian Bloom"
      ],
      [
        1,
        "Mario Brega"
      ],
      [
        1,
        "Richard Bright"
      ],
      [
        1,
        "Clem Caserta"
      ],
      [
        1,
        "Frankie Caserta"
      ],
      [
        1,
        "Julie Cohen"
      ],
      [
        1,
        "Jennifer Connelly"
      ],
      [
        1,
        "Tandy Cronyn"
      ],
      [
        1,
        "Cliff Cudney"
      ],
      [
        1,
        "Adrian Curran"
      ],
      [
        1,
        "Robert De Niro"
      ],
      [
        1,
        "Ray Dittrich"
      ],
      [
        1,
        "Paul Farentino"
      ],
      [
        1,
        "Joey Faye"
      ],
      [
        1,
        "Louise Fletcher"
      ],
      [
        1,
        "Angelo Florio"
      ],
      [
        1,
        "Darlanne Fluegel"
      ],
      [
        1,
        "Richard Foronjy"
      ],
      [
        1,
        "William Forsythe"
      ],
      [
        1,
        "Mike Gendel"
      ],
      [
        1,
        "Frank Gio"
      ],
      [
        1,
        "Alexander Godfrey"
      ],
      [
        1,
        "Robert Harper"
      ],
      [
        1,
        "Estelle Harris"
      ],
      [
        1,
        "Baxter Harris"
      ],
      [
        1,
        "James Hayden"
      ],
      [
        1,
        "Paul Herman"
      ],
      [
        1,
        "Bruno Iannone"
      ],
      [
        1,
        "Linda Ipanema"
      ],
      [
        1,
        "Rusty Jacobs"
      ],
      [
        1,
        "Marcia Jean Kurtz"
      ],
      [
        1,
        "Olga Karlatos"
      ],
      [
        1,
        "Marty Licata"
      ],
      [
        1,
        "Massimo Liti"
      ],
      [
        1,
        "Chuck Low"
      ],
      [
        1,
        "Joey Marzella"
      ],
      [
        1,
        "Elizabeth McGovern"
      ],
      [
        1,
        "Arnon Milchan"
      ],
      [
        1,
        "Dutch Miller"
      ],
      [
        1,
        "Noah Moazezi"
      ],
      [
        1,
        "Mike Monetti"
      ],
      [
        1,
        "Gerard Murphy"
      ],
      [
        1,
        "Ann Neville"
      ],
      [
        1,
        "Joe Pesci"
      ],
      [
        1,
        "Larry Rapp"
      ],
      [
        1,
        "James Russo"
      ],
      [
        1,
        "Amy Ryder"
      ],
      [
        1,
        "Marvin Scott"
      ],
      [
        1,
        "Karen Shallo"
      ],
      [
        1,
        "Frank Sisto"
      ],
      [
        1,
        "Sandra Solberg"
      ],
      [
        1,
        "Jerry Strivelli"
      ],
      [
        1,
        "Scott Tiler"
      ],
      [
        1,
        "Tuesday Weld"
      ],
      [
        1,
        "Treat Williams"
      ],
      [
        1,
        "James Woods"
      ],
      [
        1,
        "Burt Young"
      ],
      [
        1,
        "Richard Zobel"
      ]
    ],
    "directors": "Sergio Leone",
    "budget_string": "30m USD",
    "gross_string": "5.5m USD",
    "metascore_string": "75%"
  },
  {
    "imdb_id": "tt0091251",
    "plot": "After finding an old rifle, a young boy joins the Soviet resistance movement against ruthless German forces and experiences the horrors of World War II.",
    "certification": "Not Rated",
    "other_wins_count": 3,
    "other_noms_count": 0,
    "amazon": "https://www.amazon.com/Come-See-Criterion-Collection-Blu-ray/dp/B0863FG2VH/ref=sr_1_1?crid=2K5U7GRVN5X2I&amp;keywords=come+and+see&amp;qid=1654930840&amp;s=movies-tv&amp;sprefix=come+and+see%252Cmovies-tv%252C956&amp;sr=1-1&_encoding=UTF8&tag=sianglu-20&linkCode=ur2&linkId=9f5764169afc8bcf7acc5925d664fb41&camp=1789&creative=9325",
    "justwatch": "https://justwatch.com/us/movie/come-and-see?utm_source=share&utm_medium=web",
    "beige_index": 1,
    "beige_angle": 0.0,
    "cast": [
      [
        1,
        "Vladas Bagdonas"
      ],
      [
        1,
        "Aleksandr Berda"
      ],
      [
        1,
        "Vasiliy Domrachyov"
      ],
      [
        1,
        "Nina Evdokimova"
      ],
      [
        1,
        "Igor Gnevashev"
      ],
      [
        1,
        "Adolf Hitler"
      ],
      [
        1,
        "Valery Kravchenko"
      ],
      [
        1,
        "Aleksey Kravchenko"
      ],
      [
        1,
        "Evgeniy Kryzhanovskiy"
      ],
      [
        1,
        "Liubomiras Laucevicius"
      ],
      [
        1,
        "N. Lisichenok"
      ],
      [
        1,
        "Viktors Lorencs"
      ],
      [
        1,
        "J\u00fcri Lumiste"
      ],
      [
        1,
        "Viktor Manaev"
      ],
      [
        1,
        "Gennadiy Matytsky"
      ],
      [
        1,
        "Takhir Matyullin"
      ],
      [
        1,
        "Pyotr Merkurev"
      ],
      [
        1,
        "Olga Mironova"
      ],
      [
        1,
        "Valentin Mishatkin"
      ],
      [
        1,
        "Yevgeniya Polyakova"
      ],
      [
        1,
        "Kazimir Rabetsky"
      ],
      [
        1,
        "Aleksandra Ravenskikh"
      ],
      [
        1,
        "Oleg Shapko"
      ],
      [
        1,
        "Tatyana Shestakova"
      ],
      [
        1,
        "Anatoly Slivnikov"
      ],
      [
        1,
        "Aleksandr Solopov"
      ],
      [
        1,
        "Georgiy Strokov"
      ],
      [
        1,
        "Evgeniy Tilicheev"
      ],
      [
        1,
        "V. Vasilyev"
      ],
      [
        1,
        "G. Velts"
      ],
      [
        1,
        "G. Yelkin"
      ]
    ],
    "directors": "Elem Klimov",
    "budget_string": "N/A",
    "gross_string": "20.9m USD",
    "metascore_string": "N/A"
  },
  {
    "imdb_id": "tt0088763",
    "plot": "Marty McFly, a 17-year-old high school student, is accidentally sent thirty years into the past in a time-traveling DeLorean invented by his close friend, the eccentric scientist Doc Brown.",
    "certification": "PG",
    "other_wins_count": 21,
    "other_noms_count": 25,
    "amazon": "https://www.amazon.com/Future-Trilogy-Blu-ray-Robert-Zemeckis/dp/B00EF4490Y/ref=sr_1_12?crid=510O256Y4S5H&amp;keywords=back+to+the+future+blu-ray&amp;qid=1654829580&amp;sprefix=back+to+the+future+blu-ra%252Caps%252C385&amp;sr=8-12&_encoding=UTF8&tag=sianglu-20&linkCode=ur2&linkId=41bc49a45f4e02391d094342ead7f61b&camp=1789&creative=9325",
    "justwatch": "https://justwatch.com/us/movie/back-to-the-future-part-i?utm_source=share&utm_medium=web",
    "beige_index": 2,
    "beige_angle": 42.5457,
    "cast": [
      [
        5,
        "Granville 'Danny' Young"
      ],
      [
        5,
        "Donald Fullilove"
      ],
      [
        5,
        "David Harold Brown"
      ],
      [
        5,
        "Lloyd L. Tolbert"
      ],
      [
        5,
        "Tommy Thomas"
      ],
      [
        5,
        "Harry Waters Jr."
      ],
      [
        3,
        "Richard L. Duran"
      ],
      [
        3,
        "Jeff O'Haco"
      ],
      [
        1,
        "George 'Buck' Flower"
      ],
      [
        1,
        "Jamie Abbott"
      ],
      [
        1,
        "Norman Alden"
      ],
      [
        1,
        "Ivy Bethune"
      ],
      [
        1,
        "Maia Brewton"
      ],
      [
        1,
        "Katherine Britton"
      ],
      [
        1,
        "Lee Brownfield"
      ],
      [
        1,
        "J.J. Cohen"
      ],
      [
        1,
        "Robert DeLapp"
      ],
      [
        1,
        "George DiCenzo"
      ],
      [
        1,
        "Lisa Freeman"
      ],
      [
        1,
        "Courtney Gains"
      ],
      [
        1,
        "Crispin Glover"
      ],
      [
        1,
        "Johnny Green"
      ],
      [
        1,
        "Paul Hanson"
      ],
      [
        1,
        "Will Hare"
      ],
      [
        1,
        "Jason Hervey"
      ],
      [
        1,
        "Michael J. Fox"
      ],
      [
        1,
        "Wendie Jo Sperber"
      ],
      [
        1,
        "Cristen Kauffman"
      ],
      [
        1,
        "Robert Krantz"
      ],
      [
        1,
        "Frances Lee McCain"
      ],
      [
        1,
        "Christopher Lloyd"
      ],
      [
        1,
        "Jason Marin"
      ],
      [
        1,
        "Marc McClure"
      ],
      [
        1,
        "Read Morgan"
      ],
      [
        1,
        "Sachi Parker"
      ],
      [
        1,
        "Elsa Raven"
      ],
      [
        1,
        "Gary Riley"
      ],
      [
        1,
        "Casey Siemaszko"
      ],
      [
        1,
        "Eric Stoltz"
      ],
      [
        1,
        "Lea Thompson"
      ],
      [
        1,
        "James Tolkan"
      ],
      [
        1,
        "Claudia Wells"
      ],
      [
        1,
        "Tom Wilson"
      ],
      [
        1,
        "Billy Zane"
      ]
    ],
    "directors": "Robert Zemeckis",
    "budget_string": "19m USD",
    "gross_string": "383.3m USD",
    "metascore_string": "87%"
  },
  {
    "imdb_id": "tt0089881",
    "plot": "In Medieval Japan, an elderly warlord retires, handing over his empire to his three sons. However, he vastly underestimates how the new-found power will corrupt them and cause them to turn on each other...and him.",
    "certification": "R",
    "other_wins_count": 29,
    "other_noms_count": 23,
    "amazon": "https://www.amazon.com/StudioCanal-Collection-Blu-ray-Tatsuya-Nakadai/dp/B002ZG4Q5W/ref=sr_1_1?crid=3LOLCYWVKPNQ2&amp;keywords=ran+blu+ray&amp;qid=1655174636&amp;sprefix=ran+blu+ray%252Caps%252C287&amp;sr=8-1&_encoding=UTF8&tag=sianglu-20&linkCode=ur2&linkId=c9e90333d271a29e315bffc75ac9e8d7&camp=1789&creative=9325",
    "justwatch": "https://justwatch.com/us/movie/ran?utm_source=share&utm_medium=web",
    "beige_index": 2,
    "beige_angle": 45.0,
    "cast": [
      [
        2,
        "P\u00eet\u00e2"
      ],
      [
        2,
        "Masuo Amada"
      ],
      [
        2,
        "Mieko Harada"
      ],
      [
        2,
        "Y\u00fbichi Hibi"
      ],
      [
        2,
        "Hisashi Igawa"
      ],
      [
        2,
        "Toshiya Ito"
      ],
      [
        2,
        "Seiz\u00f4 Kat\u00f4"
      ],
      [
        2,
        "Kazuo Kat\u00f4"
      ],
      [
        2,
        "Takeshi Kat\u00f4"
      ],
      [
        2,
        "Hanbei Kawai"
      ],
      [
        2,
        "Sakae Kimura"
      ],
      [
        2,
        "Kenji Kodama"
      ],
      [
        2,
        "Norio Matsui"
      ],
      [
        2,
        "Yoshiko Miyazaki"
      ],
      [
        2,
        "Ryo Nagasawa"
      ],
      [
        2,
        "Tatsuya Nakadai"
      ],
      [
        2,
        "Jinpachi Nezu"
      ],
      [
        2,
        "Mansai Nomura"
      ],
      [
        2,
        "Ry\u00fbjir\u00f4 Oki"
      ],
      [
        2,
        "Daisuke Ry\u00fb"
      ],
      [
        2,
        "Masaru Sakurai"
      ],
      [
        2,
        "Masaaki Sasaki"
      ],
      [
        2,
        "Akihiko Sugizaki"
      ],
      [
        2,
        "Jun Tazaki"
      ],
      [
        2,
        "Akira Terao"
      ],
      [
        2,
        "Hitoshi Ueki"
      ],
      [
        2,
        "Takashi Watanabe"
      ],
      [
        2,
        "Yoshimitsu Yamaguchi"
      ],
      [
        2,
        "Tetsuo Yamashita"
      ],
      [
        2,
        "Masayuki Yui"
      ],
      [
        2,
        "Takao Zushi"
      ],
      [
        2,
        "Yoshitaka Zushi"
      ]
    ],
    "directors": "Akira Kurosawa",
    "budget_string": "11.5m USD",
    "gross_string": "4.2m USD",
    "metascore_string": "96%"
  },
  {
    "imdb_id": "tt0092005",
    "plot": "After the death of one of his friends, a writer recounts a childhood journey with his friends to find the body of a missing boy.",
    "certification": "R",
    "other_wins_count": 5,
    "other_noms_count": 10,
    "amazon": "https://www.amazon.com/Stand-Blu-ray-Wheaton-River-Phoenix/dp/B00A2JNFH4/ref=sr_1_1?crid=38VDZ47DDUM8N&amp;keywords=stand+by+me+blu+ray&amp;qid=1655184681&amp;s=movies-tv&amp;sprefix=stand+by+me+blu+ray%252Cmovies-tv%252C276&amp;sr=1-1&_encoding=UTF8&tag=sianglu-20&linkCode=ur2&linkId=492b0020dda5a90a405d95f87d9d8ced&camp=1789&creative=9325",
    "justwatch": "https://justwatch.com/us/movie/stand-by-me?utm_source=share&utm_medium=web",
    "beige_index": 1,
    "beige_angle": 0.0,
    "cast": [
      [
        1,
        "O.B. Babbs"
      ],
      [
        1,
        "Scott Beach"
      ],
      [
        1,
        "Marshall Bell"
      ],
      [
        1,
        "Geanette Bobst"
      ],
      [
        1,
        "William Bronder"
      ],
      [
        1,
        "Art Burke"
      ],
      [
        1,
        "John Cusack"
      ],
      [
        1,
        "Richard Dreyfuss"
      ],
      [
        1,
        "Dick Durock"
      ],
      [
        1,
        "Corey Feldman"
      ],
      [
        1,
        "Bradley Gregg"
      ],
      [
        1,
        "Kenneth Hodges"
      ],
      [
        1,
        "John Hodges"
      ],
      [
        1,
        "Bruce Kirby"
      ],
      [
        1,
        "Frances Lee McCain"
      ],
      [
        1,
        "Andy Lindberg"
      ],
      [
        1,
        "Jason Naylor"
      ],
      [
        1,
        "Jerry O'Connell"
      ],
      [
        1,
        "Jason Oliver Lipsett"
      ],
      [
        1,
        "Charlie Owens"
      ],
      [
        1,
        "River Phoenix"
      ],
      [
        1,
        "Chance Quinn"
      ],
      [
        1,
        "Gary Riley"
      ],
      [
        1,
        "Korey Scott Pollard"
      ],
      [
        1,
        "Casey Siemaszko"
      ],
      [
        1,
        "Kiefer Sutherland"
      ],
      [
        1,
        "Madeleine Swift"
      ],
      [
        1,
        "Susan Thorpe"
      ],
      [
        1,
        "Kent W. Luttrell"
      ],
      [
        1,
        "Wil Wheaton"
      ],
      [
        1,
        "Matt Williams"
      ]
    ],
    "directors": "Rob Reiner",
    "budget_string": "8m USD",
    "gross_string": "52.3m USD",
    "metascore_string": "75%"
  },
  {
    "imdb_id": "tt0090605",
    "plot": "Fifty-seven years after surviving an apocalyptic attack aboard her space vessel by merciless space creatures, Officer Ripley awakens from hyper-sleep and tries to warn anyone who will listen about the predators.",
    "certification": "R",
    "other_wins_count": 18,
    "other_noms_count": 23,
    "amazon": "https://www.amazon.com/Aliens-Anniversary-Blu-ray-Michael-Biehn/dp/B01I0K018W/ref=sr_1_14?crid=11LJI964IKZQL&amp;keywords=aliens&amp;qid=1654842427&amp;s=movies-tv&amp;sprefix=aliens%252Cmovies-tv%252C703&amp;sr=1-14&_encoding=UTF8&tag=sianglu-20&linkCode=ur2&linkId=9fce1f02c1d199da6d0626874b13a1c3&camp=1789&creative=9325",
    "justwatch": "https://justwatch.com/us/movie/aliens?utm_source=share&utm_medium=web",
    "beige_index": 2,
    "beige_angle": 36.6921,
    "cast": [
      [
        5,
        "Al Matthews"
      ],
      [
        5,
        "Alibe Parsons"
      ],
      [
        5,
        "Ricco Ross"
      ],
      [
        1,
        "William Armstrong"
      ],
      [
        1,
        "Jay Benedict"
      ],
      [
        1,
        "Michael Biehn"
      ],
      [
        1,
        "Barbara Coles"
      ],
      [
        1,
        "Valerie Colgan"
      ],
      [
        1,
        "Cynthia Dale Scott"
      ],
      [
        1,
        "Blain Fairman"
      ],
      [
        1,
        "Jenette Goldstein"
      ],
      [
        1,
        "Carrie Henn"
      ],
      [
        1,
        "Lance Henriksen"
      ],
      [
        1,
        "Colette Hiller"
      ],
      [
        1,
        "William Hope"
      ],
      [
        1,
        "Daniel Kash"
      ],
      [
        1,
        "John Lees"
      ],
      [
        1,
        "Paul Maxwell"
      ],
      [
        1,
        "Bill Paxton"
      ],
      [
        1,
        "Alan Polonsky"
      ],
      [
        1,
        "Paul Reiser"
      ],
      [
        1,
        "Mark Rolston"
      ],
      [
        1,
        "Trevor Steedman"
      ],
      [
        1,
        "Tip Tipping"
      ],
      [
        1,
        "Carl Toop"
      ],
      [
        1,
        "Sigourney Weaver"
      ]
    ],
    "directors": "James Cameron",
    "budget_string": "18.5m USD",
    "gross_string": "131.1m USD",
    "metascore_string": "84%"
  },
  {
    "imdb_id": "tt0091763",
    "plot": "Chris Taylor, a neophyte recruit in Vietnam, finds himself caught in a battle of wills between two sergeants, one good and the other evil. A shrewd examination of the brutality of war and the duality of man in conflict.",
    "certification": "R",
    "other_wins_count": 20,
    "other_noms_count": 15,
    "amazon": "https://www.amazon.com/Platoon-Blu-ray-Tom-Berenger/dp/B005FUTC1I/ref=sr_1_2?crid=3B1BQEAFRVH6Y&amp;keywords=platoon+blu+ray&amp;qid=1655184529&amp;s=movies-tv&amp;sprefix=platoon+blu+ra%252Cmovies-tv%252C271&amp;sr=1-2&_encoding=UTF8&tag=sianglu-20&linkCode=ur2&linkId=d36ffa705be1deb81ce907b5c899c357&camp=1789&creative=9325",
    "justwatch": "https://justwatch.com/us/movie/platoon?utm_source=share&utm_medium=web",
    "beige_index": 2,
    "beige_angle": 58.8645,
    "cast": [
      [
        5,
        "John C. McGinley"
      ],
      [
        5,
        "Keith David"
      ],
      [
        5,
        "Corkey Ford"
      ],
      [
        5,
        "Corey Glover"
      ],
      [
        5,
        "Reggie Johnson"
      ],
      [
        5,
        "Tony Todd"
      ],
      [
        5,
        "Forest Whitaker"
      ],
      [
        3,
        "Chris Castillejo"
      ],
      [
        3,
        "Bernardo Manalili"
      ],
      [
        3,
        "Clarisa Ortacio"
      ],
      [
        3,
        "Than Rogers"
      ],
      [
        3,
        "Paul Sanchez"
      ],
      [
        3,
        "Romy Sevilla"
      ],
      [
        3,
        "Steve Barredo"
      ],
      [
        2,
        "Li Mai Thao"
      ],
      [
        2,
        "Li Thi Van"
      ],
      [
        1,
        "J. Adam Glover"
      ],
      [
        1,
        "Andrew B. Clark"
      ],
      [
        1,
        "Ron Barracks"
      ],
      [
        1,
        "Tom Berenger"
      ],
      [
        1,
        "Willem Dafoe"
      ],
      [
        1,
        "Johnny Depp"
      ],
      [
        1,
        "Kevin Dillon"
      ],
      [
        1,
        "Dale Dye"
      ],
      [
        1,
        "Richard Edson"
      ],
      [
        1,
        "Kevin Eshelman"
      ],
      [
        1,
        "Peter Hicks"
      ],
      [
        1,
        "Ivan Kane"
      ],
      [
        1,
        "Warren McLean"
      ],
      [
        1,
        "Mark Moses"
      ],
      [
        1,
        "David Neidorf"
      ],
      [
        1,
        "Nick Nicholson"
      ],
      [
        1,
        "Bob Orwig"
      ],
      [
        1,
        "Chris Pedersen"
      ],
      [
        1,
        "Francesco Quinn"
      ],
      [
        1,
        "Charlie Sheen"
      ],
      [
        1,
        "James Terry McIlvain"
      ],
      [
        1,
        "Mathew Westfall"
      ]
    ],
    "directors": "Oliver Stone",
    "budget_string": "6m USD",
    "gross_string": "138.5m USD",
    "metascore_string": "92%"
  },
  {
    "imdb_id": "tt0093058",
    "plot": "A pragmatic U.S. Marine observes the dehumanizing effects the Vietnam War has on his fellow recruits from their brutal boot camp training to the bloody street fighting in Hue.",
    "certification": "R",
    "other_wins_count": 8,
    "other_noms_count": 14,
    "amazon": "https://www.amazon.com/Full-Metal-Jacket-Blu-ray-Blu-Ray/dp/B000VXWXTW/ref=tmm_blu_swatch_0?_encoding=UTF8&amp;qid=1654930943&amp;sr=1-2&_encoding=UTF8&tag=sianglu-20&linkCode=ur2&linkId=889f0767474f28a5ae5c0d4c2489c41a&camp=1789&creative=9325",
    "justwatch": "https://justwatch.com/us/movie/la-chaqueta-metalica?utm_source=share&utm_medium=web",
    "beige_index": 2,
    "beige_angle": 68.4729,
    "cast": [
      [
        5,
        "Michael Anthony Williams"
      ],
      [
        5,
        "Peter Edmund"
      ],
      [
        5,
        "Dorian Harewood"
      ],
      [
        5,
        "Tony Hayes"
      ],
      [
        5,
        "Keith Hodiak"
      ],
      [
        5,
        "Gary Landon Mills"
      ],
      [
        5,
        "Herbert Norville"
      ],
      [
        5,
        "Kirk Taylor"
      ],
      [
        3,
        "Costas Dino Chimona"
      ],
      [
        3,
        "Leanne Hong"
      ],
      [
        3,
        "Duc Hu Ta"
      ],
      [
        3,
        "Nguyen Hue Phong"
      ],
      [
        3,
        "Tan Hung Francione"
      ],
      [
        3,
        "Ngoc Le"
      ],
      [
        3,
        "Sal Lopez"
      ],
      [
        3,
        "Gil Kopel"
      ],
      [
        1,
        "Papillon Soo"
      ],
      [
        1,
        "Del Anderson"
      ],
      [
        1,
        "Adam Baldwin"
      ],
      [
        1,
        "Bruce Boa"
      ],
      [
        1,
        "Tim Colceri"
      ],
      [
        1,
        "Marcus D'Amico"
      ],
      [
        1,
        "Vincent D'Onofrio"
      ],
      [
        1,
        "Tony Howard"
      ],
      [
        1,
        "Arliss Howard"
      ],
      [
        1,
        "Steve Hudson"
      ],
      [
        1,
        "Kieron Jecchinis"
      ],
      [
        1,
        "R. Lee Ermey"
      ],
      [
        1,
        "Kevyn Major Howard"
      ],
      [
        1,
        "Peter Merrill"
      ],
      [
        1,
        "Matthew Modine"
      ],
      [
        1,
        "Robert Nichols"
      ],
      [
        1,
        "Ed O'Ross"
      ],
      [
        1,
        "David Parry"
      ],
      [
        1,
        "Jon Stafford"
      ],
      [
        1,
        "John Terry"
      ],
      [
        1,
        "Ian Tyler"
      ]
    ],
    "directors": "Stanley Kubrick",
    "budget_string": "30m USD",
    "gross_string": "46.4m USD",
    "metascore_string": "76%"
  },
  {
    "imdb_id": "tt0095765",
    "plot": "A filmmaker recalls his childhood when falling in love with the pictures at the cinema of his home village and forms a deep friendship with the cinema's projectionist.",
    "certification": "R",
    "other_wins_count": 24,
    "other_noms_count": 32,
    "amazon": "https://www.amazon.com/Cinema-Paradiso-Special-Blu-ray-Philippe/dp/B01MZ08HJK/ref=sr_1_4?crid=JDTIWXUY38LW&amp;keywords=cinema+paradiso&amp;qid=1654833018&amp;s=movies-tv&amp;sprefix=cinema+paradis%252Cmovies-tv-intl-ship%252C664&amp;sr=1-4&_encoding=UTF8&tag=sianglu-20&linkCode=ur2&linkId=9dd04618e71d1a96cccc8dde1143165e&camp=1789&creative=9325",
    "justwatch": "https://justwatch.com/us/movie/cinema-paradiso?utm_source=share&utm_medium=web",
    "beige_index": 1,
    "beige_angle": 0.0,
    "cast": [
      [
        1,
        "Antonella Attili"
      ],
      [
        1,
        "Concetta Borpagano"
      ],
      [
        1,
        "Enzo Cannavale"
      ],
      [
        1,
        "Salvatore Cascio"
      ],
      [
        1,
        "Franco Catalano"
      ],
      [
        1,
        "Tano Cimarosa"
      ],
      [
        1,
        "Isa Danieli"
      ],
      [
        1,
        "Nicola Di Pinto"
      ],
      [
        1,
        "Turi Giuffrida"
      ],
      [
        1,
        "Leo Gullotta"
      ],
      [
        1,
        "Turi Killer"
      ],
      [
        1,
        "Nellina Lagan\u00e0"
      ],
      [
        1,
        "Roberta Lena"
      ],
      [
        1,
        "Marco Leonardi"
      ],
      [
        1,
        "Angela Leontini"
      ],
      [
        1,
        "Giorgio Libassi"
      ],
      [
        1,
        "Mariella Lo Giudice"
      ],
      [
        1,
        "Pupella Maggio"
      ],
      [
        1,
        "Mimmo Mignemi"
      ],
      [
        1,
        "Margherita Mignemi"
      ],
      [
        1,
        "Agnese Nano"
      ],
      [
        1,
        "Philippe Noiret"
      ],
      [
        1,
        "Beatrice Palme"
      ],
      [
        1,
        "Ignazio Pappalardo"
      ],
      [
        1,
        "Giuseppe Pellegrino"
      ],
      [
        1,
        "Jacques Perrin"
      ],
      [
        1,
        "Nino Terzo"
      ],
      [
        1,
        "Angelo Tosto"
      ],
      [
        1,
        "Leopoldo Trieste"
      ]
    ],
    "directors": "Giuseppe Tornatore",
    "budget_string": "5m USD",
    "gross_string": "13m USD",
    "metascore_string": "80%"
  },
  {
    "imdb_id": "tt0095327",
    "plot": "A young boy and his little sister struggle to survive in Japan during World War II.",
    "certification": "Not Rated",
    "other_wins_count": 3,
    "other_noms_count": 0,
    "amazon": "https://www.amazon.com/Grave-Fireflies-Blu-ray-Ayano-Shiraishi/dp/B008XEZXRA/ref=sr_1_1?crid=31ZYQMFONZMUX&amp;keywords=grave+of+the+fireflies&amp;qid=1654832580&amp;s=movies-tv&amp;sprefix=grave+of+the+fireflies%252Cmovies-tv-intl-ship%252C643&amp;sr=1-1&_encoding=UTF8&tag=sianglu-20&linkCode=ur2&linkId=5a8a6e0440a0e04481d5ec6538547233&camp=1789&creative=9325",
    "justwatch": "https://justwatch.com/us/movie/hotaru-no-haka?utm_source=share&utm_medium=web",
    "beige_index": 2,
    "beige_angle": 45.0,
    "cast": [
      [
        2,
        "Kiyomi Ajisaka"
      ],
      [
        2,
        "Toshiko Ama"
      ],
      [
        2,
        "Tsukasa Ban"
      ],
      [
        2,
        "Michio Denp\u00f4"
      ],
      [
        2,
        "Naoki Fujita"
      ],
      [
        2,
        "Yoshinaga Fusamoto"
      ],
      [
        2,
        "Teruhisa Harita"
      ],
      [
        2,
        "K\u00f4z\u00f4 Hashida"
      ],
      [
        2,
        "Toyokazu Hiramatsu"
      ],
      [
        2,
        "Masafumi J\u00f4no"
      ],
      [
        2,
        "Haruo Kaji"
      ],
      [
        2,
        "Masahiro Kanetake"
      ],
      [
        2,
        "Mayumi Kawaguchi"
      ],
      [
        2,
        "Makiko Kinoshita"
      ],
      [
        2,
        "Makoto Kobayashi"
      ],
      [
        2,
        "Ikuo Kokubu"
      ],
      [
        2,
        "Tamotsu Kuni"
      ],
      [
        2,
        "Y\u00fbko Kurokawa"
      ],
      [
        2,
        "Hajime Maki"
      ],
      [
        2,
        "Haruko Matsuda"
      ],
      [
        2,
        "Atsushi Matsumoto"
      ],
      [
        2,
        "Yoshio Matsuoka"
      ],
      [
        2,
        "Mariko Miyamoto"
      ],
      [
        2,
        "Ky\u00f4ko Moriwaki"
      ],
      [
        2,
        "Masato Moriya"
      ],
      [
        2,
        "Tadashi Nakamura"
      ],
      [
        2,
        "Tetsur\u00f4 Nakayama"
      ],
      [
        2,
        "Kazumi Nozaki"
      ],
      [
        2,
        "Atsuo Omote"
      ],
      [
        2,
        "Masayo Sakai"
      ],
      [
        2,
        "Ry\u00fbji Sanada"
      ],
      [
        2,
        "Ken'ichi Sawada"
      ],
      [
        2,
        "Mika Sekita"
      ],
      [
        2,
        "Takashi Shimatani"
      ],
      [
        2,
        "Yoshiko Shinohara"
      ],
      [
        2,
        "Ayano Shiraishi"
      ],
      [
        2,
        "Kazuhiko Takeoka"
      ],
      [
        2,
        "Shir\u00f4 Tamaki"
      ],
      [
        2,
        "Hiroshi Tanaka"
      ],
      [
        2,
        "K\u00f4shir\u00f4 Tanimoto"
      ],
      [
        2,
        "Tsutomu Tatsumi"
      ],
      [
        2,
        "Keiko Ueda"
      ],
      [
        2,
        "Makio Ueno"
      ],
      [
        2,
        "Akemi Yamaguchi"
      ],
      [
        2,
        "Kiyoshi Yanagawa"
      ],
      [
        2,
        "Y\u00fbsuke Yokoyama"
      ],
      [
        2,
        "Senkoku Yukuhara"
      ]
    ],
    "directors": "Isao Takahata",
    "budget_string": "3.7m USD",
    "gross_string": "0.5m USD",
    "metascore_string": "94%"
  },
  {
    "imdb_id": "tt0095016",
    "plot": "An NYPD officer tries to save his wife and several others taken hostage by German terrorists during a Christmas party at the Nakatomi Plaza in Los Angeles.",
    "certification": "R",
    "other_wins_count": 8,
    "other_noms_count": 2,
    "amazon": "https://www.amazon.com/Hard-30th-Anniversary-Blu-ray-Digital/dp/B07BF46VHM/ref=sr_1_1?crid=36IL96OFK8BHQ&amp;keywords=die+hard+blu+ray&amp;qid=1655008246&amp;sprefix=die+hard+blu+ray%252Caps%252C325&amp;sr=8-1&_encoding=UTF8&tag=sianglu-20&linkCode=ur2&linkId=a0d62cfd50066062bec7f8b9b86a61d8&camp=1789&creative=9325",
    "justwatch": "https://justwatch.com/us/movie/die-hard-1988?utm_source=share&utm_medium=web",
    "beige_index": 1,
    "beige_angle": 26.2368,
    "cast": [
      [
        5,
        "Clarence Gilyard Jr."
      ],
      [
        5,
        "Grand L. Bush"
      ],
      [
        5,
        "Reginald VelJohnson"
      ],
      [
        5,
        "De'voreaux White"
      ],
      [
        3,
        "Betty Carvalho"
      ],
      [
        2,
        "Al Leong"
      ],
      [
        2,
        "James Shigeta"
      ],
      [
        1,
        "Selma Archerd"
      ],
      [
        1,
        "William Atherton"
      ],
      [
        1,
        "Cheryl Baker"
      ],
      [
        1,
        "Bonnie Bedelia"
      ],
      [
        1,
        "Hart Bochner"
      ],
      [
        1,
        "G\u00e9rard Bonn"
      ],
      [
        1,
        "Rebecca Broussard"
      ],
      [
        1,
        "Hans Buhringer"
      ],
      [
        1,
        "Lorenzo Caccialanza"
      ],
      [
        1,
        "George Christy"
      ],
      [
        1,
        "Rick Cicetti"
      ],
      [
        1,
        "Robert Davi"
      ],
      [
        1,
        "Bruno Doyon"
      ],
      [
        1,
        "Rick Ducommun"
      ],
      [
        1,
        "Jon E. Greene"
      ],
      [
        1,
        "Mary Ellen Trainor"
      ],
      [
        1,
        "Kate Finlayson"
      ],
      [
        1,
        "Taylor Fry"
      ],
      [
        1,
        "Paul Gleason"
      ],
      [
        1,
        "Alexander Godunov"
      ],
      [
        1,
        "Mark Goldstein"
      ],
      [
        1,
        "Stella Hall"
      ],
      [
        1,
        "Bob Harks"
      ],
      [
        1,
        "Dennis Hayden"
      ],
      [
        1,
        "Harri James"
      ],
      [
        1,
        "Bob Jennings"
      ],
      [
        1,
        "David Katz"
      ],
      [
        1,
        "Noah Land"
      ],
      [
        1,
        "Matt Landers"
      ],
      [
        1,
        "Michele Laybourn"
      ],
      [
        1,
        "Fred Lerner"
      ],
      [
        1,
        "Robert Lesser"
      ],
      [
        1,
        "Terri Lynn Doss"
      ],
      [
        1,
        "Kym Malin"
      ],
      [
        1,
        "Bill Marcus"
      ],
      [
        1,
        "Bill Margolin"
      ],
      [
        1,
        "Bruce P. Schultz"
      ],
      [
        1,
        "Richard Parker"
      ],
      [
        1,
        "Anthony Peck"
      ],
      [
        1,
        "Joey Plewa"
      ],
      [
        1,
        "Shelley Pogoda"
      ],
      [
        1,
        "P. Randall Bowers"
      ],
      [
        1,
        "Tracy Reiner"
      ],
      [
        1,
        "Alan Rickman"
      ],
      [
        1,
        "Gary Roberts"
      ],
      [
        1,
        "Dustyn Taylor"
      ],
      [
        1,
        "David Ursin"
      ],
      [
        1,
        "Wilhelm von Homburg"
      ],
      [
        1,
        "Kip Waldo"
      ],
      [
        1,
        "Bruce Willis"
      ],
      [
        1,
        "Andreas Wisniewski"
      ],
      [
        1,
        "Carmine Zozzora"
      ]
    ],
    "directors": "John McTiernan",
    "budget_string": "28m USD",
    "gross_string": "141.6m USD",
    "metascore_string": "72%"
  },
  {
    "imdb_id": "tt0096283",
    "plot": "When two girls move to the country to be near their ailing mother, they have adventures with the wondrous forest spirits who live nearby.",
    "certification": "G",
    "other_wins_count": 5,
    "other_noms_count": 2,
    "amazon": "https://www.amazon.com/Neighbor-Totoro-Bluray-Combo-Blu-ray/dp/B074128LDH/ref=sr_1_1?crid=1DMNUF4RBZG7R&amp;keywords=my+neighbor+totoro+blu+ray&amp;qid=1655181921&amp;sprefix=my+neighbor+totoro+blu+ray%252Caps%252C302&amp;sr=8-1&_encoding=UTF8&tag=sianglu-20&linkCode=ur2&linkId=ad02da730310386301213850841d003d&camp=1789&creative=9325",
    "justwatch": "https://justwatch.com/us/movie/my-neighbor-totoro?utm_source=share&utm_medium=web",
    "beige_index": 2,
    "beige_angle": 42.6519,
    "cast": [
      [
        2,
        "Tarako"
      ],
      [
        2,
        "Toshiyuki Amagasa"
      ],
      [
        2,
        "Shigeru Chiba"
      ],
      [
        2,
        "Noriko Hidaka"
      ],
      [
        2,
        "Akiko Hiramatsu"
      ],
      [
        2,
        "Masashi Hirose"
      ],
      [
        2,
        "Mitsuko Ishida"
      ],
      [
        2,
        "Shigesato Itoi"
      ],
      [
        2,
        "Tanie Kitabayashi"
      ],
      [
        2,
        "Chie K\u00f4jiro"
      ],
      [
        2,
        "Y\u00fbko Maruyama"
      ],
      [
        2,
        "Y\u00fbko Mizutani"
      ],
      [
        2,
        "Daiki Nakamura"
      ],
      [
        2,
        "Tomohiro Nishimura"
      ],
      [
        2,
        "Ikue \u00d4tani"
      ],
      [
        2,
        "Chika Sakamoto"
      ],
      [
        2,
        "Sumi Shimamoto"
      ],
      [
        2,
        "Reiko Suzuki"
      ],
      [
        2,
        "Hitoshi Takagi"
      ],
      [
        2,
        "Naoki Tatsuta"
      ],
      [
        2,
        "Machiko Washio"
      ]
    ],
    "directors": "Hayao Miyazaki",
    "budget_string": "3.7m USD",
    "gross_string": "30.6m USD",
    "metascore_string": "86%"
  },
  {
    "imdb_id": "tt0097165",
    "plot": "Maverick teacher John Keating uses poetry to embolden his boarding school students to new heights of self-expression.",
    "certification": "PG",
    "other_wins_count": 19,
    "other_noms_count": 19,
    "amazon": "https://www.amazon.com/Poets-Society-Blu-ray-Robin-Williams/dp/B005TBQS3I/ref=sr_1_1?crid=33IAZI4EPEOUB&amp;keywords=dead+poets+society+blu+ray&amp;qid=1655184482&amp;s=movies-tv&amp;sprefix=dead+poets+society+blu+ray%252Cmovies-tv%252C285&amp;sr=1-1&_encoding=UTF8&tag=sianglu-20&linkCode=ur2&linkId=21527e552360d43fac65deb673f1f2d5&camp=1789&creative=9325",
    "justwatch": "https://justwatch.com/us/movie/dead-poets-society?utm_source=share&utm_medium=web",
    "beige_index": 1,
    "beige_angle": 0.0,
    "cast": [
      [
        1,
        "Joe Aufiery"
      ],
      [
        1,
        "Carla Belver"
      ],
      [
        1,
        "Pamela Burrell"
      ],
      [
        1,
        "Matt Carey"
      ],
      [
        1,
        "Josh Charles"
      ],
      [
        1,
        "Kevin Cooney"
      ],
      [
        1,
        "John Cunningham"
      ],
      [
        1,
        "Christine D'Ercole"
      ],
      [
        1,
        "James Donnell Quinn"
      ],
      [
        1,
        "Lara Flynn Boyle"
      ],
      [
        1,
        "Robert Gleason"
      ],
      [
        1,
        "Gale Hansen"
      ],
      [
        1,
        "Ethan Hawke"
      ],
      [
        1,
        "Allison Hedges"
      ],
      [
        1,
        "Andrew Hill"
      ],
      [
        1,
        "Chris Hull"
      ],
      [
        1,
        "Colin Irving"
      ],
      [
        1,
        "James J. Christy"
      ],
      [
        1,
        "Robert J. Zigler III"
      ],
      [
        1,
        "Craig Johnson"
      ],
      [
        1,
        "Nicholas K. Gilhool"
      ],
      [
        1,
        "Kate Kearney-Patch"
      ],
      [
        1,
        "Dylan Kussman"
      ],
      [
        1,
        "Kurt Leitner"
      ],
      [
        1,
        "Norman Lloyd"
      ],
      [
        1,
        "Charles Lord"
      ],
      [
        1,
        "George Martin"
      ],
      [
        1,
        "John Martin Bradley"
      ],
      [
        1,
        "Steve Mathios"
      ],
      [
        1,
        "Simon Mein"
      ],
      [
        1,
        "Debra Mooney"
      ],
      [
        1,
        "Jane Moore"
      ],
      [
        1,
        "Alan Pottinger"
      ],
      [
        1,
        "Alexandra Powers"
      ],
      [
        1,
        "Leon Pownall"
      ],
      [
        1,
        "Bill Rowe"
      ],
      [
        1,
        "Allelon Ruggiero"
      ],
      [
        1,
        "Robert Sean Leonard"
      ],
      [
        1,
        "Kurtwood Smith"
      ],
      [
        1,
        "Keith Snyder"
      ],
      [
        1,
        "Catherine Soles"
      ],
      [
        1,
        "Sam Stegeman"
      ],
      [
        1,
        "Jonas Stiklorius"
      ],
      [
        1,
        "Richard Stites"
      ],
      [
        1,
        "Hoover Sutton"
      ],
      [
        1,
        "Ashton W. Richards"
      ],
      [
        1,
        "Melora Walters"
      ],
      [
        1,
        "James Waterston"
      ],
      [
        1,
        "Welker White"
      ],
      [
        1,
        "Robin Williams"
      ],
      [
        1,
        "Jason Woody"
      ]
    ],
    "directors": "Peter Weir",
    "budget_string": "16.4m USD",
    "gross_string": "235.9m USD",
    "metascore_string": "79%"
  },
  {
    "imdb_id": "tt0097576",
    "plot": "In 1938, after his father Professor Henry Jones, Sr. goes missing while pursuing the Holy Grail, Professor Henry \"Indiana\" Jones, Jr. finds himself up against Adolf Hitler's Nazis again to stop them from obtaining its powers.",
    "certification": "PG-13",
    "other_wins_count": 7,
    "other_noms_count": 22,
    "amazon": "https://www.amazon.com/Indiana-Jones-Last-Crusade-Blu-ray/dp/B00FZIIPUO/ref=sr_1_2?crid=WHBBUR1S8ONO&amp;keywords=last+crusade+blu+ray&amp;qid=1655008352&amp;sprefix=last+crusade+blu+ray%252Caps%252C282&amp;sr=8-2&_encoding=UTF8&tag=sianglu-20&linkCode=ur2&linkId=de04ef1205f531226766ed293a6f856a&camp=1789&creative=9325",
    "justwatch": "https://justwatch.com/us/movie/indiana-jones-3?utm_source=share&utm_medium=web",
    "beige_index": 1,
    "beige_angle": 14.9994,
    "cast": [
      [
        5,
        "Stefan Kalipha"
      ],
      [
        4,
        "Kevork Malikyan"
      ],
      [
        3,
        "Tom Branch"
      ],
      [
        3,
        "Jeff O'Haco"
      ],
      [
        1,
        "Nina Armstrong"
      ],
      [
        1,
        "Isla Blair"
      ],
      [
        1,
        "Michael Byrne"
      ],
      [
        1,
        "Sean Connery"
      ],
      [
        1,
        "Graeme Crowther"
      ],
      [
        1,
        "Vince Deadrick Sr."
      ],
      [
        1,
        "Vernon Dobtcheff"
      ],
      [
        1,
        "Alison Doody"
      ],
      [
        1,
        "Julie Eccles"
      ],
      [
        1,
        "Robert Eddison"
      ],
      [
        1,
        "Denholm Elliott"
      ],
      [
        1,
        "Harrison Ford"
      ],
      [
        1,
        "Julian Glover"
      ],
      [
        1,
        "Martin Gordon"
      ],
      [
        1,
        "Bradley Gregg"
      ],
      [
        1,
        "Ted Grossman"
      ],
      [
        1,
        "Luke Hanson"
      ],
      [
        1,
        "J.J. Hardy"
      ],
      [
        1,
        "Jerry Harte"
      ],
      [
        1,
        "Tim Hiser"
      ],
      [
        1,
        "Paul Humpoletz"
      ],
      [
        1,
        "Alex Hyde-White"
      ],
      [
        1,
        "Billy J. Mitchell"
      ],
      [
        1,
        "Frederick Jaeger"
      ],
      [
        1,
        "Chris Jenkinson"
      ],
      [
        1,
        "Ronald Lacey"
      ],
      [
        1,
        "Eugene Lipinski"
      ],
      [
        1,
        "George Malpas"
      ],
      [
        1,
        "Paul Maxwell"
      ],
      [
        1,
        "Will Miles"
      ],
      [
        1,
        "Marc Miles"
      ],
      [
        1,
        "David Murray"
      ],
      [
        1,
        "Peter Pacey"
      ],
      [
        1,
        "River Phoenix"
      ],
      [
        1,
        "John Rhys-Davies"
      ],
      [
        1,
        "Pat Roach"
      ],
      [
        1,
        "Suzanne Roquette"
      ],
      [
        1,
        "Larry Sanders"
      ],
      [
        1,
        "Alexei Sayle"
      ],
      [
        1,
        "Nicola Scott"
      ],
      [
        1,
        "Michael Sheard"
      ],
      [
        1,
        "Louis Sheldon"
      ],
      [
        1,
        "Tip Tipping"
      ],
      [
        1,
        "Richard Young"
      ]
    ],
    "directors": "Steven Spielberg",
    "budget_string": "48m USD",
    "gross_string": "474.2m USD",
    "metascore_string": "65%"
  },
  {
    "imdb_id": "tt0099348",
    "plot": "Lieutenant John Dunbar, assigned to a remote western Civil War outpost, befriends wolves and Native Americans, making him an intolerable aberration in the military.",
    "certification": "PG-13",
    "other_wins_count": 44,
    "other_noms_count": 38,
    "amazon": "https://www.amazon.com/Dances-Wolves-Kevin-Costner/dp/B000IZXHO8/ref=sr_1_2?crid=THMAQP8F02WR&amp;keywords=dances+with+wolves+blu+ray&amp;qid=1656309591&amp;s=instant-video&amp;sprefix=dances+with+wolves+blu+ray%252Cinstant-video%252C332&amp;sr=1-2&_encoding=UTF8&tag=sianglu-20&linkCode=ur2&linkId=095c4e5d562c2b202838c80c316218d3&camp=1789&creative=9325",
    "justwatch": "https://justwatch.com/us/movie/dances-with-wolves?utm_source=share&utm_medium=web",
    "beige_index": 2,
    "beige_angle": 54.7659,
    "cast": [
      [
        3,
        "Floyd 'Red Crow' Westerman"
      ],
      [
        3,
        "Rodney A. Grant"
      ],
      [
        3,
        "Maretta Big Crow"
      ],
      [
        3,
        "Clayton Big Eagle"
      ],
      [
        3,
        "Tantoo Cardinal"
      ],
      [
        3,
        "Buffalo Child"
      ],
      [
        3,
        "Otakuye Conroy"
      ],
      [
        3,
        "Graham Greene"
      ],
      [
        3,
        "Jimmy Herman"
      ],
      [
        3,
        "Marvin Holy"
      ],
      [
        3,
        "David J. Fuller"
      ],
      [
        3,
        "Doris Leader Charge"
      ],
      [
        3,
        "Richard Leader Charge"
      ],
      [
        3,
        "Nathan Lee Chasing His Horse"
      ],
      [
        3,
        "Raymond Newholy"
      ],
      [
        3,
        "Sheldon Peters Wolfchild"
      ],
      [
        3,
        "Jason R. Lone Hill"
      ],
      [
        3,
        "Steve Reevis"
      ],
      [
        3,
        "Michael Spears"
      ],
      [
        3,
        "Wes Studi"
      ],
      [
        3,
        "John Tail"
      ],
      [
        3,
        "Redwing Ted Nez"
      ],
      [
        3,
        "Ryan White Bull"
      ],
      [
        3,
        "Percy White Plume"
      ],
      [
        1,
        "James A. Mitchell"
      ],
      [
        1,
        "Kirk Baltz"
      ],
      [
        1,
        "Steven Chambers"
      ],
      [
        1,
        "Maury Chaykin"
      ],
      [
        1,
        "Kevin Costner"
      ],
      [
        1,
        "R.L. Curtin"
      ],
      [
        1,
        "Elisa Daniel"
      ],
      [
        1,
        "Conor Duffy"
      ],
      [
        1,
        "Tom Everett"
      ],
      [
        1,
        "Robert Goldman"
      ],
      [
        1,
        "Wayne Grace"
      ],
      [
        1,
        "William H. Burton Jr."
      ],
      [
        1,
        "Carter Hanner"
      ],
      [
        1,
        "Kent Hays"
      ],
      [
        1,
        "Donald Hotton"
      ],
      [
        1,
        "Larry Joshua"
      ],
      [
        1,
        "Mary McDonnell"
      ],
      [
        1,
        "Frank P. Costanza"
      ],
      [
        1,
        "Robert Pastorelli"
      ],
      [
        1,
        "Tony Pierce"
      ],
      [
        1,
        "Charles Rocket"
      ],
      [
        1,
        "Nick Thompson"
      ],
      [
        1,
        "Bill W. Curry"
      ]
    ],
    "directors": "Kevin Costner",
    "budget_string": "N/A",
    "gross_string": "N/A",
    "metascore_string": "72%"
  },
  {
    "imdb_id": "tt0099685",
    "plot": "The story of Henry Hill and his life in the mob, covering his relationship with his wife Karen Hill and his mob partners Jimmy Conway and Tommy DeVito in the Italian-American crime syndicate.",
    "certification": "R",
    "other_wins_count": 43,
    "other_noms_count": 38,
    "amazon": "https://www.amazon.com/Goodfellas-Remastered-Robert-Niro/dp/B018KDBQDI/ref=sr_1_1?crid=2KEEGTB4GQWMJ&amp;keywords=goodfellas&amp;qid=1654827526&amp;sprefix=goodfellas+%252Caps%252C426&amp;sr=8-1&_encoding=UTF8&tag=sianglu-20&linkCode=ur2&linkId=2b662c7076749052083f5017b6a7eb6a&camp=1789&creative=9325",
    "justwatch": "https://justwatch.com/us/movie/goodfellas?utm_source=share&utm_medium=web",
    "beige_index": 1,
    "beige_angle": 7.0956,
    "cast": [
      [
        5,
        "Samuel L. Jackson"
      ],
      [
        5,
        "Berlinda Tolbert"
      ],
      [
        5,
        "Isiah Whitlock Jr."
      ],
      [
        1,
        "LoNardo"
      ],
      [
        1,
        "John 'Cha Cha' Ciarcia"
      ],
      [
        1,
        "Frank Adonis"
      ],
      [
        1,
        "Frank Albanese"
      ],
      [
        1,
        "Anthony Alessandro"
      ],
      [
        1,
        "Manny Alfaro"
      ],
      [
        1,
        "Bob Altman"
      ],
      [
        1,
        "Vito Antuofermo"
      ],
      [
        1,
        "Frank Aquilino"
      ],
      [
        1,
        "Vito Balsamo"
      ],
      [
        1,
        "Norman Barbera"
      ],
      [
        1,
        "Daniela Barbosa"
      ],
      [
        1,
        "Tobin Bell"
      ],
      [
        1,
        "Joanna Bennett"
      ],
      [
        1,
        "Mikey Black"
      ],
      [
        1,
        "Joel Blake"
      ],
      [
        1,
        "Joseph Bono"
      ],
      [
        1,
        "Lorraine Bracco"
      ],
      [
        1,
        "Spencer Bradley"
      ],
      [
        1,
        "Nicole Burdette"
      ],
      [
        1,
        "Erasmus C. Alfano"
      ],
      [
        1,
        "Michael Calandrino"
      ],
      [
        1,
        "Joel Calendrillo"
      ],
      [
        1,
        "Gene Canfield"
      ],
      [
        1,
        "Clem Caserta"
      ],
      [
        1,
        "Nancy Cassaro"
      ],
      [
        1,
        "Peter Cicale"
      ],
      [
        1,
        "Michael Citriniti"
      ],
      [
        1,
        "H. Clay Dear"
      ],
      [
        1,
        "Victor Colicchio"
      ],
      [
        1,
        "Tony Conforti"
      ],
      [
        1,
        "Mike Contessa"
      ],
      [
        1,
        "Kevin Corrigan"
      ],
      [
        1,
        "Janis Corsair"
      ],
      [
        1,
        "Edward D. Murphy"
      ],
      [
        1,
        "Lisa D'Apolito"
      ],
      [
        1,
        "Joseph D'Onofrio"
      ],
      [
        1,
        "Tony Darrow"
      ],
      [
        1,
        "Robert De Niro"
      ],
      [
        1,
        "Jamie De Roy"
      ],
      [
        1,
        "Ed Deacy"
      ],
      [
        1,
        "Dominique DeVito"
      ],
      [
        1,
        "John Di Benedetto"
      ],
      [
        1,
        "Bo Dietl"
      ],
      [
        1,
        "Frank DiLeo"
      ],
      [
        1,
        "Richard Dioguardi"
      ],
      [
        1,
        "Illeana Douglas"
      ],
      [
        1,
        "Thomas E. Camuti"
      ],
      [
        1,
        "Tony Ellis"
      ],
      [
        1,
        "Louis Eppolito"
      ],
      [
        1,
        "Peter Fain"
      ],
      [
        1,
        "Steve Forleo"
      ],
      [
        1,
        "Vincent Gallo"
      ],
      [
        1,
        "Julie Garfield"
      ],
      [
        1,
        "Ruby Gaynor"
      ],
      [
        1,
        "Violet Gaynor"
      ],
      [
        1,
        "Joe Gioco"
      ],
      [
        1,
        "Bob Golub"
      ],
      [
        1,
        "Michaelangelo Graziano"
      ],
      [
        1,
        "Margaux Guerard"
      ],
      [
        1,
        "Russell Halley"
      ],
      [
        1,
        "Edward Hayes"
      ],
      [
        1,
        "Paul Herman"
      ],
      [
        1,
        "Thomas Hewson"
      ],
      [
        1,
        "Peter Hock"
      ],
      [
        1,
        "Michael Imperioli"
      ],
      [
        1,
        "Mark Jacobs"
      ],
      [
        1,
        "Alyson Jones"
      ],
      [
        1,
        "Elaine Kagan"
      ],
      [
        1,
        "Nadine Kay"
      ],
      [
        1,
        "Paula Kcira"
      ],
      [
        1,
        "Stella Keitel"
      ],
      [
        1,
        "Jesse Kirtzman"
      ],
      [
        1,
        "Dino Laudicina"
      ],
      [
        1,
        "Marianne Leone"
      ],
      [
        1,
        "Gayle Lewis"
      ],
      [
        1,
        "Ray Liotta"
      ],
      [
        1,
        "Tony Lip"
      ],
      [
        1,
        "Gaetano Lisi"
      ],
      [
        1,
        "Gaetano LoGiudice"
      ],
      [
        1,
        "Chuck Low"
      ],
      [
        1,
        "Thomas Lowry"
      ],
      [
        1,
        "Ronald Maccone"
      ],
      [
        1,
        "John Manca"
      ],
      [
        1,
        "Gina Mastrogiacomo"
      ],
      [
        1,
        "Gina Mattia"
      ],
      [
        1,
        "Debi Mazar"
      ],
      [
        1,
        "Edward McDonald"
      ],
      [
        1,
        "Fran McGee"
      ],
      [
        1,
        "Paul McIsaac"
      ],
      [
        1,
        "Marie Michaels"
      ],
      [
        1,
        "Paul Mougey"
      ],
      [
        1,
        "Richard Mullally"
      ],
      [
        1,
        "Peter Onorati"
      ],
      [
        1,
        "Daniel P. Conte"
      ],
      [
        1,
        "Vincent Pastore"
      ],
      [
        1,
        "Garry Pastore"
      ],
      [
        1,
        "Frank Pellegrino"
      ],
      [
        1,
        "Joe Pesci"
      ],
      [
        1,
        "Vito Picone"
      ],
      [
        1,
        "Angela Pietropinto"
      ],
      [
        1,
        "Anthony Polemeni"
      ],
      [
        1,
        "Anthony Powers"
      ],
      [
        1,
        "Melissa Prophet"
      ],
      [
        1,
        "James Quattrochi"
      ],
      [
        1,
        "Lawrence Sacco"
      ],
      [
        1,
        "Catherine Scorsese"
      ],
      [
        1,
        "Charles Scorsese"
      ],
      [
        1,
        "Andrew Scudiero"
      ],
      [
        1,
        "Christopher Serrone"
      ],
      [
        1,
        "Suzanne Shepherd"
      ],
      [
        1,
        "Larry Silvestri"
      ],
      [
        1,
        "Tony Sirico"
      ],
      [
        1,
        "Frank Sivero"
      ],
      [
        1,
        "Margaret Smith"
      ],
      [
        1,
        "Paul Sorvino"
      ],
      [
        1,
        "Mike Starr"
      ],
      [
        1,
        "Beau Starr"
      ],
      [
        1,
        "Philip Suriano"
      ],
      [
        1,
        "Jerry Vale"
      ],
      [
        1,
        "Anthony Valentin"
      ],
      [
        1,
        "Nick Vallelonga"
      ],
      [
        1,
        "Susan Varon"
      ],
      [
        1,
        "Frank Vincent"
      ],
      [
        1,
        "Robbie Vinton"
      ],
      [
        1,
        "Katherine Wallach"
      ],
      [
        1,
        "Luke Walter"
      ],
      [
        1,
        "Adam Wandt"
      ],
      [
        1,
        "Irving Welzer"
      ],
      [
        1,
        "Elizabeth Whitcraft"
      ],
      [
        1,
        "Welker White"
      ],
      [
        1,
        "Johnny Williams"
      ],
      [
        1,
        "Margo Winkler"
      ],
      [
        1,
        "Henny Youngman"
      ]
    ],
    "directors": "Martin Scorsese",
    "budget_string": "25m USD",
    "gross_string": "47m USD",
    "metascore_string": "90%"
  },
  {
    "imdb_id": "tt0103064",
    "plot": "A cyborg, identical to the one who failed to kill Sarah Connor, must now protect her ten-year-old son John from a more advanced and powerful cyborg.",
    "certification": "R",
    "other_wins_count": 32,
    "other_noms_count": 33,
    "amazon": "https://www.amazon.com/Terminator-Judgment-Day-Blu-ray-Digital/dp/B00UFMKSDW/ref=tmm_blu_swatch_0?_encoding=UTF8&amp;qid=1654828602&amp;sr=8-5&_encoding=UTF8&tag=sianglu-20&linkCode=ur2&linkId=6cbb531049cc5d60666267536a2439fe&camp=1789&creative=9325",
    "justwatch": "https://justwatch.com/us/movie/terminator-2-judgment-day?utm_source=share&utm_medium=web",
    "beige_index": 2,
    "beige_angle": 42.3,
    "cast": [
      [
        5,
        "Mark Christopher Lawrence"
      ],
      [
        5,
        "S. Epatha Merkerson"
      ],
      [
        5,
        "Joe Morton"
      ],
      [
        5,
        "DeVaughn Nixon"
      ],
      [
        5,
        "Abdul Salaam El Razzac"
      ],
      [
        5,
        "Guss Williams"
      ],
      [
        3,
        "Casey Chavez"
      ],
      [
        3,
        "Castulo Guerra"
      ],
      [
        3,
        "Diane Rodriguez"
      ],
      [
        2,
        "Van Ling"
      ],
      [
        1,
        "Charles A. Tamburro"
      ],
      [
        1,
        "Dalton Abbott"
      ],
      [
        1,
        "Xander Berkeley"
      ],
      [
        1,
        "Ennalls Berl"
      ],
      [
        1,
        "Earl Boen"
      ],
      [
        1,
        "Lisa Brinegar"
      ],
      [
        1,
        "Danny Cooksey"
      ],
      [
        1,
        "Nikki Cox"
      ],
      [
        1,
        "Gwenda Deacon"
      ],
      [
        1,
        "Michael Edwards"
      ],
      [
        1,
        "Noel Evangelisti"
      ],
      [
        1,
        "Terrence Evans"
      ],
      [
        1,
        "Edward Furlong"
      ],
      [
        1,
        "Ken Gibbel"
      ],
      [
        1,
        "Jenette Goldstein"
      ],
      [
        1,
        "Linda Hamilton"
      ],
      [
        1,
        "Leslie Hamilton Gearren"
      ],
      [
        1,
        "Pat Kouri"
      ],
      [
        1,
        "Don Lake"
      ],
      [
        1,
        "Jared Lounsbery"
      ],
      [
        1,
        "Tom McDonald"
      ],
      [
        1,
        "Mike Muscat"
      ],
      [
        1,
        "Dean Norris"
      ],
      [
        1,
        "Jim Palmer"
      ],
      [
        1,
        "Robert Patrick"
      ],
      [
        1,
        "Colin Patrick Lynch"
      ],
      [
        1,
        "Denney Pierce"
      ],
      [
        1,
        "J. Rob Jordan"
      ],
      [
        1,
        "Charles Robert Brown"
      ],
      [
        1,
        "Peter Schrum"
      ],
      [
        1,
        "Arnold Schwarzenegger"
      ],
      [
        1,
        "Tony Simotes"
      ],
      [
        1,
        "Dan Stanton"
      ],
      [
        1,
        "Don Stanton"
      ],
      [
        1,
        "Sven-Ole Thorsen"
      ],
      [
        1,
        "Richard Vidan"
      ],
      [
        1,
        "Shane Wilder"
      ],
      [
        1,
        "Robert Winley"
      ],
      [
        1,
        "William Wisher"
      ],
      [
        1,
        "Ron Young"
      ]
    ],
    "directors": "James Cameron",
    "budget_string": "102m USD",
    "gross_string": "520.9m USD",
    "metascore_string": "75%"
  },
  {
    "imdb_id": "tt0102926",
    "plot": "A young F.B.I. cadet must receive the help of an incarcerated and manipulative cannibal killer to help catch another serial killer, a madman who skins his victims.",
    "certification": "R",
    "other_wins_count": 64,
    "other_noms_count": 51,
    "amazon": "https://www.amazon.com/Silence-Lambs-30th-Anniversary-UHD/dp/B09B2FVTDX/ref=sr_1_7?crid=1NCXRUANNAPEW&amp;keywords=silence+of+the+lambs&amp;qid=1654827977&amp;sprefix=silence+of+the+lambs%252Caps%252C680&amp;sr=8-7&_encoding=UTF8&tag=sianglu-20&linkCode=ur2&linkId=1ebe2f536194fd9e35706cff822c16b0&camp=1789&creative=9325",
    "justwatch": "https://justwatch.com/us/movie/the-silence-of-the-lambs?utm_source=share&utm_medium=web",
    "beige_index": 2,
    "beige_angle": 42.453,
    "cast": [
      [
        5,
        "Lamont Arnold"
      ],
      [
        5,
        "Andre B. Blake"
      ],
      [
        5,
        "Obba Babatund\u00e9"
      ],
      [
        5,
        "David Early"
      ],
      [
        5,
        "Frankie Faison"
      ],
      [
        5,
        "Tommy Lafitte"
      ],
      [
        5,
        "Kasi Lemmons"
      ],
      [
        5,
        "Lawrence T. Wrentz"
      ],
      [
        1,
        "George 'Red' Schwartz"
      ],
      [
        1,
        "Lawrence A. Bonney"
      ],
      [
        1,
        "Chuck Aber"
      ],
      [
        1,
        "James B. Howard"
      ],
      [
        1,
        "Diane Baker"
      ],
      [
        1,
        "Gene Borkan"
      ],
      [
        1,
        "Don Brockett"
      ],
      [
        1,
        "Josh Broder"
      ],
      [
        1,
        "Dan Butler"
      ],
      [
        1,
        "Alex Coleman"
      ],
      [
        1,
        "Roger Corman"
      ],
      [
        1,
        "Bill Dalzell"
      ],
      [
        1,
        "Danny Darst"
      ],
      [
        1,
        "Jim Dratfield"
      ],
      [
        1,
        "Cynthia Ettinger"
      ],
      [
        1,
        "Jodie Foster"
      ],
      [
        1,
        "Scott Glenn"
      ],
      [
        1,
        "Anthony Heald"
      ],
      [
        1,
        "Brent Hinkley"
      ],
      [
        1,
        "Anthony Hopkins"
      ],
      [
        1,
        "Chris Isaak"
      ],
      [
        1,
        "Buzz Kilman"
      ],
      [
        1,
        "Jeffrie Lane"
      ],
      [
        1,
        "Paul Lazar"
      ],
      [
        1,
        "Leib Lensky"
      ],
      [
        1,
        "Ted Levine"
      ],
      [
        1,
        "Adelle Lutz"
      ],
      [
        1,
        "Pat McNamara"
      ],
      [
        1,
        "George Michael"
      ],
      [
        1,
        "Bill Miller"
      ],
      [
        1,
        "Charles Napier"
      ],
      [
        1,
        "Harry Northup"
      ],
      [
        1,
        "Jim Roche"
      ],
      [
        1,
        "Lauren Roselli"
      ],
      [
        1,
        "Stuart Rudin"
      ],
      [
        1,
        "Rebecca Saxon"
      ],
      [
        1,
        "Frank Seals Jr."
      ],
      [
        1,
        "Maria Skorobogatov"
      ],
      [
        1,
        "Brooke Smith"
      ],
      [
        1,
        "D. Stanton Miranda"
      ],
      [
        1,
        "Kenneth Utt"
      ],
      [
        1,
        "Ron Vawter"
      ],
      [
        1,
        "Daniel von Bargen"
      ],
      [
        1,
        "Tracey Walter"
      ],
      [
        1,
        "Steve Wyatt"
      ]
    ],
    "directors": "Jonathan Demme",
    "budget_string": "19m USD",
    "gross_string": "272.7m USD",
    "metascore_string": "85%"
  },
  {
    "imdb_id": "tt0101414",
    "plot": "A prince cursed to spend his days as a hideous monster sets out to regain his humanity by earning a young woman's love.",
    "certification": "G",
    "other_wins_count": 30,
    "other_noms_count": 32,
    "amazon": "https://www.amazon.com/Beauty-Beast-Anniversary-DIGITAL-Blu-ray/dp/B01G4N5Q28/ref=tmm_blu_title_0?_encoding=UTF8&amp;qid=1655194609&amp;sr=1-1-spons&_encoding=UTF8&tag=sianglu-20&linkCode=ur2&linkId=eda290021a1f821f94ecda819ecbec1f&camp=1789&creative=9325",
    "justwatch": "https://justwatch.com/us/movie/beauty-and-the-beast-1991?utm_source=share&utm_medium=web",
    "beige_index": 1,
    "beige_angle": 12.0006,
    "cast": [
      [
        5,
        "Cynthia Richards-Hewes"
      ],
      [
        3,
        "Jesse Corti"
      ],
      [
        1,
        "Bruce Adler"
      ],
      [
        1,
        "Mary Ann Hart"
      ],
      [
        1,
        "Jo Anne Worley"
      ],
      [
        1,
        "Scott Barnes"
      ],
      [
        1,
        "Robby Benson"
      ],
      [
        1,
        "Vanna Bonta"
      ],
      [
        1,
        "Maureen Brennan"
      ],
      [
        1,
        "Liz Callaway"
      ],
      [
        1,
        "Brian Cummings"
      ],
      [
        1,
        "Albert de Ruiter"
      ],
      [
        1,
        "George Dvorsky"
      ],
      [
        1,
        "Alvin Epstein"
      ],
      [
        1,
        "Rex Everhart"
      ],
      [
        1,
        "Bill Farmer"
      ],
      [
        1,
        "Bruce Fifer"
      ],
      [
        1,
        "Johnson Flucker"
      ],
      [
        1,
        "Randy Hansen"
      ],
      [
        1,
        "Mary Kay Bergman"
      ],
      [
        1,
        "Alix Korey"
      ],
      [
        1,
        "Phyllis Kubey"
      ],
      [
        1,
        "Philip L. Clarke"
      ],
      [
        1,
        "Herndon Lackey"
      ],
      [
        1,
        "Angela Lansbury"
      ],
      [
        1,
        "Larry Moss"
      ],
      [
        1,
        "Alec Murphy"
      ],
      [
        1,
        "Paige O'Hara"
      ],
      [
        1,
        "Jerry Orbach"
      ],
      [
        1,
        "Wilbur Pauley"
      ],
      [
        1,
        "Jennifer Perito"
      ],
      [
        1,
        "Caroline Peyton"
      ],
      [
        1,
        "Bradley Pierce"
      ],
      [
        1,
        "Patrick Pinney"
      ],
      [
        1,
        "Kimmy Robertson"
      ],
      [
        1,
        "Gordon Stanley"
      ],
      [
        1,
        "Stephen Sturk"
      ],
      [
        1,
        "Frank Welker"
      ],
      [
        1,
        "Richard White"
      ]
    ],
    "directors": "Gary Trousdale & Kirk Wise",
    "budget_string": "25m USD",
    "gross_string": "425m USD",
    "metascore_string": "95%"
  },
  {
    "imdb_id": "tt0105695",
    "plot": "Retired Old West gunslinger William Munny reluctantly takes on one last job, with the help of his old partner Ned Logan and a young man, The \"Schofield Kid.\"",
    "certification": "R",
    "other_wins_count": 46,
    "other_noms_count": 47,
    "amazon": "https://www.amazon.com/Unforgiven-Blu-ray/dp/B000RWDXDC/ref=sr_1_4?crid=1LKO5XU5UVI6I&amp;keywords=unforgiven+blu+ray&amp;qid=1655174331&amp;sprefix=unforgiven+blu+ray%252Caps%252C291&amp;sr=8-4&_encoding=UTF8&tag=sianglu-20&linkCode=ur2&linkId=c64418684a361ee7c7e51e68e921a54f&camp=1789&creative=9325",
    "justwatch": "https://justwatch.com/us/movie/unforgiven?utm_source=share&utm_medium=web",
    "beige_index": 1,
    "beige_angle": 19.3176,
    "cast": [
      [
        5,
        "Morgan Freeman"
      ],
      [
        4,
        "Mina E. Mina"
      ],
      [
        3,
        "Ben Cardinal"
      ],
      [
        3,
        "Cherrilene Cardinal"
      ],
      [
        1,
        "Paul Anthony McLean"
      ],
      [
        1,
        "Garner Butler"
      ],
      [
        1,
        "Frank C. Turner"
      ],
      [
        1,
        "Rob Campbell"
      ],
      [
        1,
        "Michael Charrois"
      ],
      [
        1,
        "William Davidson"
      ],
      [
        1,
        "Clint Eastwood"
      ],
      [
        1,
        "Beverley Elliott"
      ],
      [
        1,
        "Frances Fisher"
      ],
      [
        1,
        "Tara Frederick"
      ],
      [
        1,
        "Greg Goossen"
      ],
      [
        1,
        "Gene Hackman"
      ],
      [
        1,
        "Richard Harris"
      ],
      [
        1,
        "James Herman"
      ],
      [
        1,
        "Anthony James"
      ],
      [
        1,
        "Larry Joshua"
      ],
      [
        1,
        "Sam Karas"
      ],
      [
        1,
        "Robert Koons"
      ],
      [
        1,
        "Henry Kope"
      ],
      [
        1,
        "Aline Levasseur"
      ],
      [
        1,
        "Jefferson Mappin"
      ],
      [
        1,
        "Walter Marsh"
      ],
      [
        1,
        "Michael Maurer"
      ],
      [
        1,
        "Philip Maurice Hayes"
      ],
      [
        1,
        "Shane Meier"
      ],
      [
        1,
        "David Mucci"
      ],
      [
        1,
        "Lochlyn Munro"
      ],
      [
        1,
        "George Orrison"
      ],
      [
        1,
        "John Pyper-Ferguson"
      ],
      [
        1,
        "Jeremy Ratchford"
      ],
      [
        1,
        "Larry Reese"
      ],
      [
        1,
        "Liisa Repo-Martell"
      ],
      [
        1,
        "Saul Rubinek"
      ],
      [
        1,
        "Josie Smith"
      ],
      [
        1,
        "Anna Thomson"
      ],
      [
        1,
        "Ron White"
      ],
      [
        1,
        "Jaimz Woolvett"
      ]
    ],
    "directors": "Clint Eastwood",
    "budget_string": "14.4m USD",
    "gross_string": "159.2m USD",
    "metascore_string": "85%"
  },
  {
    "imdb_id": "tt0105236",
    "plot": "When a simple jewelry heist goes horribly wrong, the surviving criminals begin to suspect that one of them is a police informant.",
    "certification": "R",
    "other_wins_count": 12,
    "other_noms_count": 23,
    "amazon": "https://www.amazon.com/Reservoir-Dogs-15th-Anniversary-Blu-ray/dp/B000KX0ISG/ref=sr_1_2?crid=9UYQFPR327U7&amp;keywords=reservoir+dogs&amp;qid=1654844629&amp;s=movies-tv&amp;sprefix=reservoir+dogs%252Cmovies-tv%252C6520&amp;sr=1-2&_encoding=UTF8&tag=sianglu-20&linkCode=ur2&linkId=a6cc064a57ea0abcb2741e9bef4e0946&camp=1789&creative=9325",
    "justwatch": "https://justwatch.com/us/movie/reservoir-dogs?utm_source=share&utm_medium=web",
    "beige_index": 1,
    "beige_angle": 20.3472,
    "cast": [
      [
        5,
        "Randy Brooks"
      ],
      [
        3,
        "Maria Strova"
      ],
      [
        1,
        "Kirk Baltz"
      ],
      [
        1,
        "Lawrence Bender"
      ],
      [
        1,
        "Edward Bunker"
      ],
      [
        1,
        "Steve Buscemi"
      ],
      [
        1,
        "Suzanne Celeste"
      ],
      [
        1,
        "Tony Cosmo"
      ],
      [
        1,
        "Craig Hamann"
      ],
      [
        1,
        "Linda Kaye"
      ],
      [
        1,
        "Harvey Keitel"
      ],
      [
        1,
        "Laurie Lathem"
      ],
      [
        1,
        "Michael Madsen"
      ],
      [
        1,
        "Chris Penn"
      ],
      [
        1,
        "Stevo Polyi"
      ],
      [
        1,
        "Tim Roth"
      ],
      [
        1,
        "Robert Ruth"
      ],
      [
        1,
        "Michael Sottile"
      ],
      [
        1,
        "David Steen"
      ],
      [
        1,
        "Burr Steers"
      ],
      [
        1,
        "Quentin Tarantino"
      ],
      [
        1,
        "Lawrence Tierney"
      ],
      [
        1,
        "Steven Wright"
      ]
    ],
    "directors": "Quentin Tarantino",
    "budget_string": "1.2m USD",
    "gross_string": "2.9m USD",
    "metascore_string": "79%"
  },
  {
    "imdb_id": "tt0103639",
    "plot": "A kindhearted street urchin and a power-hungry Grand Vizier vie for a magic lamp that has the power to make their deepest wishes come true.",
    "certification": "G",
    "other_wins_count": 31,
    "other_noms_count": 22,
    "amazon": "https://www.amazon.com/Aladdin-Diamond-Blu-ray-DVD-Digital/dp/B00WR534TK/ref=sr_1_4?crid=2T6U2TJNTQAWN&amp;keywords=aladdin+blu+ray&amp;qid=1655194469&amp;s=movies-tv&amp;sprefix=aladdin+blu+r%252Cmovies-tv%252C295&amp;sr=1-4&_encoding=UTF8&tag=sianglu-20&linkCode=ur2&linkId=3b383aa9bb07df56f5eeb697db70d37c&camp=1789&creative=9325",
    "justwatch": "https://justwatch.com/us/movie/aladdin?utm_source=share&utm_medium=web",
    "beige_index": 1,
    "beige_angle": 0.0,
    "cast": [
      [
        1,
        "Charlie Adler"
      ],
      [
        1,
        "Bruce Adler"
      ],
      [
        1,
        "Jim Cummings"
      ],
      [
        1,
        "Jonathan Freeman"
      ],
      [
        1,
        "Bruce Gooch"
      ],
      [
        1,
        "Gilbert Gottfried"
      ],
      [
        1,
        "Jerry Houser"
      ],
      [
        1,
        "Philip L. Clarke"
      ],
      [
        1,
        "Linda Larkin"
      ],
      [
        1,
        "Vera Lockwood"
      ],
      [
        1,
        "Patrick Pinney"
      ],
      [
        1,
        "Douglas Seale"
      ],
      [
        1,
        "Scott Weinger"
      ],
      [
        1,
        "Frank Welker"
      ],
      [
        1,
        "Robin Williams"
      ]
    ],
    "directors": "Ron Clements & John Musker",
    "budget_string": "28m USD",
    "gross_string": "504.1m USD",
    "metascore_string": "86%"
  },
  {
    "imdb_id": "tt0107207",
    "plot": "A man's coerced confession to an I.R.A. bombing he did not commit results in the imprisonment of his father as well. An English lawyer fights to free them.",
    "certification": "R",
    "other_wins_count": 7,
    "other_noms_count": 34,
    "amazon": "https://www.amazon.com/Name-Father-Blu-ray-Daniel-Day-Lewis/dp/B00BJ0OO7A/ref=sr_1_1?crid=2XK4OTG0J8V4J&amp;keywords=in+the+name+of+the+father+blu+ray&amp;qid=1655183094&amp;s=movies-tv&amp;sprefix=in+the+name+of+the+father+blu+ray%252Cmovies-tv%252C280&amp;sr=1-1&_encoding=UTF8&tag=sianglu-20&linkCode=ur2&linkId=49f03925c6dfeb7c363f7fc0f2f051a1&camp=1789&creative=9325",
    "justwatch": "https://justwatch.com/us/movie/in-the-name-of-the-father?utm_source=share&utm_medium=web",
    "beige_index": 1,
    "beige_angle": 6.2316,
    "cast": [
      [
        5,
        "Paterson Joseph"
      ],
      [
        5,
        "Emeka Okeki"
      ],
      [
        1,
        "Alan Amsby"
      ],
      [
        1,
        "Don Baker"
      ],
      [
        1,
        "Alan Barry"
      ],
      [
        1,
        "John Benfield"
      ],
      [
        1,
        "Jimmie Bergin"
      ],
      [
        1,
        "Marlon Brando"
      ],
      [
        1,
        "Anthony Brophy"
      ],
      [
        1,
        "Maclean Burke"
      ],
      [
        1,
        "Saffron Burrows"
      ],
      [
        1,
        "Billy Byrne"
      ],
      [
        1,
        "Peter Campbell"
      ],
      [
        1,
        "Guy Carleton"
      ],
      [
        1,
        "Karen Carlisle"
      ],
      [
        1,
        "Joey Cashman"
      ],
      [
        1,
        "Jo Connor"
      ],
      [
        1,
        "Clodagh Conroy"
      ],
      [
        1,
        "Owen Conroy"
      ],
      [
        1,
        "Alison Crosbie"
      ],
      [
        1,
        "Fiona Daly"
      ],
      [
        1,
        "Phil Davis"
      ],
      [
        1,
        "Daniel Day-Lewis"
      ],
      [
        1,
        "Brian de Salvo"
      ],
      [
        1,
        "Tony Denham"
      ],
      [
        1,
        "Rachael Dowling"
      ],
      [
        1,
        "Dave Duffy"
      ],
      [
        1,
        "Catherine Dunne"
      ],
      [
        1,
        "Martin Dunne"
      ],
      [
        1,
        "Kenneth Edge"
      ],
      [
        1,
        "Beatie Edney"
      ],
      [
        1,
        "Alistair Findlay"
      ],
      [
        1,
        "John Gallagher"
      ],
      [
        1,
        "Richard Graham"
      ],
      [
        1,
        "Aiden Grennell"
      ],
      [
        1,
        "Laurence Griffin"
      ],
      [
        1,
        "Frank Harper"
      ],
      [
        1,
        "Jamie Harris"
      ],
      [
        1,
        "Denys Hawthorne"
      ],
      [
        1,
        "Luke Hayden"
      ],
      [
        1,
        "Nye Heron"
      ],
      [
        1,
        "John Higgins"
      ],
      [
        1,
        "Bosco Hogan"
      ],
      [
        1,
        "Peter Howitt"
      ],
      [
        1,
        "Joanna Irvine"
      ],
      [
        1,
        "Joe Jeffers"
      ],
      [
        1,
        "John Joe Fontana"
      ],
      [
        1,
        "Marie Jones"
      ],
      [
        1,
        "Maurice Kehoe"
      ],
      [
        1,
        "Tina Kellegher"
      ],
      [
        1,
        "Philip King"
      ],
      [
        1,
        "Sean Lawlor"
      ],
      [
        1,
        "Joey Legaspi"
      ],
      [
        1,
        "Marcus Lynch"
      ],
      [
        1,
        "John Lynch"
      ],
      [
        1,
        "Oliver Maguire"
      ],
      [
        1,
        "Dee Man Kole"
      ],
      [
        1,
        "Daniel Massey"
      ],
      [
        1,
        "Maureen McBride"
      ],
      [
        1,
        "Frankie McCafferty"
      ],
      [
        1,
        "Leah McCullagh"
      ],
      [
        1,
        "Mario McGovern"
      ],
      [
        1,
        "Darren McHugh"
      ],
      [
        1,
        "Kelly McKeavney"
      ],
      [
        1,
        "Joe McPartland"
      ],
      [
        1,
        "Gerard McSorley"
      ],
      [
        1,
        "Anna Meegan"
      ],
      [
        1,
        "Richard Michaelis"
      ],
      [
        1,
        "Iain Montague"
      ],
      [
        1,
        "Seamus Moran"
      ],
      [
        1,
        "Barbara Mulcahy"
      ],
      [
        1,
        "Larry Murphy"
      ],
      [
        1,
        "Martin Murphy"
      ],
      [
        1,
        "Jason Murtagh"
      ],
      [
        1,
        "Jane Nolan"
      ],
      [
        1,
        "Liam O'Callaghan"
      ],
      [
        1,
        "Aine O'Connor"
      ],
      [
        1,
        "Alan O'Connor"
      ],
      [
        1,
        "Paul O'Grady"
      ],
      [
        1,
        "Jer O'Leary"
      ],
      [
        1,
        "Terry O'Neill"
      ],
      [
        1,
        "Al Pacino"
      ],
      [
        1,
        "Bernard Pellegrinetti"
      ],
      [
        1,
        "Tim Perrin"
      ],
      [
        1,
        "John Pickles"
      ],
      [
        1,
        "Pete Postlethwaite"
      ],
      [
        1,
        "Paul Raynor"
      ],
      [
        1,
        "Corin Redgrave"
      ],
      [
        1,
        "Jonathan Ryan"
      ],
      [
        1,
        "Mark Sheppard"
      ],
      [
        1,
        "Peter Sheridan"
      ],
      [
        1,
        "Peter Sheridan Sr."
      ],
      [
        1,
        "Britta Smith"
      ],
      [
        1,
        "Rob Spendlove"
      ],
      [
        1,
        "Emma Thompson"
      ],
      [
        1,
        "Malcolm Tierney"
      ],
      [
        1,
        "Mick Tohill"
      ],
      [
        1,
        "Stanley Townsend"
      ],
      [
        1,
        "Julian Walsh"
      ],
      [
        1,
        "Paul Warriner"
      ],
      [
        1,
        "Mal Whyte"
      ],
      [
        1,
        "Tom Wilkinson"
      ],
      [
        1,
        "Ronan Wilmot"
      ],
      [
        1,
        "Stuart Wolfenden"
      ]
    ],
    "directors": "Jim Sheridan",
    "budget_string": "13m USD",
    "gross_string": "65.8m USD",
    "metascore_string": "84%"
  },
  {
    "imdb_id": "tt0107048",
    "plot": "A self-centered Pittsburgh weatherman finds himself inexplicably trapped in a small town as he lives the same day over and over again.",
    "certification": "PG",
    "other_wins_count": 6,
    "other_noms_count": 17,
    "amazon": "https://www.amazon.com/Groundhog-Day-15th-Anniversary-Blu-ray/dp/B001KEHAI0/ref=tmm_blu_swatch_0?_encoding=UTF8&amp;qid=1655185294&amp;sr=1-2&_encoding=UTF8&tag=sianglu-20&linkCode=ur2&linkId=69f84564444a40b531889dc3566d2da5&camp=1789&creative=9325",
    "justwatch": "https://justwatch.com/us/movie/groundhog-day?utm_source=share&utm_medium=web",
    "beige_index": 1,
    "beige_angle": 16.5006,
    "cast": [
      [
        5,
        "Dianne B. Shaw"
      ],
      [
        5,
        "John M. Watson Sr."
      ],
      [
        3,
        "Shaun Chaiyabhat"
      ],
      [
        1,
        "Ben A. Fish"
      ],
      [
        1,
        "Roger Adler"
      ],
      [
        1,
        "Lindsay Albert"
      ],
      [
        1,
        "Barbara Ann Grimes"
      ],
      [
        1,
        "Evangeline Binkley"
      ],
      [
        1,
        "Carol Bivins"
      ],
      [
        1,
        "Brian Doyle-Murray"
      ],
      [
        1,
        "Chet Dubowski"
      ],
      [
        1,
        "Rick Ducommun"
      ],
      [
        1,
        "Robin Duke"
      ],
      [
        1,
        "Chris Elliott"
      ],
      [
        1,
        "C.O. Erickson"
      ],
      [
        1,
        "Willie Garson"
      ],
      [
        1,
        "Marita Geraghty"
      ],
      [
        1,
        "Angela Gollan"
      ],
      [
        1,
        "Ann Heekin"
      ],
      [
        1,
        "Timothy Hendrickson"
      ],
      [
        1,
        "Richard Henzel"
      ],
      [
        1,
        "Ken Hudson Campbell"
      ],
      [
        1,
        "Andie MacDowell"
      ],
      [
        1,
        "Samuel Mages"
      ],
      [
        1,
        "Sandy Maschmeyer"
      ],
      [
        1,
        "Tom Milanovich"
      ],
      [
        1,
        "Amy Murdoch"
      ],
      [
        1,
        "Bill Murray"
      ],
      [
        1,
        "Leighanne O'Neil"
      ],
      [
        1,
        "Rick Overton"
      ],
      [
        1,
        "Lucina Paquet"
      ],
      [
        1,
        "David Pasquesi"
      ],
      [
        1,
        "Angela Paton"
      ],
      [
        1,
        "Brenda Pickleman"
      ],
      [
        1,
        "Les Podewell"
      ],
      [
        1,
        "Lee R. Sellars"
      ],
      [
        1,
        "Harold Ramis"
      ],
      [
        1,
        "Rob Riley"
      ],
      [
        1,
        "Don Riozz McNichols"
      ],
      [
        1,
        "Peggy Roeder"
      ],
      [
        1,
        "Eric Saiet"
      ],
      [
        1,
        "Rod Sell"
      ],
      [
        1,
        "Michael Shannon"
      ],
      [
        1,
        "Stephen Tobolowsky"
      ],
      [
        1,
        "Hynden Walch"
      ],
      [
        1,
        "Martha Webster"
      ],
      [
        1,
        "Brian Willig"
      ],
      [
        1,
        "Ben Zwick"
      ]
    ],
    "directors": "Harold Ramis",
    "budget_string": "14.6m USD",
    "gross_string": "71.1m USD",
    "metascore_string": "72%"
  },
  {
    "imdb_id": "tt0108052",
    "plot": "In German-occupied Poland during World War II, industrialist Oskar Schindler gradually becomes concerned for his Jewish workforce after witnessing their persecution by the Nazis.",
    "certification": "R",
    "other_wins_count": 84,
    "other_noms_count": 49,
    "amazon": "https://www.amazon.com/Schindlers-List-Liam-Neeson/dp/B00BEN0V8S/ref=sr_1_1?crid=147CLCCQLQYKZ&amp;keywords=schindler%2527s+list&amp;qid=1654825940&amp;sprefix=schindler%2527s+list%252Caps%252C704&amp;sr=8-1&_encoding=UTF8&tag=sianglu-20&linkCode=ur2&linkId=1093ac1349184f58e620d96bf1178ed7&camp=1789&creative=9325",
    "justwatch": "https://justwatch.com/us/movie/schindlers-list?utm_source=share&utm_medium=web",
    "beige_index": 1,
    "beige_angle": 0.864,
    "cast": [
      [
        3,
        "Ben Kingsley"
      ],
      [
        1,
        "Peter Appiano"
      ],
      [
        1,
        "Hans-J\u00f6rg Assmann"
      ],
      [
        1,
        "Uri Avrahami"
      ],
      [
        1,
        "Dominika Bednarczyk"
      ],
      [
        1,
        "Sigurd Bemme"
      ],
      [
        1,
        "Dirk Bender"
      ],
      [
        1,
        "Martin Bergmann"
      ],
      [
        1,
        "Henryk Bista"
      ],
      [
        1,
        "Tadeusz Bradecki"
      ],
      [
        1,
        "Stanislaw Brejdygant"
      ],
      [
        1,
        "Alexander Buczolich"
      ],
      [
        1,
        "Piotr Cyrwus"
      ],
      [
        1,
        "Oliwia Dabrowska"
      ],
      [
        1,
        "Ezra Dagan"
      ],
      [
        1,
        "Grzegorz Damiecki"
      ],
      [
        1,
        "Magdalena Dandourian"
      ],
      [
        1,
        "Embeth Davidtz"
      ],
      [
        1,
        "Tomasz Dedek"
      ],
      [
        1,
        "Daniel Del-Ponte"
      ],
      [
        1,
        "Pawel Delag"
      ],
      [
        1,
        "Beata Deskur"
      ],
      [
        1,
        "Miri Fabian"
      ],
      [
        1,
        "Ruth Farhi"
      ],
      [
        1,
        "Ravit Ferera"
      ],
      [
        1,
        "Ralph Fiennes"
      ],
      [
        1,
        "Peter Flechtner"
      ],
      [
        1,
        "Jeremy Flynn"
      ],
      [
        1,
        "Malgorzata Gebel"
      ],
      [
        1,
        "Marian Glinka"
      ],
      [
        1,
        "Caroline Goodall"
      ],
      [
        1,
        "Michael Gordon"
      ],
      [
        1,
        "Aldona Grochal"
      ],
      [
        1,
        "Marcin Grzymowicz"
      ],
      [
        1,
        "Alexander Held"
      ],
      [
        1,
        "Rami Heuberger"
      ],
      [
        1,
        "Slawomir Holland"
      ],
      [
        1,
        "Tadeusz Huk"
      ],
      [
        1,
        "Razia Israeli"
      ],
      [
        1,
        "Mark Ivanir"
      ],
      [
        1,
        "Jan Jurewicz"
      ],
      [
        1,
        "Piotr Kadlcik"
      ],
      [
        1,
        "Georges Kern"
      ],
      [
        1,
        "Wojciech Klata"
      ],
      [
        1,
        "Anemona Knut"
      ],
      [
        1,
        "Stanislaw Koczanowicz"
      ],
      [
        1,
        "Ewa Kolasinska"
      ],
      [
        1,
        "Wieslaw Komasa"
      ],
      [
        1,
        "Magdalena Komornicka"
      ],
      [
        1,
        "Shabtai Konorti"
      ],
      [
        1,
        "Sebastian Konrad"
      ],
      [
        1,
        "Agnieszka Korzeniowska"
      ],
      [
        1,
        "Maciej Kowalewski"
      ],
      [
        1,
        "Maciej Kozlowski"
      ],
      [
        1,
        "Zbigniew Kozlowski"
      ],
      [
        1,
        "Leopold Kozlowski"
      ],
      [
        1,
        "Hubert Kramar"
      ],
      [
        1,
        "Agnieszka Kruk\u00f3wna"
      ],
      [
        1,
        "Radoslaw Krzyzowski"
      ],
      [
        1,
        "Alicja Kubaszewska"
      ],
      [
        1,
        "Bettina Kupfer"
      ],
      [
        1,
        "Grzegorz Kwas"
      ],
      [
        1,
        "Geno Lechner"
      ],
      [
        1,
        "Erwin Leder"
      ],
      [
        1,
        "Jacek Lenczowski"
      ],
      [
        1,
        "Shmuel Levy"
      ],
      [
        1,
        "Edward Linde-Lubaszenko"
      ],
      [
        1,
        "Elina L\u00f6wensohn"
      ],
      [
        1,
        "Olaf Lubaszenko"
      ],
      [
        1,
        "Krzysztof Luft"
      ],
      [
        1,
        "Branko Lustig"
      ],
      [
        1,
        "B\u00e9atrice Macola"
      ],
      [
        1,
        "Wilhelm Manske"
      ],
      [
        1,
        "Danny Marcu"
      ],
      [
        1,
        "Haymon Maria Buttinger"
      ],
      [
        1,
        "Artus Maria Matthiessen"
      ],
      [
        1,
        "Vili Matula"
      ],
      [
        1,
        "Albert Misak"
      ],
      [
        1,
        "Thomas Morris"
      ],
      [
        1,
        "Anna Mucha"
      ],
      [
        1,
        "Liam Neeson"
      ],
      [
        1,
        "Harry Nehring"
      ],
      [
        1,
        "Jochen Nickel"
      ],
      [
        1,
        "Lech Niebielski"
      ],
      [
        1,
        "Adi Nitzan"
      ],
      [
        1,
        "Jerzy Nowak"
      ],
      [
        1,
        "Maciej Orlos"
      ],
      [
        1,
        "Maja Ostaszewska"
      ],
      [
        1,
        "G\u00f6tz Otto"
      ],
      [
        1,
        "Beata Paluch"
      ],
      [
        1,
        "Joachim Paul Assb\u00f6ck"
      ],
      [
        1,
        "Ludger Pistor"
      ],
      [
        1,
        "Piotr Polk"
      ],
      [
        1,
        "Eugeniusz Priwieziencew"
      ],
      [
        1,
        "Jacek Pulanecki"
      ],
      [
        1,
        "Osman Ragheb"
      ],
      [
        1,
        "Hans-Michael Rehberg"
      ],
      [
        1,
        "Hans Rosner"
      ],
      [
        1,
        "Beata Rybotycka"
      ],
      [
        1,
        "Jonathan Sagall"
      ],
      [
        1,
        "Jerzy Sagan"
      ],
      [
        1,
        "Michael Schiller"
      ],
      [
        1,
        "August Schm\u00f6lzer"
      ],
      [
        1,
        "Michael Schneider"
      ],
      [
        1,
        "Dorit Seadia"
      ],
      [
        1,
        "Wolfgang Seidenberg"
      ],
      [
        1,
        "Martin Semmelrogge"
      ],
      [
        1,
        "Andrzej Seweryn"
      ],
      [
        1,
        "Adam Siemion"
      ],
      [
        1,
        "Sebastian Skalski"
      ],
      [
        1,
        "Alexander Strobele"
      ],
      [
        1,
        "Etl Szyc"
      ],
      [
        1,
        "Dariusz Szymaniak"
      ],
      [
        1,
        "Friedrich von Thun"
      ],
      [
        1,
        "Agnieszka Wagner"
      ],
      [
        1,
        "Norbert Weisser"
      ],
      [
        1,
        "Andrzej Welminski"
      ],
      [
        1,
        "Maciej Winkler"
      ],
      [
        1,
        "Dieter Witting"
      ],
      [
        1,
        "Jacek W\u00f3jcicki"
      ],
      [
        1,
        "Marek Wrona"
      ],
      [
        1,
        "Lidia Wyrobiec-Bank"
      ],
      [
        1,
        "Esti Yerushalmi"
      ],
      [
        1,
        "Michael Z. Hoffmann"
      ],
      [
        1,
        "Lucyna Zabawa"
      ]
    ],
    "directors": "Steven Spielberg",
    "budget_string": "22m USD",
    "gross_string": "322.2m USD",
    "metascore_string": "94%"
  },
  {
    "imdb_id": "tt0107290",
    "plot": "A pragmatic paleontologist touring an almost complete theme park on an island in Central America is tasked with protecting a couple of kids after a power failure causes the park's cloned dinosaurs to run loose.",
    "certification": "PG-13",
    "other_wins_count": 41,
    "other_noms_count": 27,
    "amazon": "https://www.amazon.com/Jurassic-Park-Blu-ray-Sam-Neill/dp/B07739NG1Q/ref=sr_1_2?crid=1HDPDV3HQ6PCG&amp;keywords=jurassic+park+blu+ray&amp;qid=1655175558&amp;sprefix=jurassic+park+blu+ray%252Caps%252C295&amp;sr=8-2&_encoding=UTF8&tag=sianglu-20&linkCode=ur2&linkId=ecfe6420a80ebe005cfaff270c3c1eea&camp=1789&creative=9325",
    "justwatch": "https://justwatch.com/us/movie/jurassic-park-i?utm_source=share&utm_medium=web",
    "beige_index": 2,
    "beige_angle": 40.5,
    "cast": [
      [
        5,
        "Jophery C. Brown"
      ],
      [
        5,
        "Samuel L. Jackson"
      ],
      [
        3,
        "Adrian Escober"
      ],
      [
        3,
        "Miguel Sandoval"
      ],
      [
        2,
        "BD Wong"
      ],
      [
        1,
        "Richard Attenborough"
      ],
      [
        1,
        "Greg Burson"
      ],
      [
        1,
        "Dean Cundey"
      ],
      [
        1,
        "Laura Dern"
      ],
      [
        1,
        "Martin Ferrero"
      ],
      [
        1,
        "Jeff Goldblum"
      ],
      [
        1,
        "Whit Hertford"
      ],
      [
        1,
        "Christopher John Fields"
      ],
      [
        1,
        "Richard Kiley"
      ],
      [
        1,
        "Wayne Knight"
      ],
      [
        1,
        "Joseph Mazzello"
      ],
      [
        1,
        "Tom Mishler"
      ],
      [
        1,
        "Sam Neill"
      ],
      [
        1,
        "Bob Peck"
      ],
      [
        1,
        "Gerald R. Molen"
      ],
      [
        1,
        "Ariana Richards"
      ],
      [
        1,
        "Cameron Thor"
      ]
    ],
    "directors": "Steven Spielberg",
    "budget_string": "63m USD",
    "gross_string": "1099.6m USD",
    "metascore_string": "68%"
  },
  {
    "imdb_id": "tt0111161",
    "plot": "Two imprisoned men bond over a number of years, finding solace and eventual redemption through acts of common decency.",
    "certification": "R",
    "other_wins_count": 21,
    "other_noms_count": 36,
    "amazon": "https://www.amazon.com/Shawshank-Redemption-Tim-Robbins/dp/B004EJ2QEQ/ref=sr_1_3?crid=JJJV3E47YTGA&amp;keywords=the+shawshank+redemption&amp;qid=1654824840&amp;sprefix=the+shawshank+redemption%252Caps%252C317&amp;sr=8-3&_encoding=UTF8&tag=sianglu-20&linkCode=ur2&linkId=9e2cb1f15188f0cde8486bb53e1f05d2&camp=1789&creative=9325%22",
    "justwatch": "https://justwatch.com/us/movie/the-shawshank-redemption?utm_source=share&utm_medium=web",
    "beige_index": 1,
    "beige_angle": 23.5638,
    "cast": [
      [
        5,
        "Morgan Freeman"
      ],
      [
        5,
        "Alfonso Freeman"
      ],
      [
        5,
        "Mack Miles"
      ],
      [
        5,
        "Cornell Wallace"
      ],
      [
        1,
        "Dion Anderson"
      ],
      [
        1,
        "Ned Bellamy"
      ],
      [
        1,
        "Gil Bellows"
      ],
      [
        1,
        "Renee Blaine"
      ],
      [
        1,
        "Bill Bolender"
      ],
      [
        1,
        "Larry Brandenburg"
      ],
      [
        1,
        "Chuck Brauchler"
      ],
      [
        1,
        "Brian Brophy"
      ],
      [
        1,
        "Clancy Brown"
      ],
      [
        1,
        "Eugene C. DePasquale"
      ],
      [
        1,
        "Jude Ciccolella"
      ],
      [
        1,
        "John D. Craig"
      ],
      [
        1,
        "Brian Delate"
      ],
      [
        1,
        "Jeffrey DeMunn"
      ],
      [
        1,
        "Harold E. Cope Jr."
      ],
      [
        1,
        "John E. Summers"
      ],
      [
        1,
        "Vincent Foster"
      ],
      [
        1,
        "Neil Giuntoli"
      ],
      [
        1,
        "Gordon Greene"
      ],
      [
        1,
        "Bob Gunton"
      ],
      [
        1,
        "Robert Haley"
      ],
      [
        1,
        "John Horton"
      ],
      [
        1,
        "Charlie Kearns"
      ],
      [
        1,
        "Paul Kennedy"
      ],
      [
        1,
        "James Kisicki"
      ],
      [
        1,
        "Gary Lee Davis"
      ],
      [
        1,
        "Brian Libby"
      ],
      [
        1,
        "Morgan Lund"
      ],
      [
        1,
        "George Macready"
      ],
      [
        1,
        "Ken Magee"
      ],
      [
        1,
        "Scott Mann"
      ],
      [
        1,
        "Paul McCrane"
      ],
      [
        1,
        "Don McManus"
      ],
      [
        1,
        "Frank Medrano"
      ],
      [
        1,
        "Ron Newell"
      ],
      [
        1,
        "Joe Pecoraro"
      ],
      [
        1,
        "David Proval"
      ],
      [
        1,
        "Alan R. Kessler"
      ],
      [
        1,
        "John R. Woodward"
      ],
      [
        1,
        "Joseph Ragno"
      ],
      [
        1,
        "Rob Reider"
      ],
      [
        1,
        "Tim Robbins"
      ],
      [
        1,
        "Mark Rolston"
      ],
      [
        1,
        "William Sadler"
      ],
      [
        1,
        "Dorothy Silver"
      ],
      [
        1,
        "Claire Slemmer"
      ],
      [
        1,
        "Dana Snyder"
      ],
      [
        1,
        "Neil Summers"
      ],
      [
        1,
        "Rohn Thomas"
      ],
      [
        1,
        "James Whitmore"
      ],
      [
        1,
        "Donald Zinn"
      ]
    ],
    "directors": "Frank Darabont",
    "budget_string": "25m USD",
    "gross_string": "28.9m USD",
    "metascore_string": "81%"
  },
  {
    "imdb_id": "tt0110912",
    "plot": "The lives of two mob hitmen, a boxer, a gangster and his wife, and a pair of diner bandits intertwine in four tales of violence and redemption.",
    "certification": "R",
    "other_wins_count": 69,
    "other_noms_count": 75,
    "amazon": "https://www.amazon.com/Pulp-Fiction-Blu-ray-Digital-Travolta/dp/B08F6X4MLC/ref=sr_1_1?crid=3QRTTRC55FJFG&amp;keywords=pulp+fiction+blu+ray&amp;qid=1654826242&amp;sprefix=pulp+fiction+%252Caps%252C566&amp;sr=8-1&_encoding=UTF8&tag=sianglu-20&linkCode=ur2&linkId=3220b8054e4e359ff0edde293dd4692d&camp=1789&creative=9325",
    "justwatch": "https://justwatch.com/us/movie/pulp-fiction?utm_source=share&utm_medium=web",
    "beige_index": 2,
    "beige_angle": 42.8481,
    "cast": [
      [
        5,
        "Carl Allen"
      ],
      [
        5,
        "Don Blakely"
      ],
      [
        5,
        "Samuel L. Jackson"
      ],
      [
        5,
        "Phil LaMarr"
      ],
      [
        5,
        "Ving Rhames"
      ],
      [
        5,
        "Venessia Valentino"
      ],
      [
        3,
        "Paul Calderon"
      ],
      [
        3,
        "Angela Jones"
      ],
      [
        2,
        "Karen Maruyama"
      ],
      [
        1,
        "Alexis Arquette"
      ],
      [
        1,
        "Rosanna Arquette"
      ],
      [
        1,
        "Lawrence Bender"
      ],
      [
        1,
        "Brad Blumenthal"
      ],
      [
        1,
        "Steve Buscemi"
      ],
      [
        1,
        "Eric Clark"
      ],
      [
        1,
        "Maria de Medeiros"
      ],
      [
        1,
        "Bronagh Gallagher"
      ],
      [
        1,
        "Michael Gilden"
      ],
      [
        1,
        "Peter Greene"
      ],
      [
        1,
        "Kathy Griffin"
      ],
      [
        1,
        "Susan Griffiths"
      ],
      [
        1,
        "Stephen Hibbert"
      ],
      [
        1,
        "Brenda Hillhouse"
      ],
      [
        1,
        "Linda Kaye"
      ],
      [
        1,
        "Harvey Keitel"
      ],
      [
        1,
        "Lorelei Leslie"
      ],
      [
        1,
        "Chandler Lindauer"
      ],
      [
        1,
        "Laura Lovelace"
      ],
      [
        1,
        "Jerome Patrick Hoban"
      ],
      [
        1,
        "Joseph Pilato"
      ],
      [
        1,
        "Amanda Plummer"
      ],
      [
        1,
        "Tim Roth"
      ],
      [
        1,
        "Robert Ruth"
      ],
      [
        1,
        "Sy Sher"
      ],
      [
        1,
        "Gary Shorelle"
      ],
      [
        1,
        "Emil Sitka"
      ],
      [
        1,
        "Burr Steers"
      ],
      [
        1,
        "Eric Stoltz"
      ],
      [
        1,
        "Julia Sweeney"
      ],
      [
        1,
        "Quentin Tarantino"
      ],
      [
        1,
        "Uma Thurman"
      ],
      [
        1,
        "John Travolta"
      ],
      [
        1,
        "Christopher Walken"
      ],
      [
        1,
        "Frank Whaley"
      ],
      [
        1,
        "Duane Whitaker"
      ],
      [
        1,
        "Bruce Willis"
      ]
    ],
    "directors": "Quentin Tarantino",
    "budget_string": "8m USD",
    "gross_string": "213.9m USD",
    "metascore_string": "94%"
  },
  {
    "imdb_id": "tt0110357",
    "plot": "Lion prince Simba and his father are targeted by his bitter uncle, who wants to ascend the throne himself.",
    "certification": "G",
    "other_wins_count": 38,
    "other_noms_count": 35,
    "amazon": "https://www.amazon.com/Lion-King-Blu-ray-DVD-Digital/dp/B072JXB1XZ/ref=tmm_blu_swatch_0?_encoding=UTF8&amp;qid=1654830466&amp;sr=8-3&_encoding=UTF8&tag=sianglu-20&linkCode=ur2&linkId=6acba18c54e0e99a8f51bddc306ff8a6&camp=1789&creative=9325",
    "justwatch": "https://justwatch.com/us/movie/the-lion-king?utm_source=share&utm_medium=web",
    "beige_index": 2,
    "beige_angle": 56.1429,
    "cast": [
      [
        5,
        "Niketa Calame-Harris"
      ],
      [
        5,
        "James Earl Jones"
      ],
      [
        5,
        "Robert Guillaume"
      ],
      [
        5,
        "Madge Sinclair"
      ],
      [
        4,
        "Whoopi Goldberg"
      ],
      [
        3,
        "Cheech Marin"
      ],
      [
        1,
        "Rowan Atkinson"
      ],
      [
        1,
        "Matthew Broderick"
      ],
      [
        1,
        "Cathy Cavadini"
      ],
      [
        1,
        "Jim Cummings"
      ],
      [
        1,
        "Jeremy Irons"
      ],
      [
        1,
        "Daamen J. Krall"
      ],
      [
        1,
        "Moira Kelly"
      ],
      [
        1,
        "Nathan Lane"
      ],
      [
        1,
        "Zoe Leader"
      ],
      [
        1,
        "Mary Linda Phillips"
      ],
      [
        1,
        "Judi M. Durand"
      ],
      [
        1,
        "David McCharen"
      ],
      [
        1,
        "Ernie Sabella"
      ],
      [
        1,
        "Jonathan Taylor Thomas"
      ],
      [
        1,
        "Frank Welker"
      ]
    ],
    "directors": "Roger Allers & Rob Minkoff",
    "budget_string": "45m USD",
    "gross_string": "1063.6m USD",
    "metascore_string": "88%"
  },
  {
    "imdb_id": "tt0109830",
    "plot": "The presidencies of Kennedy and Johnson, the Vietnam War, the Watergate scandal and other historical events unfold from the perspective of an Alabama man with an IQ of 75, whose only desire is to be reunited with his childhood sweetheart.",
    "certification": "PG-13",
    "other_wins_count": 44,
    "other_noms_count": 75,
    "amazon": "https://www.amazon.com/Forrest-Gump-Blu-ray-Bruce-Moriarty/dp/B07P5P7W82/ref=tmm_blu_swatch_0?_encoding=UTF8&amp;qid=1654826832&amp;sr=8-2&_encoding=UTF8&tag=sianglu-20&linkCode=ur2&linkId=f52e3d07b540cacfa0542c6fd55d3a0c&camp=1789&creative=9325",
    "justwatch": "https://justwatch.com/us/movie/forrest-gump?utm_source=share&utm_medium=web",
    "beige_index": 2,
    "beige_angle": 49.9653,
    "cast": [
      [
        5,
        "W. Benson Terry"
      ],
      [
        5,
        "Michael Burgess"
      ],
      [
        5,
        "Emily Carey"
      ],
      [
        5,
        "Chiffonye Cobb"
      ],
      [
        5,
        "Kevin Davis"
      ],
      [
        5,
        "Calvin Gadsden"
      ],
      [
        5,
        "The Hallelujah Singers of Beaufort South Carolina"
      ],
      [
        5,
        "Lonnie Hamilton"
      ],
      [
        5,
        "Michael Jace"
      ],
      [
        5,
        "Lazarus Jackson"
      ],
      [
        5,
        "Kitty K. Green"
      ],
      [
        5,
        "Grand L. Bush"
      ],
      [
        5,
        "Angela Lomas"
      ],
      [
        5,
        "Michael McFall"
      ],
      [
        5,
        "Byron Minns"
      ],
      [
        5,
        "Margo Moorer"
      ],
      [
        5,
        "Afemo Omilami"
      ],
      [
        5,
        "Timothy Record"
      ],
      [
        5,
        "Gary Robinson"
      ],
      [
        5,
        "Marlena Smalls"
      ],
      [
        5,
        "Rebecca Williams"
      ],
      [
        5,
        "Mykelti Williamson"
      ],
      [
        4,
        "Al Harrington"
      ],
      [
        2,
        "Valentine"
      ],
      [
        2,
        "Teresa Denton"
      ],
      [
        1,
        "Joe Alaskey"
      ],
      [
        1,
        "Sam Anderson"
      ],
      [
        1,
        "Bonnie Ann Burgess"
      ],
      [
        1,
        "Pete Auster"
      ],
      [
        1,
        "Peter Bannon"
      ],
      [
        1,
        "Kenneth Bevington"
      ],
      [
        1,
        "Geoffrey Blake"
      ],
      [
        1,
        "Charles Boswell"
      ],
      [
        1,
        "Jack Bowden"
      ],
      [
        1,
        "Grady Bowman"
      ],
      [
        1,
        "Stephen Bridgewater"
      ],
      [
        1,
        "David Brisbin"
      ],
      [
        1,
        "Daniel C. Striepeke"
      ],
      [
        1,
        "Dick Cavett"
      ],
      [
        1,
        "Hilary Chaplain"
      ],
      [
        1,
        "Michael Conner Humphreys"
      ],
      [
        1,
        "Richard D'Alessandro"
      ],
      [
        1,
        "Hallie D\u2019Amore"
      ],
      [
        1,
        "Ed Davis"
      ],
      [
        1,
        "Steve DeRelian"
      ],
      [
        1,
        "Paulie DiCocco"
      ],
      [
        1,
        "Peter Dobson"
      ],
      [
        1,
        "Nora Dunfee"
      ],
      [
        1,
        "Mary Ellen Trainor"
      ],
      [
        1,
        "Siobhan Fallon Hogan"
      ],
      [
        1,
        "Sally Field"
      ],
      [
        1,
        "Don Fischer"
      ],
      [
        1,
        "Michael Flannery"
      ],
      [
        1,
        "Harold G. Herthum"
      ],
      [
        1,
        "Fay Genens"
      ],
      [
        1,
        "Frank Geyer"
      ],
      [
        1,
        "Aloysius Gigl"
      ],
      [
        1,
        "Jed Gillin"
      ],
      [
        1,
        "Steven Griffith"
      ],
      [
        1,
        "Hanna Hall"
      ],
      [
        1,
        "Tom Hanks"
      ],
      [
        1,
        "Elizabeth Hanks"
      ],
      [
        1,
        "Bob Harks"
      ],
      [
        1,
        "Natalie Henddrix"
      ],
      [
        1,
        "Lenny Herb"
      ],
      [
        1,
        "Aaron Izbicki"
      ],
      [
        1,
        "Daniel J. Gillooly"
      ],
      [
        1,
        "Haley Joel Osment"
      ],
      [
        1,
        "Mike Jolly"
      ],
      [
        1,
        "Christopher Jones"
      ],
      [
        1,
        "George Kelly"
      ],
      [
        1,
        "Michael Kemmerling"
      ],
      [
        1,
        "Rob Landry"
      ],
      [
        1,
        "Logan Livingston Gomez"
      ],
      [
        1,
        "Tyler Long"
      ],
      [
        1,
        "Bruce Lucvia"
      ],
      [
        1,
        "Ione M. Telech"
      ],
      [
        1,
        "Kevin Mangan"
      ],
      [
        1,
        "Mark Matheisen"
      ],
      [
        1,
        "Michael Mattison"
      ],
      [
        1,
        "Dant\u00e9 McCarthy"
      ],
      [
        1,
        "Jason McGuire"
      ],
      [
        1,
        "Timothy McNeil"
      ],
      [
        1,
        "Deborah McTeer"
      ],
      [
        1,
        "Scott Oliver"
      ],
      [
        1,
        "Bob Penny"
      ],
      [
        1,
        "Paul Raczkowski"
      ],
      [
        1,
        "John Randall"
      ],
      [
        1,
        "Matt Rebenkoff"
      ],
      [
        1,
        "Brett Rice"
      ],
      [
        1,
        "Bobby Richardson"
      ],
      [
        1,
        "Bill Roberson"
      ],
      [
        1,
        "Isabel Rose"
      ],
      [
        1,
        "Jay Ross"
      ],
      [
        1,
        "Vanessa Roth"
      ],
      [
        1,
        "Kurt Russell"
      ],
      [
        1,
        "Tiffany Salerno"
      ],
      [
        1,
        "Christine Seabrook"
      ],
      [
        1,
        "Sonny Shroyer"
      ],
      [
        1,
        "Juan Singleton"
      ],
      [
        1,
        "Gary Sinise"
      ],
      [
        1,
        "Joe Stefanelli"
      ],
      [
        1,
        "Dick Stilwell"
      ],
      [
        1,
        "Marla Sucharetza"
      ],
      [
        1,
        "Eric Underwood"
      ],
      [
        1,
        "John Voldstad"
      ],
      [
        1,
        "Ben Waddel"
      ],
      [
        1,
        "Matt Wallace"
      ],
      [
        1,
        "Kirk Ward"
      ],
      [
        1,
        "Joe Washington"
      ],
      [
        1,
        "John William Galt"
      ],
      [
        1,
        "Russ Wilson"
      ],
      [
        1,
        "Jeffrey Winner"
      ],
      [
        1,
        "John Worsham"
      ],
      [
        1,
        "Robin Wright"
      ],
      [
        1,
        "Alexander Zemeckis"
      ]
    ],
    "directors": "Robert Zemeckis",
    "budget_string": "55m USD",
    "gross_string": "678.2m USD",
    "metascore_string": "82%"
  },
  {
    "imdb_id": "tt0110413",
    "plot": "12-year-old Mathilda is reluctantly taken in by L\u00e9on, a professional assassin, after her family is murdered. An unusual relationship forms as she becomes his prot\u00e9g\u00e9e and learns the assassin's trade.",
    "certification": "R",
    "other_wins_count": 6,
    "other_noms_count": 15,
    "amazon": "https://www.amazon.com/L%25C3%25A9on-Professional-Theatrical-Extended-Blu-ray/dp/B002O5M4SA/ref=sr_1_3?crid=32O7RR1KLVJG8&amp;keywords=leon+the+professional&amp;qid=1654830237&amp;sprefix=leon+the+professional%252Caps%252C656&amp;sr=8-3&_encoding=UTF8&tag=sianglu-20&linkCode=ur2&linkId=881619124794b41c2226c90606dc84c5&camp=1789&creative=9325",
    "justwatch": "https://justwatch.com/us/movie/leon-the-professional?utm_source=share&utm_medium=web",
    "beige_index": 2,
    "beige_angle": 45.6003,
    "cast": [
      [
        5,
        "Jernard Burks"
      ],
      [
        5,
        "Abdul Hassan Sharif"
      ],
      [
        5,
        "Tommy Hollis"
      ],
      [
        5,
        "William James Stiggers Jr."
      ],
      [
        5,
        "Crystal Michelle Blake"
      ],
      [
        5,
        "Anthony Ragland"
      ],
      [
        4,
        "Ars\u00e8ne Jiroyan"
      ],
      [
        3,
        "Lucius Wyatt Cherokee"
      ],
      [
        3,
        "Keith A. Glascoe"
      ],
      [
        3,
        "Peter Appel"
      ],
      [
        3,
        "Alex Dezen"
      ],
      [
        3,
        "Samy Naceri"
      ],
      [
        3,
        "Randy Pearlstein"
      ],
      [
        3,
        "Mohammed Rashid"
      ],
      [
        3,
        "Amimul Rolly"
      ],
      [
        3,
        "Sonny Zito"
      ],
      [
        2,
        "Robert LaSardo"
      ],
      [
        2,
        "Cary Wong"
      ],
      [
        2,
        "Wallace Wong"
      ],
      [
        1,
        "Ma\u00efwenn"
      ],
      [
        1,
        "Danny Aiello"
      ],
      [
        1,
        "Marc Andr\u00e9oni"
      ],
      [
        1,
        "Michael Badalucco"
      ],
      [
        1,
        "Geoffrey Bateman"
      ],
      [
        1,
        "Denis Bellocq"
      ],
      [
        1,
        "Luc Bernard"
      ],
      [
        1,
        "Kent Broadhurst"
      ],
      [
        1,
        "Adam Busch"
      ],
      [
        1,
        "Eric Challier"
      ],
      [
        1,
        "Don Creech"
      ],
      [
        1,
        "James Fahrner"
      ],
      [
        1,
        "Ellen Greene"
      ],
      [
        1,
        "Carl J. Matusovich"
      ],
      [
        1,
        "Seth Jerome Walker"
      ],
      [
        1,
        "Jessie Keosian"
      ],
      [
        1,
        "Johnny Limo"
      ],
      [
        1,
        "Peter Linari"
      ],
      [
        1,
        "Joseph Malerba"
      ],
      [
        1,
        "George Martin"
      ],
      [
        1,
        "Thierry Maurio"
      ],
      [
        1,
        "Jeff McBride"
      ],
      [
        1,
        "James Melissinos"
      ],
      [
        1,
        "Betty Miller"
      ],
      [
        1,
        "Michael Mundra"
      ],
      [
        1,
        "Gary Oldman"
      ],
      [
        1,
        "Willi One Blood"
      ],
      [
        1,
        "Danny Peled"
      ],
      [
        1,
        "Natalie Portman"
      ],
      [
        1,
        "Elizabeth Regen"
      ],
      [
        1,
        "Jean Reno"
      ],
      [
        1,
        "Stuart Rudin"
      ],
      [
        1,
        "Keith S. Bullock"
      ],
      [
        1,
        "Tony Sauraye"
      ],
      [
        1,
        "Daniel Schenmetzler"
      ],
      [
        1,
        "Randolph Scott"
      ],
      [
        1,
        "Frank Senger"
      ],
      [
        1,
        "Mario Todisco"
      ],
      [
        1,
        "Ed Ventresca"
      ],
      [
        1,
        "Peter Vizard"
      ],
      [
        1,
        "David W. Butler"
      ]
    ],
    "directors": "Luc Besson",
    "budget_string": "22.1m USD",
    "gross_string": "19.6m USD",
    "metascore_string": "64%"
  },
  {
    "imdb_id": "tt0114814",
    "plot": "A sole survivor tells of the twisty events leading up to a horrific gun battle on a boat, which began when five criminals met at a seemingly random police lineup.",
    "certification": "R",
    "other_wins_count": 35,
    "other_noms_count": 17,
    "amazon": "https://www.amazon.com/Usual-Suspects-Blu-ray-Kevin-Spacey/dp/B000KF0GRC/ref=sr_1_3?crid=1AWPDHTE35FT0&amp;keywords=the+usual+suspects&amp;qid=1654831862&amp;s=movies-tv&amp;sprefix=the+usual+suspects%252Cmovies-tv-intl-ship%252C614&amp;sr=1-3&_encoding=UTF8&tag=sianglu-20&linkCode=ur2&linkId=1d4b8281de3e438a9081a8e0d909ae84&camp=1789&creative=9325",
    "justwatch": "https://justwatch.com/us/movie/usual-suspects-the?utm_source=share&utm_medium=web",
    "beige_index": 1,
    "beige_angle": 21.483,
    "cast": [
      [
        4,
        "Giancarlo Esposito"
      ],
      [
        3,
        "Benicio Del Toro"
      ],
      [
        3,
        "Castulo Guerra"
      ],
      [
        3,
        "Smadar Hanson"
      ],
      [
        3,
        "Peter Rocca"
      ],
      [
        1,
        "Suzy Amis"
      ],
      [
        1,
        "Stephen Baldwin"
      ],
      [
        1,
        "Paul Bartel"
      ],
      [
        1,
        "Carl Bressler"
      ],
      [
        1,
        "Gabriel Byrne"
      ],
      [
        1,
        "Michelle Clunie"
      ],
      [
        1,
        "Vito D'Ambrosio"
      ],
      [
        1,
        "Ken Daly"
      ],
      [
        1,
        "Bob Elmore"
      ],
      [
        1,
        "Christine Estabrook"
      ],
      [
        1,
        "Ron Gilbert"
      ],
      [
        1,
        "Peter Greene"
      ],
      [
        1,
        "Clark Gregg"
      ],
      [
        1,
        "Dan Hedaya"
      ],
      [
        1,
        "Morgan Hunter"
      ],
      [
        1,
        "Louis Lombardi"
      ],
      [
        1,
        "Gene Lythgow"
      ],
      [
        1,
        "Frank Medrano"
      ],
      [
        1,
        "Chazz Palminteri"
      ],
      [
        1,
        "Kevin Pollak"
      ],
      [
        1,
        "Pete Postlethwaite"
      ],
      [
        1,
        "David Powledge"
      ],
      [
        1,
        "Jack Shearer"
      ],
      [
        1,
        "Phillipe Simon"
      ],
      [
        1,
        "Kevin Spacey"
      ],
      [
        1,
        "Bert Williams"
      ]
    ],
    "directors": "Bryan Singer",
    "budget_string": "6m USD",
    "gross_string": "23.3m USD",
    "metascore_string": "77%"
  },
  {
    "imdb_id": "tt0112471",
    "plot": "A young man and woman meet on a train in Europe, and wind up spending one evening together in Vienna. Unfortunately, both know that this will probably be their only night together.",
    "certification": "R",
    "other_wins_count": 1,
    "other_noms_count": 7,
    "amazon": "https://www.amazon.com/Before-Sunrise-Ethan-Hawke/dp/B00002E224/ref=tmm_dvd_swatch_0?_encoding=UTF8&amp;qid=1655182907&amp;sr=8-11&_encoding=UTF8&tag=sianglu-20&linkCode=ur2&linkId=274db466f44095ea0e4f0a7b3d838753&camp=1789&creative=9325",
    "justwatch": "https://justwatch.com/us/movie/before-sunrise?utm_source=share&utm_medium=web",
    "beige_index": 1,
    "beige_angle": 10.2852,
    "cast": [
      [
        4,
        "Bilge Jeschim"
      ],
      [
        3,
        "Kurti"
      ],
      [
        1,
        "Branko Andric"
      ],
      [
        1,
        "Christian Ankowitsch"
      ],
      [
        1,
        "Karl Bruckschwaiger"
      ],
      [
        1,
        "Dominik Castell"
      ],
      [
        1,
        "Julie Delpy"
      ],
      [
        1,
        "Andrea Eckert"
      ],
      [
        1,
        "Hubert Fabian Kulterer"
      ],
      [
        1,
        "Wolfgang Gl\u00fcxam"
      ],
      [
        1,
        "Adam Goldberg"
      ],
      [
        1,
        "Ethan Hawke"
      ],
      [
        1,
        "Peter Ily Huemer"
      ],
      [
        1,
        "Barbara Klebel"
      ],
      [
        1,
        "Liese Lyon"
      ],
      [
        1,
        "Erni Mangold"
      ],
      [
        1,
        "Haymon Maria Buttinger"
      ],
      [
        1,
        "Hanno P\u00f6schl"
      ],
      [
        1,
        "Otto Reiter"
      ],
      [
        1,
        "Wilbirg Reiter"
      ],
      [
        1,
        "Tex Rubinowitz"
      ],
      [
        1,
        "Georg Sch\u00f6llhammer"
      ],
      [
        1,
        "Constanze Schweiger"
      ],
      [
        1,
        "Alexandra Seibel"
      ],
      [
        1,
        "John Sloss"
      ],
      [
        1,
        "Wolfgang Staribacher"
      ],
      [
        1,
        "Harald Waiglein"
      ],
      [
        1,
        "Hans Weingartner"
      ]
    ],
    "directors": "Richard Linklater",
    "budget_string": "2.5m USD",
    "gross_string": "6m USD",
    "metascore_string": "77%"
  },
  {
    "imdb_id": "tt0112641",
    "plot": "A tale of greed, deception, money, power, and murder occur between two best friends: a mafia enforcer and a casino executive compete against each other over a gambling empire, and over a fast-living and fast-loving socialite.",
    "certification": "R",
    "other_wins_count": 4,
    "other_noms_count": 10,
    "amazon": "https://www.amazon.com/Casino-Blu-ray-Robert-Niro/dp/B001EIOOV8/ref=sr_1_3?crid=3NIYQRP7KXCUM&amp;keywords=casino+blu+ray&amp;qid=1655174459&amp;sprefix=casino+blu+ray%252Caps%252C293&amp;sr=8-3&_encoding=UTF8&tag=sianglu-20&linkCode=ur2&linkId=cbc67c053dbe7ef59f00c5bf205d037d&camp=1789&creative=9325",
    "justwatch": "https://justwatch.com/us/movie/casino?utm_source=share&utm_medium=web",
    "beige_index": 1,
    "beige_angle": 6.0876,
    "cast": [
      [
        5,
        "Sly Smith"
      ],
      [
        4,
        "Ali Pirouzkar"
      ],
      [
        3,
        "Khosrow Abrishami"
      ],
      [
        3,
        "Mufid M. Khoury"
      ],
      [
        2,
        "Nobu Matsuhisa"
      ],
      [
        2,
        "Toru Nagai"
      ],
      [
        1,
        "Christian A. Azzinaro"
      ],
      [
        1,
        "Frank Adonis"
      ],
      [
        1,
        "Steve Allen"
      ],
      [
        1,
        "Bill Allison"
      ],
      [
        1,
        "Karyn Amalfitano"
      ],
      [
        1,
        "Richard Amalfitano"
      ],
      [
        1,
        "Joe Anastasi"
      ],
      [
        1,
        "Greg Anderson"
      ],
      [
        1,
        "Dom Angelo"
      ],
      [
        1,
        "David Arcerio"
      ],
      [
        1,
        "Frankie Avalon"
      ],
      [
        1,
        "Jefferey Azzinaro"
      ],
      [
        1,
        "Robert B. Sidell"
      ],
      [
        1,
        "Gino Bertin"
      ],
      [
        1,
        "John Bloom"
      ],
      [
        1,
        "Joseph Bono"
      ],
      [
        1,
        "Mike Bradley"
      ],
      [
        1,
        "Nan Brennan"
      ],
      [
        1,
        "Gary C. Rainey"
      ],
      [
        1,
        "Robert C. Tetzlaff"
      ],
      [
        1,
        "Pasquale Cajano"
      ],
      [
        1,
        "C.C. Carr"
      ],
      [
        1,
        "Clem Caserta"
      ],
      [
        1,
        "Gwen Castaldi"
      ],
      [
        1,
        "J. Charles Thompson"
      ],
      [
        1,
        "Carl Ciarfalio"
      ],
      [
        1,
        "Carrie Cipollini"
      ],
      [
        1,
        "George Comando"
      ],
      [
        1,
        "Roy Conrad"
      ],
      [
        1,
        "Peter Conti"
      ],
      [
        1,
        "Jeff Corbin"
      ],
      [
        1,
        "Dave Courvoisier"
      ],
      [
        1,
        "Rick Crachy"
      ],
      [
        1,
        "Sonny D'Angelo"
      ],
      [
        1,
        "Robert De Niro"
      ],
      [
        1,
        "Janet Denti"
      ],
      [
        1,
        "Joey DePinto"
      ],
      [
        1,
        "Tommy DeVito"
      ],
      [
        1,
        "Gil Dova"
      ],
      [
        1,
        "Larry E. Nadler"
      ],
      [
        1,
        "Haven Earle Haley"
      ],
      [
        1,
        "Richard F. Strafella"
      ],
      [
        1,
        "Oscar Goodman"
      ],
      [
        1,
        "Rudy Guerrero"
      ],
      [
        1,
        "Claudia Haro"
      ],
      [
        1,
        "Paul Herman"
      ],
      [
        1,
        "Bobby Hitt"
      ],
      [
        1,
        "Darla House"
      ],
      [
        1,
        "Charlene Hunter"
      ],
      [
        1,
        "Frankie J. Allison"
      ],
      [
        1,
        "Andy Jarrell"
      ],
      [
        1,
        "L.Q. Jones"
      ],
      [
        1,
        "Heidi Keller"
      ],
      [
        1,
        "Tyd Kierney"
      ],
      [
        1,
        "Alan King"
      ],
      [
        1,
        "Mitch Kolpan"
      ],
      [
        1,
        "Jonathan Kraft"
      ],
      [
        1,
        "Jed L. Hansen"
      ],
      [
        1,
        "Joe La Due"
      ],
      [
        1,
        "Joe Lacoco"
      ],
      [
        1,
        "Brian Le Baron"
      ],
      [
        1,
        "Ffolliott Le Coque"
      ],
      [
        1,
        "David Leavitt"
      ],
      [
        1,
        "Jennifer M. Abbott"
      ],
      [
        1,
        "Ronald Maccone"
      ],
      [
        1,
        "Csaba Maczala"
      ],
      [
        1,
        "Mike Maines"
      ],
      [
        1,
        "John Manca"
      ],
      [
        1,
        "F. Marcus Casper"
      ],
      [
        1,
        "Nick Mazzola"
      ],
      [
        1,
        "Bret McCormick"
      ],
      [
        1,
        "Michael McKensie Pratt"
      ],
      [
        1,
        "Jayne Meadows"
      ],
      [
        1,
        "Jed Mills"
      ],
      [
        1,
        "Cameron Milzer"
      ],
      [
        1,
        "Casper Molee"
      ],
      [
        1,
        "Joe Molinaro"
      ],
      [
        1,
        "Jim Morgan Williams"
      ],
      [
        1,
        "Stuart Nisbet"
      ],
      [
        1,
        "Alfred Nittoli"
      ],
      [
        1,
        "Paige Novodor"
      ],
      [
        1,
        "Jack Orend"
      ],
      [
        1,
        "Daniel P. Conte"
      ],
      [
        1,
        "Joseph P. Reidy"
      ],
      [
        1,
        "Madeline Parquette"
      ],
      [
        1,
        "Michael Paskevich"
      ],
      [
        1,
        "Linda Perri"
      ],
      [
        1,
        "Joe Pesci"
      ],
      [
        1,
        "Salvatore Petrillo"
      ],
      [
        1,
        "Kevin Pollak"
      ],
      [
        1,
        "Melissa Prophet"
      ],
      [
        1,
        "Eric Randall"
      ],
      [
        1,
        "Brian Reddy"
      ],
      [
        1,
        "Shellee Renee"
      ],
      [
        1,
        "Don Rickles"
      ],
      [
        1,
        "Richard Riehle"
      ],
      [
        1,
        "Joseph Rigano"
      ],
      [
        1,
        "David Rose"
      ],
      [
        1,
        "Gene Ruffini"
      ],
      [
        1,
        "Anthony Russell"
      ],
      [
        1,
        "Herb Schwartz"
      ],
      [
        1,
        "Cathy Scorsese"
      ],
      [
        1,
        "Catherine Scorsese"
      ],
      [
        1,
        "Jeff Scott Anderson"
      ],
      [
        1,
        "Sasha Semenoff"
      ],
      [
        1,
        "Millicent Sheridan"
      ],
      [
        1,
        "Fred Smith"
      ],
      [
        1,
        "Dick Smothers"
      ],
      [
        1,
        "Buck Stephens"
      ],
      [
        1,
        "Loren Stevens"
      ],
      [
        1,
        "Sharon Stone"
      ],
      [
        1,
        "Peter Sugden"
      ],
      [
        1,
        "Philip Suriano"
      ],
      [
        1,
        "Randy Sutton"
      ],
      [
        1,
        "Richard T. Smith"
      ],
      [
        1,
        "Jerry Vale"
      ],
      [
        1,
        "David Varriale"
      ],
      [
        1,
        "Leain Vashon"
      ],
      [
        1,
        "Vinny Vella"
      ],
      [
        1,
        "Steve Vignari"
      ],
      [
        1,
        "Craig Vincent"
      ],
      [
        1,
        "Frank Vincent"
      ],
      [
        1,
        "Erika von Tagen"
      ],
      [
        1,
        "George W. Allf"
      ],
      [
        1,
        "Richard Wagner"
      ],
      [
        1,
        "Frank Washko Jr."
      ],
      [
        1,
        "Mike Weatherford"
      ],
      [
        1,
        "Carol Wilson"
      ],
      [
        1,
        "James Woods"
      ],
      [
        1,
        "Mortiki Yerushalmi"
      ]
    ],
    "directors": "Martin Scorsese",
    "budget_string": "52m USD",
    "gross_string": "116.1m USD",
    "metascore_string": "73%"
  },
  {
    "imdb_id": "tt0114709",
    "plot": "A cowboy doll is profoundly threatened and jealous when a new spaceman figure supplants him as top toy in a boy's room.",
    "certification": "G",
    "other_wins_count": 27,
    "other_noms_count": 20,
    "amazon": "https://www.amazon.com/Story-Special-Blu-ray-Region-Free/dp/B0071AMD98/ref=tmm_blu_title_0?_encoding=UTF8&amp;qid=1654843192&amp;sr=1-19&_encoding=UTF8&tag=sianglu-20&linkCode=ur2&linkId=a408e7101332e9ea3f072a4afd9f8739&camp=1789&creative=9325",
    "justwatch": "https://justwatch.com/us/movie/toy-story?utm_source=share&utm_medium=web",
    "beige_index": 1,
    "beige_angle": 0.0,
    "cast": [
      [
        1,
        "Tim Allen"
      ],
      [
        1,
        "Spencer Aste"
      ],
      [
        1,
        "Greg Berg"
      ],
      [
        1,
        "Lisa Bradley"
      ],
      [
        1,
        "Kendall Cunningham"
      ],
      [
        1,
        "Cody Dorkin"
      ],
      [
        1,
        "Bill Farmer"
      ],
      [
        1,
        "Craig Good"
      ],
      [
        1,
        "Gregory Grudt"
      ],
      [
        1,
        "Tom Hanks"
      ],
      [
        1,
        "Penn Jillette"
      ],
      [
        1,
        "Danielle Judovits"
      ],
      [
        1,
        "Sam Lasseter"
      ],
      [
        1,
        "R. Lee Ermey"
      ],
      [
        1,
        "Brittany Levenbrown"
      ],
      [
        1,
        "Scott McAfee"
      ],
      [
        1,
        "Laurie Metcalf"
      ],
      [
        1,
        "John Morris"
      ],
      [
        1,
        "Ryan O'Donohue"
      ],
      [
        1,
        "Jeff Pidgeon"
      ],
      [
        1,
        "Patrick Pinney"
      ],
      [
        1,
        "Annie Potts"
      ],
      [
        1,
        "Jan Rabson"
      ],
      [
        1,
        "Joe Ranft"
      ],
      [
        1,
        "John Ratzenberger"
      ],
      [
        1,
        "Sarah Rayne"
      ],
      [
        1,
        "Don Rickles"
      ],
      [
        1,
        "Wallace Shawn"
      ],
      [
        1,
        "Andrew Stanton"
      ],
      [
        1,
        "Shane Sweet"
      ],
      [
        1,
        "Jim Varney"
      ],
      [
        1,
        "Erik von Detten"
      ]
    ],
    "directors": "John Lasseter",
    "budget_string": "30m USD",
    "gross_string": "394.4m USD",
    "metascore_string": "95%"
  },
  {
    "imdb_id": "tt0113247",
    "plot": "24 hours in the lives of three young men in the French suburbs the day after a violent riot.",
    "certification": "Not Rated",
    "other_wins_count": 8,
    "other_noms_count": 15,
    "amazon": "https://www.amazon.com/LA-HAINE/dp/B0711JH57H/ref=tmm_dvd_title_0?_encoding=UTF8&amp;qid=1655193891&amp;sr=1-2-catcorr&_encoding=UTF8&tag=sianglu-20&linkCode=ur2&linkId=671522e260fac14ab5305a6f6a7039cb&camp=1789&creative=9325",
    "justwatch": "https://justwatch.com/us/movie/la-haine?utm_source=share&utm_medium=web",
    "beige_index": 2,
    "beige_angle": 58.14,
    "cast": [
      [
        5,
        "Solo"
      ],
      [
        5,
        "Jos\u00e9 Dalmat"
      ],
      [
        5,
        "Hubert Kound\u00e9"
      ],
      [
        5,
        "Joseph Momo"
      ],
      [
        5,
        "Edouard Montoute"
      ],
      [
        5,
        "Fatou Thioune"
      ],
      [
        5,
        "F\u00e9licit\u00e9 Wouassi"
      ],
      [
        4,
        "Abdel Ahmed Ghili"
      ],
      [
        4,
        "Karim Belkhadra"
      ],
      [
        4,
        "Abdel-Moulah Boujdouni"
      ],
      [
        4,
        "Choukri Gabteni"
      ],
      [
        4,
        "Sabrina Houicha"
      ],
      [
        4,
        "Cut Killer"
      ],
      [
        4,
        "Sa\u00efd Taghmaoui"
      ],
      [
        3,
        "JiBi"
      ],
      [
        3,
        "Samir Khelif"
      ],
      [
        3,
        "Julie Mauduech"
      ],
      [
        3,
        "Zinedine Soualem"
      ],
      [
        2,
        "Thang-Long"
      ],
      [
        1,
        "Olga Abrego"
      ],
      [
        1,
        "Cyril Ancelin"
      ],
      [
        1,
        "Nabil Ben Mhamed"
      ],
      [
        1,
        "Bernie Bonvoisin"
      ],
      [
        1,
        "Vincent Cassel"
      ],
      [
        1,
        "Andr\u00e9e Damant"
      ],
      [
        1,
        "Marc Duret"
      ],
      [
        1,
        "Peter Kassovitz"
      ],
      [
        1,
        "Mathieu Kassovitz"
      ],
      [
        1,
        "Laurent Labasse"
      ],
      [
        1,
        "Florent Lavandeira"
      ],
      [
        1,
        "Fran\u00e7ois Levantal"
      ],
      [
        1,
        "Vincent Lindon"
      ],
      [
        1,
        "Tadek Lokcinski"
      ],
      [
        1,
        "Beno\u00eet Magimel"
      ],
      [
        1,
        "Marcel Marondo"
      ],
      [
        1,
        "Teddy Marques"
      ],
      [
        1,
        "Patrick M\u00e9dioni"
      ],
      [
        1,
        "Virginie Montel"
      ],
      [
        1,
        "Christian Moro"
      ],
      [
        1,
        "Philippe Nahon"
      ],
      [
        1,
        "Eric Pujol"
      ],
      [
        1,
        "H\u00e9lo\u00efse Rauth"
      ],
      [
        1,
        "Christophe Rossignon"
      ],
      [
        1,
        "Anthony Souter"
      ],
      [
        1,
        "S\u00e9bastien Tavel"
      ],
      [
        1,
        "Fran\u00e7ois Toumarkine"
      ],
      [
        1,
        "Karin Viard"
      ],
      [
        1,
        "Mathilde Vitry"
      ],
      [
        1,
        "Rywka Wajsbrot"
      ],
      [
        1,
        "Sandor Weltmann"
      ]
    ],
    "directors": "Mathieu Kassovitz",
    "budget_string": "3.5m USD",
    "gross_string": "0.4m USD",
    "metascore_string": "N/A"
  },
  {
    "imdb_id": "tt0113277",
    "plot": "A group of high-end professional thieves start to feel the heat from the LAPD when they unknowingly leave a clue at their latest heist.",
    "certification": "R",
    "other_wins_count": 0,
    "other_noms_count": 14,
    "amazon": "https://www.amazon.com/Heat-Jon-Voight/dp/B06XS9TPYR/ref=tmm_blu_title_0?_encoding=UTF8&amp;qid=1655007937&amp;sr=8-1&_encoding=UTF8&tag=sianglu-20&linkCode=ur2&linkId=f78ef88dc5feca9e44e5d25ad99384db&camp=1789&creative=9325",
    "justwatch": "https://justwatch.com/us/movie/heat-1995?utm_source=share&utm_medium=web",
    "beige_index": 2,
    "beige_angle": 47.286,
    "cast": [
      [
        5,
        "Hazelle Goodman"
      ],
      [
        5,
        "Niki Haris"
      ],
      [
        5,
        "Ricky Harris"
      ],
      [
        5,
        "Tone Loc"
      ],
      [
        5,
        "Dan Martin"
      ],
      [
        5,
        "Rainelle Saunders"
      ],
      [
        5,
        "Kim Staunton"
      ],
      [
        5,
        "Mykelti Williamson"
      ],
      [
        4,
        "Kenny Endoso"
      ],
      [
        4,
        "Kai Soremekun"
      ],
      [
        3,
        "Begonya Plaza"
      ],
      [
        3,
        "Wes Studi"
      ],
      [
        3,
        "Danny Trejo"
      ],
      [
        3,
        "Rick Marzan"
      ],
      [
        3,
        "Thomas Rosales Jr."
      ],
      [
        3,
        "Rey Verdugo"
      ],
      [
        1,
        "Rick Avery"
      ],
      [
        1,
        "Hank Azaria"
      ],
      [
        1,
        "Brad Baldridge"
      ],
      [
        1,
        "Xander Berkeley"
      ],
      [
        1,
        "Amy Brenneman"
      ],
      [
        1,
        "Ray Buktenica"
      ],
      [
        1,
        "Brian Camuccio"
      ],
      [
        1,
        "Andrew Camuccio"
      ],
      [
        1,
        "Max Daniels"
      ],
      [
        1,
        "Robert De Niro"
      ],
      [
        1,
        "Vince Deadrick Jr."
      ],
      [
        1,
        "Thomas Elfmont"
      ],
      [
        1,
        "Martin Ferrero"
      ],
      [
        1,
        "William Fichtner"
      ],
      [
        1,
        "Kimberly Flynn"
      ],
      [
        1,
        "Steven Ford"
      ],
      [
        1,
        "Farrah Forke"
      ],
      [
        1,
        "Hannes Fritsch"
      ],
      [
        1,
        "Kevin Gage"
      ],
      [
        1,
        "Amanda Graves"
      ],
      [
        1,
        "Emily Graves"
      ],
      [
        1,
        "Ted Harvey"
      ],
      [
        1,
        "Dennis Haysbert"
      ],
      [
        1,
        "Patricia Healy"
      ],
      [
        1,
        "Paul Herman"
      ],
      [
        1,
        "Ashley Judd"
      ],
      [
        1,
        "Cindy Katz"
      ],
      [
        1,
        "Val Kilmer"
      ],
      [
        1,
        "Wendy L. Walsh"
      ],
      [
        1,
        "Ted Levine"
      ],
      [
        1,
        "Brian Libby"
      ],
      [
        1,
        "Bill McIntosh"
      ],
      [
        1,
        "Terry Miller"
      ],
      [
        1,
        "Paul Moyer"
      ],
      [
        1,
        "Tom Noonan"
      ],
      [
        1,
        "Daniel O'Haco"
      ],
      [
        1,
        "Al Pacino"
      ],
      [
        1,
        "Jeremy Piven"
      ],
      [
        1,
        "Natalie Portman"
      ],
      [
        1,
        "Mario Roberts"
      ],
      [
        1,
        "Henry Rollins"
      ],
      [
        1,
        "Tom Sizemore"
      ],
      [
        1,
        "Susan Traylor"
      ],
      [
        1,
        "Jerry Trimble"
      ],
      [
        1,
        "Diane Venora"
      ],
      [
        1,
        "Jon Voight"
      ],
      [
        1,
        "Yvonne Zima"
      ]
    ],
    "directors": "Michael Mann",
    "budget_string": "60m USD",
    "gross_string": "187.4m USD",
    "metascore_string": "76%"
  },
  {
    "imdb_id": "tt0112573",
    "plot": "Scottish warrior William Wallace leads his countrymen in a rebellion to free his homeland from the tyranny of King Edward I of England.",
    "certification": "R",
    "other_wins_count": 28,
    "other_noms_count": 34,
    "amazon": "https://www.amazon.com/Braveheart-Blu-ray-Mel-Gibson/dp/B01M7SPXFV/ref=sr_1_5?crid=1DZFKESOEB8WL&amp;keywords=braveheart&amp;qid=1654843042&amp;s=movies-tv&amp;sprefix=bravehear%252Cmovies-tv%252C933&amp;sr=1-5&_encoding=UTF8&tag=sianglu-20&linkCode=ur2&linkId=0dacb509f40ec308f77457e580223f46&camp=1789&creative=9325",
    "justwatch": "https://justwatch.com/us/movie/oldboy?utm_source=share&utm_medium=web",
    "beige_index": 1,
    "beige_angle": 0.0,
    "cast": [
      [
        1,
        "Alun Armstrong"
      ],
      [
        1,
        "Julie Austin"
      ],
      [
        1,
        "Ian Bannen"
      ],
      [
        1,
        "Joanne Bett"
      ],
      [
        1,
        "Stephen Billington"
      ],
      [
        1,
        "Michael Byrne"
      ],
      [
        1,
        "Mhairi Calvey"
      ],
      [
        1,
        "Liam Carney"
      ],
      [
        1,
        "Jimmy Chisholm"
      ],
      [
        1,
        "Fred Chiverton"
      ],
      [
        1,
        "Daniel Coll"
      ],
      [
        1,
        "James Cosmo"
      ],
      [
        1,
        "Brian Cox"
      ],
      [
        1,
        "Martin Dempsey"
      ],
      [
        1,
        "Martin Dunne"
      ],
      [
        1,
        "Tommy Flanagan"
      ],
      [
        1,
        "David Gant"
      ],
      [
        1,
        "Donal Gibson"
      ],
      [
        1,
        "Mel Gibson"
      ],
      [
        1,
        "Brendan Gleeson"
      ],
      [
        1,
        "Peter Hanly"
      ],
      [
        1,
        "Bernard Horsfall"
      ],
      [
        1,
        "John Kavanagh"
      ],
      [
        1,
        "Phil Kelly"
      ],
      [
        1,
        "Jimmy Keogh"
      ],
      [
        1,
        "Sean Lawlor"
      ],
      [
        1,
        "Richard Leaf"
      ],
      [
        1,
        "Dean Lopata"
      ],
      [
        1,
        "Angus Macfadyen"
      ],
      [
        1,
        "Sophie Marceau"
      ],
      [
        1,
        "Jeanne Marine"
      ],
      [
        1,
        "Catherine McCormack"
      ],
      [
        1,
        "Sean McGinley"
      ],
      [
        1,
        "Patrick McGoohan"
      ],
      [
        1,
        "Barry McGovern"
      ],
      [
        1,
        "David McKay"
      ],
      [
        1,
        "Gerard McSorley"
      ],
      [
        1,
        "Peter Mullan"
      ],
      [
        1,
        "Bill Murdoch"
      ],
      [
        1,
        "Martin Murphy"
      ],
      [
        1,
        "John Murtagh"
      ],
      [
        1,
        "Sandy Nelson"
      ],
      [
        1,
        "Alex Norton"
      ],
      [
        1,
        "Niall O'Brien"
      ],
      [
        1,
        "David O'Hara"
      ],
      [
        1,
        "Jer O'Leary"
      ],
      [
        1,
        "Robert Paterson"
      ],
      [
        1,
        "Ralph Riach"
      ],
      [
        1,
        "James Robinson"
      ],
      [
        1,
        "Joe Savino"
      ],
      [
        1,
        "William Scott-Masson"
      ],
      [
        1,
        "Gerda Stevenson"
      ],
      [
        1,
        "Alan Tall"
      ],
      [
        1,
        "Malcolm Tierney"
      ],
      [
        1,
        "Paul Tucker"
      ],
      [
        1,
        "Rupert Vansittart"
      ],
      [
        1,
        "Andrew Weir"
      ],
      [
        1,
        "Tam White"
      ],
      [
        1,
        "Mal Whyte"
      ]
    ],
    "directors": "Mel Gibson",
    "budget_string": "72m USD",
    "gross_string": "213.2m USD",
    "metascore_string": "68%"
  },
  {
    "imdb_id": "tt0114369",
    "plot": "Two detectives, a rookie and a veteran, hunt a serial killer who uses the seven deadly sins as his motives.",
    "certification": "R",
    "other_wins_count": 29,
    "other_noms_count": 42,
    "amazon": "https://www.amazon.com/Seven-Blu-ray-Andrew-Kevin-Walker/dp/B004XKVPG6/ref=tmm_blu_swatch_0?_encoding=UTF8&amp;qid=1654827745&amp;sr=1-1&_encoding=UTF8&tag=sianglu-20&linkCode=ur2&linkId=80b16a558ff4ff3109f69a2fa8c7cb9d&camp=1789&creative=9325",
    "justwatch": "https://justwatch.com/us/movie/se7en?utm_source=share&utm_medium=web",
    "beige_index": 2,
    "beige_angle": 63.9819,
    "cast": [
      [
        5,
        "Lexie Bigham"
      ],
      [
        5,
        "Ron Blair"
      ],
      [
        5,
        "Beverly Burke"
      ],
      [
        5,
        "John C. McGinley"
      ],
      [
        5,
        "Bob Collins"
      ],
      [
        5,
        "David Correia"
      ],
      [
        5,
        "Jimmy Dale Hartsell"
      ],
      [
        5,
        "William Davidson"
      ],
      [
        5,
        "Reg E. Cathey"
      ],
      [
        5,
        "Morgan Freeman"
      ],
      [
        5,
        "Alfonso Freeman"
      ],
      [
        5,
        "Hawthorne James"
      ],
      [
        5,
        "Dominique Jennings"
      ],
      [
        5,
        "Richard Roundtree"
      ],
      [
        5,
        "Pamala Tyson"
      ],
      [
        3,
        "Daniel Zacapa"
      ],
      [
        2,
        "Charline Su"
      ],
      [
        1,
        "Charles A. Tamburro"
      ],
      [
        1,
        "Julie Araskog"
      ],
      [
        1,
        "Richmond Arquette"
      ],
      [
        1,
        "Mark Boone Junior"
      ],
      [
        1,
        "Gene Borkan"
      ],
      [
        1,
        "John Cassini"
      ],
      [
        1,
        "George Christy"
      ],
      [
        1,
        "Peter Crombie"
      ],
      [
        1,
        "James Deeth"
      ],
      [
        1,
        "Mario Di Donato"
      ],
      [
        1,
        "Paul Eckstein"
      ],
      [
        1,
        "Brian Evers"
      ],
      [
        1,
        "Rachel Flanagan"
      ],
      [
        1,
        "Duffy Gaver"
      ],
      [
        1,
        "Endre Hules"
      ],
      [
        1,
        "Andrew Kevin Walker"
      ],
      [
        1,
        "Allan Kolman"
      ],
      [
        1,
        "R. Lee Ermey"
      ],
      [
        1,
        "Lennie Loftin"
      ],
      [
        1,
        "Bob Mack"
      ],
      [
        1,
        "Michael Massee"
      ],
      [
        1,
        "Evan Mirand"
      ],
      [
        1,
        "Jennifer Mueller"
      ],
      [
        1,
        "Leland Orser"
      ],
      [
        1,
        "Gwyneth Paltrow"
      ],
      [
        1,
        "Brad Pitt"
      ],
      [
        1,
        "Richard Portnow"
      ],
      [
        1,
        "Michael Reid MacKay"
      ],
      [
        1,
        "Sarah Reinhardt"
      ],
      [
        1,
        "Harris Savides"
      ],
      [
        1,
        "Heidi Schanz"
      ],
      [
        1,
        "Richard Schiff"
      ],
      [
        1,
        "Martin Serene"
      ],
      [
        1,
        "Kevin Spacey"
      ],
      [
        1,
        "Bob Stephenson"
      ],
      [
        1,
        "Emily Wagner"
      ],
      [
        1,
        "Harrison White"
      ],
      [
        1,
        "Shannon Wilcox"
      ]
    ],
    "directors": "David Fincher",
    "budget_string": "33m USD",
    "gross_string": "327.3m USD",
    "metascore_string": "65%"
  },
  {
    "imdb_id": "tt0117951",
    "plot": "Renton, deeply immersed in the Edinburgh drug scene, tries to clean up and get out, despite the allure of the drugs and influence of friends.",
    "certification": "R",
    "other_wins_count": 23,
    "other_noms_count": 34,
    "amazon": "https://www.amazon.com/T2-Trainspotting-Blu-ray-Ewen-Bremner/dp/B06XSTX63Z/ref=tmm_blu_swatch_0?_encoding=UTF8&amp;qid=1655181487&amp;sr=8-2&_encoding=UTF8&tag=sianglu-20&linkCode=ur2&linkId=38c908b4debb1288fe54838e60933764&camp=1789&creative=9325",
    "justwatch": "https://justwatch.com/us/movie/trainspotting?utm_source=share&utm_medium=web",
    "beige_index": 1,
    "beige_angle": 12.4614,
    "cast": [
      [
        5,
        "Eddie Nestor"
      ],
      [
        1,
        "Keith Allen"
      ],
      [
        1,
        "Kevin Allen"
      ],
      [
        1,
        "Fiona Bell"
      ],
      [
        1,
        "Ewen Bremner"
      ],
      [
        1,
        "Robert Carlyle"
      ],
      [
        1,
        "James Cosmo"
      ],
      [
        1,
        "Kate Donnelly"
      ],
      [
        1,
        "Victor Eadie"
      ],
      [
        1,
        "Vincent Friell"
      ],
      [
        1,
        "Shirley Henderson"
      ],
      [
        1,
        "Jonny Lee Miller"
      ],
      [
        1,
        "Annie Louise Ross"
      ],
      [
        1,
        "Pauline Lynch"
      ],
      [
        1,
        "Kelly Macdonald"
      ],
      [
        1,
        "Ewan McGregor"
      ],
      [
        1,
        "Kevin McKidd"
      ],
      [
        1,
        "Stuart McQuarrie"
      ],
      [
        1,
        "Peter Mullan"
      ],
      [
        1,
        "Eileen Nicholas"
      ],
      [
        1,
        "Billy Riddoch"
      ],
      [
        1,
        "Hugh Ross"
      ],
      [
        1,
        "Susan Vidler"
      ],
      [
        1,
        "Irvine Welsh"
      ],
      [
        1,
        "Finlay Welsh"
      ],
      [
        1,
        "Dale Winton"
      ]
    ],
    "directors": "Danny Boyle",
    "budget_string": "2.3m USD",
    "gross_string": "16.8m USD",
    "metascore_string": "83%"
  },
  {
    "imdb_id": "tt0116282",
    "plot": "Minnesota car salesman Jerry Lundegaard's inept crime falls apart due to his and his henchmen's bungling and the persistent police work of the quite pregnant Marge Gunderson.",
    "certification": "R",
    "other_wins_count": 81,
    "other_noms_count": 58,
    "amazon": "https://www.amazon.com/Fargo-Blu-ray-William-H-Macy/dp/B00I83WJBY/ref=sr_1_2?crid=1B9W2FRMVBZNR&amp;keywords=fargo+blu+ray&amp;qid=1655181866&amp;sprefix=fargo+blu+ray%252Caps%252C295&amp;sr=8-2&_encoding=UTF8&tag=sianglu-20&linkCode=ur2&linkId=918c05438b05493b3389e5089a4447ba&camp=1789&creative=9325",
    "justwatch": "https://justwatch.com/us/movie/fargo?utm_source=share&utm_medium=web",
    "beige_index": 1,
    "beige_angle": 26.0532,
    "cast": [
      [
        5,
        "Sharon Anderson"
      ],
      [
        5,
        "David S. Lomax"
      ],
      [
        3,
        "Jos\u00e9 Feliciano"
      ],
      [
        3,
        "Steve Reevis"
      ],
      [
        2,
        "Steve Park"
      ],
      [
        1,
        "Wayne A. Evenson"
      ],
      [
        1,
        "John Bandemer"
      ],
      [
        1,
        "Petra Boden"
      ],
      [
        1,
        "Bain Boehlke"
      ],
      [
        1,
        "Bruce Bohne"
      ],
      [
        1,
        "Larry Brandenburg"
      ],
      [
        1,
        "Steve Buscemi"
      ],
      [
        1,
        "John Carroll Lynch"
      ],
      [
        1,
        "Tony Denman"
      ],
      [
        1,
        "Steve Edelman"
      ],
      [
        1,
        "James Gaulke"
      ],
      [
        1,
        "William H. Macy"
      ],
      [
        1,
        "Gary Houston"
      ],
      [
        1,
        "Michelle Hutchison"
      ],
      [
        1,
        "Steven I. Schafer"
      ],
      [
        1,
        "Warren Keith"
      ],
      [
        1,
        "Larissa Kokernot"
      ],
      [
        1,
        "Frances McDormand"
      ],
      [
        1,
        "Robert Ozasky"
      ],
      [
        1,
        "Melissa Peterman"
      ],
      [
        1,
        "Harve Presnell"
      ],
      [
        1,
        "Cliff Rakerd"
      ],
      [
        1,
        "Kristin Rudr\u00fcd"
      ],
      [
        1,
        "Peter Schmitz"
      ],
      [
        1,
        "Kurt Schweickhardt"
      ],
      [
        1,
        "Jessica Shepherd"
      ],
      [
        1,
        "Bix Skahill"
      ],
      [
        1,
        "Rose Stockton"
      ],
      [
        1,
        "Peter Stormare"
      ],
      [
        1,
        "Michelle Suzanne LeDoux"
      ],
      [
        1,
        "J. Todd Anderson"
      ],
      [
        1,
        "Don Wescott"
      ],
      [
        1,
        "Sally Wingert"
      ]
    ],
    "directors": "Joel Coen & Ethan Coen",
    "budget_string": "7m USD",
    "gross_string": "60.6m USD",
    "metascore_string": "85%"
  },
  {
    "imdb_id": "tt0119698",
    "plot": "On a journey to find the cure for a Tatarigami's curse, Ashitaka finds himself in the middle of a war between the forest gods and Tatara, a mining colony. In this quest he also meets San, the Mononoke Hime.",
    "certification": "PG-13",
    "other_wins_count": 14,
    "other_noms_count": 7,
    "amazon": "https://www.amazon.com/Princess-Mononoke-Bluray-Combo-Blu-ray/dp/B073ZWK3FK/ref=sr_1_2?crid=3JJFDLJ5ZJDVC&amp;keywords=princess+mononoke&amp;qid=1654843692&amp;s=movies-tv&amp;sprefix=princess+mononoke%252Cmovies-tv%252C940&amp;sr=1-2&_encoding=UTF8&tag=sianglu-20&linkCode=ur2&linkId=23d1892252f4b23dc2c4b6b7c6f54b15&camp=1789&creative=9325",
    "justwatch": "https://justwatch.com/us/movie/princess-mononoke?utm_source=share&utm_medium=web",
    "beige_index": 2,
    "beige_angle": 45.0,
    "cast": [
      [
        5,
        "Keith David"
      ],
      [
        2,
        "Eriko Ando"
      ],
      [
        2,
        "Yoshiaki Arai"
      ],
      [
        2,
        "Jennifer Cihi"
      ],
      [
        2,
        "Takako Fuji"
      ],
      [
        2,
        "Naoya Fujimaki"
      ],
      [
        2,
        "Kota Fukazawa"
      ],
      [
        2,
        "Daigo Fukunaga"
      ],
      [
        2,
        "Nobuyoshi Harada"
      ],
      [
        2,
        "Shigeto Ho"
      ],
      [
        2,
        "Kei Iinuma"
      ],
      [
        2,
        "Kiho Iinuma"
      ],
      [
        2,
        "Yuriko Ishida"
      ],
      [
        2,
        "Leslie Ishii"
      ],
      [
        2,
        "Fumi Kakuta"
      ],
      [
        2,
        "Tsunehiko Kamij\u00f4"
      ],
      [
        2,
        "Yuko Kashima"
      ],
      [
        2,
        "Eiji Kato"
      ],
      [
        2,
        "Yayoi Kazuki"
      ],
      [
        2,
        "Kazuya Kobayashi"
      ],
      [
        2,
        "Kaoru Kobayashi"
      ],
      [
        2,
        "Yoshimasa Kond\u00f4"
      ],
      [
        2,
        "Tomikazu Kuwabara"
      ],
      [
        2,
        "Yoshiaki Masuda"
      ],
      [
        2,
        "Y\u00f4ji Matsuda"
      ],
      [
        2,
        "Y\u00fbki Matsuda"
      ],
      [
        2,
        "Takashi Matsuyama"
      ],
      [
        2,
        "Akihiro Miwa"
      ],
      [
        2,
        "Shigenobu Miyake"
      ],
      [
        2,
        "Mitsuko Mori"
      ],
      [
        2,
        "Hisaya Morishige"
      ],
      [
        2,
        "Kazuyo Murata"
      ],
      [
        2,
        "Akira Nagoya"
      ],
      [
        2,
        "Katsutoshi Nagura"
      ],
      [
        2,
        "Akio Nakamura"
      ],
      [
        2,
        "Miyuki Nikaido"
      ],
      [
        2,
        "Masahiko Nishimura"
      ],
      [
        2,
        "Hiroki Ochi"
      ],
      [
        2,
        "Nobuhisa Okazaki"
      ],
      [
        2,
        "Tetsuo Omi"
      ],
      [
        2,
        "Ryota Ono"
      ],
      [
        2,
        "Kimihiro Reizei"
      ],
      [
        2,
        "Shir\u00f4 Sait\u00f4"
      ],
      [
        2,
        "Akira Sakamoto"
      ],
      [
        2,
        "Shinobu Sakashita"
      ],
      [
        2,
        "Mitsuru Sat\u00f4"
      ],
      [
        2,
        "Masahiko Seno"
      ],
      [
        2,
        "Sumi Shimamoto"
      ],
      [
        2,
        "Daikichi Sugawara"
      ],
      [
        2,
        "Kazue Sugiura"
      ],
      [
        2,
        "Toshio Suzuki"
      ],
      [
        2,
        "Riri Tajima"
      ],
      [
        2,
        "Saori Takatsuki"
      ],
      [
        2,
        "Y\u00fbko Tanaka"
      ],
      [
        2,
        "Kenji Tashiro"
      ],
      [
        2,
        "Shinji Tokumaru"
      ],
      [
        2,
        "Akihiko Tonosaki"
      ],
      [
        2,
        "Keiko Tsukamoto"
      ],
      [
        2,
        "Kazuyo Uekusa"
      ],
      [
        2,
        "Fumio Ukibe"
      ],
      [
        2,
        "Shinichi Watanabe"
      ],
      [
        2,
        "Tetsu Watanabe"
      ],
      [
        2,
        "Ikuko Yamamoto"
      ],
      [
        2,
        "Michiko Yamamoto"
      ],
      [
        2,
        "Kinu Yoshimi"
      ],
      [
        2,
        "Akiko Yoshioka"
      ]
    ],
    "directors": "Hayao Miyazaki",
    "budget_string": "19.8m USD",
    "gross_string": "169.8m USD",
    "metascore_string": "76%"
  },
  {
    "imdb_id": "tt0119217",
    "plot": "Will Hunting, a janitor at M.I.T., has a gift for mathematics, but needs help from a psychologist to find direction in his life.",
    "certification": "R",
    "other_wins_count": 22,
    "other_noms_count": 61,
    "amazon": "https://www.amazon.com/Good-Will-Hunting-Anniversary-Blu-ray/dp/B0088EDO08/ref=sr_1_6?crid=3VK4C2U8I0826&amp;keywords=good+will+hunting&amp;qid=1654843919&amp;s=movies-tv&amp;sprefix=good+will+hunting%252Cmovies-tv%252C792&amp;sr=1-6&_encoding=UTF8&tag=sianglu-20&linkCode=ur2&linkId=c692c45238f8b4fd22527a30ba0f7561&camp=1789&creative=9325",
    "justwatch": "https://justwatch.com/us/movie/good-will-hunting?utm_source=share&utm_medium=web",
    "beige_index": 1,
    "beige_angle": 22.95,
    "cast": [
      [
        5,
        "Derrick Bridgeman"
      ],
      [
        5,
        "Dan Washington"
      ],
      [
        5,
        "Vik Sahay"
      ],
      [
        2,
        "Frank Nakashima"
      ],
      [
        1,
        "Ben Affleck"
      ],
      [
        1,
        "Casey Affleck"
      ],
      [
        1,
        "James Allodi"
      ],
      [
        1,
        "Chris Britton"
      ],
      [
        1,
        "Joe Cannon"
      ],
      [
        1,
        "Francesco Clemente"
      ],
      [
        1,
        "Matt Damon"
      ],
      [
        1,
        "Jennifer Deathe"
      ],
      [
        1,
        "Shannon Egleson"
      ],
      [
        1,
        "David Eisner"
      ],
      [
        1,
        "Richard Fitzpatrick"
      ],
      [
        1,
        "Jimmy Flynn"
      ],
      [
        1,
        "Alison Folland"
      ],
      [
        1,
        "Libby Geller"
      ],
      [
        1,
        "Cole Hauser"
      ],
      [
        1,
        "Bruce Hunter"
      ],
      [
        1,
        "Steven Kozlowski"
      ],
      [
        1,
        "Chas Lawther"
      ],
      [
        1,
        "Rob Lynds"
      ],
      [
        1,
        "Rob Lyons"
      ],
      [
        1,
        "Rachel Majorowski"
      ],
      [
        1,
        "Ann Matacunas"
      ],
      [
        1,
        "Colleen McCauley"
      ],
      [
        1,
        "Matt Mercier"
      ],
      [
        1,
        "John Mighton"
      ],
      [
        1,
        "Barna Moricz"
      ],
      [
        1,
        "Jessica Morton"
      ],
      [
        1,
        "Patrick O'Donnell"
      ],
      [
        1,
        "George Plimpton"
      ],
      [
        1,
        "Kevin Rushton"
      ],
      [
        1,
        "Stellan Skarsg\u00e5rd"
      ],
      [
        1,
        "Ralph St. George"
      ],
      [
        1,
        "Robert Talvano"
      ],
      [
        1,
        "Scott William Winters"
      ],
      [
        1,
        "Philip Williams"
      ],
      [
        1,
        "Robin Williams"
      ]
    ],
    "directors": "Gus Van Sant",
    "budget_string": "10m USD",
    "gross_string": "225.9m USD",
    "metascore_string": "70%"
  },
  {
    "imdb_id": "tt0118849",
    "plot": "After a boy loses his sister's pair of shoes, he goes on a series of adventures in order to find them. When he can't, he tries a new way to \"win\" a new pair.",
    "certification": "PG",
    "other_wins_count": 13,
    "other_noms_count": 4,
    "amazon": "https://www.amazon.com/Children-Heaven-English-Subtitled-Mohammad/dp/B008RK7VNO/ref=sr_1_13?crid=20EFKTFEXCJLF&amp;keywords=children+of+heaven+blu+ray&amp;qid=1655182525&amp;sprefix=children+of+heaven+blu+ray%252Caps%252C279&amp;sr=8-13&_encoding=UTF8&tag=sianglu-20&linkCode=ur2&linkId=2e505633b1ebf7d31bfb201b39648411&camp=1789&creative=9325",
    "justwatch": "https://justwatch.com/us/movie/children-of-heaven?utm_source=share&utm_medium=web",
    "beige_index": 4,
    "beige_angle": 132.0,
    "cast": [
      [
        4,
        "Hossein Ahamdloo"
      ],
      [
        4,
        "Mohammad Amir Naji"
      ],
      [
        4,
        "Kazem Asqarpoor"
      ],
      [
        4,
        "Ali Chaharian"
      ],
      [
        4,
        "Masume Dair"
      ],
      [
        4,
        "Reza Dehghan"
      ],
      [
        4,
        "Amir Farrokh Hashemian"
      ],
      [
        4,
        "Navid Feyzabadi"
      ],
      [
        4,
        "Soheil Haj-Amini"
      ],
      [
        4,
        "Siamak Haj-Amini"
      ],
      [
        4,
        "Mohammad Haj-Hosseini"
      ],
      [
        4,
        "Mohammad Hossein Mortavazi"
      ],
      [
        4,
        "Amir Hossein Tavakkoli"
      ],
      [
        4,
        "Seyd-Ali Hosseini"
      ],
      [
        4,
        "Mohammad-Hasan Hosseinian"
      ],
      [
        4,
        "Nafise Jafar-Mohammadi"
      ],
      [
        4,
        "Seyed Karym Alikhani"
      ],
      [
        4,
        "Amir-Ali Kazemi"
      ],
      [
        4,
        "Javad Kazemi"
      ],
      [
        4,
        "Rahman Kharazchi"
      ],
      [
        4,
        "Christopher Maleki"
      ],
      [
        4,
        "Qolamreza Maleki"
      ],
      [
        4,
        "Hadi Maleki"
      ],
      [
        4,
        "Kamal Mirkarimi"
      ],
      [
        4,
        "Zahra Mirzai"
      ],
      [
        4,
        "Dariush Mokhtari"
      ],
      [
        4,
        "Mohammad Oskooi"
      ],
      [
        4,
        "Kambiz Peykarnegar"
      ],
      [
        4,
        "Ahmad Pourmokhber"
      ],
      [
        4,
        "Moshen Qadiri Nejad"
      ],
      [
        4,
        "Azade Qale Noi"
      ],
      [
        4,
        "Behzad Rafi"
      ],
      [
        4,
        "Hasan Roohparvari"
      ],
      [
        4,
        "Abbas-Ali Roomandi"
      ],
      [
        4,
        "Mash-Ebrahim Safari"
      ],
      [
        4,
        "Faramarz Safarizadeh"
      ],
      [
        4,
        "Haj-Ali Salimi"
      ],
      [
        4,
        "Fereshte Sarabandi"
      ],
      [
        4,
        "Bahare Seddiqi"
      ],
      [
        4,
        "Jafar Seyfollahi"
      ],
      [
        4,
        "Mohammad-Hossein Shahidi"
      ],
      [
        4,
        "Davud Shams"
      ],
      [
        4,
        "Pasha Shoja Zadeh"
      ],
      [
        4,
        "Golnaz Tariqat"
      ],
      [
        4,
        "Jamshid Yusefi"
      ],
      [
        4,
        "Sara Zamani"
      ]
    ],
    "directors": "Majid Majidi",
    "budget_string": "0.2m USD",
    "gross_string": "0.9m USD",
    "metascore_string": "77%"
  },
  {
    "imdb_id": "tt0118799",
    "plot": "When an open-minded Jewish waiter and his son become victims of the Holocaust, he uses a perfect mixture of will, humor, and imagination to protect his son from the dangers around their camp.",
    "certification": "PG-13",
    "other_wins_count": 69,
    "other_noms_count": 52,
    "amazon": "https://www.amazon.com/Life-Beautiful-Claudio-Alfonsi/dp/B00001U0DP/ref=tmm_dvd_swatch_0?_encoding=UTF8&amp;qid=1654828217&amp;sr=8-4&_encoding=UTF8&tag=sianglu-20&linkCode=ur2&linkId=f675667a12adf58bc6b28a485ba8d179&camp=1789&creative=9325",
    "justwatch": "https://justwatch.com/us/movie/life-is-beautiful?utm_source=share&utm_medium=web",
    "beige_index": 1,
    "beige_angle": 0.0,
    "cast": [
      [
        1,
        "Lidia Alfonsi"
      ],
      [
        1,
        "Claudio Alfonsi"
      ],
      [
        1,
        "Angel Alise Middelthon"
      ],
      [
        1,
        "Gil Baroni"
      ],
      [
        1,
        "Roberto Benigni"
      ],
      [
        1,
        "Massimo Bianchi"
      ],
      [
        1,
        "Sergio Bini Bustric"
      ],
      [
        1,
        "J\u00fcrgen Bohn"
      ],
      [
        1,
        "Nicoletta Braschi"
      ],
      [
        1,
        "Horst Buchholz"
      ],
      [
        1,
        "Verena Buratti"
      ],
      [
        1,
        "Robert Camero"
      ],
      [
        1,
        "Giorgio Cantarini"
      ],
      [
        1,
        "Ennio Consalvi"
      ],
      [
        1,
        "Giancarlo Cosentino"
      ],
      [
        1,
        "Aaron Craig"
      ],
      [
        1,
        "Pietro De Silva"
      ],
      [
        1,
        "Giustino Durano"
      ],
      [
        1,
        "Alfiero Falomi"
      ],
      [
        1,
        "James Falzone"
      ],
      [
        1,
        "Daniela Fedke"
      ],
      [
        1,
        "Antonio Fommei"
      ],
      [
        1,
        "Amerigo Fontani"
      ],
      [
        1,
        "Stefano Frangipani"
      ],
      [
        1,
        "Ernst Frowein Holger"
      ],
      [
        1,
        "Alessandra Grassi"
      ],
      [
        1,
        "Francesco Guzzo"
      ],
      [
        1,
        "Hannes Hellmann"
      ],
      [
        1,
        "Wolfgang Hillinger"
      ],
      [
        1,
        "Dirk K. van den Berg"
      ],
      [
        1,
        "Patrizia Lazzarini"
      ],
      [
        1,
        "Raffaella Lebboroni"
      ],
      [
        1,
        "Maria Letizia"
      ],
      [
        1,
        "Giuliana Lojodice"
      ],
      [
        1,
        "Concetta Lombardo"
      ],
      [
        1,
        "Margarita Lucia Krauss"
      ],
      [
        1,
        "Carlotta Mangione"
      ],
      [
        1,
        "Franco Mescolini"
      ],
      [
        1,
        "Francesca Messinese"
      ],
      [
        1,
        "Andrea Nardi"
      ],
      [
        1,
        "Marisa Paredes"
      ],
      [
        1,
        "G\u00fcnther Pfanzelter"
      ],
      [
        1,
        "Cristiana Porchiella"
      ],
      [
        1,
        "Antonio Prester"
      ],
      [
        1,
        "Maria Rita Macellari"
      ],
      [
        1,
        "Gina Rovere"
      ],
      [
        1,
        "Massimo Salvianti"
      ],
      [
        1,
        "Richard Sammel"
      ],
      [
        1,
        "James Schindler"
      ],
      [
        1,
        "Laura Susanne Ruedeberg"
      ],
      [
        1,
        "Andrea Tidona"
      ],
      [
        1,
        "Giovanna Villa"
      ]
    ],
    "directors": "Roberto Benigni",
    "budget_string": "20m USD",
    "gross_string": "230.1m USD",
    "metascore_string": "59%"
  },
  {
    "imdb_id": "tt0119488",
    "plot": "As corruption grows in 1950s Los Angeles, three policemen - one strait-laced, one brutal, and one sleazy - investigate a series of murders with their own brand of justice.",
    "certification": "R",
    "other_wins_count": 90,
    "other_noms_count": 86,
    "amazon": "https://www.amazon.com/L-Confidential-Blu-ray-James-Cromwell/dp/B010GJM750/ref=sr_1_1?crid=2FGEXW6ZKKYBU&amp;keywords=la+confidential+blu+ray&amp;qid=1655007805&amp;sprefix=LA+confidential+blu+ray%252Caps%252C291&amp;sr=8-1&_encoding=UTF8&tag=sianglu-20&linkCode=ur2&linkId=5801a2c8048dbb4cd2794243c3d742ed&camp=1789&creative=9325",
    "justwatch": "https://justwatch.com/us/movie/l-a-confidential?utm_source=share&utm_medium=web",
    "beige_index": 1,
    "beige_angle": 23.337,
    "cast": [
      [
        5,
        "Robert Barry Fleming"
      ],
      [
        5,
        "Jeremiah Birkett"
      ],
      [
        5,
        "Robert Harrison"
      ],
      [
        5,
        "Jeff Sanders"
      ],
      [
        5,
        "Karr Washington"
      ],
      [
        3,
        "Marisol Padilla S\u00e1nchez"
      ],
      [
        3,
        "Salim Grant"
      ],
      [
        3,
        "Thomas Rosales Jr."
      ],
      [
        1,
        "Precious Chong"
      ],
      [
        1,
        "Symba"
      ],
      [
        1,
        "Matthew Allen Bretz"
      ],
      [
        1,
        "Tomas Arana"
      ],
      [
        1,
        "Jeff Austin"
      ],
      [
        1,
        "Simon Baker"
      ],
      [
        1,
        "Brenda Bakke"
      ],
      [
        1,
        "Kim Basinger"
      ],
      [
        1,
        "Graham Beckel"
      ],
      [
        1,
        "Brian Bossetta"
      ],
      [
        1,
        "April Breneman"
      ],
      [
        1,
        "Michael Chieffo"
      ],
      [
        1,
        "Bob Clendenin"
      ],
      [
        1,
        "Jack Conley"
      ],
      [
        1,
        "James Cromwell"
      ],
      [
        1,
        "Russell Crowe"
      ],
      [
        1,
        "John Currie Slade"
      ],
      [
        1,
        "Gwenda Deacon"
      ],
      [
        1,
        "Danny DeVito"
      ],
      [
        1,
        "Shane Dixon"
      ],
      [
        1,
        "Scott Eberlein"
      ],
      [
        1,
        "Noel Evangelisti"
      ],
      [
        1,
        "Robert Foster"
      ],
      [
        1,
        "Shawnee Free Jones"
      ],
      [
        1,
        "Allan Graf"
      ],
      [
        1,
        "Elisabeth Granli"
      ],
      [
        1,
        "Paul Guilfoyle"
      ],
      [
        1,
        "John H. Evans"
      ],
      [
        1,
        "Norman Howell"
      ],
      [
        1,
        "T.J. Kennedy"
      ],
      [
        1,
        "Rebecca Klingler"
      ],
      [
        1,
        "Jack Knight"
      ],
      [
        1,
        "Brian Lally"
      ],
      [
        1,
        "Steven Lambert"
      ],
      [
        1,
        "Fred Lerner"
      ],
      [
        1,
        "Lennie Loftin"
      ],
      [
        1,
        "John Mahon"
      ],
      [
        1,
        "Kevin Maloney"
      ],
      [
        1,
        "Henry Marder"
      ],
      [
        1,
        "Jordan Marder"
      ],
      [
        1,
        "Michael McCleery"
      ],
      [
        1,
        "Matt McCoy"
      ],
      [
        1,
        "Monty McKee"
      ],
      [
        1,
        "Jim Metzler"
      ],
      [
        1,
        "Henry Meyers"
      ],
      [
        1,
        "Colin Mitchell"
      ],
      [
        1,
        "Marilyn Monroe"
      ],
      [
        1,
        "Ingo Neuhaus"
      ],
      [
        1,
        "Bodie Newcomb"
      ],
      [
        1,
        "Michael Ossmann"
      ],
      [
        1,
        "Chris Palermo"
      ],
      [
        1,
        "Kevin Patrick Kelly"
      ],
      [
        1,
        "Guy Pearce"
      ],
      [
        1,
        "Don Pulford"
      ],
      [
        1,
        "Steve Rankin"
      ],
      [
        1,
        "Ron Rifkin"
      ],
      [
        1,
        "Irene Roseen"
      ],
      [
        1,
        "Darrell Sandeen"
      ],
      [
        1,
        "Paolo Seganti"
      ],
      [
        1,
        "Beverly Sharpe"
      ],
      [
        1,
        "Chris Short"
      ],
      [
        1,
        "Ginger Slaughter"
      ],
      [
        1,
        "Amber Smith"
      ],
      [
        1,
        "Kevin Spacey"
      ],
      [
        1,
        "David St. James"
      ],
      [
        1,
        "Dick Stilwell"
      ],
      [
        1,
        "David Strathairn"
      ],
      [
        1,
        "Sandra Taylor"
      ],
      [
        1,
        "Jess Thomas"
      ],
      [
        1,
        "Robert Thompson"
      ],
      [
        1,
        "Patrice Walters"
      ],
      [
        1,
        "Michael Warwick"
      ],
      [
        1,
        "Gregory White"
      ],
      [
        1,
        "Gene Wolande"
      ],
      [
        1,
        "Jody Wood"
      ],
      [
        1,
        "Lisa Worthy"
      ],
      [
        1,
        "George Yager"
      ],
      [
        1,
        "Will Zahrn"
      ]
    ],
    "directors": "Curtis Hanson",
    "budget_string": "35m USD",
    "gross_string": "126.2m USD",
    "metascore_string": "90%"
  },
  {
    "imdb_id": "tt0120586",
    "plot": "A former neo-nazi skinhead tries to prevent his younger brother from going down the same wrong path that he did.",
    "certification": "R",
    "other_wins_count": 4,
    "other_noms_count": 14,
    "amazon": "https://www.amazon.com/American-History-X-Edward-Norton/dp/B001RL4K2A/ref=sr_1_2?crid=38ER7G9CP2B6B&amp;keywords=american+history+x&amp;qid=1654831705&amp;s=movies-tv&amp;sprefix=american+history+%252Cmovies-tv-intl-ship%252C765&amp;sr=1-2&_encoding=UTF8&tag=sianglu-20&linkCode=ur2&linkId=613907d3f9d9babbbefac62277f57b10&camp=1789&creative=9325",
    "justwatch": "https://justwatch.com/us/movie/american-history-x?utm_source=share&utm_medium=web",
    "beige_index": 2,
    "beige_angle": 55.3167,
    "cast": [
      [
        5,
        "Sydney 'Big Dawg' Colston"
      ],
      [
        5,
        "Jason Bose Smith"
      ],
      [
        5,
        "Avery Brooks"
      ],
      [
        5,
        "Antonio David Lyons"
      ],
      [
        5,
        "Kiante Elam"
      ],
      [
        5,
        "Jonathan Fowler Jr."
      ],
      [
        5,
        "Danso Gordon"
      ],
      [
        5,
        "Nigel Miguel"
      ],
      [
        5,
        "Guy Torry"
      ],
      [
        2,
        "Fairuza Balk"
      ],
      [
        2,
        "Keram Malicki-S\u00e1nchez"
      ],
      [
        1,
        "Giuseppe Andrews"
      ],
      [
        1,
        "David Basulto"
      ],
      [
        1,
        "Tara Blanchard"
      ],
      [
        1,
        "Michelle Christine White"
      ],
      [
        1,
        "Joe Cortese"
      ],
      [
        1,
        "Beverly D'Angelo"
      ],
      [
        1,
        "Paul E. Short"
      ],
      [
        1,
        "Edward Furlong"
      ],
      [
        1,
        "Elliott Gould"
      ],
      [
        1,
        "Paul Hopkins"
      ],
      [
        1,
        "Stacy Keach"
      ],
      [
        1,
        "Thomas L. Bellissimo"
      ],
      [
        1,
        "Anne Lambton"
      ],
      [
        1,
        "Paul Le Mat"
      ],
      [
        1,
        "Cherish Lee"
      ],
      [
        1,
        "Jennifer Lien"
      ],
      [
        1,
        "Jordan Marder"
      ],
      [
        1,
        "Christopher Masterson"
      ],
      [
        1,
        "Jim Norton"
      ],
      [
        1,
        "Edward Norton"
      ],
      [
        1,
        "Richard Noyce"
      ],
      [
        1,
        "Keith Odett"
      ],
      [
        1,
        "Denney Pierce"
      ],
      [
        1,
        "Nicholas R. Oleson"
      ],
      [
        1,
        "Alexis Rose Coen"
      ],
      [
        1,
        "William Russ"
      ],
      [
        1,
        "Alex Sol"
      ],
      [
        1,
        "Ethan Suplee"
      ],
      [
        1,
        "Sam Vlahos"
      ],
      [
        1,
        "Steve Wolford"
      ]
    ],
    "directors": "Tony Kaye",
    "budget_string": "20m USD",
    "gross_string": "23.9m USD",
    "metascore_string": "62%"
  },
  {
    "imdb_id": "tt0120735",
    "plot": "Eddy persuades his three pals to pool money for a vital poker game against a powerful local mobster, Hatchet Harry. Eddy loses, after which Harry gives him a week to pay back 500,000 pounds.",
    "certification": "R",
    "other_wins_count": 13,
    "other_noms_count": 8,
    "amazon": "https://www.amazon.com/Lock-Stock-Smoking-Barrels-Blu-ray/dp/B004ZJZQDS/ref=tmm_blu_swatch_0?_encoding=UTF8&amp;qid=1655180976&amp;sr=1-3&_encoding=UTF8&tag=sianglu-20&linkCode=ur2&linkId=481a9d37124795f6e559cdb8a2fd4900&camp=1789&creative=9325",
    "justwatch": "https://justwatch.com/us/movie/lock-stock-and-two-smoking-barrels?utm_source=share&utm_medium=web",
    "beige_index": 1,
    "beige_angle": 33.9066,
    "cast": [
      [
        5,
        "Elwin 'Chopper' David"
      ],
      [
        5,
        "Vas Blackwood"
      ],
      [
        5,
        "Danny John-Jules"
      ],
      [
        5,
        "Bal Jusar"
      ],
      [
        4,
        "Jake Abraham"
      ],
      [
        1,
        "Sting"
      ],
      [
        1,
        "Rob Brydon"
      ],
      [
        1,
        "Stephen Callender-Ferrier"
      ],
      [
        1,
        "Steve Collins"
      ],
      [
        1,
        "Vera Day"
      ],
      [
        1,
        "Jason Flemyng"
      ],
      [
        1,
        "Dexter Fletcher"
      ],
      [
        1,
        "Jimmy Flint"
      ],
      [
        1,
        "Charles Forbes"
      ],
      [
        1,
        "Alan Ford"
      ],
      [
        1,
        "Ronnie Fox"
      ],
      [
        1,
        "Sid Golder"
      ],
      [
        1,
        "Alex Hall"
      ],
      [
        1,
        "Frank Harper"
      ],
      [
        1,
        "John Houchin"
      ],
      [
        1,
        "Derek Howard"
      ],
      [
        1,
        "Vinnie Jones"
      ],
      [
        1,
        "Huggy Leaver"
      ],
      [
        1,
        "Steven Mackintosh"
      ],
      [
        1,
        "Nick Marcq"
      ],
      [
        1,
        "Stephen Marcus"
      ],
      [
        1,
        "Tim Maurice-Jones"
      ],
      [
        1,
        "Victor McGuire"
      ],
      [
        1,
        "Lenny McLean"
      ],
      [
        1,
        "Tony McMahon"
      ],
      [
        1,
        "Peter McNicholl"
      ],
      [
        1,
        "Mark Mooney"
      ],
      [
        1,
        "Nick Moran"
      ],
      [
        1,
        "P.H. Moriarty"
      ],
      [
        1,
        "Suzy Ratner"
      ],
      [
        1,
        "David Reid"
      ],
      [
        1,
        "Nicholas Rowe"
      ],
      [
        1,
        "Jason Statham"
      ],
      [
        1,
        "Steve Sweeney"
      ],
      [
        1,
        "James Tarbuck"
      ],
      [
        1,
        "Andrew Tiernan"
      ],
      [
        1,
        "Richard Vanstone"
      ],
      [
        1,
        "Matthew Vaughn"
      ]
    ],
    "directors": "Guy Ritchie",
    "budget_string": "1.6m USD",
    "gross_string": "3.8m USD",
    "metascore_string": "66%"
  },
  {
    "imdb_id": "tt0118715",
    "plot": "Ultimate L.A. slacker Jeff \"The Dude\" Lebowski, mistaken for a millionaire of the same name, seeks restitution for a rug ruined by debt collectors, enlisting his bowling buddies for help while trying to find the millionaire's missing wife.",
    "certification": "R",
    "other_wins_count": 5,
    "other_noms_count": 18,
    "amazon": "https://www.amazon.com/Big-Lebowski-Blu-ray-Jeff-Bridges/dp/B005OV0PS2/ref=sr_1_2?crid=22W0ROMYK8JFP&amp;keywords=the+big+lebowski+blu+ray&amp;qid=1655183752&amp;sprefix=the+big+lebowski+blu+ray%252Caps%252C282&amp;sr=8-2&_encoding=UTF8&tag=sianglu-20&linkCode=ur2&linkId=b46e3f200e50ac3728c52a2f90424290&camp=1789&creative=9325",
    "justwatch": "https://justwatch.com/us/movie/the-big-lebowski?utm_source=share&utm_medium=web",
    "beige_index": 2,
    "beige_angle": 42.075,
    "cast": [
      [
        5,
        "Terrence Burton"
      ],
      [
        5,
        "Richard Gant"
      ],
      [
        5,
        "Ajgie Kirkland"
      ],
      [
        4,
        "Marshall Manesh"
      ],
      [
        3,
        "Luis Colina"
      ],
      [
        3,
        "Mike Gomez"
      ],
      [
        3,
        "Jerry Haleva"
      ],
      [
        3,
        "Carlos Leon"
      ],
      [
        3,
        "Irene Olga L\u00f3pez"
      ],
      [
        2,
        "Philip Moon"
      ],
      [
        1,
        "Flea"
      ],
      [
        1,
        "Jeff Bridges"
      ],
      [
        1,
        "Harry Bugin"
      ],
      [
        1,
        "Steve Buscemi"
      ],
      [
        1,
        "Christian Clemenson"
      ],
      [
        1,
        "Jimmie Dale Gilmore"
      ],
      [
        1,
        "Sam Elliott"
      ],
      [
        1,
        "Lu Elrod"
      ],
      [
        1,
        "Jesse Flanagan"
      ],
      [
        1,
        "James G. Hoosier"
      ],
      [
        1,
        "Ben Gazzara"
      ],
      [
        1,
        "John Goodman"
      ],
      [
        1,
        "David Huddleston"
      ],
      [
        1,
        "Dom Irrera"
      ],
      [
        1,
        "Jack Kehler"
      ],
      [
        1,
        "Warren Keith"
      ],
      [
        1,
        "G\u00e9rard L'Heureux"
      ],
      [
        1,
        "Jennifer Lamb"
      ],
      [
        1,
        "Aimee Mann"
      ],
      [
        1,
        "Julianne Moore"
      ],
      [
        1,
        "Mark Pellegrino"
      ],
      [
        1,
        "Jon Polito"
      ],
      [
        1,
        "Tara Reid"
      ],
      [
        1,
        "Leon Russom"
      ],
      [
        1,
        "Philip Seymour Hoffman"
      ],
      [
        1,
        "Peter Siragusa"
      ],
      [
        1,
        "Peter Stormare"
      ],
      [
        1,
        "David Thewlis"
      ],
      [
        1,
        "John Turturro"
      ],
      [
        1,
        "Torsten Voges"
      ]
    ],
    "directors": "Joel Coen & Ethan Coen",
    "budget_string": "15m USD",
    "gross_string": "47m USD",
    "metascore_string": "71%"
  },
  {
    "imdb_id": "tt0120815",
    "plot": "Following the Normandy Landings, a group of U.S. soldiers go behind enemy lines to retrieve a paratrooper whose brothers have been killed in action.",
    "certification": "R",
    "other_wins_count": 74,
    "other_noms_count": 75,
    "amazon": "https://www.amazon.com/Saving-Private-Ryan-Sapphire-Blu-ray/dp/B003LL3N1I/ref=tmm_blu_swatch_0?_encoding=UTF8&amp;qid=1654828159&amp;sr=8-3&_encoding=UTF8&tag=sianglu-20&linkCode=ur2&linkId=8cd3a237915aab3aec52be56489b2d2d&camp=1789&creative=9325",
    "justwatch": "https://justwatch.com/us/movie/saving-private-ryan?utm_source=share&utm_medium=web",
    "beige_index": 1,
    "beige_angle": 1.9062,
    "cast": [
      [
        4,
        "Vin Diesel"
      ],
      [
        1,
        "Lee Aaron Rosen"
      ],
      [
        1,
        "Loclann Aiken"
      ],
      [
        1,
        "John Barnett"
      ],
      [
        1,
        "Martin Beaton"
      ],
      [
        1,
        "Amanda Boxer"
      ],
      [
        1,
        "Nick Brooks"
      ],
      [
        1,
        "Dylan Bruno"
      ],
      [
        1,
        "Victor Burke"
      ],
      [
        1,
        "Maclean Burke"
      ],
      [
        1,
        "Edward Burns"
      ],
      [
        1,
        "Kathleen Byron"
      ],
      [
        1,
        "Marc Cass"
      ],
      [
        1,
        "Daniel Cerqueira"
      ],
      [
        1,
        "Valerie Colgan"
      ],
      [
        1,
        "Aiden Condron"
      ],
      [
        1,
        "Stephane Cornicard"
      ],
      [
        1,
        "Bryan Cranston"
      ],
      [
        1,
        "Matt Damon"
      ],
      [
        1,
        "Ted Danson"
      ],
      [
        1,
        "Jeremy Davies"
      ],
      [
        1,
        "Raffaello Degruttola"
      ],
      [
        1,
        "Dale Dye"
      ],
      [
        1,
        "Sam Ellis"
      ],
      [
        1,
        "Michelle Evans"
      ],
      [
        1,
        "Dennis Farina"
      ],
      [
        1,
        "Nathan Fillion"
      ],
      [
        1,
        "Neil Finnighan"
      ],
      [
        1,
        "Rob Freeman"
      ],
      [
        1,
        "Paschal Friel"
      ],
      [
        1,
        "Paul Garcia"
      ],
      [
        1,
        "Paul Giamatti"
      ],
      [
        1,
        "Thomas Gizbert"
      ],
      [
        1,
        "Adam Goldberg"
      ],
      [
        1,
        "Demetri Goritsas"
      ],
      [
        1,
        "Steve Griffin"
      ],
      [
        1,
        "Stephan Grothgar"
      ],
      [
        1,
        "Dorothy Grumbar"
      ],
      [
        1,
        "Shane Hagan"
      ],
      [
        1,
        "Tom Hanks"
      ],
      [
        1,
        "Crofton Hardester"
      ],
      [
        1,
        "Paul Hickey"
      ],
      [
        1,
        "Martin Hub"
      ],
      [
        1,
        "Ryan Hurst"
      ],
      [
        1,
        "James Innes-Smith"
      ],
      [
        1,
        "Shane Johnson"
      ],
      [
        1,
        "Corey Johnson"
      ],
      [
        1,
        "Tilo Keiner"
      ],
      [
        1,
        "Ronald Longridge"
      ],
      [
        1,
        "Eric Loren"
      ],
      [
        1,
        "Laird Macintosh"
      ],
      [
        1,
        "Anna Maguire"
      ],
      [
        1,
        "Michael Mantas"
      ],
      [
        1,
        "William Marsh"
      ],
      [
        1,
        "Max Martini"
      ],
      [
        1,
        "Brian Maynard"
      ],
      [
        1,
        "Martin McDougall"
      ],
      [
        1,
        "Seamus McQuade"
      ],
      [
        1,
        "Peter Miles"
      ],
      [
        1,
        "Markus Napier"
      ],
      [
        1,
        "Leland Orser"
      ],
      [
        1,
        "Barry Pepper"
      ],
      [
        1,
        "Mark Phillips"
      ],
      [
        1,
        "Ian Porter"
      ],
      [
        1,
        "Harve Presnell"
      ],
      [
        1,
        "Erich Redman"
      ],
      [
        1,
        "Giovanni Ribisi"
      ],
      [
        1,
        "Rolf Saxon"
      ],
      [
        1,
        "Andrew Scott"
      ],
      [
        1,
        "Sam Scudder"
      ],
      [
        1,
        "Gary Sefton"
      ],
      [
        1,
        "John Sharian"
      ],
      [
        1,
        "Matthew Sharp"
      ],
      [
        1,
        "Adam Shaw"
      ],
      [
        1,
        "Tom Sizemore"
      ],
      [
        1,
        "Julian Spencer"
      ],
      [
        1,
        "Joerg Stadler"
      ],
      [
        1,
        "David Vegh"
      ],
      [
        1,
        "Vincent Walsh"
      ],
      [
        1,
        "John Walters"
      ],
      [
        1,
        "Nigel Whitmey"
      ],
      [
        1,
        "David Wohl"
      ],
      [
        1,
        "Grahame Wood"
      ],
      [
        1,
        "Glenn Wrage"
      ],
      [
        1,
        "Harrison Young"
      ]
    ],
    "directors": "Steven Spielberg",
    "budget_string": "70m USD",
    "gross_string": "482.3m USD",
    "metascore_string": "91%"
  },
  {
    "imdb_id": "tt0120382",
    "plot": "An insurance salesman discovers his whole life is actually a reality TV show.",
    "certification": "PG",
    "other_wins_count": 40,
    "other_noms_count": 66,
    "amazon": "https://www.amazon.com/Truman-Show/dp/B001S3GDVS/ref=sr_1_2?crid=3RM2XDSFHC3Z1&amp;keywords=the+truman+show+blu+ray&amp;qid=1655175049&amp;sprefix=the+truman+show+blu+ray%252Caps%252C286&amp;sr=8-2&_encoding=UTF8&tag=sianglu-20&linkCode=ur2&linkId=ac1c7be8a6e200fe588b233483a11a40&camp=1789&creative=9325",
    "justwatch": "https://justwatch.com/us/movie/the-truman-show?utm_source=share&utm_medium=web",
    "beige_index": 2,
    "beige_angle": 43.0434,
    "cast": [
      [
        5,
        "David Andrew Nash"
      ],
      [
        5,
        "Fritz Dominique"
      ],
      [
        5,
        "Al Foster"
      ],
      [
        5,
        "Earl Hilliard Jr."
      ],
      [
        5,
        "Angel Schmiedt"
      ],
      [
        5,
        "Nastassja Schmiedt"
      ],
      [
        5,
        "Tony Todd"
      ],
      [
        5,
        "Sebastian Youngblood"
      ],
      [
        3,
        "Mario Ernesto S\u00e1nchez"
      ],
      [
        3,
        "Joe Minjares"
      ],
      [
        3,
        "Marco Rubeo"
      ],
      [
        3,
        "Marcia DeBonis"
      ],
      [
        3,
        "Carly Smiga"
      ],
      [
        2,
        "Una Damon"
      ],
      [
        2,
        "Saemi Nakamura"
      ],
      [
        2,
        "Yuji Okumoto"
      ],
      [
        2,
        "Kiyoko Yamaguchi"
      ],
      [
        1,
        "Mark Alan Gillott"
      ],
      [
        1,
        "Susan Angelo"
      ],
      [
        1,
        "Philip Baker Hall"
      ],
      [
        1,
        "Terry Camilleri"
      ],
      [
        1,
        "Jim Carrey"
      ],
      [
        1,
        "Judy Clayton"
      ],
      [
        1,
        "Kade Coates"
      ],
      [
        1,
        "Dave Corey"
      ],
      [
        1,
        "Antoni Corone"
      ],
      [
        1,
        "Daryl Davis"
      ],
      [
        1,
        "Robert Davis"
      ],
      [
        1,
        "Brian Delate"
      ],
      [
        1,
        "Noah Emmerich"
      ],
      [
        1,
        "Paul Giamatti"
      ],
      [
        1,
        "Philip Glass"
      ],
      [
        1,
        "Dona Hardy"
      ],
      [
        1,
        "Ed Harris"
      ],
      [
        1,
        "Mal Jones"
      ],
      [
        1,
        "O-Lan Jones"
      ],
      [
        1,
        "Logan Kirksey"
      ],
      [
        1,
        "Sam Kitchin"
      ],
      [
        1,
        "Peter Krause"
      ],
      [
        1,
        "Zoaunne LeRoy"
      ],
      [
        1,
        "Laura Linney"
      ],
      [
        1,
        "Joseph Lucus"
      ],
      [
        1,
        "Krista Lynn Landolfi"
      ],
      [
        1,
        "Marc Macaulay"
      ],
      [
        1,
        "Matthew McDonough"
      ],
      [
        1,
        "Larry McDowell"
      ],
      [
        1,
        "Natascha McElhone"
      ],
      [
        1,
        "Joel McKinnon Miller"
      ],
      [
        1,
        "Jeanette Miller"
      ],
      [
        1,
        "Muriel Moore"
      ],
      [
        1,
        "R.J. Murdock"
      ],
      [
        1,
        "John Pleshette"
      ],
      [
        1,
        "John Pramik"
      ],
      [
        1,
        "Ted Raymond"
      ],
      [
        1,
        "John Roselius"
      ],
      [
        1,
        "Joseph Rye"
      ],
      [
        1,
        "Jay Saiter"
      ],
      [
        1,
        "Heidi Schanz"
      ],
      [
        1,
        "Harry Shearer"
      ],
      [
        1,
        "Tom Simmons"
      ],
      [
        1,
        "Blair Slater"
      ],
      [
        1,
        "Millie Slavin"
      ],
      [
        1,
        "Savannah Swafford"
      ],
      [
        1,
        "Ron Taylor"
      ],
      [
        1,
        "Don Taylor"
      ],
      [
        1,
        "Holland Taylor"
      ],
      [
        1,
        "Adam Tomei"
      ],
      [
        1,
        "Jim Towers"
      ],
      [
        1,
        "Judson Vaughn"
      ]
    ],
    "directors": "Peter Weir",
    "budget_string": "60m USD",
    "gross_string": "264.1m USD",
    "metascore_string": "90%"
  },
  {
    "imdb_id": "tt0137523",
    "plot": "An insomniac office worker and a devil-may-care soap maker form an underground fight club that evolves into much more.",
    "certification": "R",
    "other_wins_count": 11,
    "other_noms_count": 37,
    "amazon": "https://www.amazon.com/Fight-Club-10th-Anniversary-Blu-ray/dp/B001992NUQ/ref=sr_1_4?crid=2NV80E0W9B5NN&amp;keywords=fight+club&amp;qid=1654826914&amp;sprefix=fight+club%252Caps%252C518&amp;sr=8-4&_encoding=UTF8&tag=sianglu-20&linkCode=ur2&linkId=15c9bb45755b8b6d414ac2f8f8d31762&camp=1789&creative=9325",
    "justwatch": "https://justwatch.com/us/movie/fight-club?utm_source=share&utm_medium=web",
    "beige_index": 2,
    "beige_angle": 56.547,
    "cast": [
      [
        5,
        "Sydney 'Big Dawg' Colston"
      ],
      [
        5,
        "Lou Beatty Jr."
      ],
      [
        5,
        "Valerie Bickford"
      ],
      [
        5,
        "Anderson Bourell"
      ],
      [
        5,
        "Dierdre Downing-Jackson"
      ],
      [
        5,
        "Scotch Ellis Loring"
      ],
      [
        5,
        "Thom Gossom Jr."
      ],
      [
        5,
        "David Jean Thomas"
      ],
      [
        5,
        "Bennie Moore"
      ],
      [
        5,
        "Tyrone R. Livingston"
      ],
      [
        5,
        "Markus Redmond"
      ],
      [
        5,
        "Robby Robinson"
      ],
      [
        3,
        "Lauren Sanchez"
      ],
      [
        2,
        "Joon Kim"
      ],
      [
        1,
        "David Andrews"
      ],
      [
        1,
        "Richmond Arquette"
      ],
      [
        1,
        "Eion Bailey"
      ],
      [
        1,
        "Joel Bissonnette"
      ],
      [
        1,
        "Stuart Blumberg"
      ],
      [
        1,
        "Eugenie Bondurant"
      ],
      [
        1,
        "Helena Bonham Carter"
      ],
      [
        1,
        "Ezra Buzzington"
      ],
      [
        1,
        "Christina Cabot"
      ],
      [
        1,
        "Paul Carafotes"
      ],
      [
        1,
        "Andi Carnick"
      ],
      [
        1,
        "Carl Ciarfalio"
      ],
      [
        1,
        "Christie Cronenweth"
      ],
      [
        1,
        "Charlie Dell"
      ],
      [
        1,
        "Tim DeZarn"
      ],
      [
        1,
        "Mark Fite"
      ],
      [
        1,
        "Michael Girardin"
      ],
      [
        1,
        "Zach Grenier"
      ],
      [
        1,
        "Peter Iacangelo"
      ],
      [
        1,
        "Christopher John Fields"
      ],
      [
        1,
        "Edward Kowalczyk"
      ],
      [
        1,
        "Rob Lanza"
      ],
      [
        1,
        "David Lee Smith"
      ],
      [
        1,
        "Jared Leto"
      ],
      [
        1,
        "Meat Loaf"
      ],
      [
        1,
        "George Maguire"
      ],
      [
        1,
        "Owen Masterson"
      ],
      [
        1,
        "Holt McCallany"
      ],
      [
        1,
        "Pat McNamara"
      ],
      [
        1,
        "Evan Mirand"
      ],
      [
        1,
        "Edward Norton"
      ],
      [
        1,
        "Todd Peirce"
      ],
      [
        1,
        "Brad Pitt"
      ],
      [
        1,
        "Van Quattro"
      ],
      [
        1,
        "Michael Shamus Wiles"
      ],
      [
        1,
        "Rachel Singer"
      ],
      [
        1,
        "Bob Stephenson"
      ],
      [
        1,
        "Leonard Termo"
      ],
      [
        1,
        "Matt Winston"
      ]
    ],
    "directors": "David Fincher",
    "budget_string": "63m USD",
    "gross_string": "101.2m USD",
    "metascore_string": "66%"
  },
  {
    "imdb_id": "tt0133093",
    "plot": "When a beautiful stranger leads computer hacker Neo to a forbidding underworld, he discovers the shocking truth--the life he knows is the elaborate deception of an evil cyber-intelligence.",
    "certification": "R",
    "other_wins_count": 38,
    "other_noms_count": 51,
    "amazon": "https://www.amazon.com/Matrix-10th-Anniversary/dp/B007KW5N76/ref=tmm_blu_title_0?_encoding=UTF8&amp;qid=1654827229&amp;sr=8-5&_encoding=UTF8&tag=sianglu-20&linkCode=ur2&linkId=da99e39b9fa081422a06e7ca4ebf8d63&camp=1789&creative=9325",
    "justwatch": "https://justwatch.com/us/movie/the-matrix?utm_source=share&utm_medium=web",
    "beige_index": 2,
    "beige_angle": 56.25,
    "cast": [
      [
        5,
        "Marcus Chong"
      ],
      [
        5,
        "Laurence Fishburne"
      ],
      [
        5,
        "Gloria Foster"
      ],
      [
        5,
        "Deni Gordon"
      ],
      [
        5,
        "Anthony Ray Parker"
      ],
      [
        5,
        "Adryn White"
      ],
      [
        5,
        "Steve Dodd"
      ],
      [
        5,
        "Janaya Pender"
      ],
      [
        4,
        "Julian Arahanga"
      ],
      [
        2,
        "Natalie Tjen"
      ],
      [
        1,
        "Marc Aden Gray"
      ],
      [
        1,
        "David Aston"
      ],
      [
        1,
        "Jeremy Ball"
      ],
      [
        1,
        "Tamara Brown"
      ],
      [
        1,
        "Michael Butcher"
      ],
      [
        1,
        "Matt Doran"
      ],
      [
        1,
        "Paul Goddard"
      ],
      [
        1,
        "Nigel Harbach"
      ],
      [
        1,
        "Fiona Johnson"
      ],
      [
        1,
        "Harry Lawrence"
      ],
      [
        1,
        "Bernard Ledger"
      ],
      [
        1,
        "Belinda McClory"
      ],
      [
        1,
        "Carrie-Anne Moss"
      ],
      [
        1,
        "Ada Nicodemou"
      ],
      [
        1,
        "David O'Connor"
      ],
      [
        1,
        "Joe Pantoliano"
      ],
      [
        1,
        "Chris Pattinson"
      ],
      [
        1,
        "Luke Quinton"
      ],
      [
        1,
        "Keanu Reeves"
      ],
      [
        1,
        "Robert Simper"
      ],
      [
        1,
        "Robert Taylor"
      ],
      [
        1,
        "Hugo Weaving"
      ],
      [
        1,
        "Rowan Witt"
      ],
      [
        1,
        "Eleanor Witt"
      ],
      [
        1,
        "Lawrence Woodward"
      ],
      [
        1,
        "Bill Young"
      ]
    ],
    "directors": "Lana Wachowski & Lilly Wachowski",
    "budget_string": "63m USD",
    "gross_string": "467.2m USD",
    "metascore_string": "73%"
  },
  {
    "imdb_id": "tt0167404",
    "plot": "A frightened, withdrawn Philadelphia boy who communicates with spirits seeks the help of a disheartened child psychologist.",
    "certification": "PG-13",
    "other_wins_count": 37,
    "other_noms_count": 50,
    "amazon": "https://www.amazon.com/Sixth-Sense-Blu-ray-Bruce-Willis/dp/B001BRZ5J2/ref=tmm_blu_swatch_0?_encoding=UTF8&amp;qid=1655174800&amp;sr=8-3&_encoding=UTF8&tag=sianglu-20&linkCode=ur2&linkId=a1129ae24e0d53f095a04d971a6eb5ad&camp=1789&creative=9325",
    "justwatch": "https://justwatch.com/us/movie/the-sixth-sense?utm_source=share&utm_medium=web",
    "beige_index": 1,
    "beige_angle": 33.3666,
    "cast": [
      [
        5,
        "Lisa Summerour"
      ],
      [
        5,
        "Firdous Bamji"
      ],
      [
        5,
        "M. Night Shyamalan"
      ],
      [
        5,
        "Samia Shoaib"
      ],
      [
        3,
        "Jose L. Rodriguez"
      ],
      [
        3,
        "Carlos Xavier Lopez"
      ],
      [
        3,
        "Nico Woulard"
      ],
      [
        1,
        "Peter Anthony Tambakis"
      ],
      [
        1,
        "Candy Aston-Dennis"
      ],
      [
        1,
        "Mischa Barton"
      ],
      [
        1,
        "Toni Collette"
      ],
      [
        1,
        "Holly Cross Vagley"
      ],
      [
        1,
        "Janis Dardaris"
      ],
      [
        1,
        "Jodi Dawson"
      ],
      [
        1,
        "Heidi Fischer"
      ],
      [
        1,
        "Glenn Fitzgerald"
      ],
      [
        1,
        "Samantha Fitzpatrick"
      ],
      [
        1,
        "Neill Hartley"
      ],
      [
        1,
        "Wes Heywood"
      ],
      [
        1,
        "Michael J. Lyons"
      ],
      [
        1,
        "Haley Joel Osment"
      ],
      [
        1,
        "Kate Kearney-Patch"
      ],
      [
        1,
        "Ronnie Lea"
      ],
      [
        1,
        "Patrick McDade"
      ],
      [
        1,
        "Tom McLaughlin"
      ],
      [
        1,
        "Tony Michael Donnelly"
      ],
      [
        1,
        "Trevor Morgan"
      ],
      [
        1,
        "Carol Nielson"
      ],
      [
        1,
        "Bruce Norris"
      ],
      [
        1,
        "Angelica Page"
      ],
      [
        1,
        "Sarah Ripard"
      ],
      [
        1,
        "Hayden Saunier"
      ],
      [
        1,
        "Marilyn Shanok"
      ],
      [
        1,
        "Ellen Sheppard"
      ],
      [
        1,
        "KaDee Strickland"
      ],
      [
        1,
        "Donnie Wahlberg"
      ],
      [
        1,
        "Olivia Williams"
      ],
      [
        1,
        "Bruce Willis"
      ],
      [
        1,
        "Greg Wood"
      ],
      [
        1,
        "Keith Woulard"
      ],
      [
        1,
        "Jeffrey Zubernis"
      ]
    ],
    "directors": "M. Night Shyamalan",
    "budget_string": "40m USD",
    "gross_string": "672.8m USD",
    "metascore_string": "64%"
  },
  {
    "imdb_id": "tt0120689",
    "plot": "The lives of guards on Death Row are affected by one of their charges: a black man accused of child murder and rape, yet who has a mysterious gift.",
    "certification": "R",
    "other_wins_count": 15,
    "other_noms_count": 33,
    "amazon": "https://www.amazon.com/Green-Mile-15th-Anniversary-Blu-ray/dp/B00L9050MS/ref=sr_1_4?crid=YV5PO4BW282O&amp;keywords=the+green+mile&amp;qid=1654828404&amp;sprefix=the++green+mile%252Caps%252C1018&amp;sr=8-4&_encoding=UTF8&tag=sianglu-20&linkCode=ur2&linkId=0720517e37a91452b56b0c87f67322ca&camp=1789&creative=9325",
    "justwatch": "https://justwatch.com/us/movie/green-mile-the?utm_source=share&utm_medium=web",
    "beige_index": 1,
    "beige_angle": 25.0002,
    "cast": [
      [
        5,
        "Michael Clarke Duncan"
      ],
      [
        5,
        "Mack Miles"
      ],
      [
        3,
        "Graham Greene"
      ],
      [
        3,
        "Rai Tasco"
      ],
      [
        1,
        "Eve Brent"
      ],
      [
        1,
        "Brent Briscoe"
      ],
      [
        1,
        "Patricia Clarkson"
      ],
      [
        1,
        "James Cromwell"
      ],
      [
        1,
        "Dee Croxton"
      ],
      [
        1,
        "Harry Dean Stanton"
      ],
      [
        1,
        "Jeffrey DeMunn"
      ],
      [
        1,
        "Bailey Drucker"
      ],
      [
        1,
        "Evanne Drucker"
      ],
      [
        1,
        "David E. Browning"
      ],
      [
        1,
        "Van Epperson"
      ],
      [
        1,
        "Bill Gratton"
      ],
      [
        1,
        "Dabbs Greer"
      ],
      [
        1,
        "Tom Hanks"
      ],
      [
        1,
        "Bonnie Hunt"
      ],
      [
        1,
        "Doug Hutchison"
      ],
      [
        1,
        "Gary Imhoff"
      ],
      [
        1,
        "Michael Jeter"
      ],
      [
        1,
        "Christopher Joel Ives"
      ],
      [
        1,
        "Rebecca Klingler"
      ],
      [
        1,
        "Scotty Leavenworth"
      ],
      [
        1,
        "Katelyn Leavenworth"
      ],
      [
        1,
        "Brian Libby"
      ],
      [
        1,
        "Paula Malcomson"
      ],
      [
        1,
        "Bill McKinney"
      ],
      [
        1,
        "David Morse"
      ],
      [
        1,
        "Barry Pepper"
      ],
      [
        1,
        "Sam Rockwell"
      ],
      [
        1,
        "William Sadler"
      ],
      [
        1,
        "Rachel Singer"
      ],
      [
        1,
        "Gary Sinise"
      ],
      [
        1,
        "Edrie Warner"
      ]
    ],
    "directors": "Frank Darabont",
    "budget_string": "60m USD",
    "gross_string": "286.8m USD",
    "metascore_string": "61%"
  },
  {
    "imdb_id": "tt0169547",
    "plot": "A sexually frustrated suburban father has a mid-life crisis after becoming infatuated with his daughter's best friend.",
    "certification": "R",
    "other_wins_count": 106,
    "other_noms_count": 102,
    "amazon": "https://www.amazon.com/American-Beauty-Blu-ray-Dan-Jinks/dp/B072K1Y9CC/ref=sr_1_5?crid=WI3GQM62IOYB&amp;keywords=american+beauty&amp;qid=1654842535&amp;s=movies-tv&amp;sprefix=ameri%252Cmovies-tv%252C1199&amp;sr=1-5&_encoding=UTF8&tag=sianglu-20&linkCode=ur2&linkId=99cf37d1894d9dea7aa2d6a674be73de&camp=1789&creative=9325",
    "justwatch": "https://justwatch.com/us/movie/american-beauty?utm_source=share&utm_medium=web",
    "beige_index": 1,
    "beige_angle": 26.4384,
    "cast": [
      [
        5,
        "Kent Faulcon"
      ],
      [
        5,
        "Reshma Gajjar"
      ],
      [
        3,
        "Ara Celi"
      ],
      [
        3,
        "Carolina Lancaster"
      ],
      [
        2,
        "John Cho"
      ],
      [
        1,
        "Dennis Anderson"
      ],
      [
        1,
        "Nancy Anderson"
      ],
      [
        1,
        "Fort Atkinson"
      ],
      [
        1,
        "Scott Bakula"
      ],
      [
        1,
        "Annette Bening"
      ],
      [
        1,
        "Wes Bentley"
      ],
      [
        1,
        "Thora Birch"
      ],
      [
        1,
        "Sue Casey"
      ],
      [
        1,
        "Erin Cathryn Strubbe"
      ],
      [
        1,
        "Lisa Cloud"
      ],
      [
        1,
        "Chris Cooper"
      ],
      [
        1,
        "Barry Del Sherman"
      ],
      [
        1,
        "Alison Faulk"
      ],
      [
        1,
        "Peter Gallagher"
      ],
      [
        1,
        "Chelsea Hertford"
      ],
      [
        1,
        "Allison Janney"
      ],
      [
        1,
        "Marissa Jaret Winokur"
      ],
      [
        1,
        "Heather Joy Sher"
      ],
      [
        1,
        "Matthew Kimbrough"
      ],
      [
        1,
        "Joel McCrary"
      ],
      [
        1,
        "Sam Robards"
      ],
      [
        1,
        "Amber Smith"
      ],
      [
        1,
        "Kevin Spacey"
      ],
      [
        1,
        "Mena Suvari"
      ],
      [
        1,
        "Chekesha Van Putten"
      ],
      [
        1,
        "Brenda Wehle"
      ],
      [
        1,
        "Emily Zachary"
      ]
    ],
    "directors": "Sam Mendes",
    "budget_string": "15m USD",
    "gross_string": "356.3m USD",
    "metascore_string": "84%"
  },
  {
    "imdb_id": "tt0245712",
    "plot": "A horrific car accident connects three stories, each involving characters dealing with loss, regret, and life's harsh realities, all in the name of love.",
    "certification": "R",
    "other_wins_count": 55,
    "other_noms_count": 24,
    "amazon": "https://www.amazon.com/Amores-Perros-Signature-Emilio-Echevarr%25C3%25ADa/dp/B00007L4MB/ref=tmm_dvd_swatch_0?_encoding=UTF8&amp;qid=1655186013&amp;sr=1-3&_encoding=UTF8&tag=sianglu-20&linkCode=ur2&linkId=71782491d19afdf0ba616fcea6608b8a&camp=1789&creative=9325",
    "justwatch": "https://justwatch.com/us/movie/loves-a-bitch?utm_source=share&utm_medium=web",
    "beige_index": 3,
    "beige_angle": 100.4004,
    "cast": [
      [
        4,
        "Jos\u00e9 Sefami"
      ],
      [
        3,
        "Regina Abad"
      ],
      [
        3,
        "Laura Almela"
      ],
      [
        3,
        "Gema Aparicio"
      ],
      [
        3,
        "Jorge Arellano"
      ],
      [
        3,
        "Adriana Barraza"
      ],
      [
        3,
        "Vanessa Bauche"
      ],
      [
        3,
        "Carlo Bernal"
      ],
      [
        3,
        "Ernesto Bog"
      ],
      [
        3,
        "Humberto Busto"
      ],
      [
        3,
        "Gerardo Campbell"
      ],
      [
        3,
        "Heriberto Castillo"
      ],
      [
        3,
        "Patricio Castillo"
      ],
      [
        3,
        "Lourdes Echevarr\u00eda"
      ],
      [
        3,
        "Emilio Echevarr\u00eda"
      ],
      [
        3,
        "Dagoberto Gama"
      ],
      [
        3,
        "Gael Garc\u00eda Bernal"
      ],
      [
        3,
        "Luisa Geliz"
      ],
      [
        3,
        "Hilda Gonz\u00e1lez"
      ],
      [
        3,
        "Edgar Gonz\u00e1lez"
      ],
      [
        3,
        "\u00c1lvaro Guerrero"
      ],
      [
        3,
        "Jonathan Herrera"
      ],
      [
        3,
        "Adriana Islas"
      ],
      [
        3,
        "Jos\u00e9 Luis Barraza"
      ],
      [
        3,
        "Juan Manuel Ramos"
      ],
      [
        3,
        "Rosa Mar\u00eda Bianchi"
      ],
      [
        3,
        "Ana Mar\u00eda Gonz\u00e1lez"
      ],
      [
        3,
        "\u00c1ngeles Mar\u00edn"
      ],
      [
        3,
        "Mauricio Mart\u00ednez"
      ],
      [
        3,
        "Roberto Medina"
      ],
      [
        3,
        "Gustavo Mu\u00f1oz"
      ],
      [
        3,
        "Rodrigo Murray"
      ],
      [
        3,
        "Rodrigo Ostap"
      ],
      [
        3,
        "Marco P\u00e9rez"
      ],
      [
        3,
        "Alma Roc\u00edo Gonz\u00e1lez"
      ],
      [
        3,
        "Dunia Sald\u00edvar"
      ],
      [
        3,
        "Bruno Salgado"
      ],
      [
        3,
        "Jorge Salinas"
      ],
      [
        3,
        "Carlos Samperio"
      ],
      [
        3,
        "Gustavo S\u00e1nchez Parra"
      ],
      [
        3,
        "Leoncio Torres"
      ],
      [
        3,
        "Adriana Varone"
      ],
      [
        2,
        "Kazuyo Togawa"
      ],
      [
        1,
        "Ricardo Dalmacci"
      ],
      [
        1,
        "Goya Toledo"
      ]
    ],
    "directors": "Alejandro G. I\u00f1\u00e1rritu",
    "budget_string": "2m USD",
    "gross_string": "20.9m USD",
    "metascore_string": "83%"
  },
  {
    "imdb_id": "tt0208092",
    "plot": "Unscrupulous boxing promoters, violent bookmakers, a Russian gangster, incompetent amateur robbers and supposedly Jewish jewelers fight to track down a priceless stolen diamond.",
    "certification": "R",
    "other_wins_count": 4,
    "other_noms_count": 7,
    "amazon": "https://www.amazon.com/Snatch-Blu-ray-Benicio-Del-Toro/dp/B00164CM46/ref=sr_1_1?crid=2LNXF3IDX5CS3&amp;keywords=snatch+blu+ray&amp;qid=1655008134&amp;sprefix=snatch+blu+ray%252Caps%252C297&amp;sr=8-1&_encoding=UTF8&tag=sianglu-20&linkCode=ur2&linkId=3d660fb09e42a7c2c3e0eed89e5ac90c&camp=1789&creative=9325",
    "justwatch": "https://justwatch.com/us/movie/snatch?utm_source=share&utm_medium=web",
    "beige_index": 1,
    "beige_angle": 29.052,
    "cast": [
      [
        5,
        "Ade"
      ],
      [
        5,
        "Elwin 'Chopper' David"
      ],
      [
        5,
        "Robbie Gee"
      ],
      [
        5,
        "Lennie James"
      ],
      [
        4,
        "Goldie"
      ],
      [
        3,
        "Benicio Del Toro"
      ],
      [
        2,
        "Jason Ninh Cao"
      ],
      [
        1,
        "William Beck"
      ],
      [
        1,
        "Andy Beckwith"
      ],
      [
        1,
        "Ewen Bremner"
      ],
      [
        1,
        "Jason Buckham"
      ],
      [
        1,
        "Mickey Cantwell"
      ],
      [
        1,
        "Teena Collins"
      ],
      [
        1,
        "Nicola Collins"
      ],
      [
        1,
        "Charles Cork"
      ],
      [
        1,
        "James Cunningham"
      ],
      [
        1,
        "Sorcha Cusack"
      ],
      [
        1,
        "Mickey Dee"
      ],
      [
        1,
        "Liam Donaghy"
      ],
      [
        1,
        "Sam Douglas"
      ],
      [
        1,
        "Austin Drage"
      ],
      [
        1,
        "Tim Faraday"
      ],
      [
        1,
        "Dennis Farina"
      ],
      [
        1,
        "John Farnell"
      ],
      [
        1,
        "Jason Flemyng"
      ],
      [
        1,
        "Adam Fogerty"
      ],
      [
        1,
        "Alan Ford"
      ],
      [
        1,
        "Stephen Graham"
      ],
      [
        1,
        "Sid Hoare"
      ],
      [
        1,
        "Michael Hughes"
      ],
      [
        1,
        "Ronald Isaac"
      ],
      [
        1,
        "Vinnie Jones"
      ],
      [
        1,
        "Chuck Julian"
      ],
      [
        1,
        "Dave Legeno"
      ],
      [
        1,
        "Liam McMahon"
      ],
      [
        1,
        "Eric Meyers"
      ],
      [
        1,
        "Paul O'Boyle"
      ],
      [
        1,
        "Shaun Pearson"
      ],
      [
        1,
        "Brad Pitt"
      ],
      [
        1,
        "Mike Reid"
      ],
      [
        1,
        "Jimmy Roussounis"
      ],
      [
        1,
        "Sidney Sedin"
      ],
      [
        1,
        "Rade Serbedzija"
      ],
      [
        1,
        "Andrew Shield"
      ],
      [
        1,
        "Dean Smith"
      ],
      [
        1,
        "Roy Snell"
      ],
      [
        1,
        "Jason Statham"
      ],
      [
        1,
        "Trevor Steedman"
      ],
      [
        1,
        "Yuri Stepanov"
      ],
      [
        1,
        "Peter Szakacs"
      ],
      [
        1,
        "John Taheny"
      ],
      [
        1,
        "Mick Theo"
      ],
      [
        1,
        "Andy Till"
      ],
      [
        1,
        "Velibor Topic"
      ],
      [
        1,
        "James Warren"
      ],
      [
        1,
        "Scott Welch"
      ],
      [
        1,
        "Joe Williams"
      ]
    ],
    "directors": "Guy Ritchie",
    "budget_string": "8.6m USD",
    "gross_string": "83.6m USD",
    "metascore_string": "55%"
  },
  {
    "imdb_id": "tt0209144",
    "plot": "A man with short-term memory loss attempts to track down his wife's murderer.",
    "certification": "R",
    "other_wins_count": 57,
    "other_noms_count": 58,
    "amazon": "https://www.amazon.com/Memento-Guy-Pearce/dp/B0763T3HC6/ref=sr_1_1?crid=3I1EGOK2GNJLL&amp;keywords=memento&amp;qid=1654833204&amp;s=movies-tv&amp;sprefix=me%252Cmovies-tv-intl-ship%252C483&amp;sr=1-1&_encoding=UTF8&tag=sianglu-20&linkCode=ur2&linkId=c544e8e75eb46102377bfd4c9b739b96&camp=1789&creative=9325",
    "justwatch": "https://justwatch.com/us/movie/memento?utm_source=share&utm_medium=web",
    "beige_index": 1,
    "beige_angle": 0.0,
    "cast": [
      [
        1,
        "Mark Boone Junior"
      ],
      [
        1,
        "Kimberly Campbell"
      ],
      [
        1,
        "Russ Fega"
      ],
      [
        1,
        "Jorja Fox"
      ],
      [
        1,
        "Larry Holden"
      ],
      [
        1,
        "Callum Keith Rennie"
      ],
      [
        1,
        "Thomas Lennon"
      ],
      [
        1,
        "Carrie-Anne Moss"
      ],
      [
        1,
        "Marianne Muellerleile"
      ],
      [
        1,
        "Joe Pantoliano"
      ],
      [
        1,
        "Guy Pearce"
      ],
      [
        1,
        "Harriet Sansom Harris"
      ],
      [
        1,
        "Stephen Tobolowsky"
      ]
    ],
    "directors": "Christopher Nolan",
    "budget_string": "9m USD",
    "gross_string": "40m USD",
    "metascore_string": "80%"
  },
  {
    "imdb_id": "tt0180093",
    "plot": "The drug-induced utopias of four Coney Island people are shattered when their addictions run deep.",
    "certification": "R",
    "other_wins_count": 37,
    "other_noms_count": 69,
    "amazon": "https://www.amazon.com/Requiem-Dream-Directors-Cut-Blu-ray/dp/B00284BNKC/ref=sr_1_4?crid=2HT2DSMQEIB1D&amp;keywords=requiem+for+a+dream&amp;qid=1654843978&amp;s=movies-tv&amp;sprefix=requiem+for+%252Cmovies-tv%252C973&amp;sr=1-4&_encoding=UTF8&tag=sianglu-20&linkCode=ur2&linkId=8c21e5cd6eb29de480975776f90bc5c1&camp=1789&creative=9325",
    "justwatch": "https://justwatch.com/us/movie/requiem-for-a-dream?utm_source=share&utm_medium=web",
    "beige_index": 2,
    "beige_angle": 51.4035,
    "cast": [
      [
        5,
        "Te'ron A. O'Neal"
      ],
      [
        5,
        "Aliya Campbell"
      ],
      [
        5,
        "Bryan Chattoo"
      ],
      [
        5,
        "Peter Cheyenne"
      ],
      [
        5,
        "Keith David"
      ],
      [
        5,
        "Eddie De Harp"
      ],
      [
        5,
        "Denise Dowse"
      ],
      [
        5,
        "Leland Gantt"
      ],
      [
        5,
        "Marlon Wayans"
      ],
      [
        5,
        "Ajay Naidu"
      ],
      [
        5,
        "Samia Shoaib"
      ],
      [
        3,
        "Olga Merediz"
      ],
      [
        3,
        "Ricardo Vi\u00f1as"
      ],
      [
        3,
        "Ami Goodheart"
      ],
      [
        2,
        "Lianna Pai"
      ],
      [
        1,
        "Abraham Abraham"
      ],
      [
        1,
        "Abraham Aronofsky"
      ],
      [
        1,
        "Charlotte Aronofsky"
      ],
      [
        1,
        "Stanley B. Herman"
      ],
      [
        1,
        "Dylan Baker"
      ],
      [
        1,
        "Gregg Bello"
      ],
      [
        1,
        "Bill Buell"
      ],
      [
        1,
        "Ellen Burstyn"
      ],
      [
        1,
        "James Chinlund"
      ],
      [
        1,
        "Ben Cohen"
      ],
      [
        1,
        "Eric Cohen"
      ],
      [
        1,
        "Jennifer Connelly"
      ],
      [
        1,
        "Brian Costello"
      ],
      [
        1,
        "Jenny Decker"
      ],
      [
        1,
        "Robert Dylan Cohen"
      ],
      [
        1,
        "Scott Franklin"
      ],
      [
        1,
        "Allison Furman"
      ],
      [
        1,
        "Sean Gullette"
      ],
      [
        1,
        "Marcia Jean Kurtz"
      ],
      [
        1,
        "Michael Kaycheck"
      ],
      [
        1,
        "Louise Lasser"
      ],
      [
        1,
        "Jared Leto"
      ],
      [
        1,
        "Heather Litteer"
      ],
      [
        1,
        "Peter Maloney"
      ],
      [
        1,
        "Mark Margolis"
      ],
      [
        1,
        "Chas Mastin"
      ],
      [
        1,
        "Christopher McDonald"
      ],
      [
        1,
        "Todd Miller"
      ],
      [
        1,
        "Jack O'Connell"
      ],
      [
        1,
        "Shaun O'Hagan"
      ],
      [
        1,
        "Jimmie Ray Weeks"
      ],
      [
        1,
        "Geordan Reisner"
      ],
      [
        1,
        "Janet Sarno"
      ],
      [
        1,
        "Hubert Selby Jr."
      ],
      [
        1,
        "Ben Shenkman"
      ],
      [
        1,
        "Suzanne Shepherd"
      ],
      [
        1,
        "Henry Stram"
      ],
      [
        1,
        "Nina Zavarin"
      ]
    ],
    "directors": "Darren Aronofsky",
    "budget_string": "4.5m USD",
    "gross_string": "7.4m USD",
    "metascore_string": "68%"
  },
  {
    "imdb_id": "tt0172495",
    "plot": "A former Roman General sets out to exact vengeance against the corrupt emperor who murdered his family and sent him into slavery.",
    "certification": "R",
    "other_wins_count": 54,
    "other_noms_count": 106,
    "amazon": "https://www.amazon.com/Gladiator-Remastered-Blu-ray-Region-Free/dp/B001XCW6W4/ref=tmm_blu_title_0?_encoding=UTF8&amp;amp&amp;qid=1654830535&amp;amp&amp;sr=8-12&_encoding=UTF8&tag=sianglu-20&linkCode=ur2&linkId=80ae2591edd794bb07f44c3a0ec6df91&camp=1789&creative=9325",
    "justwatch": "https://justwatch.com/us/movie/gladiator-2000?utm_source=share&utm_medium=web",
    "beige_index": 1,
    "beige_angle": 27.0,
    "cast": [
      [
        5,
        "Djimon Hounsou"
      ],
      [
        4,
        "Said Amel"
      ],
      [
        4,
        "Omid Djalili"
      ],
      [
        3,
        "Giannina Facio"
      ],
      [
        3,
        "Mark Lewis"
      ],
      [
        1,
        "Chick Allan"
      ],
      [
        1,
        "Tomas Arana"
      ],
      [
        1,
        "Al Ashton"
      ],
      [
        1,
        "David Bailie"
      ],
      [
        1,
        "Ray Calleja"
      ],
      [
        1,
        "Giorgio Cantarini"
      ],
      [
        1,
        "Allan Corduner"
      ],
      [
        1,
        "Russell Crowe"
      ],
      [
        1,
        "Tony Curran"
      ],
      [
        1,
        "Billy Dowd"
      ],
      [
        1,
        "Tommy Flanagan"
      ],
      [
        1,
        "Gilly Gilchrist"
      ],
      [
        1,
        "Richard Harris"
      ],
      [
        1,
        "David Hemmings"
      ],
      [
        1,
        "David J. Nicholls"
      ],
      [
        1,
        "Derek Jacobi"
      ],
      [
        1,
        "Chris Kell"
      ],
      [
        1,
        "Adam Levy"
      ],
      [
        1,
        "Nicholas McGaughey"
      ],
      [
        1,
        "Michael Mellinger"
      ],
      [
        1,
        "Ralf Moeller"
      ],
      [
        1,
        "Connie Nielsen"
      ],
      [
        1,
        "Joaquin Phoenix"
      ],
      [
        1,
        "John Quinn"
      ],
      [
        1,
        "Alun Raglan"
      ],
      [
        1,
        "Oliver Reed"
      ],
      [
        1,
        "David Schofield"
      ],
      [
        1,
        "John Shrapnel"
      ],
      [
        1,
        "Brian Smyj"
      ],
      [
        1,
        "Sven-Ole Thorsen"
      ],
      [
        1,
        "Spencer Treat Clark"
      ]
    ],
    "directors": "Ridley Scott",
    "budget_string": "103m USD",
    "gross_string": "465.4m USD",
    "metascore_string": "67%"
  },
  {
    "imdb_id": "tt0198781",
    "plot": "In order to power the city, monsters have to scare children so that they scream. However, the children are toxic to the monsters, and after a child gets through, two monsters realize things may not be what they think.",
    "certification": "G",
    "other_wins_count": 14,
    "other_noms_count": 38,
    "amazon": "https://www.amazon.com/MONSTERS-INC-Blu-ray-John-Goodman/dp/B07TQTHCX3/ref=tmm_blu_title_0?_encoding=UTF8&amp;amp&amp;qid=1655184048&amp;amp&amp;sr=8-3&_encoding=UTF8&tag=sianglu-20&linkCode=ur2&linkId=567f6e869609f1e84d12fd4cc5a55c54&camp=1789&creative=9325",
    "justwatch": "https://justwatch.com/us/movie/monsters-incorporated?utm_source=share&utm_medium=web",
    "beige_index": 1,
    "beige_angle": 8.1,
    "cast": [
      [
        5,
        "Marc John Jefferies"
      ],
      [
        1,
        "Bret 'Brook' Parker"
      ],
      [
        1,
        "Steve Buscemi"
      ],
      [
        1,
        "James Coburn"
      ],
      [
        1,
        "Gino Conforti"
      ],
      [
        1,
        "Patti Deutsch"
      ],
      [
        1,
        "Pete Docter"
      ],
      [
        1,
        "Bobby Edner"
      ],
      [
        1,
        "Ashley Edner"
      ],
      [
        1,
        "Bill Farmer"
      ],
      [
        1,
        "Teresa Ganzel"
      ],
      [
        1,
        "Daniel Gerson"
      ],
      [
        1,
        "Mary Gibbs"
      ],
      [
        1,
        "Taylor Gifaldi"
      ],
      [
        1,
        "John Goodman"
      ],
      [
        1,
        "Bonnie Hunt"
      ],
      [
        1,
        "Joe Lala"
      ],
      [
        1,
        "Samuel Lord Black"
      ],
      [
        1,
        "Noah Luke"
      ],
      [
        1,
        "Danny Mann"
      ],
      [
        1,
        "Laraine Newman"
      ],
      [
        1,
        "Frank Oz"
      ],
      [
        1,
        "Kay Panabaker"
      ],
      [
        1,
        "Bob Peterson"
      ],
      [
        1,
        "Jeff Pidgeon"
      ],
      [
        1,
        "Josh Qualtieri"
      ],
      [
        1,
        "Guido Quaroni"
      ],
      [
        1,
        "Jan Rabson"
      ],
      [
        1,
        "Lisa Raggio"
      ],
      [
        1,
        "Joe Ranft"
      ],
      [
        1,
        "Sophia Ranft"
      ],
      [
        1,
        "John Ratzenberger"
      ],
      [
        1,
        "Katherine Ringgold"
      ],
      [
        1,
        "Bob Scott"
      ],
      [
        1,
        "Wallace Shawn"
      ],
      [
        1,
        "David Silverman"
      ],
      [
        1,
        "Steve Susskind"
      ],
      [
        1,
        "Jim Thornton"
      ],
      [
        1,
        "Jennifer Tilly"
      ],
      [
        1,
        "Lee Unkrich"
      ]
    ],
    "directors": "Pete Docter, David Silverman, & Lee Unkrich",
    "budget_string": "115m USD",
    "gross_string": "579.7m USD",
    "metascore_string": "79%"
  },
  {
    "imdb_id": "tt0268978",
    "plot": "After John Nash, a brilliant but asocial mathematician, accepts secret work in cryptography, his life takes a turn for the nightmarish.",
    "certification": "PG-13",
    "other_wins_count": 33,
    "other_noms_count": 69,
    "amazon": "https://www.amazon.com/Beautiful-Cinderella-Double-Feature-Blu-ray/dp/B00C7MW5EI/ref=sr_1_7?crid=3NVCB0Y3YJU8C&amp;keywords=a+beautiful+mind+blu+ray&amp;qid=1655174879&amp;sprefix=a+beautiful+mind%252Caps%252C291&amp;sr=8-7&_encoding=UTF8&tag=sianglu-20&linkCode=ur2&linkId=d6f035fa13fe0986e7d250f2c623278e&camp=1789&creative=9325",
    "justwatch": "https://justwatch.com/us/movie/a-beautiful-mind?utm_source=share&utm_medium=web",
    "beige_index": 1,
    "beige_angle": 0.0,
    "cast": [
      [
        1,
        "Tommy Allen"
      ],
      [
        1,
        "David B. Allen"
      ],
      [
        1,
        "Dave Bayer"
      ],
      [
        1,
        "Paul Bettany"
      ],
      [
        1,
        "John Blaylock"
      ],
      [
        1,
        "Patrick Blindauer"
      ],
      [
        1,
        "Bob Broder"
      ],
      [
        1,
        "Eva Burkley"
      ],
      [
        1,
        "Valentina Cardinalli"
      ],
      [
        1,
        "Vivien Cardone"
      ],
      [
        1,
        "Kent Cassella"
      ],
      [
        1,
        "Tanya Clarke"
      ],
      [
        1,
        "Jennifer Connelly"
      ],
      [
        1,
        "Russell Crowe"
      ],
      [
        1,
        "Bryce Dallas Howard"
      ],
      [
        1,
        "Jesse Doran"
      ],
      [
        1,
        "Gregory Dress"
      ],
      [
        1,
        "Will Dunham"
      ],
      [
        1,
        "Anthony Easton"
      ],
      [
        1,
        "Michael Esper"
      ],
      [
        1,
        "Teagle F. Bougere"
      ],
      [
        1,
        "Thomas F. Walsh"
      ],
      [
        1,
        "Kathleen Fellegara"
      ],
      [
        1,
        "Adam Goldberg"
      ],
      [
        1,
        "Jason Gray-Stanford"
      ],
      [
        1,
        "Ed Harris"
      ],
      [
        1,
        "Judd Hirsch"
      ],
      [
        1,
        "Cheryl Howard"
      ],
      [
        1,
        "Rance Howard"
      ],
      [
        1,
        "Jane Jenkins"
      ],
      [
        1,
        "Ed Jupp Jr."
      ],
      [
        1,
        "Brian Keith Lewis"
      ],
      [
        1,
        "Betsy Klompus"
      ],
      [
        1,
        "Josh Lucas"
      ],
      [
        1,
        "Logan McCall"
      ],
      [
        1,
        "Tom McNutt"
      ],
      [
        1,
        "Lyena Nomura"
      ],
      [
        1,
        "Carla Occhiogrosso"
      ],
      [
        1,
        "Josh Pais"
      ],
      [
        1,
        "Austin Pendleton"
      ],
      [
        1,
        "Holly Pitrago"
      ],
      [
        1,
        "Christopher Plummer"
      ],
      [
        1,
        "Anthony Rapp"
      ],
      [
        1,
        "Glenn Roberts"
      ],
      [
        1,
        "Isadore Rosenfeld"
      ],
      [
        1,
        "Matt Samson"
      ],
      [
        1,
        "Stelio Savante"
      ],
      [
        1,
        "Jillie Simon"
      ],
      [
        1,
        "Brian Smyj"
      ],
      [
        1,
        "Victor Steinbach"
      ],
      [
        1,
        "Christopher Stockton"
      ],
      [
        1,
        "Darius Stone"
      ],
      [
        1,
        "Roy Thinnes"
      ],
      [
        1,
        "Yvonne Thomas"
      ],
      [
        1,
        "Alex Toma"
      ],
      [
        1,
        "Tracey Toomey"
      ],
      [
        1,
        "Amy Walz"
      ],
      [
        1,
        "Jennifer Weedon"
      ]
    ],
    "directors": "Ron Howard",
    "budget_string": "58m USD",
    "gross_string": "316.8m USD",
    "metascore_string": "72%"
  },
  {
    "imdb_id": "tt0211915",
    "plot": "Am\u00e9lie is an innocent and naive girl in Paris with her own sense of justice. She decides to help those around her and, along the way, discovers love.",
    "certification": "R",
    "other_wins_count": 59,
    "other_noms_count": 69,
    "amazon": "https://www.amazon.com/Amelie/dp/B0087CNBFY/ref=sr_1_3?crid=1LL41KZIMR5FW&amp;keywords=amelie+dvd&amp;qid=1654930499&amp;s=movies-tv&amp;sprefix=amelie+dvd%252Cmovies-tv%252C752&amp;sr=1-3&_encoding=UTF8&tag=sianglu-20&linkCode=ur2&linkId=e92eb7f443446353459c0dc4a6da93cf&camp=1789&creative=9325",
    "justwatch": "https://justwatch.com/us/movie/amelie?utm_source=share&utm_medium=web",
    "beige_index": 1,
    "beige_angle": 5.373,
    "cast": [
      [
        4,
        "Maurice B\u00e9nichou"
      ],
      [
        4,
        "Jamel Debbouze"
      ],
      [
        1,
        "Rufus"
      ],
      [
        1,
        "Armelle"
      ],
      [
        1,
        "Marc Amyot"
      ],
      [
        1,
        "Karine Asure"
      ],
      [
        1,
        "Fran\u00e7ois Aubineau"
      ],
      [
        1,
        "Amaury Babault"
      ],
      [
        1,
        "Philippe Beautier"
      ],
      [
        1,
        "Jean-Pierre Becker"
      ],
      [
        1,
        "Fabien B\u00e9har"
      ],
      [
        1,
        "Fran\u00e7ois Bercovici"
      ],
      [
        1,
        "Eug\u00e8ne Berthier"
      ],
      [
        1,
        "Dominique Bettenfeld"
      ],
      [
        1,
        "Franck-Olivier Bonnet"
      ],
      [
        1,
        "Christiane Bopp"
      ],
      [
        1,
        "Charles-Roger Bour"
      ],
      [
        1,
        "Jean-Pol Brissart"
      ],
      [
        1,
        "Urbain Cancelier"
      ],
      [
        1,
        "Fabienne Chaudat"
      ],
      [
        1,
        "Lorella Cravotta"
      ],
      [
        1,
        "Andr\u00e9e Damant"
      ],
      [
        1,
        "Paule Dar\u00e9"
      ],
      [
        1,
        "Jean Darie"
      ],
      [
        1,
        "Artus de Penguern"
      ],
      [
        1,
        "Val\u00e9riane de Villeneuve"
      ],
      [
        1,
        "Marie-Laure Descoureaux"
      ],
      [
        1,
        "Kevin Dias"
      ],
      [
        1,
        "Andr\u00e9 Dussollier"
      ],
      [
        1,
        "Alain Floret"
      ],
      [
        1,
        "Robert Gendreu"
      ],
      [
        1,
        "Thierry Gibault"
      ],
      [
        1,
        "Flora Guiet"
      ],
      [
        1,
        "Raymonde Heudeline"
      ],
      [
        1,
        "Ticky Holgado"
      ],
      [
        1,
        "R\u00e9gis Iacono"
      ],
      [
        1,
        "Jonathan Joss"
      ],
      [
        1,
        "Mathieu Kassovitz"
      ],
      [
        1,
        "Julianna Kovacs"
      ],
      [
        1,
        "Myriam Labb\u00e9"
      ],
      [
        1,
        "Jerry Lucas"
      ],
      [
        1,
        "Mady Malroux"
      ],
      [
        1,
        "Monette Malroux"
      ],
      [
        1,
        "Claire Maurier"
      ],
      [
        1,
        "Serge Merlin"
      ],
      [
        1,
        "Fr\u00e9d\u00e9ric Mitterrand"
      ],
      [
        1,
        "Clotilde Mollet"
      ],
      [
        1,
        "Franck Monier"
      ],
      [
        1,
        "Yolande Moreau"
      ],
      [
        1,
        "Isabelle Nanty"
      ],
      [
        1,
        "Philippe Paimblanc"
      ],
      [
        1,
        "Frankie Pain"
      ],
      [
        1,
        "Luc Palun"
      ],
      [
        1,
        "Patrick Paroux"
      ],
      [
        1,
        "Claude Perron"
      ],
      [
        1,
        "Isis Peyrade"
      ],
      [
        1,
        "Dominique Pinon"
      ],
      [
        1,
        "Michel Robin"
      ],
      [
        1,
        "Jean Rupert"
      ],
      [
        1,
        "Audrey Tautou"
      ],
      [
        1,
        "Sophie Tellier"
      ],
      [
        1,
        "Spencer Tracy"
      ],
      [
        1,
        "Jacques Viala"
      ],
      [
        1,
        "Fran\u00e7ois Viaur"
      ],
      [
        1,
        "Guillaume Viry"
      ],
      [
        1,
        "G\u00e9rald Weingand"
      ],
      [
        1,
        "Val\u00e9rie Zarrouk"
      ]
    ],
    "directors": "Jean-Pierre Jeunet",
    "budget_string": "10m USD",
    "gross_string": "174.1m USD",
    "metascore_string": "69%"
  },
  {
    "imdb_id": "tt0245429",
    "plot": "During her family's move to the suburbs, a sullen 10-year-old girl wanders into a world ruled by gods, witches, and spirits, and where humans are changed into beasts.",
    "certification": "PG",
    "other_wins_count": 57,
    "other_noms_count": 31,
    "amazon": "https://www.amazon.com/Spirited-Away-Bluray-Combo-Blu-ray/dp/B074121MFR/ref=sr_1_5?crid=19LVWCMTRMNHU&amp;keywords=spirited+away&amp;qid=1654829732&amp;sprefix=spirited+aw%252Caps%252C724&amp;sr=8-5&_encoding=UTF8&tag=sianglu-20&linkCode=ur2&linkId=18502bc3cf02b592ee88194caf7e9985&camp=1789&creative=9325",
    "justwatch": "https://justwatch.com/us/movie/spirited-away?utm_source=share&utm_medium=web",
    "beige_index": 2,
    "beige_angle": 45.0,
    "cast": [
      [
        2,
        "Masahiro Asano"
      ],
      [
        2,
        "Sachie Azuma"
      ],
      [
        2,
        "Tatsuya Gash\u00fbin"
      ],
      [
        2,
        "Koba Hayashi"
      ],
      [
        2,
        "Kazutaka Hayashida"
      ],
      [
        2,
        "Rumi Hiiragi"
      ],
      [
        2,
        "Miyu Irino"
      ],
      [
        2,
        "Tetsur\u00f4 Ishibashi"
      ],
      [
        2,
        "Naoto Kaji"
      ],
      [
        2,
        "Tsunehiko Kamij\u00f4"
      ],
      [
        2,
        "Ry\u00fbnosuke Kamiki"
      ],
      [
        2,
        "Shinobu Katabuchi"
      ],
      [
        2,
        "Yayoi Kazuki"
      ],
      [
        2,
        "Noriko Kitou"
      ],
      [
        2,
        "Masayuki Kizu"
      ],
      [
        2,
        "Minako Masuda"
      ],
      [
        2,
        "Katsuhisa Matsuo"
      ],
      [
        2,
        "Mina Meguro"
      ],
      [
        2,
        "Takashi Nait\u00f4"
      ],
      [
        2,
        "Akio Nakamura"
      ],
      [
        2,
        "Mari Natsuki"
      ],
      [
        2,
        "Y\u00f4 \u00d4izumi"
      ],
      [
        2,
        "Makiko Oku"
      ],
      [
        2,
        "Y\u00f4ko \u00d4no"
      ],
      [
        2,
        "Takehiko Ono"
      ],
      [
        2,
        "Orika Ono"
      ],
      [
        2,
        "Shir\u00f4 Sait\u00f4"
      ],
      [
        2,
        "Mayumi Sako"
      ],
      [
        2,
        "Yasuko Sawaguchi"
      ],
      [
        2,
        "Katsutomo Sh\u00eebara"
      ],
      [
        2,
        "Sonoko Soeda"
      ],
      [
        2,
        "Bunta Sugawara"
      ],
      [
        2,
        "Yoshitaka Sukegawa"
      ],
      [
        2,
        "Aki Tachikawa"
      ],
      [
        2,
        "Miwa Takachi"
      ],
      [
        2,
        "Hiromi Takeuchi"
      ],
      [
        2,
        "Yumi Tamai"
      ],
      [
        2,
        "Shinji Tokumaru"
      ],
      [
        2,
        "Akiko Tomihira"
      ],
      [
        2,
        "Shigeyuki Totsugi"
      ],
      [
        2,
        "Keiko Tsukamoto"
      ],
      [
        2,
        "Shigeru Wakita"
      ],
      [
        2,
        "Rina Yamada"
      ],
      [
        2,
        "Kaori Yamagata"
      ],
      [
        2,
        "Ikuko Yamamoto"
      ],
      [
        2,
        "Michiko Yamamoto"
      ],
      [
        2,
        "Noriko Yamaya"
      ],
      [
        2,
        "Ken Yasuda"
      ]
    ],
    "directors": "Hayao Miyazaki",
    "budget_string": "19m USD",
    "gross_string": "355.7m USD",
    "metascore_string": "96%"
  },
  {
    "imdb_id": "tt0120737",
    "plot": "A meek Hobbit from the Shire and eight companions set out on a journey to destroy the powerful One Ring and save Middle-earth from the Dark Lord Sauron.",
    "certification": "PG-13",
    "other_wins_count": 117,
    "other_noms_count": 126,
    "amazon": "https://www.amazon.com/Lord-Rings-Fellowship-Extended-Editions/dp/B0026L7H20/ref=sr_1_7?crid=2ZEHUMYPWUHF6&amp;keywords=fellowship+of+the+ring+blu-ray&amp;qid=1654826575&amp;sprefix=fellowship+of+the+ring+blu-ray%252Caps%252C421&amp;sr=8-7&_encoding=UTF8&tag=sianglu-20&linkCode=ur2&linkId=b2372d10e7938c8211de2c3267ed10cb&camp=1789&creative=9325",
    "justwatch": "https://justwatch.com/us/movie/lord-of-the-rings-i-the-fellowship-of-the-ring?utm_source=share&utm_medium=web",
    "beige_index": 1,
    "beige_angle": 16.0722,
    "cast": [
      [
        4,
        "Sala Baker"
      ],
      [
        4,
        "Semi Kuresa"
      ],
      [
        4,
        "Lawrence Makoare"
      ],
      [
        4,
        "Jono Manks"
      ],
      [
        4,
        "Shane Rangi"
      ],
      [
        1,
        "Noel Appleby"
      ],
      [
        1,
        "Sean Astin"
      ],
      [
        1,
        "Sean Bean"
      ],
      [
        1,
        "Victoria Beynon-Cole"
      ],
      [
        1,
        "Cate Blanchett"
      ],
      [
        1,
        "Orlando Bloom"
      ],
      [
        1,
        "Billy Boyd"
      ],
      [
        1,
        "Jed Brophy"
      ],
      [
        1,
        "Peter Corrigan"
      ],
      [
        1,
        "Marton Csokas"
      ],
      [
        1,
        "Lori Dungey"
      ],
      [
        1,
        "Megan Edwards"
      ],
      [
        1,
        "Michael Elsworth"
      ],
      [
        1,
        "Mark Ferguson"
      ],
      [
        1,
        "Norman Forsey"
      ],
      [
        1,
        "Phil Grieve"
      ],
      [
        1,
        "Lee Hartley"
      ],
      [
        1,
        "Ian Holm"
      ],
      [
        1,
        "Alan Howard"
      ],
      [
        1,
        "Billy Jackson"
      ],
      [
        1,
        "Katie Jackson"
      ],
      [
        1,
        "William Johnson"
      ],
      [
        1,
        "Sam La Hood"
      ],
      [
        1,
        "Christopher Lee"
      ],
      [
        1,
        "Thomas McGinty"
      ],
      [
        1,
        "Brent McIntyre"
      ],
      [
        1,
        "Ian McKellen"
      ],
      [
        1,
        "Bret McKenzie"
      ],
      [
        1,
        "Peter McKenzie"
      ],
      [
        1,
        "Sarah McLeod"
      ],
      [
        1,
        "Dominic Monaghan"
      ],
      [
        1,
        "Elizabeth Moody"
      ],
      [
        1,
        "Viggo Mortensen"
      ],
      [
        1,
        "Ian Mune"
      ],
      [
        1,
        "Kate O'Rourke"
      ],
      [
        1,
        "Craig Parker"
      ],
      [
        1,
        "Ben Price"
      ],
      [
        1,
        "Cameron Rhodes"
      ],
      [
        1,
        "John Rhys-Davies"
      ],
      [
        1,
        "Thomas Robins"
      ],
      [
        1,
        "Vincent Roxburgh"
      ],
      [
        1,
        "Martyn Sanderson"
      ],
      [
        1,
        "Brian Sergent"
      ],
      [
        1,
        "Andy Serkis"
      ],
      [
        1,
        "Harry Sinclair"
      ],
      [
        1,
        "Chris Streeter"
      ],
      [
        1,
        "Liv Tyler"
      ],
      [
        1,
        "Clinton Ulyatt"
      ],
      [
        1,
        "David Weatherley"
      ],
      [
        1,
        "Hugo Weaving"
      ],
      [
        1,
        "Elijah Wood"
      ]
    ],
    "directors": "Peter Jackson",
    "budget_string": "93m USD",
    "gross_string": "898.1m USD",
    "metascore_string": "92%"
  },
  {
    "imdb_id": "tt0264464",
    "plot": "Barely 21 yet, Frank is a skilled forger who has passed as a doctor, lawyer and pilot. FBI agent Carl becomes obsessed with tracking down the con man, who only revels in the pursuit.",
    "certification": "PG-13",
    "other_wins_count": 16,
    "other_noms_count": 44,
    "amazon": "https://www.amazon.com/Catch-Me-You-Can-Blu-ray/dp/B0074GV1C4/ref=sr_1_2?crid=1LE9RHBZR79F9&amp;keywords=catch+me+if+you+can+blu+ray&amp;qid=1655182401&amp;sprefix=catch+me+if+you+can+blu+ray%252Caps%252C296&amp;sr=8-2&_encoding=UTF8&tag=sianglu-20&linkCode=ur2&linkId=d4285c22aa087ff2133d820affd91bf6&camp=1789&creative=9325",
    "justwatch": "https://justwatch.com/us/movie/catch-me-if-you-can?utm_source=share&utm_medium=web",
    "beige_index": 1,
    "beige_angle": 15.705,
    "cast": [
      [
        5,
        "Eugene Fleming"
      ],
      [
        5,
        "Ellis Hall"
      ],
      [
        5,
        "Benita Krista Nall"
      ],
      [
        5,
        "Andrew Meeks"
      ],
      [
        3,
        "Ana Maria Quintana"
      ],
      [
        3,
        "Mercedes Cornett"
      ],
      [
        2,
        "Jennifer Kan"
      ],
      [
        1,
        "Frank Abagnale Jr."
      ],
      [
        1,
        "Amy Acker"
      ],
      [
        1,
        "Amy Adams"
      ],
      [
        1,
        "Jamie Anderson"
      ],
      [
        1,
        "Jim Antonio"
      ],
      [
        1,
        "Candice Azzara"
      ],
      [
        1,
        "Mike Baldridge"
      ],
      [
        1,
        "Elizabeth Banks"
      ],
      [
        1,
        "Nathalie Baye"
      ],
      [
        1,
        "Alex Bisping"
      ],
      [
        1,
        "Jean-Fran\u00e7ois Blanchard"
      ],
      [
        1,
        "Stan Bly"
      ],
      [
        1,
        "Jane Bodle"
      ],
      [
        1,
        "Joshua Boyd"
      ],
      [
        1,
        "Jonathan Brent"
      ],
      [
        1,
        "James Brolin"
      ],
      [
        1,
        "Kitty Carlisle"
      ],
      [
        1,
        "Lilyan Chauvin"
      ],
      [
        1,
        "Ashley Cohen"
      ],
      [
        1,
        "Kelly Cohen"
      ],
      [
        1,
        "Lauren Cohn"
      ],
      [
        1,
        "Jessica Collins"
      ],
      [
        1,
        "Thomas Crawford"
      ],
      [
        1,
        "Robert Curtis Brown"
      ],
      [
        1,
        "Jonathan Dankner"
      ],
      [
        1,
        "Fred Datig"
      ],
      [
        1,
        "Kyle Davis"
      ],
      [
        1,
        "Alfred Dennis"
      ],
      [
        1,
        "Leonardo DiCaprio"
      ],
      [
        1,
        "Kaitlin Doubleday"
      ],
      [
        1,
        "Celine du Tertre"
      ],
      [
        1,
        "James DuMont"
      ],
      [
        1,
        "Stephen Dunham"
      ],
      [
        1,
        "Patrice Dussault"
      ],
      [
        1,
        "Steve Eastin"
      ],
      [
        1,
        "Shane Edelman"
      ],
      [
        1,
        "Jane Edith Wilson"
      ],
      [
        1,
        "Chris Ellis"
      ],
      [
        1,
        "Joel Ewing"
      ],
      [
        1,
        "Jimmie F. Skaggs"
      ],
      [
        1,
        "John Finn"
      ],
      [
        1,
        "Joe Garagiola"
      ],
      [
        1,
        "Jennifer Garner"
      ],
      [
        1,
        "Mathieu Gaudreault"
      ],
      [
        1,
        "Brian Goodman"
      ],
      [
        1,
        "Dave Hager"
      ],
      [
        1,
        "Tom Hanks"
      ],
      [
        1,
        "Kam Heskin"
      ],
      [
        1,
        "Jeremy Howard"
      ],
      [
        1,
        "Brian Howe"
      ],
      [
        1,
        "Kelly Hutchinson"
      ],
      [
        1,
        "Alex Hyde-White"
      ],
      [
        1,
        "Jasmine Jessica Anthony"
      ],
      [
        1,
        "Frank John Hughes"
      ],
      [
        1,
        "Brandon Keener"
      ],
      [
        1,
        "Deborah Kellner"
      ],
      [
        1,
        "Max Kerstein"
      ],
      [
        1,
        "Donna Kimball"
      ],
      [
        1,
        "Matthew Kimbrough"
      ],
      [
        1,
        "Jack Knight"
      ],
      [
        1,
        "Thomas Kopache"
      ],
      [
        1,
        "Sarah Lancaster"
      ],
      [
        1,
        "Roger L\u00e9ger"
      ],
      [
        1,
        "Nancy Lenehan"
      ],
      [
        1,
        "Jennifer Manley"
      ],
      [
        1,
        "Jill Matson-Sachoff"
      ],
      [
        1,
        "Kelly McNair"
      ],
      [
        1,
        "Steven Meizler"
      ],
      [
        1,
        "Maggie Mellin"
      ],
      [
        1,
        "Ritchie Montgomery"
      ],
      [
        1,
        "James Morrison"
      ],
      [
        1,
        "Jamie Moss"
      ],
      [
        1,
        "Jan Munroe"
      ],
      [
        1,
        "J. Patrick McCormack"
      ],
      [
        1,
        "Robert Peters"
      ],
      [
        1,
        "Ellen Pompeo"
      ],
      [
        1,
        "Anthony Powers"
      ],
      [
        1,
        "Ray Proscia"
      ],
      [
        1,
        "Gerald R. Molen"
      ],
      [
        1,
        "Jaime Ray Newman"
      ],
      [
        1,
        "Sarah Rush"
      ],
      [
        1,
        "Morgan Rusler"
      ],
      [
        1,
        "Robert Ruth"
      ],
      [
        1,
        "Martin Sheen"
      ],
      [
        1,
        "Angela Sorensen"
      ],
      [
        1,
        "Robert Symonds"
      ],
      [
        1,
        "Guy Thauvette"
      ],
      [
        1,
        "Patrick Thomas O'Brien"
      ],
      [
        1,
        "Malachi Throne"
      ],
      [
        1,
        "Paul Todd"
      ],
      [
        1,
        "Margaret Travolta"
      ],
      [
        1,
        "Guy-Daniel Tremblay"
      ],
      [
        1,
        "Christopher Walken"
      ],
      [
        1,
        "Steve Witting"
      ],
      [
        1,
        "Wendy Worthington"
      ]
    ],
    "directors": "Steven Spielberg",
    "budget_string": "52m USD",
    "gross_string": "352.1m USD",
    "metascore_string": "75%"
  },
  {
    "imdb_id": "tt0317248",
    "plot": "In the slums of Rio, two kids' paths diverge as one struggles to become a photographer and the other a kingpin.",
    "certification": "R",
    "other_wins_count": 74,
    "other_noms_count": 46,
    "amazon": "https://www.amazon.com/City-God-Digital-Alexandre-Rodrigues/dp/B004SIP6GI/ref=tmm_dvd_swatch_0?_encoding=UTF8&amp;qid=1654828095&amp;sr=8-1&_encoding=UTF8&tag=sianglu-20&linkCode=ur2&linkId=c452e3d3fe5c9835e0490e318b27d391&camp=1789&creative=9325",
    "justwatch": "https://justwatch.com/us/movie/city-of-god?utm_source=share&utm_medium=web",
    "beige_index": 4,
    "beige_angle": 122.7564,
    "cast": [
      [
        5,
        "Paulo 'Jacar\u00e9' C\u00e9sar"
      ],
      [
        5,
        "Olivia Ara\u00fajo"
      ],
      [
        5,
        "Marcello Costa"
      ],
      [
        5,
        "Marcos Coutinho"
      ],
      [
        5,
        "Darlan Cunha"
      ],
      [
        5,
        "Renato de Souza"
      ],
      [
        5,
        "Leandro Firmino"
      ],
      [
        5,
        "Seu Jorge"
      ],
      [
        5,
        "Edson Montenegro"
      ],
      [
        5,
        "Dani Ornellas"
      ],
      [
        5,
        "Luis Ot\u00e1vio"
      ],
      [
        5,
        "Alexandre Rodrigues"
      ],
      [
        5,
        "Roberta Rodrigues"
      ],
      [
        5,
        "Rosangela Rodrigues"
      ],
      [
        5,
        "Rubens Sabino"
      ],
      [
        5,
        "Babu Santana"
      ],
      [
        5,
        "Mary Sheila"
      ],
      [
        5,
        "Douglas Silva"
      ],
      [
        5,
        "Ad\u00e3o Xalebarad\u00e3"
      ],
      [
        3,
        "Micael"
      ],
      [
        3,
        "Christiano"
      ],
      [
        3,
        "Eduardo 'BR' Piranha"
      ],
      [
        3,
        "Alexandre 'China' Tavares"
      ],
      [
        3,
        "F\u00e1bio 'Dog' da Cunha"
      ],
      [
        3,
        "R\u00f3mulo 'Guinomo' Sech"
      ],
      [
        3,
        "Marcos 'Kikito' Junqueira"
      ],
      [
        3,
        "Carlos 'Lencinho' Smith"
      ],
      [
        3,
        "Marcello 'M\u00e1scara' Alves"
      ],
      [
        3,
        "Omar 'Mazinho' Barcelos"
      ],
      [
        3,
        "Wanderson 'Pet\u00e3o' Lopes"
      ],
      [
        3,
        "Nelson Amaral"
      ],
      [
        3,
        "Otto Amorim"
      ],
      [
        3,
        "L\u00facio Andrey"
      ],
      [
        3,
        "Marcelo Ant\u00f3nio Santos"
      ],
      [
        3,
        "Wallace Ara\u00fajo"
      ],
      [
        3,
        "Marcelo Ara\u00fajo"
      ],
      [
        3,
        "Wendel Barros"
      ],
      [
        3,
        "Diego Batista"
      ],
      [
        3,
        "S\u00e9rgio Bispo"
      ],
      [
        3,
        "Edward Boggiss"
      ],
      [
        3,
        "Alice Braga"
      ],
      [
        3,
        "Bartolomeu Braga"
      ],
      [
        3,
        "Anderson Bruno Marques"
      ],
      [
        3,
        "Gero Camilo"
      ],
      [
        3,
        "Luis Carlos Oliveira"
      ],
      [
        3,
        "Luiz Carlos Ribeiro Seixas"
      ],
      [
        3,
        "Luis Carlos Rodrigues Oliveira"
      ],
      [
        3,
        "F\u00e1bio Castor Concei\u00e7\u00e3o"
      ],
      [
        3,
        "Alexander Cerqueira"
      ],
      [
        3,
        "Cl\u00e1udio C\u00e9sar"
      ],
      [
        3,
        "J\u00falio C\u00e9sar Siqueira"
      ],
      [
        3,
        "M\u00e1rcio Costa"
      ],
      [
        3,
        "Wellington Costa Ricardo"
      ],
      [
        3,
        "J\u00e9ssica da Silva"
      ],
      [
        3,
        "Rafael de Castro"
      ],
      [
        3,
        "Rafael de Souza"
      ],
      [
        3,
        "Leandro Dias Batista"
      ],
      [
        3,
        "Alex dos Santos"
      ],
      [
        3,
        "Pierre dos Santos"
      ],
      [
        3,
        "Christian Duurvoort"
      ],
      [
        3,
        "Gustavo Engr\u00e1cia"
      ],
      [
        3,
        "Guilherme Estevam"
      ],
      [
        3,
        "Karina Falc\u00e3o"
      ],
      [
        3,
        "Anderson Faria"
      ],
      [
        3,
        "Vinicius Faria"
      ],
      [
        3,
        "Jota Farias"
      ],
      [
        3,
        "Diego Ferreira"
      ],
      [
        3,
        "Maur\u00edcio Figueiras"
      ],
      [
        3,
        "Dami\u00e3o Firmino"
      ],
      [
        3,
        "Denise Fonseca"
      ],
      [
        3,
        "Rafael Fontenele"
      ],
      [
        3,
        "Guti Fraga"
      ],
      [
        3,
        "Ramon Francisco"
      ],
      [
        3,
        "Euclides Garcia"
      ],
      [
        3,
        "Leo Generoso"
      ],
      [
        3,
        "Emerson Gomes"
      ],
      [
        3,
        "Michel Gomes"
      ],
      [
        3,
        "Wemerson Gon\u00e7alves"
      ],
      [
        3,
        "Michele Gon\u00e7alves"
      ],
      [
        3,
        "Fabiano Gon\u00e7alves"
      ],
      [
        3,
        "Leandro Gon\u00e7alves"
      ],
      [
        3,
        "Antoni Guedes"
      ],
      [
        3,
        "Carlos Henrique Avernas"
      ],
      [
        3,
        "\u00c9der J\u00falio Martins"
      ],
      [
        3,
        "Yuri Krushewsky"
      ],
      [
        3,
        "Leandro Lima"
      ],
      [
        3,
        "John Lima"
      ],
      [
        3,
        "Frederico Lins"
      ],
      [
        3,
        "Paulo Lins"
      ],
      [
        3,
        "Ricardo Lira"
      ],
      [
        3,
        "Arlindo Lopes"
      ],
      [
        3,
        "Leandro Lucas"
      ],
      [
        3,
        "Anderson Lug\u00e3o"
      ],
      [
        3,
        "M\u00e1rio Luiz Costa Oliveira"
      ],
      [
        3,
        "Andr\u00e9 Luiz Mendes"
      ],
      [
        3,
        "Marina Mandon\u00e7a Pinheiro"
      ],
      [
        3,
        "Francisco Marcos"
      ],
      [
        3,
        "Kiko Marques"
      ],
      [
        3,
        "Thiago Martins"
      ],
      [
        3,
        "Ivan Martins"
      ],
      [
        3,
        "Carol Meirelles"
      ],
      [
        3,
        "Wagner Mello"
      ],
      [
        3,
        "Leonardo Melo"
      ],
      [
        3,
        "Marcelo Melo"
      ],
      [
        3,
        "Marcello Melo Jr."
      ],
      [
        3,
        "Diego Mendes"
      ],
      [
        3,
        "Jonas Michel"
      ],
      [
        3,
        "Roberto Miguez"
      ],
      [
        3,
        "Leandra Miranda"
      ],
      [
        3,
        "Ed Money"
      ],
      [
        3,
        "Renan Monteiro"
      ],
      [
        3,
        "Leiz Moreira"
      ],
      [
        3,
        "Graziella Moretto"
      ],
      [
        3,
        "Matheus Nachtergaele"
      ],
      [
        3,
        "Lu\u00eds Nascimento"
      ],
      [
        3,
        "Wallace Nascimento"
      ],
      [
        3,
        "Felipe Nogueira"
      ],
      [
        3,
        "Edson Oliveira"
      ],
      [
        3,
        "Erick Oliveira"
      ],
      [
        3,
        "Charles Paraventi"
      ],
      [
        3,
        "Felipe Paulino"
      ],
      [
        3,
        "Andr\u00e9 Pires Martins"
      ],
      [
        3,
        "Felipe Porto"
      ],
      [
        3,
        "Bruno Ricardo"
      ],
      [
        3,
        "Robson Rocha"
      ],
      [
        3,
        "Ricardo Rocha da Silva"
      ],
      [
        3,
        "Ant\u00f3nio Rodrigues"
      ],
      [
        3,
        "Luciana Roque"
      ],
      [
        3,
        "Waldeck Roque"
      ],
      [
        3,
        "Sabrina Rosa"
      ],
      [
        3,
        "Charles Samuel"
      ],
      [
        3,
        "Bernardo Santos"
      ],
      [
        3,
        "Jo\u00e3o Soares"
      ],
      [
        3,
        "Peter Soares"
      ],
      [
        3,
        "Jefechander Suplino"
      ],
      [
        3,
        "Harlem Teixeira"
      ],
      [
        3,
        "Gil Torres"
      ],
      [
        3,
        "Delano Valentim"
      ],
      [
        3,
        "Cleiton Ventura"
      ],
      [
        3,
        "Marcos Viana"
      ],
      [
        3,
        "Felipe Villela Mendon\u00e7a"
      ],
      [
        3,
        "Marcio Vinicios"
      ],
      [
        3,
        "Ruy Vit\u00f3rio"
      ],
      [
        3,
        "Thiago Wallace"
      ],
      [
        3,
        "Guilherme William"
      ],
      [
        3,
        "Daniel Zettel"
      ],
      [
        2,
        "Phellipe Haagensen"
      ],
      [
        2,
        "Jonathan Haagensen"
      ]
    ],
    "directors": "Fernando Meirelles & K\u00e1tia Lund",
    "budget_string": "7.9m USD",
    "gross_string": "30.7m USD",
    "metascore_string": "79%"
  },
  {
    "imdb_id": "tt0253474",
    "plot": "A Polish Jewish musician struggles to survive the destruction of the Warsaw ghetto of World War II.",
    "certification": "R",
    "other_wins_count": 54,
    "other_noms_count": 74,
    "amazon": "https://www.amazon.com/The-Pianist-Blu-ray/dp/B07GNTLQTY/ref=sr_1_5?crid=ASXOQ1JB1JXT&amp;keywords=the+pianist&amp;qid=1654830031&amp;sprefix=pianist%252Caps%252C512&amp;sr=8-5&_encoding=UTF8&tag=sianglu-20&linkCode=ur2&linkId=763179a98844077e055b9309ccbd28ea&camp=1789&creative=9325",
    "justwatch": "https://justwatch.com/us/movie/the-pianist?utm_source=share&utm_medium=web",
    "beige_index": 1,
    "beige_angle": 0.0,
    "cast": [
      [
        1,
        "Popeck"
      ],
      [
        1,
        "Grzegorz Artman"
      ],
      [
        1,
        "Katarzyna Bargielowska"
      ],
      [
        1,
        "Adam Bauman"
      ],
      [
        1,
        "John Bennett"
      ],
      [
        1,
        "Andrzej Blumenfeld"
      ],
      [
        1,
        "Paul Bradley"
      ],
      [
        1,
        "Adrien Brody"
      ],
      [
        1,
        "Joanna Brodzik"
      ],
      [
        1,
        "Pawel Burczyk"
      ],
      [
        1,
        "Daniel Caltagirone"
      ],
      [
        1,
        "Zofia Czerwinska"
      ],
      [
        1,
        "Zbigniew Dziduch"
      ],
      [
        1,
        "Marian Dziedziel"
      ],
      [
        1,
        "Emilio Fernandez"
      ],
      [
        1,
        "Katarzyna Figura"
      ],
      [
        1,
        "Frank Finlay"
      ],
      [
        1,
        "Torsten Flach"
      ],
      [
        1,
        "Lejb Fogelman"
      ],
      [
        1,
        "Emilia Fox"
      ],
      [
        1,
        "Nina Franoszek"
      ],
      [
        1,
        "Jerzy G\u00f3ralczyk"
      ],
      [
        1,
        "Ben Harlan"
      ],
      [
        1,
        "Jessica Kate Meyer"
      ],
      [
        1,
        "John Keogh"
      ],
      [
        1,
        "Frank-Michael K\u00f6be"
      ],
      [
        1,
        "Jaroslaw Kopaczewski"
      ],
      [
        1,
        "Cezary Kosinski"
      ],
      [
        1,
        "Maciej Kowalewski"
      ],
      [
        1,
        "Thomas Kretschmann"
      ],
      [
        1,
        "Udo Kroschwald"
      ],
      [
        1,
        "Patrick Lanagan"
      ],
      [
        1,
        "Thomas Lawinky"
      ],
      [
        1,
        "Dmitri Leshchenko"
      ],
      [
        1,
        "Dorota Liliental"
      ],
      [
        1,
        "Maureen Lipman"
      ],
      [
        1,
        "Lech Mackiewicz"
      ],
      [
        1,
        "Anthony Milner"
      ],
      [
        1,
        "Rafal Mohr"
      ],
      [
        1,
        "Wanja Mues"
      ],
      [
        1,
        "Maja Ostaszewska"
      ],
      [
        1,
        "Joachim Paul Assb\u00f6ck"
      ],
      [
        1,
        "Valentine Pelka"
      ],
      [
        1,
        "Christoph Pieczynski"
      ],
      [
        1,
        "Andrzej Pieczynski"
      ],
      [
        1,
        "Ruth Platt"
      ],
      [
        1,
        "Morgane Polanski"
      ],
      [
        1,
        "Norbert Rakowski"
      ],
      [
        1,
        "Peter Rappengl\u00fcck"
      ],
      [
        1,
        "Uwe Rathsam"
      ],
      [
        1,
        "Julia Rayner"
      ],
      [
        1,
        "Richard Ridings"
      ],
      [
        1,
        "Cyril Shaps"
      ],
      [
        1,
        "Nomi Sharron"
      ],
      [
        1,
        "Piotr Siejka"
      ],
      [
        1,
        "Lucy Skeaping"
      ],
      [
        1,
        "Roddy Skeaping"
      ],
      [
        1,
        "Roy Smiles"
      ],
      [
        1,
        "Wojciech Smolarz"
      ],
      [
        1,
        "Ed Stoppard"
      ],
      [
        1,
        "Tom Strauss"
      ],
      [
        1,
        "Viola Szen"
      ],
      [
        1,
        "Andrzej Szenajch"
      ],
      [
        1,
        "Etl Szyc"
      ],
      [
        1,
        "Andrew Tiernan"
      ],
      [
        1,
        "Tomasz Tyndyk"
      ],
      [
        1,
        "Ronan Vibert"
      ],
      [
        1,
        "Detlev von Wangenheim"
      ],
      [
        1,
        "Andrzej Walden"
      ],
      [
        1,
        "Zbigniew Walerys"
      ],
      [
        1,
        "Darian Wawer"
      ],
      [
        1,
        "Maciej Winkler"
      ],
      [
        1,
        "Tadeusz Wojtych"
      ],
      [
        1,
        "Zbigniew Zamachowski"
      ],
      [
        1,
        "Michal Zebrowski"
      ],
      [
        1,
        "Andrzej Zielinski"
      ],
      [
        1,
        "Maurycy Zylber"
      ],
      [
        1,
        "Xawery Zylber"
      ]
    ],
    "directors": "Roman Polanski",
    "budget_string": "35m USD",
    "gross_string": "120.1m USD",
    "metascore_string": "85%"
  },
  {
    "imdb_id": "tt0167261",
    "plot": "While Frodo and Sam edge closer to Mordor with the help of the shifty Gollum, the divided fellowship makes a stand against Sauron's new ally, Saruman, and his hordes of Isengard.",
    "certification": "PG-13",
    "other_wins_count": 124,
    "other_noms_count": 138,
    "amazon": "https://www.amazon.com/Lord-Rings-Fellowship-Extended-Editions/dp/B0026L7H20/ref=sr_1_7?crid=2ZEHUMYPWUHF6&amp;keywords=fellowship+of+the+ring+blu-ray&amp;qid=1654826575&amp;sprefix=fellowship+of+the+ring+blu-ray%252Caps%252C421&amp;sr=8-7&_encoding=UTF8&tag=sianglu-20&linkCode=ur2&linkId=b2372d10e7938c8211de2c3267ed10cb&camp=1789&creative=9325",
    "justwatch": "https://justwatch.com/us/movie/the-lord-of-the-rings-the-two-towers?utm_source=share&utm_medium=web",
    "beige_index": 1,
    "beige_angle": 14.4,
    "cast": [
      [
        4,
        "Sala Baker"
      ],
      [
        4,
        "Nathaniel Lees"
      ],
      [
        4,
        "Robbie Magasiva"
      ],
      [
        4,
        "Jono Manks"
      ],
      [
        1,
        "Bruce Allpress"
      ],
      [
        1,
        "Sean Astin"
      ],
      [
        1,
        "John Bach"
      ],
      [
        1,
        "Sean Bean"
      ],
      [
        1,
        "Victoria Beynon-Cole"
      ],
      [
        1,
        "Cate Blanchett"
      ],
      [
        1,
        "Orlando Bloom"
      ],
      [
        1,
        "Billy Boyd"
      ],
      [
        1,
        "Jed Brophy"
      ],
      [
        1,
        "Alistair Browning"
      ],
      [
        1,
        "Sam Comery"
      ],
      [
        1,
        "Brad Dourif"
      ],
      [
        1,
        "Calum Gittins"
      ],
      [
        1,
        "Phil Grieve"
      ],
      [
        1,
        "Lee Hartley"
      ],
      [
        1,
        "Bernard Hill"
      ],
      [
        1,
        "Bruce Hopkins"
      ],
      [
        1,
        "Paris Howe Strewe"
      ],
      [
        1,
        "Katie Jackson"
      ],
      [
        1,
        "Billy Jackson"
      ],
      [
        1,
        "Sam La Hood"
      ],
      [
        1,
        "Christopher Lee"
      ],
      [
        1,
        "Timothy Lee"
      ],
      [
        1,
        "John Leigh"
      ],
      [
        1,
        "Robyn Malcolm"
      ],
      [
        1,
        "Brent McIntyre"
      ],
      [
        1,
        "Ian McKellen"
      ],
      [
        1,
        "Dominic Monaghan"
      ],
      [
        1,
        "Viggo Mortensen"
      ],
      [
        1,
        "John Noble"
      ],
      [
        1,
        "Paul Norell"
      ],
      [
        1,
        "Miranda Otto"
      ],
      [
        1,
        "Craig Parker"
      ],
      [
        1,
        "Bruce Phillips"
      ],
      [
        1,
        "Robert Pollock"
      ],
      [
        1,
        "John Rhys-Davies"
      ],
      [
        1,
        "Andy Serkis"
      ],
      [
        1,
        "Phillip Spencer Harris"
      ],
      [
        1,
        "Olivia Tennet"
      ],
      [
        1,
        "Ray Trickitt"
      ],
      [
        1,
        "Liv Tyler"
      ],
      [
        1,
        "Karl Urban"
      ],
      [
        1,
        "Stephen Ure"
      ],
      [
        1,
        "Hugo Weaving"
      ],
      [
        1,
        "David Wenham"
      ],
      [
        1,
        "Elijah Wood"
      ]
    ],
    "directors": "Peter Jackson",
    "budget_string": "94m USD",
    "gross_string": "947.9m USD",
    "metascore_string": "87%"
  },
  {
    "imdb_id": "tt0364569",
    "plot": "After being kidnapped and imprisoned for fifteen years, Oh Dae-Su is released, only to find that he must find his captor in five days.",
    "certification": "R",
    "other_wins_count": 40,
    "other_noms_count": 21,
    "amazon": "https://www.amazon.com/Oldboy-Blu-Ray-Min-sik-Choi/dp/B07SMMBY4R/ref=tmm_blu_swatch_0?_encoding=UTF8&amp;qid=1654842885&amp;sr=1-15&_encoding=UTF8&tag=sianglu-20&linkCode=ur2&linkId=a8f03e75548d8932f4c23153ea22b70e&camp=1789&creative=9325",
    "justwatch": "https://justwatch.com/us/movie/oldboy?utm_source=share&utm_medium=web",
    "beige_index": 2,
    "beige_angle": 45.0,
    "cast": [
      [
        2,
        "Jung Ae Kwak"
      ],
      [
        2,
        "Kim Byeong-Ok"
      ],
      [
        2,
        "Choon-Beom Choi"
      ],
      [
        2,
        "Jae-Sup Choi"
      ],
      [
        2,
        "Chang-hak Choi"
      ],
      [
        2,
        "Jung-ok Han"
      ],
      [
        2,
        "Jae-Duk Han"
      ],
      [
        2,
        "Seon-Rang Han"
      ],
      [
        2,
        "Myeong-haeng Heo"
      ],
      [
        2,
        "Kang Hye-jeong"
      ],
      [
        2,
        "Dong Hyun Baek"
      ],
      [
        2,
        "Seol-a Im"
      ],
      [
        2,
        "Chae-soo Jang"
      ],
      [
        2,
        "Woo-Jae Jeon"
      ],
      [
        2,
        "Dae-han Ji"
      ],
      [
        2,
        "Joong-hyun Ji"
      ],
      [
        2,
        "Lee Ji-hye"
      ],
      [
        2,
        "Yoo Ji-Tae"
      ],
      [
        2,
        "Myeong-cheol Ju"
      ],
      [
        2,
        "Young-ae Kim"
      ],
      [
        2,
        "San Kim"
      ],
      [
        2,
        "Su-hyeon Kim"
      ],
      [
        2,
        "Won-jung Kim"
      ],
      [
        2,
        "Oh Kwang-rok"
      ],
      [
        2,
        "Seung-shin Lee"
      ],
      [
        2,
        "Young-hee Lee"
      ],
      [
        2,
        "Dae-yeon Lee"
      ],
      [
        2,
        "Hong-Pyo Lee"
      ],
      [
        2,
        "Jung-pil Lee"
      ],
      [
        2,
        "Syd Lim"
      ],
      [
        2,
        "Mi Mi Lee"
      ],
      [
        2,
        "Choi Min-sik"
      ],
      [
        2,
        "Sang-moo Moon"
      ],
      [
        2,
        "Seon-bok Mun"
      ],
      [
        2,
        "Park Myung-shin"
      ],
      [
        2,
        "Young-ju Oh"
      ],
      [
        2,
        "Tae-kyung Oh"
      ],
      [
        2,
        "Dal-su Oh"
      ],
      [
        2,
        "Il-han Oo"
      ],
      [
        2,
        "Sung-Gyu Park"
      ],
      [
        2,
        "Ji-hun Park"
      ],
      [
        2,
        "Jae-woong Park"
      ],
      [
        2,
        "Geun-Seok Park"
      ],
      [
        2,
        "Jo Sang-gyeong"
      ],
      [
        2,
        "Myeong-seok Seo"
      ],
      [
        2,
        "Seok-jae Seong"
      ],
      [
        2,
        "Bi-Joon Shin"
      ],
      [
        2,
        "Beom-sik Sin"
      ],
      [
        2,
        "Oh Soon-tae"
      ],
      [
        2,
        "Yoo Yeon-Seok"
      ],
      [
        2,
        "Yi Yong"
      ],
      [
        2,
        "Jin-Yool Yoon"
      ],
      [
        2,
        "Jin-Seo Yoon"
      ]
    ],
    "directors": "Park Chan-wook",
    "budget_string": "3m USD",
    "gross_string": "15.2m USD",
    "metascore_string": "77%"
  },
  {
    "imdb_id": "tt0266543",
    "plot": "After his son is captured in the Great Barrier Reef and taken to Sydney, a timid clownfish sets out on a journey to bring him home.",
    "certification": "G",
    "other_wins_count": 47,
    "other_noms_count": 63,
    "amazon": "https://www.amazon.com/Finding-Nemo-Three-Disc-Collectors-Packaging/dp/B00867GJYK/ref=sr_1_2?crid=S79NA5DXVP9C&amp;keywords=finding+nemo+blu+ray&amp;qid=1655179776&amp;s=movies-tv&amp;sprefix=finding+nemo+blu+ray%252Cmovies-tv%252C286&amp;sr=1-2&_encoding=UTF8&tag=sianglu-20&linkCode=ur2&linkId=82cfeea8335a11daa04096b058e0c877&camp=1789&creative=9325",
    "justwatch": "https://justwatch.com/us/movie/finding-nemo?utm_source=share&utm_medium=web",
    "beige_index": 1,
    "beige_angle": 0.0,
    "cast": [
      [
        1,
        "Eric Bana"
      ],
      [
        1,
        "Erica Beck"
      ],
      [
        1,
        "Nicholas Bird"
      ],
      [
        1,
        "Albert Brooks"
      ],
      [
        1,
        "Willem Dafoe"
      ],
      [
        1,
        "Ellen DeGeneres"
      ],
      [
        1,
        "LuLu Ebeling"
      ],
      [
        1,
        "Brad Garrett"
      ],
      [
        1,
        "Alexander Gould"
      ],
      [
        1,
        "Barry Humphries"
      ],
      [
        1,
        "Bill Hunter"
      ],
      [
        1,
        "Allison Janney"
      ],
      [
        1,
        "Vicki Lewis"
      ],
      [
        1,
        "Austin Pendleton"
      ],
      [
        1,
        "Erik Per Sullivan"
      ],
      [
        1,
        "Elizabeth Perkins"
      ],
      [
        1,
        "Bob Peterson"
      ],
      [
        1,
        "Joe Ranft"
      ],
      [
        1,
        "Jordan Ranft"
      ],
      [
        1,
        "John Ratzenberger"
      ],
      [
        1,
        "Stephen Root"
      ],
      [
        1,
        "Geoffrey Rush"
      ],
      [
        1,
        "Bruce Spence"
      ],
      [
        1,
        "Andrew Stanton"
      ]
    ],
    "directors": "Andrew Stanton & Lee Unkrich",
    "budget_string": "94m USD",
    "gross_string": "940.4m USD",
    "metascore_string": "90%"
  },
  {
    "imdb_id": "tt0353969",
    "plot": "In a small Korean province in 1986, two detectives struggle with the case of multiple young women being found raped and murdered by an unknown culprit.",
    "certification": "Not Rated",
    "other_wins_count": 33,
    "other_noms_count": 5,
    "amazon": "https://www.amazon.com/Memories-Murder-Kang-ho-Song/dp/B0009XRZQK/ref=tmm_dvd_title_0?_encoding=UTF8&amp;qid=1655183357&amp;sr=8-18-spons&_encoding=UTF8&tag=sianglu-20&linkCode=ur2&linkId=9c61de688af1c3fa9430ba5899a5f0c7&camp=1789&creative=9325",
    "justwatch": "https://justwatch.com/us/movie/memories-of-murder?utm_source=share&utm_medium=web",
    "beige_index": 2,
    "beige_angle": 45.0,
    "cast": [
      [
        2,
        "Yoo-Geum"
      ],
      [
        2,
        "Bong-gi Baek"
      ],
      [
        2,
        "Jin-Chul Baek"
      ],
      [
        2,
        "Seung-Hwan Choi"
      ],
      [
        2,
        "Hyeon-gi Choi"
      ],
      [
        2,
        "Gyo-sik Choi"
      ],
      [
        2,
        "Jong-ryol Choi"
      ],
      [
        2,
        "Myung-jae Chun"
      ],
      [
        2,
        "Lee Dong-yong"
      ],
      [
        2,
        "Woo Go-na"
      ],
      [
        2,
        "Seong-Chun Goo"
      ],
      [
        2,
        "Soo-Jung Gwak"
      ],
      [
        2,
        "Park Hae-il"
      ],
      [
        2,
        "Dae-gwan Han"
      ],
      [
        2,
        "Chung-Hwan Han"
      ],
      [
        2,
        "Seong-Geun Han"
      ],
      [
        2,
        "Byun Hee-Bong"
      ],
      [
        2,
        "Woo-Sung Hwang"
      ],
      [
        2,
        "Yeom Hye-ran"
      ],
      [
        2,
        "Mi-seon Jeon"
      ],
      [
        2,
        "Park Jin-Woo"
      ],
      [
        2,
        "Moon-Eui Jo"
      ],
      [
        2,
        "Deok-jae Jo"
      ],
      [
        2,
        "Kim Joo-Ryung"
      ],
      [
        2,
        "Joo-Hyun Jun"
      ],
      [
        2,
        "In-sun Jung"
      ],
      [
        2,
        "Jin-hee Kang"
      ],
      [
        2,
        "Min-Jeong Kim"
      ],
      [
        2,
        "Ha-kyeong Kim"
      ],
      [
        2,
        "Cheol-Yong Kim"
      ],
      [
        2,
        "Roe-ha Kim"
      ],
      [
        2,
        "Mi-ok Kim"
      ],
      [
        2,
        "Hee-jin Kim"
      ],
      [
        2,
        "Yeong-Gwan Kim"
      ],
      [
        2,
        "Kyeong-rae Kim"
      ],
      [
        2,
        "Jo-hwa Kim"
      ],
      [
        2,
        "Tae-han Kim"
      ],
      [
        2,
        "Seo-hie Ko"
      ],
      [
        2,
        "Mi-Hyung Kwon"
      ],
      [
        2,
        "Byung-gil Kwon"
      ],
      [
        2,
        "Hyeok-poong Kwon"
      ],
      [
        2,
        "Mi-ok Lee"
      ],
      [
        2,
        "In-hee Lee"
      ],
      [
        2,
        "Hun-Kyung Lee"
      ],
      [
        2,
        "Kang-San Lee"
      ],
      [
        2,
        "Sang-uk Lee"
      ],
      [
        2,
        "Da-il Lee"
      ],
      [
        2,
        "Ga-Myun Lee"
      ],
      [
        2,
        "Ok-joo Lee"
      ],
      [
        2,
        "Hyuk-jae Lee"
      ],
      [
        2,
        "Geun-se Lee"
      ],
      [
        2,
        "Jae-eung Lee"
      ],
      [
        2,
        "Dae-hyeon Lee"
      ],
      [
        2,
        "Shin Moon-Sung"
      ],
      [
        2,
        "Jae-Gyun Na"
      ],
      [
        2,
        "Oh-Yeong Oh"
      ],
      [
        2,
        "Hyun-young Park"
      ],
      [
        2,
        "No-shik Park"
      ],
      [
        2,
        "So-hyeon Park"
      ],
      [
        2,
        "Tae-kyeong Park"
      ],
      [
        2,
        "Sang-woo Park"
      ],
      [
        2,
        "Jong-hwa Ryoo"
      ],
      [
        2,
        "Tae-ho Ryu"
      ],
      [
        2,
        "Pir-han Ryu"
      ],
      [
        2,
        "Kim Sang-kyung"
      ],
      [
        2,
        "Young-hwa Seo"
      ],
      [
        2,
        "Min-Soo Seo"
      ],
      [
        2,
        "Jeong-seon Seong"
      ],
      [
        2,
        "Sung-bo Shim"
      ],
      [
        2,
        "Dong-hwan Shin"
      ],
      [
        2,
        "Hyeon-seung Sin"
      ],
      [
        2,
        "Hyeon-jong Sin"
      ],
      [
        2,
        "Kang-gook Son"
      ],
      [
        2,
        "Jin-Hwan Son"
      ],
      [
        2,
        "Kang-ho Song"
      ],
      [
        2,
        "Jae-ho Song"
      ],
      [
        2,
        "Shin Woon-seob"
      ],
      [
        2,
        "Chi-Hyuk Yoo"
      ],
      [
        2,
        "Seung-mok Yoo"
      ],
      [
        2,
        "Ha-Young Yoo"
      ],
      [
        2,
        "Bak-ji Yoon"
      ],
      [
        2,
        "In-su Yu"
      ],
      [
        2,
        "Ga-hyun Yun"
      ]
    ],
    "directors": "Bong Joon Ho",
    "budget_string": "2.8m USD",
    "gross_string": "1.2m USD",
    "metascore_string": "82%"
  },
  {
    "imdb_id": "tt0266697",
    "plot": "After awakening from a four-year coma, a former assassin wreaks vengeance on the team of assassins who betrayed her.",
    "certification": "R",
    "other_wins_count": 29,
    "other_noms_count": 100,
    "amazon": "https://www.amazon.com/Kill-Bill-Blu-ray-Uma-Thurman/dp/B001BJ690Y/ref=sr_1_1?crid=BTN3Y6RA1X0E&amp;keywords=kill+bill+volume+1+blu+ray&amp;qid=1655175664&amp;sprefix=kill+bill+volume+1+blu+ray%252Caps%252C296&amp;sr=8-1&_encoding=UTF8&tag=sianglu-20&linkCode=ur2&linkId=54fa36cb1b40aca2f433d73506141bb5&camp=1789&creative=9325",
    "justwatch": "https://justwatch.com/us/movie/kill-bill-vol-1?utm_source=share&utm_medium=web",
    "beige_index": 2,
    "beige_angle": 43.8462,
    "cast": [
      [
        5,
        "Vivica A. Fox"
      ],
      [
        5,
        "Ambrosia Kelley"
      ],
      [
        2,
        "Shin'ichi Chiba"
      ],
      [
        2,
        "Goro Daimon"
      ],
      [
        2,
        "Sachiko Fujii"
      ],
      [
        2,
        "Xiaohui Hu"
      ],
      [
        2,
        "Yuki Kazamatsuri"
      ],
      [
        2,
        "Kazuki Kitamura"
      ],
      [
        2,
        "Jun Kunimura"
      ],
      [
        2,
        "Chiaki Kuriyama"
      ],
      [
        2,
        "Naomi Kusumi"
      ],
      [
        2,
        "Shu Lan Tuan"
      ],
      [
        2,
        "Lucy Liu"
      ],
      [
        2,
        "Chia-Hui Liu"
      ],
      [
        2,
        "Ai Maeda"
      ],
      [
        2,
        "Juri Manase"
      ],
      [
        2,
        "Akaji Maro"
      ],
      [
        2,
        "Hikaru Midorikawa"
      ],
      [
        2,
        "Yoshiyuki Morishita"
      ],
      [
        2,
        "Kenji Ohba"
      ],
      [
        2,
        "Sakichi Sato"
      ],
      [
        2,
        "Tetsuro Shimaguchi"
      ],
      [
        2,
        "Shun Sugata"
      ],
      [
        2,
        "Issey Takahashi"
      ],
      [
        2,
        "Y\u00f4ji Tanaka"
      ],
      [
        2,
        "Yoshiko Yamaguchi"
      ],
      [
        2,
        "So Yamanaka"
      ],
      [
        2,
        "Ronnie Yoshiko Fujiyama"
      ],
      [
        2,
        "Jin Zhan Zhang"
      ],
      [
        1,
        "Michael Bowen"
      ],
      [
        1,
        "David Carradine"
      ],
      [
        1,
        "Julie Dreyfus"
      ],
      [
        1,
        "Daryl Hannah"
      ],
      [
        1,
        "Jonathan Loughran"
      ],
      [
        1,
        "Michael Madsen"
      ],
      [
        1,
        "James Parks"
      ],
      [
        1,
        "Michael Parks"
      ],
      [
        1,
        "Stevo Polyi"
      ],
      [
        1,
        "Uma Thurman"
      ]
    ],
    "directors": "Quentin Tarantino",
    "budget_string": "30m USD",
    "gross_string": "180.9m USD",
    "metascore_string": "69%"
  },
  {
    "imdb_id": "tt0167260",
    "plot": "Gandalf and Aragorn lead the World of Men against Sauron's army to draw his gaze from Frodo and Sam as they approach Mount Doom with the One Ring.",
    "certification": "PG-13",
    "other_wins_count": 198,
    "other_noms_count": 124,
    "amazon": "https://www.amazon.com/Lord-Rings-Fellowship-Extended-Editions/dp/B0026L7H20/ref=sr_1_7?crid=2ZEHUMYPWUHF6&amp;keywords=fellowship+of+the+ring+blu-ray&amp;qid=1654826575&amp;sprefix=fellowship+of+the+ring+blu-ray%252Caps%252C421&amp;sr=8-7&_encoding=UTF8&tag=sianglu-20&linkCode=ur2&linkId=b2372d10e7938c8211de2c3267ed10cb&camp=1789&creative=9325",
    "justwatch": "https://justwatch.com/us/movie/the-return-of-the-king?utm_source=share&utm_medium=web",
    "beige_index": 1,
    "beige_angle": 12.4146,
    "cast": [
      [
        4,
        "Sala Baker"
      ],
      [
        4,
        "Lawrence Makoare"
      ],
      [
        4,
        "Shane Rangi"
      ],
      [
        4,
        "Pete Smith"
      ],
      [
        1,
        "Noel Appleby"
      ],
      [
        1,
        "Sean Astin"
      ],
      [
        1,
        "Ali Astin"
      ],
      [
        1,
        "David Aston"
      ],
      [
        1,
        "John Bach"
      ],
      [
        1,
        "Sean Bean"
      ],
      [
        1,
        "Cate Blanchett"
      ],
      [
        1,
        "Orlando Bloom"
      ],
      [
        1,
        "Billy Boyd"
      ],
      [
        1,
        "Sadwyn Brophy"
      ],
      [
        1,
        "Jed Brophy"
      ],
      [
        1,
        "Alistair Browning"
      ],
      [
        1,
        "Marton Csokas"
      ],
      [
        1,
        "Brad Dourif"
      ],
      [
        1,
        "Ross Duncan"
      ],
      [
        1,
        "Richard Edge"
      ],
      [
        1,
        "Michael Elsworth"
      ],
      [
        1,
        "Jason Fitch"
      ],
      [
        1,
        "Phil Grieve"
      ],
      [
        1,
        "Lee Hartley"
      ],
      [
        1,
        "Bernard Hill"
      ],
      [
        1,
        "Ian Holm"
      ],
      [
        1,
        "Bruce Hopkins"
      ],
      [
        1,
        "Alan Howard"
      ],
      [
        1,
        "Ian Hughes"
      ],
      [
        1,
        "Katie Jackson"
      ],
      [
        1,
        "Billy Jackson"
      ],
      [
        1,
        "Christopher Lee"
      ],
      [
        1,
        "Ian McKellen"
      ],
      [
        1,
        "Bret McKenzie"
      ],
      [
        1,
        "Sarah McLeod"
      ],
      [
        1,
        "Maisy McLeod-Riera"
      ],
      [
        1,
        "Dominic Monaghan"
      ],
      [
        1,
        "Viggo Mortensen"
      ],
      [
        1,
        "John Noble"
      ],
      [
        1,
        "Paul Norell"
      ],
      [
        1,
        "Miranda Otto"
      ],
      [
        1,
        "Craig Parker"
      ],
      [
        1,
        "Bruce Phillips"
      ],
      [
        1,
        "Robert Pollock"
      ],
      [
        1,
        "John Rhys-Davies"
      ],
      [
        1,
        "Todd Rippon"
      ],
      [
        1,
        "Thomas Robins"
      ],
      [
        1,
        "Andy Serkis"
      ],
      [
        1,
        "Harry Sinclair"
      ],
      [
        1,
        "Bruce Spence"
      ],
      [
        1,
        "Peter Tait"
      ],
      [
        1,
        "Joel Tobeck"
      ],
      [
        1,
        "Liv Tyler"
      ],
      [
        1,
        "Karl Urban"
      ],
      [
        1,
        "Stephen Ure"
      ],
      [
        1,
        "Hugo Weaving"
      ],
      [
        1,
        "David Wenham"
      ],
      [
        1,
        "Elijah Wood"
      ]
    ],
    "directors": "Peter Jackson",
    "budget_string": "94m USD",
    "gross_string": "1146.4m USD",
    "metascore_string": "94%"
  },
  {
    "imdb_id": "tt0325980",
    "plot": "Blacksmith Will Turner teams up with eccentric pirate \"Captain\" Jack Sparrow to save his love, the governor's daughter, from Jack's former pirate allies, who are now undead.",
    "certification": "PG-13",
    "other_wins_count": 38,
    "other_noms_count": 99,
    "amazon": "https://www.amazon.com/Pirates-Caribbean-Curse-Black-Blu-ray/dp/B004DTLK6M/ref=sr_1_4?crid=18XW61RU4ZLQX&amp;keywords=pirates+of+the+caribbean+curse+of+the+black+pearl&amp;qid=1655193621&amp;s=movies-tv&amp;sprefix=pirates+of+the+caribbean+curse+of+the+black+pearl%252Cmovies-tv%252C315&amp;sr=1-4&_encoding=UTF8&tag=sianglu-20&linkCode=ur2&linkId=9078b5e7d8f5b32e5390b1f2c78b56ab&camp=1789&creative=9325",
    "justwatch": "https://justwatch.com/us/movie/pirates-of-the-caribbean-the-curse-of-the-black-pearl?utm_source=share&utm_medium=web",
    "beige_index": 2,
    "beige_angle": 42.6096,
    "cast": [
      [
        5,
        "LeJon"
      ],
      [
        5,
        "Michael A. Thompson"
      ],
      [
        5,
        "Isaac C. Singleton Jr."
      ],
      [
        5,
        "Treva Etienne"
      ],
      [
        5,
        "Rudy McCollum"
      ],
      [
        5,
        "Israel Oyelumade"
      ],
      [
        5,
        "Michael W. Williams"
      ],
      [
        5,
        "Zoe Saldana"
      ],
      [
        3,
        "F\u00e9lix Castro"
      ],
      [
        3,
        "Gerard J. Reyes"
      ],
      [
        3,
        "Jos\u00e9 Zelaya"
      ],
      [
        3,
        "Don LaDaga"
      ],
      [
        3,
        "Maxie Santillan Jr."
      ],
      [
        1,
        "Lee Arenberg"
      ],
      [
        1,
        "Mike Babcock"
      ],
      [
        1,
        "David Bailie"
      ],
      [
        1,
        "Angus Barnett"
      ],
      [
        1,
        "Michael Berry Jr."
      ],
      [
        1,
        "Orlando Bloom"
      ],
      [
        1,
        "Matthew Bowyer"
      ],
      [
        1,
        "Vanessa Branch"
      ],
      [
        1,
        "Brye Cooper"
      ],
      [
        1,
        "Mackenzie Crook"
      ],
      [
        1,
        "Jack Davenport"
      ],
      [
        1,
        "Luke de Woolfson"
      ],
      [
        1,
        "Johnny Depp"
      ],
      [
        1,
        "Lucinda Dryzek"
      ],
      [
        1,
        "Michael Earl Lane"
      ],
      [
        1,
        "Finneus Egan"
      ],
      [
        1,
        "Greg Ellis"
      ],
      [
        1,
        "Owen Finnegan"
      ],
      [
        1,
        "D.P. FitzGerald"
      ],
      [
        1,
        "Jerry Gauny"
      ],
      [
        1,
        "Trevor Goddard"
      ],
      [
        1,
        "Paula J. Newman"
      ],
      [
        1,
        "Paul Keith"
      ],
      [
        1,
        "Martin Klebba"
      ],
      [
        1,
        "Keira Knightley"
      ],
      [
        1,
        "Vince Lozano"
      ],
      [
        1,
        "Lauren Maher"
      ],
      [
        1,
        "Christian Martin"
      ],
      [
        1,
        "Ian McIntyre"
      ],
      [
        1,
        "Tobias McKinney"
      ],
      [
        1,
        "Kevin McNally"
      ],
      [
        1,
        "Giles New"
      ],
      [
        1,
        "Damian O'Hare"
      ],
      [
        1,
        "Ralph P. Martin"
      ],
      [
        1,
        "David Patykewich"
      ],
      [
        1,
        "Jonathan Pryce"
      ],
      [
        1,
        "Sam Roberts"
      ],
      [
        1,
        "Ben Roberts"
      ],
      [
        1,
        "Geoffrey Rush"
      ],
      [
        1,
        "Christopher S. Capp"
      ],
      [
        1,
        "Tommy Schooler"
      ],
      [
        1,
        "M. Scott Shields"
      ],
      [
        1,
        "Michael Sean Tighe"
      ],
      [
        1,
        "Dustin Seavey"
      ],
      [
        1,
        "Guy Siner"
      ],
      [
        1,
        "Dylan Smith"
      ],
      [
        1,
        "Christopher Sullivan"
      ],
      [
        1,
        "Craig Thomson"
      ],
      [
        1,
        "Fred Toft"
      ],
      [
        1,
        "Antonio Valentino"
      ],
      [
        1,
        "Ben Wilson"
      ]
    ],
    "directors": "Gore Verbinski",
    "budget_string": "140m USD",
    "gross_string": "654.3m USD",
    "metascore_string": "63%"
  },
  {
    "imdb_id": "tt0395169",
    "plot": "Paul Rusesabagina, a hotel manager, houses over a thousand Tutsi refugees during their struggle against the Hutu militia in Rwanda, Africa.",
    "certification": "PG-13",
    "other_wins_count": 16,
    "other_noms_count": 46,
    "amazon": "https://www.amazon.com/Hotel-Rwanda-Don-Cheadle/dp/B0007R4T3U/ref=tmm_dvd_swatch_0?_encoding=UTF8&amp;qid=&amp;sr=&_encoding=UTF8&tag=sianglu-20&linkCode=ur2&linkId=15b05e8a4bbf5622d0341e0222c7191d&camp=1789&creative=9325",
    "justwatch": "https://justwatch.com/us/movie/hotel-rwanda?utm_source=share&utm_medium=web",
    "beige_index": 5,
    "beige_angle": 158.7337714286,
    "cast": [
      [
        5,
        "Mabutho 'Kid' Sithole"
      ],
      [
        5,
        "Don Cheadle"
      ],
      [
        5,
        "Sonni Chidiebere"
      ],
      [
        5,
        "Antonio David Lyons"
      ],
      [
        5,
        "Desmond Dube"
      ],
      [
        5,
        "Hakeem Kae-Kazim"
      ],
      [
        5,
        "Mosa Kaiser"
      ],
      [
        5,
        "Thomas Kariuki"
      ],
      [
        5,
        "Tony Kgoroge"
      ],
      [
        5,
        "Leleti Khumalo"
      ],
      [
        5,
        "Harriet Lenabe"
      ],
      [
        5,
        "Mduduzi Mabaso"
      ],
      [
        5,
        "Mothusi Magano"
      ],
      [
        5,
        "Xolani Mali"
      ],
      [
        5,
        "Noxolo Maqashalala"
      ],
      [
        5,
        "Lebo Mashile"
      ],
      [
        5,
        "Lennox Mathabathe"
      ],
      [
        5,
        "Sibusiso Mhlongo"
      ],
      [
        5,
        "Ofentse Modiselle"
      ],
      [
        5,
        "Simo Mogwaza"
      ],
      [
        5,
        "Lerato Mokgotho"
      ],
      [
        5,
        "Fana Mokoena"
      ],
      [
        5,
        "Rosie Motene"
      ],
      [
        5,
        "Jeremiah Ndlovu"
      ],
      [
        5,
        "Mirriam Ngomani"
      ],
      [
        5,
        "Thulani Nyembe"
      ],
      [
        5,
        "Mathabo Pieterson"
      ],
      [
        5,
        "Kgomotso Seitshohlo"
      ],
      [
        4,
        "Sophie Okonedo"
      ],
      [
        1,
        "Roberto Citran"
      ],
      [
        1,
        "Neil McCarthy"
      ],
      [
        1,
        "Nick Nolte"
      ],
      [
        1,
        "David O'Hara"
      ],
      [
        1,
        "Joaquin Phoenix"
      ],
      [
        1,
        "Jean Reno"
      ],
      [
        1,
        "Cara Seymour"
      ],
      [
        1,
        "Ashleigh Tobias"
      ]
    ],
    "directors": "Terry George",
    "budget_string": "17.5m USD",
    "gross_string": "33.9m USD",
    "metascore_string": "79%"
  },
  {
    "imdb_id": "tt0363163",
    "plot": "Traudl Junge, the final secretary for Adolf Hitler, tells of the Nazi dictator's final days in his Berlin bunker at the end of WWII.",
    "certification": "R",
    "other_wins_count": 22,
    "other_noms_count": 33,
    "amazon": "https://www.amazon.com/Downfall-Blu-ray-Bruno-Ganz/dp/B077ZCVWGP/ref=sr_1_2?crid=1YN7AMNSJ32S0&amp;keywords=downfall+blu+ray&amp;qid=1655008690&amp;sprefix=downfall+%252Caps%252C285&amp;sr=8-2&_encoding=UTF8&tag=sianglu-20&linkCode=ur2&linkId=4a52d9e9a9e21f9193481b2de6805517&camp=1789&creative=9325",
    "justwatch": "https://justwatch.com/us/movie/downfall-2004?utm_source=share&utm_medium=web",
    "beige_index": 1,
    "beige_angle": 0.0,
    "cast": [
      [
        1,
        "Klaus B. Wolf"
      ],
      [
        1,
        "Julia Bauer"
      ],
      [
        1,
        "Christian Berkel"
      ],
      [
        1,
        "Andrey Blagoslovenski"
      ],
      [
        1,
        "Dirk Borchardt"
      ],
      [
        1,
        "Laura Borlein"
      ],
      [
        1,
        "Gregory Borlein"
      ],
      [
        1,
        "Elizaveta Boyarskaya"
      ],
      [
        1,
        "Michael Brandner"
      ],
      [
        1,
        "I. Bubenchikov"
      ],
      [
        1,
        "Fabian Busch"
      ],
      [
        1,
        "Martin Butzke"
      ],
      [
        1,
        "Dmitriy Bykovskiy-Romashov"
      ],
      [
        1,
        "Elena Dreyden"
      ],
      [
        1,
        "Sergey Evseev"
      ],
      [
        1,
        "Heino Ferch"
      ],
      [
        1,
        "Bruno Ganz"
      ],
      [
        1,
        "Mathias Gn\u00e4dinger"
      ],
      [
        1,
        "Bohdan Graczyk"
      ],
      [
        1,
        "Donevan Gunia"
      ],
      [
        1,
        "Hans H. Steinberg"
      ],
      [
        1,
        "Matthias Habich"
      ],
      [
        1,
        "Corinna Harfouch"
      ],
      [
        1,
        "Norbert Heckner"
      ],
      [
        1,
        "Alexander Held"
      ],
      [
        1,
        "Andr\u00e9 Hennicke"
      ],
      [
        1,
        "Enno Hesse"
      ],
      [
        1,
        "Christian Hoening"
      ],
      [
        1,
        "Dietrich Hollinderb\u00e4umer"
      ],
      [
        1,
        "Evgeniy Ilovayskiy"
      ],
      [
        1,
        "Julia Jentsch"
      ],
      [
        1,
        "Rolf Kanies"
      ],
      [
        1,
        "Oleg Khoroshilov"
      ],
      [
        1,
        "Michael Kind"
      ],
      [
        1,
        "Juliane K\u00f6hler"
      ],
      [
        1,
        "Karl Kranzkowski"
      ],
      [
        1,
        "Thomas Kretschmann"
      ],
      [
        1,
        "Thorsten Krohn"
      ],
      [
        1,
        "Ulrike Krumbiegel"
      ],
      [
        1,
        "Thomas Limpinsel"
      ],
      [
        1,
        "Michael Lippold"
      ],
      [
        1,
        "Konstantin Lukashov"
      ],
      [
        1,
        "Dieter Mann"
      ],
      [
        1,
        "Alexandra Maria Lara"
      ],
      [
        1,
        "Tara Marie Linke"
      ],
      [
        1,
        "Ulrich Matthes"
      ],
      [
        1,
        "Stefan Mehren"
      ],
      [
        1,
        "Michael Mendl"
      ],
      [
        1,
        "Amelie Menges"
      ],
      [
        1,
        "Birgit Minichmayr"
      ],
      [
        1,
        "Ilya Mozgovoy"
      ],
      [
        1,
        "Silke Nikowski"
      ],
      [
        1,
        "Ulrich Noethen"
      ],
      [
        1,
        "Alexei Oleinikov"
      ],
      [
        1,
        "G\u00f6tz Otto"
      ],
      [
        1,
        "Oleg Piminov"
      ],
      [
        1,
        "Katerina Poladjan"
      ],
      [
        1,
        "Oleg Popov"
      ],
      [
        1,
        "Silke Popp"
      ],
      [
        1,
        "Christian Redl"
      ],
      [
        1,
        "Bettina Redlich"
      ],
      [
        1,
        "Vasiliy Reutov"
      ],
      [
        1,
        "Igor Romanov"
      ],
      [
        1,
        "Sergey Rublev"
      ],
      [
        1,
        "Tanja Schleiff"
      ],
      [
        1,
        "Christian Schmidt"
      ],
      [
        1,
        "Heinrich Schmieder"
      ],
      [
        1,
        "August Schm\u00f6lzer"
      ],
      [
        1,
        "Jurij Schrader"
      ],
      [
        1,
        "Boris Schwarzmann"
      ],
      [
        1,
        "Mariya Semyonova"
      ],
      [
        1,
        "Igor Sergeev"
      ],
      [
        1,
        "Aleksandr Slastin"
      ],
      [
        1,
        "Valeri Slavinski"
      ],
      [
        1,
        "Alina Sokar"
      ],
      [
        1,
        "Valeriy Solomakhin"
      ],
      [
        1,
        "Klaus-J\u00fcrgen Steinmann"
      ],
      [
        1,
        "Charlotte Stoiber"
      ],
      [
        1,
        "Devid Striesow"
      ],
      [
        1,
        "Oliver Stritzel"
      ],
      [
        1,
        "Veit St\u00fcbner"
      ],
      [
        1,
        "Anna Thalbach"
      ],
      [
        1,
        "Thomas Thieme"
      ],
      [
        1,
        "J\u00fcrgen Tonkel"
      ],
      [
        1,
        "Mikhail Tryasorukov"
      ],
      [
        1,
        "Vsevolod Tsurilo"
      ],
      [
        1,
        "Leopold von Buttlar"
      ],
      [
        1,
        "Justus von Dohn\u00e1nyi"
      ],
      [
        1,
        "Elisabeth von Koch"
      ],
      [
        1,
        "Tatyana Zhuravlyova"
      ]
    ],
    "directors": "Oliver Hirschbiegel",
    "budget_string": "16.7m USD",
    "gross_string": "92.2m USD",
    "metascore_string": "82%"
  },
  {
    "imdb_id": "tt0347149",
    "plot": "When an unconfident young woman is cursed with an old body by a spiteful witch, her only chance of breaking the spell lies with a self-indulgent yet insecure young wizard and his companions in his legged, walking castle.",
    "certification": "PG",
    "other_wins_count": 14,
    "other_noms_count": 19,
    "amazon": "https://www.amazon.com/Howls-Moving-Castle-Blu-ray-Simmons/dp/B084FDZS3T/ref=sr_1_1?crid=EYQBTBHEO5JG&amp;keywords=howl%2527s+moving+castle+blu+ray&amp;qid=1655181302&amp;s=movies-tv&amp;sprefix=howl%2527s+moving+castle%252Cmovies-tv%252C288&amp;sr=1-1&_encoding=UTF8&tag=sianglu-20&linkCode=ur2&linkId=5920fa417cb36d90f9a4101032baea4a&camp=1789&creative=9325",
    "justwatch": "https://justwatch.com/us/movie/howls-moving-castle?utm_source=share&utm_medium=web",
    "beige_index": 2,
    "beige_angle": 40.7142,
    "cast": [
      [
        3,
        "Carlos Alazraqui"
      ],
      [
        2,
        "Chieko Baish\u00f4"
      ],
      [
        2,
        "Tatsuya Gash\u00fbin"
      ],
      [
        2,
        "Daijir\u00f4 Harada"
      ],
      [
        2,
        "Mitsunori Isaki"
      ],
      [
        2,
        "Ry\u00fbnosuke Kamiki"
      ],
      [
        2,
        "Haruko Kat\u00f4"
      ],
      [
        2,
        "Yayoi Kazuki"
      ],
      [
        2,
        "Takuya Kimura"
      ],
      [
        2,
        "Akihiro Miwa"
      ],
      [
        2,
        "Manabu Muraji"
      ],
      [
        2,
        "Y\u00f4 \u00d4izumi"
      ],
      [
        2,
        "Akio \u00d4tsuka"
      ],
      [
        2,
        "Shigeyuki Totsugi"
      ],
      [
        2,
        "Mayuno Yasokawa"
      ],
      [
        2,
        "Ken Yasuda"
      ]
    ],
    "directors": "Hayao Miyazaki",
    "budget_string": "24m USD",
    "gross_string": "236.3m USD",
    "metascore_string": "80%"
  },
  {
    "imdb_id": "tt0381681",
    "plot": "Nine years after Jesse and Celine first met, they encounter each other again on the French leg of Jesse's book tour.",
    "certification": "R",
    "other_wins_count": 9,
    "other_noms_count": 31,
    "amazon": "https://www.amazon.com/Before-Sunset-Steve-Kloves/dp/B0002YLC24/ref=tmm_dvd_swatch_0?_encoding=UTF8&amp;qid=1655185487&amp;sr=1-5&_encoding=UTF8&tag=sianglu-20&linkCode=ur2&linkId=7a507db409626375434caa5ddb34794d&camp=1789&creative=9325",
    "justwatch": "https://justwatch.com/us/movie/before-sunset?utm_source=share&utm_medium=web",
    "beige_index": 1,
    "beige_angle": 0.0,
    "cast": [
      [
        1,
        "Diabolo"
      ],
      [
        1,
        "Julie Delpy"
      ],
      [
        1,
        "Albert Delpy"
      ],
      [
        1,
        "Vernon Dobtcheff"
      ],
      [
        1,
        "Denis Evrard"
      ],
      [
        1,
        "Ethan Hawke"
      ],
      [
        1,
        "Louise Lemoine Torr\u00e8s"
      ],
      [
        1,
        "Rodolphe Pauly"
      ],
      [
        1,
        "Marie Pillet"
      ],
      [
        1,
        "Mariane Plasteig"
      ]
    ],
    "directors": "Richard Linklater",
    "budget_string": "2.7m USD",
    "gross_string": "15.9m USD",
    "metascore_string": "90%"
  },
  {
    "imdb_id": "tt0317705",
    "plot": "While trying to lead a quiet suburban life, a family of undercover superheroes are forced into action to save the world.",
    "certification": "PG",
    "other_wins_count": 65,
    "other_noms_count": 56,
    "amazon": "https://www.amazon.com/Incredibles-Blu-ray-Craig-Nelson/dp/B01CAFWEPQ/ref=tmm_blu_title_0?_encoding=UTF8&amp;qid=1655185621&amp;sr=1-8&_encoding=UTF8&tag=sianglu-20&linkCode=ur2&linkId=4b9c1524498d17f898d6b642a916dd92&camp=1789&creative=9325",
    "justwatch": "https://justwatch.com/us/movie/the-incredibles?utm_source=share&utm_medium=web",
    "beige_index": 1,
    "beige_angle": 25.9992,
    "cast": [
      [
        5,
        "Kimberly Adair Clark"
      ],
      [
        5,
        "Samuel L. Jackson"
      ],
      [
        3,
        "Louis Gonzales"
      ],
      [
        3,
        "Elizabeth Pe\u00f1a"
      ],
      [
        3,
        "Louis Martin Braga III"
      ],
      [
        2,
        "Sarah Vowell"
      ],
      [
        2,
        "Peter Sohn"
      ],
      [
        1,
        "Bret 'Brook' Parker"
      ],
      [
        1,
        "Mark Andrews"
      ],
      [
        1,
        "Nicholas Bird"
      ],
      [
        1,
        "Michael Bird"
      ],
      [
        1,
        "Brad Bird"
      ],
      [
        1,
        "Wayne Canney"
      ],
      [
        1,
        "Pete Docter"
      ],
      [
        1,
        "Spencer Fox"
      ],
      [
        1,
        "Eli Fucile"
      ],
      [
        1,
        "Pamela Gaye Walker"
      ],
      [
        1,
        "Elizabeth Greenberg"
      ],
      [
        1,
        "Holly Hunter"
      ],
      [
        1,
        "Ollie Johnston"
      ],
      [
        1,
        "Jason Lee"
      ],
      [
        1,
        "Bradford Lewis"
      ],
      [
        1,
        "Dominique Louis"
      ],
      [
        1,
        "Bud Luckey"
      ],
      [
        1,
        "Jazzie Mahannah"
      ],
      [
        1,
        "Ted Mathot"
      ],
      [
        1,
        "Randy Nelson"
      ],
      [
        1,
        "Teddy Newton"
      ],
      [
        1,
        "Bob Peterson"
      ],
      [
        1,
        "Jeff Pidgeon"
      ],
      [
        1,
        "Juliet Pokorny"
      ],
      [
        1,
        "Joe Ranft"
      ],
      [
        1,
        "John Ratzenberger"
      ],
      [
        1,
        "Lori Richardson"
      ],
      [
        1,
        "A.J. Riebli III"
      ],
      [
        1,
        "Katherine Ringgold"
      ],
      [
        1,
        "Lou Romano"
      ],
      [
        1,
        "Stephen Schaffer"
      ],
      [
        1,
        "Bob Scott"
      ],
      [
        1,
        "Wallace Shawn"
      ],
      [
        1,
        "Jean Sincere"
      ],
      [
        1,
        "Andrew Stanton"
      ],
      [
        1,
        "Craig T. Nelson"
      ],
      [
        1,
        "Frank Thomas"
      ],
      [
        1,
        "Deirdre Warin"
      ]
    ],
    "directors": "Brad Bird",
    "budget_string": "92m USD",
    "gross_string": "631.6m USD",
    "metascore_string": "90%"
  },
  {
    "imdb_id": "tt0338013",
    "plot": "When their relationship turns sour, a couple undergoes a medical procedure to have each other erased from their memories.",
    "certification": "R",
    "other_wins_count": 72,
    "other_noms_count": 111,
    "amazon": "https://www.amazon.com/Eternal-Sunshine-Spotless-Mind-Blu-ray/dp/B00466H3DG/ref=sr_1_3?crid=495QJVHZJSCH&amp;keywords=eternal+sunshine+of+the+spotless+mind&amp;qid=1654844679&amp;s=movies-tv&amp;sprefix=eternal+sunshine+%252Cmovies-tv%252C504&amp;sr=1-3&_encoding=UTF8&tag=sianglu-20&linkCode=ur2&linkId=734d339a9ef058544f090e1105ef9c44&camp=1789&creative=9325",
    "justwatch": "https://justwatch.com/us/movie/eternal-sunshine-of-the-spotless-mind?utm_source=share&utm_medium=web",
    "beige_index": 1,
    "beige_angle": 18.0,
    "cast": [
      [
        5,
        "Amir Ali Said"
      ],
      [
        1,
        "Jane Adams"
      ],
      [
        1,
        "Debbon Ayer"
      ],
      [
        1,
        "Jim Carrey"
      ],
      [
        1,
        "David Cross"
      ],
      [
        1,
        "Lola Daehler"
      ],
      [
        1,
        "Kirsten Dunst"
      ],
      [
        1,
        "Josh Flitter"
      ],
      [
        1,
        "Thomas Jay Ryan"
      ],
      [
        1,
        "Paulie Litt"
      ],
      [
        1,
        "Deirdre O'Connell"
      ],
      [
        1,
        "Brian Price"
      ],
      [
        1,
        "Gerry Robert Byrne"
      ],
      [
        1,
        "Mark Ruffalo"
      ],
      [
        1,
        "Ryan Whitney"
      ],
      [
        1,
        "Tom Wilkinson"
      ],
      [
        1,
        "Kate Winslet"
      ],
      [
        1,
        "Elijah Wood"
      ]
    ],
    "directors": "Michel Gondry",
    "budget_string": "20m USD",
    "gross_string": "74m USD",
    "metascore_string": "89%"
  },
  {
    "imdb_id": "tt0405159",
    "plot": "A determined woman works with a hardened boxing trainer to become a professional.",
    "certification": "PG-13",
    "other_wins_count": 63,
    "other_noms_count": 86,
    "amazon": "https://www.amazon.com/Million-Dollar-Blu-ray-Clint-Eastwood/dp/B00FWPQ82C/ref=tmm_mfc_swatch_0?_encoding=UTF8&amp;qid=1655182170&amp;sr=8-1&_encoding=UTF8&tag=sianglu-20&linkCode=ur2&linkId=1a3d8ad6b954367aad381e0728e0e743&camp=1789&creative=9325",
    "justwatch": "https://justwatch.com/us/movie/million-dollar-baby?utm_source=share&utm_medium=web",
    "beige_index": 2,
    "beige_angle": 41.0229,
    "cast": [
      [
        5,
        "Mike Colter"
      ],
      [
        5,
        "Morgan Freeman"
      ],
      [
        5,
        "Anthony Mackie"
      ],
      [
        3,
        "Ray Corona"
      ],
      [
        3,
        "Benito Martinez"
      ],
      [
        3,
        "Michael Pe\u00f1a"
      ],
      [
        3,
        "Miguel P\u00e9rez"
      ],
      [
        3,
        "Marco Rodr\u00edguez"
      ],
      [
        3,
        "Naveen"
      ],
      [
        2,
        "Lucia Rijker"
      ],
      [
        2,
        "Ming Lo"
      ],
      [
        2,
        "Jamison Yang"
      ],
      [
        1,
        "Jay Baruchel"
      ],
      [
        1,
        "Kirsten Berman"
      ],
      [
        1,
        "Jim Cantafio"
      ],
      [
        1,
        "Marcus Chait"
      ],
      [
        1,
        "Jude Ciccolella"
      ],
      [
        1,
        "Jon D. Schorle II"
      ],
      [
        1,
        "Joe D'Angerio"
      ],
      [
        1,
        "Clint Eastwood"
      ],
      [
        1,
        "Morgan Eastwood"
      ],
      [
        1,
        "Ned Eisenberg"
      ],
      [
        1,
        "Br\u00edan F. O'Byrne"
      ],
      [
        1,
        "Dean Familton"
      ],
      [
        1,
        "Don Familton"
      ],
      [
        1,
        "Vincent Foster"
      ],
      [
        1,
        "Erica Grant"
      ],
      [
        1,
        "Ted Grossman"
      ],
      [
        1,
        "Susan Krebs"
      ],
      [
        1,
        "Riki Lindhome"
      ],
      [
        1,
        "Steven M. Porter"
      ],
      [
        1,
        "Bruce MacVittie"
      ],
      [
        1,
        "Rob Maron"
      ],
      [
        1,
        "Margo Martindale"
      ],
      [
        1,
        "Tom McCleister"
      ],
      [
        1,
        "Louis Moret"
      ],
      [
        1,
        "David Powledge"
      ],
      [
        1,
        "Marty Sammon"
      ],
      [
        1,
        "Kim Strauss"
      ],
      [
        1,
        "Hilary Swank"
      ],
      [
        1,
        "Brian T. Finney"
      ],
      [
        1,
        "Mark Thomason"
      ],
      [
        1,
        "Spice Williams-Crosby"
      ]
    ],
    "directors": "Clint Eastwood",
    "budget_string": "30m USD",
    "gross_string": "216.8m USD",
    "metascore_string": "86%"
  },
  {
    "imdb_id": "tt0434409",
    "plot": "In a future British dystopian society, a shadowy freedom fighter, known only by the alias of \"V\", plots to overthrow the tyrannical government - with the help of a young woman.",
    "certification": "R",
    "other_wins_count": 7,
    "other_noms_count": 29,
    "amazon": "https://www.amazon.com/V-Vendetta-Blu-ray-Natalie-Portman/dp/B000PC0U1W/ref=sr_1_1?crid=2BBBENOUFD3U3&amp;keywords=v+for+vendetta+blu+ray&amp;qid=1655180772&amp;s=movies-tv&amp;sprefix=v+for+vendetta+blu+ray%252Cmovies-tv%252C350&amp;sr=1-1&_encoding=UTF8&tag=sianglu-20&linkCode=ur2&linkId=eacda043ad2acd12835d524064aacaaa&camp=1789&creative=9325",
    "justwatch": "https://justwatch.com/us/movie/v-for-vendetta?utm_source=share&utm_medium=web",
    "beige_index": 1,
    "beige_angle": 6.7194,
    "cast": [
      [
        5,
        "Daniel Donaldson"
      ],
      [
        4,
        "David Merheb"
      ],
      [
        1,
        "Roger Allam"
      ],
      [
        1,
        "Paul Antony-Barber"
      ],
      [
        1,
        "Clive Ashborn"
      ],
      [
        1,
        "Matthew Bates"
      ],
      [
        1,
        "Oliver Bradshaw"
      ],
      [
        1,
        "Julie Brown"
      ],
      [
        1,
        "Amelda Brown"
      ],
      [
        1,
        "Ian Burfield"
      ],
      [
        1,
        "Grant Burgin"
      ],
      [
        1,
        "Richard Campbell"
      ],
      [
        1,
        "Billie Cook"
      ],
      [
        1,
        "Charles Cork"
      ],
      [
        1,
        "Roderic Culver"
      ],
      [
        1,
        "Sin\u00e9ad Cusack"
      ],
      [
        1,
        "Greg Donaldson"
      ],
      [
        1,
        "Anna Farnworth"
      ],
      [
        1,
        "Emma Field-Rayner"
      ],
      [
        1,
        "Adrian Finighan"
      ],
      [
        1,
        "Stephen Fry"
      ],
      [
        1,
        "Patricia Gannon"
      ],
      [
        1,
        "Megan Gay"
      ],
      [
        1,
        "Eamon Geoghegan"
      ],
      [
        1,
        "Selina Giles"
      ],
      [
        1,
        "Gerard Gilroy"
      ],
      [
        1,
        "Rupert Graves"
      ],
      [
        1,
        "Laura Greenwood"
      ],
      [
        1,
        "Jason Griffiths"
      ],
      [
        1,
        "Tara Hacking"
      ],
      [
        1,
        "Carsten Hayes"
      ],
      [
        1,
        "Guy Henry"
      ],
      [
        1,
        "Simon Holmes"
      ],
      [
        1,
        "Juliet Howland"
      ],
      [
        1,
        "John Hurt"
      ],
      [
        1,
        "Derek Hutchinson"
      ],
      [
        1,
        "Richard Laing"
      ],
      [
        1,
        "David Leitch"
      ],
      [
        1,
        "Mark Longhurst"
      ],
      [
        1,
        "Eddie Marsan"
      ],
      [
        1,
        "Alister Mazzotti"
      ],
      [
        1,
        "Martin McGlade"
      ],
      [
        1,
        "Kyra Meyer"
      ],
      [
        1,
        "Ben Miles"
      ],
      [
        1,
        "Caoimhe Murdock"
      ],
      [
        1,
        "Sophia New"
      ],
      [
        1,
        "Simon Newby"
      ],
      [
        1,
        "Raife Patrick Burchell"
      ],
      [
        1,
        "Mark Phoenix"
      ],
      [
        1,
        "Tim Pigott-Smith"
      ],
      [
        1,
        "Imogen Poots"
      ],
      [
        1,
        "Natalie Portman"
      ],
      [
        1,
        "Ben Posener"
      ],
      [
        1,
        "Madeleine Rakic-Platt"
      ],
      [
        1,
        "Andy Rashleigh"
      ],
      [
        1,
        "Antje Rau"
      ],
      [
        1,
        "Stephen Rea"
      ],
      [
        1,
        "John Ringham"
      ],
      [
        1,
        "Brin Rosser"
      ],
      [
        1,
        "Joseph Rye"
      ],
      [
        1,
        "Martin Savage"
      ],
      [
        1,
        "Jack Schouten"
      ],
      [
        1,
        "Cosima Shaw"
      ],
      [
        1,
        "Michael Simkins"
      ],
      [
        1,
        "Malcolm Sinclair"
      ],
      [
        1,
        "Dulcie Smart"
      ],
      [
        1,
        "Chad Stahelski"
      ],
      [
        1,
        "John Standing"
      ],
      [
        1,
        "Bradley Steve Ford"
      ],
      [
        1,
        "Mary Stockley"
      ],
      [
        1,
        "Ian T. Dickinson"
      ],
      [
        1,
        "William Tapley"
      ],
      [
        1,
        "Hugo Weaving"
      ],
      [
        1,
        "Natasha Wightman"
      ],
      [
        1,
        "Matt Wilkinson"
      ]
    ],
    "directors": "James McTeigue",
    "budget_string": "54m USD",
    "gross_string": "132.5m USD",
    "metascore_string": "62%"
  },
  {
    "imdb_id": "tt0476735",
    "plot": "The family of a left-wing journalist is torn apart after the military coup of Turkey in 1980.",
    "certification": "Not Rated",
    "other_wins_count": 14,
    "other_noms_count": 7,
    "amazon": "https://www.amazon.com/Ergenc-Fikret-Humeyra-Buyukustun-Tekindor/dp/B000VDIOEA/ref=tmm_dvd_title_0?_encoding=UTF8&amp;amp&amp;qid=1655194091&amp;amp&amp;sr=1-1&_encoding=UTF8&tag=sianglu-20&linkCode=ur2&linkId=45a0c28e4fd74a4f6edb950cdf095099&camp=1789&creative=9325",
    "justwatch": "https://justwatch.com/us/movie/my-father-and-my-son?utm_source=share&utm_medium=web",
    "beige_index": 4,
    "beige_angle": 132.0,
    "cast": [
      [
        4,
        "H\u00fcmeyra"
      ],
      [
        4,
        "Tugyan Akay Kavuk\u00e7u"
      ],
      [
        4,
        "Burak Ak\u00e7akaya"
      ],
      [
        4,
        "Dilek Akinci"
      ],
      [
        4,
        "Ugur Aktas"
      ],
      [
        4,
        "Can Bozoglu"
      ],
      [
        4,
        "Tuba B\u00fcy\u00fck\u00fcst\u00fcn"
      ],
      [
        4,
        "Semih \u00c7akir"
      ],
      [
        4,
        "Berkan Cengiz"
      ],
      [
        4,
        "Askin Ceylan"
      ],
      [
        4,
        "Burak \u00c7imen"
      ],
      [
        4,
        "Ali \u00c7olpan"
      ],
      [
        4,
        "Nergis \u00c7orak\u00e7i"
      ],
      [
        4,
        "Muzaffer Demirel"
      ],
      [
        4,
        "Yetkin Dikinciler"
      ],
      [
        4,
        "Halit Ergen\u00e7"
      ],
      [
        4,
        "Mahmut G\u00f6kg\u00f6z"
      ],
      [
        4,
        "Hayri G\u00fcler"
      ],
      [
        4,
        "Sen G\u00fcnal"
      ],
      [
        4,
        "Ertugrul G\u00fcnd\u00fcz"
      ],
      [
        4,
        "Levent Intepe"
      ],
      [
        4,
        "Emre Karaca"
      ],
      [
        4,
        "Binnur Kaya"
      ],
      [
        4,
        "Ege Kaya"
      ],
      [
        4,
        "Sercan Kilic"
      ],
      [
        4,
        "Neyla Kula"
      ],
      [
        4,
        "Serkan Kunter"
      ],
      [
        4,
        "Fikret Kuskan"
      ],
      [
        4,
        "\u00d6zge \u00d6zberk"
      ],
      [
        4,
        "Ugurcan \u00d6zfirinci"
      ],
      [
        4,
        "Ayta\u00e7 \u00d6zg\u00fcr"
      ],
      [
        4,
        "Rifat Ozkok"
      ],
      [
        4,
        "Sencar Sagdic"
      ],
      [
        4,
        "Murat Saglam"
      ],
      [
        4,
        "Aladdin Sakar"
      ],
      [
        4,
        "Eser Sariyar"
      ],
      [
        4,
        "Bilge Sen"
      ],
      [
        4,
        "Cankat Sesli"
      ],
      [
        4,
        "Serif Sezer"
      ],
      [
        4,
        "Buket Sezgin"
      ],
      [
        4,
        "Serdar Soybelli"
      ],
      [
        4,
        "Ege Tanman"
      ],
      [
        4,
        "Levent Tasci"
      ],
      [
        4,
        "\u00c7etin Tekindor"
      ],
      [
        4,
        "Erdal Tosun"
      ],
      [
        4,
        "G\u00f6rkem Uzun"
      ],
      [
        4,
        "Yakup Yavru"
      ]
    ],
    "directors": "Cagan Irmak",
    "budget_string": "N/A",
    "gross_string": "18.6m USD",
    "metascore_string": "N/A"
  },
  {
    "imdb_id": "tt0372784",
    "plot": "After training with his mentor, Batman begins his fight to free crime-ridden Gotham City from corruption.",
    "certification": "PG-13",
    "other_wins_count": 13,
    "other_noms_count": 78,
    "amazon": "https://www.amazon.com/Batman-Begins-Blu-ray-Emma-Thomas/dp/B000PC6A3E/ref=sr_1_3?crid=2NA81WWZ8IJPU&amp;keywords=batman+begins+blu+ray&amp;qid=1655022920&amp;sprefix=batman+begins%252Caps%252C283&amp;sr=8-3&_encoding=UTF8&tag=sianglu-20&linkCode=ur2&linkId=d0393068f967edac79e25e6006c8ade0&camp=1789&creative=9325",
    "justwatch": "https://justwatch.com/us/movie/batman-begins?utm_source=share&utm_medium=web",
    "beige_index": 2,
    "beige_angle": 43.8453,
    "cast": [
      [
        5,
        "Christine Adams"
      ],
      [
        5,
        "Kwaku Ankomah"
      ],
      [
        5,
        "Earlene Bentley"
      ],
      [
        5,
        "Leon Delroy Williams"
      ],
      [
        5,
        "Morgan Freeman"
      ],
      [
        5,
        "Roger Griffiths"
      ],
      [
        5,
        "Emmanuel Idowu"
      ],
      [
        5,
        "Jo Martin"
      ],
      [
        5,
        "Colin McFarlane"
      ],
      [
        5,
        "Jordan Shaw"
      ],
      [
        5,
        "Mel Taylor"
      ],
      [
        5,
        "Cedric Young"
      ],
      [
        4,
        "Omar Mostafa"
      ],
      [
        4,
        "Mark Rhino Smith"
      ],
      [
        3,
        "David Bedella"
      ],
      [
        3,
        "Jay Buozzi"
      ],
      [
        2,
        "Khan Bonfils"
      ],
      [
        2,
        "Chike Chan"
      ],
      [
        2,
        "Mark Chiu"
      ],
      [
        2,
        "Tenzin Clive Ball"
      ],
      [
        2,
        "Tenzin Gyurme"
      ],
      [
        2,
        "Soo Hee Ding"
      ],
      [
        2,
        "Turbo Kong"
      ],
      [
        2,
        "Ken Watanabe"
      ],
      [
        2,
        "Vincent Wong"
      ],
      [
        2,
        "Tom Wu"
      ],
      [
        2,
        "Roger Yuan"
      ],
      [
        2,
        "Sai-Kit Yung"
      ],
      [
        1,
        "Jamie Hayden"
      ],
      [
        1,
        "Rick Avery"
      ],
      [
        1,
        "Christian Bale"
      ],
      [
        1,
        "Alexandra Bastedo"
      ],
      [
        1,
        "Mark Boone Junior"
      ],
      [
        1,
        "Tim Booth"
      ],
      [
        1,
        "Richard Brake"
      ],
      [
        1,
        "Dominic Burgess"
      ],
      [
        1,
        "John Burke"
      ],
      [
        1,
        "Michael Caine"
      ],
      [
        1,
        "Nadia Cameron-Blakey"
      ],
      [
        1,
        "Rory Campbell"
      ],
      [
        1,
        "Fabio Cardascia"
      ],
      [
        1,
        "Jeff Christian"
      ],
      [
        1,
        "Risteard Cooper"
      ],
      [
        1,
        "Phill Curr"
      ],
      [
        1,
        "Jonathan D. Ellis"
      ],
      [
        1,
        "Karen David"
      ],
      [
        1,
        "Timothy Deenihan"
      ],
      [
        1,
        "Charles Edwards"
      ],
      [
        1,
        "Ilyssa Fradin"
      ],
      [
        1,
        "Jack Gleeson"
      ],
      [
        1,
        "Ruben Halse"
      ],
      [
        1,
        "Joe Hanley"
      ],
      [
        1,
        "Tamer Hassan"
      ],
      [
        1,
        "Rutger Hauer"
      ],
      [
        1,
        "Larry Holden"
      ],
      [
        1,
        "Katie Holmes"
      ],
      [
        1,
        "Conn Horgan"
      ],
      [
        1,
        "Kieran Hurley"
      ],
      [
        1,
        "John Judd"
      ],
      [
        1,
        "John Kazek"
      ],
      [
        1,
        "Darragh Kelly"
      ],
      [
        1,
        "Charlie Kranz"
      ],
      [
        1,
        "Richard Laing"
      ],
      [
        1,
        "Ronan Leahy"
      ],
      [
        1,
        "Noah Lee Margetts"
      ],
      [
        1,
        "Dave Legeno"
      ],
      [
        1,
        "Gus Lewis"
      ],
      [
        1,
        "Emma Lockhart"
      ],
      [
        1,
        "Flavia Masetto"
      ],
      [
        1,
        "Martin McDougall"
      ],
      [
        1,
        "Terry McMahon"
      ],
      [
        1,
        "Matt Miller"
      ],
      [
        1,
        "Alex Moggridge"
      ],
      [
        1,
        "Gerard Murphy"
      ],
      [
        1,
        "Cillian Murphy"
      ],
      [
        1,
        "David Murray"
      ],
      [
        1,
        "Liam Neeson"
      ],
      [
        1,
        "Tom Nolan"
      ],
      [
        1,
        "John Nolan"
      ],
      [
        1,
        "Patrick Nolan"
      ],
      [
        1,
        "Gary Oldman"
      ],
      [
        1,
        "Andrew Pleavin"
      ],
      [
        1,
        "Catherine Porter"
      ],
      [
        1,
        "T.J. Ramini"
      ],
      [
        1,
        "Shane Rimmer"
      ],
      [
        1,
        "Linus Roache"
      ],
      [
        1,
        "Lucy Russell"
      ],
      [
        1,
        "Joseph Rye"
      ],
      [
        1,
        "Joe Sargent"
      ],
      [
        1,
        "Rade Serbedzija"
      ],
      [
        1,
        "Karl Shiels"
      ],
      [
        1,
        "Emily Steven-Daly"
      ],
      [
        1,
        "Sara Stewart"
      ],
      [
        1,
        "Mark Straker"
      ],
      [
        1,
        "Jeremy Theobald"
      ],
      [
        1,
        "Poppy Tierney"
      ],
      [
        1,
        "Stephen Walters"
      ],
      [
        1,
        "Sarah Wateridge"
      ],
      [
        1,
        "Spencer Wilding"
      ],
      [
        1,
        "Tom Wilkinson"
      ]
    ],
    "directors": "Christopher Nolan",
    "budget_string": "150m USD",
    "gross_string": "373.7m USD",
    "metascore_string": "70%"
  },
  {
    "imdb_id": "tt0407887",
    "plot": "An undercover cop and a mole in the police attempt to identify each other while infiltrating an Irish gang in South Boston.",
    "certification": "R",
    "other_wins_count": 93,
    "other_noms_count": 141,
    "amazon": "https://www.amazon.com/Departed-Blu-ray/dp/B000PC1MNM/ref=sr_1_1?crid=3EVM0XY79ZWM3&amp;keywords=the+departed+dvd&amp;qid=1654831765&amp;s=movies-tv&amp;sprefix=the+%252Cmovies-tv-intl-ship%252C525&amp;sr=1-1&_encoding=UTF8&tag=sianglu-20&linkCode=ur2&linkId=6ca45c639f14964ffc516d7cb1893032&camp=1789&creative=9325",
    "justwatch": "https://justwatch.com/us/movie/the-departed?utm_source=share&utm_medium=web",
    "beige_index": 1,
    "beige_angle": 20.142,
    "cast": [
      [
        5,
        "Anthony Anderson"
      ],
      [
        5,
        "Alex Morris"
      ],
      [
        5,
        "Darryl Wooten"
      ],
      [
        5,
        "Gurdeep Singh"
      ],
      [
        4,
        "Armen Garo"
      ],
      [
        2,
        "Robert 'Toshi' Kar Yuen Chan"
      ],
      [
        2,
        "Victor Chan"
      ],
      [
        2,
        "Lyman Chen"
      ],
      [
        2,
        "Tony M. Yee"
      ],
      [
        2,
        "Walter Y.F. Wong"
      ],
      [
        2,
        "Henry Yuk"
      ],
      [
        1,
        "Thomas B. Duffy"
      ],
      [
        1,
        "James Badge Dale"
      ],
      [
        1,
        "Alec Baldwin"
      ],
      [
        1,
        "Andrew Breving"
      ],
      [
        1,
        "Jill Brown"
      ],
      [
        1,
        "Michael Byron"
      ],
      [
        1,
        "Deborah Carlson"
      ],
      [
        1,
        "Craig Castaldo"
      ],
      [
        1,
        "John Cenatiempo"
      ],
      [
        1,
        "Johnny Cicco"
      ],
      [
        1,
        "Bo Cleary"
      ],
      [
        1,
        "David Conley"
      ],
      [
        1,
        "Patrick Coppola"
      ],
      [
        1,
        "Kevin Corrigan"
      ],
      [
        1,
        "Peter Crafts"
      ],
      [
        1,
        "Douglas Crosby"
      ],
      [
        1,
        "Paddy Curran"
      ],
      [
        1,
        "Kristen Dalton"
      ],
      [
        1,
        "Matt Damon"
      ],
      [
        1,
        "Paula DeMers"
      ],
      [
        1,
        "Leonardo DiCaprio"
      ],
      [
        1,
        "Conor Donovan"
      ],
      [
        1,
        "Shay Duffin"
      ],
      [
        1,
        "Anthony Estrella"
      ],
      [
        1,
        "Daniel F. Risteen Jr."
      ],
      [
        1,
        "Vera Farmiga"
      ],
      [
        1,
        "John Farrer"
      ],
      [
        1,
        "Sarah Fearon"
      ],
      [
        1,
        "Chris Fischer"
      ],
      [
        1,
        "David Fischer"
      ],
      [
        1,
        "Jay Giannone"
      ],
      [
        1,
        "Brian Haley"
      ],
      [
        1,
        "Peg Holzemer"
      ],
      [
        1,
        "Richard Hughes"
      ],
      [
        1,
        "Paris Karounos"
      ],
      [
        1,
        "Chance Kelly"
      ],
      [
        1,
        "Tom Kemp"
      ],
      [
        1,
        "Mary Klug"
      ],
      [
        1,
        "William Lee"
      ],
      [
        1,
        "Dorothy Lyman"
      ],
      [
        1,
        "Dennis Lynch"
      ],
      [
        1,
        "Amanda Lynch"
      ],
      [
        1,
        "J.C. MacKenzie"
      ],
      [
        1,
        "Frank Mallicoat"
      ],
      [
        1,
        "John McConnell"
      ],
      [
        1,
        "Larry Mitchell"
      ],
      [
        1,
        "Audrie Neenan"
      ],
      [
        1,
        "Jack Nicholson"
      ],
      [
        1,
        "David O'Hara"
      ],
      [
        1,
        "Mick O'Rourke"
      ],
      [
        1,
        "Francis P. Hughes"
      ],
      [
        1,
        "Kevin P. McCarthy"
      ],
      [
        1,
        "Joseph P. Reidy"
      ],
      [
        1,
        "Tracey Paleo"
      ],
      [
        1,
        "Zachary Pauliks"
      ],
      [
        1,
        "John Polce"
      ],
      [
        1,
        "Joseph Riccobene"
      ],
      [
        1,
        "Mark Rolston"
      ],
      [
        1,
        "John Rue"
      ],
      [
        1,
        "Denece Ryland"
      ],
      [
        1,
        "Nellie Sciutto"
      ],
      [
        1,
        "Francesca Scorsese"
      ],
      [
        1,
        "Terry Serpico"
      ],
      [
        1,
        "William Severs"
      ],
      [
        1,
        "Martin Sheen"
      ],
      [
        1,
        "Billy Smith"
      ],
      [
        1,
        "Brian Smyj"
      ],
      [
        1,
        "Emma Tillinger Koskoff"
      ],
      [
        1,
        "Sallie Toussaint"
      ],
      [
        1,
        "Robert Wahlberg"
      ],
      [
        1,
        "Mark Wahlberg"
      ],
      [
        1,
        "Peter Welch"
      ],
      [
        1,
        "Ray Winstone"
      ]
    ],
    "directors": "Martin Scorsese",
    "budget_string": "90m USD",
    "gross_string": "291.5m USD",
    "metascore_string": "85%"
  },
  {
    "imdb_id": "tt0482571",
    "plot": "After a tragic accident, two stage magicians in 1890s London engage in a battle to create the ultimate illusion while sacrificing everything they have to outwit each other.",
    "certification": "PG-13",
    "other_wins_count": 6,
    "other_noms_count": 43,
    "amazon": "https://www.amazon.com/Prestige-Blu-ray-Christian-Bale/dp/B000L212HC/ref=sr_1_3?crid=1QEC7SHS96KL6&amp;keywords=the+prestige&amp;qid=1654831934&amp;s=movies-tv&amp;sprefix=the+%252Cmovies-tv-intl-ship%252C668&amp;sr=1-3&_encoding=UTF8&tag=sianglu-20&linkCode=ur2&linkId=768dfe5708cd4b0dfbab5276c0261d50&camp=1789&creative=9325",
    "justwatch": "https://justwatch.com/us/movie/the-prestige?utm_source=share&utm_medium=web",
    "beige_index": 1,
    "beige_angle": 1.2006,
    "cast": [
      [
        2,
        "Chao Li Chi"
      ],
      [
        1,
        "John B. Crye"
      ],
      [
        1,
        "Christian Bale"
      ],
      [
        1,
        "Jodi Bianca Wise"
      ],
      [
        1,
        "David Bowie"
      ],
      [
        1,
        "Ezra Buzzington"
      ],
      [
        1,
        "Michael Caine"
      ],
      [
        1,
        "Chris Cleveland"
      ],
      [
        1,
        "Daniel Davis"
      ],
      [
        1,
        "Scott Davis"
      ],
      [
        1,
        "Anthony De Marco"
      ],
      [
        1,
        "Russ Fega"
      ],
      [
        1,
        "Nikki Glick"
      ],
      [
        1,
        "Rebecca Hall"
      ],
      [
        1,
        "Jamie Harris"
      ],
      [
        1,
        "Edward Hibbert"
      ],
      [
        1,
        "Sean Howse"
      ],
      [
        1,
        "Gregory Humphreys"
      ],
      [
        1,
        "Hugh Jackman"
      ],
      [
        1,
        "Ricky Jay"
      ],
      [
        1,
        "Scarlett Johansson"
      ],
      [
        1,
        "Christopher Judges"
      ],
      [
        1,
        "Clive Kennedy"
      ],
      [
        1,
        "James Lancaster"
      ],
      [
        1,
        "Johnny Liska"
      ],
      [
        1,
        "Samantha Mahurin"
      ],
      [
        1,
        "Sam Menning"
      ],
      [
        1,
        "Olivia Merg"
      ],
      [
        1,
        "Zoe Merg"
      ],
      [
        1,
        "William Morgan Sheppard"
      ],
      [
        1,
        "Christopher Neame"
      ],
      [
        1,
        "James Otis"
      ],
      [
        1,
        "J. Paul Moore"
      ],
      [
        1,
        "Piper Perabo"
      ],
      [
        1,
        "Ron Perkins"
      ],
      [
        1,
        "Jim Piddock"
      ],
      [
        1,
        "Roger Rees"
      ],
      [
        1,
        "Enn Reitel"
      ],
      [
        1,
        "Mark Ryan"
      ],
      [
        1,
        "Julia Sanford"
      ],
      [
        1,
        "Andy Serkis"
      ],
      [
        1,
        "Monty Stuart"
      ],
      [
        1,
        "Brian Tahash"
      ],
      [
        1,
        "Robert W. Arbogast"
      ],
      [
        1,
        "Kevin Will"
      ]
    ],
    "directors": "Christopher Nolan",
    "budget_string": "40m USD",
    "gross_string": "109.7m USD",
    "metascore_string": "66%"
  },
  {
    "imdb_id": "tt0457430",
    "plot": "In the Falangist Spain of 1944, the bookish young stepdaughter of a sadistic army officer escapes into an eerie but captivating fantasy world.",
    "certification": "R",
    "other_wins_count": 106,
    "other_noms_count": 115,
    "amazon": "https://www.amazon.com/Pans-Labyrinth-Blu-ray-Alfonso-Cuar%25C3%25B3n/dp/B000WSLAUO/ref=sr_1_3?crid=9IVK7QDAZ91F&amp;keywords=pan%2527s+labyrinth+blu+ray&amp;qid=1655174523&amp;sprefix=pan%2527s+labyrinth+blu+ra%252Caps%252C282&amp;sr=8-3&_encoding=UTF8&tag=sianglu-20&linkCode=ur2&linkId=8f668adf4edbfd7574fa2c563860d10d&camp=1789&creative=9325",
    "justwatch": "https://justwatch.com/us/movie/pans-labyrinth?utm_source=share&utm_medium=web",
    "beige_index": 1,
    "beige_angle": 4.6458,
    "cast": [
      [
        3,
        "Federico Luppi"
      ],
      [
        1,
        "Pablo Ad\u00e1n"
      ],
      [
        1,
        "Fernando Albizu"
      ],
      [
        1,
        "\u00c1lex Angulo"
      ],
      [
        1,
        "Ivana Baquero"
      ],
      [
        1,
        "Chicho Campillo"
      ],
      [
        1,
        "Roger Casamajor"
      ],
      [
        1,
        "Juanjo Cucal\u00f3n"
      ],
      [
        1,
        "Mila Espiga"
      ],
      [
        1,
        "Pedro G. Marzo"
      ],
      [
        1,
        "\u00cd\u00f1igo Garc\u00e9s"
      ],
      [
        1,
        "Lal\u00e1 Gat\u00f3o"
      ],
      [
        1,
        "Ariadna Gil"
      ],
      [
        1,
        "Sebasti\u00e1n Haro"
      ],
      [
        1,
        "Doug Jones"
      ],
      [
        1,
        "Eusebio L\u00e1zaro"
      ],
      [
        1,
        "Sergi L\u00f3pez"
      ],
      [
        1,
        "Jos\u00e9 Luis Torrijo"
      ],
      [
        1,
        "Chani Mart\u00edn"
      ],
      [
        1,
        "Ivan Massagu\u00e9"
      ],
      [
        1,
        "Lina Mira"
      ],
      [
        1,
        "Pepa Pedroche"
      ],
      [
        1,
        "Ana S\u00e1ez"
      ],
      [
        1,
        "Manolo Solo"
      ],
      [
        1,
        "Milo Taboada"
      ],
      [
        1,
        "Fernando Tielve"
      ],
      [
        1,
        "Gonzalo Uriarte"
      ],
      [
        1,
        "C\u00e9sar Vea"
      ],
      [
        1,
        "Maribel Verd\u00fa"
      ],
      [
        1,
        "Francisco Vidal"
      ],
      [
        1,
        "Mario Zorrilla"
      ]
    ],
    "directors": "Guillermo del Toro",
    "budget_string": "19m USD",
    "gross_string": "83.9m USD",
    "metascore_string": "98%"
  },
  {
    "imdb_id": "tt0405094",
    "plot": "In 1984 East Berlin, an agent of the secret police, conducting surveillance on a writer and his lover, finds himself becoming increasingly absorbed by their lives.",
    "certification": "R",
    "other_wins_count": 79,
    "other_noms_count": 38,
    "amazon": "https://www.amazon.com/The-Lives-of-Others/dp/B00185EENU/ref=tmm_dvd_title_0?_encoding=UTF8&amp;amp&amp;qid=1654841576&amp;amp&amp;sr=8-2&_encoding=UTF8&tag=sianglu-20&linkCode=ur2&linkId=692f477bfcc6ea01b82c7655fa5c0df7&camp=1789&creative=9325",
    "justwatch": "https://justwatch.com/us/movie/the-lives-of-others?utm_source=share&utm_medium=web",
    "beige_index": 1,
    "beige_angle": 9.0,
    "cast": [
      [
        5,
        "Sheri Hagen"
      ],
      [
        1,
        "Thomas Arnold"
      ],
      [
        1,
        "Hans-Uwe Bauer"
      ],
      [
        1,
        "Inga Birkenfeld"
      ],
      [
        1,
        "Ludwig Blochberger"
      ],
      [
        1,
        "Martin Brambach"
      ],
      [
        1,
        "Matthias Brenner"
      ],
      [
        1,
        "Werner Daehn"
      ],
      [
        1,
        "Paul Fa\u00dfnacht"
      ],
      [
        1,
        "Gabi Fleming"
      ],
      [
        1,
        "Martina Gedeck"
      ],
      [
        1,
        "Michael Gerber"
      ],
      [
        1,
        "Marie Gruber"
      ],
      [
        1,
        "Hubertus Hartmann"
      ],
      [
        1,
        "Charly H\u00fcbner"
      ],
      [
        1,
        "Kai Ivo Baulitz"
      ],
      [
        1,
        "Elja-Dusa Kedves"
      ],
      [
        1,
        "Philipp Kewenig"
      ],
      [
        1,
        "Volkmar Kleinert"
      ],
      [
        1,
        "Herbert Knaup"
      ],
      [
        1,
        "Sebastian Koch"
      ],
      [
        1,
        "Susanna Kraus"
      ],
      [
        1,
        "Manfred Ludwig Sextett"
      ],
      [
        1,
        "Paul Maximilian Sch\u00fcller"
      ],
      [
        1,
        "Volker Michalowski"
      ],
      [
        1,
        "Ulrich M\u00fche"
      ],
      [
        1,
        "Ernst-Ludwig Petrowsky"
      ],
      [
        1,
        "Harald Polzin"
      ],
      [
        1,
        "Hinnerk Sch\u00f6nemann"
      ],
      [
        1,
        "Hildegard Schroedter"
      ],
      [
        1,
        "Gitta Schweigh\u00f6fer"
      ],
      [
        1,
        "Thomas Thieme"
      ],
      [
        1,
        "Bastian Trost"
      ],
      [
        1,
        "Ulrich Tukur"
      ],
      [
        1,
        "Fabian von Klitzing"
      ],
      [
        1,
        "Jens Wassermann"
      ]
    ],
    "directors": "Florian Henckel von Donnersmarck",
    "budget_string": "2m USD",
    "gross_string": "77.4m USD",
    "metascore_string": "89%"
  },
  {
    "imdb_id": "tt0758758",
    "plot": "After graduating from Emory University, top student and athlete Christopher McCandless abandons his possessions, gives his entire $24,000 savings account to charity and hitchhikes to Alaska to live in the wilderness. Along the way, Christopher encounters a series of characters that shape his life.",
    "certification": "R",
    "other_wins_count": 23,
    "other_noms_count": 104,
    "amazon": "https://www.amazon.com/Into-Wild-Blu-ray-digtial-copy/dp/B09328MFGT/ref=sr_1_1?crid=LNR84E0TUOB9&amp;keywords=into+the+wild+blu+ray&amp;qid=1655184889&amp;s=movies-tv&amp;sprefix=into+the+wild+blu+ray%252Cmovies-tv%252C301&amp;sr=1-1&_encoding=UTF8&tag=sianglu-20&linkCode=ur2&linkId=5878c7a038a29e4b511fb955691da286&camp=1789&creative=9325",
    "justwatch": "https://justwatch.com/us/movie/into-the-wild?utm_source=share&utm_medium=web",
    "beige_index": 1,
    "beige_angle": 21.9996,
    "cast": [
      [
        5,
        "Cheryl Francis Harrington"
      ],
      [
        5,
        "Paul Knauls"
      ],
      [
        3,
        "Matt Contreras"
      ],
      [
        1,
        "Jim Beidler"
      ],
      [
        1,
        "R.D. Call"
      ],
      [
        1,
        "Candice Campos"
      ],
      [
        1,
        "Jim Davis"
      ],
      [
        1,
        "John Decker"
      ],
      [
        1,
        "Signe Egholm Olsen"
      ],
      [
        1,
        "Zach Galifianakis"
      ],
      [
        1,
        "Jim Gallien"
      ],
      [
        1,
        "Marcia Gay Harden"
      ],
      [
        1,
        "Brian H. Dierker"
      ],
      [
        1,
        "Emile Hirsch"
      ],
      [
        1,
        "John Hofer"
      ],
      [
        1,
        "Jerry Hofer"
      ],
      [
        1,
        "Hal Holbrook"
      ],
      [
        1,
        "William Hurt"
      ],
      [
        1,
        "John Jabaley"
      ],
      [
        1,
        "Catherine Keener"
      ],
      [
        1,
        "Leonard Knight"
      ],
      [
        1,
        "Thure Lindhardt"
      ],
      [
        1,
        "Robin Mathews"
      ],
      [
        1,
        "Carine McCandless"
      ],
      [
        1,
        "Malinda McCollum"
      ],
      [
        1,
        "Craig Mutsch"
      ],
      [
        1,
        "James O'Neill"
      ],
      [
        1,
        "Sharon Olds"
      ],
      [
        1,
        "Haley Ramm"
      ],
      [
        1,
        "Kristen Stewart"
      ],
      [
        1,
        "Vince Vaughn"
      ],
      [
        1,
        "Terry Waldner"
      ],
      [
        1,
        "Floyd Wall"
      ],
      [
        1,
        "Bryce Walters"
      ],
      [
        1,
        "Merritt Wever"
      ],
      [
        1,
        "Steven Wiig"
      ]
    ],
    "directors": "Sean Penn",
    "budget_string": "15m USD",
    "gross_string": "56.7m USD",
    "metascore_string": "73%"
  },
  {
    "imdb_id": "tt0382932",
    "plot": "A rat who can cook makes an unusual alliance with a young kitchen worker at a famous Paris restaurant.",
    "certification": "G",
    "other_wins_count": 66,
    "other_noms_count": 42,
    "amazon": "https://www.amazon.com/RATATOUILLE-Blu-ray-Patton-Oswalt/dp/B07TPXM2FS/ref=tmm_blu_title_0?_encoding=UTF8&amp;amp&amp;qid=1655185362&amp;amp&amp;sr=1-1&_encoding=UTF8&tag=sianglu-20&linkCode=ur2&linkId=e20ea4414ba500858ea88280c6d689f3&camp=1789&creative=9325",
    "justwatch": "https://justwatch.com/us/movie/ratatouille?utm_source=share&utm_medium=web",
    "beige_index": 1,
    "beige_angle": 2.4552,
    "cast": [
      [
        2,
        "Peter Sohn"
      ],
      [
        1,
        "Will Arnett"
      ],
      [
        1,
        "Brad Bird"
      ],
      [
        1,
        "Jack Bird"
      ],
      [
        1,
        "Julius Callahan"
      ],
      [
        1,
        "Lindsey Collins"
      ],
      [
        1,
        "Brian Dennehy"
      ],
      [
        1,
        "Tony Fucile"
      ],
      [
        1,
        "Janeane Garofalo"
      ],
      [
        1,
        "Brad Garrett"
      ],
      [
        1,
        "Ian Holm"
      ],
      [
        1,
        "Thomas Keller"
      ],
      [
        1,
        "Bradford Lewis"
      ],
      [
        1,
        "Teddy Newton"
      ],
      [
        1,
        "Peter O'Toole"
      ],
      [
        1,
        "Patton Oswalt"
      ],
      [
        1,
        "John Ratzenberger"
      ],
      [
        1,
        "James Remar"
      ],
      [
        1,
        "Lori Richardson"
      ],
      [
        1,
        "Lou Romano"
      ],
      [
        1,
        "St\u00e9phane Roux"
      ],
      [
        1,
        "Jake Steinfeld"
      ]
    ],
    "directors": "Brad Bird & Jan Pinkava",
    "budget_string": "150m USD",
    "gross_string": "623.7m USD",
    "metascore_string": "96%"
  },
  {
    "imdb_id": "tt0986264",
    "plot": "An eight-year-old boy is thought to be a lazy trouble-maker, until the new art teacher has the patience and compassion to discover the real problem behind his struggles in school.",
    "certification": "PG",
    "other_wins_count": 24,
    "other_noms_count": 15,
    "amazon": "https://www.amazon.com/Taare-Zameen-par-Ein-Stern-allemand/dp/B0020GUWR8/ref=sr_1_4?crid=216Z3DQSDMQHM&amp;keywords=Taare+Zameen+Par&amp;qid=1655008599&amp;sprefix=taare+zameen+par%252Caps%252C276&amp;sr=8-4&_encoding=UTF8&tag=sianglu-20&linkCode=ur2&linkId=9b62ce83a6a9fa4695c4cffb7c8852a8&camp=1789&creative=9325",
    "justwatch": "https://justwatch.com/us/movie/like-stars-on-earth?utm_source=share&utm_medium=web",
    "beige_index": 5,
    "beige_angle": 149.1428571429,
    "cast": [
      [
        5,
        "Gurkirtan"
      ],
      [
        5,
        "Ayaan"
      ],
      [
        5,
        "Ricky"
      ],
      [
        5,
        "Jadav"
      ],
      [
        5,
        "Vitthal"
      ],
      [
        5,
        "Prashant"
      ],
      [
        5,
        "Vivekanandan"
      ],
      [
        5,
        "Abhishek Bachchan"
      ],
      [
        5,
        "Megha Bengali"
      ],
      [
        5,
        "Bugs Bhargava Krishna"
      ],
      [
        5,
        "Alorika Chatterjee"
      ],
      [
        5,
        "Tanay Chheda"
      ],
      [
        5,
        "Tisca Chopra"
      ],
      [
        5,
        "Sanjay Dadich"
      ],
      [
        5,
        "Madhav Datt"
      ],
      [
        5,
        "Sachet Engineer"
      ],
      [
        5,
        "Aniket Engineer"
      ],
      [
        5,
        "Krishn Gopinath"
      ],
      [
        5,
        "Munireh Guhilot"
      ],
      [
        5,
        "Ramit Gupta"
      ],
      [
        5,
        "Rajgopal Iyer"
      ],
      [
        5,
        "Gurdeepak Kaur"
      ],
      [
        5,
        "Aamir Khan"
      ],
      [
        5,
        "Ravi Khanvilkar"
      ],
      [
        5,
        "Pratima Kulkarni"
      ],
      [
        5,
        "Girish Kumar Menon"
      ],
      [
        5,
        "Lalitha Lajmi"
      ],
      [
        5,
        "Brihan Lamba"
      ],
      [
        5,
        "Meghna Malik"
      ],
      [
        5,
        "Veer Mohan"
      ],
      [
        5,
        "Girija Oak"
      ],
      [
        5,
        "M.K. Raina"
      ],
      [
        5,
        "Sameer Rajda"
      ],
      [
        5,
        "Sonali Sachdev"
      ],
      [
        5,
        "Shankar Sachdev"
      ],
      [
        5,
        "Darsheel Safary"
      ],
      [
        5,
        "Vipin Sharma"
      ],
      [
        5,
        "Sanjay Sinha"
      ],
      [
        5,
        "Arnav Valcha"
      ]
    ],
    "directors": "Aamir Khan & Amole Gupte",
    "budget_string": "N/A",
    "gross_string": "21.9m USD",
    "metascore_string": "N/A"
  },
  {
    "imdb_id": "tt0477348",
    "plot": "Violence and mayhem ensue after a hunter stumbles upon a drug deal gone wrong and more than two million dollars in cash near the Rio Grande.",
    "certification": "R",
    "other_wins_count": 159,
    "other_noms_count": 140,
    "amazon": "https://www.amazon.com/Country-Old-Men-Blu-ray-Digital/dp/B08F6TVVX3/ref=sr_1_2?crid=F1T7XTR2L6GW&amp;keywords=no+country+for+old+men+blu+ray&amp;qid=1655179531&amp;sprefix=no+country+for+old+men+blu+ray%252Caps%252C372&amp;sr=8-2&_encoding=UTF8&tag=sianglu-20&linkCode=ur2&linkId=a6fbfa9900040ae625d246e26bfa93cd&camp=1789&creative=9325",
    "justwatch": "https://justwatch.com/us/movie/no-country-for-old-men?utm_source=share&utm_medium=web",
    "beige_index": 1,
    "beige_angle": 30.2724,
    "cast": [
      [
        3,
        "David A. Gomez"
      ],
      [
        3,
        "George Adelo"
      ],
      [
        3,
        "Eduardo Antonio Garcia"
      ],
      [
        3,
        "Javier Bardem"
      ],
      [
        3,
        "Angel H. Alvarado Jr."
      ],
      [
        3,
        "Johnnie Hector"
      ],
      [
        3,
        "Milton Hernandez"
      ],
      [
        3,
        "John Mancha"
      ],
      [
        3,
        "Dorsey Ray"
      ],
      [
        2,
        "Roland Uribe"
      ],
      [
        1,
        "Josh Blaylock"
      ],
      [
        1,
        "Margaret Bowman"
      ],
      [
        1,
        "Rodger Boyce"
      ],
      [
        1,
        "Josh Brolin"
      ],
      [
        1,
        "Barry Corbin"
      ],
      [
        1,
        "Rutherford Cravens"
      ],
      [
        1,
        "Garret Dillahunt"
      ],
      [
        1,
        "Jason Douglas"
      ],
      [
        1,
        "Beth Grant"
      ],
      [
        1,
        "Mathew Greer"
      ],
      [
        1,
        "Kit Gwin"
      ],
      [
        1,
        "Doris Hargrave"
      ],
      [
        1,
        "Tess Harper"
      ],
      [
        1,
        "Woody Harrelson"
      ],
      [
        1,
        "Zach Hopkins"
      ],
      [
        1,
        "Richard Jackson"
      ],
      [
        1,
        "Gene Jones"
      ],
      [
        1,
        "Thomas Kopache"
      ],
      [
        1,
        "Kathy Lamkin"
      ],
      [
        1,
        "Caleb Landry Jones"
      ],
      [
        1,
        "Tommy Lee Jones"
      ],
      [
        1,
        "Chip Love"
      ],
      [
        1,
        "Kelly Macdonald"
      ],
      [
        1,
        "Josh Meyer"
      ],
      [
        1,
        "Marc Miles"
      ],
      [
        1,
        "Trent Moore"
      ],
      [
        1,
        "Matthew Posey"
      ],
      [
        1,
        "Luce Rains"
      ],
      [
        1,
        "Ana Reeder"
      ],
      [
        1,
        "Stephen Root"
      ],
      [
        1,
        "Brandon Smith"
      ],
      [
        1,
        "Boots Southerland"
      ],
      [
        1,
        "Chris Warner"
      ],
      [
        1,
        "Myk Watford"
      ]
    ],
    "directors": "Ethan Coen & Joel Coen",
    "budget_string": "25m USD",
    "gross_string": "171.6m USD",
    "metascore_string": "92%"
  },
  {
    "imdb_id": "tt0469494",
    "plot": "A story of family, religion, hatred, oil and madness, focusing on a turn-of-the-century prospector in the early days of the business.",
    "certification": "R",
    "other_wins_count": 114,
    "other_noms_count": 137,
    "amazon": "https://www.amazon.com/There-Will-Be-Blood-Blu-ray/dp/B072ZLL4M2/ref=tmm_blu_swatch_0?_encoding=UTF8&amp;qid=1655174721&amp;sr=8-1&_encoding=UTF8&tag=sianglu-20&linkCode=ur2&linkId=876fffbf390447951abf23e5ea9504c2&camp=1789&creative=9325",
    "justwatch": "https://justwatch.com/us/movie/il-y-aura-du-sang?utm_source=share&utm_medium=web",
    "beige_index": 1,
    "beige_angle": 0.0,
    "cast": [
      [
        1,
        "Robert Arber"
      ],
      [
        1,
        "Robert Barge"
      ],
      [
        1,
        "Steven Barr"
      ],
      [
        1,
        "Bob Bell"
      ],
      [
        1,
        "Bob Bock"
      ],
      [
        1,
        "Matthew Braden Stringer"
      ],
      [
        1,
        "Kevin Breznahan"
      ],
      [
        1,
        "Barry Bruce"
      ],
      [
        1,
        "John Burton"
      ],
      [
        1,
        "Robert Caroline"
      ],
      [
        1,
        "Randall Carver"
      ],
      [
        1,
        "John Chitwood"
      ],
      [
        1,
        "Paul Dano"
      ],
      [
        1,
        "Daniel Day-Lewis"
      ],
      [
        1,
        "Barry Del Sherman"
      ],
      [
        1,
        "James Downey"
      ],
      [
        1,
        "Hope Elizabeth Reeves"
      ],
      [
        1,
        "Paul F. Tompkins"
      ],
      [
        1,
        "Colleen Foy"
      ],
      [
        1,
        "Dillon Freasier"
      ],
      [
        1,
        "Vince Froio"
      ],
      [
        1,
        "Irene G. Hunter"
      ],
      [
        1,
        "Louise Gregg"
      ],
      [
        1,
        "Russell Harvard"
      ],
      [
        1,
        "Kellie Hill"
      ],
      [
        1,
        "Robert Hills"
      ],
      [
        1,
        "Ciar\u00e1n Hinds"
      ],
      [
        1,
        "Hans Howes"
      ],
      [
        1,
        "Kevin J. O'Connor"
      ],
      [
        1,
        "Ronald Krut"
      ],
      [
        1,
        "Coco Leigh"
      ],
      [
        1,
        "Sydney McCallister"
      ],
      [
        1,
        "Jim Meskimen"
      ],
      [
        1,
        "Joseph Mussey"
      ],
      [
        1,
        "Christine Olejniczak"
      ],
      [
        1,
        "Joy Rawls"
      ],
      [
        1,
        "Huey Rhudy"
      ],
      [
        1,
        "Amber Roberts"
      ],
      [
        1,
        "Phil Shelly"
      ],
      [
        1,
        "Jacob Stringer"
      ],
      [
        1,
        "Martin Stringer"
      ],
      [
        1,
        "Erica Sullivan"
      ],
      [
        1,
        "Dan Swallow"
      ],
      [
        1,
        "Stockton Taylor"
      ],
      [
        1,
        "Harrison Taylor"
      ],
      [
        1,
        "Charles Thomas Doyle"
      ],
      [
        1,
        "John W. Watts"
      ],
      [
        1,
        "David Warshofsky"
      ],
      [
        1,
        "David Williams"
      ],
      [
        1,
        "David Willis"
      ],
      [
        1,
        "Colton Woodward"
      ]
    ],
    "directors": "Paul Thomas Anderson",
    "budget_string": "25m USD",
    "gross_string": "76.2m USD",
    "metascore_string": "93%"
  },
  {
    "imdb_id": "tt0910970",
    "plot": "In the distant future, a small waste-collecting robot inadvertently embarks on a space journey that will ultimately decide the fate of mankind.",
    "certification": "G",
    "other_wins_count": 93,
    "other_noms_count": 95,
    "amazon": "https://www.amazon.com/WALL-Blu-ray-Ben-Burtt/dp/B07TMK5GGW/ref=tmm_blu_swatch_0?_encoding=UTF8&amp;qid=1654841504&amp;sr=8-2&_encoding=UTF8&tag=sianglu-20&linkCode=ur2&linkId=54139b9d5c1124263a5a7ce77c0818e3&camp=1789&creative=9325",
    "justwatch": "https://justwatch.com/us/movie/wall-e?utm_source=share&utm_medium=web",
    "beige_index": 1,
    "beige_angle": 25.2,
    "cast": [
      [
        5,
        "Donald Fullilove"
      ],
      [
        4,
        "Kathy Najimy"
      ],
      [
        1,
        "Lori Alan"
      ],
      [
        1,
        "Ben Burtt"
      ],
      [
        1,
        "John Cygan"
      ],
      [
        1,
        "Pete Docter"
      ],
      [
        1,
        "Teresa Ganzel"
      ],
      [
        1,
        "Jeff Garlin"
      ],
      [
        1,
        "Jess Harnell"
      ],
      [
        1,
        "Elissa Knight"
      ],
      [
        1,
        "Laraine Newman"
      ],
      [
        1,
        "Teddy Newton"
      ],
      [
        1,
        "Jeff Pidgeon"
      ],
      [
        1,
        "Jan Rabson"
      ],
      [
        1,
        "John Ratzenberger"
      ],
      [
        1,
        "Lori Richardson"
      ],
      [
        1,
        "Andrew Stanton"
      ],
      [
        1,
        "Sigourney Weaver"
      ],
      [
        1,
        "Colette Whitaker"
      ],
      [
        1,
        "Fred Willard"
      ]
    ],
    "directors": "Andrew Stanton",
    "budget_string": "180m USD",
    "gross_string": "521.3m USD",
    "metascore_string": "95%"
  },
  {
    "imdb_id": "tt1205489",
    "plot": "Disgruntled Korean War veteran Walt Kowalski sets out to reform his neighbor, Thao Lor, a Hmong teenager who tried to steal Kowalski's prized possession: a 1972 Gran Torino.",
    "certification": "R",
    "other_wins_count": 21,
    "other_noms_count": 22,
    "amazon": "https://www.amazon.com/Gran-Torino/dp/B00YQJRXWE/ref=tmm_blu_title_0?_encoding=UTF8&amp;qid=1655181687&amp;sr=8-3&_encoding=UTF8&tag=sianglu-20&linkCode=ur2&linkId=67f4dfc4c81748837996689545965d26&camp=1789&creative=9325",
    "justwatch": "https://justwatch.com/us/movie/gran-torino?utm_source=share&utm_medium=web",
    "beige_index": 2,
    "beige_angle": 49.7646,
    "cast": [
      [
        5,
        "Arthur Cartwright"
      ],
      [
        5,
        "My-Ishia Cason-Brown"
      ],
      [
        5,
        "Nana Gbewonyo"
      ],
      [
        5,
        "Cory Hardrict"
      ],
      [
        3,
        "Ramon Camacho"
      ],
      [
        3,
        "Parng D. Yarng"
      ],
      [
        3,
        "Carlos Guadarrama"
      ],
      [
        3,
        "Maykao K. Lytongpao"
      ],
      [
        3,
        "Antonio Mireles"
      ],
      [
        3,
        "Andrew Tamez-Hull"
      ],
      [
        2,
        "Brooke Chia Thao"
      ],
      [
        2,
        "Tru Hang"
      ],
      [
        2,
        "Ahney Her"
      ],
      [
        2,
        "Julia Ho"
      ],
      [
        2,
        "Zoua Kue"
      ],
      [
        2,
        "Stephen Kue"
      ],
      [
        2,
        "Choua Kue"
      ],
      [
        2,
        "Jerry Lee"
      ],
      [
        2,
        "Alice Lor"
      ],
      [
        2,
        "Douacha Ly"
      ],
      [
        2,
        "Lee Mong Vang"
      ],
      [
        2,
        "Doua Moua"
      ],
      [
        2,
        "Tong Pao Kue"
      ],
      [
        2,
        "Xia Soua Chang"
      ],
      [
        2,
        "Chee Thao"
      ],
      [
        2,
        "Elvis Thao"
      ],
      [
        2,
        "Bee Vang"
      ],
      [
        2,
        "Sonny Vue"
      ],
      [
        2,
        "Ia Vue Yang"
      ],
      [
        2,
        "Nelly Yang Sao Yia"
      ],
      [
        1,
        "Vincent Bonasso"
      ],
      [
        1,
        "Marty Bufalini"
      ],
      [
        1,
        "Christopher Carley"
      ],
      [
        1,
        "John Carroll Lynch"
      ],
      [
        1,
        "Thomas D. Mahard"
      ],
      [
        1,
        "Austin Douglas Smith"
      ],
      [
        1,
        "Michael E. Kurowski"
      ],
      [
        1,
        "Clint Eastwood"
      ],
      [
        1,
        "Scott Eastwood"
      ],
      [
        1,
        "Davis Gloff"
      ],
      [
        1,
        "Brian Haley"
      ],
      [
        1,
        "William Hill"
      ],
      [
        1,
        "Brian Howe"
      ],
      [
        1,
        "Geraldine Hughes"
      ],
      [
        1,
        "John Johns"
      ],
      [
        1,
        "Conor Liam Callaghan"
      ],
      [
        1,
        "Claudia Rodgers"
      ],
      [
        1,
        "Greg Trzaskoma"
      ],
      [
        1,
        "Dreama Walker"
      ],
      [
        1,
        "Clint Ward"
      ],
      [
        1,
        "Rochelle Winter"
      ]
    ],
    "directors": "Clint Eastwood",
    "budget_string": "33m USD",
    "gross_string": "270m USD",
    "metascore_string": "72%"
  },
  {
    "imdb_id": "tt0468569",
    "plot": "When the menace known as the Joker wreaks havoc and chaos on the people of Gotham, Batman must accept one of the greatest psychological and physical tests of his ability to fight injustice.",
    "certification": "PG-13",
    "other_wins_count": 157,
    "other_noms_count": 163,
    "amazon": "https://www.amazon.com/Dark-Knight-Trilogy-UHD-Blu-ray/dp/B0774D6HBB/ref=sr_1_2?crid=ZGYE0M6EPJWZ&amp;keywords=dark+knight+4k&amp;qid=1654825456&amp;sprefix=dark+knight%252Caps%252C718&amp;sr=8-2&_encoding=UTF8&tag=sianglu-20&linkCode=ur2&linkId=b81076981b8e6374c876761c0c6c54e3&camp=1789&creative=9325",
    "justwatch": "https://justwatch.com/us/movie/batman-the-dark-knight?utm_source=share&utm_medium=web",
    "beige_index": 2,
    "beige_angle": 45.9279,
    "cast": [
      [
        5,
        "David Ajala"
      ],
      [
        5,
        "Ariyon Bakare"
      ],
      [
        5,
        "Winston Ellis"
      ],
      [
        5,
        "Morgan Freeman"
      ],
      [
        5,
        "Lorna Gayle"
      ],
      [
        5,
        "Michael Jai White"
      ],
      [
        5,
        "Tom Lister Jr."
      ],
      [
        5,
        "Lateef Lovejoy"
      ],
      [
        5,
        "Colin McFarlane"
      ],
      [
        5,
        "Gertrude Mosley"
      ],
      [
        5,
        "Olumiji Olawumi"
      ],
      [
        5,
        "Daryl Satcher"
      ],
      [
        5,
        "K. Todd Freeman"
      ],
      [
        5,
        "Charles Venn"
      ],
      [
        3,
        "Joe Caballero"
      ],
      [
        3,
        "Nestor Carbonell"
      ],
      [
        3,
        "Monique Gabriela Curnen"
      ],
      [
        3,
        "Dale Rivera"
      ],
      [
        3,
        "Nydia Rodriguez Terracina"
      ],
      [
        2,
        "Edison Chen"
      ],
      [
        2,
        "Chin Han"
      ],
      [
        2,
        "Wai Wong"
      ],
      [
        1,
        "Michael Andrew Gorman"
      ],
      [
        1,
        "William Armstrong"
      ],
      [
        1,
        "Christian Bale"
      ],
      [
        1,
        "Doug Ballard"
      ],
      [
        1,
        "Greg Beam"
      ],
      [
        1,
        "Andrew Bicknell"
      ],
      [
        1,
        "Paul Birchard"
      ],
      [
        1,
        "Peter Brooke"
      ],
      [
        1,
        "Philip Bulcock"
      ],
      [
        1,
        "Michael Caine"
      ],
      [
        1,
        "Tommy Campbell"
      ],
      [
        1,
        "Nigel Carrington"
      ],
      [
        1,
        "Patrick Clear"
      ],
      [
        1,
        "Michael Corey Foster"
      ],
      [
        1,
        "Ritchie Coster"
      ],
      [
        1,
        "Nancy Crane"
      ],
      [
        1,
        "David Dastmalchian"
      ],
      [
        1,
        "Ron Dean"
      ],
      [
        1,
        "Peter DeFaria"
      ],
      [
        1,
        "Sam Derence"
      ],
      [
        1,
        "Richard Dillane"
      ],
      [
        1,
        "Aaron Eckhart"
      ],
      [
        1,
        "Grahame Edwards"
      ],
      [
        1,
        "James Farruggio"
      ],
      [
        1,
        "Aidan Feore"
      ],
      [
        1,
        "William Fichtner"
      ],
      [
        1,
        "James Fierro"
      ],
      [
        1,
        "Thomas Gaitsch"
      ],
      [
        1,
        "Nathan Gamble"
      ],
      [
        1,
        "Danny Goldring"
      ],
      [
        1,
        "Hannah Gunn"
      ],
      [
        1,
        "Maggie Gyllenhaal"
      ],
      [
        1,
        "Joshua Harto"
      ],
      [
        1,
        "Craig Heaney"
      ],
      [
        1,
        "Erik Hellman"
      ],
      [
        1,
        "Sophia Hinshelwood"
      ],
      [
        1,
        "Sarah Jayne Dunn"
      ],
      [
        1,
        "Adam Kalesperis"
      ],
      [
        1,
        "Jennifer Knox"
      ],
      [
        1,
        "Keith Kupferer"
      ],
      [
        1,
        "Brandon Lambdin"
      ],
      [
        1,
        "Patrick Leahy"
      ],
      [
        1,
        "Heath Ledger"
      ],
      [
        1,
        "Matthew Leitch"
      ],
      [
        1,
        "Walter Lewis"
      ],
      [
        1,
        "Andy Luther"
      ],
      [
        1,
        "Lanny Lutz"
      ],
      [
        1,
        "Helene Maksoud"
      ],
      [
        1,
        "Lisa McAllister"
      ],
      [
        1,
        "Tom McElroy"
      ],
      [
        1,
        "Melinda McGraw"
      ],
      [
        1,
        "Anthony Michael Hall"
      ],
      [
        1,
        "Roger Monk"
      ],
      [
        1,
        "Cillian Murphy"
      ],
      [
        1,
        "Vincenzo Nicoli"
      ],
      [
        1,
        "Matthew O'Neill"
      ],
      [
        1,
        "Gary Oldman"
      ],
      [
        1,
        "Chris Petschler"
      ],
      [
        1,
        "Ian Pirie"
      ],
      [
        1,
        "Vincent Riotta"
      ],
      [
        1,
        "Matt Rippy"
      ],
      [
        1,
        "Eric Roberts"
      ],
      [
        1,
        "Joshua Rollins"
      ],
      [
        1,
        "Beatrice Rosen"
      ],
      [
        1,
        "Jonathan Ryland"
      ],
      [
        1,
        "James Scales"
      ],
      [
        1,
        "Matt Shallenberger"
      ],
      [
        1,
        "William Smillie"
      ],
      [
        1,
        "Michael Stoyanov"
      ],
      [
        1,
        "Ronan Summers"
      ],
      [
        1,
        "Keith Szarabajka"
      ],
      [
        1,
        "Tristan Tait"
      ],
      [
        1,
        "Michael Vieau"
      ],
      [
        1,
        "Bronson Webb"
      ],
      [
        1,
        "Will Zahrn"
      ]
    ],
    "directors": "Christopher Nolan",
    "budget_string": "185m USD",
    "gross_string": "1006.1m USD",
    "metascore_string": "84%"
  },
  {
    "imdb_id": "tt1305806",
    "plot": "A retired legal counselor writes a novel hoping to find closure for one of his past unresolved homicide cases and for his unreciprocated love with his superior - both of which still haunt him decades later.",
    "certification": "R",
    "other_wins_count": 52,
    "other_noms_count": 43,
    "amazon": "https://www.amazon.com/Secret-Their-Eyes-Blu-ray/dp/B0036TGSJO/ref=sr_1_1?crid=281330ZYBMN0B&amp;keywords=the+secret+in+their+eyes+blu+ray&amp;qid=1655181164&amp;s=movies-tv&amp;sprefix=the+secret+in+their+eyes+blu+ray%252Cmovies-tv%252C281&amp;sr=1-1&_encoding=UTF8&tag=sianglu-20&linkCode=ur2&linkId=f8b1f8deab923087d20238e68a94a1f7&camp=1789&creative=9325",
    "justwatch": "https://justwatch.com/us/movie/the-secret-in-their-eyes?utm_source=share&utm_medium=web",
    "beige_index": 3,
    "beige_angle": 103.3542,
    "cast": [
      [
        3,
        "Alejandro Abelenda"
      ],
      [
        3,
        "Mario Alarc\u00f3n"
      ],
      [
        3,
        "Mariano Argento"
      ],
      [
        3,
        "Sebasti\u00e1n Blanco"
      ],
      [
        3,
        "Judith Buchalter"
      ],
      [
        3,
        "Kiko Cerone"
      ],
      [
        3,
        "Liliana Cuomo"
      ],
      [
        3,
        "Gabriela Daniell"
      ],
      [
        3,
        "Ricardo Dar\u00edn"
      ],
      [
        3,
        "David Di Napoli"
      ],
      [
        3,
        "Elvio Duvini"
      ],
      [
        3,
        "Guillermo Francella"
      ],
      [
        3,
        "Alicia Hayd\u00e9e Pennachi"
      ],
      [
        3,
        "Juan Jos\u00e9 Ort\u00edz"
      ],
      [
        3,
        "Pedro Kochdilian"
      ],
      [
        3,
        "H\u00e9ctor La Porta"
      ],
      [
        3,
        "Sergio L\u00f3pez Santana"
      ],
      [
        3,
        "Jos\u00e9 Luis Gioia"
      ],
      [
        3,
        "Carlos Mele"
      ],
      [
        3,
        "B\u00e1rbara Palladino"
      ],
      [
        3,
        "Fernando Pardo"
      ],
      [
        3,
        "Alejandro P\u00e9rez"
      ],
      [
        3,
        "Carla Quevedo"
      ],
      [
        3,
        "Pablo Rago"
      ],
      [
        3,
        "Rudy Romano"
      ],
      [
        3,
        "Oscar S\u00e1nchez"
      ],
      [
        3,
        "Iv\u00e1n Sosa"
      ],
      [
        3,
        "Maximiliano Trento"
      ],
      [
        3,
        "Dar\u00edo Valenzuela"
      ],
      [
        3,
        "Soledad Villamil"
      ],
      [
        1,
        "Javier Godino"
      ]
    ],
    "directors": "Juan Jos\u00e9 Campanella",
    "budget_string": "2m USD",
    "gross_string": "35.1m USD",
    "metascore_string": "80%"
  },
  {
    "imdb_id": "tt1187043",
    "plot": "Two friends are searching for their long lost companion. They revisit their college days and recall the memories of their friend who inspired them to think differently, even as the rest of the world called them \"idiots\".",
    "certification": "PG-13",
    "other_wins_count": 63,
    "other_noms_count": 27,
    "amazon": "https://www.amazon.com/3-Idiots-Aamir-Khan/dp/B07V4JBS1H/ref=sr_1_2?crid=1Q2VOFHFLUNXB&amp;keywords=3+idiots&amp;qid=1654844384&amp;s=movies-tv&amp;sprefix=3+id%252Cmovies-tv%252C1059&amp;sr=1-2&_encoding=UTF8&tag=sianglu-20&linkCode=ur2&linkId=a12736044e5b2b8524169e017c21e380&camp=1789&creative=9325",
    "justwatch": "https://justwatch.com/us/movie/3-idiots?utm_source=share&utm_medium=web",
    "beige_index": 5,
    "beige_angle": 148.32,
    "cast": [
      [
        5,
        "Pitobash"
      ],
      [
        5,
        "Madhavan"
      ],
      [
        5,
        "Sarvanna"
      ],
      [
        5,
        "Smriti"
      ],
      [
        5,
        "Sonu"
      ],
      [
        5,
        "Vaidyanathan"
      ],
      [
        5,
        "Raghunathan"
      ],
      [
        5,
        "Saurrabh Agnihotri"
      ],
      [
        5,
        "Tanveer Ahmed"
      ],
      [
        5,
        "Shoaib Ahmed"
      ],
      [
        5,
        "Arun Bali"
      ],
      [
        5,
        "Freny Bhagat"
      ],
      [
        5,
        "Meghna Bhalla"
      ],
      [
        5,
        "Mukund Bhatt"
      ],
      [
        5,
        "Dharmendra Bhurji"
      ],
      [
        5,
        "Chaitali Bose"
      ],
      [
        5,
        "Aakash Dabhade"
      ],
      [
        5,
        "Farida Dadi"
      ],
      [
        5,
        "Dileep Desai"
      ],
      [
        5,
        "Shailja Dhar"
      ],
      [
        5,
        "Jigmet Dorjey"
      ],
      [
        5,
        "A.S. Duggal"
      ],
      [
        5,
        "Dilshad Edibam"
      ],
      [
        5,
        "Ali Fazal"
      ],
      [
        5,
        "Rohitash Gaud"
      ],
      [
        5,
        "Elihud George"
      ],
      [
        5,
        "Pooja Goswami"
      ],
      [
        5,
        "Boman Irani"
      ],
      [
        5,
        "Jaaved Jaaferi"
      ],
      [
        5,
        "Apul Jaisinghani"
      ],
      [
        5,
        "Komal Jha"
      ],
      [
        5,
        "Amardeep Jha"
      ],
      [
        5,
        "Michael Joseph"
      ],
      [
        5,
        "Sharman Joshi"
      ],
      [
        5,
        "Kareena Kapoor"
      ],
      [
        5,
        "Annapurna Kaul"
      ],
      [
        5,
        "Aamir Khan"
      ],
      [
        5,
        "R.S. Kodange"
      ],
      [
        5,
        "Jayant Kripalani"
      ],
      [
        5,
        "Praful Kulkarni"
      ],
      [
        5,
        "Dinesh Kumar"
      ],
      [
        5,
        "Rahul Kumar"
      ],
      [
        5,
        "Lata Kurdikar"
      ],
      [
        5,
        "Insia Lacewalla"
      ],
      [
        5,
        "Akhil Mishra"
      ],
      [
        5,
        "Md. Nadim Mostofa Jibon"
      ],
      [
        5,
        "Debashish Naha"
      ],
      [
        5,
        "Tsanwal Namgyal"
      ],
      [
        5,
        "Jalil Parkar"
      ],
      [
        5,
        "Kishor Patil"
      ],
      [
        5,
        "Rajendra Patwardhan"
      ],
      [
        5,
        "Achyut Potdar"
      ],
      [
        5,
        "Pundit Prayag Raj"
      ],
      [
        5,
        "Keshav Rae"
      ],
      [
        5,
        "Rajeev Ravindranathan"
      ],
      [
        5,
        "Shankar Sachdev"
      ],
      [
        5,
        "Trilok Sadhwani"
      ],
      [
        5,
        "Parikshit Sahni"
      ],
      [
        5,
        "Rakesh Sharma"
      ],
      [
        5,
        "Dayal Sharma"
      ],
      [
        5,
        "Sitaram Sharma"
      ],
      [
        5,
        "Supriya Shukla"
      ],
      [
        5,
        "Nishi Singh"
      ],
      [
        5,
        "Mona Singh"
      ],
      [
        5,
        "Malvika Singh"
      ],
      [
        5,
        "Harvinder Singh"
      ],
      [
        5,
        "Sanjay Sood"
      ],
      [
        5,
        "Hitesh Tak"
      ],
      [
        5,
        "Atul Tiwari"
      ],
      [
        5,
        "Omi Vaidya"
      ],
      [
        5,
        "Suhas Vaidya"
      ],
      [
        5,
        "Madhav Vaze"
      ],
      [
        5,
        "Kumar Veer Singh"
      ],
      [
        5,
        "Dushyant Wagh"
      ],
      [
        3,
        "Olivier Lafont"
      ]
    ],
    "directors": "Rajkumar Hirani",
    "budget_string": "11.4m USD",
    "gross_string": "60.3m USD",
    "metascore_string": "67%"
  },
  {
    "imdb_id": "tt0978762",
    "plot": "A tale of friendship between two unlikely pen pals: Mary, a lonely, eight-year-old girl living in the suburbs of Melbourne, and Max, a forty-four-year old, severely obese man living in New York.",
    "certification": "Not Rated",
    "other_wins_count": 5,
    "other_noms_count": 9,
    "amazon": "https://www.amazon.com/Mary-Max-Blu-ray-Toni-Collette/dp/B003CJXIOG/ref=sr_1_1?crid=33MUX8DLOLACH&amp;keywords=mary+and+max+blu+ray&amp;qid=1655183995&amp;sprefix=mary+and+max+blu+ray%252Caps%252C278&amp;sr=8-1&_encoding=UTF8&tag=sianglu-20&linkCode=ur2&linkId=a83007f491ea787ecad098113f9129dd&camp=1789&creative=9325",
    "justwatch": "https://justwatch.com/us/movie/mary-and-max?utm_source=share&utm_medium=web",
    "beige_index": 1,
    "beige_angle": 25.9992,
    "cast": [
      [
        5,
        "Christopher Massey"
      ],
      [
        5,
        "Carolyn Shakespeare-Allen"
      ],
      [
        2,
        "Mandy Mao"
      ],
      [
        1,
        "Ian 'Molly' Meldrum"
      ],
      [
        1,
        "Eric Bana"
      ],
      [
        1,
        "Bernie Clifford"
      ],
      [
        1,
        "Toni Collette"
      ],
      [
        1,
        "Melanie Coombs"
      ],
      [
        1,
        "Dan Doherty"
      ],
      [
        1,
        "Adam Elliot"
      ],
      [
        1,
        "John Flaus"
      ],
      [
        1,
        "Julie Forsyth"
      ],
      [
        1,
        "Ren\u00e9e Geyer"
      ],
      [
        1,
        "Antoinette Halloran"
      ],
      [
        1,
        "Hamish Hughes"
      ],
      [
        1,
        "Barry Humphries"
      ],
      [
        1,
        "Michael James Allen"
      ],
      [
        1,
        "Alf Klimek"
      ],
      [
        1,
        "Athanasios Kourtidis"
      ],
      [
        1,
        "Yorgos Kourtidis"
      ],
      [
        1,
        "Daniel Marks"
      ],
      [
        1,
        "Oliver Marks"
      ],
      [
        1,
        "Patrick McCabe"
      ],
      [
        1,
        "Shaun Patten"
      ],
      [
        1,
        "Philip Seymour Hoffman"
      ],
      [
        1,
        "Leanne Smith"
      ],
      [
        1,
        "Bethany Whitmore"
      ]
    ],
    "directors": "Adam Elliot",
    "budget_string": "10.4m USD",
    "gross_string": "1.7m USD",
    "metascore_string": "N/A"
  },
  {
    "imdb_id": "tt0361748",
    "plot": "In Nazi-occupied France during World War II, a plan to assassinate Nazi leaders by a group of Jewish U.S. soldiers coincides with a theatre owner's vengeful plans for the same.",
    "certification": "R",
    "other_wins_count": 132,
    "other_noms_count": 172,
    "amazon": "https://www.amazon.com/Inglourious-Basterds-Blu-ray-Brad-Pitt/dp/B002RD55MQ/ref=sr_1_3?crid=2K0YWSPDKTKOG&amp;keywords=inglourious+basterds+blu+ray&amp;qid=1654843610&amp;s=movies-tv&amp;sprefix=inglourious+basterrds%252Cmovies-tv%252C677&amp;sr=1-3&_encoding=UTF8&tag=sianglu-20&linkCode=ur2&linkId=30e85cc76ea64cb198d31a939a8fc08d&camp=1789&creative=9325",
    "justwatch": "https://justwatch.com/us/movie/inglorious-basterds?utm_source=share&utm_medium=web",
    "beige_index": 1,
    "beige_angle": 16.7994,
    "cast": [
      [
        5,
        "Jacky Ido"
      ],
      [
        5,
        "Samuel L. Jackson"
      ],
      [
        3,
        "Tina Rodriguez"
      ],
      [
        3,
        "Salvadore Brandt"
      ],
      [
        3,
        "Carlos Fidel"
      ],
      [
        1,
        "Michael Bacall"
      ],
      [
        1,
        "Christian Berkel"
      ],
      [
        1,
        "Rainer Bock"
      ],
      [
        1,
        "Christian Br\u00fcckner"
      ],
      [
        1,
        "Daniel Br\u00fchl"
      ],
      [
        1,
        "Gedeon Burkhard"
      ],
      [
        1,
        "August Diehl"
      ],
      [
        1,
        "Omar Doom"
      ],
      [
        1,
        "Julie Dreyfus"
      ],
      [
        1,
        "Ken Duken"
      ],
      [
        1,
        "Hilmar Eichhorn"
      ],
      [
        1,
        "Patrick Elias"
      ],
      [
        1,
        "Michael Fassbender"
      ],
      [
        1,
        "Alexander Fehling"
      ],
      [
        1,
        "Anne-Sophie Franck"
      ],
      [
        1,
        "Lena Friedrich"
      ],
      [
        1,
        "Enzo G. Castellari"
      ],
      [
        1,
        "Jake Garber"
      ],
      [
        1,
        "Olivier Girard"
      ],
      [
        1,
        "Sylvester Groth"
      ],
      [
        1,
        "Petra Hartung"
      ],
      [
        1,
        "Wilfried Hochholdinger"
      ],
      [
        1,
        "Sebastian H\u00fclk"
      ],
      [
        1,
        "Buddy Joe Hooker"
      ],
      [
        1,
        "Harvey Keitel"
      ],
      [
        1,
        "Michael Kranz"
      ],
      [
        1,
        "Diane Kruger"
      ],
      [
        1,
        "M\u00e9lanie Laurent"
      ],
      [
        1,
        "Samm Levine"
      ],
      [
        1,
        "Wolfgang Lindner"
      ],
      [
        1,
        "Jasper Linnewedel"
      ],
      [
        1,
        "Eva L\u00f6bau"
      ],
      [
        1,
        "Denis M\u00e9nochet"
      ],
      [
        1,
        "Volker Michalowski"
      ],
      [
        1,
        "S\u00f6nke M\u00f6hring"
      ],
      [
        1,
        "Mike Myers"
      ],
      [
        1,
        "B.J. Novak"
      ],
      [
        1,
        "Jana Pallaske"
      ],
      [
        1,
        "Andr\u00e9 Penvern"
      ],
      [
        1,
        "Ludger Pistor"
      ],
      [
        1,
        "Brad Pitt"
      ],
      [
        1,
        "Leo Plank"
      ],
      [
        1,
        "Eli Roth"
      ],
      [
        1,
        "Paul Rust"
      ],
      [
        1,
        "Richard Sammel"
      ],
      [
        1,
        "Michael Scheel"
      ],
      [
        1,
        "Til Schweiger"
      ],
      [
        1,
        "Arndt Schwering-Sohnrey"
      ],
      [
        1,
        "L\u00e9a Seydoux"
      ],
      [
        1,
        "Bo Svenson"
      ],
      [
        1,
        "Quentin Tarantino"
      ],
      [
        1,
        "Rod Taylor"
      ],
      [
        1,
        "Andreas Tietz"
      ],
      [
        1,
        "Christoph Waltz"
      ],
      [
        1,
        "Martin Wuttke"
      ]
    ],
    "directors": "Quentin Tarantino",
    "budget_string": "70m USD",
    "gross_string": "321.5m USD",
    "metascore_string": "69%"
  },
  {
    "imdb_id": "tt1049413",
    "plot": "78-year-old Carl Fredricksen travels to Paradise Falls in his house equipped with balloons, inadvertently taking a young stowaway.",
    "certification": "PG",
    "other_wins_count": 77,
    "other_noms_count": 87,
    "amazon": "https://www.amazon.com/UP-Blu-ray-Ed-Asner/dp/B07TLH8DN6/ref=tmm_blu_title_0?_encoding=UTF8&amp;qid=1655007881&amp;sr=8-1&_encoding=UTF8&tag=sianglu-20&linkCode=ur2&linkId=3a6601d4c15819049c8eda2d9dc4434e&camp=1789&creative=9325",
    "justwatch": "https://justwatch.com/us/movie/up-2009?utm_source=share&utm_medium=web",
    "beige_index": 1,
    "beige_angle": 25.9992,
    "cast": [
      [
        5,
        "Donald Fullilove"
      ],
      [
        5,
        "Delroy Lindo"
      ],
      [
        2,
        "Jordan Nagai"
      ],
      [
        1,
        "Mark Andrews"
      ],
      [
        1,
        "Ed Asner"
      ],
      [
        1,
        "Brenda Chapman"
      ],
      [
        1,
        "Emma Coats"
      ],
      [
        1,
        "Josh Cooley"
      ],
      [
        1,
        "John Cygan"
      ],
      [
        1,
        "Pete Docter"
      ],
      [
        1,
        "Elie Docter"
      ],
      [
        1,
        "Tony Fucile"
      ],
      [
        1,
        "Teresa Ganzel"
      ],
      [
        1,
        "Jess Harnell"
      ],
      [
        1,
        "David Kaye"
      ],
      [
        1,
        "Valerie LaPointe"
      ],
      [
        1,
        "Danny Mann"
      ],
      [
        1,
        "Laraine Newman"
      ],
      [
        1,
        "Teddy Newton"
      ],
      [
        1,
        "Bob Peterson"
      ],
      [
        1,
        "Jeff Pidgeon"
      ],
      [
        1,
        "Christopher Plummer"
      ],
      [
        1,
        "Jan Rabson"
      ],
      [
        1,
        "Jerome Ranft"
      ],
      [
        1,
        "John Ratzenberger"
      ],
      [
        1,
        "Bob Scott"
      ],
      [
        1,
        "Harry Shearer"
      ]
    ],
    "directors": "Pete Docter & Bob Peterson",
    "budget_string": "175m USD",
    "gross_string": "735.1m USD",
    "metascore_string": "88%"
  },
  {
    "imdb_id": "tt1028532",
    "plot": "A college professor bonds with an abandoned dog he takes into his home.",
    "certification": "G",
    "other_wins_count": 0,
    "other_noms_count": 1,
    "amazon": "https://www.amazon.com/Hachi-Dogs-Blu-ray-Richard-Gere/dp/B0031RAOW8/ref=sr_1_2?crid=2Z0J26XRWNX9D&amp;keywords=hachi+a+dog%2527s+tale+blu+ray&amp;qid=1655185931&amp;s=movies-tv&amp;sprefix=hachi+a+dog%2527s+tale+blu+ray%252Cmovies-tv%252C270&amp;sr=1-2&_encoding=UTF8&tag=sianglu-20&linkCode=ur2&linkId=24e764f3bae0aa68019ed02bc7e2d662&camp=1789&creative=9325",
    "justwatch": "https://justwatch.com/us/movie/hachi-a-dogs-tale?utm_source=share&utm_medium=web",
    "beige_index": 1,
    "beige_angle": 16.434,
    "cast": [
      [
        5,
        "Davenia McFadden"
      ],
      [
        2,
        "Cary-Hiroyuki Tagawa"
      ],
      [
        1,
        "Jason Alexander"
      ],
      [
        1,
        "Joan Allen"
      ],
      [
        1,
        "Erick Avari"
      ],
      [
        1,
        "Gloria Crist"
      ],
      [
        1,
        "Timothy Crowe"
      ],
      [
        1,
        "Kevin DeCoste"
      ],
      [
        1,
        "Rob Degnan"
      ],
      [
        1,
        "Troy Doherty"
      ],
      [
        1,
        "Blake Friedman"
      ],
      [
        1,
        "Richard Gere"
      ],
      [
        1,
        "Tora Hallstr\u00f6m"
      ],
      [
        1,
        "Daniel Kirby"
      ],
      [
        1,
        "Sarah Roemer"
      ],
      [
        1,
        "Denece Ryland"
      ],
      [
        1,
        "Frank S. Aronson"
      ],
      [
        1,
        "Ian Sherman"
      ],
      [
        1,
        "Donna Sorbello"
      ],
      [
        1,
        "Robbie Sublett"
      ],
      [
        1,
        "Rich Tretheway"
      ],
      [
        1,
        "Thomas Tynell"
      ],
      [
        1,
        "Bates Wilder"
      ]
    ],
    "directors": "Lasse Hallstr\u00f6m",
    "budget_string": "16m USD",
    "gross_string": "46.7m USD",
    "metascore_string": "N/A"
  },
  {
    "imdb_id": "tt1130884",
    "plot": "In 1954, a U.S. Marshal investigates the disappearance of a murderer who escaped from a hospital for the criminally insane.",
    "certification": "R",
    "other_wins_count": 11,
    "other_noms_count": 66,
    "amazon": "https://www.amazon.com/Shutter-Island-Blu-ray-Leonardo-DiCaprio/dp/B071FH3J88/ref=tmm_blu_swatch_0?_encoding=UTF8&amp;qid=1655175507&amp;sr=8-2&_encoding=UTF8&tag=sianglu-20&linkCode=ur2&linkId=97cfd84fa34120c7b9f3b6da6824f3d1&camp=1789&creative=9325",
    "justwatch": "https://justwatch.com/us/movie/shutter-island?utm_source=share&utm_medium=web",
    "beige_index": 2,
    "beige_angle": 38.3076,
    "cast": [
      [
        5,
        "Raymond Anthony Thomas"
      ],
      [
        5,
        "Curtiss Cook"
      ],
      [
        5,
        "Keith Fluker"
      ],
      [
        5,
        "Darryl Wooten"
      ],
      [
        4,
        "Ziad Akl"
      ],
      [
        3,
        "Ben Kingsley"
      ],
      [
        1,
        "Thomas B. Duffy"
      ],
      [
        1,
        "Robin Bartlett"
      ],
      [
        1,
        "Drew Beasley"
      ],
      [
        1,
        "Michael Byron"
      ],
      [
        1,
        "John Carroll Lynch"
      ],
      [
        1,
        "Ken Cheeseman"
      ],
      [
        1,
        "Patricia Clarkson"
      ],
      [
        1,
        "Aidan Cole Mitchell"
      ],
      [
        1,
        "Matthew Cowles"
      ],
      [
        1,
        "Christopher Denham"
      ],
      [
        1,
        "Leonardo DiCaprio"
      ],
      [
        1,
        "Michael E. Chapman"
      ],
      [
        1,
        "Jackie Earle Haley"
      ],
      [
        1,
        "Bree Elrod"
      ],
      [
        1,
        "Gary Galone"
      ],
      [
        1,
        "Lars Gerhard"
      ],
      [
        1,
        "Gabriel Hansen"
      ],
      [
        1,
        "Ruby Jerins"
      ],
      [
        1,
        "Tom Kemp"
      ],
      [
        1,
        "Elias Koteas"
      ],
      [
        1,
        "Jill Larson"
      ],
      [
        1,
        "Ted Levine"
      ],
      [
        1,
        "Dennis Lynch"
      ],
      [
        1,
        "Joseph McKenna"
      ],
      [
        1,
        "Joseph P. Reidy"
      ],
      [
        1,
        "John Porell"
      ],
      [
        1,
        "Mark Ruffalo"
      ],
      [
        1,
        "Nellie Sciutto"
      ],
      [
        1,
        "Joseph Sikora"
      ],
      [
        1,
        "Max von Sydow"
      ],
      [
        1,
        "Bates Wilder"
      ],
      [
        1,
        "Michelle Williams"
      ],
      [
        1,
        "Steve Witting"
      ]
    ],
    "directors": "Martin Scorsese",
    "budget_string": "80m USD",
    "gross_string": "294.8m USD",
    "metascore_string": "63%"
  },
  {
    "imdb_id": "tt1375666",
    "plot": "A thief who steals corporate secrets through the use of dream-sharing technology is given the inverse task of planting an idea into the mind of a C.E.O., but his tragic past may doom the project and his team to disaster.",
    "certification": "PG-13",
    "other_wins_count": 153,
    "other_noms_count": 220,
    "amazon": "https://www.amazon.com/Inception-Blu-ray-Leonardo-DiCaprio/dp/B015S4DRK2/ref=tmm_blu_swatch_0?_encoding=UTF8&amp;qid=1654826971&amp;sr=8-3&_encoding=UTF8&tag=sianglu-20&linkCode=ur2&linkId=6292b3f926ac77329a2a415ee9e968ae&camp=1789&creative=9325",
    "justwatch": "https://justwatch.com/us/movie/inception?utm_source=share&utm_medium=web",
    "beige_index": 1,
    "beige_angle": 27.5292,
    "cast": [
      [
        5,
        "Earl Cameron"
      ],
      [
        5,
        "Carl Gilliard"
      ],
      [
        5,
        "Nicole Pulliam"
      ],
      [
        5,
        "Dileep Rao"
      ],
      [
        2,
        "Tai-Li Lee"
      ],
      [
        2,
        "Tohoru Masamune"
      ],
      [
        2,
        "Yuji Okumoto"
      ],
      [
        2,
        "Ken Watanabe"
      ],
      [
        1,
        "Peter Basham"
      ],
      [
        1,
        "Natasha Beaumont"
      ],
      [
        1,
        "Tom Berenger"
      ],
      [
        1,
        "Virgile Bramly"
      ],
      [
        1,
        "Michael Caine"
      ],
      [
        1,
        "Nicolas Clerc"
      ],
      [
        1,
        "Adam Cole"
      ],
      [
        1,
        "Marion Cotillard"
      ],
      [
        1,
        "Helena Cullinan"
      ],
      [
        1,
        "Jean-Michel Dagory"
      ],
      [
        1,
        "Coralie Dedykere"
      ],
      [
        1,
        "Leonardo DiCaprio"
      ],
      [
        1,
        "Russ Fega"
      ],
      [
        1,
        "Mark Fleischmann"
      ],
      [
        1,
        "Michael Gaston"
      ],
      [
        1,
        "Johnathan Geare"
      ],
      [
        1,
        "Taylor Geare"
      ],
      [
        1,
        "Claire Geare"
      ],
      [
        1,
        "Jack Gilroy"
      ],
      [
        1,
        "Joseph Gordon-Levitt"
      ],
      [
        1,
        "Lukas Haas"
      ],
      [
        1,
        "Tom Hardy"
      ],
      [
        1,
        "Ryan Hayward"
      ],
      [
        1,
        "Tim Kelleher"
      ],
      [
        1,
        "Silvie Laguna"
      ],
      [
        1,
        "Shelley Lang"
      ],
      [
        1,
        "Alex Lombard"
      ],
      [
        1,
        "Jill Maddrell"
      ],
      [
        1,
        "Cillian Murphy"
      ],
      [
        1,
        "Jack Murray"
      ],
      [
        1,
        "Angela Nathenson"
      ],
      [
        1,
        "Miranda Nolan"
      ],
      [
        1,
        "Magnus Nolan"
      ],
      [
        1,
        "Elliot Page"
      ],
      [
        1,
        "Andrew Pleavin"
      ],
      [
        1,
        "Pete Postlethwaite"
      ],
      [
        1,
        "Marc Raducci"
      ],
      [
        1,
        "Lisa Reynolds"
      ],
      [
        1,
        "Talulah Riley"
      ],
      [
        1,
        "Felix Scott"
      ],
      [
        1,
        "Jason Tendell"
      ],
      [
        1,
        "Kraig Thornber"
      ],
      [
        1,
        "Shannon Welles"
      ]
    ],
    "directors": "Christopher Nolan",
    "budget_string": "160m USD",
    "gross_string": "836.8m USD",
    "metascore_string": "74%"
  },
  {
    "imdb_id": "tt0435761",
    "plot": "The toys are mistakenly delivered to a day-care center instead of the attic right before Andy leaves for college, and it's up to Woody to convince the other toys that they weren't abandoned and to return home.",
    "certification": "G",
    "other_wins_count": 59,
    "other_noms_count": 96,
    "amazon": "https://www.amazon.com/Story-Four-Disc-Blu-ray-Combo-Digital/dp/B003XKPPOU/ref=tmm_blu_title_0?_encoding=UTF8&amp;qid=1654844040&amp;sr=1-1-spons&_encoding=UTF8&tag=sianglu-20&linkCode=ur2&linkId=643f21c31e2ea7cb6c4a764345bff362&camp=1789&creative=9325",
    "justwatch": "https://justwatch.com/us/movie/toy-story-3-3d?utm_source=share&utm_medium=web",
    "beige_index": 1,
    "beige_angle": 14.9994,
    "cast": [
      [
        4,
        "Whoopi Goldberg"
      ],
      [
        3,
        "Carlos Alazraqui"
      ],
      [
        3,
        "Javier Fern\u00e1ndez-Pe\u00f1a"
      ],
      [
        3,
        "Charlie Bright"
      ],
      [
        3,
        "Aram\u00e9 Scott"
      ],
      [
        2,
        "Godfrey Gao"
      ],
      [
        1,
        "Lori Alan"
      ],
      [
        1,
        "Tim Allen"
      ],
      [
        1,
        "Ned Beatty"
      ],
      [
        1,
        "Jodi Benson"
      ],
      [
        1,
        "Blake Clark"
      ],
      [
        1,
        "Joan Cusack"
      ],
      [
        1,
        "John Cygan"
      ],
      [
        1,
        "Timothy Dalton"
      ],
      [
        1,
        "Teresa Ganzel"
      ],
      [
        1,
        "Jeff Garlin"
      ],
      [
        1,
        "Tom Hanks"
      ],
      [
        1,
        "Jess Harnell"
      ],
      [
        1,
        "Estelle Harris"
      ],
      [
        1,
        "Bonnie Hunt"
      ],
      [
        1,
        "Leo Jergovic"
      ],
      [
        1,
        "Michael Keaton"
      ],
      [
        1,
        "Richard Kind"
      ],
      [
        1,
        "Amber Kroner"
      ],
      [
        1,
        "R. Lee Ermey"
      ],
      [
        1,
        "Bud Luckey"
      ],
      [
        1,
        "Brianna Maiwand"
      ],
      [
        1,
        "Danny Mann"
      ],
      [
        1,
        "Laurie Metcalf"
      ],
      [
        1,
        "Nikolas Michailidis"
      ],
      [
        1,
        "Bea Miller"
      ],
      [
        1,
        "John Morris"
      ],
      [
        1,
        "Laraine Newman"
      ],
      [
        1,
        "Teddy Newton"
      ],
      [
        1,
        "Bob Peterson"
      ],
      [
        1,
        "Jeff Pidgeon"
      ],
      [
        1,
        "Jan Rabson"
      ],
      [
        1,
        "Jerome Ranft"
      ],
      [
        1,
        "John Ratzenberger"
      ],
      [
        1,
        "Don Rickles"
      ],
      [
        1,
        "Emily Ricks Hahn"
      ],
      [
        1,
        "Kristen Schaal"
      ],
      [
        1,
        "Wallace Shawn"
      ],
      [
        1,
        "Lee Unkrich"
      ],
      [
        1,
        "Erik von Detten"
      ],
      [
        1,
        "Frank Welker"
      ],
      [
        1,
        "Colette Whitaker"
      ],
      [
        1,
        "Jack Willis"
      ]
    ],
    "directors": "Lee Unkrich",
    "budget_string": "200m USD",
    "gross_string": "1067m USD",
    "metascore_string": "92%"
  },
  {
    "imdb_id": "tt1255953",
    "plot": "Twins journey to the Middle East to discover their family history and fulfill their mother's last wishes.",
    "certification": "R",
    "other_wins_count": 40,
    "other_noms_count": 17,
    "amazon": "https://www.amazon.com/Incendies-Two-Disc-Blu-ray-DVD-Combo/dp/B0056NEK20/ref=sr_1_1?crid=CD0B1M9EJAE6&amp;keywords=incendies+blu+ray&amp;qid=1655008299&amp;sprefix=incendies+blu+ray%252Caps%252C285&amp;sr=8-1&_encoding=UTF8&tag=sianglu-20&linkCode=ur2&linkId=ea1d86d520f585554918a70b77220c66&camp=1789&creative=9325",
    "justwatch": "https://justwatch.com/us/movie/incendies?utm_source=share&utm_medium=web",
    "beige_index": 4,
    "beige_angle": 118.098,
    "cast": [
      [
        4,
        "Fadel Abdel Latif"
      ],
      [
        4,
        "Kamal Al Muhaisen"
      ],
      [
        4,
        "Sumaya Al-Attia"
      ],
      [
        4,
        "Bader Alami"
      ],
      [
        4,
        "Lara Atalla"
      ],
      [
        4,
        "Anton Atalla"
      ],
      [
        4,
        "Christine Aubin Khalifah"
      ],
      [
        4,
        "Robert Auclair"
      ],
      [
        4,
        "Baya Belal"
      ],
      [
        4,
        "Abdelghani Ben Rafalia"
      ],
      [
        4,
        "Amine Benzenine"
      ],
      [
        4,
        "Shadi Bishara Jou'aneh"
      ],
      [
        4,
        "Chaouki Charbel"
      ],
      [
        4,
        "Zalfa Chelhot"
      ],
      [
        4,
        "Sulwan Daoud"
      ],
      [
        4,
        "Marwan Dudin"
      ],
      [
        4,
        "Abdelghafour Elaaziz"
      ],
      [
        4,
        "Ali Elayan"
      ],
      [
        4,
        "Nadia Essadiqi"
      ],
      [
        4,
        "Kamal Fanni"
      ],
      [
        4,
        "Ramzi Fanni"
      ],
      [
        4,
        "Firas Fanni"
      ],
      [
        4,
        "Rand Faris"
      ],
      [
        4,
        "Axel Garbi"
      ],
      [
        4,
        "Jihad Hazem"
      ],
      [
        4,
        "Rehab Hazim"
      ],
      [
        4,
        "Raja'a Hikma"
      ],
      [
        4,
        "Hadeel Hisham"
      ],
      [
        4,
        "Sajida Hussein"
      ],
      [
        4,
        "Majida Hussein"
      ],
      [
        4,
        "Ali Hussein"
      ],
      [
        4,
        "Abdallah Hweidi"
      ],
      [
        4,
        "Sam Jamal"
      ],
      [
        4,
        "Baker Kabbani"
      ],
      [
        4,
        "Mustafa Kamel"
      ],
      [
        4,
        "Hind Kamel"
      ],
      [
        4,
        "Mher Karakashian"
      ],
      [
        4,
        "Basel Karim Hazem"
      ],
      [
        4,
        "Georges Khayat"
      ],
      [
        4,
        "Nabil Koni"
      ],
      [
        4,
        "Adel Ladikani"
      ],
      [
        4,
        "Belkacem Lahbairi"
      ],
      [
        4,
        "Rasmeyeh Leftey"
      ],
      [
        4,
        "Yada Mahmoud"
      ],
      [
        4,
        "Mohamed Majd"
      ],
      [
        4,
        "Hayef Majeed Mubarak"
      ],
      [
        4,
        "Ahmad Massad"
      ],
      [
        4,
        "Zeinab Mohammad"
      ],
      [
        4,
        "Maria Mohammedi"
      ],
      [
        4,
        "Hamed Najem"
      ],
      [
        4,
        "Nafeh Niam"
      ],
      [
        4,
        "Asriah Nijres"
      ],
      [
        4,
        "Ram Qabil"
      ],
      [
        4,
        "Raya Qaraein"
      ],
      [
        4,
        "Laila Qutub"
      ],
      [
        4,
        "Baraka Rahmani"
      ],
      [
        4,
        "Joyce Raie"
      ],
      [
        4,
        "Mohammad Rasheed Ajlouni"
      ],
      [
        4,
        "Jabar Risheq"
      ],
      [
        4,
        "Camille Rizkallah"
      ],
      [
        4,
        "Mohammad Sami"
      ],
      [
        4,
        "Hussein Sami"
      ],
      [
        4,
        "Nabil Sawalha"
      ],
      [
        4,
        "Jackie Sawiris"
      ],
      [
        4,
        "Widad Shafago"
      ],
      [
        4,
        "Karim Shiyab"
      ],
      [
        4,
        "Yousef Shweihat"
      ],
      [
        4,
        "Yousef Soufan"
      ],
      [
        4,
        "Ahmad Srour"
      ],
      [
        4,
        "Aladeen Tawfeek"
      ],
      [
        4,
        "Noura Waleed"
      ],
      [
        4,
        "Michael Zananiri"
      ],
      [
        2,
        "Lubna Azabal"
      ],
      [
        1,
        "Allen Altman"
      ],
      [
        1,
        "Karim Babin"
      ],
      [
        1,
        "Dominique Briand"
      ],
      [
        1,
        "M\u00e9lissa D\u00e9sormeaux-Poulin"
      ],
      [
        1,
        "John Dunn-Hill"
      ],
      [
        1,
        "Maxim Gaudette"
      ],
      [
        1,
        "R\u00e9my Girard"
      ],
      [
        1,
        "Fr\u00e9d\u00e9ric Paquet"
      ],
      [
        1,
        "Celine Soulier"
      ]
    ],
    "directors": "Denis Villeneuve",
    "budget_string": "6.8m USD",
    "gross_string": "6.8m USD",
    "metascore_string": "80%"
  },
  {
    "imdb_id": "tt0892769",
    "plot": "A hapless young Viking who aspires to hunt dragons becomes the unlikely friend of a young dragon himself, and learns there may be more to the creatures than he assumed.",
    "certification": "PG",
    "other_wins_count": 25,
    "other_noms_count": 61,
    "amazon": "https://www.amazon.com/Dragons-Deluxe-Double-Feature-Blu-ray/dp/B0145JSEXG/ref=sr_1_8?crid=3I6VT5NH5EJP8&amp;keywords=how+to+train+your+dragon+blu+ray&amp;qid=1655183811&amp;sprefix=how+to+train+your+dragon+blu+ray%252Caps%252C292&amp;sr=8-8&_encoding=UTF8&tag=sianglu-20&linkCode=ur2&linkId=d64e7eec7459a7f4cdd26416dc2a3e31&camp=1789&creative=9325",
    "justwatch": "https://justwatch.com/us/movie/how-to-train-your-dragon?utm_source=share&utm_medium=web",
    "beige_index": 1,
    "beige_angle": 11.0772,
    "cast": [
      [
        3,
        "America Ferrera"
      ],
      [
        1,
        "Robin Atkin Downes"
      ],
      [
        1,
        "Jay Baruchel"
      ],
      [
        1,
        "Gerard Butler"
      ],
      [
        1,
        "Kieron Elliott"
      ],
      [
        1,
        "Craig Ferguson"
      ],
      [
        1,
        "Jonah Hill"
      ],
      [
        1,
        "Ashley Jensen"
      ],
      [
        1,
        "Philip McGrade"
      ],
      [
        1,
        "T.J. Miller"
      ],
      [
        1,
        "Christopher Mintz-Plasse"
      ],
      [
        1,
        "David Tennant"
      ],
      [
        1,
        "Kristen Wiig"
      ]
    ],
    "directors": "Dean DeBlois & Chris Sanders",
    "budget_string": "165m USD",
    "gross_string": "494.9m USD",
    "metascore_string": "75%"
  },
  {
    "imdb_id": "tt1291584",
    "plot": "The youngest son of an alcoholic former boxer returns home, where he's trained by his father for competition in a mixed martial arts tournament - a path that puts the fighter on a collision course with his estranged, older brother.",
    "certification": "PG-13",
    "other_wins_count": 5,
    "other_noms_count": 21,
    "amazon": "https://www.amazon.com/Warrior-Combo-DVD-Blu-ray/dp/B005WKH38K/ref=tmm_blu_title_0?_encoding=UTF8&amp;qid=1655181768&amp;sr=8-1&_encoding=UTF8&tag=sianglu-20&linkCode=ur2&linkId=311f4187bd99ad80545b4ef7c9da0eb2&camp=1789&creative=9325",
    "justwatch": "https://justwatch.com/us/movie/warrior-2011?utm_source=share&utm_medium=web",
    "beige_index": 2,
    "beige_angle": 49.95,
    "cast": [
      [
        5,
        "Etta Cox"
      ],
      [
        5,
        "Yves Edwards"
      ],
      [
        5,
        "Rashad Evans"
      ],
      [
        5,
        "Anthony Johnson"
      ],
      [
        5,
        "Denzel Whitaker"
      ],
      [
        5,
        "Armon York Williams"
      ],
      [
        4,
        "Laura Chinn"
      ],
      [
        4,
        "Tammy Townsend"
      ],
      [
        3,
        "Panuvat Anthony Nanakornpanom"
      ],
      [
        3,
        "Roan Carneiro"
      ],
      [
        3,
        "Maximiliano Hern\u00e1ndez"
      ],
      [
        3,
        "Vanessa Martinez"
      ],
      [
        3,
        "Carlos Miranda"
      ],
      [
        3,
        "Jaime Sinue Aguirre"
      ],
      [
        3,
        "Jack Fisher"
      ],
      [
        3,
        "Tim Katz"
      ],
      [
        3,
        "Hans Marrero"
      ],
      [
        3,
        "Andre' Mason"
      ],
      [
        3,
        "Tom McCue"
      ],
      [
        3,
        "Anthony Tambakis"
      ],
      [
        2,
        "Fernando Chien"
      ],
      [
        1,
        "Kurt Angle"
      ],
      [
        1,
        "Erik Apple"
      ],
      [
        1,
        "Stephan Bonnar"
      ],
      [
        1,
        "Dan Caldwell"
      ],
      [
        1,
        "Bryan Callen"
      ],
      [
        1,
        "Lexi Cowan"
      ],
      [
        1,
        "Jimmy Cvetic"
      ],
      [
        1,
        "Michelle Dawn Mooney"
      ],
      [
        1,
        "Jake Digman"
      ],
      [
        1,
        "James Dreussi"
      ],
      [
        1,
        "Kevin Dunn"
      ],
      [
        1,
        "Joel Edgerton"
      ],
      [
        1,
        "Noah Emmerich"
      ],
      [
        1,
        "Richard Fike"
      ],
      [
        1,
        "Frank Grillo"
      ],
      [
        1,
        "Tom Hardy"
      ],
      [
        1,
        "Jeff Hochendoner"
      ],
      [
        1,
        "James Houk"
      ],
      [
        1,
        "Jace Jeanes"
      ],
      [
        1,
        "Aaron Kleiber"
      ],
      [
        1,
        "Nick Lehane"
      ],
      [
        1,
        "Nathan Marquardt"
      ],
      [
        1,
        "Jonathan Matthew Anik"
      ],
      [
        1,
        "Jake McLaughlin"
      ],
      [
        1,
        "Jennifer Morrison"
      ],
      [
        1,
        "Nick Nolte"
      ],
      [
        1,
        "Sandy Notaro"
      ],
      [
        1,
        "Francesca Ortenzio"
      ],
      [
        1,
        "Amir Perets"
      ],
      [
        1,
        "Lambert R. Strayer"
      ],
      [
        1,
        "Josh Rosenthal"
      ],
      [
        1,
        "Raymond Rowe"
      ],
      [
        1,
        "Sam Sheridan"
      ],
      [
        1,
        "Adam Stanley"
      ],
      [
        1,
        "Daniel Stevens"
      ],
      [
        1,
        "Julia Stockstad"
      ],
      [
        1,
        "Capri Thomas"
      ],
      [
        1,
        "Roman Vasylyshyn"
      ],
      [
        1,
        "Kelly Vinn"
      ]
    ],
    "directors": "Gavin O'Connor",
    "budget_string": "25m USD",
    "gross_string": "23.3m USD",
    "metascore_string": "71%"
  },
  {
    "imdb_id": "tt1832382",
    "plot": "A married couple are faced with a difficult decision - to improve the life of their child by moving to another country or to stay in Iran and look after a deteriorating parent who has Alzheimer's disease.",
    "certification": "PG-13",
    "other_wins_count": 88,
    "other_noms_count": 50,
    "amazon": "https://www.amazon.com/Separation-Blu-ray-Peyman-Moaadi/dp/B005LAIIIK/ref=sr_1_1?crid=1HJBWBMNDSVAB&amp;keywords=a+separation+blu+ray&amp;qid=1655008199&amp;sprefix=a+separation+blu+ray%252Caps%252C289&amp;sr=8-1&_encoding=UTF8&tag=sianglu-20&linkCode=ur2&linkId=a0933e80b4c765a710db5d6effa1d03d&camp=1789&creative=9325",
    "justwatch": "https://justwatch.com/us/movie/a-separation?utm_source=share&utm_medium=web",
    "beige_index": 4,
    "beige_angle": 132.0,
    "cast": [
      [
        4,
        "Mohammadhasan Asghari"
      ],
      [
        4,
        "Shirin Azimiyannezhad"
      ],
      [
        4,
        "Sareh Bayat"
      ],
      [
        4,
        "Hamid Dadju"
      ],
      [
        4,
        "Mohammad Ebrahimian"
      ],
      [
        4,
        "Sarina Farhadi"
      ],
      [
        4,
        "Samad Farhang"
      ],
      [
        4,
        "Ali Fattahi"
      ],
      [
        4,
        "Nafise Ghodrati"
      ],
      [
        4,
        "Seyyd Hamid Mirshams"
      ],
      [
        4,
        "Leila Hatami"
      ],
      [
        4,
        "Roya Hosseini"
      ],
      [
        4,
        "Shahab Hosseini"
      ],
      [
        4,
        "Kimia Hosseini"
      ],
      [
        4,
        "Seyyed Jamshid Hosseini"
      ],
      [
        4,
        "Hamid Janane"
      ],
      [
        4,
        "Babak Karimi"
      ],
      [
        4,
        "Sahar Kave"
      ],
      [
        4,
        "Payman Maadi"
      ],
      [
        4,
        "Manuchehr Mohammadzade"
      ],
      [
        4,
        "Mazdak Mohaymeni"
      ],
      [
        4,
        "Majid Nameni"
      ],
      [
        4,
        "Ali Nazari"
      ],
      [
        4,
        "Farhad Nosrati"
      ],
      [
        4,
        "Mahmoud Rafi'i"
      ],
      [
        4,
        "Bahareh Riahi"
      ],
      [
        4,
        "Peyman Sadeghi"
      ],
      [
        4,
        "Mohammad Saffari"
      ],
      [
        4,
        "Nosratollah Seyfizade"
      ],
      [
        4,
        "Ali-Asghar Shahbazi"
      ],
      [
        4,
        "Bahare Shahbazi"
      ],
      [
        4,
        "Khodarahm Soleymannezhad"
      ],
      [
        4,
        "Maria Tehranchi"
      ],
      [
        4,
        "Shirin Yazdanbakhsh"
      ],
      [
        4,
        "Merila Zare'i"
      ],
      [
        4,
        "Armine Zeytounchian"
      ],
      [
        4,
        "Sohibanoo Zolqadr"
      ]
    ],
    "directors": "Asghar Farhadi",
    "budget_string": "0.5m USD",
    "gross_string": "22.9m USD",
    "metascore_string": "95%"
  },
  {
    "imdb_id": "tt1454029",
    "plot": "An aspiring author during the civil rights movement of the 1960s decides to write a book detailing the African American maids' point of view on the white families for which they work, and the hardships they go through on a daily basis.",
    "certification": "PG-13",
    "other_wins_count": 78,
    "other_noms_count": 121,
    "amazon": "https://www.amazon.com/Help-Two-Disc-Blu-ray-DVD-Combo/dp/B005J6LKVI/ref=sr_1_1?crid=EH8U6TNI78OZ&amp;keywords=the+help+blu+ray&amp;qid=1655194557&amp;s=movies-tv&amp;sprefix=the+help+blu+ray%252Cmovies-tv%252C351&amp;sr=1-1&_encoding=UTF8&tag=sianglu-20&linkCode=ur2&linkId=26075913f9b7b24b1fbfb1072576fb37&camp=1789&creative=9325",
    "justwatch": "https://justwatch.com/us/movie/the-help?utm_source=share&utm_medium=web",
    "beige_index": 3,
    "beige_angle": 74.0376,
    "cast": [
      [
        5,
        "LaChanze"
      ],
      [
        5,
        "Florence 'Flo' Roach"
      ],
      [
        5,
        "Millicent Bolton"
      ],
      [
        5,
        "Henry Carpenter"
      ],
      [
        5,
        "Wade Cottonfield"
      ],
      [
        5,
        "Viola Davis"
      ],
      [
        5,
        "Nelsan Ellis"
      ],
      [
        5,
        "Aunjanue Ellis"
      ],
      [
        5,
        "Ashley Johnson"
      ],
      [
        5,
        "Carol Lee"
      ],
      [
        5,
        "David Oyelowo"
      ],
      [
        5,
        "Tarra Riggs"
      ],
      [
        5,
        "Roslyn Ruff"
      ],
      [
        5,
        "Kelsey Scot"
      ],
      [
        5,
        "Octavia Spencer"
      ],
      [
        5,
        "Carol Sutton"
      ],
      [
        5,
        "Cicely Tyson"
      ],
      [
        5,
        "Sheerene Whitfield"
      ],
      [
        1,
        "Coyt Bailey"
      ],
      [
        1,
        "Amy Beckwith"
      ],
      [
        1,
        "Don Brock"
      ],
      [
        1,
        "Tiffany Brouwer"
      ],
      [
        1,
        "Anna Camp"
      ],
      [
        1,
        "Jessica Chastain"
      ],
      [
        1,
        "Wes Chatham"
      ],
      [
        1,
        "Charles Cooper"
      ],
      [
        1,
        "Diana Cooper"
      ],
      [
        1,
        "Bryce Dallas Howard"
      ],
      [
        1,
        "Cleta Elaine Ellington"
      ],
      [
        1,
        "Sloane Fair"
      ],
      [
        1,
        "Becky Fly"
      ],
      [
        1,
        "Emma Henry"
      ],
      [
        1,
        "Eleanor Henry"
      ],
      [
        1,
        "Dana Ivey"
      ],
      [
        1,
        "Allison Janney"
      ],
      [
        1,
        "Anna Jennings"
      ],
      [
        1,
        "Leslie Jordan"
      ],
      [
        1,
        "Brian Kerwin"
      ],
      [
        1,
        "Christopher Lowell"
      ],
      [
        1,
        "Shane McRae"
      ],
      [
        1,
        "Lauren Miller"
      ],
      [
        1,
        "Ritchie Montgomery"
      ],
      [
        1,
        "Ahna O'Reilly"
      ],
      [
        1,
        "Elizabeth Smith"
      ],
      [
        1,
        "Sissy Spacek"
      ],
      [
        1,
        "Mary Steenburgen"
      ],
      [
        1,
        "Emma Stone"
      ],
      [
        1,
        "John Taylor"
      ],
      [
        1,
        "Mary Taylor Killebrew"
      ],
      [
        1,
        "Kathryn Ursy"
      ],
      [
        1,
        "Mike Vogel"
      ],
      [
        1,
        "Stephanie Ward"
      ],
      [
        1,
        "Ted Welch"
      ]
    ],
    "directors": "Tate Taylor",
    "budget_string": "25m USD",
    "gross_string": "216.6m USD",
    "metascore_string": "62%"
  },
  {
    "imdb_id": "tt1201607",
    "plot": "Harry, Ron, and Hermione search for Voldemort's remaining Horcruxes in their effort to destroy the Dark Lord as the final battle rages on at Hogwarts.",
    "certification": "PG-13",
    "other_wins_count": 46,
    "other_noms_count": 91,
    "amazon": "https://www.amazon.com/Harry-Potter-Deathly-Hallows-Blu-ray/dp/B001UV4XJ2/ref=sr_1_1?crid=HWN15T5LXVR6&amp;keywords=deathly+hallows+part+2+blu+ray&amp;qid=1655183048&amp;s=movies-tv&amp;sprefix=deathly+hallows+part+2+blu+ray%252Cmovies-tv%252C282&amp;sr=1-1&_encoding=UTF8&tag=sianglu-20&linkCode=ur2&linkId=8a1a9bd7b3931a9a8c94d1efd967480e&camp=1789&creative=9325",
    "justwatch": "https://justwatch.com/us/movie/harry-potter-and-the-deathly-hallows-part-2?utm_source=share&utm_medium=web",
    "beige_index": 1,
    "beige_angle": 12.96,
    "cast": [
      [
        5,
        "Louis Cordice"
      ],
      [
        5,
        "Alfred Enoch"
      ],
      [
        5,
        "George Harris"
      ],
      [
        5,
        "Afshan Azad"
      ],
      [
        2,
        "Joshua Jo"
      ],
      [
        2,
        "Katie Leung"
      ],
      [
        1,
        "Tony Adkins"
      ],
      [
        1,
        "Anthony Allgood"
      ],
      [
        1,
        "Helena Barlow"
      ],
      [
        1,
        "Hebe Beardsall"
      ],
      [
        1,
        "Sarah Bennett"
      ],
      [
        1,
        "Alan Bennett"
      ],
      [
        1,
        "Helena Bonham Carter"
      ],
      [
        1,
        "Arthur Bowen"
      ],
      [
        1,
        "David Bradley"
      ],
      [
        1,
        "Jim Broadbent"
      ],
      [
        1,
        "Peter Burroughs"
      ],
      [
        1,
        "Jon Campling"
      ],
      [
        1,
        "Jessie Cave"
      ],
      [
        1,
        "Benedict Clarke"
      ],
      [
        1,
        "Robbie Coltrane"
      ],
      [
        1,
        "Ellie Darcey-Alden"
      ],
      [
        1,
        "Warwick Davis"
      ],
      [
        1,
        "Daphne de Beistegui"
      ],
      [
        1,
        "Graham Duff"
      ],
      [
        1,
        "Will Dunn"
      ],
      [
        1,
        "Mike Edmonds"
      ],
      [
        1,
        "Ruby Evans"
      ],
      [
        1,
        "Amber Evans"
      ],
      [
        1,
        "Tom Felton"
      ],
      [
        1,
        "Ralph Fiennes"
      ],
      [
        1,
        "Peter G. Reed"
      ],
      [
        1,
        "Michael Gambon"
      ],
      [
        1,
        "Bertie Gilbert"
      ],
      [
        1,
        "Domhnall Gleeson"
      ],
      [
        1,
        "Rusty Goffe"
      ],
      [
        1,
        "Jade Gordon"
      ],
      [
        1,
        "Rohan Gotobed"
      ],
      [
        1,
        "Sian Grace Phillips"
      ],
      [
        1,
        "Rupert Grint"
      ],
      [
        1,
        "Scarlett Hefner"
      ],
      [
        1,
        "Michael Henbury Ballan"
      ],
      [
        1,
        "Guy Henry"
      ],
      [
        1,
        "Andrew Herd"
      ],
      [
        1,
        "Josh Herdman"
      ],
      [
        1,
        "Ciar\u00e1n Hinds"
      ],
      [
        1,
        "Emil Hostina"
      ],
      [
        1,
        "John Hurt"
      ],
      [
        1,
        "Ralph Ineson"
      ],
      [
        1,
        "Jason Isaacs"
      ],
      [
        1,
        "Gemma Jones"
      ],
      [
        1,
        "Jon Key"
      ],
      [
        1,
        "Tony Kirwood"
      ],
      [
        1,
        "Isabella Laughland"
      ],
      [
        1,
        "Dave Legeno"
      ],
      [
        1,
        "Georgina Leonidas"
      ],
      [
        1,
        "Matthew Lewis"
      ],
      [
        1,
        "Evanna Lynch"
      ],
      [
        1,
        "Kelly Macdonald"
      ],
      [
        1,
        "Miriam Margolyes"
      ],
      [
        1,
        "Helen McCrory"
      ],
      [
        1,
        "Penelope McGhie"
      ],
      [
        1,
        "Ashley McGuire"
      ],
      [
        1,
        "Alfie McIlwain"
      ],
      [
        1,
        "William Melling"
      ],
      [
        1,
        "Nick Moran"
      ],
      [
        1,
        "Devon Murray"
      ],
      [
        1,
        "Benn Northover"
      ],
      [
        1,
        "Gary Oldman"
      ],
      [
        1,
        "Toby Papworth"
      ],
      [
        1,
        "Ariella Paradise"
      ],
      [
        1,
        "Ian Peck"
      ],
      [
        1,
        "Oliver Phelps"
      ],
      [
        1,
        "James Phelps"
      ],
      [
        1,
        "Leslie Phillips"
      ],
      [
        1,
        "Cl\u00e9mence Po\u00e9sy"
      ],
      [
        1,
        "Katie Purvis"
      ],
      [
        1,
        "Daniel Radcliffe"
      ],
      [
        1,
        "Chris Rankin"
      ],
      [
        1,
        "Adrian Rawlins"
      ],
      [
        1,
        "Alan Rickman"
      ],
      [
        1,
        "Granville Saxton"
      ],
      [
        1,
        "Anna Shaffer"
      ],
      [
        1,
        "Judith Sharp"
      ],
      [
        1,
        "Maggie Smith"
      ],
      [
        1,
        "Geraldine Somerville"
      ],
      [
        1,
        "Timothy Spall"
      ],
      [
        1,
        "Freddie Stroma"
      ],
      [
        1,
        "Natalia Tena"
      ],
      [
        1,
        "David Thewlis"
      ],
      [
        1,
        "Emma Thompson"
      ],
      [
        1,
        "Suzanne Toase"
      ],
      [
        1,
        "Ryan Turner"
      ],
      [
        1,
        "Julie Walters"
      ],
      [
        1,
        "Emma Watson"
      ],
      [
        1,
        "Brian Wheeler"
      ],
      [
        1,
        "Spencer Wilding"
      ],
      [
        1,
        "Mark Williams"
      ],
      [
        1,
        "Phil Wright"
      ],
      [
        1,
        "Bonnie Wright"
      ]
    ],
    "directors": "David Yates",
    "budget_string": "125m USD",
    "gross_string": "1342.4m USD",
    "metascore_string": "85%"
  },
  {
    "imdb_id": "tt1675434",
    "plot": "After he becomes a quadriplegic from a paragliding accident, an aristocrat hires a young man from the projects to be his caregiver.",
    "certification": "R",
    "other_wins_count": 38,
    "other_noms_count": 39,
    "amazon": "https://www.amazon.com/Intouchables-Blu-ray-Anne-Ny/dp/B0087IT8LO/ref=sr_1_5?crid=3GI8UFQO6A58V&amp;keywords=the+intouchables&amp;qid=1654832090&amp;s=movies-tv&amp;sprefix=the+intouchables%252Cmovies-tv-intl-ship%252C836&amp;sr=1-5&_encoding=UTF8&tag=sianglu-20&linkCode=ur2&linkId=cab93f02bf7307f06d9a23020f186489&camp=1789&creative=9325",
    "justwatch": "https://justwatch.com/us/movie/the-intouchables?utm_source=share&utm_medium=web",
    "beige_index": 2,
    "beige_angle": 38.6055,
    "cast": [
      [
        5,
        "Absa Diatou Toure"
      ],
      [
        5,
        "Salimata Kamate"
      ],
      [
        5,
        "Cyril Mendy"
      ],
      [
        5,
        "Omar Sy"
      ],
      [
        3,
        "Hedi Bouchenafa"
      ],
      [
        3,
        "K\u00e9vin Wamo"
      ],
      [
        2,
        "Yun-Ping He"
      ],
      [
        1,
        "Christian Ameri"
      ],
      [
        1,
        "Alain Anthony"
      ],
      [
        1,
        "Benjamin Baroche"
      ],
      [
        1,
        "Renaud Barse"
      ],
      [
        1,
        "Caroline Bourg"
      ],
      [
        1,
        "Doroth\u00e9e Bri\u00e8re"
      ],
      [
        1,
        "Fran\u00e7ois Bureloup"
      ],
      [
        1,
        "\u00c9milie Caen"
      ],
      [
        1,
        "Le Capriccio Fran\u00e7ais"
      ],
      [
        1,
        "Fran\u00e7ois Caron"
      ],
      [
        1,
        "Jean-Fran\u00e7ois Cayrey"
      ],
      [
        1,
        "Fran\u00e7ois Cluzet"
      ],
      [
        1,
        "Dominique Daguier"
      ],
      [
        1,
        "Jos\u00e9phine de Meaux"
      ],
      [
        1,
        "Marie-Laure Descoureaux"
      ],
      [
        1,
        "Ian Fenelon"
      ],
      [
        1,
        "Audrey Fleurot"
      ],
      [
        1,
        "Alba Ga\u00efa Bellugi"
      ],
      [
        1,
        "Dominique Henry"
      ],
      [
        1,
        "Elliot Latil"
      ],
      [
        1,
        "Antoine Laurent"
      ],
      [
        1,
        "Sylvain Lazard"
      ],
      [
        1,
        "Philippe Le Fevre"
      ],
      [
        1,
        "Anne Le Ny"
      ],
      [
        1,
        "Fabrice Mantegna"
      ],
      [
        1,
        "Nicky Marbot"
      ],
      [
        1,
        "Clotilde Mollet"
      ],
      [
        1,
        "Gr\u00e9goire Oestermann"
      ],
      [
        1,
        "J\u00e9r\u00f4me Pauwels"
      ],
      [
        1,
        "Thomas Soliv\u00e9r\u00e8s"
      ],
      [
        1,
        "Michel Winogradoff"
      ]
    ],
    "directors": "Olivier Nakache & \u00c9ric Toledano",
    "budget_string": "13.2m USD",
    "gross_string": "426.6m USD",
    "metascore_string": "57%"
  },
  {
    "imdb_id": "tt2106476",
    "plot": "A teacher lives a lonely life, all the while struggling over his son's custody. His life slowly gets better as he finds love and receives good news from his son, but his new luck is about to be brutally shattered by an innocent little lie.",
    "certification": "R",
    "other_wins_count": 38,
    "other_noms_count": 72,
    "amazon": "https://www.amazon.com/Hunt-Blu-ray-Mads-Mikkelsen/dp/B00F6SHCYS/ref=sr_1_4?crid=OCTZMDCPCS5X&amp;keywords=the+hunt+2012&amp;qid=1654844942&amp;s=movies-tv&amp;sprefix=the+hunt+2012%252Cmovies-tv%252C506&amp;sr=1-4&_encoding=UTF8&tag=sianglu-20&linkCode=ur2&linkId=1aa9a283ddfeef35ca182081fb327333&camp=1789&creative=9325",
    "justwatch": "https://justwatch.com/us/movie/the-hunt-2012?utm_source=share&utm_medium=web",
    "beige_index": 1,
    "beige_angle": 0.0,
    "cast": [
      [
        1,
        "Rosa"
      ],
      [
        1,
        "Marie Aktor"
      ],
      [
        1,
        "Rikke Bergmann"
      ],
      [
        1,
        "Thomas Bo Larsen"
      ],
      [
        1,
        "Katrine Brygmann Salomon"
      ],
      [
        1,
        "Sebastian Bull Sarning"
      ],
      [
        1,
        "Mona C. Soliman"
      ],
      [
        1,
        "Nina Christrup"
      ],
      [
        1,
        "Nicolai Dahl Hamilton"
      ],
      [
        1,
        "Daniel Engstrup"
      ],
      [
        1,
        "Lasse Fogelstr\u00f8m"
      ],
      [
        1,
        "Karina Fogh Holmkj\u00e6r"
      ],
      [
        1,
        "Josefine Gr\u00e5b\u00f8l"
      ],
      [
        1,
        "Heidi Gross"
      ],
      [
        1,
        "\u00d8yvind Hagen-Traberg"
      ],
      [
        1,
        "Bjarne Henriksen"
      ],
      [
        1,
        "Jacob H\u00f8jlev J\u00f8rgensen"
      ],
      [
        1,
        "Jytte Kvinesdal"
      ],
      [
        1,
        "Rasmus Lind Rubin"
      ],
      [
        1,
        "Anne Louise Hassing"
      ],
      [
        1,
        "Mads Mikkelsen"
      ],
      [
        1,
        "Steen Ordell Guldbrand Jensen"
      ],
      [
        1,
        "Birgit Petersen"
      ],
      [
        1,
        "Lars Ranthe"
      ],
      [
        1,
        "Alexandra Rapaport"
      ],
      [
        1,
        "Thomas Ravn"
      ],
      [
        1,
        "Mie Ravn Nielsen"
      ],
      [
        1,
        "S\u00f8ren R\u00f8nholt"
      ],
      [
        1,
        "Frank Rub\u00e6k"
      ],
      [
        1,
        "Hana Shuan"
      ],
      [
        1,
        "Troels Thorsen"
      ],
      [
        1,
        "Annika Wedderkopp"
      ],
      [
        1,
        "Kim Westi"
      ],
      [
        1,
        "Allan Wibor Christensen"
      ],
      [
        1,
        "Susse Wold"
      ]
    ],
    "directors": "Thomas Vinterberg",
    "budget_string": "3.8m USD",
    "gross_string": "15.8m USD",
    "metascore_string": "77%"
  },
  {
    "imdb_id": "tt1853728",
    "plot": "With the help of a German bounty-hunter, a freed slave sets out to rescue his wife from a brutal plantation-owner in Mississippi.",
    "certification": "R",
    "other_wins_count": 56,
    "other_noms_count": 158,
    "amazon": "https://www.amazon.com/Django-Unchained-Blu-ray-Leonardo-DiCaprio/dp/B00OJ0WVCW/ref=sr_1_2?crid=3CYKF3H0URH9L&amp;keywords=django+unchained+blu-ray&amp;qid=1654841445&amp;sprefix=django+unchained+blu-%252Caps%252C523&amp;sr=8-2&_encoding=UTF8&tag=sianglu-20&linkCode=ur2&linkId=a36608eeed1a9c4d6713c4e4dcfe6eea&camp=1789&creative=9325",
    "justwatch": "https://justwatch.com/us/movie/django-unchained?utm_source=share&utm_medium=web",
    "beige_index": 2,
    "beige_angle": 70.317,
    "cast": [
      [
        5,
        "Kinetic"
      ],
      [
        5,
        "Chuuch"
      ],
      [
        5,
        "Edrick Browne"
      ],
      [
        5,
        "Jarrod Bunch"
      ],
      [
        5,
        "Amari Cheatom"
      ],
      [
        5,
        "Clay Donahue Fontenot"
      ],
      [
        5,
        "Jamal Duff"
      ],
      [
        5,
        "Ato Essandoh"
      ],
      [
        5,
        "Jon Eyez"
      ],
      [
        5,
        "Miriam F. Glover"
      ],
      [
        5,
        "Jamie Foxx"
      ],
      [
        5,
        "Dudley Fuqua"
      ],
      [
        5,
        "Nichole Galicia"
      ],
      [
        5,
        "Dana Gourrier"
      ],
      [
        5,
        "Marcus Henderson"
      ],
      [
        5,
        "Jacky Ido"
      ],
      [
        5,
        "Omar J. Dorsey"
      ],
      [
        5,
        "Keith Jefferson"
      ],
      [
        5,
        "Samuel L. Jackson"
      ],
      [
        5,
        "Escalante Lundy"
      ],
      [
        5,
        "Evan Parke"
      ],
      [
        5,
        "Sharon Pierre-Louis"
      ],
      [
        5,
        "Sammi Rotibi"
      ],
      [
        5,
        "Carl Singleton"
      ],
      [
        5,
        "Victoria Thomas"
      ],
      [
        5,
        "Dani\u00e8le Watts"
      ],
      [
        4,
        "Kerry Washington"
      ],
      [
        1,
        "Todd Allen"
      ],
      [
        1,
        "Michael Bacall"
      ],
      [
        1,
        "Zo\u00eb Bell"
      ],
      [
        1,
        "Ned Bellamy"
      ],
      [
        1,
        "Christopher Berry"
      ],
      [
        1,
        "Michael Bowen"
      ],
      [
        1,
        "Brian Brown"
      ],
      [
        1,
        "Robert Carradine"
      ],
      [
        1,
        "Laura Cayouette"
      ],
      [
        1,
        "Dennis Christopher"
      ],
      [
        1,
        "Dave Coennen"
      ],
      [
        1,
        "Kim Collins"
      ],
      [
        1,
        "Nicholas Dashnaw"
      ],
      [
        1,
        "Bruce Dern"
      ],
      [
        1,
        "Leonardo DiCaprio"
      ],
      [
        1,
        "Doc Duhame"
      ],
      [
        1,
        "J.D. Evermore"
      ],
      [
        1,
        "M.C. Gainey"
      ],
      [
        1,
        "Jake Garber"
      ],
      [
        1,
        "Walton Goggins"
      ],
      [
        1,
        "Shannon Hazlett"
      ],
      [
        1,
        "Ronan Hice"
      ],
      [
        1,
        "Jonah Hill"
      ],
      [
        1,
        "Lee Horsley"
      ],
      [
        1,
        "Cooper Huckabee"
      ],
      [
        1,
        "John Jarratt"
      ],
      [
        1,
        "Don Johnson"
      ],
      [
        1,
        "Rex Linn"
      ],
      [
        1,
        "Jack Lucarelli"
      ],
      [
        1,
        "John McConnell"
      ],
      [
        1,
        "Keniaryn Mitchell"
      ],
      [
        1,
        "Ritchie Montgomery"
      ],
      [
        1,
        "Ted Neeley"
      ],
      [
        1,
        "Franco Nero"
      ],
      [
        1,
        "Michael Parks"
      ],
      [
        1,
        "James Parks"
      ],
      [
        1,
        "Tom Proctor"
      ],
      [
        1,
        "James Remar"
      ],
      [
        1,
        "Dane Rhodes"
      ],
      [
        1,
        "Kim Robillard"
      ],
      [
        1,
        "James Russo"
      ],
      [
        1,
        "Tom Savini"
      ],
      [
        1,
        "Kerry Sims"
      ],
      [
        1,
        "Lewis Smith"
      ],
      [
        1,
        "Craig Stark"
      ],
      [
        1,
        "David Steen"
      ],
      [
        1,
        "Shana Stein"
      ],
      [
        1,
        "Louise Stratten"
      ],
      [
        1,
        "Don Stroud"
      ],
      [
        1,
        "Amber Tamblyn"
      ],
      [
        1,
        "Russ Tamblyn"
      ],
      [
        1,
        "Quentin Tarantino"
      ],
      [
        1,
        "Ashley Toman"
      ],
      [
        1,
        "Christoph Waltz"
      ],
      [
        1,
        "Tom Wopat"
      ]
    ],
    "directors": "Quentin Tarantino",
    "budget_string": "100m USD",
    "gross_string": "426.1m USD",
    "metascore_string": "81%"
  },
  {
    "imdb_id": "tt1345836",
    "plot": "Eight years after the Joker's reign of anarchy, Batman, with the help of the enigmatic Catwoman, is forced from his exile to save Gotham City from the brutal guerrilla terrorist Bane.",
    "certification": "PG-13",
    "other_wins_count": 39,
    "other_noms_count": 102,
    "amazon": "https://www.amazon.com/Dark-Knight-Rises-Blu-ray/dp/B004LWZWGK/ref=sr_1_5?crid=1HTJR3M53M5DW&amp;keywords=the+dark+knight+rises&amp;qid=1654842685&amp;s=movies-tv&amp;sprefix=the+dark+knight+ris%252Cmovies-tv%252C593&amp;sr=1-5&_encoding=UTF8&tag=sianglu-20&linkCode=ur2&linkId=572aa721752e84143f521df3e61a1fcc&camp=1789&creative=9325",
    "justwatch": "https://justwatch.com/us/movie/the-dark-knight-rises?utm_source=share&utm_medium=web",
    "beige_index": 2,
    "beige_angle": 40.8825,
    "cast": [
      [
        5,
        "LeJon"
      ],
      [
        5,
        "Rob Brown"
      ],
      [
        5,
        "Phillip Browne"
      ],
      [
        5,
        "Joshua Elijah Reese"
      ],
      [
        5,
        "Morgan Freeman"
      ],
      [
        5,
        "David Gyasi"
      ],
      [
        5,
        "Duane Henry"
      ],
      [
        5,
        "Antwan Lewis"
      ],
      [
        5,
        "John Macmillan"
      ],
      [
        5,
        "Robert Wisdom"
      ],
      [
        5,
        "Christopher Judge"
      ],
      [
        4,
        "Daniel Sunjata"
      ],
      [
        3,
        "Hector Atreyu Ruiz"
      ],
      [
        3,
        "Nestor Carbonell"
      ],
      [
        3,
        "Tyler Dean Flores"
      ],
      [
        3,
        "Noel Gugliemi"
      ],
      [
        3,
        "Reggie Lee"
      ],
      [
        3,
        "Gonzalo Menendez"
      ],
      [
        3,
        "Andres Perez-Molina"
      ],
      [
        3,
        "India Wadsworth"
      ],
      [
        2,
        "Julie Mun"
      ],
      [
        1,
        "Aldous Davidson"
      ],
      [
        1,
        "Alon Aboutboul"
      ],
      [
        1,
        "Tomas Arana"
      ],
      [
        1,
        "Jillian Armenante"
      ],
      [
        1,
        "Christian Bale"
      ],
      [
        1,
        "Mychael Bates"
      ],
      [
        1,
        "Tommy Bayiokos"
      ],
      [
        1,
        "Jay Benedict"
      ],
      [
        1,
        "Aldo Bigante"
      ],
      [
        1,
        "Ian Bohen"
      ],
      [
        1,
        "Brent Briscoe"
      ],
      [
        1,
        "Warren Brown"
      ],
      [
        1,
        "Michael Caine"
      ],
      [
        1,
        "Jake Canuso"
      ],
      [
        1,
        "Harry Coles"
      ],
      [
        1,
        "Tom Conti"
      ],
      [
        1,
        "Ben Cornish"
      ],
      [
        1,
        "Marion Cotillard"
      ],
      [
        1,
        "Oliver Cotton"
      ],
      [
        1,
        "Patrick Cox"
      ],
      [
        1,
        "Brett Cullen"
      ],
      [
        1,
        "Lex Daniel"
      ],
      [
        1,
        "David Dayan Fisher"
      ],
      [
        1,
        "William Devane"
      ],
      [
        1,
        "Aaron Eckhart"
      ],
      [
        1,
        "Chris Ellis"
      ],
      [
        1,
        "Will Estes"
      ],
      [
        1,
        "Aja Evans"
      ],
      [
        1,
        "John Farrer"
      ],
      [
        1,
        "Russ Fega"
      ],
      [
        1,
        "Massi Furlan"
      ],
      [
        1,
        "Uri Gavriel"
      ],
      [
        1,
        "Todd Gearhart"
      ],
      [
        1,
        "Ronnie Gene Blevins"
      ],
      [
        1,
        "Aidan Gillen"
      ],
      [
        1,
        "Sarah Goldberg"
      ],
      [
        1,
        "Joseph Gordon-Levitt"
      ],
      [
        1,
        "Burn Gorman"
      ],
      [
        1,
        "Daina Griffith"
      ],
      [
        1,
        "Travis Guba"
      ],
      [
        1,
        "Tom Hardy"
      ],
      [
        1,
        "Desmond Harrington"
      ],
      [
        1,
        "James Harvey Ward"
      ],
      [
        1,
        "Anne Hathaway"
      ],
      [
        1,
        "Chris Hill"
      ],
      [
        1,
        "Peter Holden"
      ],
      [
        1,
        "John Hollingworth"
      ],
      [
        1,
        "Cameron Jack"
      ],
      [
        1,
        "Charles Jackson Coyne"
      ],
      [
        1,
        "Michael James Faradie"
      ],
      [
        1,
        "Phillip James Griffith"
      ],
      [
        1,
        "Patrick Jordan"
      ],
      [
        1,
        "Nick Julian"
      ],
      [
        1,
        "Claire Julien"
      ],
      [
        1,
        "Sam Kennard"
      ],
      [
        1,
        "Kevin Kiely Jnr"
      ],
      [
        1,
        "Mark Killeen"
      ],
      [
        1,
        "Joey King"
      ],
      [
        1,
        "Aramis Knight"
      ],
      [
        1,
        "Patrick Leahy"
      ],
      [
        1,
        "Fredric Lehne"
      ],
      [
        1,
        "Thomas Lennon"
      ],
      [
        1,
        "Gus Lewis"
      ],
      [
        1,
        "Joseph Lyle Taylor"
      ],
      [
        1,
        "Ben Mendelsohn"
      ],
      [
        1,
        "Matthew Modine"
      ],
      [
        1,
        "David Monahan"
      ],
      [
        1,
        "Courtney Munch"
      ],
      [
        1,
        "Cillian Murphy"
      ],
      [
        1,
        "Liam Neeson"
      ],
      [
        1,
        "Daniel Newman"
      ],
      [
        1,
        "Rory Nolan"
      ],
      [
        1,
        "John Nolan"
      ],
      [
        1,
        "Miranda Nolan"
      ],
      [
        1,
        "Gary Oldman"
      ],
      [
        1,
        "Josh Pence"
      ],
      [
        1,
        "Glen Powell"
      ],
      [
        1,
        "Linus Roache"
      ],
      [
        1,
        "Luke Rutherford"
      ],
      [
        1,
        "Max Schuler"
      ],
      [
        1,
        "Josh Stewart"
      ],
      [
        1,
        "Juno Temple"
      ],
      [
        1,
        "Aliash Tepina"
      ],
      [
        1,
        "Trevor White"
      ],
      [
        1,
        "Wade Williams"
      ]
    ],
    "directors": "Christopher Nolan",
    "budget_string": "250m USD",
    "gross_string": "1081.2m USD",
    "metascore_string": "78%"
  },
  {
    "imdb_id": "tt1979320",
    "plot": "The merciless 1970s rivalry between Formula One rivals James Hunt and Niki Lauda.",
    "certification": "R",
    "other_wins_count": 5,
    "other_noms_count": 65,
    "amazon": "https://www.amazon.com/Rush-Blu-ray/dp/B00GR9JXSS/ref=tmm_blu_title_0?_encoding=UTF8&amp;amp&amp;qid=1655184796&amp;amp&amp;sr=1-1&_encoding=UTF8&tag=sianglu-20&linkCode=ur2&linkId=8e32265ab6e5b56e323523dc64e56dc2&camp=1789&creative=9325",
    "justwatch": "https://justwatch.com/us/movie/rush-2013?utm_source=share&utm_medium=web",
    "beige_index": 1,
    "beige_angle": 8.6202,
    "cast": [
      [
        3,
        "Roberto Cavazos"
      ],
      [
        3,
        "Roger Nevares"
      ],
      [
        3,
        "Joe Ferrara"
      ],
      [
        2,
        "Masashi Fujimoto"
      ],
      [
        2,
        "Akira Koieyama"
      ],
      [
        2,
        "Eiji Mihara"
      ],
      [
        2,
        "Zack Niizato"
      ],
      [
        1,
        "Patrick Baladi"
      ],
      [
        1,
        "Folker Banik"
      ],
      [
        1,
        "Paolo Barone"
      ],
      [
        1,
        "Alan Bayer"
      ],
      [
        1,
        "Polly Brindle"
      ],
      [
        1,
        "Hannah Britland"
      ],
      [
        1,
        "Daniel Br\u00fchl"
      ],
      [
        1,
        "David Calder"
      ],
      [
        1,
        "Alastair Caldwell"
      ],
      [
        1,
        "Ilario Calvo"
      ],
      [
        1,
        "Marco Canadea"
      ],
      [
        1,
        "Bob Constanduros"
      ],
      [
        1,
        "Klaus D. Mund"
      ],
      [
        1,
        "Vanda Dadras"
      ],
      [
        1,
        "Augusto Dallara"
      ],
      [
        1,
        "Jamie de Courcey"
      ],
      [
        1,
        "Jos\u00e9phine de La Baume"
      ],
      [
        1,
        "Raffaello Degruttola"
      ],
      [
        1,
        "Natalie Dormer"
      ],
      [
        1,
        "Hans-Eckart Eckhardt"
      ],
      [
        1,
        "Andreas Engelmann"
      ],
      [
        1,
        "Pierfrancesco Favino"
      ],
      [
        1,
        "Christian Feist"
      ],
      [
        1,
        "Francesco Fronte"
      ],
      [
        1,
        "Demetri Goritsas"
      ],
      [
        1,
        "Chris Hemsworth"
      ],
      [
        1,
        "Scott Hopkins"
      ],
      [
        1,
        "Val Jobara"
      ],
      [
        1,
        "Brooke Johnston"
      ],
      [
        1,
        "Jochen Kolenda"
      ],
      [
        1,
        "Xavier Laurent"
      ],
      [
        1,
        "Stephen Mangan"
      ],
      [
        1,
        "Alexandra Maria Lara"
      ],
      [
        1,
        "Luca Matteo Zizzari"
      ],
      [
        1,
        "Lisa McAllister"
      ],
      [
        1,
        "Christian McKay"
      ],
      [
        1,
        "Morris Minelli"
      ],
      [
        1,
        "Luca Naddeo"
      ],
      [
        1,
        "Marco Napoli"
      ],
      [
        1,
        "James Norton"
      ],
      [
        1,
        "Alistair Petrie"
      ],
      [
        1,
        "Mark Postgate"
      ],
      [
        1,
        "Erich Redman"
      ],
      [
        1,
        "Douglas Reith"
      ],
      [
        1,
        "Julian Rhind-Tutt"
      ],
      [
        1,
        "Vincent Riotta"
      ],
      [
        1,
        "Martin Savage"
      ],
      [
        1,
        "Jay Simpson"
      ],
      [
        1,
        "Jamie Sives"
      ],
      [
        1,
        "Cristian Solimeno"
      ],
      [
        1,
        "Philippe Spall"
      ],
      [
        1,
        "Cristian Stelluti"
      ],
      [
        1,
        "Colin Stinton"
      ],
      [
        1,
        "Geoffrey Streatfeild"
      ],
      [
        1,
        "Simon Taylor"
      ],
      [
        1,
        "Julien Vialon"
      ],
      [
        1,
        "Marcello Walton"
      ],
      [
        1,
        "Matthew Watkinson"
      ],
      [
        1,
        "Olivia Wilde"
      ],
      [
        1,
        "Tom Wlaschiha"
      ],
      [
        1,
        "Christopher Wolert"
      ],
      [
        1,
        "Anthony Wolfe"
      ],
      [
        1,
        "Jeremy Wolfe"
      ],
      [
        1,
        "Vanessa Zachos"
      ]
    ],
    "directors": "Ron Howard",
    "budget_string": "38m USD",
    "gross_string": "97m USD",
    "metascore_string": "74%"
  },
  {
    "imdb_id": "tt0993846",
    "plot": "Based on the true story of Jordan Belfort, from his rise to a wealthy stock-broker living the high life to his fall involving crime, corruption and the federal government.",
    "certification": "R",
    "other_wins_count": 37,
    "other_noms_count": 174,
    "amazon": "https://www.amazon.com/Wolf-Wall-Street-Blu-ray-Digital/dp/B00H9KKKAY/ref=sr_1_3?crid=18OV9MZT0KDLC&amp;keywords=wolf+of+wall+street+blu+ray&amp;qid=1655169696&amp;sprefix=wolf+of+wall+street%252Caps%252C298&amp;sr=8-3&_encoding=UTF8&tag=sianglu-20&linkCode=ur2&linkId=217655dd9caf58edee93e9c75c2d5c7a&camp=1789&creative=9325",
    "justwatch": "https://justwatch.com/us/movie/wolf-of-wall-street-the?utm_source=share&utm_medium=web",
    "beige_index": 1,
    "beige_angle": 23.7762,
    "cast": [
      [
        5,
        "Jamel Daniels"
      ],
      [
        5,
        "Starr Duncan-Lowe"
      ],
      [
        5,
        "Sharon Jones"
      ],
      [
        5,
        "Johnnie Mae"
      ],
      [
        5,
        "Reginald VelJohnson"
      ],
      [
        5,
        "Saundra Williams"
      ],
      [
        5,
        "Rizwan Manji"
      ],
      [
        4,
        "Armen Garo"
      ],
      [
        4,
        "Donnie Keshawarz"
      ],
      [
        4,
        "Zineb Oukach"
      ],
      [
        3,
        "Marcos A. Gonzalez"
      ],
      [
        3,
        "Carla Corvo"
      ],
      [
        3,
        "Jos\u00e9 Ram\u00f3n Rosario"
      ],
      [
        2,
        "Kenneth Choi"
      ],
      [
        1,
        "Danny A. Abeckaser"
      ],
      [
        1,
        "Deema Aitken"
      ],
      [
        1,
        "Justin Anthony Long"
      ],
      [
        1,
        "Krista Ashworth"
      ],
      [
        1,
        "Ashlie Atkinson"
      ],
      [
        1,
        "Dean Auer"
      ],
      [
        1,
        "Adria Baratta"
      ],
      [
        1,
        "John Behlmann"
      ],
      [
        1,
        "Jordan Belfort"
      ],
      [
        1,
        "R\u00e9my Bennett"
      ],
      [
        1,
        "Natalie Bensel"
      ],
      [
        1,
        "John Bernard Martin"
      ],
      [
        1,
        "Jon Bernthal"
      ],
      [
        1,
        "Dan Bittner"
      ],
      [
        1,
        "Ashley Blankenship"
      ],
      [
        1,
        "Jeremy Bobb"
      ],
      [
        1,
        "Steven Boyer"
      ],
      [
        1,
        "Viki Boyle"
      ],
      [
        1,
        "Michael Bryan French"
      ],
      [
        1,
        "P.J. Byrne"
      ],
      [
        1,
        "Chris Caldovino"
      ],
      [
        1,
        "Allyson Carr"
      ],
      [
        1,
        "Katarina Cas"
      ],
      [
        1,
        "Aya Cash"
      ],
      [
        1,
        "Kyle Chandler"
      ],
      [
        1,
        "Brian Charles Johnson"
      ],
      [
        1,
        "Robert Clohessy"
      ],
      [
        1,
        "Shea Coleman"
      ],
      [
        1,
        "Catherine Curtin"
      ],
      [
        1,
        "Dan Daily"
      ],
      [
        1,
        "Michael Devine"
      ],
      [
        1,
        "Leonardo DiCaprio"
      ],
      [
        1,
        "Bo Dietl"
      ],
      [
        1,
        "Jean Dujardin"
      ],
      [
        1,
        "Christine Ebersole"
      ],
      [
        1,
        "Giselle Eisenberg"
      ],
      [
        1,
        "Jon Favreau"
      ],
      [
        1,
        "Kathleen Fellegara"
      ],
      [
        1,
        "Danny Flaherty"
      ],
      [
        1,
        "Tracy Friedman"
      ],
      [
        1,
        "Jason Furlani"
      ],
      [
        1,
        "Aaron Glaser"
      ],
      [
        1,
        "Tom Greer"
      ],
      [
        1,
        "Brendan Griffin"
      ],
      [
        1,
        "Ted Griffin"
      ],
      [
        1,
        "Daniel Hepner"
      ],
      [
        1,
        "Edward Herrmann"
      ],
      [
        1,
        "Jonah Hill"
      ],
      [
        1,
        "Jake Hoffman"
      ],
      [
        1,
        "Ward Horton"
      ],
      [
        1,
        "Jared Houseman"
      ],
      [
        1,
        "Dan Hunter"
      ],
      [
        1,
        "Michael Izquierdo"
      ],
      [
        1,
        "Christina Jeffs"
      ],
      [
        1,
        "Dustin Kerns"
      ],
      [
        1,
        "Jaclyn Keys"
      ],
      [
        1,
        "Stephen Kunken"
      ],
      [
        1,
        "Stephanie Kurtzuba"
      ],
      [
        1,
        "Aaron Lazar"
      ],
      [
        1,
        "Fran Lebowitz"
      ],
      [
        1,
        "Ben Loving"
      ],
      [
        1,
        "Joanna Lumley"
      ],
      [
        1,
        "J.C. MacKenzie"
      ],
      [
        1,
        "Kerry Malloy"
      ],
      [
        1,
        "Zana Markelson"
      ],
      [
        1,
        "Sabina Maschi"
      ],
      [
        1,
        "Chris Matesevac"
      ],
      [
        1,
        "Matthew McConaughey"
      ],
      [
        1,
        "Madison McKinley"
      ],
      [
        1,
        "MacKenzie Meehan"
      ],
      [
        1,
        "Thomas Middleditch"
      ],
      [
        1,
        "Cristin Milioti"
      ],
      [
        1,
        "Derek Millman"
      ],
      [
        1,
        "Paul Monte Jr."
      ],
      [
        1,
        "Charley Morgan"
      ],
      [
        1,
        "Bekah Mulberg"
      ],
      [
        1,
        "Michael Nathanson"
      ],
      [
        1,
        "Sandra Nelson"
      ],
      [
        1,
        "Natasha Newman-Thomas"
      ],
      [
        1,
        "Scott Nicholson"
      ],
      [
        1,
        "J.T. O'Connor"
      ],
      [
        1,
        "Jamie O\u2019Connell"
      ],
      [
        1,
        "Michael O\u2019Hara"
      ],
      [
        1,
        "Tess Olivia"
      ],
      [
        1,
        "Matte Osian"
      ],
      [
        1,
        "Garry Pastore"
      ],
      [
        1,
        "Gregory Perri"
      ],
      [
        1,
        "Meghan Rafferty"
      ],
      [
        1,
        "Ben Rameaka"
      ],
      [
        1,
        "Matthew Rauch"
      ],
      [
        1,
        "Dierdre Reimold"
      ],
      [
        1,
        "Rob Reiner"
      ],
      [
        1,
        "Chris Riggi"
      ],
      [
        1,
        "Margot Robbie"
      ],
      [
        1,
        "Barry Rothbart"
      ],
      [
        1,
        "Steve Routman"
      ],
      [
        1,
        "Brian Sacca"
      ],
      [
        1,
        "Ellen Sexton"
      ],
      [
        1,
        "Bret Shuford"
      ],
      [
        1,
        "Lawrence Smith"
      ],
      [
        1,
        "Jon Spinogatti"
      ],
      [
        1,
        "Ashley Springer"
      ],
      [
        1,
        "Davram Stiefler"
      ],
      [
        1,
        "Ethan Suplee"
      ],
      [
        1,
        "Johnathan Tchaikovsky"
      ],
      [
        1,
        "Sebastian Tillinger"
      ],
      [
        1,
        "Emily Tremaine"
      ],
      [
        1,
        "Brian Tweedy"
      ],
      [
        1,
        "Paul Urcioli"
      ],
      [
        1,
        "Ben Van Bergen"
      ],
      [
        1,
        "Frank van Putten"
      ],
      [
        1,
        "Louis Vanaria"
      ],
      [
        1,
        "Victor Verhaeghe"
      ],
      [
        1,
        "Shea Whigham"
      ],
      [
        1,
        "Welker White"
      ],
      [
        1,
        "Hardy Winburn"
      ],
      [
        1,
        "Steve Witting"
      ],
      [
        1,
        "Justin Yllanes"
      ],
      [
        1,
        "Peter Youngblood Hills"
      ],
      [
        1,
        "Henry Zebrowski"
      ]
    ],
    "directors": "Martin Scorsese",
    "budget_string": "100m USD",
    "gross_string": "392m USD",
    "metascore_string": "75%"
  },
  {
    "imdb_id": "tt2024544",
    "plot": "In the antebellum United States, Solomon Northup, a free black man from upstate New York, is abducted and sold into slavery.",
    "certification": "R",
    "other_wins_count": 239,
    "other_noms_count": 337,
    "amazon": "https://www.amazon.com/Years-Slave-Blu-ray-Chiwetel-Ejiofor/dp/B00G4Q3NDA/ref=sr_1_1?crid=1SG3N0LL2WL9Q&amp;keywords=12+years+a+slave+blu+ray&amp;qid=1655182703&amp;sprefix=12+years+a+slave+blu+ray%252Caps%252C285&amp;sr=8-1&_encoding=UTF8&tag=sianglu-20&linkCode=ur2&linkId=1a3479f792afefcfa69099f99dd6eaa6&camp=1789&creative=9325",
    "justwatch": "https://justwatch.com/us/movie/twelve-years-a-slave?utm_source=share&utm_medium=web",
    "beige_index": 4,
    "beige_angle": 120.828,
    "cast": [
      [
        5,
        "Devyn A. Tyler"
      ],
      [
        5,
        "Gregory Bright"
      ],
      [
        5,
        "Chris Chalk"
      ],
      [
        5,
        "Topsy Chapman"
      ],
      [
        5,
        "Nicole Collins"
      ],
      [
        5,
        "Andre De'Sean Shanks"
      ],
      [
        5,
        "Chiwetel Ejiofor"
      ],
      [
        5,
        "Dudley Fuqua"
      ],
      [
        5,
        "Anwan Glover"
      ],
      [
        5,
        "Mustafa Harris"
      ],
      [
        5,
        "Kelvin Harrison Jr."
      ],
      [
        5,
        "Dwight Henry"
      ],
      [
        5,
        "Isaiah Jackson"
      ],
      [
        5,
        "Willo Jean-Baptiste"
      ],
      [
        5,
        "Michael Kenneth Williams"
      ],
      [
        5,
        "Marcus Lyle Brown"
      ],
      [
        5,
        "Mister Mackey Jr."
      ],
      [
        5,
        "Devin Maurice Evans"
      ],
      [
        5,
        "Lupita Nyong'o"
      ],
      [
        5,
        "Adepero Oduye"
      ],
      [
        5,
        "Austin Purnell"
      ],
      [
        5,
        "Storm Reid"
      ],
      [
        5,
        "Kelsey Scott"
      ],
      [
        5,
        "Craig Tate"
      ],
      [
        5,
        "Deneen Tyler"
      ],
      [
        5,
        "Quvenzhan\u00e9 Wallis"
      ],
      [
        5,
        "Alfre Woodard"
      ],
      [
        5,
        "Cameron Zeigler"
      ],
      [
        5,
        "Vivian Fleming-Alvarez"
      ],
      [
        4,
        "Ashley Dyke"
      ],
      [
        1,
        "Bryan Batt"
      ],
      [
        1,
        "Tony Bentley"
      ],
      [
        1,
        "Christopher Berry"
      ],
      [
        1,
        "James C. Victor"
      ],
      [
        1,
        "Bill Camp"
      ],
      [
        1,
        "Benedict Cumberbatch"
      ],
      [
        1,
        "Paul Dano"
      ],
      [
        1,
        "Garret Dillahunt"
      ],
      [
        1,
        "Andy Dylan"
      ],
      [
        1,
        "J.D. Evermore"
      ],
      [
        1,
        "Michael Fassbender"
      ],
      [
        1,
        "Thomas Francis Murphy"
      ],
      [
        1,
        "Paul Giamatti"
      ],
      [
        1,
        "Dickie Gravois"
      ],
      [
        1,
        "Richard Holden"
      ],
      [
        1,
        "Jay Huguley"
      ],
      [
        1,
        "Liza J. Bennett"
      ],
      [
        1,
        "Taran Killam"
      ],
      [
        1,
        "Douglas M. Griffin"
      ],
      [
        1,
        "Marc Macaulay"
      ],
      [
        1,
        "John McConnell"
      ],
      [
        1,
        "Scoot McNairy"
      ],
      [
        1,
        "Scott Michael Jefferson"
      ],
      [
        1,
        "Ritchie Montgomery"
      ],
      [
        1,
        "Sarah Paulson"
      ],
      [
        1,
        "Brad Pitt"
      ],
      [
        1,
        "Tom Proctor"
      ],
      [
        1,
        "Rob Steinberg"
      ]
    ],
    "directors": "Steve McQueen",
    "budget_string": "20m USD",
    "gross_string": "187.7m USD",
    "metascore_string": "96%"
  },
  {
    "imdb_id": "tt1392214",
    "plot": "When Keller Dover's daughter and her friend go missing, he takes matters into his own hands as the police pursue multiple leads and the pressure mounts.",
    "certification": "R",
    "other_wins_count": 10,
    "other_noms_count": 37,
    "amazon": "https://www.amazon.com/Prisoners-Blu-ray-DVD-Hugh-Jackman/dp/B0034G4P3A/ref=sr_1_1?crid=1W917V4IQN7NS&amp;keywords=prisoners+blu+ray&amp;qid=1655181980&amp;sprefix=prisoners+blu+ray%252Caps%252C325&amp;sr=8-1&_encoding=UTF8&tag=sianglu-20&linkCode=ur2&linkId=9a2b1cb96d30eacceab3dc258bfc8d13&camp=1789&creative=9325",
    "justwatch": "https://justwatch.com/us/movie/prisoners?utm_source=share&utm_medium=web",
    "beige_index": 2,
    "beige_angle": 51.8823,
    "cast": [
      [
        5,
        "Kyla-Drew"
      ],
      [
        5,
        "Viola Davis"
      ],
      [
        5,
        "Brian Daye"
      ],
      [
        5,
        "Terrence Howard"
      ],
      [
        5,
        "Brad James"
      ],
      [
        5,
        "Pam Smith"
      ],
      [
        4,
        "Zo\u00eb Soul"
      ],
      [
        3,
        "J. Omar Castro"
      ],
      [
        2,
        "Lana Yoo"
      ],
      [
        1,
        "John Atwood"
      ],
      [
        1,
        "Maria Bello"
      ],
      [
        1,
        "Robert C. Treveiler"
      ],
      [
        1,
        "Len Cariou"
      ],
      [
        1,
        "Dennis Christopher"
      ],
      [
        1,
        "Paul Dano"
      ],
      [
        1,
        "David Dastmalchian"
      ],
      [
        1,
        "Mark Drum"
      ],
      [
        1,
        "Wayne Duvall"
      ],
      [
        1,
        "Sandra Ellis Lafferty"
      ],
      [
        1,
        "Erin Gerasimovich"
      ],
      [
        1,
        "Rodrick Goins"
      ],
      [
        1,
        "Jake Gyllenhaal"
      ],
      [
        1,
        "Alisa Harris"
      ],
      [
        1,
        "Hugh Jackman"
      ],
      [
        1,
        "Michelle Keller"
      ],
      [
        1,
        "Melissa Leo"
      ],
      [
        1,
        "Jane McNeill"
      ],
      [
        1,
        "Stacy Melich"
      ],
      [
        1,
        "Robert Mello"
      ],
      [
        1,
        "Dylan Minnette"
      ],
      [
        1,
        "Jeff Pope"
      ],
      [
        1,
        "Anthony Reynolds"
      ],
      [
        1,
        "Victoria Staley"
      ],
      [
        1,
        "Todd Truley"
      ]
    ],
    "directors": "Denis Villeneuve",
    "budget_string": "46m USD",
    "gross_string": "122.1m USD",
    "metascore_string": "70%"
  },
  {
    "imdb_id": "tt3011894",
    "plot": "Six short stories that explore the extremities of human behavior involving people in distress.",
    "certification": "R",
    "other_wins_count": 49,
    "other_noms_count": 57,
    "amazon": "https://www.amazon.com/Wild-Tales-Blu-ray-Ricardo-Dar%25C3%25ADn/dp/B00UHAJ1AI/ref=sr_1_1?crid=30627PW6RP5NR&amp;keywords=wild+tales+blu+ray&amp;qid=1655183690&amp;sprefix=wild+tales+blu+ray%252Caps%252C286&amp;sr=8-1&_encoding=UTF8&tag=sianglu-20&linkCode=ur2&linkId=5376b164e95f446639e41b0f262c318a&camp=1789&creative=9325",
    "justwatch": "https://justwatch.com/us/movie/wild-tales?utm_source=share&utm_medium=web",
    "beige_index": 3,
    "beige_angle": 108.0,
    "cast": [
      [
        3,
        "Liliana Ackerman"
      ],
      [
        3,
        "Carlos Alberto Vavassori"
      ],
      [
        3,
        "Miguel \u00c1ngel Platinado Grando"
      ],
      [
        3,
        "Alejandro Angelini"
      ],
      [
        3,
        "Dami\u00e1n Ben\u00edtez"
      ],
      [
        3,
        "Cristina Blanco"
      ],
      [
        3,
        "Gustavo Bonfigli"
      ],
      [
        3,
        "C\u00e9sar Bord\u00f3n"
      ],
      [
        3,
        "Pablo Bricker"
      ],
      [
        3,
        "Pablo Chao"
      ],
      [
        3,
        "Rita Cortese"
      ],
      [
        3,
        "Gustavo Curchio"
      ],
      [
        3,
        "Alan Daicz"
      ],
      [
        3,
        "Ricardo Dar\u00edn"
      ],
      [
        3,
        "Germ\u00e1n de Silva"
      ],
      [
        3,
        "Diana Deglauy"
      ],
      [
        3,
        "Claudio Delan"
      ],
      [
        3,
        "Miguel Di Lemme"
      ],
      [
        3,
        "Walter Donado"
      ],
      [
        3,
        "H\u00e9ctor Drachtman"
      ],
      [
        3,
        "Nancy Dupl\u00e1a"
      ],
      [
        3,
        "Graciela Fodrini"
      ],
      [
        3,
        "Camila Franco"
      ],
      [
        3,
        "Marcelo Frasca"
      ],
      [
        3,
        "\u00c1ngel Frega"
      ],
      [
        3,
        "Andrea Garrote"
      ],
      [
        3,
        "Lucrecia Gelardi"
      ],
      [
        3,
        "Diego Gentile"
      ],
      [
        3,
        "Mart\u00edn Gervasoni"
      ],
      [
        3,
        "Dar\u00edo Grandinetti"
      ],
      [
        3,
        "Paula Grinszpan"
      ],
      [
        3,
        "Silvina La Morte"
      ],
      [
        3,
        "Mar\u00eda Laura Caccamo"
      ],
      [
        3,
        "Federico Liss"
      ],
      [
        3,
        "Pablo Machado"
      ],
      [
        3,
        "Lucila Mangone"
      ],
      [
        3,
        "Luis Manuel Altamirano Garc\u00eda"
      ],
      [
        3,
        "Oscar Mart\u00ednez"
      ],
      [
        3,
        "Mar\u00eda Marull"
      ],
      [
        3,
        "Luis Mazzeo"
      ],
      [
        3,
        "Daniel Merwicer"
      ],
      [
        3,
        "Margarita Molfino"
      ],
      [
        3,
        "Pablo Moseinco"
      ],
      [
        3,
        "Carlos Moy\u00e1"
      ],
      [
        3,
        "Horacio Nin Uria"
      ],
      [
        3,
        "Osmar N\u00fa\u00f1ez"
      ],
      [
        3,
        "Mar\u00eda Onetto"
      ],
      [
        3,
        "Juan Pablo Colombo"
      ],
      [
        3,
        "Javier Pedersoli"
      ],
      [
        3,
        "Fiorella Pedrazzini"
      ],
      [
        3,
        "Marcelo Pozzi"
      ],
      [
        3,
        "Erica Rivas"
      ],
      [
        3,
        "Victoria Roland"
      ],
      [
        3,
        "Noem\u00ed Ron"
      ],
      [
        3,
        "Mar\u00eda Rosa L\u00f3pez Ottonello"
      ],
      [
        3,
        "Juan Santiago Linari"
      ],
      [
        3,
        "Leonardo Sbaraglia"
      ],
      [
        3,
        "Mariano Sigman"
      ],
      [
        3,
        "Camila Sof\u00eda Casas"
      ],
      [
        3,
        "Emilio Soler"
      ],
      [
        3,
        "Diego Starosta"
      ],
      [
        3,
        "Ricardo Truppel"
      ],
      [
        3,
        "Abi\u00e1n Vainstein"
      ],
      [
        3,
        "Horacio Vay"
      ],
      [
        3,
        "Ramiro Vayo"
      ],
      [
        3,
        "Diego Vel\u00e1zquez"
      ],
      [
        3,
        "M\u00f3nica Villa"
      ],
      [
        3,
        "Liliana Weimer"
      ],
      [
        3,
        "Julieta Zylberberg"
      ]
    ],
    "directors": "Dami\u00e1n Szifron",
    "budget_string": "3.3m USD",
    "gross_string": "30.6m USD",
    "metascore_string": "77%"
  },
  {
    "imdb_id": "tt2582802",
    "plot": "A promising young drummer enrolls at a cut-throat music conservatory where his dreams of greatness are mentored by an instructor who will stop at nothing to realize a student's potential.",
    "certification": "R",
    "other_wins_count": 95,
    "other_noms_count": 145,
    "amazon": "https://www.amazon.com/Whiplash-Blu-ray-J-K-Simmons/dp/B00PT3AUYO/ref=sr_1_3?crid=DUINO39L9U8C&amp;keywords=whiplash&amp;qid=1654832037&amp;s=movies-tv&amp;sprefix=whiplash%252Cmovies-tv-intl-ship%252C378&amp;sr=1-3&_encoding=UTF8&tag=sianglu-20&linkCode=ur2&linkId=06079e612f1cdbe5ff3c40ffdea54ffc&camp=1789&creative=9325",
    "justwatch": "https://justwatch.com/us/movie/whiplash?utm_source=share&utm_medium=web",
    "beige_index": 3,
    "beige_angle": 104.1084,
    "cast": [
      [
        5,
        "Clifton 'Fou Fou' Eddie"
      ],
      [
        5,
        "Keenan Allen"
      ],
      [
        5,
        "Jocelyn Ayanna"
      ],
      [
        5,
        "Tony Baker"
      ],
      [
        5,
        "Calvin C. Winbush"
      ],
      [
        5,
        "Rogelio Douglas Jr."
      ],
      [
        5,
        "Henry G. Sanders"
      ],
      [
        5,
        "April Grace"
      ],
      [
        5,
        "Damon Gupton"
      ],
      [
        5,
        "Marcus Henderson"
      ],
      [
        5,
        "Jimmie Kirkpatrick"
      ],
      [
        5,
        "Tarik Lowe"
      ],
      [
        5,
        "Kofi Siriboe"
      ],
      [
        5,
        "Ayinde Vaughan"
      ],
      [
        5,
        "Yancey Wells"
      ],
      [
        5,
        "Kavita Patil"
      ],
      [
        3,
        "Adrian Burks"
      ],
      [
        1,
        "Melissa Benoist"
      ],
      [
        1,
        "Jayson Blair"
      ],
      [
        1,
        "Sam Campisi"
      ],
      [
        1,
        "Michael D. Cohen"
      ],
      [
        1,
        "Shai Golan"
      ],
      [
        1,
        "Keenan Henson"
      ],
      [
        1,
        "Janet Hoskins"
      ],
      [
        1,
        "Charlie Ian"
      ],
      [
        1,
        "Max Kasch"
      ],
      [
        1,
        "Krista Kilber"
      ],
      [
        1,
        "Tyler Kimball"
      ],
      [
        1,
        "Nate Lang"
      ],
      [
        1,
        "Chris Mulkey"
      ],
      [
        1,
        "Paul Reiser"
      ],
      [
        1,
        "Candace Roberge"
      ],
      [
        1,
        "J.K. Simmons"
      ],
      [
        1,
        "Suanne Spoke"
      ],
      [
        1,
        "Austin Stowell"
      ],
      [
        1,
        "Miles Teller"
      ],
      [
        1,
        "C.J. Vana"
      ]
    ],
    "directors": "Damien Chazelle",
    "budget_string": "3.3m USD",
    "gross_string": "49.4m USD",
    "metascore_string": "88%"
  },
  {
    "imdb_id": "tt0816692",
    "plot": "A team of explorers travel through a wormhole in space in an attempt to ensure humanity's survival.",
    "certification": "PG-13",
    "other_wins_count": 43,
    "other_noms_count": 148,
    "amazon": "https://www.amazon.com/InterStellar-4K-UltraHD-Blu-ray-Interstellar/dp/B0767FCYDW/ref=sr_1_3?crid=RR89UF19O3UQ&amp;keywords=interstellar&amp;qid=1654828470&amp;sprefix=interstellar%252Caps%252C632&amp;sr=8-3&_encoding=UTF8&tag=sianglu-20&linkCode=ur2&linkId=92e3022524b32e791a0306d7adf6cf37&camp=1789&creative=9325",
    "justwatch": "https://justwatch.com/us/movie/interstellar?utm_source=share&utm_medium=web",
    "beige_index": 1,
    "beige_angle": 26.0694,
    "cast": [
      [
        5,
        "David Gyasi"
      ],
      [
        5,
        "David Oyelowo"
      ],
      [
        3,
        "Elyes Gabel"
      ],
      [
        1,
        "Casey Affleck"
      ],
      [
        1,
        "Wes Bentley"
      ],
      [
        1,
        "Andrew Borba"
      ],
      [
        1,
        "Ellen Burstyn"
      ],
      [
        1,
        "Michael Caine"
      ],
      [
        1,
        "Leah Cairns"
      ],
      [
        1,
        "Timoth\u00e9e Chalamet"
      ],
      [
        1,
        "Jessica Chastain"
      ],
      [
        1,
        "Matt Damon"
      ],
      [
        1,
        "William Devane"
      ],
      [
        1,
        "William Dickinson"
      ],
      [
        1,
        "Russ Fega"
      ],
      [
        1,
        "Mackenzie Foy"
      ],
      [
        1,
        "Griffen Fraser"
      ],
      [
        1,
        "Lena Georgas"
      ],
      [
        1,
        "Topher Grace"
      ],
      [
        1,
        "Anne Hathaway"
      ],
      [
        1,
        "Jeff Hephner"
      ],
      [
        1,
        "Bill Irwin"
      ],
      [
        1,
        "John Lithgow"
      ],
      [
        1,
        "Matthew McConaughey"
      ],
      [
        1,
        "Flora Nolan"
      ],
      [
        1,
        "Brooke Smith"
      ],
      [
        1,
        "Josh Stewart"
      ],
      [
        1,
        "Collette Wolfe"
      ],
      [
        1,
        "Francis X. McCarthy"
      ]
    ],
    "directors": "Christopher Nolan",
    "budget_string": "165m USD",
    "gross_string": "701.7m USD",
    "metascore_string": "74%"
  },
  {
    "imdb_id": "tt2267998",
    "plot": "With his wife's disappearance having become the focus of an intense media circus, a man sees the spotlight turned on him when it's suspected that he may not be innocent.",
    "certification": "R",
    "other_wins_count": 64,
    "other_noms_count": 187,
    "amazon": "https://www.amazon.com/Gone-Girl-Blu-ray-Ben-Affleck/dp/B07FDPF4FK/ref=tmm_blu_title_0?_encoding=UTF8&amp;amp&amp;qid=1655182817&amp;amp&amp;sr=8-1-spons&_encoding=UTF8&tag=sianglu-20&linkCode=ur2&linkId=0735afbaf6d05eb276d748f53d57ec9e&camp=1789&creative=9325",
    "justwatch": "https://justwatch.com/us/movie/gone-girl?utm_source=share&utm_medium=web",
    "beige_index": 1,
    "beige_angle": 23.8608,
    "cast": [
      [
        5,
        "Tyler Perry"
      ],
      [
        5,
        "Antonio St. James"
      ],
      [
        5,
        "L.A. Williams"
      ],
      [
        2,
        "Scott Takeda"
      ],
      [
        1,
        "Lynn Adrianna Freedman"
      ],
      [
        1,
        "Ben Affleck"
      ],
      [
        1,
        "Mark Atteberry"
      ],
      [
        1,
        "Lisa Banes"
      ],
      [
        1,
        "Kate Campbell"
      ],
      [
        1,
        "David Clennon"
      ],
      [
        1,
        "Carrie Coon"
      ],
      [
        1,
        "Darin Cooper"
      ],
      [
        1,
        "Fred Cross"
      ],
      [
        1,
        "Kim Dickens"
      ],
      [
        1,
        "Ashley Didion"
      ],
      [
        1,
        "Patrick Fugit"
      ],
      [
        1,
        "Lauren Glazier"
      ],
      [
        1,
        "Sean Guse"
      ],
      [
        1,
        "Boyd Holbrook"
      ],
      [
        1,
        "Pete Housman"
      ],
      [
        1,
        "Leonard Kelly-Young"
      ],
      [
        1,
        "Lola Kirke"
      ],
      [
        1,
        "Brett Leigh"
      ],
      [
        1,
        "Scoot McNairy"
      ],
      [
        1,
        "Jamie McShane"
      ],
      [
        1,
        "Lee Norris"
      ],
      [
        1,
        "Lexis Nutt"
      ],
      [
        1,
        "Neil Patrick Harris"
      ],
      [
        1,
        "Rosamund Pike"
      ],
      [
        1,
        "Julia Prud'homme"
      ],
      [
        1,
        "Missi Pyle"
      ],
      [
        1,
        "Emily Ratajkowski"
      ],
      [
        1,
        "Kathleen Rose Perkins"
      ],
      [
        1,
        "Casey Ruggieri"
      ],
      [
        1,
        "Donna Rusch"
      ],
      [
        1,
        "Blake Sheldon"
      ],
      [
        1,
        "Cyd Strittmatter"
      ],
      [
        1,
        "Kathy Sweeney-Meadows"
      ],
      [
        1,
        "Mark T. Anderson"
      ],
      [
        1,
        "Cooper Thornton"
      ],
      [
        1,
        "Sela Ward"
      ],
      [
        1,
        "Casey Wilson"
      ],
      [
        1,
        "Ricky Wood"
      ]
    ],
    "directors": "David Fincher",
    "budget_string": "61m USD",
    "gross_string": "369.3m USD",
    "metascore_string": "79%"
  },
  {
    "imdb_id": "tt2278388",
    "plot": "A writer encounters the owner of an aging high-class hotel, who tells him of his early years serving as a lobby boy in the hotel's glorious years under an exceptional concierge.",
    "certification": "R",
    "other_wins_count": 130,
    "other_noms_count": 226,
    "amazon": "https://www.amazon.com/Grand-Budapest-Hotel-Blu-ray/dp/B00JAQJNN0/ref=sr_1_2?crid=S85C9EA23N1P&amp;keywords=the+grand+budapest+hotel+blu+ray&amp;qid=1655183146&amp;s=movies-tv&amp;sprefix=the+grand+budapest+hotel+blu+ray%252Cmovies-tv%252C280&amp;sr=1-2&_encoding=UTF8&tag=sianglu-20&linkCode=ur2&linkId=1f598cec038cf2abd55c995f44cbc307&camp=1789&creative=9325",
    "justwatch": "https://justwatch.com/us/movie/the-grand-budapest-hotel?utm_source=share&utm_medium=web",
    "beige_index": 1,
    "beige_angle": 6.363,
    "cast": [
      [
        5,
        "Waris Ahluwalia"
      ],
      [
        5,
        "Manpreet Gerlach"
      ],
      [
        3,
        "Tony Revolori"
      ],
      [
        2,
        "Kunichi Nomura"
      ],
      [
        1,
        "David Adamik"
      ],
      [
        1,
        "Mathieu Amalric"
      ],
      [
        1,
        "Bob Balaban"
      ],
      [
        1,
        "Gisela Bech"
      ],
      [
        1,
        "Michael Benthin"
      ],
      [
        1,
        "Roman Berger"
      ],
      [
        1,
        "Robert Bienas"
      ],
      [
        1,
        "Dirk Bossmann"
      ],
      [
        1,
        "Adrien Brody"
      ],
      [
        1,
        "Michaela Caspar"
      ],
      [
        1,
        "David Cioffi"
      ],
      [
        1,
        "Oliver Claridge"
      ],
      [
        1,
        "Wolfgang Czeczor"
      ],
      [
        1,
        "Willem Dafoe"
      ],
      [
        1,
        "Darin Damjanow"
      ],
      [
        1,
        "Marko Dyrlich"
      ],
      [
        1,
        "Golo Euler"
      ],
      [
        1,
        "Sabine Euler"
      ],
      [
        1,
        "Ralph Fiennes"
      ],
      [
        1,
        "Jeff Goldblum"
      ],
      [
        1,
        "Marie Goyette"
      ],
      [
        1,
        "Alfred H\u00e4nel"
      ],
      [
        1,
        "Heike Hanold-Lynch"
      ],
      [
        1,
        "Oliver Hazell"
      ],
      [
        1,
        "Lucas Hedges"
      ],
      [
        1,
        "Reinhold Hegelow"
      ],
      [
        1,
        "Moritz Hepper"
      ],
      [
        1,
        "Enrico Hoffmann"
      ],
      [
        1,
        "Matthias Holfert"
      ],
      [
        1,
        "Uwe Holoubek"
      ],
      [
        1,
        "Ben Howard"
      ],
      [
        1,
        "Neal Huff"
      ],
      [
        1,
        "Robin Hurlstone"
      ],
      [
        1,
        "Frank Jacob"
      ],
      [
        1,
        "Heinz-Werner Jeschkowski"
      ],
      [
        1,
        "Claudia Jung"
      ],
      [
        1,
        "Harvey Keitel"
      ],
      [
        1,
        "Renate Klein"
      ],
      [
        1,
        "Bernhard Kremser"
      ],
      [
        1,
        "Lisa Kreuzer"
      ],
      [
        1,
        "Monika Kr\u00fcger"
      ],
      [
        1,
        "Ursula Kuhnt"
      ],
      [
        1,
        "Jude Law"
      ],
      [
        1,
        "Manfred Lindner"
      ],
      [
        1,
        "Arwin Lobedann"
      ],
      [
        1,
        "Gyula Luk\u00e1cs"
      ],
      [
        1,
        "Florian Lukas"
      ],
      [
        1,
        "Roy Macready"
      ],
      [
        1,
        "Karl Markovics"
      ],
      [
        1,
        "Hans Martin Stier"
      ],
      [
        1,
        "Matthias Matschke"
      ],
      [
        1,
        "Marcel Mazur"
      ],
      [
        1,
        "Lennart Meyer"
      ],
      [
        1,
        "Volker Michalowski"
      ],
      [
        1,
        "Birgit M\u00fcller"
      ],
      [
        1,
        "Ed Munro"
      ],
      [
        1,
        "Bill Murray"
      ],
      [
        1,
        "F. Murray Abraham"
      ],
      [
        1,
        "Wolfram Nielacny"
      ],
      [
        1,
        "Jella Niemann"
      ],
      [
        1,
        "Steffen Nixdorf"
      ],
      [
        1,
        "Edward Norton"
      ],
      [
        1,
        "Jeno Orosz"
      ],
      [
        1,
        "Piet Paes"
      ],
      [
        1,
        "John Peet"
      ],
      [
        1,
        "Larry Pine"
      ],
      [
        1,
        "Anna Rademacher"
      ],
      [
        1,
        "Rainer Reiners"
      ],
      [
        1,
        "Georg Rittmannsperger"
      ],
      [
        1,
        "Saoirse Ronan"
      ],
      [
        1,
        "Dar Ronge"
      ],
      [
        1,
        "Gabriel Rush"
      ],
      [
        1,
        "Steffen Scheumann"
      ],
      [
        1,
        "Paul Schlase"
      ],
      [
        1,
        "J\u00fcrgen Schw\u00e4mmle"
      ],
      [
        1,
        "Jason Schwartzman"
      ],
      [
        1,
        "L\u00e9a Seydoux"
      ],
      [
        1,
        "Philipp Sonntag"
      ],
      [
        1,
        "Carl Sprague"
      ],
      [
        1,
        "Daniel Steiner"
      ],
      [
        1,
        "Fisher Stevens"
      ],
      [
        1,
        "Gerald Sullivan"
      ],
      [
        1,
        "Tilda Swinton"
      ],
      [
        1,
        "Georg Tryphon"
      ],
      [
        1,
        "Sabine Urig"
      ],
      [
        1,
        "Bohumil V\u00e1chal"
      ],
      [
        1,
        "Giselda Volodi"
      ],
      [
        1,
        "Hendrik von B\u00fcltzingsl\u00f6wen"
      ],
      [
        1,
        "Hannes Wegener"
      ],
      [
        1,
        "Milton Welsh"
      ],
      [
        1,
        "Jutta Westphal"
      ],
      [
        1,
        "Tom Wilkinson"
      ],
      [
        1,
        "Owen Wilson"
      ],
      [
        1,
        "Wallace Wolodarsky"
      ],
      [
        1,
        "Francesco Zippel"
      ]
    ],
    "directors": "Wes Anderson",
    "budget_string": "25m USD",
    "gross_string": "173.1m USD",
    "metascore_string": "88%"
  },
  {
    "imdb_id": "tt3170832",
    "plot": "Held captive for 7 years in an enclosed space, a woman and her young son finally gain their freedom, allowing the boy to experience the outside world for the first time.",
    "certification": "R",
    "other_wins_count": 107,
    "other_noms_count": 140,
    "amazon": "https://www.amazon.com/Room-Blu-ray-Digital-Brie-Larson/dp/B017RR6A9I/ref=tmm_blu_title_0?_encoding=UTF8&amp;qid=1655183512&amp;sr=8-15&_encoding=UTF8&tag=sianglu-20&linkCode=ur2&linkId=7a60fca162045bff4d6348e90a8b617a&camp=1789&creative=9325",
    "justwatch": "https://justwatch.com/us/movie/room-2015?utm_source=share&utm_medium=web",
    "beige_index": 1,
    "beige_angle": 33.4278,
    "cast": [
      [
        4,
        "Cas Anvar"
      ],
      [
        4,
        "Amanda Brugel"
      ],
      [
        4,
        "Zarrin Darnell-Martin"
      ],
      [
        3,
        "Rodrigo Fernandez-Stoll"
      ],
      [
        2,
        "Jee-Yun Lee"
      ],
      [
        1,
        "Joan Allen"
      ],
      [
        1,
        "Sean Bridgers"
      ],
      [
        1,
        "Wendy Crewson"
      ],
      [
        1,
        "Kate Drummond"
      ],
      [
        1,
        "Randal Edwards"
      ],
      [
        1,
        "Jack Fulton"
      ],
      [
        1,
        "Matt Gordon"
      ],
      [
        1,
        "William H. Macy"
      ],
      [
        1,
        "Brie Larson"
      ],
      [
        1,
        "Justin Mader"
      ],
      [
        1,
        "Tom McCamus"
      ],
      [
        1,
        "Sandy McMaster"
      ],
      [
        1,
        "Rory O'Shea"
      ],
      [
        1,
        "Joe Pingue"
      ],
      [
        1,
        "Ola Sturik"
      ],
      [
        1,
        "Jacob Tremblay"
      ]
    ],
    "directors": "Lenny Abrahamson",
    "budget_string": "13m USD",
    "gross_string": "35.4m USD",
    "metascore_string": "86%"
  },
  {
    "imdb_id": "tt1895587",
    "plot": "The true story of how the Boston Globe uncovered the massive scandal of child molestation and cover-up within the local Catholic Archdiocese, shaking the entire Catholic Church to its core.",
    "certification": "R",
    "other_wins_count": 122,
    "other_noms_count": 143,
    "amazon": "https://www.amazon.com/Spotlight-Blu-ray-Mark-Ruffalo/dp/B019NB5EVG/ref=sr_1_1?crid=3FVL5L2TEF73R&amp;keywords=spotlight+blu+ray&amp;qid=1655184846&amp;s=movies-tv&amp;sprefix=spotlight+blu+ray%252Cmovies-tv%252C281&amp;sr=1-1&_encoding=UTF8&tag=sianglu-20&linkCode=ur2&linkId=5b8d3c60b1a1dada5c50b35a82a23be0&camp=1789&creative=9325",
    "justwatch": "https://justwatch.com/us/movie/spotlight?utm_source=share&utm_medium=web",
    "beige_index": 1,
    "beige_angle": 20.5524,
    "cast": [
      [
        5,
        "Neion George"
      ],
      [
        5,
        "Janet Pinnick"
      ],
      [
        5,
        "Martin Roach"
      ],
      [
        4,
        "Zarrin Darnell-Martin"
      ],
      [
        3,
        "Paloma Nu\u00f1ez"
      ],
      [
        2,
        "Elena Juatco"
      ],
      [
        1,
        "Don Allison"
      ],
      [
        1,
        "Gene Amoroso"
      ],
      [
        1,
        "Robert B. Kennedy"
      ],
      [
        1,
        "Darrin Baker"
      ],
      [
        1,
        "Paula Barrett"
      ],
      [
        1,
        "Brad Borbridge"
      ],
      [
        1,
        "Len Cariou"
      ],
      [
        1,
        "Brian Chamberlain"
      ],
      [
        1,
        "Robert Clarke"
      ],
      [
        1,
        "Michael Countryman"
      ],
      [
        1,
        "Michael Cyril Creighton"
      ],
      [
        1,
        "Brian d'Arcy James"
      ],
      [
        1,
        "Rob deLeeuw"
      ],
      [
        1,
        "Tom Driscoll"
      ],
      [
        1,
        "Moira Driscoll"
      ],
      [
        1,
        "Stefanie Drummond"
      ],
      [
        1,
        "Nancy E. Carroll"
      ],
      [
        1,
        "Richard Fitzpatrick"
      ],
      [
        1,
        "David Fraser"
      ],
      [
        1,
        "Gary Galone"
      ],
      [
        1,
        "Paul Guilfoyle"
      ],
      [
        1,
        "Laurie Heineman"
      ],
      [
        1,
        "Neal Huff"
      ],
      [
        1,
        "Michael Keaton"
      ],
      [
        1,
        "Maureen Keiller"
      ],
      [
        1,
        "Jimmy LeBlanc"
      ],
      [
        1,
        "Dennis Lynch"
      ],
      [
        1,
        "Rachel McAdams"
      ],
      [
        1,
        "Shannon McDonough"
      ],
      [
        1,
        "Sharon McFarlane"
      ],
      [
        1,
        "Mark McGrinder"
      ],
      [
        1,
        "Wendy Merry"
      ],
      [
        1,
        "Timothy Mooney"
      ],
      [
        1,
        "Krista Morin"
      ],
      [
        1,
        "Laurie Murdoch"
      ],
      [
        1,
        "Siobhan Murphy"
      ],
      [
        1,
        "Duane Murray"
      ],
      [
        1,
        "Doug Murray"
      ],
      [
        1,
        "Mairtin O'Carrigan"
      ],
      [
        1,
        "Richard O'Rourke"
      ],
      [
        1,
        "Eileen Padua"
      ],
      [
        1,
        "Anthony Paolucci"
      ],
      [
        1,
        "Tim Progosh"
      ],
      [
        1,
        "Michele Proude"
      ],
      [
        1,
        "Patty Ross"
      ],
      [
        1,
        "Mark Ruffalo"
      ],
      [
        1,
        "Shannon Ruth"
      ],
      [
        1,
        "Daniel Ruth"
      ],
      [
        1,
        "Jennifer Ruth"
      ],
      [
        1,
        "Liev Schreiber"
      ],
      [
        1,
        "Bruce-Robert Serafin"
      ],
      [
        1,
        "Jamey Sheridan"
      ],
      [
        1,
        "Paulette Sinclair"
      ],
      [
        1,
        "John Slattery"
      ],
      [
        1,
        "Joe Stapleton"
      ],
      [
        1,
        "Donna Sue Jahier"
      ],
      [
        1,
        "Stanley Tucci"
      ],
      [
        1,
        "Nancy Villone"
      ],
      [
        1,
        "Forrest Weber"
      ],
      [
        1,
        "Tim Whalen"
      ],
      [
        1,
        "Elena Wohl"
      ]
    ],
    "directors": "Tom McCarthy",
    "budget_string": "20m USD",
    "gross_string": "98.7m USD",
    "metascore_string": "93%"
  },
  {
    "imdb_id": "tt1392190",
    "plot": "In a post-apocalyptic wasteland, a woman rebels against a tyrannical ruler in search for her homeland with the aid of a group of female prisoners, a psychotic worshiper, and a drifter named Max.",
    "certification": "R",
    "other_wins_count": 241,
    "other_noms_count": 233,
    "amazon": "https://www.amazon.com/Mad-Max-Fury-Road-Blu-ray/dp/B0772BTKS2/ref=sr_1_3?crid=2X4VFHORPJUNS&amp;keywords=mad+max+fury+road+blu+ray&amp;qid=1655183937&amp;sprefix=mad+max+fury+road+blu+ray%252Caps%252C287&amp;sr=8-3&_encoding=UTF8&tag=sianglu-20&linkCode=ur2&linkId=0ba317c58ff6c66fa122da42474449ca&camp=1789&creative=9325",
    "justwatch": "https://justwatch.com/us/movie/mad-max-fury-road?utm_source=share&utm_medium=web",
    "beige_index": 1,
    "beige_angle": 17.334,
    "cast": [
      [
        5,
        "Crusoe Kurddal"
      ],
      [
        4,
        "Zo\u00eb Kravitz"
      ],
      [
        3,
        "Elizabeth Cunico"
      ],
      [
        2,
        "Megan Gale"
      ],
      [
        2,
        "Courtney Eaton"
      ],
      [
        2,
        "Hiroshi Kasuga"
      ],
      [
        1,
        "Iota"
      ],
      [
        1,
        "Darren Andrew Mitchell"
      ],
      [
        1,
        "Richard Carter"
      ],
      [
        1,
        "Cass Cumerford"
      ],
      [
        1,
        "Sebastian Dickins"
      ],
      [
        1,
        "Stephen Dunlevy"
      ],
      [
        1,
        "Jennifer Hagan"
      ],
      [
        1,
        "Tom Hardy"
      ],
      [
        1,
        "Josh Helman"
      ],
      [
        1,
        "Nicholas Hoult"
      ],
      [
        1,
        "John Howard"
      ],
      [
        1,
        "Rosie Huntington-Whiteley"
      ],
      [
        1,
        "Jon Iles"
      ],
      [
        1,
        "Coco Jack Gillies"
      ],
      [
        1,
        "Melissa Jaffer"
      ],
      [
        1,
        "Nathan Jones"
      ],
      [
        1,
        "Robert Jones"
      ],
      [
        1,
        "Gillian Jones"
      ],
      [
        1,
        "Melita Jurisic"
      ],
      [
        1,
        "Hugh Keays-Byrne"
      ],
      [
        1,
        "Antoinette Kellermann"
      ],
      [
        1,
        "Quentin Kenihan"
      ],
      [
        1,
        "Riley Keough"
      ],
      [
        1,
        "Christina Koch"
      ],
      [
        1,
        "Abbey Lee"
      ],
      [
        1,
        "Russ McCarroll"
      ],
      [
        1,
        "Richard Norton"
      ],
      [
        1,
        "Chris Patton"
      ],
      [
        1,
        "Lee Perry"
      ],
      [
        1,
        "Vincent Roxburgh"
      ],
      [
        1,
        "Angus Sampson"
      ],
      [
        1,
        "Ben Smith-Petersen"
      ],
      [
        1,
        "Joy Smithers"
      ],
      [
        1,
        "Hunter Stratton Boland"
      ],
      [
        1,
        "Charlize Theron"
      ],
      [
        1,
        "Macyn Van Borssum"
      ],
      [
        1,
        "Greg van Borssum"
      ],
      [
        1,
        "John Walton"
      ],
      [
        1,
        "Judd Wild"
      ]
    ],
    "directors": "George Miller",
    "budget_string": "150m USD",
    "gross_string": "375.7m USD",
    "metascore_string": "90%"
  },
  {
    "imdb_id": "tt2096673",
    "plot": "After young Riley is uprooted from her Midwest life and moved to San Francisco, her emotions - Joy, Fear, Anger, Disgust and Sadness - conflict on how best to navigate a new city, house, and school.",
    "certification": "PG",
    "other_wins_count": 100,
    "other_noms_count": 116,
    "amazon": "https://www.amazon.com/INSIDE-OUT-Blu-ray-Amy-Poehler/dp/B07TPRS2Y8/ref=tmm_blu_title_0?_encoding=UTF8&amp;amp&amp;qid=1655180851&amp;amp&amp;sr=1-1&_encoding=UTF8&tag=sianglu-20&linkCode=ur2&linkId=beb7e30b4db447681ffa3919fb090311&camp=1789&creative=9325",
    "justwatch": "https://justwatch.com/us/movie/inside-out-2015?utm_source=share&utm_medium=web",
    "beige_index": 2,
    "beige_angle": 36.8037,
    "cast": [
      [
        5,
        "Dani Dare"
      ],
      [
        5,
        "Dara Iruka"
      ],
      [
        5,
        "Dawnn Lewis"
      ],
      [
        5,
        "Mindy Kaling"
      ],
      [
        4,
        "Rashida Jones"
      ],
      [
        3,
        "Carlos Alazraqui"
      ],
      [
        3,
        "Ronnie Del Carmen"
      ],
      [
        3,
        "Evan Hudak"
      ],
      [
        3,
        "Emma Hudak"
      ],
      [
        3,
        "Keith Ferguson"
      ],
      [
        2,
        "Kaitlyn Dias"
      ],
      [
        1,
        "Flea"
      ],
      [
        1,
        "Bret 'Brook' Parker"
      ],
      [
        1,
        "Lori Alan"
      ],
      [
        1,
        "Gregg Berger"
      ],
      [
        1,
        "Lewis Black"
      ],
      [
        1,
        "Aurora Blue"
      ],
      [
        1,
        "Veronika Bonell"
      ],
      [
        1,
        "Josh Cooley"
      ],
      [
        1,
        "Lola Cooley"
      ],
      [
        1,
        "John Cygan"
      ],
      [
        1,
        "Andrea Datzman"
      ],
      [
        1,
        "Pete Docter"
      ],
      [
        1,
        "Tony Fucile"
      ],
      [
        1,
        "Teresa Ganzel"
      ],
      [
        1,
        "Mary Gibbs"
      ],
      [
        1,
        "Dave Goelz"
      ],
      [
        1,
        "Bill Hader"
      ],
      [
        1,
        "Randy Hahn"
      ],
      [
        1,
        "Jess Harnell"
      ],
      [
        1,
        "Carter Hastings"
      ],
      [
        1,
        "Jacob Hopkins"
      ],
      [
        1,
        "Molly Jackson"
      ],
      [
        1,
        "Daniella Jones"
      ],
      [
        1,
        "Richard Kind"
      ],
      [
        1,
        "Elissa Knight"
      ],
      [
        1,
        "Diane Lane"
      ],
      [
        1,
        "Erik Langley"
      ],
      [
        1,
        "Kyle MacLachlan"
      ],
      [
        1,
        "Danny Mann"
      ],
      [
        1,
        "Bobby Moynihan"
      ],
      [
        1,
        "Laraine Newman"
      ],
      [
        1,
        "Frank Oz"
      ],
      [
        1,
        "Paula Pell"
      ],
      [
        1,
        "Nick Pitera"
      ],
      [
        1,
        "Amy Poehler"
      ],
      [
        1,
        "Paula Poundstone"
      ],
      [
        1,
        "Jan Rabson"
      ],
      [
        1,
        "John Ratzenberger"
      ],
      [
        1,
        "Peter Sagal"
      ],
      [
        1,
        "Patrick Seitz"
      ],
      [
        1,
        "Harry Shearer"
      ],
      [
        1,
        "Phyllis Smith"
      ],
      [
        1,
        "Paris Van Dyke"
      ],
      [
        1,
        "Lennon Wynn"
      ],
      [
        1,
        "Dashell Zamm"
      ]
    ],
    "directors": "Pete Docter & Ronnie Del Carmen",
    "budget_string": "175m USD",
    "gross_string": "858.8m USD",
    "metascore_string": "94%"
  },
  {
    "imdb_id": "tt5311514",
    "plot": "Two strangers find themselves linked in a bizarre way. When a connection forms, will distance be the only thing to keep them apart?",
    "certification": "PG",
    "other_wins_count": 16,
    "other_noms_count": 26,
    "amazon": "https://www.amazon.com/Your-Name-Blu-ray-Michael-Sinterniklaas/dp/B074R4KSTM/ref=sr_1_1_sspa?crid=2LM0WBXM139RW&amp;keywords=your+name&amp;qid=1654844233&amp;s=movies-tv&amp;sprefix=your+name%252Cmovies-tv%252C752&amp;sr=1-1-spons&amp;psc=1&amp;spLa=ZW5jcnlwdGVkUXVhbGlmaWVyPUEzNzlDMTFZMUJVSVIxJmVuY3J5cHRlZElkPUEwNzAzNDk4MTNMWU9TUjdISlZGMCZlbmNyeXB0ZWRBZElkPUEwMDQ4MDk5UUg0S0VIWFFDODc3JndpZGdldE5hbWU9c3BfYXRmJmFjdGlvbj1jbGlja1JlZGlyZWN0JmRvTm90TG9nQ2xpY2s9dHJ1ZQ==&_encoding=UTF8&tag=sianglu-20&linkCode=ur2&linkId=7ca9dd3d4ce3016993e25850940b6382&camp=1789&creative=9325",
    "justwatch": "https://justwatch.com/us/movie/your-name?utm_source=share&utm_medium=web",
    "beige_index": 2,
    "beige_angle": 45.0,
    "cast": [
      [
        2,
        "Chaf\u00fbrin"
      ],
      [
        2,
        "Shinjir\u00f4 G\u00f4da"
      ],
      [
        2,
        "Shin'ya Hamazoe"
      ],
      [
        2,
        "Manami Hanawa"
      ],
      [
        2,
        "Kana Hanazawa"
      ],
      [
        2,
        "Nodoka Hasegawa"
      ],
      [
        2,
        "Tamari Hinata"
      ],
      [
        2,
        "Suguru Inoue"
      ],
      [
        2,
        "Kazuhiko Inoue"
      ],
      [
        2,
        "Kaito Ishikawa"
      ],
      [
        2,
        "Ry\u00fbnosuke Kamiki"
      ],
      [
        2,
        "Mone Kamishiraishi"
      ],
      [
        2,
        "Yasuhiro Kikuchi"
      ],
      [
        2,
        "Hiroki Matsukawa"
      ],
      [
        2,
        "Tsuyoshi Minamijima"
      ],
      [
        2,
        "Miho Morisaki"
      ],
      [
        2,
        "Tatsuya Murakami"
      ],
      [
        2,
        "Takayuki Nakatsukasa"
      ],
      [
        2,
        "Y\u00f4hei Namekawa"
      ],
      [
        2,
        "Ry\u00f4 Narita"
      ],
      [
        2,
        "Shouko Negoro"
      ],
      [
        2,
        "Sayaka \u00d4hara"
      ],
      [
        2,
        "Haruka Ominami"
      ],
      [
        2,
        "Takashi Onozuka"
      ],
      [
        2,
        "Aika Oomae"
      ],
      [
        2,
        "Kanami Sat\u00f4"
      ],
      [
        2,
        "Nobunaga Shimazaki"
      ],
      [
        2,
        "Yuuki Shin"
      ],
      [
        2,
        "Miho Tabata"
      ],
      [
        2,
        "Kanon Tani"
      ],
      [
        2,
        "Yuka Terasaki"
      ],
      [
        2,
        "Masaki Terasoma"
      ],
      [
        2,
        "Eriko Tomioka"
      ],
      [
        2,
        "Miyu Tsuji"
      ],
      [
        2,
        "Ryoko Usami"
      ],
      [
        2,
        "Tomohiro Yamaguchi"
      ],
      [
        2,
        "Eiji Yamamoto"
      ],
      [
        2,
        "Nozomi Yamane"
      ],
      [
        2,
        "Baron Yamazaki"
      ],
      [
        2,
        "Aoi Y\u00fbki"
      ]
    ],
    "directors": "Makoto Shinkai",
    "budget_string": "3.1m USD",
    "gross_string": "358.2m USD",
    "metascore_string": "79%"
  },
  {
    "imdb_id": "tt5074352",
    "plot": "Former wrestler Mahavir Singh Phogat and his two wrestler daughters struggle towards glory at the Commonwealth Games in the face of societal oppression.",
    "certification": "Not Rated",
    "other_wins_count": 29,
    "other_noms_count": 19,
    "amazon": "https://www.amazon.com/Dangal-Aamir-Khan/dp/B06XCKY1Z2/ref=sr_1_1?crid=3QK12ZRKBQPUR&amp;keywords=dangal+movie&amp;qid=1655022993&amp;sprefix=dangal+movie%252Caps%252C282&amp;sr=8-1&_encoding=UTF8&tag=sianglu-20&linkCode=ur2&linkId=041b620d8fba67cc45f5b222935fca8d&camp=1789&creative=9325",
    "justwatch": "https://justwatch.com/us/movie/dangal?utm_source=share&utm_medium=web",
    "beige_index": 5,
    "beige_angle": 150.2856,
    "cast": [
      [
        5,
        "Olamilekan Akanbi Jason"
      ],
      [
        5,
        "Jagbir"
      ],
      [
        5,
        "Ravi Aneja"
      ],
      [
        5,
        "Anurag Arora"
      ],
      [
        5,
        "Mahesh Balraj"
      ],
      [
        5,
        "Vivan Bhatena"
      ],
      [
        5,
        "Suhani Bhatnagar"
      ],
      [
        5,
        "Anmol Charan"
      ],
      [
        5,
        "Karamveer Choudhary"
      ],
      [
        5,
        "Ishika Gagneja"
      ],
      [
        5,
        "Badrul Islam"
      ],
      [
        5,
        "Vaishnavi Kadam"
      ],
      [
        5,
        "Aamir Khan"
      ],
      [
        5,
        "Hafiz Khan"
      ],
      [
        5,
        "Sumit Khanna"
      ],
      [
        5,
        "Aparshakti Khurana"
      ],
      [
        5,
        "Girish Kulkarni"
      ],
      [
        5,
        "Sanya Malhotra"
      ],
      [
        5,
        "Meenu Prajapati"
      ],
      [
        5,
        "Ansh Rathore"
      ],
      [
        5,
        "Ritvik Sahore"
      ],
      [
        5,
        "Fatima Sana Shaikh"
      ],
      [
        5,
        "Shishir Sharma"
      ],
      [
        5,
        "Shivam Sharma"
      ],
      [
        5,
        "Sakshi Tanwar"
      ],
      [
        5,
        "Gurpreet Toti"
      ],
      [
        5,
        "Zaira Wasim"
      ]
    ],
    "directors": "Nitesh Tiwari",
    "budget_string": "10.3m USD",
    "gross_string": "303.7m USD",
    "metascore_string": "N/A"
  },
  {
    "imdb_id": "tt4016934",
    "plot": "A woman is hired as a handmaiden to a Japanese heiress, but secretly she is involved in a plot to defraud her.",
    "certification": "Not Rated",
    "other_wins_count": 69,
    "other_noms_count": 108,
    "amazon": "https://www.amazon.com/The-Handmaiden-Blu-ray/dp/B06XWWS1LK/ref=tmm_blu_swatch_0?_encoding=UTF8&amp;qid=1655194402&amp;sr=1-4&_encoding=UTF8&tag=sianglu-20&linkCode=ur2&linkId=408b86ced7e7da509a9ce91f24e291c3&camp=1789&creative=9325",
    "justwatch": "https://justwatch.com/us/movie/the-handmaiden?utm_source=share&utm_medium=web",
    "beige_index": 2,
    "beige_angle": 44.5095,
    "cast": [
      [
        2,
        "Tomomitsu Adachi"
      ],
      [
        2,
        "Il-Hyuk Bae"
      ],
      [
        2,
        "Jong-Ryul Choi"
      ],
      [
        2,
        "Shinsuke Fujimoto"
      ],
      [
        2,
        "Si-yeon Ha"
      ],
      [
        2,
        "Jeong Ha-dam"
      ],
      [
        2,
        "Kim Hae-sook"
      ],
      [
        2,
        "Chang-Hyun Han"
      ],
      [
        2,
        "Ha-na Han"
      ],
      [
        2,
        "So-Yeon Heo"
      ],
      [
        2,
        "Eun-ji Hong"
      ],
      [
        2,
        "Seo-Yoon Hwang"
      ],
      [
        2,
        "Hyung-tae Im"
      ],
      [
        2,
        "Han-sun Jang"
      ],
      [
        2,
        "In-gyeom Jeong"
      ],
      [
        2,
        "Lee Ji-Ha"
      ],
      [
        2,
        "Lee Ji-hye"
      ],
      [
        2,
        "Cho Jin-woong"
      ],
      [
        2,
        "Eun-hyung Jo"
      ],
      [
        2,
        "Yong-Hyun Jo"
      ],
      [
        2,
        "Ha Jung-woo"
      ],
      [
        2,
        "Takashi Kakizawa"
      ],
      [
        2,
        "Byung-gwi Kim"
      ],
      [
        2,
        "In-su Kim"
      ],
      [
        2,
        "Jong-Dae Kim"
      ],
      [
        2,
        "Eun-yeong Kim"
      ],
      [
        2,
        "Ri-woo Kim"
      ],
      [
        2,
        "Si-eun Kim"
      ],
      [
        2,
        "Jin-Chul Kim"
      ],
      [
        2,
        "In-woo Kim"
      ],
      [
        2,
        "Do-Hyung Kim"
      ],
      [
        2,
        "Joon-woo Kim"
      ],
      [
        2,
        "Soo-Woong Kim"
      ],
      [
        2,
        "Hyeok Kwon"
      ],
      [
        2,
        "In-Chul Lee"
      ],
      [
        2,
        "Yoon-jae Lee"
      ],
      [
        2,
        "Kyu-jung Lee"
      ],
      [
        2,
        "Dong-hwi Lee"
      ],
      [
        2,
        "Yong-nyeo Lee"
      ],
      [
        2,
        "Kim Min-hee"
      ],
      [
        2,
        "Byung Mo Choi"
      ],
      [
        2,
        "Katsuhiro Nagano"
      ],
      [
        2,
        "Gyeong-min Nam"
      ],
      [
        2,
        "Man-Seok Oh"
      ],
      [
        2,
        "Ki-Ryung Park"
      ],
      [
        2,
        "Shin-hye Park"
      ],
      [
        2,
        "Ahn Seong-Bong"
      ],
      [
        2,
        "Moon So-ri"
      ],
      [
        2,
        "Da-eun Song"
      ],
      [
        2,
        "Wook-Hyun Sun"
      ],
      [
        2,
        "Kim Tae-ri"
      ],
      [
        2,
        "Rina Takagi"
      ],
      [
        2,
        "Geun-hee Won"
      ],
      [
        2,
        "Min-chae Yoo"
      ],
      [
        1,
        "Alexander Scarborough"
      ]
    ],
    "directors": "Park Chan-wook",
    "budget_string": "8.5m USD",
    "gross_string": "37.8m USD",
    "metascore_string": "84%"
  },
  {
    "imdb_id": "tt2119532",
    "plot": "World War II American Army Medic Desmond T. Doss, who served during the Battle of Okinawa, refuses to kill people and becomes the first man in American history to receive the Medal of Honor without firing a shot.",
    "certification": "R",
    "other_wins_count": 54,
    "other_noms_count": 115,
    "amazon": "https://www.amazon.com/Hacksaw-Ridge-Andrew-Garfield/dp/B01LTHZVSI/ref=tmm_dvd_swatch_0?_encoding=UTF8&amp;qid=1655183576&amp;sr=8-2&_encoding=UTF8&tag=sianglu-20&linkCode=ur2&linkId=a8037466641898122a7e3d4c538a2d9b&camp=1789&creative=9325",
    "justwatch": "https://justwatch.com/us/movie/hacksaw-ridge?utm_source=share&utm_medium=web",
    "beige_index": 1,
    "beige_angle": 12.6,
    "cast": [
      [
        4,
        "Firass Dirani"
      ],
      [
        3,
        "Nico Cortez"
      ],
      [
        3,
        "Santo Tripodi"
      ],
      [
        2,
        "Taki Abe"
      ],
      [
        2,
        "Honsen Haga"
      ],
      [
        2,
        "Hiroshi Kasuga"
      ],
      [
        2,
        "Yuki Nagashima"
      ],
      [
        2,
        "Ryuzaburo Naruse"
      ],
      [
        2,
        "Kazuaki Ono"
      ],
      [
        2,
        "Nobuaki Shimamoto"
      ],
      [
        2,
        "William Takayanagi-Temm"
      ],
      [
        2,
        "Daisaku Takeda"
      ],
      [
        2,
        "Yoji Tatsuta"
      ],
      [
        2,
        "Toshiyuki Teramoto"
      ],
      [
        2,
        "Taka Uematsu"
      ],
      [
        2,
        "Kazuki Yuyama"
      ],
      [
        1,
        "Georgia Adamson"
      ],
      [
        1,
        "Nathan Baird"
      ],
      [
        1,
        "Helmut Bakaitis"
      ],
      [
        1,
        "John Batziolas"
      ],
      [
        1,
        "Adam Bowes"
      ],
      [
        1,
        "Luke Bracey"
      ],
      [
        1,
        "Laurence Brewer"
      ],
      [
        1,
        "Darcy Bryce"
      ],
      [
        1,
        "Laura Buckton"
      ],
      [
        1,
        "Nathaniel Buzolic"
      ],
      [
        1,
        "John Cannon"
      ],
      [
        1,
        "Tyler Coppin"
      ],
      [
        1,
        "Ryan Corr"
      ],
      [
        1,
        "Nicholas Cowey"
      ],
      [
        1,
        "Goran D. Kleut"
      ],
      [
        1,
        "Josh Dean Williams"
      ],
      [
        1,
        "Raphael Dubois"
      ],
      [
        1,
        "Simon Edds"
      ],
      [
        1,
        "Andrew Garfield"
      ],
      [
        1,
        "Hayden Geens"
      ],
      [
        1,
        "Milo Gibson"
      ],
      [
        1,
        "Harry Greenwood"
      ],
      [
        1,
        "Rachel Griffiths"
      ],
      [
        1,
        "Roman Guerriero"
      ],
      [
        1,
        "Nathan Halls"
      ],
      [
        1,
        "Andrew Hansch"
      ],
      [
        1,
        "Benedict Hardie"
      ],
      [
        1,
        "Michael Hennessy"
      ],
      [
        1,
        "Charles Jacobs"
      ],
      [
        1,
        "Mikael Koski"
      ],
      [
        1,
        "Dennis Kreusler"
      ],
      [
        1,
        "Ned Law"
      ],
      [
        1,
        "James Lugton"
      ],
      [
        1,
        "Sean Lynch"
      ],
      [
        1,
        "James Mackay"
      ],
      [
        1,
        "Benjamin McCann"
      ],
      [
        1,
        "Tim McGarry"
      ],
      [
        1,
        "Luke McMahon"
      ],
      [
        1,
        "Thatcher McMaster"
      ],
      [
        1,
        "Ben Mingay"
      ],
      [
        1,
        "James Moffett"
      ],
      [
        1,
        "Robert Morgan"
      ],
      [
        1,
        "Matt Nable"
      ],
      [
        1,
        "James O'Connell"
      ],
      [
        1,
        "Ben O'Toole"
      ],
      [
        1,
        "Teresa Palmer"
      ],
      [
        1,
        "Sam Parsonson"
      ],
      [
        1,
        "Luke Pegler"
      ],
      [
        1,
        "Ori Pfeffer"
      ],
      [
        1,
        "Troy Pickering"
      ],
      [
        1,
        "Richard Platt"
      ],
      [
        1,
        "Tim Potter"
      ],
      [
        1,
        "Richard Pyros"
      ],
      [
        1,
        "Philip Quast"
      ],
      [
        1,
        "Samuel R. Wright"
      ],
      [
        1,
        "Craig Reeves"
      ],
      [
        1,
        "Anthony Rizzo"
      ],
      [
        1,
        "Jim Robison"
      ],
      [
        1,
        "Richard Roxburgh"
      ],
      [
        1,
        "Andrew Sears"
      ],
      [
        1,
        "Michael Sheasby"
      ],
      [
        1,
        "Kasia Stelmach"
      ],
      [
        1,
        "Eric Taugherbeck"
      ],
      [
        1,
        "Damien Thomlinson"
      ],
      [
        1,
        "Daniel Thone"
      ],
      [
        1,
        "Jarin Towney"
      ],
      [
        1,
        "Adrian Twigg"
      ],
      [
        1,
        "Thomas Unger"
      ],
      [
        1,
        "Charles Upton"
      ],
      [
        1,
        "Vince Vaughn"
      ],
      [
        1,
        "Jacob Warner"
      ],
      [
        1,
        "Hugo Weaving"
      ],
      [
        1,
        "Sam Worthington"
      ],
      [
        1,
        "Bill Young"
      ]
    ],
    "directors": "Mel Gibson",
    "budget_string": "40m USD",
    "gross_string": "180.6m USD",
    "metascore_string": "71%"
  },
  {
    "imdb_id": "tt2380307",
    "plot": "Aspiring musician Miguel, confronted with his family's ancestral ban on music, enters the Land of the Dead to find his great-great-grandfather, a legendary singer.",
    "certification": "PG",
    "other_wins_count": 107,
    "other_noms_count": 40,
    "amazon": "https://www.amazon.com/COCO-Blu-ray-Anthony-Gonzalez/dp/B07885CY3T/ref=tmm_blu_swatch_0?_encoding=UTF8&amp;qid=1654843325&amp;sr=1-4&_encoding=UTF8&tag=sianglu-20&linkCode=ur2&linkId=98bb6dac2526df87df04866306aec290&camp=1789&creative=9325",
    "justwatch": "https://justwatch.com/us/movie/coco?utm_source=share&utm_medium=web",
    "beige_index": 3,
    "beige_angle": 98.6094,
    "cast": [
      [
        3,
        "Blanca Araceli"
      ],
      [
        3,
        "Alfonso Arau"
      ],
      [
        3,
        "Lombardo Boyar"
      ],
      [
        3,
        "Jaime Camil"
      ],
      [
        3,
        "Natalia Cordova-Buckley"
      ],
      [
        3,
        "Sof\u00eda Espinosa"
      ],
      [
        3,
        "Gael Garc\u00eda Bernal"
      ],
      [
        3,
        "Anthony Gonzalez"
      ],
      [
        3,
        "Gabriel Iglesias"
      ],
      [
        3,
        "Edward James Olmos"
      ],
      [
        3,
        "Selene Luna"
      ],
      [
        3,
        "Cheech Marin"
      ],
      [
        3,
        "Carla Medina"
      ],
      [
        3,
        "Ana Ofelia Murgu\u00eda"
      ],
      [
        3,
        "Dyana Ortelli"
      ],
      [
        3,
        "Salvador Reyes"
      ],
      [
        3,
        "Herbert Siguenza"
      ],
      [
        3,
        "Octavio Solis"
      ],
      [
        3,
        "Alanna Ubach"
      ],
      [
        3,
        "Luis Valdez"
      ],
      [
        3,
        "Renee Victor"
      ],
      [
        2,
        "Benjamin Bratt"
      ],
      [
        1,
        "John Ratzenberger"
      ]
    ],
    "directors": "Lee Unkrich & Adrian Molina",
    "budget_string": "175m USD",
    "gross_string": "807.8m USD",
    "metascore_string": "81%"
  },
  {
    "imdb_id": "tt3315342",
    "plot": "In a future where mutants are nearly extinct, an elderly and weary Logan leads a quiet life. But when Laura, a mutant child pursued by scientists, comes to him for help, he must get her to safety.",
    "certification": "R",
    "other_wins_count": 28,
    "other_noms_count": 79,
    "amazon": "https://www.amazon.com/Logan-Blu-ray-Hugh-Jackman/dp/B073VZCVX9/ref=tmm_blu_title_0?_encoding=UTF8&amp;qid=1655185073&amp;sr=1-5&_encoding=UTF8&tag=sianglu-20&linkCode=ur2&linkId=6109dc571ab2b9dd6fad2e51043277a5&camp=1789&creative=9325",
    "justwatch": "https://justwatch.com/us/movie/logan?utm_source=share&utm_medium=web",
    "beige_index": 2,
    "beige_angle": 52.9659,
    "cast": [
      [
        5,
        "Quincy Fouse"
      ],
      [
        5,
        "Eriq La Salle"
      ],
      [
        5,
        "James Moses Black"
      ],
      [
        5,
        "Elise Neal"
      ],
      [
        5,
        "Bryant Tardy"
      ],
      [
        4,
        "Joe Nin Williams"
      ],
      [
        4,
        "Eyad Elbitar"
      ],
      [
        4,
        "Ned Yousef"
      ],
      [
        3,
        "Panuvat Anthony Nanakornpanom"
      ],
      [
        3,
        "Salef Celiz"
      ],
      [
        3,
        "Al Coronel"
      ],
      [
        3,
        "Anthony Escobar"
      ],
      [
        3,
        "Alison Fernandez"
      ],
      [
        3,
        "Frank Gallegos"
      ],
      [
        3,
        "Reynaldo Gallegos"
      ],
      [
        3,
        "Jason Genao"
      ],
      [
        3,
        "Jimmy Gonzales"
      ],
      [
        3,
        "Daniel Hernandez"
      ],
      [
        3,
        "Doris Morgado"
      ],
      [
        3,
        "Elizabeth Rodriguez"
      ],
      [
        3,
        "Chester Rushing"
      ],
      [
        3,
        "Bryan Sloyer"
      ],
      [
        3,
        "Robert Vargas"
      ],
      [
        3,
        "Ashlyn Casalegno"
      ],
      [
        3,
        "Chase Cubia"
      ],
      [
        3,
        "Evan D. Taylor"
      ],
      [
        3,
        "Haley Glass"
      ],
      [
        3,
        "Craig Henningsen"
      ],
      [
        3,
        "Toby Holguin"
      ],
      [
        3,
        "Baxter Humby"
      ],
      [
        3,
        "Noell Jellison"
      ],
      [
        3,
        "Aidan Kennedy"
      ],
      [
        3,
        "Rissa Kilar"
      ],
      [
        3,
        "Vincenzo Lucatorto"
      ],
      [
        3,
        "Victor Winters-Junco"
      ],
      [
        2,
        "Rocky Abou-Sakher"
      ],
      [
        2,
        "Andrew Arrabito"
      ],
      [
        2,
        "Michael Lehr"
      ],
      [
        2,
        "Han Soto"
      ],
      [
        2,
        "Emma Teo"
      ],
      [
        2,
        "Phi Vu"
      ],
      [
        2,
        "Robert Wu"
      ],
      [
        1,
        "Garrett Hammond"
      ],
      [
        1,
        "Paul Andrew O'Connor"
      ],
      [
        1,
        "Katie Anne Mitchell"
      ],
      [
        1,
        "Mark Ashworth"
      ],
      [
        1,
        "John Bernecker"
      ],
      [
        1,
        "Daniel Bernhardt"
      ],
      [
        1,
        "Maureen Brennan"
      ],
      [
        1,
        "Damon Carney"
      ],
      [
        1,
        "Jean Claude Leuyer"
      ],
      [
        1,
        "Vanessa Cloke"
      ],
      [
        1,
        "Dave Davis"
      ],
      [
        1,
        "Kelton DuMont"
      ],
      [
        1,
        "Stephen Dunlevy"
      ],
      [
        1,
        "Richard E. Grant"
      ],
      [
        1,
        "Jeremy Fitzgerald"
      ],
      [
        1,
        "Lara Grice"
      ],
      [
        1,
        "Jef Groff"
      ],
      [
        1,
        "Lauren Gros"
      ],
      [
        1,
        "James Handy"
      ],
      [
        1,
        "Luke Hawx"
      ],
      [
        1,
        "Boyd Holbrook"
      ],
      [
        1,
        "Hugh Jackman"
      ],
      [
        1,
        "Sebastian James"
      ],
      [
        1,
        "Keith Jardine"
      ],
      [
        1,
        "David Kallaway"
      ],
      [
        1,
        "Dafne Keen"
      ],
      [
        1,
        "Lennie Loftin"
      ],
      [
        1,
        "Parker Lovein"
      ],
      [
        1,
        "Aaron Matthews"
      ],
      [
        1,
        "Matthew McClain"
      ],
      [
        1,
        "Brandon Melendy"
      ],
      [
        1,
        "Stephen Merchant"
      ],
      [
        1,
        "Nayah Murphy"
      ],
      [
        1,
        "Mali O'Connor"
      ],
      [
        1,
        "Chris Palermo"
      ],
      [
        1,
        "David Paris"
      ],
      [
        1,
        "John Raymond"
      ],
      [
        1,
        "Clinton Roberts"
      ],
      [
        1,
        "Bryce Romero"
      ],
      [
        1,
        "David Simpson"
      ],
      [
        1,
        "Krzysztof Soszynski"
      ],
      [
        1,
        "Patrick Stewart"
      ],
      [
        1,
        "Ryan Sturz"
      ],
      [
        1,
        "Hannah Westerfield"
      ],
      [
        1,
        "Hudson Wright"
      ]
    ],
    "directors": "James Mangold",
    "budget_string": "97m USD",
    "gross_string": "619.2m USD",
    "metascore_string": "77%"
  },
  {
    "imdb_id": "tt5027774",
    "plot": "A mother personally challenges the local authorities to solve her daughter's murder when they fail to catch the culprit.",
    "certification": "R",
    "other_wins_count": 131,
    "other_noms_count": 235,
    "amazon": "https://www.amazon.com/Billboards-Outside-Missouri-Blu-ray-Digital/dp/B0788WSVFW/ref=tmm_blu_title_0?_encoding=UTF8&amp;qid=1655181410&amp;sr=8-2&_encoding=UTF8&tag=sianglu-20&linkCode=ur2&linkId=180ecea22e5cf7b4ee91f26b5d262461&camp=1789&creative=9325",
    "justwatch": "https://justwatch.com/us/movie/three-billboards-outside-ebbing-missouri?utm_source=share&utm_medium=web",
    "beige_index": 2,
    "beige_angle": 53.3997,
    "cast": [
      [
        5,
        "Darrell Britt-Gibson"
      ],
      [
        5,
        "Marcus Lyle Brown"
      ],
      [
        5,
        "Clarke Peters"
      ],
      [
        5,
        "Eleanor T. Threatt"
      ],
      [
        5,
        "Amanda Warren"
      ],
      [
        3,
        "Alejandro Barrios"
      ],
      [
        3,
        "Jason Ledford"
      ],
      [
        3,
        "Michael Aaron Milligan"
      ],
      [
        3,
        "Malaya Rivera Drew"
      ],
      [
        1,
        "Selah Atwood"
      ],
      [
        1,
        "Allyssa Barley"
      ],
      [
        1,
        "Christopher Berry"
      ],
      [
        1,
        "Kerry Condon"
      ],
      [
        1,
        "Abbie Cornish"
      ],
      [
        1,
        "Peter Dinklage"
      ],
      [
        1,
        "Woody Harrelson"
      ],
      [
        1,
        "John Hawkes"
      ],
      [
        1,
        "Lucas Hedges"
      ],
      [
        1,
        "Zeljko Ivanek"
      ],
      [
        1,
        "William J. Harrison"
      ],
      [
        1,
        "Caleb Landry Jones"
      ],
      [
        1,
        "Sandy Martin"
      ],
      [
        1,
        "Riya May Atwood"
      ],
      [
        1,
        "Frances McDormand"
      ],
      [
        1,
        "Gregory Nassif St. John"
      ],
      [
        1,
        "Kathryn Newton"
      ],
      [
        1,
        "Sam Rockwell"
      ],
      [
        1,
        "Brendan Sexton III"
      ],
      [
        1,
        "Samara Weaving"
      ],
      [
        1,
        "Jerry Winsett"
      ]
    ],
    "directors": "Martin McDonagh",
    "budget_string": "15m USD",
    "gross_string": "160.3m USD",
    "metascore_string": "88%"
  },
  {
    "imdb_id": "tt4154756",
    "plot": "The Avengers and their allies must be willing to sacrifice all in an attempt to defeat the powerful Thanos before his blitz of devastation and ruin puts an end to the universe.",
    "certification": "PG-13",
    "other_wins_count": 46,
    "other_noms_count": 78,
    "amazon": "https://www.amazon.com/AVENGERS-INFINITY-Robert-Downey-Jr/dp/B07BZC5KHW/ref=sr_1_3?crid=ZSG68YQN5U6J&amp;keywords=avengers+infinity+war&amp;qid=1654842232&amp;s=movies-tv&amp;sprefix=avengers+infinity+wa%252Cmovies-tv%252C857&amp;sr=1-3&_encoding=UTF8&tag=sianglu-20&linkCode=ur2&linkId=714eb45d667131af4348652a626bccda&camp=1789&creative=9325",
    "justwatch": "https://justwatch.com/us/movie/avengers-infinity-war?utm_source=share&utm_medium=web",
    "beige_index": 2,
    "beige_angle": 61.8156,
    "cast": [
      [
        5,
        "Michael Anthony Rogers"
      ],
      [
        5,
        "Don Cheadle"
      ],
      [
        5,
        "Winston Duke"
      ],
      [
        5,
        "Idris Elba"
      ],
      [
        5,
        "Danai Gurira"
      ],
      [
        5,
        "Michael James Shaw"
      ],
      [
        5,
        "Ameenah Kaplan"
      ],
      [
        5,
        "Florence Kasumba"
      ],
      [
        5,
        "Samuel L. Jackson"
      ],
      [
        5,
        "Anthony Mackie"
      ],
      [
        5,
        "Olaniyan Thurmon"
      ],
      [
        5,
        "Letitia Wright"
      ],
      [
        5,
        "Zoe Saldana"
      ],
      [
        4,
        "Vin Diesel"
      ],
      [
        3,
        "Jacob Batalon"
      ],
      [
        3,
        "Benicio Del Toro"
      ],
      [
        3,
        "Ariana Greenblatt"
      ],
      [
        2,
        "Ethan Dizon"
      ],
      [
        2,
        "Dave Bautista"
      ],
      [
        2,
        "Tiffany Espensen"
      ],
      [
        2,
        "Benedict Wong"
      ],
      [
        1,
        "Isabella Amara"
      ],
      [
        1,
        "Paul Bettany"
      ],
      [
        1,
        "Chadwick Boseman"
      ],
      [
        1,
        "Josh Brolin"
      ],
      [
        1,
        "Kerry Condon"
      ],
      [
        1,
        "Carrie Coon"
      ],
      [
        1,
        "Bradley Cooper"
      ],
      [
        1,
        "Benedict Cumberbatch"
      ],
      [
        1,
        "Peter Dinklage"
      ],
      [
        1,
        "Robert Downey Jr."
      ],
      [
        1,
        "Chris Evans"
      ],
      [
        1,
        "Monique Ganderton"
      ],
      [
        1,
        "Karen Gillan"
      ],
      [
        1,
        "Sean Gunn"
      ],
      [
        1,
        "Chris Hemsworth"
      ],
      [
        1,
        "Tom Hiddleston"
      ],
      [
        1,
        "Tom Holland"
      ],
      [
        1,
        "William Hurt"
      ],
      [
        1,
        "Blair Jasin"
      ],
      [
        1,
        "Scarlett Johansson"
      ],
      [
        1,
        "Pom Klementieff"
      ],
      [
        1,
        "Aaron Lazar"
      ],
      [
        1,
        "Stan Lee"
      ],
      [
        1,
        "Ross Marquand"
      ],
      [
        1,
        "Stephen McFeely"
      ],
      [
        1,
        "Laura Miller"
      ],
      [
        1,
        "Terry Notary"
      ],
      [
        1,
        "Elizabeth Olsen"
      ],
      [
        1,
        "Gwyneth Paltrow"
      ],
      [
        1,
        "Robert Pralgo"
      ],
      [
        1,
        "Chris Pratt"
      ],
      [
        1,
        "Mark Ruffalo"
      ],
      [
        1,
        "Cobie Smulders"
      ],
      [
        1,
        "Sebastian Stan"
      ],
      [
        1,
        "Tom Vaughan-Lawlor"
      ],
      [
        1,
        "Matthew Zuk"
      ]
    ],
    "directors": "Anthony Russo & Joe Russo",
    "budget_string": "321m USD",
    "gross_string": "2048.4m USD",
    "metascore_string": "68%"
  },
  {
    "imdb_id": "tt6966692",
    "plot": "A working-class Italian-American bouncer becomes the driver of an African-American classical pianist on a tour of venues through the 1960s American South.",
    "certification": "PG-13",
    "other_wins_count": 56,
    "other_noms_count": 124,
    "amazon": "https://www.amazon.com/Green-Book-Blu-ray-Viggo-Mortensen/dp/B086PSMVZX/ref=sr_1_1?crid=12O8BY8XEZLVB&amp;keywords=green+book+blu+ray&amp;qid=1655169640&amp;sprefix=green+book+blu+ra%252Caps%252C289&amp;sr=8-1&_encoding=UTF8&tag=sianglu-20&linkCode=ur2&linkId=25cd332f68f1462469e9f78f6fd976f5&camp=1789&creative=9325",
    "justwatch": "https://justwatch.com/us/movie/green-book?utm_source=share&utm_medium=web",
    "beige_index": 2,
    "beige_angle": 51.6465,
    "cast": [
      [
        5,
        "Mahershala Ali"
      ],
      [
        5,
        "Martin Bats Bradford"
      ],
      [
        5,
        "Gralen Bryant Banks"
      ],
      [
        5,
        "Brian Distance"
      ],
      [
        5,
        "Ron Flagge"
      ],
      [
        5,
        "Kenneth Israel"
      ],
      [
        5,
        "Sam Malone"
      ],
      [
        5,
        "Floyd Miles"
      ],
      [
        5,
        "Montrel Miller"
      ],
      [
        5,
        "Ninja N. Devoe"
      ],
      [
        5,
        "Derrick Spears"
      ],
      [
        5,
        "Iqbal Theba"
      ],
      [
        4,
        "Suehyla El-Attar Young"
      ],
      [
        3,
        "Randal Gonzalez"
      ],
      [
        3,
        "Kermit Burns III"
      ],
      [
        3,
        "Peter Gabb"
      ],
      [
        2,
        "David An"
      ],
      [
        1,
        "Billy Breed"
      ],
      [
        1,
        "Lindsay Brice"
      ],
      [
        1,
        "P.J. Byrne"
      ],
      [
        1,
        "Linda Cardellini"
      ],
      [
        1,
        "Leslie Castay"
      ],
      [
        1,
        "Mike Cerrone"
      ],
      [
        1,
        "Joe Cortese"
      ],
      [
        1,
        "Dimiter D. Marinov"
      ],
      [
        1,
        "Craig DiFrancia"
      ],
      [
        1,
        "Don DiPetta"
      ],
      [
        1,
        "Quinn Duffy"
      ],
      [
        1,
        "Hudson Galloway"
      ],
      [
        1,
        "Daniel Greene"
      ],
      [
        1,
        "Mike Hatton"
      ],
      [
        1,
        "Brian Hayes Currie"
      ],
      [
        1,
        "Ted Huckabee"
      ],
      [
        1,
        "Seth Hurwitz"
      ],
      [
        1,
        "David Kallaway"
      ],
      [
        1,
        "Jim Klock"
      ],
      [
        1,
        "Sharon Landry"
      ],
      [
        1,
        "Jenna Laurenzo"
      ],
      [
        1,
        "Von Lewis"
      ],
      [
        1,
        "Gavin Lyle Foley"
      ],
      [
        1,
        "Anthony Mangano"
      ],
      [
        1,
        "Sebastian Maniscalco"
      ],
      [
        1,
        "Jon Michael Davis"
      ],
      [
        1,
        "Viggo Mortensen"
      ],
      [
        1,
        "Ricky Muse"
      ],
      [
        1,
        "Maggie Nixon"
      ],
      [
        1,
        "Shane Partlow"
      ],
      [
        1,
        "Dane Rhodes"
      ],
      [
        1,
        "Gertrud Sigle"
      ],
      [
        1,
        "Christina Simpkins"
      ],
      [
        1,
        "David Simpson"
      ],
      [
        1,
        "Geraldine Singer"
      ],
      [
        1,
        "Paul Sloan"
      ],
      [
        1,
        "Jon Sortland"
      ],
      [
        1,
        "Don Stark"
      ],
      [
        1,
        "Brian Stepanek"
      ],
      [
        1,
        "Harrison Stone"
      ],
      [
        1,
        "Rodolfo Vallelonga"
      ],
      [
        1,
        "Frank Vallelonga"
      ],
      [
        1,
        "Nick Vallelonga"
      ],
      [
        1,
        "Louis Venere"
      ],
      [
        1,
        "Tom Virtue"
      ],
      [
        1,
        "James W. Evermore"
      ],
      [
        1,
        "Dennis W. Hall"
      ],
      [
        1,
        "Johnny Williams"
      ]
    ],
    "directors": "Peter Farrelly",
    "budget_string": "23m USD",
    "gross_string": "321.8m USD",
    "metascore_string": "69%"
  },
  {
    "imdb_id": "tt8267604",
    "plot": "While serving a five-year sentence for a violent crime, a 12-year-old boy sues his parents for neglect.",
    "certification": "R",
    "other_wins_count": 35,
    "other_noms_count": 52,
    "amazon": "https://www.amazon.com/Capernaum-Blu-ray-Nadine-Labaki/dp/B07NBPSN7F/ref=tmm_blu_swatch_0?_encoding=UTF8&amp;qid=1654844834&amp;sr=1-2&_encoding=UTF8&tag=sianglu-20&linkCode=ur2&linkId=69217f67a99050342ee00273acf4f87c&camp=1789&creative=9325",
    "justwatch": "https://justwatch.com/us/movie/capernaum?utm_source=share&utm_medium=web",
    "beige_index": 4,
    "beige_angle": 139.5725142857,
    "cast": [
      [
        5,
        "Yordanos Shiferaw"
      ],
      [
        5,
        "Boluwatife Treasure Bankole"
      ],
      [
        4,
        "Renelyn A. Wilson"
      ],
      [
        4,
        "Mohamad Abdellatif"
      ],
      [
        4,
        "Abdo Abdo"
      ],
      [
        4,
        "Hampig Abraham"
      ],
      [
        4,
        "Jamil Ahmad"
      ],
      [
        4,
        "Mohamad Akkar"
      ],
      [
        4,
        "Elias Akobegia"
      ],
      [
        4,
        "Mohammad Al Abdallah"
      ],
      [
        4,
        "Wissam Al Ali"
      ],
      [
        4,
        "Kawsar Al Haddad"
      ],
      [
        4,
        "Sami Al Jamal"
      ],
      [
        4,
        "Zain Al Rafeea"
      ],
      [
        4,
        "Iman Al Rafeea"
      ],
      [
        4,
        "Hussein Al Rafeea"
      ],
      [
        4,
        "Riman Al Rafeea"
      ],
      [
        4,
        "Legamett Alamou"
      ],
      [
        4,
        "Khodr Ali Alkhalaf"
      ],
      [
        4,
        "Fatme Aliammal"
      ],
      [
        4,
        "Najat Amar Moussa"
      ],
      [
        4,
        "Faten Asmar"
      ],
      [
        4,
        "Ahmad Assaf"
      ],
      [
        4,
        "Mohamad Assaf"
      ],
      [
        4,
        "Rosina Awtar"
      ],
      [
        4,
        "Roula Awtar"
      ],
      [
        4,
        "Shahinour Awtar"
      ],
      [
        4,
        "Rima Awter"
      ],
      [
        4,
        "Elean Badrawi"
      ],
      [
        4,
        "Rinalyn Balderamos"
      ],
      [
        4,
        "Merlita Bayo"
      ],
      [
        4,
        "Yemisrach Begasham"
      ],
      [
        4,
        "Lama Begaum"
      ],
      [
        4,
        "Minara Begaum"
      ],
      [
        4,
        "Hassan Ben Qaiss"
      ],
      [
        4,
        "Fe Blanco"
      ],
      [
        4,
        "Anas Breij"
      ],
      [
        4,
        "Mohamad Chabouri"
      ],
      [
        4,
        "Darine Chahine"
      ],
      [
        4,
        "Samira Chalhoub"
      ],
      [
        4,
        "Maryam Chamoun Haddad"
      ],
      [
        4,
        "Mohamad Chbib"
      ],
      [
        4,
        "Alaa Chouchnieh"
      ],
      [
        4,
        "Louais Dandan"
      ],
      [
        4,
        "Mohamad Darwich"
      ],
      [
        4,
        "Nasser Darwich"
      ],
      [
        4,
        "Zakaria Darwich"
      ],
      [
        4,
        "Jasmine Delore"
      ],
      [
        4,
        "Ahmad El Beiruty"
      ],
      [
        4,
        "Mohamad El Beiruty"
      ],
      [
        4,
        "Mohamad El Hallak"
      ],
      [
        4,
        "Nour El Husseini"
      ],
      [
        4,
        "Abdellatif El Joghlob"
      ],
      [
        4,
        "Said El Nachar"
      ],
      [
        4,
        "Asmaa El Razek"
      ],
      [
        4,
        "Rahaf El Razek"
      ],
      [
        4,
        "Ahmad Fahim"
      ],
      [
        4,
        "Ahmad Fakhreddine"
      ],
      [
        4,
        "Hayat Fakhredine"
      ],
      [
        4,
        "Shaden Fakih"
      ],
      [
        4,
        "Barraa' Fares"
      ],
      [
        4,
        "Groupe Franciscain"
      ],
      [
        4,
        "Tespina George Megalides"
      ],
      [
        4,
        "Hussein Ghaddar"
      ],
      [
        4,
        "Ahmad Ghadi"
      ],
      [
        4,
        "Mirna Ghamloushi"
      ],
      [
        4,
        "Jamil Ghosh"
      ],
      [
        4,
        "Abdallah Hajjar"
      ],
      [
        4,
        "Mohammad Hammoud"
      ],
      [
        4,
        "Nabil Hasan Ali"
      ],
      [
        4,
        "Farah Hasno"
      ],
      [
        4,
        "Soeur Helene Seif"
      ],
      [
        4,
        "Tamer Ibrahim"
      ],
      [
        4,
        "Mirna Izzam"
      ],
      [
        4,
        "Cedra Izzam"
      ],
      [
        4,
        "Bahia Jaber"
      ],
      [
        4,
        "Taha Jaber"
      ],
      [
        4,
        "Mary Jane Magracia"
      ],
      [
        4,
        "Murad Jawapra"
      ],
      [
        4,
        "Joseph Jimbazian"
      ],
      [
        4,
        "Abdelsalam Kamal"
      ],
      [
        4,
        "Farah Kanjo"
      ],
      [
        4,
        "Fadi Kassem"
      ],
      [
        4,
        "Mahmoud Kassem"
      ],
      [
        4,
        "Awedis Kerdejian"
      ],
      [
        4,
        "Kevork Kevorkian"
      ],
      [
        4,
        "Elias Khoury"
      ],
      [
        4,
        "Nadine Labaki"
      ],
      [
        4,
        "Joe Maalouf"
      ],
      [
        4,
        "Ghida Majzoub"
      ],
      [
        4,
        "Grace Mikhael"
      ],
      [
        4,
        "Ali Mordaa"
      ],
      [
        4,
        "Michel Moukarzel"
      ],
      [
        4,
        "Majd Nabil Alhalabi"
      ],
      [
        4,
        "Kemet Nado Mongesho"
      ],
      [
        4,
        "Hassan Nassar"
      ],
      [
        4,
        "Ahmad Obeid"
      ],
      [
        4,
        "Chadi Obeid"
      ],
      [
        4,
        "Foutoun Raad"
      ],
      [
        4,
        "Abbas Raafat"
      ],
      [
        4,
        "Saleh Ramadan"
      ],
      [
        4,
        "Serena Safa"
      ],
      [
        4,
        "Zeinab Safwan"
      ],
      [
        4,
        "Mona Saliba"
      ],
      [
        4,
        "Ahmad Shabana"
      ],
      [
        4,
        "Hirot Solomon"
      ],
      [
        4,
        "Michele Suedad"
      ],
      [
        4,
        "Mostafa Taabani"
      ],
      [
        4,
        "Mohamad Tayeh"
      ],
      [
        4,
        "Haymourot Teshome"
      ],
      [
        4,
        "Alexandre Youakim"
      ],
      [
        4,
        "Joseph Younes"
      ],
      [
        4,
        "Fadi Yousef"
      ],
      [
        4,
        "Samir Zaki Hussein"
      ],
      [
        4,
        "Ali Zoghbi"
      ]
    ],
    "directors": "Nadine Labaki",
    "budget_string": "4m USD",
    "gross_string": "64.4m USD",
    "metascore_string": "75%"
  },
  {
    "imdb_id": "tt4633694",
    "plot": "Teen Miles Morales becomes the Spider-Man of his universe, and must join with five spider-powered individuals from other dimensions to stop a threat for all realities.",
    "certification": "PG",
    "other_wins_count": 81,
    "other_noms_count": 57,
    "amazon": "https://www.amazon.com/Spider-Man-Spider-Verse-Blu-ray-Shameik-Moore/dp/6317632464/ref=sr_1_5?crid=U8JSFN23LHP4&amp;keywords=into+the+spider+verse&amp;qid=1654842770&amp;s=movies-tv&amp;sprefix=into+the+spider+verse%252Cmovies-tv%252C580&amp;sr=1-5&_encoding=UTF8&tag=sianglu-20&linkCode=ur2&linkId=895fc8c32be37d814748029d7e7cf1e2&camp=1789&creative=9325",
    "justwatch": "https://justwatch.com/us/movie/spider-man-into-the-spider-verse?utm_source=share&utm_medium=web",
    "beige_index": 3,
    "beige_angle": 96.4458,
    "cast": [
      [
        5,
        "Marvin 'Krondon' Jones III"
      ],
      [
        5,
        "Mahershala Ali"
      ],
      [
        5,
        "Adam Brown"
      ],
      [
        5,
        "Alycia Cooper"
      ],
      [
        5,
        "Terrence Hardy"
      ],
      [
        5,
        "Rif Hutton"
      ],
      [
        5,
        "Shakira Ja'nai Paye"
      ],
      [
        5,
        "Kelby Joseph"
      ],
      [
        5,
        "Harrison Knight"
      ],
      [
        5,
        "Luna Lauren Velez"
      ],
      [
        5,
        "Jason Linere-White"
      ],
      [
        5,
        "Donna Lynn Leavy"
      ],
      [
        5,
        "Jessica Mikayla"
      ],
      [
        5,
        "Shameik Moore"
      ],
      [
        5,
        "Chrystee Pharris"
      ],
      [
        5,
        "Juan Pope"
      ],
      [
        5,
        "Edwin R. Habacon"
      ],
      [
        5,
        "Dennis Singletary"
      ],
      [
        5,
        "Brian Tyree Henry"
      ],
      [
        5,
        "Holly Walker"
      ],
      [
        5,
        "Kim Yarbrough"
      ],
      [
        5,
        "Devika Parikh"
      ],
      [
        5,
        "Nick Jaine"
      ],
      [
        5,
        "Muneeb Rehman"
      ],
      [
        4,
        "June Christopher"
      ],
      [
        4,
        "Zo\u00eb Kravitz"
      ],
      [
        3,
        "Oscar Camacho"
      ],
      [
        3,
        "Juan Carlos Arvelo"
      ],
      [
        3,
        "Joaqu\u00edn Cosio"
      ],
      [
        3,
        "Oscar Isaac"
      ],
      [
        3,
        "Miguel Jiron"
      ],
      [
        3,
        "Natalie Morales"
      ],
      [
        3,
        "Arthur Ortiz"
      ],
      [
        3,
        "Juan Pacheco"
      ],
      [
        3,
        "Jacqueline Pinol"
      ],
      [
        3,
        "Al Rodrigo"
      ],
      [
        3,
        "Michelle Jubilee Gonzalez"
      ],
      [
        3,
        "Ruth Zalduondo"
      ],
      [
        3,
        "Ruby Zalduondo"
      ],
      [
        2,
        "Jorma Taccone"
      ],
      [
        2,
        "Claudia Choi"
      ],
      [
        2,
        "Greta Lee"
      ],
      [
        1,
        "Kimiko Glenn"
      ],
      [
        1,
        "David Applebee"
      ],
      [
        1,
        "Lake Bell"
      ],
      [
        1,
        "Gredel Berrios Calladine"
      ],
      [
        1,
        "Jon Bruno"
      ],
      [
        1,
        "Nicolas Cage"
      ],
      [
        1,
        "Sarah D. Cole"
      ],
      [
        1,
        "Mimi Davila"
      ],
      [
        1,
        "Kathryn Hahn"
      ],
      [
        1,
        "Melanie Haynes"
      ],
      [
        1,
        "Bridget Hoffman"
      ],
      [
        1,
        "Joseph Izzo"
      ],
      [
        1,
        "Jake Johnson"
      ],
      [
        1,
        "Lex Lang"
      ],
      [
        1,
        "Stan Lee"
      ],
      [
        1,
        "Andrew Leviton"
      ],
      [
        1,
        "Post Malone"
      ],
      [
        1,
        "Caitlin McKenna"
      ],
      [
        1,
        "Scott Menville"
      ],
      [
        1,
        "Christopher Miller"
      ],
      [
        1,
        "John Mulaney"
      ],
      [
        1,
        "Courtney Peldon"
      ],
      [
        1,
        "Chris Pine"
      ],
      [
        1,
        "Darcy Rose Byrnes"
      ],
      [
        1,
        "Joseph Sanfelippo"
      ],
      [
        1,
        "Liev Schreiber"
      ],
      [
        1,
        "Justin Shenkarow"
      ],
      [
        1,
        "Warren Sroka"
      ],
      [
        1,
        "Hailee Steinfeld"
      ],
      [
        1,
        "Melissa Sturm"
      ],
      [
        1,
        "Lily Tomlin"
      ],
      [
        1,
        "Carlos Zaragoza"
      ]
    ],
    "directors": "Bob Persichetti, Peter Ramsey, & Rodney Rothman",
    "budget_string": "90m USD",
    "gross_string": "375.5m USD",
    "metascore_string": "87%"
  },
  {
    "imdb_id": "tt6751668",
    "plot": "Greed and class discrimination threaten the newly formed symbiotic relationship between the wealthy Park family and the destitute Kim clan.",
    "certification": "R",
    "other_wins_count": 304,
    "other_noms_count": 271,
    "amazon": "https://www.amazon.com/Parasite-Blu-ray-Kang-Ho-Song/dp/B086PVRDRY/ref=sr_1_3?crid=1O8ATSA23T8FO&amp;keywords=parasite+movie&amp;qid=1654830352&amp;sprefix=parasite%252Caps%252C716&amp;sr=8-3&_encoding=UTF8&tag=sianglu-20&linkCode=ur2&linkId=4e3b8f203df6a6ab687d7e2c47beb036&camp=1789&creative=9325",
    "justwatch": "https://justwatch.com/us/movie/parasite-2019?utm_source=share&utm_medium=web",
    "beige_index": 2,
    "beige_angle": 44.3934,
    "cast": [
      [
        2,
        "Mangchi"
      ],
      [
        2,
        "Ji-yeon"
      ],
      [
        2,
        "Gamja"
      ],
      [
        2,
        "Mungchi"
      ],
      [
        2,
        "Jeong A Reum"
      ],
      [
        2,
        "Dong-hyeon Baek"
      ],
      [
        2,
        "Kim Bo Ryoung"
      ],
      [
        2,
        "Oh Byeol Ha"
      ],
      [
        2,
        "Yu Chae-ryeon"
      ],
      [
        2,
        "Yeo-jeong Cho"
      ],
      [
        2,
        "Woo-sik Choi"
      ],
      [
        2,
        "Lee Do Woon"
      ],
      [
        2,
        "Lee Dong-yong"
      ],
      [
        2,
        "Anna Elisabeth Rihlmann"
      ],
      [
        2,
        "Riccardo Ferraresso"
      ],
      [
        2,
        "Andreas Fronk"
      ],
      [
        2,
        "Park Ga Bin"
      ],
      [
        2,
        "Kim Geon"
      ],
      [
        2,
        "Go Gwan-jae"
      ],
      [
        2,
        "Kim Ha Eun"
      ],
      [
        2,
        "Hee Ha-woun"
      ],
      [
        2,
        "Seo Han Gyeol"
      ],
      [
        2,
        "Seo Hee Young"
      ],
      [
        2,
        "Park Hye Sook"
      ],
      [
        2,
        "Jang Hye-jin"
      ],
      [
        2,
        "Ji Hye-yeong"
      ],
      [
        2,
        "Kim Hyea-ryun"
      ],
      [
        2,
        "Kang Hyun Gyu"
      ],
      [
        2,
        "Kim I-seul"
      ],
      [
        2,
        "Jeong Il-hwa"
      ],
      [
        2,
        "Hwang In Kyung"
      ],
      [
        2,
        "Lee In Kyung"
      ],
      [
        2,
        "Jung Jae Hoon"
      ],
      [
        2,
        "Park Jae Wan"
      ],
      [
        2,
        "Lee Jeong-eun"
      ],
      [
        2,
        "Choi Jeong-hyun"
      ],
      [
        2,
        "Lee Ji-hye"
      ],
      [
        2,
        "Choi Ji-won"
      ],
      [
        2,
        "Jang Ji-woo"
      ],
      [
        2,
        "Kim Jin Hyung"
      ],
      [
        2,
        "An Jing San"
      ],
      [
        2,
        "Jae-myeong Jo"
      ],
      [
        2,
        "Seo Jun Park"
      ],
      [
        2,
        "Yi-Seo Jung"
      ],
      [
        2,
        "Ik-han Jung"
      ],
      [
        2,
        "Hyun-jun Jung"
      ],
      [
        2,
        "Ji-so Jung"
      ],
      [
        2,
        "Kim Jung Woo"
      ],
      [
        2,
        "Kyu-baek Kim"
      ],
      [
        2,
        "Sin-ae Kwak"
      ],
      [
        2,
        "Sun-kyun Lee"
      ],
      [
        2,
        "Eun-hee Lee"
      ],
      [
        2,
        "Joo-hyung Lee"
      ],
      [
        2,
        "Lee Lu A."
      ],
      [
        2,
        "Melinda McDonald"
      ],
      [
        2,
        "Park Mi Ae"
      ],
      [
        2,
        "Han Mi-ja"
      ],
      [
        2,
        "Seol Min"
      ],
      [
        2,
        "Oh Mu Yong"
      ],
      [
        2,
        "Park Myeong-hoon"
      ],
      [
        2,
        "Keun-rok Park"
      ],
      [
        2,
        "JaeWook Park"
      ],
      [
        2,
        "So-dam Park"
      ],
      [
        2,
        "Rosie Peralta"
      ],
      [
        2,
        "Seo Pok-hyun"
      ],
      [
        2,
        "Lee Sang Kyung"
      ],
      [
        2,
        "Jung Se-hyun"
      ],
      [
        2,
        "Kim Se-in"
      ],
      [
        2,
        "Ahn Seong-Bong"
      ],
      [
        2,
        "Baik Seung Hwan"
      ],
      [
        2,
        "Je Seung-hyun"
      ],
      [
        2,
        "Shin Seung-min"
      ],
      [
        2,
        "Lee Si Eun"
      ],
      [
        2,
        "Lee Si Hoo"
      ],
      [
        2,
        "Lee Si-hoon"
      ],
      [
        2,
        "Kang-ho Song"
      ],
      [
        2,
        "Ju Su-ji"
      ],
      [
        2,
        "Shim Su-mi"
      ],
      [
        2,
        "Yang Sun Young"
      ],
      [
        2,
        "Kim Sung Joon"
      ],
      [
        2,
        "Kim Sung Su"
      ],
      [
        2,
        "Sean Thomas"
      ],
      [
        2,
        "Jang Ye-Won"
      ],
      [
        2,
        "Kim Yooin"
      ],
      [
        2,
        "Hye-ri Yoon"
      ],
      [
        2,
        "Young-woo Yoon"
      ],
      [
        2,
        "Kim Young Jo"
      ],
      [
        1,
        "Katrin Maurer"
      ],
      [
        1,
        "Mark Siegmund"
      ]
    ],
    "directors": "Bong Joon Ho",
    "budget_string": "11.4m USD",
    "gross_string": "263.1m USD",
    "metascore_string": "96%"
  },
  {
    "imdb_id": "tt7286456",
    "plot": "A mentally troubled stand-up comedian embarks on a downward spiral that leads to the creation of an iconic villain.",
    "certification": "R",
    "other_wins_count": 120,
    "other_noms_count": 239,
    "amazon": "https://www.amazon.com/Joker-Blu-ray-Joaquin-Phoenix/dp/B07XN4BNN5/ref=sr_1_3?crid=3F3ON68C61MLK&amp;keywords=joker+blu-ray&amp;qid=1654842833&amp;s=movies-tv&amp;sprefix=joker+blu-ra%252Cmovies-tv%252C596&amp;sr=1-3&_encoding=UTF8&tag=sianglu-20&linkCode=ur2&linkId=fd1339ac1cf7d2173fa3063fb4174573&camp=1789&creative=9325",
    "justwatch": "https://justwatch.com/us/movie/joker-2019?utm_source=share&utm_medium=web",
    "beige_index": 2,
    "beige_angle": 55.0161,
    "cast": [
      [
        5,
        "Greer Barnes"
      ],
      [
        5,
        "Mandela Bellamy"
      ],
      [
        5,
        "Kim Brockington"
      ],
      [
        5,
        "Tony D. Head"
      ],
      [
        5,
        "Demetrius Dotson II"
      ],
      [
        5,
        "April Grace"
      ],
      [
        5,
        "Rocco Luna"
      ],
      [
        5,
        "Chris Redd"
      ],
      [
        5,
        "Brian Tyree Henry"
      ],
      [
        5,
        "Sharon Washington"
      ],
      [
        5,
        "Alonzo Wright"
      ],
      [
        4,
        "Zazie Beetz"
      ],
      [
        3,
        "Rich Campbell"
      ],
      [
        3,
        "Damian Emmanuel"
      ],
      [
        3,
        "Vito Gerbino"
      ],
      [
        3,
        "Adam Quezada"
      ],
      [
        3,
        "Troy Roberts"
      ],
      [
        3,
        "Evan Rosado"
      ],
      [
        3,
        "Mike Troll"
      ],
      [
        3,
        "Xavyer Ure\u00f1a"
      ],
      [
        1,
        "Craig Austin"
      ],
      [
        1,
        "Richard Baratta"
      ],
      [
        1,
        "Michael Benz"
      ],
      [
        1,
        "Keith Buterbaugh"
      ],
      [
        1,
        "Bryan Callen"
      ],
      [
        1,
        "Bill Camp"
      ],
      [
        1,
        "John Cenatiempo"
      ],
      [
        1,
        "James Ciccone"
      ],
      [
        1,
        "Frances Conroy"
      ],
      [
        1,
        "Brett Cullen"
      ],
      [
        1,
        "Robert De Niro"
      ],
      [
        1,
        "Michael-Scott Druckenmiller"
      ],
      [
        1,
        "Steven Elson"
      ],
      [
        1,
        "Jane Fergus"
      ],
      [
        1,
        "Glenn Fleshler"
      ],
      [
        1,
        "David Gibson"
      ],
      [
        1,
        "Leigh Gill"
      ],
      [
        1,
        "Hannah Gross"
      ],
      [
        1,
        "Gary Gulman"
      ],
      [
        1,
        "Murphy Guyer"
      ],
      [
        1,
        "Peter Hans Benson"
      ],
      [
        1,
        "Douglas Hodge"
      ],
      [
        1,
        "Ray Iannicelli"
      ],
      [
        1,
        "Sondra James"
      ],
      [
        1,
        "Carrie Louise Putrello"
      ],
      [
        1,
        "Carl Lundstedt"
      ],
      [
        1,
        "Graham Maby"
      ],
      [
        1,
        "Marc Maron"
      ],
      [
        1,
        "Jeff McCarthy"
      ],
      [
        1,
        "Sam Morril"
      ],
      [
        1,
        "Mick O'Rourke"
      ],
      [
        1,
        "Josh Pais"
      ],
      [
        1,
        "Dante Pereira-Olson"
      ],
      [
        1,
        "Joaquin Phoenix"
      ],
      [
        1,
        "Danny Schoch"
      ],
      [
        1,
        "Roger Squitero"
      ],
      [
        1,
        "Mick Szal"
      ],
      [
        1,
        "Ben Warheit"
      ],
      [
        1,
        "Shea Whigham"
      ],
      [
        1,
        "Jack Wilkins"
      ],
      [
        1,
        "Frank Wood"
      ],
      [
        1,
        "Lou Young"
      ]
    ],
    "directors": "Todd Phillips",
    "budget_string": "55m USD",
    "gross_string": "1074.4m USD",
    "metascore_string": "59%"
  },
  {
    "imdb_id": "tt8579674",
    "plot": "April 6th, 1917. As an infantry battalion assembles to wage war deep in enemy territory, two soldiers are assigned to race against time and deliver a message that will stop 1,600 men from walking straight into a deadly trap.",
    "certification": "R",
    "other_wins_count": 131,
    "other_noms_count": 206,
    "amazon": "https://www.amazon.com/1917-Blu-ray-George-MacKay/dp/B082PQH2P7/ref=tmm_blu_swatch_0?_encoding=UTF8&amp;qid=1655008492&amp;sr=8-1&_encoding=UTF8&tag=sianglu-20&linkCode=ur2&linkId=d7aeda7bd550854ea66d04f0716ff075&camp=1789&creative=9325",
    "justwatch": "https://justwatch.com/us/movie/1917?utm_source=share&utm_medium=web",
    "beige_index": 1,
    "beige_angle": 11.2068,
    "cast": [
      [
        5,
        "Elliot Edusah"
      ],
      [
        4,
        "Gabriel Akuwudike"
      ],
      [
        3,
        "Nabhaan Rizwan"
      ],
      [
        1,
        "Benjamin Adams"
      ],
      [
        1,
        "Andy Apollo"
      ],
      [
        1,
        "Daniel Attwell"
      ],
      [
        1,
        "Elliot Baxter"
      ],
      [
        1,
        "Anson Boon"
      ],
      [
        1,
        "Pip Carter"
      ],
      [
        1,
        "Dean-Charles Chapman"
      ],
      [
        1,
        "Phil Cheadle"
      ],
      [
        1,
        "Bradley Connor"
      ],
      [
        1,
        "Samson Cox-Vinell"
      ],
      [
        1,
        "Benedict Cumberbatch"
      ],
      [
        1,
        "Josef Davies"
      ],
      [
        1,
        "Richard Dempsey"
      ],
      [
        1,
        "Claire Duburcq"
      ],
      [
        1,
        "Justin Edwards"
      ],
      [
        1,
        "Colin Firth"
      ],
      [
        1,
        "Tommy French"
      ],
      [
        1,
        "Kenny Fullwood"
      ],
      [
        1,
        "John Hollingworth"
      ],
      [
        1,
        "Luke Hornsby"
      ],
      [
        1,
        "Gerran Howell"
      ],
      [
        1,
        "Adam Hugill"
      ],
      [
        1,
        "Jacob James Beswick"
      ],
      [
        1,
        "Michael Jibson"
      ],
      [
        1,
        "Taddeo Kufus"
      ],
      [
        1,
        "Bogdan Kumshatsky"
      ],
      [
        1,
        "Jonny Lavelle"
      ],
      [
        1,
        "Spike Leighton"
      ],
      [
        1,
        "Merlin Leonhardt"
      ],
      [
        1,
        "Robert Maaser"
      ],
      [
        1,
        "George MacKay"
      ],
      [
        1,
        "Ivy-l Macnamara"
      ],
      [
        1,
        "Richard Madden"
      ],
      [
        1,
        "Daniel Mays"
      ],
      [
        1,
        "Richard McCabe"
      ],
      [
        1,
        "Kye Mckee"
      ],
      [
        1,
        "Joe Mendes"
      ],
      [
        1,
        "Ryan Nolan"
      ],
      [
        1,
        "Jamie Parker"
      ],
      [
        1,
        "Billy Postlethwaite"
      ],
      [
        1,
        "Michael Rouse"
      ],
      [
        1,
        "Jonah Russell"
      ],
      [
        1,
        "Adrian Scarborough"
      ],
      [
        1,
        "Andrew Scott"
      ],
      [
        1,
        "Jack Shalloo"
      ],
      [
        1,
        "Jos Slovick"
      ],
      [
        1,
        "Mark Strong"
      ],
      [
        1,
        "Paul Tinto"
      ],
      [
        1,
        "Chris Walley"
      ],
      [
        1,
        "Ian Wilson"
      ]
    ],
    "directors": "Sam Mendes",
    "budget_string": "95m USD",
    "gross_string": "384.9m USD",
    "metascore_string": "78%"
  },
  {
    "imdb_id": "tt1950186",
    "plot": "American car designer Carroll Shelby and driver Ken Miles battle corporate interference and the laws of physics to build a revolutionary race car for Ford in order to defeat Ferrari at the 24 Hours of Le Mans in 1966.",
    "certification": "PG-13",
    "other_wins_count": 23,
    "other_noms_count": 91,
    "amazon": "https://www.amazon.com/Ford-Ferrari-Blu-ray-Matt-Damon/dp/B07Z74Q2B5/ref=tmm_blu_swatch_0?_encoding=UTF8&amp;qid=1655184632&amp;sr=1-1&_encoding=UTF8&tag=sianglu-20&linkCode=ur2&linkId=76c241a7f68003e2fad0507e046c47ce&camp=1789&creative=9325",
    "justwatch": "https://justwatch.com/us/movie/ford-v-ferrari?utm_source=share&utm_medium=web",
    "beige_index": 1,
    "beige_angle": 7.974,
    "cast": [
      [
        5,
        "Lou Beatty Jr."
      ],
      [
        3,
        "Craig Frosty Silva"
      ],
      [
        3,
        "Christopher Darga"
      ],
      [
        2,
        "Kirt Kishita"
      ],
      [
        1,
        "Bonnie Antonini"
      ],
      [
        1,
        "Evan Arnold"
      ],
      [
        1,
        "Peter Arpesella"
      ],
      [
        1,
        "Luka Bale"
      ],
      [
        1,
        "Christian Bale"
      ],
      [
        1,
        "Caitriona Balfe"
      ],
      [
        1,
        "Tim Banning"
      ],
      [
        1,
        "Francesco Bauco"
      ],
      [
        1,
        "Emil Beheshti"
      ],
      [
        1,
        "Jon Bernthal"
      ],
      [
        1,
        "Brad Beyer"
      ],
      [
        1,
        "Lachlan Buchanan"
      ],
      [
        1,
        "Andrew Burlinson"
      ],
      [
        1,
        "Leif Carlgren"
      ],
      [
        1,
        "Sean Carrigan"
      ],
      [
        1,
        "Giovanni Cirfiera"
      ],
      [
        1,
        "Guido Cocomello"
      ],
      [
        1,
        "Ben Collins"
      ],
      [
        1,
        "Darin Cooper"
      ],
      [
        1,
        "Matt Damon"
      ],
      [
        1,
        "Larsen Deane"
      ],
      [
        1,
        "Luiggi Debiasse"
      ],
      [
        1,
        "Elizabeth Dement"
      ],
      [
        1,
        "Vernon Dew"
      ],
      [
        1,
        "Mario Di Donato"
      ],
      [
        1,
        "Angelo Dibello"
      ],
      [
        1,
        "Grace Fae"
      ],
      [
        1,
        "Camillo Faieta"
      ],
      [
        1,
        "JJ Feild"
      ],
      [
        1,
        "Stephane Fiorenza"
      ],
      [
        1,
        "Marc Forget"
      ],
      [
        1,
        "Tanner Foust"
      ],
      [
        1,
        "Paul Fox"
      ],
      [
        1,
        "Gian Franco Tordi"
      ],
      [
        1,
        "Remo Girone"
      ],
      [
        1,
        "Joss Glennie-Smith"
      ],
      [
        1,
        "Alex Gurney"
      ],
      [
        1,
        "Ian Harding"
      ],
      [
        1,
        "Cameron Hennings"
      ],
      [
        1,
        "Ward Horton"
      ],
      [
        1,
        "Corrado Invernizzi"
      ],
      [
        1,
        "Thomas John Rudolph"
      ],
      [
        1,
        "Noah Jupe"
      ],
      [
        1,
        "Mark Krenik"
      ],
      [
        1,
        "Michael Lanahan"
      ],
      [
        1,
        "Wallace Langham"
      ],
      [
        1,
        "Jonathan LaPaglia"
      ],
      [
        1,
        "Cl\u00e9ment Larue"
      ],
      [
        1,
        "Shawn Law"
      ],
      [
        1,
        "Tracy Letts"
      ],
      [
        1,
        "Josh Lucas"
      ],
      [
        1,
        "Rudolf Martin"
      ],
      [
        1,
        "Giles Matthey"
      ],
      [
        1,
        "Adam Mayfield"
      ],
      [
        1,
        "Brad McCabe"
      ],
      [
        1,
        "Jenelle McKee"
      ],
      [
        1,
        "Ray McKinnon"
      ],
      [
        1,
        "Jack McMullen"
      ],
      [
        1,
        "Jan Munroe"
      ],
      [
        1,
        "Wyatt Nash"
      ],
      [
        1,
        "Ryan O'Dell"
      ],
      [
        1,
        "Aylam Orian"
      ],
      [
        1,
        "Marisa Petroro"
      ],
      [
        1,
        "Brent Pontin"
      ],
      [
        1,
        "Darrin Prescott"
      ],
      [
        1,
        "Drew Rausch"
      ],
      [
        1,
        "Benjamin Rigby"
      ],
      [
        1,
        "Arron Shiver"
      ],
      [
        1,
        "Stefania Spampinato"
      ],
      [
        1,
        "Ottavio Taddei"
      ],
      [
        1,
        "James Tappan"
      ],
      [
        1,
        "Jon Ueberroth"
      ],
      [
        1,
        "Joe Williamson"
      ],
      [
        1,
        "Steven Ziel"
      ],
      [
        1,
        "Zack Zublena"
      ]
    ],
    "directors": "James Mangold",
    "budget_string": "97.6m USD",
    "gross_string": "225.5m USD",
    "metascore_string": "81%"
  },
  {
    "imdb_id": "tt4154796",
    "plot": "After the devastating events of Avengers: Infinity War (2018), the universe is in ruins. With the help of remaining allies, the Avengers assemble once more in order to reverse Thanos' actions and restore balance to the universe.",
    "certification": "PG-13",
    "other_wins_count": 70,
    "other_noms_count": 131,
    "amazon": "https://www.amazon.com/AVENGERS-ENDGAME-Blu-ray-Robert-Downey/dp/B07RX2JVBQ/ref=sr_1_6?crid=VT6P660NZT6F&amp;keywords=avengers+endgame&amp;qid=1654843854&amp;s=movies-tv&amp;sprefix=avengers+endgame%252Cmovies-tv%252C857&amp;sr=1-6&_encoding=UTF8&tag=sianglu-20&linkCode=ur2&linkId=9d483ae2d22f8aa42f551c97c9a1a868&camp=1789&creative=9325",
    "justwatch": "https://justwatch.com/us/movie/avengers-endgame?utm_source=share&utm_medium=web",
    "beige_index": 2,
    "beige_angle": 41.3802,
    "cast": [
      [
        5,
        "Angela Bassett"
      ],
      [
        5,
        "Don Cheadle"
      ],
      [
        5,
        "Winston Duke"
      ],
      [
        5,
        "Danai Gurira"
      ],
      [
        5,
        "Michael James Shaw"
      ],
      [
        5,
        "Ameenah Kaplan"
      ],
      [
        5,
        "Samuel L. Jackson"
      ],
      [
        5,
        "Anthony Mackie"
      ],
      [
        5,
        "Yvette Nicole Brown"
      ],
      [
        5,
        "Olaniyan Thurmon"
      ],
      [
        5,
        "Letitia Wright"
      ],
      [
        5,
        "Zoe Saldana"
      ],
      [
        4,
        "Vin Diesel"
      ],
      [
        4,
        "Tessa Thompson"
      ],
      [
        3,
        "Jacob Batalon"
      ],
      [
        3,
        "Maximiliano Hern\u00e1ndez"
      ],
      [
        2,
        "Taika Waititi"
      ],
      [
        2,
        "Dave Bautista"
      ],
      [
        2,
        "Ken Jeong"
      ],
      [
        2,
        "Hye Jin Jang"
      ],
      [
        2,
        "James Lin"
      ],
      [
        2,
        "Hiroyuki Sanada"
      ],
      [
        2,
        "Benedict Wong"
      ],
      [
        1,
        "Ben Sakamoto"
      ],
      [
        1,
        "Michael A. Cook"
      ],
      [
        1,
        "Jackson A. Dunn"
      ],
      [
        1,
        "Hayley Atwell"
      ],
      [
        1,
        "Matthew Berry"
      ],
      [
        1,
        "Russell Bobbitt"
      ],
      [
        1,
        "Chadwick Boseman"
      ],
      [
        1,
        "Josh Brolin"
      ],
      [
        1,
        "Linda Cardellini"
      ],
      [
        1,
        "Jack Champion"
      ],
      [
        1,
        "Kerry Condon"
      ],
      [
        1,
        "Carrie Coon"
      ],
      [
        1,
        "Bradley Cooper"
      ],
      [
        1,
        "Benedict Cumberbatch"
      ],
      [
        1,
        "James D'Arcy"
      ],
      [
        1,
        "Michael Douglas"
      ],
      [
        1,
        "Robert Downey Jr."
      ],
      [
        1,
        "Jennifer Elmore"
      ],
      [
        1,
        "Chris Evans"
      ],
      [
        1,
        "Jon Favreau"
      ],
      [
        1,
        "Emma Fuhrmann"
      ],
      [
        1,
        "Anthony G Breed"
      ],
      [
        1,
        "Monique Ganderton"
      ],
      [
        1,
        "Karen Gillan"
      ],
      [
        1,
        "Patrick Gorman"
      ],
      [
        1,
        "Frank Grillo"
      ],
      [
        1,
        "Sean Gunn"
      ],
      [
        1,
        "Sam Hargrave"
      ],
      [
        1,
        "Chris Hemsworth"
      ],
      [
        1,
        "Tom Hiddleston"
      ],
      [
        1,
        "Tom Holland"
      ],
      [
        1,
        "William Hurt"
      ],
      [
        1,
        "Scarlett Johansson"
      ],
      [
        1,
        "Pom Klementieff"
      ],
      [
        1,
        "Brie Larson"
      ],
      [
        1,
        "Aaron Lazar"
      ],
      [
        1,
        "Loen LeClair"
      ],
      [
        1,
        "Bazlo LeClair"
      ],
      [
        1,
        "Stan Lee"
      ],
      [
        1,
        "Evangeline Lilly"
      ],
      [
        1,
        "Mike Lutz"
      ],
      [
        1,
        "Lia Mariella Russo"
      ],
      [
        1,
        "Ross Marquand"
      ],
      [
        1,
        "Joy McAvoy"
      ],
      [
        1,
        "Brent McGee"
      ],
      [
        1,
        "John Michael Morris"
      ],
      [
        1,
        "Lee Moore"
      ],
      [
        1,
        "Callan Mulvey"
      ],
      [
        1,
        "Terry Notary"
      ],
      [
        1,
        "Elizabeth Olsen"
      ],
      [
        1,
        "Gwyneth Paltrow"
      ],
      [
        1,
        "Taylor Patterson"
      ],
      [
        1,
        "Michelle Pfeiffer"
      ],
      [
        1,
        "Natalie Portman"
      ],
      [
        1,
        "John Posey"
      ],
      [
        1,
        "Robert Pralgo"
      ],
      [
        1,
        "Chris Pratt"
      ],
      [
        1,
        "Lexi Rabe"
      ],
      [
        1,
        "Jimmy Ray Pickens"
      ],
      [
        1,
        "Robert Redford"
      ],
      [
        1,
        "Jeremy Renner"
      ],
      [
        1,
        "Erica Ribley"
      ],
      [
        1,
        "Augie Rosalina"
      ],
      [
        1,
        "Paul Rudd"
      ],
      [
        1,
        "Mark Ruffalo"
      ],
      [
        1,
        "Julian Russo"
      ],
      [
        1,
        "Rene Russo"
      ],
      [
        1,
        "Ava Russo"
      ],
      [
        1,
        "Joe Russo"
      ],
      [
        1,
        "Brian Schaeffer"
      ],
      [
        1,
        "Ty Simpkins"
      ],
      [
        1,
        "John Slattery"
      ],
      [
        1,
        "Cobie Smulders"
      ],
      [
        1,
        "Sebastian Stan"
      ],
      [
        1,
        "Jim Starlin"
      ],
      [
        1,
        "Tilda Swinton"
      ],
      [
        1,
        "Marisa Tomei"
      ],
      [
        1,
        "Tom Vaughan-Lawlor"
      ],
      [
        1,
        "Jamie Wedel"
      ],
      [
        1,
        "Tom Wisdom"
      ],
      [
        1,
        "Cade Woodward"
      ]
    ],
    "directors": "Anthony Russo & Joe Russo",
    "budget_string": "356m USD",
    "gross_string": "2797.5m USD",
    "metascore_string": "78%"
  },
  {
    "imdb_id": "tt8503618",
    "plot": "The real life of one of America's foremost founding fathers and first Secretary of the Treasury, Alexander Hamilton. Captured live on Broadway from the Richard Rodgers Theater with the original Broadway cast.",
    "certification": "PG-13",
    "other_wins_count": 17,
    "other_noms_count": 46,
    "amazon": "https://www.amazon.com/Hamilton-Original-Broadway-Recording-Explicit/dp/B013JLBPGE/ref=sr_1_1?keywords=hamilton+soundtrack&amp;qid=1656409233&amp;sr=8-1&_encoding=UTF8&tag=sianglu-20&linkCode=ur2&linkId=91ab62b9772a41eda426ffbeb0dff00b&camp=1789&creative=9325",
    "justwatch": "https://justwatch.com/au/movie/hamilton?utm_source=share&utm_medium=web",
    "beige_index": 4,
    "beige_angle": 139.2243428571,
    "cast": [
      [
        5,
        "Sasha Hutchings"
      ],
      [
        5,
        "Chris Jackson"
      ],
      [
        5,
        "Sydney James Harcourt"
      ],
      [
        5,
        "Leslie Odom Jr."
      ],
      [
        5,
        "Okieriete Onaodowan"
      ],
      [
        5,
        "Austin Smith"
      ],
      [
        5,
        "Ephraim Sykes"
      ],
      [
        5,
        "Ariana DeBose"
      ],
      [
        4,
        "Jasmine Cephas Jones"
      ],
      [
        4,
        "Daveed Diggs"
      ],
      [
        4,
        "Ren\u00e9e Elise Goldsberry"
      ],
      [
        3,
        "Elizabeth Judd"
      ],
      [
        3,
        "Lin-Manuel Miranda"
      ],
      [
        3,
        "Anthony Ramos"
      ],
      [
        3,
        "Jon Rua"
      ],
      [
        3,
        "Carleigh Bettiol"
      ],
      [
        3,
        "Seth Stewart"
      ],
      [
        1,
        "Phillipa Soo"
      ],
      [
        1,
        "Hope Easterbrook"
      ],
      [
        1,
        "Jonathan Groff"
      ],
      [
        1,
        "Thayne Jasperson"
      ]
    ],
    "directors": "Thomas Kail",
    "budget_string": "N/A",
    "gross_string": "N/A",
    "metascore_string": "90%"
  },
  {
    "imdb_id": "tt10272386",
    "plot": "A man refuses all assistance from his daughter as he ages. As he tries to make sense of his changing circumstances, he begins to doubt his loved ones, his own mind and even the fabric of his reality.",
    "certification": "PG-13",
    "other_wins_count": 33,
    "other_noms_count": 157,
    "amazon": "https://www.amazon.com/The-Father-Blu-ray/dp/B09341VH4P/ref=sr_1_1?crid=XZXAY8ROYH7B&amp;keywords=the+father+blu+ray&amp;qid=1655169505&amp;sprefix=the+father+blu+ray%252Caps%252C292&amp;sr=8-1&_encoding=UTF8&tag=sianglu-20&linkCode=ur2&linkId=765920dad2963ebe88341ff09a62d7e7&camp=1789&creative=9325",
    "justwatch": "https://justwatch.com/us/movie/the-father?utm_source=share&utm_medium=web",
    "beige_index": 1,
    "beige_angle": 27.0,
    "cast": [
      [
        5,
        "Ayesha Dharker"
      ],
      [
        1,
        "Olivia Colman"
      ],
      [
        1,
        "Mark Gatiss"
      ],
      [
        1,
        "Anthony Hopkins"
      ],
      [
        1,
        "Imogen Poots"
      ],
      [
        1,
        "Rufus Sewell"
      ],
      [
        1,
        "Olivia Williams"
      ],
      [
        1,
        "Roman Zeller"
      ]
    ],
    "directors": "Florian Zeller",
    "budget_string": "N/A",
    "gross_string": "24.7m USD",
    "metascore_string": "88%"
  },
  {
    "imdb_id": "tt10872600",
    "plot": "With Spider-Man's identity now revealed, Peter asks Doctor Strange for help. When a spell goes wrong, dangerous foes from other worlds start to appear, forcing Peter to discover what it truly means to be Spider-Man.",
    "certification": "PG-13",
    "other_wins_count": 27,
    "other_noms_count": 55,
    "amazon": "https://www.amazon.com/Spider-Man-No-Way-Home-Blu-ray/dp/B09LRX2TXR/ref=sr_1_2?crid=339MG5CG2YAEW&amp;keywords=spider+man+no+way+home+blu+ray&amp;qid=1655007995&amp;sprefix=spider+man+no+way+home%252Caps%252C295&amp;sr=8-2&_encoding=UTF8&tag=sianglu-20&linkCode=ur2&linkId=9853cf7b1d7ff6f27eb5a05f6a37625d&camp=1789&creative=9325",
    "justwatch": "https://justwatch.com/us/movie/spider-man-no-way-home?utm_source=share&utm_medium=web",
    "beige_index": 2,
    "beige_angle": 66.5001,
    "cast": [
      [
        5,
        "Darnell Appling"
      ],
      [
        5,
        "Hannibal Buress"
      ],
      [
        5,
        "Willie Burton"
      ],
      [
        5,
        "Jwaundace Candece"
      ],
      [
        5,
        "Greg Clarkson"
      ],
      [
        5,
        "Jamie Foxx"
      ],
      [
        5,
        "Dean Meminger"
      ],
      [
        5,
        "Paula Newsome"
      ],
      [
        5,
        "Anisa Nyell Johnson"
      ],
      [
        5,
        "J.B. Smoove"
      ],
      [
        5,
        "Haroon Khan"
      ],
      [
        4,
        "Arian Moayed"
      ],
      [
        4,
        "Zendaya"
      ],
      [
        3,
        "Jacob Batalon"
      ],
      [
        3,
        "Rolando Fernandez"
      ],
      [
        3,
        "Tony Revolori"
      ],
      [
        3,
        "Mary Rivera"
      ],
      [
        3,
        "Kathleen Cardoso"
      ],
      [
        3,
        "B. Clutch Dunlap"
      ],
      [
        3,
        "Minnah Dunlap"
      ],
      [
        3,
        "Zany Dunlap"
      ],
      [
        3,
        "Andrew Dunlap"
      ],
      [
        3,
        "Jonathan Sam"
      ],
      [
        2,
        "Emily Fong"
      ],
      [
        2,
        "Michael Le"
      ],
      [
        2,
        "Regina Ting Chen"
      ],
      [
        2,
        "Benedict Wong"
      ],
      [
        1,
        "Paris Benjamin"
      ],
      [
        1,
        "Gabriella Cila"
      ],
      [
        1,
        "Charlie Cox"
      ],
      [
        1,
        "Benedict Cumberbatch"
      ],
      [
        1,
        "Willem Dafoe"
      ],
      [
        1,
        "Carol Dines"
      ],
      [
        1,
        "Rudy Eisenzopf"
      ],
      [
        1,
        "Jon Favreau"
      ],
      [
        1,
        "Ed Force"
      ],
      [
        1,
        "Andrew Garfield"
      ],
      [
        1,
        "Thomas Haden Church"
      ],
      [
        1,
        "Tom Hardy"
      ],
      [
        1,
        "Mallory Hoff"
      ],
      [
        1,
        "Tom Holland"
      ],
      [
        1,
        "Rhys Ifans"
      ],
      [
        1,
        "Glenn Keogh"
      ],
      [
        1,
        "Gregory Konow"
      ],
      [
        1,
        "Tobey Maguire"
      ],
      [
        1,
        "Robert Mitchel Owenby"
      ],
      [
        1,
        "Alfred Molina"
      ],
      [
        1,
        "Angourie Rice"
      ],
      [
        1,
        "J.K. Simmons"
      ],
      [
        1,
        "Taylor St. Clair"
      ],
      [
        1,
        "Martin Starr"
      ],
      [
        1,
        "Marisa Tomei"
      ],
      [
        1,
        "Ben VanderMey"
      ],
      [
        1,
        "Gary Weeks"
      ]
    ],
    "directors": "Jon Watts",
    "budget_string": "200m USD",
    "gross_string": "1892.7m USD",
    "metascore_string": "71%"
  },
  {
    "imdb_id": "tt15097216",
    "plot": "When a tribal man is arrested for a case of alleged theft, his wife turns to a human-rights lawyer to help bring justice.",
    "certification": "TV-MA",
    "other_wins_count": 3,
    "other_noms_count": 0,
    "amazon": "https://www.amazon.com/Jai-Bhim-Tamil-Suriya/dp/B09KPJHKC5/ref=sr_1_1?crid=YNEUINPYTMYV&amp;keywords=jai+bhim&amp;qid=1656309250&amp;sprefix=jai+bhim+%252Caps%252C269&amp;sr=8-1&_encoding=UTF8&tag=sianglu-20&linkCode=ur2&linkId=7a19a6afbc0b5fed91c70fbb513e4292&camp=1789&creative=9325",
    "justwatch": "https://justwatch.com/us/movie/jai-bhim?utm_source=share&utm_medium=web",
    "beige_index": 5,
    "beige_angle": 149.1428571429,
    "cast": [
      [
        5,
        "Suriya"
      ],
      [
        5,
        "Viji"
      ],
      [
        5,
        "Manikandan"
      ],
      [
        5,
        "Vijaykumar"
      ],
      [
        5,
        "Vennila"
      ],
      [
        5,
        "Venkateshan"
      ],
      [
        5,
        "Velu"
      ],
      [
        5,
        "Vasantha"
      ],
      [
        5,
        "Valli"
      ],
      [
        5,
        "Thenaambal"
      ],
      [
        5,
        "Ilavarasu"
      ],
      [
        5,
        "Jayarao"
      ],
      [
        5,
        "Thangavel"
      ],
      [
        5,
        "Sivakumar"
      ],
      [
        5,
        "Tamizh"
      ],
      [
        5,
        "Kumaravel"
      ],
      [
        5,
        "Shankar"
      ],
      [
        5,
        "Selvi"
      ],
      [
        5,
        "Selvaraj"
      ],
      [
        5,
        "Sathya"
      ],
      [
        5,
        "Rajendran"
      ],
      [
        5,
        "Sakthivel"
      ],
      [
        5,
        "Ranjith"
      ],
      [
        5,
        "Kaleeswaran"
      ],
      [
        5,
        "Ranganathan"
      ],
      [
        5,
        "Ramesh"
      ],
      [
        5,
        "Raja"
      ],
      [
        5,
        "Prashanth"
      ],
      [
        5,
        "Poongodi"
      ],
      [
        5,
        "Parthi"
      ],
      [
        5,
        "Deepa"
      ],
      [
        5,
        "Kaveri"
      ],
      [
        5,
        "Panneer"
      ],
      [
        5,
        "Malar"
      ],
      [
        5,
        "Lakshmi"
      ],
      [
        5,
        "Kanniyappan"
      ],
      [
        5,
        "Kannan"
      ],
      [
        5,
        "Janaki"
      ],
      [
        5,
        "Srikanth"
      ],
      [
        5,
        "Kulothungan"
      ],
      [
        5,
        "Pathman"
      ],
      [
        5,
        "Padmapriya"
      ],
      [
        5,
        "Geetha"
      ],
      [
        5,
        "Ezhumalai"
      ],
      [
        5,
        "Ellappan"
      ],
      [
        5,
        "Rukmani"
      ],
      [
        5,
        "Dhanalakshmi"
      ],
      [
        5,
        "Devi"
      ],
      [
        5,
        "Chitra"
      ],
      [
        5,
        "Chinnarasu"
      ],
      [
        5,
        "Maheshwaran"
      ],
      [
        5,
        "Dayaalan"
      ],
      [
        5,
        "Jaishankar"
      ],
      [
        5,
        "Ravishankar"
      ],
      [
        5,
        "Bhoominathan"
      ],
      [
        5,
        "Brahadeeshwaran"
      ],
      [
        5,
        "Anju"
      ],
      [
        5,
        "Murugesan"
      ],
      [
        5,
        "Anjala"
      ],
      [
        5,
        "Deva"
      ],
      [
        5,
        "Subramani"
      ],
      [
        5,
        "Saravanan"
      ],
      [
        5,
        "Sellavel"
      ],
      [
        5,
        "Yuva"
      ],
      [
        5,
        "Dhandapani"
      ],
      [
        5,
        "Karthik"
      ],
      [
        5,
        "Anandhi"
      ],
      [
        5,
        "Amala"
      ],
      [
        5,
        "Jagir"
      ],
      [
        5,
        "Sivajudasan"
      ],
      [
        5,
        "Ashokan"
      ],
      [
        5,
        "Ganeshan"
      ],
      [
        5,
        "Kamal"
      ],
      [
        5,
        "Balaji"
      ],
      [
        5,
        "Parthasarathy"
      ],
      [
        5,
        "Sathish"
      ],
      [
        5,
        "Harikrishnan"
      ],
      [
        5,
        "Murugan"
      ],
      [
        5,
        "Ramasubramanyan"
      ],
      [
        5,
        "Thiagarajan"
      ],
      [
        5,
        "Aarumugam"
      ],
      [
        5,
        "Alima"
      ],
      [
        5,
        "Md Abidur Rahaman"
      ],
      [
        5,
        "Anbaana Arun"
      ],
      [
        5,
        "Sivalingam Babu"
      ],
      [
        5,
        "Rajesh Balachandiran"
      ],
      [
        5,
        "M.S. Bhaskar"
      ],
      [
        5,
        "Bava Chelladurai"
      ],
      [
        5,
        "Set Dhanasekhar"
      ],
      [
        5,
        "Tarun Foujdar"
      ],
      [
        5,
        "Bala Hasan"
      ],
      [
        5,
        "V. Jayaprakash"
      ],
      [
        5,
        "Baby Joshika Maya"
      ],
      [
        5,
        "Hello Kandasamy"
      ],
      [
        5,
        "Vetri Kiran"
      ],
      [
        5,
        "Amarar Krav Maha Sriram"
      ],
      [
        5,
        "Mayilai Manivannan"
      ],
      [
        5,
        "Lijo Mol Jose"
      ],
      [
        5,
        "Velli Muthu"
      ],
      [
        5,
        "Kumar Natarajan"
      ],
      [
        5,
        "Ramkumar Pazhani"
      ],
      [
        5,
        "Jijoi Pr"
      ],
      [
        5,
        "K. Raj"
      ],
      [
        5,
        "Prakash Raj"
      ],
      [
        5,
        "Supervisor Raju"
      ],
      [
        5,
        "Anaangoor Raju"
      ],
      [
        5,
        "Winner' Ramachandran"
      ],
      [
        5,
        "Rao Ramesh"
      ],
      [
        5,
        "Raja Rani Pandian"
      ],
      [
        5,
        "S. Ravi"
      ],
      [
        5,
        "Subatra Robert"
      ],
      [
        5,
        "Sekar Sami"
      ],
      [
        5,
        "Aravind Sejju"
      ],
      [
        5,
        "M. Sinrasu"
      ],
      [
        5,
        "Bigil Siva"
      ],
      [
        5,
        "Sujatha Sivakumar"
      ],
      [
        5,
        "David Soloman"
      ],
      [
        5,
        "Guru Somasundaram"
      ],
      [
        5,
        "Supergood Subramani"
      ],
      [
        5,
        "Chaplin Sundamir"
      ],
      [
        5,
        "Shankar Sundaram"
      ],
      [
        5,
        "Sanjay Swaroop"
      ],
      [
        5,
        "Sibi Thomas"
      ],
      [
        5,
        "Kalakkadu V. Ranganathan"
      ],
      [
        5,
        "Ravi Venkatraman"
      ],
      [
        5,
        "Rajisha Vijayan"
      ],
      [
        5,
        "Aahil World"
      ]
    ],
    "directors": "T.J. Gnanavel",
    "budget_string": "N/A",
    "gross_string": "N/A",
    "metascore_string": "N/A"
  },
  {
    "imdb_id": "tt6710474",
    "plot": "An aging Chinese immigrant is swept up in an insane adventure, where she alone can save the world by exploring other universes connecting with the lives she could have led.",
    "certification": "R",
    "other_wins_count": 1,
    "other_noms_count": 0,
    "amazon": "https://www.amazon.com/Everything-Everywhere-All-Once-Blu-ray/dp/B09X175QH5/ref=tmm_blu_swatch_0?_encoding=UTF8&amp;qid=1654845128&amp;sr=1-2&_encoding=UTF8&tag=sianglu-20&linkCode=ur2&linkId=aaff3a29dc4fffd3e4010772f7443dbd&camp=1789&creative=9325",
    "justwatch": "https://justwatch.com/us/movie/everything-everywhere-all-at-once?utm_source=share&utm_medium=web",
    "beige_index": 2,
    "beige_angle": 41.0292,
    "cast": [
      [
        5,
        "Sunita Mani"
      ],
      [
        4,
        "Peter Banifaz"
      ],
      [
        3,
        "Panuvat Anthony Nanakornpanom"
      ],
      [
        3,
        "Narayana Cabral"
      ],
      [
        3,
        "Chelsey Goldsmith"
      ],
      [
        3,
        "Craig Henningsen"
      ],
      [
        2,
        "Peter Boon Koh"
      ],
      [
        2,
        "Dylan Henry Lau"
      ],
      [
        2,
        "James Hong"
      ],
      [
        2,
        "Stephanie Hsu"
      ],
      [
        2,
        "Ke Huy Quan"
      ],
      [
        2,
        "Li Jing"
      ],
      [
        2,
        "Brian Le"
      ],
      [
        2,
        "Andy Le"
      ],
      [
        2,
        "Jane Lui"
      ],
      [
        2,
        "Michiko Nishiwaki"
      ],
      [
        2,
        "Harry Shum Jr."
      ],
      [
        2,
        "Hiroshi Yada"
      ],
      [
        2,
        "Michelle Yeoh"
      ],
      [
        1,
        "Randall Archer"
      ],
      [
        1,
        "Dan Brown"
      ],
      [
        1,
        "Timothy Eulich"
      ],
      [
        1,
        "Jason Hanmer"
      ],
      [
        1,
        "Efka Kvaraciejus"
      ],
      [
        1,
        "Aaron Lazar"
      ],
      [
        1,
        "Jamie Lee Curtis"
      ],
      [
        1,
        "Cara Marie Chooljian"
      ],
      [
        1,
        "Tallie Medel"
      ],
      [
        1,
        "Anthony Molinari"
      ],
      [
        1,
        "Daniel Scheinert"
      ],
      [
        1,
        "Timothy Scott Ralston"
      ],
      [
        1,
        "Jenny Slate"
      ],
      [
        1,
        "Audrey Wasilewski"
      ],
      [
        1,
        "Biff Wiff"
      ]
    ],
    "directors": "Dan Kwan & Daniel Scheinert",
    "budget_string": "N/A",
    "gross_string": "68.7m USD",
    "metascore_string": "81%"
  },
  {
    "imdb_id": "tt1745960",
    "plot": "After more than thirty years of service as one of the Navy's top aviators, Pete Mitchell is where he belongs, pushing the envelope as a courageous test pilot and dodging the advancement in rank that would ground him.",
    "certification": "PG-13",
    "other_wins_count": 0,
    "other_noms_count": 0,
    "amazon": "https://www.amazon.com/Top-Gun-Maverick-Tom-Cruise/dp/B09XZD472R/ref=sr_1_3?crid=1MCAQSTYPFTH4&amp;keywords=top+gun+maverick&amp;qid=1654832960&amp;s=movies-tv&amp;sprefix=top+gun+maverick%252Cmovies-tv-intl-ship%252C619&amp;sr=1-3&_encoding=UTF8&tag=sianglu-20&linkCode=ur2&linkId=1e95d94f869265ed476519e1a79765f7&camp=1789&creative=9325",
    "justwatch": "https://justwatch.com/us/movie/top-gun-maverick?utm_source=share&utm_medium=web",
    "beige_index": 2,
    "beige_angle": 65.7,
    "cast": [
      [
        5,
        "Jay Ellis"
      ],
      [
        5,
        "Chelsea Harris"
      ],
      [
        5,
        "Tristan Henry"
      ],
      [
        5,
        "Chaz Ingram"
      ],
      [
        5,
        "Shannon Kane"
      ],
      [
        5,
        "Darnell Kirkwood"
      ],
      [
        5,
        "Chido Nwokocha"
      ],
      [
        5,
        "Charles Parnell"
      ],
      [
        5,
        "Greg Tarzan Davis"
      ],
      [
        5,
        "Bashir Salahuddin"
      ],
      [
        3,
        "Manny Jacinto"
      ],
      [
        3,
        "Danny Ramirez"
      ],
      [
        3,
        "Stephanie Andrea Barron"
      ],
      [
        3,
        "Shantel Limbo"
      ],
      [
        2,
        "Raymond Lee"
      ],
      [
        2,
        "Whylip Lee"
      ],
      [
        2,
        "Kara Wang"
      ],
      [
        1,
        "Monica Barbaro"
      ],
      [
        1,
        "Austin Bowerman"
      ],
      [
        1,
        "Jennifer Connelly"
      ],
      [
        1,
        "Tom Cruise"
      ],
      [
        1,
        "Brian Ferguson"
      ],
      [
        1,
        "Ian Gary"
      ],
      [
        1,
        "Landon Gordon"
      ],
      [
        1,
        "Jon Hamm"
      ],
      [
        1,
        "James Handy"
      ],
      [
        1,
        "Ed Harris"
      ],
      [
        1,
        "Val Kilmer"
      ],
      [
        1,
        "Jean Louisa Kelly"
      ],
      [
        1,
        "Peter Mark Kendall"
      ],
      [
        1,
        "Rachael Markarian"
      ],
      [
        1,
        "Jake Picking"
      ],
      [
        1,
        "Glen Powell"
      ],
      [
        1,
        "Lewis Pullman"
      ],
      [
        1,
        "Jack Schumacher"
      ],
      [
        1,
        "Bob Stephenson"
      ],
      [
        1,
        "Miles Teller"
      ],
      [
        1,
        "Alec Williams"
      ],
      [
        1,
        "Rachel Winfree"
      ],
      [
        1,
        "Lyliana Wray"
      ]
    ],
    "directors": "Joseph Kosinski",
    "budget_string": "N/A",
    "gross_string": "321.4m USD",
    "metascore_string": "78%"
  }
]